import { t } from '../../../i18n/i18n';

export default {
  save: t('Common:SAVE'),
  back: t('Common:BACK'),
  cancel: t('Common:CANCEL'),
  cancelYes: t('Common:YES_CANCEL'),
  cancelNo: t('Common:NO'),
  cancelTextPart1: t('Common:ARE_YOU_SURE'),
  cancelTextPart2: t('Common:UNSAVED_CHANGES'),
  previewJourney: t('EditJourney:PREVIEW_JOURNEY'),
};
