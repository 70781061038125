import { t } from '../../../../i18n/i18n';

export default {
  addressLine1: t('OPSConsoleOrgs:ADDRESS_LINE_1'),
  addressLine2: t('OPSConsoleOrgs:ADDRESS_LINE_2'),
  firstName: t('Common:FIRST_NAME'),
  yearly: t('Billing:ANNUAL_TITLE'),
  buyLicenses: t('License:BUY_LICENSES'),
  buyLicensesMessage: t('Billing:BUY_LICENSES_MESSAGE'),
  cancel: t('Common:CANCEL'),
  city: t('Common:CITY'),
  country: t('Common:COUNTRY'),
  enterNumber: t('Billing:LICENSES_PLACEHOLDER'),
  lastName: t('Common:LAST_NAME'),
  licenseMessagePart1: t('Billing:LICENSE_MESSAGE_PART_1'),
  licenseMessagePart2: t('Billing:LICENSE_MESSAGE_PART_2'),
  licenseMessagePart3: t('Billing:LICENSE_MESSAGE_PART_3'),
  numberLicenses: t('Billing:NUMBER_OF_LICENSES'),
  next: t('Common:NEXT'),
  monthly: t('Billing:MONTHLY_TITLE'),
  billingEmail: t('OPSConsoleOrgs:BILLING_EMAIL'),
  term: t('OPSConsoleOrgs:TERM_TITLE'),
  state: t('Common:STATE'),
};
