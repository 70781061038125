import { CHARGEBEE_MONTHLY_TERM, SubscribeCustomerInfo } from '../store/billing';
import { handleAPIRequest } from '../store/utils/reduxUtils';
import { API_PUBLIC } from '../types/constants';

declare global {
  const Chargebee: any;
}

const getCBInstance = () => {
  return Chargebee.inited
    ? Chargebee.getInstance()
    : Chargebee.init({
        site: process.env.REACT_APP_CHARGEBEE_SITE,
        publishableKey: process.env.REACT_APP_CHARGEBEE_PUBLIC_KEY,
        isItemsModel: true,
      });
};

// ***
// When you change the requests do not forget to specify new data
// in docs/Endpoints.md
// ***

export const openPortal = async (chargeBeeCheckoutClose: () => void) => {
  const cbInstance = getCBInstance();

  // Callback API for ChargeBee Portal Popup: https://www.chargebee.com/checkout-portal-docs/cbinstanceobj-api-ref.html#checkout-and-portal-integration
  cbInstance.setPortalCallbacks({
    close: () => {
      chargeBeeCheckoutClose();
    },
  });

  cbInstance.setPortalSession(() => handleAPIRequest(`${API_PUBLIC}/billing/createPortalSession`, {}));
  const cbPortal = cbInstance.createChargebeePortal();
  cbPortal.open();
};

export const subscribe = (
  quantity = 10,
  term = CHARGEBEE_MONTHLY_TERM,
  customerInfo: SubscribeCustomerInfo,
  tenantId: string,
  chargeBeeCheckoutSuccess: () => void
) => {
  const cbInstance = getCBInstance();
  const cart = cbInstance.getCart();
  const product = cbInstance.initializeProduct(term);
  product.setPlanQuantity(quantity);
  product.setCustomData({
    cf_tenantId: tenantId,
  });
  cart.replaceProduct(product);
  if (customerInfo) cart.setCustomer(customerInfo);

  // Callback API for ChargeBee Checkout Popup: https://www.chargebee.com/checkout-portal-docs/cbinstanceobj-api-ref.html#checkout-and-portal-integration
  cbInstance.setCheckoutCallbacks(function () {
    return {
      success: function () {
        chargeBeeCheckoutSuccess();
      },
    };
  });

  cart.proceedToCheckout();
};
