import { t } from '../../../i18n/i18n';

export default {
  description: t('QuickSetupCalendarConnected:DESCRIPTION'),
  instructionTitle: t('QuickSetupCalendarConnected:INSTRUCTION_TITLE'),
  instructionStepA1: t('QuickSetupCalendarConnected:INSTRUCTION_STEP_A1'),
  instructionStepA2: t('QuickSetupCalendarConnected:INSTRUCTION_STEP_A2'),
  instructionStepB: t('QuickSetupCalendarConnected:INSTRUCTION_STEP_B'),
  instructionStepC: t('QuickSetupCalendarConnected:INSTRUCTION_STEP_C'),
  next: t('Common:NEXT'),
  title: t('QuickSetupCalendarConnected:TITLE'),
  stepMessage: t('QuickSetupCalendarConnected:STEP_MESSAGE'),
};
