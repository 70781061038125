import { ToastNotification, ToastNotificationOptions } from './types';

export enum NotificationsActionTypes {
  ADD_TOAST_TO_QUEUE = 'notifications/ADD_TOAST_TO_QUEUE',
  REMOVE_TOAST_FROM_QUEUE = 'notifications/REMOVE_TOAST_FROM_QUEUE',
  SHOW_TOAST = 'notifications/SHOW_TOAST',
  HIDE_TOAST = 'notifications/HIDE_TOAST',
}

export type NotificationsAction =
  | { type: NotificationsActionTypes.ADD_TOAST_TO_QUEUE; payload: ToastNotification }
  | { type: NotificationsActionTypes.REMOVE_TOAST_FROM_QUEUE; payload?: string }
  | { type: NotificationsActionTypes.SHOW_TOAST; payload: ToastNotificationOptions }
  | { type: NotificationsActionTypes.HIDE_TOAST; payload: string };

const addToastToQueue = (payload: ToastNotification): NotificationsAction => ({
  type: NotificationsActionTypes.ADD_TOAST_TO_QUEUE,
  payload,
});
const removeToastFromQueue = (payload: string): NotificationsAction => ({
  type: NotificationsActionTypes.REMOVE_TOAST_FROM_QUEUE,
  payload,
});

const showToast = (payload: ToastNotificationOptions): NotificationsAction => ({
  type: NotificationsActionTypes.SHOW_TOAST,
  payload,
});
const hideToast = (payload: string): NotificationsAction => ({
  type: NotificationsActionTypes.HIDE_TOAST,
  payload,
});

export const notificationsActions = { addToastToQueue, removeToastFromQueue, showToast, hideToast };
