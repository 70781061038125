import { t } from '../../../i18n/i18n';

export default {
  inputTitle: t('JourneysPageStep:FORM_INPUT_TILE'),
  inputSubtitle: t('JourneysPageStep:FORM_INPUT_SUBTITLE'),
  writeQuestion: t('JourneysPageStep:FORM_WRITE_QUESTION'),
  inputRequired: t('JourneysPageStep:FORM_INPUT_REQUIRED'),
  questionRequired: t('JourneysPageStep:FORM_QUESTION_REQUIRED'),
  answer: t('JourneysPageStep:FORM_ANSWER'),
  addAnswer: t('JourneysPageStep:FORM_ADD_ANSWER'),
  add: t('EditJourney:ADD_LABEL'),
  save: t('Common:SAVE'),
  cancel: t('Common:CANCEL'),
};
