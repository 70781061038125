import { Button } from 'primereact/button';
import { Sidebar } from 'primereact/sidebar';
import { TextEditor } from '../../common';
import { PlusIcon } from '@heroicons/react/24/outline';
import { useEffect, useRef, useState } from 'react';
import { Menu } from 'primereact/menu';
import { MERGE_FIELD_OPTIONS, MERGE_FIELD_SUBJECT_OPTIONS } from '../../../store/bookingTemplates';
import { Editor } from 'primereact/editor';
import labels from './labels';
import { ScrollPanel } from 'primereact/scrollpanel';

type SmartAlertPersonalizeProps = {
  visible: boolean;
  close: () => void;
  handleCancel: () => void;
  handleSave: (body: string, subject?: string) => void;
  subject: string;
  body: string;
  title: string;
  isSMS?: boolean;
  isReadOnly?: boolean;
};
export const SmartAlertPersonalize = ({
  visible,
  handleCancel,
  handleSave,
  subject,
  body,
  title,
  isSMS,
  isReadOnly,
}: SmartAlertPersonalizeProps) => {
  const [localSubject, setLocalSubject] = useState(subject);
  const [localBody, setLocalBody] = useState(body);
  const menuSubject = useRef<Menu>(null);
  const menuBody = useRef<Menu>(null);
  const editorSubjectRef = useRef<Editor | null>(null);
  const editorBodyRef = useRef<Editor | null>(null);

  const onSave = () => {
    handleSave(localBody, localSubject);
  };

  const onCancel = () => {
    handleCancel();
    setLocalSubject('');
    setLocalBody('');
  };

  useEffect(() => {
    setLocalSubject(subject || '');
  }, [subject]);

  useEffect(() => {
    setLocalBody(body);
  }, [body]);

  const addMergeVariable = (value: string, type: 'subject' | 'body') => {
    const editorRef = type === 'subject' ? editorSubjectRef : editorBodyRef;
    const selection = editorRef.current && editorRef.current?.getQuill()?.getSelection(true);
    const mergeValue = '<' + value + '>';

    if (selection && editorRef.current) {
      editorRef.current.getQuill().insertText(selection.index, mergeValue);
    }
  };

  const menuItemsSubject = MERGE_FIELD_SUBJECT_OPTIONS.map((option) => ({
    label: option.name,
    command: () => addMergeVariable(option.name, 'subject'),
  }));
  const menuItemsBody = MERGE_FIELD_OPTIONS.map((option) => ({
    label: option.name,
    command: () => addMergeVariable(option.name, 'body'),
  }));

  const getHeader = (type: 'subject' | 'body') => {
    return (
      <span className="ql-formats">
        {type === 'body' && !isSMS && (
          <>
            <button className="ql-bold" aria-label="Bold"></button>
            <button className="ql-italic" aria-label="Italic"></button>
            <button className="ql-underline" aria-label="Underline"></button>
            <button className="ql-link" aria-label="Link"></button>
            {/* <button className="ql-image" aria-label="Image"></button> */}
          </>
        )}
        <Button
          className="flex-center gap-4px text-heavy-60 hover:text-heavy-80 text-label-xs-reg w-fit"
          text
          onClick={(e) => {
            e.stopPropagation();
            (type === 'subject' ? menuSubject : menuBody).current?.toggle(e);
          }}
        >
          <PlusIcon width={12} height={12} />
          {labels.addVariable}
        </Button>
        <Menu
          ref={type === 'subject' ? menuSubject : menuBody}
          model={type === 'subject' ? menuItemsSubject : menuItemsBody}
          popup
          className="max-h-15rem overflow-y-auto"
        />
      </span>
    );
  };

  return (
    <Sidebar
      visible={visible}
      position="right"
      onHide={handleCancel}
      dismissable={false}
      showCloseIcon={false}
      className="w-500px"
      pt={{ header: { className: 'hidden' }, content: { className: 'p-0' } }}
    >
      <div className="flex flex-column h-full">
        <div className="flex-none text-sidebar-header text-heavy-100 px-32px pt-32px pb-16px">{title}</div>
        <div className="flex-none border-top-1 border-heavy-20" />
        <div className="flex-1 overflow-hidden">
          <ScrollPanel>
            <div className="flex flex-column gap-16px p-32px">
              {!isSMS && (
                <div className="flex flex-column gap-8px">
                  <div className="text-title-xs-med text-heavy-100">{labels.subject}</div>
                  <TextEditor
                    className="flex flex-column"
                    value={localSubject}
                    setValue={(newValue: string) => setLocalSubject(newValue)}
                    setEditorRef={(editor: Editor | null) => (editorSubjectRef.current = editor)}
                    header={getHeader('subject')}
                    showUndoRedo={true}
                    useTextValue={true}
                    isReadOnly={isReadOnly}
                  />
                </div>
              )}
              <div className="flex flex-column gap-8px">
                <div className="text-title-xs-med text-heavy-100">{labels.body}</div>
                <TextEditor
                  className="flex flex-column"
                  value={localBody}
                  setValue={(newValue: string) => setLocalBody(newValue)}
                  setEditorRef={(editor: Editor | null) => (editorBodyRef.current = editor)}
                  header={getHeader('body')}
                  showUndoRedo={true}
                  useTextValue={isSMS}
                  isReadOnly={isReadOnly}
                />
              </div>
            </div>
          </ScrollPanel>
        </div>
        <div className="flex-none flex-left-center gap-6px border-top-1 border-heavy-20 px-32px py-24px">
          <Button className="flex-center button-xl min-w-120px" onClick={onSave} disabled={isReadOnly}>
            {labels.save}
          </Button>
          <Button className="button-xl" text onClick={onCancel}>
            {labels.cancel}
          </Button>
        </div>
      </div>
    </Sidebar>
  );
};
