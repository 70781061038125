import { UpdateGroupBookingPageInput } from '../../API';
import { API_PUBLIC } from '../../types/constants';
import { handleAPIRequest } from '../utils/reduxUtils';
import { GetGroupBookingPageResponse } from './types';

// ***
// When you change the requests do not forget to specify new data
// in docs/Endpoints.md
// ***

export async function getGroupBookingPages(workspaceId: string) {
  return handleAPIRequest<GetGroupBookingPageResponse>(`${API_PUBLIC}/getGroupBookingPage`, { workspaceId });
}

export async function getGroupBookingPage(id: string) {
  return handleAPIRequest<GetGroupBookingPageResponse>(`${API_PUBLIC}/getGroupBookingPage`, { id });
}

export async function upsertGroupBookingPage(groupBookingPage: UpdateGroupBookingPageInput) {
  return handleAPIRequest<void>(`${API_PUBLIC}/upsertGroupBookingPage`, { groupBookingPage });
}

export async function deleteGroupBookingPage(ids: string[]) {
  return handleAPIRequest<void>(`${API_PUBLIC}/deleteGroupBookingPage`, { ids });
}
