import { combineReducers } from 'redux';
import { UsersAction, UsersActionTypes } from './actions';
import { InviteUsersOptions, UserAdminDataFullRecord } from './types';
import { GlobalAction, GlobalActionTypes } from '../global/actions';
import { DEFAULT_FILTER } from './constants';

const users = (state: UserAdminDataFullRecord[] = [], action: UsersAction | GlobalAction) => {
  switch (action.type) {
    case UsersActionTypes.GET_USERS_SUCCESS:
      return action.payload;
    case GlobalActionTypes.RESET_WHOLE_STORE:
      return [];
    default:
      return state;
  }
};

const selectedUser = (state = {} as UserAdminDataFullRecord, action: UsersAction | GlobalAction) => {
  switch (action.type) {
    case UsersActionTypes.SET_USER_RECORD:
      return action.payload;
    case UsersActionTypes.UPDATE_USER_RECORD:
      return { ...state, ...action.payload };
    case UsersActionTypes.EDIT_USER_RECORD_SUCCESS:
    case UsersActionTypes.DISABLE_USER_RECORD_SUCCESS:
    case UsersActionTypes.DELETE_USER_RECORD_SUCCESS:
      return {} as UserAdminDataFullRecord;
    case GlobalActionTypes.RESET_WHOLE_STORE:
      return {} as UserAdminDataFullRecord;
    default:
      return state;
  }
};

const inviteUsersOptions = (state: InviteUsersOptions | null = null, action: UsersAction | GlobalAction) => {
  switch (action.type) {
    case UsersActionTypes.GET_INVITE_USERS_OPTIONS_SUCCESS:
      return action.payload;
    case UsersActionTypes.GET_INVITE_USERS_OPTIONS_REQUEST:
    case GlobalActionTypes.RESET_WHOLE_STORE:
      return null;
    default:
      return state;
  }
};

const activeSuperAdmins = (state: UserAdminDataFullRecord[] = [], action: UsersAction | GlobalAction) => {
  switch (action.type) {
    case UsersActionTypes.SET_ACTIVE_SUPER_ADMINS:
      return action.payload;
    case GlobalActionTypes.RESET_WHOLE_STORE:
      return [];
    default:
      return state;
  }
};

const filter = (state = DEFAULT_FILTER, action: UsersAction | GlobalAction) => {
  switch (action.type) {
    case UsersActionTypes.SET_FILTER:
      return { ...state, ...action.payload };
    case GlobalActionTypes.RESET_WHOLE_STORE:
      return DEFAULT_FILTER;
    default:
      return state;
  }
};

const selectedUsers = (state: string[] = [], action: UsersAction | GlobalAction) => {
  switch (action.type) {
    case UsersActionTypes.SELECT_USERS:
      return action.payload;
    case UsersActionTypes.GET_USERS_REQUEST:
    case UsersActionTypes.GET_USERS_PAGE_DATA_REQUEST:
    case GlobalActionTypes.RESET_WHOLE_STORE:
      return [];
    default:
      return state;
  }
};

const lastLoadTime = (state = 0, action: UsersAction | GlobalAction) => {
  switch (action.type) {
    case UsersActionTypes.GET_USERS_SUCCESS:
      return new Date().getTime();
    case GlobalActionTypes.RESET_WHOLE_STORE:
      return 0;
    default:
      return state;
  }
};

const isFetching = (state = false, action: UsersAction | GlobalAction) => {
  switch (action.type) {
    case UsersActionTypes.GET_USERS_REQUEST:
    case UsersActionTypes.EDIT_USER_RECORD_REQUEST:
    case UsersActionTypes.DISABLE_USER_RECORD_REQUEST:
    case UsersActionTypes.DELETE_USER_RECORD_REQUEST:
    case UsersActionTypes.GET_USERS_PAGE_DATA_REQUEST:
      return true;
    case UsersActionTypes.GET_USERS_SUCCESS:
    case UsersActionTypes.GET_USERS_FAIL:
    case UsersActionTypes.EDIT_USER_RECORD_SUCCESS:
    case UsersActionTypes.EDIT_USER_RECORD_FAIL:
    case UsersActionTypes.DISABLE_USER_RECORD_SUCCESS:
    case UsersActionTypes.DISABLE_USER_RECORD_FAIL:
    case UsersActionTypes.DELETE_USER_RECORD_SUCCESS:
    case UsersActionTypes.DELETE_USER_RECORD_FAIL:
      return false;
    case GlobalActionTypes.RESET_WHOLE_STORE:
      return false;
    default:
      return state;
  }
};

const isInviteUsersFetching = (state = false, action: UsersAction | GlobalAction) => {
  switch (action.type) {
    case UsersActionTypes.INVITE_USERS_REQUEST:
      return true;
    case UsersActionTypes.INVITE_USERS_SUCCESS:
    case UsersActionTypes.INVITE_USERS_FAIL:
      return false;
    case GlobalActionTypes.RESET_WHOLE_STORE:
      return false;
    default:
      return state;
  }
};

const error = (state: unknown | null = null, action: UsersAction | GlobalAction) => {
  switch (action.type) {
    case UsersActionTypes.GET_USERS_FAIL:
    case UsersActionTypes.INVITE_USERS_FAIL:
    case UsersActionTypes.EDIT_USER_RECORD_FAIL:
    case UsersActionTypes.DISABLE_USER_RECORD_FAIL:
    case UsersActionTypes.DELETE_USER_RECORD_FAIL:
      return action.error;
    case UsersActionTypes.GET_USERS_REQUEST:
    case UsersActionTypes.INVITE_USERS_REQUEST:
    case UsersActionTypes.EDIT_USER_RECORD_REQUEST:
    case UsersActionTypes.DISABLE_USER_RECORD_REQUEST:
    case UsersActionTypes.DELETE_USER_RECORD_REQUEST:
    case UsersActionTypes.GET_USERS_PAGE_DATA_REQUEST:
      return null;
    case GlobalActionTypes.RESET_WHOLE_STORE:
      return null;
    default:
      return state;
  }
};

export default combineReducers({
  users,
  selectedUser,
  inviteUsersOptions,
  activeSuperAdmins,
  filter,
  selectedUsers,
  lastLoadTime,
  isFetching,
  isInviteUsersFetching,
  error,
});
