import { WorkspaceInput, CreateTenantInput } from '../../API';
import { UserAdminDataFullRecord } from '../users/types';

export type WorkspaceWhoStep = {
  adminList: UserAdminDataFullRecord[];
  userList: UserAdminDataFullRecord[];
};

export type WorkspaceData = WorkspaceInput & WorkspaceWhoStep;

export enum WorkspaceActions {
  CREATE = 'create workspace',
  UPDATE = 'update workspace',
  DELETE = 'delete workspace',
}

export type WorkspaceOption = {
  id: string;
  name: string;
};

export type GetWorkspaceResponse = {
  workspace: WorkspaceInput;
};

export type GetWorkspacesResponse = {
  workspaces: WorkspaceInput[];
};

export type UpsertWorkspaceResponse = {
  workspace: WorkspaceInput;
  users?: string[];
  usersToDelete?: string[];
};

export type DeteleWorkspaceResponse = {
  workspaceId: string;
  usersToDelete: string[];
};

export type SaveWorkspaceResponse = GetWorkspaceResponse;

export type GetTenantDataResponse = {
  tenant: CreateTenantInput;
};

export type AccordionIndexes = {
  what: boolean;
  who: boolean;
  how: boolean;
  security: boolean;
};
