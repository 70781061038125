import { useEffect, useRef, useState } from 'react';
import { IntegrationType, WorkspaceIntegrationType } from '../../../API';
import { useDispatch, useSelector } from 'react-redux';
import { userSettingsSelectors } from '../../../store/userSettings';
import { ConnectIntegration } from '../connectIntegration/ConnectIntegration';
import { HubSpotCustomParameters, integrationActions, WorkspaceIntegrationRequest } from '../../../store/integration';
import { authenticationSelectors } from '../../../store/authentication';
import { navigationService } from '../../../services/NavigationService';
import { Path } from '../../../routing';
import { HubSpotAuthButton } from '../../private';
import { getIntegrationURLParams } from '../../../store/integration/utils';
import { getUrlWithoutParams } from '../../../services/URLService';
import { InputSwitch } from 'primereact/inputswitch';
import labels from './labels';

interface IHubspotIntegration {
  integrationType: IntegrationType | WorkspaceIntegrationType;
  handleDisconnect: () => void;
}

export const HubspotIntegration = (props: IHubspotIntegration) => {
  const { integrationType, handleDisconnect } = props;
  const dispatch = useDispatch();
  const isAuthenticationFetching = useSelector(authenticationSelectors.selectIsFetching);
  const isUserSettingsReceived = useSelector(userSettingsSelectors.selectIsUserSettingsReceived);
  const userSettingsError = useSelector(userSettingsSelectors.selectError);
  const initialLoad = useRef(true); // Use a ref to track the initial load
  const workspaceId = useSelector(authenticationSelectors.selectWorkspaceId);

  const workspaceIntegrationSettings = useSelector(
    authenticationSelectors.selectWorkspaceIntegrationSettings(integrationType)
  );

  const [customSettingsLoaded, setCustomSettingsLoaded] = useState(false);
  const [createEvents, setCreateEvents] = useState(true);
  const [bookLeads, setBookLeads] = useState(true);
  const [bookContacts, setBookContacts] = useState(true);
  const [savedSettings, setSavedSettings] = useState({} as any);

  useEffect(() => {
    setCustomSettingsLoaded(true);
    if (workspaceIntegrationSettings) {
      const settings = workspaceIntegrationSettings as unknown as HubSpotCustomParameters;
      setCreateEvents('true' === settings.createEvents);
      setBookLeads('true' === settings.bookLeads);
      setBookContacts('true' === settings.bookContacts);

      setSavedSettings({
        createEvents: 'true' === settings.createEvents,
        bookLeads: 'true' === settings.bookLeads,
        bookContacts: 'true' === settings.bookContacts,
      });
    }
  }, [workspaceIntegrationSettings]);

  const hasCustomSettingsChanges = () => {
    return (
      savedSettings &&
      (savedSettings.createEvents !== createEvents ||
        savedSettings.bookLeads !== bookLeads ||
        savedSettings.bookContacts !== bookContacts)
    );
  };

  useEffect(() => {
    if (isUserSettingsReceived && !isAuthenticationFetching && !userSettingsError) {
      const { code, type, workspaceId, customParameters } = getIntegrationURLParams();

      if (code && type && workspaceId) {
        const workspaceIntegrationResponse = {
          code,
          type,
          workspaceId,
          redirectURI: getUrlWithoutParams(),
          customParameters,
        } as WorkspaceIntegrationRequest;

        handleGenericIntegrationAuthentication(workspaceIntegrationResponse);
      }
    }
  }, [isUserSettingsReceived, isAuthenticationFetching, userSettingsError, integrationType]);

  const handleGenericIntegrationAuthentication = async (integration: WorkspaceIntegrationRequest) => {
    dispatch(integrationActions.connectWorkspaceIntegrationRequest(integration));
    navigationService.navigateTo(Path.ConnectIntegration); // clear the URL from code
    initialLoad.current = false; // after navigating, mark that the initial load is complete
  };

  const handleGetHubSpotCodeRedirect = () => {
    // TODO add that integrations to workspaces and provide correct id
    dispatch(
      integrationActions.getHubSpotCodeRedirect(window.location.href, workspaceId, {
        createEvents: createEvents.toString(),
        bookLeads: bookLeads.toString(),
        bookContacts: bookContacts.toString(),
      })
    );
  };

  const handleUpdate = () => {
    dispatch(
      integrationActions.connectWorkspaceIntegrationRequest({
        type: WorkspaceIntegrationType.HUBSPOT,
        workspaceId,
        customParameters: {
          createEvents: createEvents.toString(),
          bookLeads: bookLeads.toString(),
          bookContacts: bookContacts.toString(),
        },
      })
    );
  };

  return (
    <ConnectIntegration
      integrationType={integrationType}
      handleDisconnect={handleDisconnect}
      ConnectButton={HubSpotAuthButton}
      handleAuthRedirect={handleGetHubSpotCodeRedirect}
      hasCustomSettingsChanges={hasCustomSettingsChanges()}
      buttonUpdateIsDisabled={false}
      handleUpdate={handleUpdate}
      CustomSettingsSection={
        customSettingsLoaded ? (
          <>
            <div className="text-title-xs-med mb-2 mt-24px">{labels.createEvents}</div>
            <div className="text-body-s-reg text-heavy-60 flex align-items-center gap-2 mb-24px">
              <InputSwitch checked={createEvents} onChange={(e) => setCreateEvents(!!e.value)} />
              {labels.create}
            </div>
            <div className="text-title-xs-med mb-2">{labels.bookUsers}</div>
            <div className="text-body-s-reg text-heavy-60 flex gap-4">
              <div className="flex align-items-center gap-2">
                <InputSwitch checked={bookContacts} onChange={(e) => setBookContacts(!!e.value)} />
                {labels.contacts}
              </div>
              <div className="flex align-items-center gap-2">
                <InputSwitch checked={bookLeads} onChange={(e) => setBookLeads(!!e.value)} />
                {labels.leads}
              </div>
            </div>
          </>
        ) : (
          <></>
        )
      }
    />
  );
};
