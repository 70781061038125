import {
  ReactFlow,
  MiniMap,
  Controls,
  useNodesState,
  useEdgesState,
  addEdge,
  Connection,
  Background,
  BackgroundVariant,
  ConnectionLineType,
} from '@xyflow/react';
import '@xyflow/react/dist/style.css';
import { useCallback, useEffect } from 'react';
import {
  deleteJourneyStepModalActions,
  deleteJourneyStepModalSelectors,
  getColorByStepType,
  JourneyNodes,
  journeysActions,
  journeysSelectors,
} from '../../../store/journeyBuilder';
import { JourneyStepNode } from '../journeyStepNode/JourneyStepNode';
import { JourneyStartNode } from '../journeyStartNode/JourneyStartNode';
import { JourneyPlusNode } from '../journeyPlusNode/JourneyPlusNode';
import { JourneyStartPlusNode } from '../journeyStartPlusNode/JourneyStartPlusNode';
import { useDispatch, useSelector } from 'react-redux';
import { ConfirmationModal } from '../../common';
import labels from './labels';
import { generateEdges, generateNodes, isJourneyStepNode } from './utils';
import { EditJourneyButtonBar } from '../editJourneyButtonBar/EditJourneyButtonBar';
import { EditJourneySidebar } from '../editJourneySidebar/EditJourneySidebar';

export const JourneyCanvas = () => {
  const dispatch = useDispatch();
  const deleteIsOpen = useSelector(deleteJourneyStepModalSelectors.selectIsModalOpen);
  const journey = useSelector(journeysSelectors.selectJourney);
  const selectedStep = useSelector(journeysSelectors.selectJourneyStep);
  const isReadOnly = useSelector(journeysSelectors.selectJourneyReadOnly);

  const [nodes, setNodes, onNodesChange] = useNodesState<JourneyNodes>(
    generateNodes(journey, selectedStep, isReadOnly)
  );
  const [edges, setEdges, onEdgesChange] = useEdgesState(generateEdges(journey, selectedStep));

  const onConnect = useCallback((params: Connection) => setEdges((els) => addEdge(params, els)), []);

  useEffect(() => {
    setNodes(generateNodes(journey, selectedStep, isReadOnly));
    setEdges(generateEdges(journey, selectedStep));
  }, [journey.root]);

  useEffect(() => {
    setNodes(generateNodes(journey, selectedStep, isReadOnly));
  }, [selectedStep]);

  const nodeColor = (node: JourneyNodes) => {
    return isJourneyStepNode(node) ? getColorByStepType(node.data?.journeyStep?.type) : 'var(--heavy-blue-40)';
  };

  const nodeTypes = {
    startNode: JourneyStartNode,
    startPlusNode: JourneyStartPlusNode,
    plusNode: JourneyPlusNode,
    journeyStep: JourneyStepNode,
  };

  const deleteModalClose = () => {
    dispatch(deleteJourneyStepModalActions.closeModal());
  };
  const deleteModalConfirm = () => {
    dispatch(journeysActions.removeJourneyStep(selectedStep?.id || ''));
    deleteModalClose();
  };

  return (
    <div>
      <div className="journey-canvas">
        <ReactFlow
          nodes={nodes}
          nodeTypes={nodeTypes}
          edges={edges}
          onNodesChange={onNodesChange}
          onEdgesChange={onEdgesChange}
          onConnect={onConnect}
          nodesDraggable={false}
          edgesFocusable={false}
          connectionLineType={ConnectionLineType.Step}
          deleteKeyCode={null}
        >
          <MiniMap
            bgColor="var(--primary-white)"
            nodeColor={nodeColor}
            maskColor="var(--heavy-blue-20)"
            nodeStrokeColor={nodeColor}
            pannable
          />
          <Controls />
          <Background variant={BackgroundVariant.Dots} color="var(--heavy-blue-50)" gap={12} size={1} />
        </ReactFlow>
        <ConfirmationModal
          title={labels.deleteTitle}
          description={labels.deleteDescription}
          additionalText={selectedStep?.children?.length ? [labels.deleteAdditionalText] : undefined}
          visible={deleteIsOpen}
          onClose={deleteModalClose}
          onCancel={deleteModalClose}
          onConfirm={deleteModalConfirm}
          cancelButtonLabel={labels.no}
          confirmButtonLabel={labels.yes}
        />
      </div>
      <EditJourneyButtonBar />
      <EditJourneySidebar />
    </div>
  );
};
