import { t } from '../../i18n/i18n';

export default {
  all: t('Common:ALL'),
  my: t('Common:MY'),
  search: t('Common:SEARCH'),
  selected: t('Common:SELECTED'),
  confirmDelete: t('Common:CONFIRM_DELETE'),
  delete: t('Common:DELETE'),
  noResults: t('Common:NO_RESULTS_FOUND'),
  title: t('BookingTemplates:TITLE'),
  tooltip: t('BookingTemplates:TOOLTIP'),
  newBookingTemplate: t('BookingTemplates:NEW_BOOKING_TEMPLATE'),
  myTemplates: t('BookingTemplates:FILTER_MY_TEMPLATES'),
};
