import { Edge } from '@xyflow/react';
import { Journey, JourneyStep, JourneyStepType } from '../../../API';
import {
  DEFAULT_EDGE,
  dX,
  dY,
  getJourneyStepNodeHeight,
  JourneyNodes,
  JourneyStepNodeType,
} from '../../../store/journeyBuilder';

const generateCustomNodes = (
  res: JourneyNodes[],
  step: JourneyStep,
  selectedStep: JourneyStep | null,
  x: number,
  y: number,
  maxX: { [level: number]: number },
  isLastChild: boolean,
  conditionIndex: number,
  isReadOnly: boolean
) => {
  const journeyStep = selectedStep && selectedStep?.id === step.id ? selectedStep : step;
  const currentX = Object.keys(maxX).reduce((acc, level) => {
    const lvl = +level;
    if (lvl >= y) {
      acc = Math.max(acc, maxX[lvl] !== undefined ? maxX[lvl] + dX : acc);
    }
    return acc;
  }, x);
  res.push({
    id: `node_${journeyStep.id}`,
    position: { x: currentX, y },
    data: { journeyStep, isLastChild, conditionIndex, isReadOnly },
    type: 'journeyStep',
  });

  // save max value for lvl(y)
  maxX[y] = currentX;

  const nodeHeight = getJourneyStepNodeHeight(journeyStep);
  const children = journeyStep.children;

  if (children?.length) {
    let nextY = y + nodeHeight + dY;
    const multipleChildren = children.length > 1;
    const isLastChildCondition = children[children.length - 1]?.type === JourneyStepType.CONDITION;

    if (isLastChildCondition) {
      // middle plus only if the last one is condition
      res.push({
        id: `plusMid_${journeyStep.id}`,
        position: { x: currentX + 38, y: y + nodeHeight + dY },
        data: { parent: journeyStep, isReadOnly },
        type: 'plusNode',
      });
      nextY += multipleChildren ? 84 : 54;
    } else if (multipleChildren) {
      nextY += dY;
    }

    children.forEach(
      (step, index) =>
        step &&
        generateCustomNodes(
          res,
          step,
          selectedStep,
          currentX + index * dX,
          nextY,
          maxX,
          isLastChildCondition,
          step?.type === JourneyStepType.CONDITION ? index + 1 : 0,
          isReadOnly
        )
    );
  } else if (journeyStep.type !== JourneyStepType.DESTINATION) {
    res.push({
      id: `plus_${journeyStep.id}`,
      position: { x: currentX + 33, y: y + nodeHeight + dY },
      data: { parent: journeyStep, isReadOnly },
      type: 'plusNode',
    });
  }
};

export const generateNodes = (journey: Journey, selectedStep: JourneyStep | null, isReadOnly: boolean) => {
  const res: JourneyNodes[] = [];
  res.push({
    id: 'start',
    position: { x: 50, y: 50 },
    data: {},
    type: 'startNode',
  });
  if (!journey.root) {
    res.push({
      id: 'startPlus',
      position: { x: 50, y: 128 },
      data: { parent: {} as JourneyStep, isReadOnly },
      type: 'startPlusNode',
    });
  } else {
    // save max values for each lvl
    const maxX: { [level: number]: number } = {};
    generateCustomNodes(res, journey.root, selectedStep, 50, 128, maxX, false, 0, isReadOnly);
  }

  return res;
};

const generateCustomEdges = (res: Edge[], source: string, step: JourneyStep, selectedStep: JourneyStep | null) => {
  const journeyStep = selectedStep && selectedStep?.id === step.id ? selectedStep : step;
  res.push({
    ...DEFAULT_EDGE,
    id: 'edge' + Math.random() * 100,
    source: source,
    target: `node_${journeyStep.id}`,
  });

  const children = journeyStep.children;
  if (children?.length) {
    let nextSource = `node_${journeyStep.id}`;

    // if there are children and the last one is condition add plus
    if (children[children?.length - 1]?.type === JourneyStepType.CONDITION) {
      res.push({
        ...DEFAULT_EDGE,
        id: 'edge' + Math.random() * 100,
        source: `node_${journeyStep.id}`,
        target: `plusMid_${journeyStep.id}`,
      });
      nextSource = `plusMid_${journeyStep.id}`;
    }

    children.forEach((step) => step && generateCustomEdges(res, nextSource, step, selectedStep));
  } else if (journeyStep.type !== JourneyStepType.DESTINATION) {
    res.push({
      ...DEFAULT_EDGE,
      id: 'edge' + Math.random() * 100,
      source: `node_${journeyStep.id}`,
      target: `plus_${journeyStep.id}`,
    });
  }
};

export const generateEdges = (journey: Journey, selectedStep: JourneyStep | null) => {
  const res: Edge[] = [];

  if (!journey.root) {
    res.push({
      ...DEFAULT_EDGE,
      id: 'start_edge',
      source: 'start',
      target: 'startPlus',
    });
  } else {
    generateCustomEdges(res, 'start', journey.root, selectedStep);
  }

  return res;
};

export const isJourneyStepNode = (node: JourneyNodes): node is JourneyStepNodeType =>
  node && 'journeyStep' in node.data;
