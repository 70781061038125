import { IntegrationType, UpdateBookingTemplateInput } from '../../API';
import { UpsertSmartTypeFrom } from '../smartAlerts';

export type BookingTemplateOption = {
  name: string;
  id: string;
};

export enum NotificationTypes {
  CONFIRMATION = 'CONFIRMATION',
  RESCHEDULE = 'RESCHEDULE',
  REMINDER = 'REMINDER',
  CANCELATION = 'CANCELATION',
  FOLLOW_UP = 'FOLLOW_UP',
  SMS = 'SMS',
}

export enum FilterType {
  MY = 'MY',
  ALL = 'ALL',
}

export type BookingTemplateWhoOption = {
  value: string;
  label: string;
  phoneNumber: boolean;
  integrationTypes: IntegrationType[];
  teamActive?: boolean;
};

export type GetBookingTemplatesResponse = {
  bookingTemplates: UpdateBookingTemplateInput[];
};

export type SmartAlertModalRequest = { isEdit?: boolean; isReadOnly?: boolean };
export type WarningSmartAlertModalRequest = { upsertType: UpsertSmartTypeFrom };

export type AccordionIndexes = {
  what: boolean;
  who: boolean;
  where: boolean;
  when: boolean;
  how: boolean;
  alerts: boolean;
  invitee: boolean;
  after: boolean;
};
