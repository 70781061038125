import { IntegrationType, WorkspaceIntegrationType } from '../../../API';
import { GoogleAuthButton } from '../../public';
import { CodeResponse } from '@react-oauth/google';
import { useDispatch } from 'react-redux';
import { userSettingsActions } from '../../../store/userSettings';
import { ConnectIntegration } from '../connectIntegration/ConnectIntegration';
import { ThirdPartyType } from '../../../store/authentication';

interface IGoogleIntegration {
  integrationType: IntegrationType | WorkspaceIntegrationType;
  handleDisconnect: () => void;
}

export const GoogleIntegration = (props: IGoogleIntegration) => {
  const { integrationType, handleDisconnect } = props;
  const dispatch = useDispatch();

  const handleGoogleAuthSuccess = (codeResponse: CodeResponse) => {
    dispatch(
      userSettingsActions.connectExternalCalendarRequest({
        type: ThirdPartyType.GOOGLE,
        integrationType: IntegrationType.GOOGLE_MEET,
        codeResponse,
        switchAccount: false,
        login: false,
        updateIntegration: true,
      })
    );
  };

  return (
    <ConnectIntegration
      integrationType={integrationType}
      handleDisconnect={handleDisconnect}
      ConnectButton={GoogleAuthButton}
      handleAuthSuccess={handleGoogleAuthSuccess}
    />
  );
};
