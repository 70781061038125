import { t } from '../../../i18n/i18n';

export default {
  save: t('Common:SAVE'),
  cancel: t('Common:CANCEL'),
  pictureFormats: t('Common:PICTURE_FORMATS'),
  pictureSize: t('Common:PICTURE_SIZE'),
  title: t('BookingPageBrandingModal:TITLE'),
  descriptionBookingPage: t('BookingPageBrandingModal:DESCRIPTION_BOOKING_PAGE'),
  descriptionBookingTemplate: t('BookingPageBrandingModal:DESCRIPTION_BOOKING_TEMPLATE'),
  logo: t('BookingPageBrandingModal:LOGO'),
  background: t('BookingPageBrandingModal:BACKGROUND'),
  backgroundOptionBlank: t('BookingPageBrandingModal:BACKGROUND_OPTION_BLANK'),
  backgroundOptionWallpaper: t('BookingPageBrandingModal:BACKGROUND_OPTION_WALLPAPER'),
  backgroundOptionColor: t('BookingPageBrandingModal:BACKGROUND_OPTION_Color'),
  backgroundType: t('BookingPageBrandingModal:BACKGROUND_TYPE'),
  backgroundTypeTop: t('BookingPageBrandingModal:BACKGROUND_TYPE_TOP'),
  backgroundTypeFull: t('BookingPageBrandingModal:BACKGROUND_TYPE_FULL'),
  backgroundTypeLeft: t('BookingPageBrandingModal:BACKGROUND_TYPE_LEFT'),
  backgroundTypeRight: t('BookingPageBrandingModal:BACKGROUND_TYPE_RIGHT'),
  backgroundColor: t('BookingPageBrandingModal:BACKGROUND_COLOR'),
  mainColor: t('BookingPageBrandingModal:MAIN_COLOR'),
  fontColor: t('BookingPageBrandingModal:FONT_COLOR'),
  customCss: t('BookingPageBrandingModal:CUSTOM_CSS'),
  customCssPlaceholder: t('BookingPageBrandingModal:CUSTOM_CSS_PLACEHOLDER'),
  footerHtml: t('BookingPageBrandingModal:FOOTER_HTML'),
  footerHtmlPlaceholder: t('BookingPageBrandingModal:FOOTER_HTML_PLACEHOLDER'),
  resetDefaultStyles: t('BookingPageBrandingModal:RESET_DEFAULT_STYLES'),
  stepJourney: t('BookingPageBrandingModal:STEP_JOURNEY'),
  stepBookingPage: t('BookingPageBrandingModal:STEP_BOOKING_PAGE'),
  stepBookingForm: t('BookingPageBrandingModal:STEP_BOOKING_FORM'),
  stepBooked: t('BookingPageBrandingModal:STEP_BOOKED'),
  stepRescheduled: t('BookingPageBrandingModal:STEP_RESCHEDULED'),
  stepCanceled: t('BookingPageBrandingModal:STEP_CANCELED'),
  stepCancel: t('BookingPageBrandingModal:STEP_CANCEL'),
};
