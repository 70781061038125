import { UpdateGroupBookingPageInput } from '../../API';
import { CLONE_NAME_POSTFIX } from '../../types/constants';
import { GetGroupBookingPagePayload, GetPublicGroupBookingPagePayload } from './types';
import { AppThunk } from '../rootStore';
import { groupCloneModalActions } from './modal';
import { groupBookingPagesSelectors } from './selectors';

export enum groupBookingPagesActionTypes {
  GET_GROUP_BOOKING_PAGES_REQUEST = 'groupBookingPages/GET_GROUP_BOOKING_PAGES_REQUEST',
  GET_GROUP_BOOKING_PAGES_SUCCESS = 'groupBookingPages/GET_GROUP_BOOKING_PAGES_SUCCESS',
  GET_GROUP_BOOKING_PAGES_FAIL = 'groupBookingPages/GET_GROUP_BOOKING_PAGES_FAIL',
  GET_GROUP_BOOKING_PAGE_REQUEST = 'groupBookingPages/GET_GROUP_BOOKING_PAGE_REQUEST',
  GET_PUBLIC_GROUP_BOOKING_PAGE_REQUEST = 'groupBookingPages/GET_PUBLIC_GROUP_BOOKING_PAGE_REQUEST',
  GET_GROUP_BOOKING_PAGE_SUCCESS = 'groupBookingPages/GET_GROUP_BOOKING_PAGE_SUCCESS',
  GET_GROUP_BOOKING_PAGE_FAIL = 'groupBookingPages/GET_GROUP_BOOKING_PAGE_FAIL',
  SET_GROUP_BOOKING_PAGE = 'groupBookingPages/SET_GROUP_BOOKING_PAGE',
  RESET_GROUP_BOOKING_PAGE = 'groupBookingPages/RESET_GROUP_BOOKING_PAGE',
  UPDATE_GROUP_BOOKING_PAGE = 'groupBookingPages/UPDATE_GROUP_BOOKING_PAGE',
  UPSERT_GROUP_BOOKING_PAGE_REQUEST = 'groupBookingPages/UPSERT_GROUP_BOOKING_PAGE_REQUEST',
  UPSERT_GROUP_BOOKING_PAGE_SUCCESS = 'groupBookingPages/UPSERT_GROUP_BOOKING_PAGE_SUCCESS',
  UPSERT_GROUP_BOOKING_PAGE_FAIL = 'groupBookingPages/UPSERT_GROUP_BOOKING_PAGE_FAIL',
  ACTIVATE_GROUP_BOOKING_PAGE_REQUEST = 'groupBookingPages/ACTIVATE_GROUP_BOOKING_PAGE_REQUEST',
  ACTIVATE_GROUP_BOOKING_PAGE_SUCCESS = 'groupBookingPages/ACTIVATE_GROUP_BOOKING_PAGE_SUCCESS',
  ACTIVATE_GROUP_BOOKING_PAGE_FAIL = 'groupBookingPages/ACTIVATE_GROUP_BOOKING_PAGE_FAIL',
  DELETE_GROUP_BOOKING_PAGE_REQUEST = 'groupBookingPages/DELETE_GROUP_BOOKING_PAGE_REQUEST',
  DELETE_GROUP_BOOKING_PAGE_SUCCESS = 'groupBookingPages/DELETE_GROUP_BOOKING_PAGE_SUCCESS',
  DELETE_GROUP_BOOKING_PAGE_FAIL = 'groupBookingPages/DELETE_GROUP_BOOKING_PAGE_FAIL',
  SET_CLONE_NAME = 'groupBookingPages/SET_CLONE_NAME',
  CLONE_GROUP_BOOKING_PAGE_REQUEST = 'groupBookingPages/CLONE_GROUP_BOOKING_PAGE_REQUEST',
  CLONE_GROUP_BOOKING_PAGE_SUCCESS = 'groupBookingPages/CLONE_GROUP_BOOKING_PAGE_SUCCESS',
  CLONE_GROUP_BOOKING_PAGE_FAIL = 'groupBookingPages/CLONE_GROUP_BOOKING_PAGE_FAIL',
}

export type GroupBookingPagesAction =
  | { type: groupBookingPagesActionTypes.GET_GROUP_BOOKING_PAGES_REQUEST }
  | { type: groupBookingPagesActionTypes.GET_GROUP_BOOKING_PAGES_SUCCESS; payload: UpdateGroupBookingPageInput[] }
  | { type: groupBookingPagesActionTypes.GET_GROUP_BOOKING_PAGES_FAIL; error: unknown }
  | { type: groupBookingPagesActionTypes.GET_GROUP_BOOKING_PAGE_REQUEST; payload: GetGroupBookingPagePayload }
  | {
      type: groupBookingPagesActionTypes.GET_PUBLIC_GROUP_BOOKING_PAGE_REQUEST;
      payload: GetPublicGroupBookingPagePayload;
    }
  | { type: groupBookingPagesActionTypes.GET_GROUP_BOOKING_PAGE_SUCCESS; payload: UpdateGroupBookingPageInput }
  | { type: groupBookingPagesActionTypes.GET_GROUP_BOOKING_PAGE_FAIL; error: unknown }
  | { type: groupBookingPagesActionTypes.SET_GROUP_BOOKING_PAGE; payload: UpdateGroupBookingPageInput }
  | { type: groupBookingPagesActionTypes.RESET_GROUP_BOOKING_PAGE }
  | { type: groupBookingPagesActionTypes.UPDATE_GROUP_BOOKING_PAGE; payload: Partial<UpdateGroupBookingPageInput> }
  | { type: groupBookingPagesActionTypes.UPSERT_GROUP_BOOKING_PAGE_REQUEST }
  | { type: groupBookingPagesActionTypes.UPSERT_GROUP_BOOKING_PAGE_SUCCESS }
  | { type: groupBookingPagesActionTypes.UPSERT_GROUP_BOOKING_PAGE_FAIL; error: unknown }
  | { type: groupBookingPagesActionTypes.ACTIVATE_GROUP_BOOKING_PAGE_REQUEST }
  | { type: groupBookingPagesActionTypes.ACTIVATE_GROUP_BOOKING_PAGE_SUCCESS }
  | { type: groupBookingPagesActionTypes.ACTIVATE_GROUP_BOOKING_PAGE_FAIL; error: unknown }
  | { type: groupBookingPagesActionTypes.DELETE_GROUP_BOOKING_PAGE_REQUEST; payload: string[] }
  | { type: groupBookingPagesActionTypes.DELETE_GROUP_BOOKING_PAGE_SUCCESS }
  | { type: groupBookingPagesActionTypes.DELETE_GROUP_BOOKING_PAGE_FAIL; error: unknown }
  | { type: groupBookingPagesActionTypes.SET_CLONE_NAME; payload: string }
  | { type: groupBookingPagesActionTypes.CLONE_GROUP_BOOKING_PAGE_REQUEST }
  | { type: groupBookingPagesActionTypes.CLONE_GROUP_BOOKING_PAGE_SUCCESS }
  | { type: groupBookingPagesActionTypes.CLONE_GROUP_BOOKING_PAGE_FAIL; error: unknown };

const getGroupBookingPagesRequest = (): GroupBookingPagesAction => ({
  type: groupBookingPagesActionTypes.GET_GROUP_BOOKING_PAGES_REQUEST,
});
const getGroupBookingPagesSuccess = (payload: UpdateGroupBookingPageInput[]): GroupBookingPagesAction => ({
  type: groupBookingPagesActionTypes.GET_GROUP_BOOKING_PAGES_SUCCESS,
  payload,
});
const getGroupBookingPagesFail = (error: unknown): GroupBookingPagesAction => ({
  type: groupBookingPagesActionTypes.GET_GROUP_BOOKING_PAGES_FAIL,
  error,
});

const getGroupBookingPageRequest = (payload: GetGroupBookingPagePayload): GroupBookingPagesAction => ({
  type: groupBookingPagesActionTypes.GET_GROUP_BOOKING_PAGE_REQUEST,
  payload,
});
const getPublicGroupBookingPageRequest = (payload: GetPublicGroupBookingPagePayload): GroupBookingPagesAction => ({
  type: groupBookingPagesActionTypes.GET_PUBLIC_GROUP_BOOKING_PAGE_REQUEST,
  payload,
});
const getGroupBookingPageSuccess = (payload: UpdateGroupBookingPageInput): GroupBookingPagesAction => ({
  type: groupBookingPagesActionTypes.GET_GROUP_BOOKING_PAGE_SUCCESS,
  payload,
});
const getGroupBookingPageFail = (error: unknown): GroupBookingPagesAction => ({
  type: groupBookingPagesActionTypes.GET_GROUP_BOOKING_PAGE_FAIL,
  error,
});

const setGroupBookingPage = (payload: UpdateGroupBookingPageInput): GroupBookingPagesAction => ({
  type: groupBookingPagesActionTypes.SET_GROUP_BOOKING_PAGE,
  payload,
});
const resetGroupBookingPage = (): GroupBookingPagesAction => ({
  type: groupBookingPagesActionTypes.RESET_GROUP_BOOKING_PAGE,
});
const updateGroupBookingPage = (payload: Partial<UpdateGroupBookingPageInput>): GroupBookingPagesAction => ({
  type: groupBookingPagesActionTypes.UPDATE_GROUP_BOOKING_PAGE,
  payload,
});

const upsertGroupBookingPageRequest = (): GroupBookingPagesAction => ({
  type: groupBookingPagesActionTypes.UPSERT_GROUP_BOOKING_PAGE_REQUEST,
});
const upsertGroupBookingPageSuccess = (): GroupBookingPagesAction => ({
  type: groupBookingPagesActionTypes.UPSERT_GROUP_BOOKING_PAGE_SUCCESS,
});
const upsertGroupBookingPageFail = (error: unknown): GroupBookingPagesAction => ({
  type: groupBookingPagesActionTypes.UPSERT_GROUP_BOOKING_PAGE_FAIL,
  error,
});

const activateGroupBookingPageRequest = (): GroupBookingPagesAction => ({
  type: groupBookingPagesActionTypes.ACTIVATE_GROUP_BOOKING_PAGE_REQUEST,
});
const activateGroupBookingPageSuccess = (): GroupBookingPagesAction => ({
  type: groupBookingPagesActionTypes.ACTIVATE_GROUP_BOOKING_PAGE_SUCCESS,
});
const activateGroupBookingPageFail = (error: unknown): GroupBookingPagesAction => ({
  type: groupBookingPagesActionTypes.ACTIVATE_GROUP_BOOKING_PAGE_FAIL,
  error,
});

const deleteGroupBookingPageRequest = (payload: string[]): GroupBookingPagesAction => ({
  type: groupBookingPagesActionTypes.DELETE_GROUP_BOOKING_PAGE_REQUEST,
  payload,
});
const deleteGroupBookingPageSuccess = (): GroupBookingPagesAction => ({
  type: groupBookingPagesActionTypes.DELETE_GROUP_BOOKING_PAGE_SUCCESS,
});
const deleteGroupBookingPageFail = (error: unknown): GroupBookingPagesAction => ({
  type: groupBookingPagesActionTypes.DELETE_GROUP_BOOKING_PAGE_FAIL,
  error,
});

const setCloneName = (payload: string): GroupBookingPagesAction => ({
  type: groupBookingPagesActionTypes.SET_CLONE_NAME,
  payload,
});
const cloneGroupBookingPageRequest = (): GroupBookingPagesAction => ({
  type: groupBookingPagesActionTypes.CLONE_GROUP_BOOKING_PAGE_REQUEST,
});
const cloneGroupBookingPageSuccess = (): GroupBookingPagesAction => ({
  type: groupBookingPagesActionTypes.CLONE_GROUP_BOOKING_PAGE_SUCCESS,
});
const cloneGroupBookingPageFail = (error: unknown): GroupBookingPagesAction => ({
  type: groupBookingPagesActionTypes.CLONE_GROUP_BOOKING_PAGE_FAIL,
  error,
});
const cloneGroupBookingPageThunk = (): AppThunk => (dispatch, getState) => {
  const state = getState();
  const name = groupBookingPagesSelectors.selectGroupBookingPageName(state) + CLONE_NAME_POSTFIX;
  dispatch(setCloneName(name));
  dispatch(groupCloneModalActions.openModal());
};

export const groupBookingPagesActions = {
  getGroupBookingPagesRequest,
  getGroupBookingPagesSuccess,
  getGroupBookingPagesFail,

  getGroupBookingPageRequest,
  getPublicGroupBookingPageRequest,
  getGroupBookingPageSuccess,
  getGroupBookingPageFail,

  setGroupBookingPage,
  resetGroupBookingPage,
  updateGroupBookingPage,

  upsertGroupBookingPageRequest,
  upsertGroupBookingPageSuccess,
  upsertGroupBookingPageFail,

  activateGroupBookingPageRequest,
  activateGroupBookingPageSuccess,
  activateGroupBookingPageFail,

  deleteGroupBookingPageRequest,
  deleteGroupBookingPageSuccess,
  deleteGroupBookingPageFail,

  setCloneName,
  cloneGroupBookingPageRequest,
  cloneGroupBookingPageSuccess,
  cloneGroupBookingPageFail,
  cloneGroupBookingPageThunk,
};
