import { handleAPIRequest } from '../utils/reduxUtils';
import { API_PUBLIC } from '../../types/constants';
import {
  GetJourneyEventsByIdResponse,
  getJourneyEventsByJourneyIdResponse,
  GetJourneyByIdResponse,
  GetJourneysByWorkspaceIdResponse,
} from './types';
import { Journey, JourneyEvent } from '../../API';

// ***
// When you change the requests do not forget to specify new data
// in docs/Endpoints.md
// ***

export const getJourneysByWorkspaceId = async (workspaceId: string): Promise<GetJourneysByWorkspaceIdResponse> => {
  return handleAPIRequest<GetJourneysByWorkspaceIdResponse>(`${API_PUBLIC}/getJourneysByWorkspaceId`, { workspaceId });
};

export const getJourneyById = async (id: string): Promise<GetJourneyByIdResponse> => {
  return handleAPIRequest<GetJourneyByIdResponse>(`${API_PUBLIC}/getJourneyById `, { id });
};

export const upsertJourney = async (journey: Journey): Promise<GetJourneyByIdResponse> => {
  return handleAPIRequest<GetJourneyByIdResponse>(`${API_PUBLIC}/upsertJourney `, { journey });
};

export const deleteJourneys = async (ids: string[]): Promise<void> => {
  return handleAPIRequest<void>(`${API_PUBLIC}/deleteJourneys `, { ids });
};

export const getJourneyEventsByJourneyId = async (journeyId: string): Promise<getJourneyEventsByJourneyIdResponse> => {
  return handleAPIRequest<getJourneyEventsByJourneyIdResponse>(`${API_PUBLIC}/getJourneyEventsByJourneyId `, {
    journeyId,
  });
};

export const getJourneyEventById = async (id: string): Promise<GetJourneyEventsByIdResponse> => {
  return handleAPIRequest<GetJourneyEventsByIdResponse>(`${API_PUBLIC}/getJourneyEventById `, { id });
};

export const upsertJourneyEvent = async (journeyEvent: JourneyEvent): Promise<GetJourneyEventsByIdResponse> => {
  return handleAPIRequest<GetJourneyEventsByIdResponse>(`${API_PUBLIC}/upsertJourneyEvent  `, { journeyEvent });
};

export const deleteJourneyEvent = async (journeyEventId: string): Promise<void> => {
  handleAPIRequest<void>(`${API_PUBLIC}/deleteJourneyEvent  `, { journeyEventId });
};
