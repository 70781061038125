import { PermissionAction, Role } from '../../API';
import { PERMISSION_LIST } from './constants';
import { Permission } from './types';

export const generatePermissionsListOnUncheck = (permission: Permission, action: PermissionAction, state: Role) => {
  const permissionsList = state[permission];
  const updatedPermissionList =
    action !== PermissionAction.READ && permissionsList
      ? permissionsList.filter((permission) => permission !== action)
      : [];
  const workspacePermissionList =
    state.workspaceAdminActions && action !== PermissionAction.READ
      ? state.workspaceAdminActions.filter((permission) => permission !== action)
      : [];
  let allPermissions = {};
  if (permission === 'myBookingPages') {
    const allBookingPages = state.allBookingPages;
    if (allBookingPages?.includes(action)) {
      allPermissions = { allBookingPages: allBookingPages.filter((item) => item !== action) };
    }
  }
  if (permission === 'smartAlerts') {
    const smartAlertTemplates = state.smartAlertTemplates;
    if (smartAlertTemplates?.includes(action)) {
      allPermissions = { smartAlertTemplates: smartAlertTemplates.filter((item) => item !== action) };
    }
  }
  return {
    [permission]: updatedPermissionList,
    workspaceAdminActions: workspacePermissionList,
    isAccessToAllWorkspaces: false,
    ...allPermissions,
  };
};

export const generatePermissionsListOnCheck = (permission: Permission, action: PermissionAction, state: Role) => {
  const permissionsList = state[permission];
  const updatedPermissionList = [...(permissionsList ? permissionsList : []), action];
  if (!updatedPermissionList.includes(PermissionAction.READ)) {
    updatedPermissionList.push(PermissionAction.READ);
  }
  let allPermissions = {};
  if (permission === 'workspaceAdminActions') {
    allPermissions = PERMISSION_LIST.reduce((prev, permission) => {
      const list = state[permission];
      const updatedList = list ? (list.includes(action) ? list : [...list, action]) : [action];
      if (!updatedList.includes(PermissionAction.READ)) {
        updatedList.push(PermissionAction.READ);
      }
      return { ...prev, [permission]: updatedList };
    }, {});
  } else if (permission === 'allBookingPages') {
    const myBookingPages = state.myBookingPages;
    if (!myBookingPages?.includes(action)) {
      allPermissions = { myBookingPages: [...(myBookingPages ? myBookingPages : []), action] };
    }
  } else if (permission === 'smartAlertTemplates') {
    const smartAlerts = state.smartAlerts;
    if (!smartAlerts?.includes(action)) {
      allPermissions = { smartAlerts: [...(smartAlerts ? smartAlerts : []), action] };
    }
  }
  return { [permission]: updatedPermissionList, ...allPermissions };
};
