import { t } from '../../../i18n/i18n';

export default {
  back: t('Common:BACK'),
  next: t('Common:NEXT'),
  host: t('PublicBookingPage:HOST'),
  hostPhone: t('PublicBookingPage:HOST_PHONE'),
  edit: t('PublicBookingPage:EDIT_BUTTON'),
  loadingLabel1: t('PublicBookingPage:LOADING_LABEL_1'),
  loadingLabel2: t('PublicBookingPage:LOADING_LABEL_2'),
  loadingLabel3: t('PublicBookingPage:LOADING_LABEL_3'),
};
