import { t } from '../../i18n/i18n';

export default {
  bookMeetingDesc: t('LicenseExpired:BOOK_MEETING_DESCRIPTION'),
  bookMeetingTitle: t('LicenseExpired:BOOK_MEETING_TITLE'),
  callDescPart1: t('LicenseExpired:CALL_DESCRIPTION_PART1'),
  callDescPart2: t('LicenseExpired:CALL_DESCRIPTION_PART2'),
  callTitle: t('LicenseExpired:CALL_TITLE'),
  chatDesc: t('LicenseExpired:CHAT_DESCRIPTION'),
  chatTitle: t('LicenseExpired:CHAT_TITLE'),
  contactTitle: t('LicenseExpired:CONTACT_TITLE'),
  descAdminTrial: t('LicenseExpired:DESCRIPTION_ADMIN_TRIAL'),
  descAdminPaid: t('LicenseExpired:DESCRIPTION_ADMIN_PAID'),
  descPart1Trial: t('LicenseExpired:DESCRIPTION_PART1_TRIAL'),
  descPart1Paid: t('LicenseExpired:DESCRIPTION_PART1_PAID'),
  descPart2: t('LicenseExpired:DESCRIPTION_PART2'),
  emailDesc: t('LicenseExpired:EMAIL_DESCRIPTION'),
  emailTitle: t('LicenseExpired:EMAIL_TITLE'),
  renewLicenses: t('LicenseExpired:RENEW_LICENSES'),
  title: t('LicenseExpired:TITLE'),
  titleTrial: t('LicenseExpired:TITLE_TRIAL'),
  upgradeNow: t('LicenseExpired:UPGRADE_NOW'),
  viewPricingPage: t('LicenseExpired:VIEW_PRICING_PAGE'),
};
