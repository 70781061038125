import { UpdateBookingPageInput } from '../../API';
import { API_PUBLIC } from '../../types/constants';
import { handleAPIRequest } from '../utils/reduxUtils';
import { GetBookingPagesResponse, PostBookingPageResponse } from './types';

// ***
// When you change the requests do not forget to specify new data
// in docs/Endpoints.md
// ***

export const getBookingPages = (workspaceId: string) => {
  return handleAPIRequest<GetBookingPagesResponse>(`${API_PUBLIC}/getBookingPage`, { workspaceId });
};

export const getBookingPage = async (bookingPageId: string): Promise<GetBookingPagesResponse> => {
  return handleAPIRequest<GetBookingPagesResponse>(`${API_PUBLIC}/getBookingPage`, { bookingPageId });
};

export const postBookingPage = async (bookingPage: UpdateBookingPageInput): Promise<PostBookingPageResponse> => {
  return handleAPIRequest<PostBookingPageResponse>(`${API_PUBLIC}/upsertBookingPage`, { bookingPage });
};

export const deleteBookingPages = async (bookingPagesIds: string[], workspaceId: string) => {
  return handleAPIRequest<void>(`${API_PUBLIC}/deleteBookingPage`, {
    data: bookingPagesIds.map((id) => ({ id, workspaceId })),
  });
};
