import { Journey, JourneyCondition, JourneyDestination, JourneyPage, JourneyStep } from '../../API';
import { CLONE_NAME_POSTFIX } from '../availability';
import { AppThunk } from '../rootStore';
import { cloneJourneysModalActions } from './modal';
import { journeysSelectors } from './selectors';
import {
  AddJourneyStepPayload,
  EditJourneyStepPayload,
  FieldsAndCards,
  GetJourneyByIdResponse,
  GetJourneyEventsByJourneyIdResponse,
  GetJourneysByWorkspaceIdResponse,
  JourneyEventFilter,
  JourneyFilter,
} from './types';
import { getAllCards, getAllFields } from './utils';

export enum JourneysActionTypes {
  GET_JOURNEYS_REQUEST = 'journeys/GET_JOURNEYS_REQUEST',
  GET_JOURNEYS_SUCCESS = 'journeys/GET_JOURNEYS_SUCCESS',
  GET_JOURNEYS_FAIL = 'journeys/GET_JOURNEYS_FAIL',
  SET_JOURNEY = 'journeys/SET_JOURNEY',
  SET_FILTER = 'journeys/SET_FILTER',
  SELECT_JOURNEYS = 'journeys/SELECT_JOURNEYS',
  CLONE_JOURNEY_REQUEST = 'journeys/CLONE_JOURNEY_REQUEST',
  CLONE_JOURNEY_SUCCESS = 'journeys/CLONE_JOURNEY_SUCCESS',
  CLONE_JOURNEY_FAIL = 'journeys/CLONE_JOURNEY_FAIL',
  SET_CLONE_NAME = 'journeys/SET_CLONE_NAME',
  DELETE_JOURNEYS_REQUEST = 'journeys/DELETE_JOURNEYS_REQUEST',
  DELETE_JOURNEYS_SUCCESS = 'journeys/DELETE_JOURNEYS_SUCCESS',
  DELETE_JOURNEYS_FAIL = 'journeys/DELETE_JOURNEYS_FAIL',
  DEACTIVATE_JOURNEY_REQUEST = 'journeys/DEACTIVATE_JOURNEY_REQUEST',
  DEACTIVATE_JOURNEY_SUCCESS = 'journeys/DEACTIVATE_JOURNEY_SUCCESS',
  DEACTIVATE_JOURNEY_FAIL = 'journeys/DEACTIVATE_JOURNEY_FAIL',
  GET_JOURNEY_REQUEST = 'journeys/GET_JOURNEY_REQUEST',
  GET_JOURNEY_SUCCESS = 'journeys/GET_JOURNEY_SUCCESS',
  GET_JOURNEY_FAIL = 'journeys/GET_JOURNEY_FAIL',
  UPDATE_JOURNEY = 'journeys/UPDATE_JOURNEY',
  UPSERT_JOURNEY_REQUEST = 'journeys/UPSERT_JOURNEY_REQUEST',
  UPSERT_JOURNEY_SUCCESS = 'journeys/UPSERT_JOURNEY_SUCCESS',
  UPSERT_JOURNEY_FAIL = 'journeys/UPSERT_JOURNEY_FAIL',
  ADD_JOURNEY_STEP = 'journeys/ADD_JOURNEY_STEP',
  EDIT_JOURNEY_STEP = 'journeys/EDIT_JOURNEY_STEP',
  SET_JOURNEY_STEP = 'journeys/SET_JOURNEY_STEP',
  RESET_JOURNEY_STEP = 'journeys/RESET_JOURNEY_STEP',
  COPY_JOURNEY_STEP = 'journeys/COPY_JOURNEY_STEP',
  REMOVE_JOURNEY_STEP = 'journeys/REMOVE_JOURNEY_STEP',
  SET_EDIT_SIDEBAR = 'journeys/SET_EDIT_SIDEBAR',
  UPDATE_JOURNEY_STEP = 'journeys/UPDATE_JOURNEY_STEP',
  UPDATE_PAGE_STEP = 'journeys/UPDATE_PAGE_STEP',
  UPDATE_CONDITION_STEP = 'journeys/UPDATE_CONDITION_STEP',
  UPDATE_DESTINATION_STEP = 'journeys/UPDATE_DESTINATION_STEP',
  SET_FIELDS_AND_CARDS = 'journeys/SET_FIELDS_AND_CARDS',
  GET_JOURNEY_EVENTS_REQUEST = 'journeys/GET_JOURNEY_EVENTS_REQUEST',
  GET_JOURNEY_EVENTS_SUCCESS = 'journeys/GET_JOURNEY_EVENTS_SUCCESS',
  GET_JOURNEY_EVENTS_FAIL = 'journeys/GET_JOURNEY_EVENTS_FAIL',
  SET_EVENT_FILTER = 'journeys/SET_EVENT_FILTER',
}

export type JourneysAction =
  | { type: JourneysActionTypes.GET_JOURNEYS_REQUEST }
  | { type: JourneysActionTypes.GET_JOURNEYS_SUCCESS; payload: GetJourneysByWorkspaceIdResponse }
  | { type: JourneysActionTypes.GET_JOURNEYS_FAIL; error: unknown }
  | { type: JourneysActionTypes.SET_JOURNEY; payload: Journey }
  | { type: JourneysActionTypes.SET_FILTER; payload: Partial<JourneyFilter> }
  | { type: JourneysActionTypes.SELECT_JOURNEYS; payload: string[] }
  | { type: JourneysActionTypes.CLONE_JOURNEY_REQUEST }
  | { type: JourneysActionTypes.CLONE_JOURNEY_SUCCESS }
  | { type: JourneysActionTypes.CLONE_JOURNEY_FAIL; error: unknown }
  | { type: JourneysActionTypes.SET_CLONE_NAME; payload: string }
  | { type: JourneysActionTypes.DELETE_JOURNEYS_REQUEST; payload: string[] }
  | { type: JourneysActionTypes.DELETE_JOURNEYS_SUCCESS }
  | { type: JourneysActionTypes.DELETE_JOURNEYS_FAIL; error: unknown }
  | { type: JourneysActionTypes.DEACTIVATE_JOURNEY_REQUEST }
  | { type: JourneysActionTypes.DEACTIVATE_JOURNEY_SUCCESS }
  | { type: JourneysActionTypes.DEACTIVATE_JOURNEY_FAIL; error: unknown }
  | { type: JourneysActionTypes.SET_JOURNEY; payload: Journey }
  | { type: JourneysActionTypes.GET_JOURNEY_REQUEST; payload: string }
  | { type: JourneysActionTypes.GET_JOURNEY_SUCCESS; payload: GetJourneyByIdResponse }
  | { type: JourneysActionTypes.GET_JOURNEY_FAIL; error: unknown }
  | { type: JourneysActionTypes.UPDATE_JOURNEY; payload: Partial<Journey> }
  | { type: JourneysActionTypes.UPSERT_JOURNEY_REQUEST }
  | { type: JourneysActionTypes.UPSERT_JOURNEY_SUCCESS }
  | { type: JourneysActionTypes.UPSERT_JOURNEY_FAIL; error: unknown }
  | { type: JourneysActionTypes.EDIT_JOURNEY_STEP; payload: EditJourneyStepPayload }
  | { type: JourneysActionTypes.ADD_JOURNEY_STEP; payload: AddJourneyStepPayload }
  | { type: JourneysActionTypes.SET_JOURNEY_STEP; payload: JourneyStep }
  | { type: JourneysActionTypes.RESET_JOURNEY_STEP }
  | { type: JourneysActionTypes.COPY_JOURNEY_STEP; payload: JourneyStep }
  | { type: JourneysActionTypes.REMOVE_JOURNEY_STEP; payload: string }
  | { type: JourneysActionTypes.SET_EDIT_SIDEBAR; payload: boolean }
  | { type: JourneysActionTypes.UPDATE_JOURNEY_STEP; payload: Partial<JourneyStep> }
  | { type: JourneysActionTypes.UPDATE_PAGE_STEP; payload: Partial<JourneyPage> }
  | { type: JourneysActionTypes.UPDATE_CONDITION_STEP; payload: Partial<JourneyCondition> }
  | { type: JourneysActionTypes.UPDATE_DESTINATION_STEP; payload: Partial<JourneyDestination> }
  | { type: JourneysActionTypes.SET_FIELDS_AND_CARDS; payload: FieldsAndCards }
  | { type: JourneysActionTypes.GET_JOURNEY_EVENTS_REQUEST }
  | { type: JourneysActionTypes.GET_JOURNEY_EVENTS_SUCCESS; payload: GetJourneyEventsByJourneyIdResponse }
  | { type: JourneysActionTypes.GET_JOURNEY_EVENTS_FAIL; error: unknown }
  | { type: JourneysActionTypes.SET_EVENT_FILTER; payload: Partial<JourneyEventFilter> };

const getJourneysRequest = (): JourneysAction => ({ type: JourneysActionTypes.GET_JOURNEYS_REQUEST });
const getJourneysSuccess = (payload: GetJourneysByWorkspaceIdResponse): JourneysAction => ({
  type: JourneysActionTypes.GET_JOURNEYS_SUCCESS,
  payload,
});
const getJourneysFail = (error: unknown): JourneysAction => ({
  type: JourneysActionTypes.GET_JOURNEYS_FAIL,
  error,
});

const setJourney = (payload: Journey): JourneysAction => ({
  type: JourneysActionTypes.SET_JOURNEY,
  payload,
});

const setFilter = (payload: Partial<JourneyFilter>): JourneysAction => ({
  type: JourneysActionTypes.SET_FILTER,
  payload,
});

const selectJourneys = (payload: string[]): JourneysAction => ({
  type: JourneysActionTypes.SELECT_JOURNEYS,
  payload,
});

const cloneJourneyRequest = (): JourneysAction => ({
  type: JourneysActionTypes.CLONE_JOURNEY_REQUEST,
});
const cloneJourneySuccess = (): JourneysAction => ({
  type: JourneysActionTypes.CLONE_JOURNEY_SUCCESS,
});
const cloneJourneyFail = (error: unknown): JourneysAction => ({
  type: JourneysActionTypes.CLONE_JOURNEY_FAIL,
  error,
});
const setCloneName = (payload: string): JourneysAction => ({
  type: JourneysActionTypes.SET_CLONE_NAME,
  payload,
});
const cloneJourneyThunk = (): AppThunk => (dispatch, getState) => {
  const state = getState();
  const cloneName = journeysSelectors.selectJourneyName(state) + CLONE_NAME_POSTFIX;
  dispatch(setCloneName(cloneName));
  dispatch(cloneJourneysModalActions.openModal());
};

const deleteJourneysRequest = (payload: string[]): JourneysAction => ({
  type: JourneysActionTypes.DELETE_JOURNEYS_REQUEST,
  payload,
});
const deleteJourneysSuccess = (): JourneysAction => ({
  type: JourneysActionTypes.DELETE_JOURNEYS_SUCCESS,
});
const deleteJourneysFail = (error: unknown): JourneysAction => ({
  type: JourneysActionTypes.DELETE_JOURNEYS_FAIL,
  error,
});

const deactivateJourneyRequest = (): JourneysAction => ({
  type: JourneysActionTypes.DEACTIVATE_JOURNEY_REQUEST,
});
const deactivateJourneySuccess = (): JourneysAction => ({
  type: JourneysActionTypes.DEACTIVATE_JOURNEY_SUCCESS,
});
const deactivateJourneyFail = (error: unknown): JourneysAction => ({
  type: JourneysActionTypes.DEACTIVATE_JOURNEY_FAIL,
  error,
});

const getJourneyRequest = (payload: string): JourneysAction => ({
  type: JourneysActionTypes.GET_JOURNEY_REQUEST,
  payload,
});
const getJourneySuccess = (payload: GetJourneyByIdResponse): JourneysAction => ({
  type: JourneysActionTypes.GET_JOURNEY_SUCCESS,
  payload,
});
const getJourneyFail = (error: unknown): JourneysAction => ({
  type: JourneysActionTypes.GET_JOURNEY_FAIL,
  error,
});

const updateJourney = (payload: Partial<Journey>): JourneysAction => ({
  type: JourneysActionTypes.UPDATE_JOURNEY,
  payload,
});

const upsertJourneyRequest = (): JourneysAction => ({
  type: JourneysActionTypes.UPSERT_JOURNEY_REQUEST,
});
const upsertJourneySuccess = (): JourneysAction => ({
  type: JourneysActionTypes.UPSERT_JOURNEY_SUCCESS,
});
const upsertJourneyFail = (error: unknown): JourneysAction => ({
  type: JourneysActionTypes.UPSERT_JOURNEY_FAIL,
  error,
});

const addJourneyStep = (payload: AddJourneyStepPayload): JourneysAction => ({
  type: JourneysActionTypes.ADD_JOURNEY_STEP,
  payload,
});
const editJourneyStep = (payload: EditJourneyStepPayload): JourneysAction => ({
  type: JourneysActionTypes.EDIT_JOURNEY_STEP,
  payload,
});
const setJourneyStep = (payload: JourneyStep): JourneysAction => ({
  type: JourneysActionTypes.SET_JOURNEY_STEP,
  payload,
});
const resetJourneyStep = (): JourneysAction => ({
  type: JourneysActionTypes.RESET_JOURNEY_STEP,
});
const copyJourneyStep = (payload: JourneyStep): JourneysAction => ({
  type: JourneysActionTypes.COPY_JOURNEY_STEP,
  payload,
});
const removeJourneyStep = (payload: string): JourneysAction => ({
  type: JourneysActionTypes.REMOVE_JOURNEY_STEP,
  payload,
});

const setEditSidebar = (payload: boolean): JourneysAction => ({
  type: JourneysActionTypes.SET_EDIT_SIDEBAR,
  payload,
});

const updateJourneyStep = (payload: Partial<JourneyStep>): JourneysAction => ({
  type: JourneysActionTypes.UPDATE_JOURNEY_STEP,
  payload,
});
const updatePageStep = (payload: Partial<JourneyPage>): JourneysAction => ({
  type: JourneysActionTypes.UPDATE_PAGE_STEP,
  payload,
});
const updateConditionStep = (payload: Partial<JourneyCondition>): JourneysAction => ({
  type: JourneysActionTypes.UPDATE_CONDITION_STEP,
  payload,
});
const updateDestinationStep = (payload: Partial<JourneyDestination>): JourneysAction => ({
  type: JourneysActionTypes.UPDATE_DESTINATION_STEP,
  payload,
});

const setFieldsAndCards = (payload: FieldsAndCards): JourneysAction => ({
  type: JourneysActionTypes.SET_FIELDS_AND_CARDS,
  payload,
});
const regenerateFieldsAndCardsThunk = (): AppThunk => (dispatch, getState) => {
  const state = getState();
  const journey = journeysSelectors.selectJourney(state);
  const fields = getAllFields(journey.root);
  const cards = getAllCards(journey.root);
  dispatch(setFieldsAndCards({ fields, cards }));
};

const getJourneyEventsRequest = (): JourneysAction => ({ type: JourneysActionTypes.GET_JOURNEY_EVENTS_REQUEST });
const getJourneyEventsSuccess = (payload: GetJourneyEventsByJourneyIdResponse): JourneysAction => ({
  type: JourneysActionTypes.GET_JOURNEY_EVENTS_SUCCESS,
  payload,
});
const getJourneyEventsFail = (error: unknown): JourneysAction => ({
  type: JourneysActionTypes.GET_JOURNEY_EVENTS_FAIL,
  error,
});

const setEventFilter = (payload: Partial<JourneyEventFilter>): JourneysAction => ({
  type: JourneysActionTypes.SET_EVENT_FILTER,
  payload,
});

export const journeysActions = {
  getJourneysRequest,
  getJourneysSuccess,
  getJourneysFail,

  setJourney,

  setFilter,
  selectJourneys,

  cloneJourneyRequest,
  cloneJourneySuccess,
  cloneJourneyFail,
  setCloneName,
  cloneJourneyThunk,

  deleteJourneysRequest,
  deleteJourneysSuccess,
  deleteJourneysFail,

  deactivateJourneyRequest,
  deactivateJourneySuccess,
  deactivateJourneyFail,

  getJourneyRequest,
  getJourneySuccess,
  getJourneyFail,

  updateJourney,

  upsertJourneyRequest,
  upsertJourneySuccess,
  upsertJourneyFail,

  addJourneyStep,
  editJourneyStep,
  setJourneyStep,
  resetJourneyStep,
  copyJourneyStep,
  removeJourneyStep,

  setEditSidebar,

  updateJourneyStep,
  updatePageStep,
  updateConditionStep,
  updateDestinationStep,

  setFieldsAndCards,
  regenerateFieldsAndCardsThunk,

  getJourneyEventsRequest,
  getJourneyEventsSuccess,
  getJourneyEventsFail,

  setEventFilter,
};
