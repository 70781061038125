import { Link } from 'react-router-dom';
import { Button } from 'primereact/button';
import sumoistBackground from '../../../assets/images/sumoistBackground.svg';
import { ReactComponent as Sumoist } from '../../../assets/images/sumoist.svg';

interface ISumoistHelper {
  title?: string;
  message?: string;
  buttonLabel?: string;
  buttonUrl?: string;
}

export const SumoistHelper = ({ title, message, buttonLabel, buttonUrl }: ISumoistHelper) => {
  return (
    <div className="sumoist-container" style={{ backgroundImage: `url(${sumoistBackground})` }}>
      <div className="sumoist">
        <Sumoist />
        <div className="sumoist-chick-1" />
        <div className="sumoist-chick-2" />
      </div>
      <div className="text-display-md-reg pb-4px">{title}</div>
      <div className="text-label-input-reg">{message}</div>
      {buttonUrl && (
        <div className="pt-24px">
          <Link to={buttonUrl}>
            <Button label={buttonLabel || 'Button'}></Button>
          </Link>
        </div>
      )}
    </div>
  );
};
