import { WorkspaceInput, BookingPageStyleInput, WorkspaceIntegrationType } from '../../API';
import { CLONE_NAME_POSTFIX } from '../../types/constants';
import { ImageType, UploadImageRequestPayload, UploadImageSuccessPayload } from '../../types/types';
import { AppThunk } from '../rootStore';
import { cloneWorkspaceModalActions } from './modal';
import { workspacesSelectors } from './selectors';
import { AccordionIndexes, WorkspaceData } from './types';

export enum workspacesActionTypes {
  GET_WORKSPACES_REQUEST = 'workspaces/GET_WORKSPACES_REQUEST',
  GET_WORKSPACES_SUCCESS = 'workspaces/GET_WORKSPACES_SUCCESS',
  GET_WORKSPACES_FAIL = 'workspaces/GET_WORKSPACES_FAIL',
  GET_WORKSPACE_REQUEST = 'workspaces/GET_WORKSPACE_REQUEST',
  GET_WORKSPACE_SUCCESS = 'workspaces/GET_WORKSPACE_SUCCESS',
  GET_WORKSPACE_FAIL = 'workspaces/GET_WORKSPACE_FAIL',
  CREATE_WORKSPACE_REQUEST = 'workspaces/CREATE_WORKSPACE_REQUEST',
  CREATE_WORKSPACE_SUCCESS = 'workspaces/CREATE_WORKSPACE_SUCCESS',
  CREATE_WORKSPACE_FAIL = 'workspaces/CREATE_WORKSPACE_FAIL',
  UPDATE_WORKSPACE = 'workspaces/UPDATE_WORKSPACE',
  UPDATE_WORKSPACE_STYLE = 'workspaces/UPDATE_WORKSPACE_STYLE',
  ACTIVATE_WORKSPACE_REQUEST = 'workspaces/ACTIVATE_WORKSPACE_REQUEST',
  ACTIVATE_WORKSPACE_SUCCESS = 'workspaces/ACTIVATE_WORKSPACE_SUCCESS',
  ACTIVATE_WORKSPACE_FAIL = 'workspaces/ACTIVATE_WORKSPACE_FAIL',
  SAVE_WORKSPACE_REQUEST = 'workspaces/SAVE_WORKSPACE_REQUEST',
  SAVE_WORKSPACE_SUCCESS = 'workspaces/SAVE_WORKSPACE_SUCCESS',
  SAVE_WORKSPACE_FAIL = 'workspaces/SAVE_WORKSPACE_FAIL',
  DELETE_WORKSPACES_REQUEST = 'workspaces/DELETE_WORKSPACES_REQUEST',
  DELETE_WORKSPACES_SUCCESS = 'workspaces/DELETE_WORKSPACES_SUCCESS',
  DELETE_WORKSPACES_FAIL = 'workspaces/DELETE_WORKSPACES_FAIL',
  SET_WORKSPACE = 'workspaces/SET_WORKSPACE',
  UPLOAD_AVATAR_FILE_REQUEST = 'workspaces/UPLOAD_AVATAR_FILE_REQUEST',
  UPLOAD_AVATAR_FILE_SUCCESS = 'workspaces/UPLOAD_AVATAR_FILE_SUCCESS',
  UPLOAD_AVATAR_FILE_FAIL = 'workspaces/UPLOAD_AVATAR_FILE_FAIL',
  UPLOAD_BACKGROUND_FILE_REQUEST = 'workspaces/UPLOAD_BACKGROUND_FILE_REQUEST',
  UPLOAD_BACKGROUND_FILE_SUCCESS = 'workspaces/UPLOAD_BACKGROUND_FILE_SUCCESS',
  UPLOAD_BACKGROUND_FILE_FAIL = 'workspaces/UPLOAD_BACKGROUND_FILE_FAIL',
  RESET_IMAGE = 'workspaces/RESET_IMAGE',
  CLEAR_IMAGE = 'workspaces/CLEAR_IMAGE',
  CLONE_WORKSPACE_REQUEST = 'workspaces/CLONE_WORKSPACE_REQUEST',
  CLONE_WORKSPACE_SUCCESS = 'workspaces/CLONE_WORKSPACE_SUCCESS',
  CLONE_WORKSPACE_FAIL = 'workspaces/CLONE_WORKSPACE_FAIL',
  SET_CLONE_NAME = 'workspaces/SET_CLONE_NAME',
  REMOVE_INTEGRATION_REQUEST = `workspaces/REMOVE_INTEGRATION_REQUEST`,
  REMOVE_INTEGRATION_SUCCESS = `workspaces/REMOVE_INTEGRATION_SUCCESS`,
  REMOVE_INTEGRATION_FAIL = `workspaces/REMOVE_INTEGRATION_FAIL`,
  UPDATE_ACCORDION_INDEXES = 'bookingPages/UPDATE_ACCORDION_INDEXES',
}

export type WorkspacesAction =
  | { type: workspacesActionTypes.GET_WORKSPACES_REQUEST }
  | { type: workspacesActionTypes.GET_WORKSPACES_SUCCESS; payload: WorkspaceInput[] }
  | { type: workspacesActionTypes.GET_WORKSPACES_FAIL; error: unknown }
  | { type: workspacesActionTypes.GET_WORKSPACE_REQUEST; id: string }
  | { type: workspacesActionTypes.GET_WORKSPACE_SUCCESS; payload: WorkspaceInput }
  | { type: workspacesActionTypes.GET_WORKSPACE_FAIL; error: unknown }
  | { type: workspacesActionTypes.CREATE_WORKSPACE_REQUEST }
  | { type: workspacesActionTypes.CREATE_WORKSPACE_SUCCESS }
  | { type: workspacesActionTypes.CREATE_WORKSPACE_FAIL; error: unknown }
  | { type: workspacesActionTypes.ACTIVATE_WORKSPACE_REQUEST; payload: string }
  | { type: workspacesActionTypes.ACTIVATE_WORKSPACE_SUCCESS }
  | { type: workspacesActionTypes.ACTIVATE_WORKSPACE_FAIL; error: unknown }
  | { type: workspacesActionTypes.UPDATE_WORKSPACE; payload: Partial<WorkspaceData> }
  | { type: workspacesActionTypes.UPDATE_WORKSPACE_STYLE; payload: Partial<BookingPageStyleInput> }
  | { type: workspacesActionTypes.SAVE_WORKSPACE_REQUEST }
  | { type: workspacesActionTypes.SAVE_WORKSPACE_SUCCESS }
  | { type: workspacesActionTypes.SAVE_WORKSPACE_FAIL; error: unknown }
  | { type: workspacesActionTypes.DELETE_WORKSPACES_REQUEST }
  | { type: workspacesActionTypes.DELETE_WORKSPACES_SUCCESS }
  | { type: workspacesActionTypes.DELETE_WORKSPACES_FAIL; error: unknown }
  | { type: workspacesActionTypes.SET_WORKSPACE; payload: WorkspaceData }
  | { type: workspacesActionTypes.UPLOAD_AVATAR_FILE_REQUEST; payload: UploadImageRequestPayload }
  | { type: workspacesActionTypes.UPLOAD_AVATAR_FILE_SUCCESS; payload: UploadImageSuccessPayload }
  | { type: workspacesActionTypes.UPLOAD_AVATAR_FILE_FAIL; error: unknown }
  | { type: workspacesActionTypes.UPLOAD_BACKGROUND_FILE_REQUEST; payload: UploadImageRequestPayload }
  | { type: workspacesActionTypes.UPLOAD_BACKGROUND_FILE_SUCCESS; payload: UploadImageSuccessPayload }
  | { type: workspacesActionTypes.UPLOAD_BACKGROUND_FILE_FAIL; error: unknown }
  | { type: workspacesActionTypes.RESET_IMAGE; payload: ImageType }
  | { type: workspacesActionTypes.CLEAR_IMAGE; payload: ImageType }
  | { type: workspacesActionTypes.CLONE_WORKSPACE_REQUEST }
  | { type: workspacesActionTypes.CLONE_WORKSPACE_SUCCESS }
  | { type: workspacesActionTypes.CLONE_WORKSPACE_FAIL; error: unknown }
  | { type: workspacesActionTypes.SET_CLONE_NAME; payload: string }
  | { type: workspacesActionTypes.REMOVE_INTEGRATION_REQUEST; integrationType: WorkspaceIntegrationType }
  | { type: workspacesActionTypes.REMOVE_INTEGRATION_SUCCESS }
  | { type: workspacesActionTypes.REMOVE_INTEGRATION_FAIL; error: unknown }
  | { type: workspacesActionTypes.UPDATE_ACCORDION_INDEXES; payload: Partial<AccordionIndexes> };

const getWorkspacesRequest = (): WorkspacesAction => ({ type: workspacesActionTypes.GET_WORKSPACES_REQUEST });
const getWorkspacesSuccess = (payload: WorkspaceInput[]): WorkspacesAction => ({
  type: workspacesActionTypes.GET_WORKSPACES_SUCCESS,
  payload,
});
const getWorkspacesFail = (error: unknown): WorkspacesAction => ({
  type: workspacesActionTypes.GET_WORKSPACES_FAIL,
  error,
});

const getWorkspaceRequest = (id: string): WorkspacesAction => ({
  type: workspacesActionTypes.GET_WORKSPACE_REQUEST,
  id,
});
const getWorkspaceSuccess = (payload: WorkspaceInput): WorkspacesAction => ({
  type: workspacesActionTypes.GET_WORKSPACE_SUCCESS,
  payload,
});
const getWorkspaceFail = (error: unknown): WorkspacesAction => ({
  type: workspacesActionTypes.GET_WORKSPACE_FAIL,
  error,
});

const createWorkspaceRequest = (): WorkspacesAction => ({
  type: workspacesActionTypes.CREATE_WORKSPACE_REQUEST,
});
const createWorkspaceSuccess = (): WorkspacesAction => ({
  type: workspacesActionTypes.CREATE_WORKSPACE_SUCCESS,
});
const createWorkspaceFail = (error: unknown): WorkspacesAction => ({
  type: workspacesActionTypes.CREATE_WORKSPACE_FAIL,
  error,
});

const updateWorkspace = (payload: Partial<WorkspaceData>): WorkspacesAction => ({
  type: workspacesActionTypes.UPDATE_WORKSPACE,
  payload,
});

const updateWorkspaceStyle = (payload: Partial<BookingPageStyleInput>): WorkspacesAction => ({
  type: workspacesActionTypes.UPDATE_WORKSPACE_STYLE,
  payload,
});

const activateWorkspaceRequest = (payload: string): WorkspacesAction => ({
  type: workspacesActionTypes.ACTIVATE_WORKSPACE_REQUEST,
  payload,
});
const activateWorkspaceSuccess = (): WorkspacesAction => ({
  type: workspacesActionTypes.ACTIVATE_WORKSPACE_SUCCESS,
});
const activateWorkspaceFail = (error: unknown): WorkspacesAction => ({
  type: workspacesActionTypes.ACTIVATE_WORKSPACE_FAIL,
  error,
});

const saveWorkspaceRequest = (): WorkspacesAction => ({
  type: workspacesActionTypes.SAVE_WORKSPACE_REQUEST,
});
const saveWorkspaceSuccess = (): WorkspacesAction => ({
  type: workspacesActionTypes.SAVE_WORKSPACE_SUCCESS,
});
const saveWorkspaceFail = (error: unknown): WorkspacesAction => ({
  type: workspacesActionTypes.SAVE_WORKSPACE_FAIL,
  error,
});

const deleteWorkspacesRequest = (): WorkspacesAction => ({
  type: workspacesActionTypes.DELETE_WORKSPACES_REQUEST,
});
const deleteWorkspacesSuccess = (): WorkspacesAction => ({
  type: workspacesActionTypes.DELETE_WORKSPACES_SUCCESS,
});
const deleteWorkspacesFail = (error: unknown): WorkspacesAction => ({
  type: workspacesActionTypes.DELETE_WORKSPACES_FAIL,
  error,
});

const setWorkspace = (payload: WorkspaceData): WorkspacesAction => ({
  type: workspacesActionTypes.SET_WORKSPACE,
  payload,
});

const uploadAvatarFileRequest = (payload: UploadImageRequestPayload): WorkspacesAction => ({
  type: workspacesActionTypes.UPLOAD_AVATAR_FILE_REQUEST,
  payload,
});
const uploadAvatarFileSuccess = (payload: UploadImageSuccessPayload): WorkspacesAction => ({
  type: workspacesActionTypes.UPLOAD_AVATAR_FILE_SUCCESS,
  payload,
});
const uploadAvatarFileFail = (error: unknown): WorkspacesAction => ({
  type: workspacesActionTypes.UPLOAD_AVATAR_FILE_FAIL,
  error,
});

const uploadBackgroundFileRequest = (payload: UploadImageRequestPayload): WorkspacesAction => ({
  type: workspacesActionTypes.UPLOAD_BACKGROUND_FILE_REQUEST,
  payload,
});
const uploadBackgroundFileSuccess = (payload: UploadImageSuccessPayload): WorkspacesAction => ({
  type: workspacesActionTypes.UPLOAD_BACKGROUND_FILE_SUCCESS,
  payload,
});
const uploadBackgroundFileFail = (error: unknown): WorkspacesAction => ({
  type: workspacesActionTypes.UPLOAD_BACKGROUND_FILE_FAIL,
  error,
});

const resetImage = (payload: ImageType): WorkspacesAction => ({
  type: workspacesActionTypes.RESET_IMAGE,
  payload,
});
const clearImage = (payload: ImageType): WorkspacesAction => ({
  type: workspacesActionTypes.CLEAR_IMAGE,
  payload,
});

const cloneWorkspaceRequest = (): WorkspacesAction => ({
  type: workspacesActionTypes.CLONE_WORKSPACE_REQUEST,
});
const cloneWorkspaceSuccess = (): WorkspacesAction => ({
  type: workspacesActionTypes.CLONE_WORKSPACE_SUCCESS,
});
const cloneWorkspaceFail = (error: unknown): WorkspacesAction => ({
  type: workspacesActionTypes.CLONE_WORKSPACE_FAIL,
  error,
});

const removeIntegrationRequest = (integrationType: WorkspaceIntegrationType): WorkspacesAction => ({
  type: workspacesActionTypes.REMOVE_INTEGRATION_REQUEST,
  integrationType,
});
const removeIntegrationSuccess = (): WorkspacesAction => ({
  type: workspacesActionTypes.REMOVE_INTEGRATION_SUCCESS,
});
const removeIntegrationFail = (error: unknown): WorkspacesAction => ({
  type: workspacesActionTypes.REMOVE_INTEGRATION_FAIL,
  error,
});

const setCloneName = (payload: string): WorkspacesAction => ({
  type: workspacesActionTypes.SET_CLONE_NAME,
  payload,
});

const updateAccordionIndexes = (payload: Partial<AccordionIndexes>): WorkspacesAction => ({
  type: workspacesActionTypes.UPDATE_ACCORDION_INDEXES,
  payload,
});

// TODO: move to /thunks please
const cloneWorkspaceThunk = (): AppThunk => (dispatch, getState) => {
  const state = getState();
  const name = workspacesSelectors.selectName(state) + CLONE_NAME_POSTFIX;
  dispatch(setCloneName(name));
  dispatch(cloneWorkspaceModalActions.openModal());
};

export const workspacesActions = {
  getWorkspacesRequest,
  getWorkspacesSuccess,
  getWorkspacesFail,

  getWorkspaceRequest,
  getWorkspaceSuccess,
  getWorkspaceFail,

  createWorkspaceRequest,
  createWorkspaceSuccess,
  createWorkspaceFail,

  updateWorkspace,
  updateWorkspaceStyle,

  activateWorkspaceRequest,
  activateWorkspaceSuccess,
  activateWorkspaceFail,

  saveWorkspaceRequest,
  saveWorkspaceSuccess,
  saveWorkspaceFail,

  deleteWorkspacesRequest,
  deleteWorkspacesSuccess,
  deleteWorkspacesFail,

  setWorkspace,

  uploadAvatarFileRequest,
  uploadAvatarFileSuccess,
  uploadAvatarFileFail,

  uploadBackgroundFileRequest,
  uploadBackgroundFileSuccess,
  uploadBackgroundFileFail,

  resetImage,
  clearImage,

  cloneWorkspaceRequest,
  cloneWorkspaceSuccess,
  cloneWorkspaceFail,

  removeIntegrationRequest,
  removeIntegrationSuccess,
  removeIntegrationFail,

  setCloneName,
  updateAccordionIndexes,
  cloneWorkspaceThunk,
};
