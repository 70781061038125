export const Users = Object.freeze({
  ADD_TO_TEAM: `Añadir al equipo`,
  ADD_TO_TEAM_PLACEHOLDER: `Seleccionar equipo/s`,
  ALREADY_INVITED_COUNT: `Ya es usuario`,
  ASSIGN_BOOKING_TEMPLATES: `Asignar plantillas de reserva`,
  ASSIGN_BOOKING_TEMPLATES_PLACEHOLDER: `Seleccionar plantilla/s de reserva`,
  ASSIGN_ROLE: `Asignar rol`,
  ASSIGN_WORKSPACE: `Asignar espacio de trabajo`,
  CLOSE: `Cerrar`,
  CONFIRM_DIALOG_DESC: `Para confirmar, enviará una invitación por correo electrónico de la siguiente manera:`,
  CSV_INVITE_MESSAGE_TITLE: `Configurar un equipo grande en un minuto`,
  CSV_TEMPLATE_DESC: `y complete las direcciones de correo electrónico y los roles de sus usuarios.`,
  CSV_TEMPLATE_TITLE: `Descargar plantilla`,
  CSV_UPLOAD_DESC: `Seleccionar archivo CSV en su computadora`,
  CSV_UPLOAD_TITLE: `Subir CSV`,
  DELETE_TOOLTIP: `Solo puede eliminar usuarios con "Invitación enviada" que haya invitado. Los superadministradores pueden eliminar a cualquier usuario.`,
  EMAIL: `Correo electrónico`,
  FULL_NAME: `Nombre completo`,
  HIDE_INACTIVE_USERS: `Ocultar usuarios inactivos`,
  INVITE: `Invitar`,
  INVITE_MESSAGE_DESC: `Escriba o pegue correos electrónicos y enviaremos una invitación a sus compañeros de equipo. Separe las direcciones de correo electrónico con una coma.`,
  INVITE_MESSAGE_TITLE: `Direcciones de correo electrónico`,
  INVITE_TITLE: `Invitar usuarios`,
  LAST_ACTIVE: `Última actividad`,
  LOADING_LABEL_1: `Comprobando duplicados`,
  LOADING_LABEL_2: `Importando usuarios`,
  LOADING_LABEL_3: `Asignando atributos`,
  LOADING_LABEL_4: `Enviando invitaciones`,
  BOOKING_TEMPLATE: `Plantilla de reserva`,
  NAME: `Nombre`,
  NO_EMAIL_ADDRESSES: `No se encontraron direcciones de correo electrónico.`,
  OPTIONAL: `Opcional`,
  PASTE_EMAILS_TITLE: `Agregar correo electrónico`,
  ROLE: `Rol`,
  STATUS: `Estado`,
  STATUS_INVITE_SENT: `Invitación enviada`,
  STATUS_ACTIVE: `Activo`,
  STATUS_INACTIVE: `Inactivo`,
  SUPER_ADMIN_ACCESS_DESCRIPTION: `El superadministrador tendrá acceso a todos los espacios de trabajo`,
  TEAM: `Equipo`,
  TOOLTIP: `Invite y administre el acceso de los empleados a esta cuenta de SUMO1`,
  USER_COUNT: `Usuarios únicos que serán invitados`,
  WORKSPACE: `Espacio de trabajo`,
});

export const UsersConfirmDialog = Object.freeze({
  DELETE_DESCRIPTION: `¿Estás seguro de que quieres eliminar`,
  DELETE_COMMENT_1: `Desactivar o eliminar a un usuario liberará una licencia.`,
  DELETE_NOTE_DESCRIPTION: `Todos los datos del usuario se eliminarán permanentemente de los servidores de SUMO1.`,
  DELETE_NOTE_TITLE: `Cumplimiento de GDPR`,
  DELETE_TITLE: `Eliminar`,
  DISABLE_COMMENT_1: `Desactivar a un usuario liberará una licencia.`,
  DISABLE_COMMENT_2: `Las citas no se eliminarán ni cancelarán.`,
  DISABLE_DESCRIPTION: `¿Estás seguro de que quieres desactivar`,
  DISABLE_TITLE: `Desactivar usuario`,
});

export const EditUser = Object.freeze({
  ACCESS_TITLE: `Acceso`,
  ACTIVE: `Activo`,
  ACTIVE_TOOLTIP: `Marca esta casilla para inactivar a este usuario y liberar una licencia.`,
  CONTACT_DETAILS_TITLE: `Detalles de contacto`,
  DEACTIVATE_HIMSELF: `No puedes desactivarte a ti mismo.`,
  DAYS_10: `10 días`,
  DAYS_15: `15 días`,
  DAYS_20: `20 días`,
  DAYS_30: `30 días`,
  EXPIRATION_DATE: `Fecha de expiración`,
  HIGHER_ROLE_DESCRIPTION: `Este usuario tiene un rol superior al tuyo,`,
  INVITE_SENT_TOOLTIP: `El usuario aún no ha iniciado sesión. Eliminar el registro liberará la licencia.`,
  BOOKING_TEMPLATES: `Plantillas de reserva`,
  BOOKING_TEMPLATES_MESSAGE: `Este usuario admite las siguientes plantillas de reserva`,
  BOOKING_TEMPLATES_TOOLTIP: `Seleccione primero un espacio de trabajo`,
  NO_PERMISSION_DESCRIPTION: `No tienes permiso para editar usuarios,`,
  ONLY_ONE_SUPER_ADMIN: `Außer Ihnen gibt es keine weiteren Superadministratoren. Um die Rolle zu ändern, laden Sie mindestens einen weiteren Superadministrator ein`,
  READONLY_DESCRIPTION: `solo está disponible el modo de lectura.`,
  TEAMS: `Equipos`,
  TEAMS_MESSAGE: `Este usuario es miembro de los siguientes equipos.`,
  TITLE: `Editar usuario`,
  USER_OF_WORKSPACE_TITLE: `Usuario del espacio de trabajo`,
  WORKSPACE_MESSAGE: `Este usuario puede utilizar los siguientes espacios de trabajo.`,
});

export const UsersToastsNotifications = Object.freeze({
  EDIT_USER_ERROR_TOAST: `Error en la solicitud de edición de usuario`,
  EDIT_USER_SUCCESS_TOAST: `Solicitud de edición de usuario exitosa`,
  ENABLE_USER_TOAST_HEADER: `Usuario activado`,
  ENABLE_USER_SUCCESS_TOAST: `ahora está activado.`,
  GET_LICENSES_ERROR_MESSAGE: `Error en la solicitud de licencias`,
  GET_USERS_ERROR_MESSAGE: `Error en la solicitud de usuarios`,
  INVITE_USERS_EMAIL_INVALID: `Una o más de las direcciones de correo electrónico ingresadas no son válidas.`,
  INVITE_USERS_ERROR_TOAST: `Error en la solicitud de invitación de usuarios`,
  INVITE_USERS_SUCCESS_TOAST: `Solicitud de invitación de usuarios exitosa`,
  DELETE_USER_TOAST_HEADER: `Usuario eliminado`,
  DELETE_USER_ERROR_TOAST: `Error en la solicitud de eliminación de usuario`,
  DELETE_USER_SUCCESS_TOAST: `ahora está eliminado.`,
  DISABLE_USER_TOAST_HEADER: `Usuario desactivado`,
  DISABLE_USER_ERROR_TOAST: `Error en la solicitud de desactivación/activación de usuario`,
  DISABLE_USER_SUCCESS_TOAST: `ahora está desactivado.`,
});

export const License = Object.freeze({
  ASSIGNED: `Asignada`,
  BUY_LICENSES: `Comprar licencias`,
  MY_BILLINGS: `Mis Facturaciones`,
  SUBSCRIPTION: `Suscripción`,
  LICENSES: `Licencias`,
  PAID_EXPIRES_MESSAGE_PART_1: `Pago pendiente:`,
  PAID_EXPIRES_MESSAGE_PART_2_AGO: `días atrás`,
  PAID_EXPIRES_MESSAGE_PART_2_LEFT: `días restantes`,
  PAID_EXPIRES_MESSAGE_PART_2_TODAY: `hoy`,
  REMAINING: `Restantes`,
  REMAINING_MESSAGE: `No tienes suficientes licencias restantes`,
  TITLE: `Estado de la licencia de usuario`,
  TRIAL_EXPIRES_MESSAGE: `La prueba gratuita termina pronto.`,
  SEATS_REMOVAL_SCHEDULED: `Cambios programados para eliminar asientos.`,
});
