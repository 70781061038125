import { t } from '../../../i18n/i18n';

export default {
  cancel: t('Common:CANCEL'),
  description: t('BookingPagesOverrideModal:DESCRIPTION'),
  duplicate: t('Common:NAME_IS_DUPLICATE'),
  save: t('Common:SAVE'),
  title: t('BookingPagesOverrideModal:TITLE'),
  name: t('BookingPageWhatStep:NAME'),
  namePlaceholder: t('BookingPageWhatStep:NAME_PLACEHOLDER'),
};
