import { Calendar } from 'primereact/calendar';
import { Button } from 'primereact/button';
import i18n from '../../../i18n/i18n';
import labels from './labels';
import { useEffect, useRef } from 'react';

type FilterDateRangeProps = {
  showCalendar: boolean;
  setShowCalendar: (value: boolean) => void;
  selectedRange: Array<Date | null>;
  handleDateChange: (value?: string | Date | Date[] | null) => void;
};

export const FilterDateRange = ({
  showCalendar,
  setShowCalendar,
  selectedRange,
  handleDateChange,
}: FilterDateRangeProps) => {
  const calendarRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (calendarRef.current && !calendarRef.current.contains(event.target as Node)) {
        setShowCalendar(false);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleTodayClick = () => {
    const today = new Date();
    handleDateChange([today, today]);
  };

  const handleThisMonthClick = () => {
    const today = new Date();
    const firstDay = new Date(today.getFullYear(), today.getMonth(), 1);
    const lastDay = new Date(today.getFullYear(), today.getMonth() + 1, 0);
    handleDateChange([firstDay, lastDay]);
  };

  return (
    <>
      {showCalendar && (
        <div className="relative">
          <div
            ref={calendarRef}
            className="flex absolute z-1 -mt-12px border-radius-14px bg-primary-white shadow-level-2"
          >
            <Calendar
              selectionMode="range"
              locale={i18n.language}
              inline
              value={selectedRange.filter((date): date is Date => !!date) || null}
              onChange={(e) => handleDateChange(e.value)}
            />
            <div className="flex flex-column gap-16px p-20px">
              <Button label={labels.today} outlined onClick={handleTodayClick} />
              <Button label={labels.thisMonth} outlined onClick={handleThisMonthClick} />
            </div>
          </div>
        </div>
      )}
    </>
  );
};
