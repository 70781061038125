import { AppThunk } from '../rootStore';
import { eventSelectors } from './selectors';
import {
  calculateConditionNextStep,
  calculateEventLocationSettings,
  calculateUpdatedEvent,
  filterJourneyStepForSave,
} from './utils';
import { eventActions } from './actions';
import { EventSteps } from './types';
import { JourneyDestinationType, JourneyStepInput, JourneyStepType } from '../../API';

const setEventDateThunk =
  (payload: string): AppThunk =>
  (dispatch, getState) => {
    const currentDate = eventSelectors.selectEventDateString(getState());
    const emptyTime = payload !== currentDate;

    dispatch(eventActions.updateEvent({ eventDate: payload, ...(emptyTime ? { startTime: '' } : {}) }));
  };

const setEventStartTimeThunk =
  (payload: number): AppThunk =>
  (dispatch, getState) => {
    const state = getState();
    const agenda = eventSelectors.selectAgenda(state);
    const event = eventSelectors.selectEvent(state);
    const isRescheduleMode = eventSelectors.selectIsRescheduleMode(state);

    dispatch(eventActions.updateEvent(calculateUpdatedEvent(agenda, event, payload)));
    if (!isRescheduleMode) {
      dispatch(eventActions.setEventStep(EventSteps.INFO));
    }
  };

const saveEventThunk = (): AppThunk => (dispatch, getState) => {
  const isExisting = eventSelectors.selectIsExisting(getState());
  if (!isExisting) {
    const bookingPage = eventSelectors.selectBookingPage(getState());
    const event = eventSelectors.selectEvent(getState());
    const hostPhone = eventSelectors.selectHostFullPhone(getState());
    const customPhone = eventSelectors.selectCustomPhone(getState());
    const attendeePhone = eventSelectors.selectAttendeePhoneFormatted(getState());
    const attendeeLocation = eventSelectors.selectAttendeeLocation(getState());

    const settings = calculateEventLocationSettings(
      event.location,
      bookingPage?.where,
      hostPhone,
      customPhone,
      attendeePhone,
      attendeeLocation
    );
    dispatch(eventActions.updateLocation({ settings }));
  }
  dispatch(isExisting ? eventActions.updateEventRequest() : eventActions.createEventRequest());
};

const setPreviousJourneyStep = (): AppThunk => (dispatch, getState) => {
  const steps = eventSelectors.selectJourneySavedSteps(getState());
  const currentStep = eventSelectors.selectJourneyCurrentStep(getState());
  const currentStepIndex = steps.findIndex((step) => step.id === currentStep?.id);

  if (currentStepIndex > 0) {
    dispatch(eventActions.setJourneySteps(steps.slice(0, currentStepIndex)));
  }
};

const setNextJourneyStep = (): AppThunk => (dispatch, getState) => {
  const currentStep = eventSelectors.selectJourneyCurrentStep(getState());
  const steps = eventSelectors.selectJourneySavedSteps(getState());
  const isPreviewMode = eventSelectors.selectIsPreviewMode(getState());
  const currentStepIndex = steps.findIndex((step) => step.id === currentStep?.id);
  const filteredSteps = steps.slice(0, currentStepIndex + 1);

  if (!currentStep?.children?.length) {
    return;
  }

  let nextStep: JourneyStepInput | null = null;

  if (currentStep?.children[0]?.type === JourneyStepType.CONDITION) {
    nextStep = calculateConditionNextStep(steps, currentStep);
  } else {
    nextStep = currentStep?.children[0] || null;
  }

  if (nextStep?.type === JourneyStepType.PAGE) {
    dispatch(eventActions.setJourneySteps([...filteredSteps, filterJourneyStepForSave(nextStep)]));
  }

  if (nextStep?.type === JourneyStepType.DESTINATION) {
    dispatch(eventActions.setJourneySteps([...filteredSteps, nextStep]));

    if (nextStep.destination?.type === JourneyDestinationType.BOOKING_PAGE) {
      const bookingPageId = eventSelectors.selectBookingPageId(getState());
      if(bookingPageId && bookingPageId !== nextStep.destination?.bookingPageId){
        dispatch(eventActions.resetBookingPageSteps());
      }
      dispatch(eventActions.setBookingPageId(nextStep.destination?.bookingPageId || ''));
      dispatch(eventActions.getAgendaRequest());
    } else if (nextStep.destination?.type === JourneyDestinationType.EXTERNAL_URL) {
      if (!isPreviewMode) {
        dispatch(eventActions.createJourneyEventRequest());
      }
      window.open(nextStep.destination.externalURL || '', '_blank');
    } else if (nextStep.destination?.type === JourneyDestinationType.MESSAGE) {
      if (!isPreviewMode) {
        dispatch(eventActions.createJourneyEventRequest());
      }
    }
  }
};

export const eventThunks = {
  setEventDateThunk,
  setEventStartTimeThunk,
  saveEventThunk,
  setPreviousJourneyStep,
  setNextJourneyStep,
};
