import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { Outlet } from 'react-router-dom';
import { authenticationActions, authenticationSelectors } from '../store/authentication';
import { Path } from './pathes';
// import Layout from '../components/private/auth/layout';
import { GlobalError, Preloader } from '../components/common';
import { globalActions } from '../store/global';
import { UserSettingsKeys, userSettingsSelectors } from '../store/userSettings';
import { navigationService } from '../services/NavigationService';
import { DEFAULT_GLOBAL_SEARCH, globalSelectors } from '../store/global';
import { errorSelectors } from '../store/error/selectors';
import { useSession } from '../hooks/useSession';
import { RainbowLayout } from '../components/private/auth/RainbowLayout';

export const PrivateRoute = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { isAuthenticated } = useSession();
  const authenticationDataReceived = useSelector(authenticationSelectors.selectAuthenticationDataReceived);

  const isQuickSetupFinished = useSelector(userSettingsSelectors.selectIsQuickSetupFinished);
  const isGlobalFetching = useSelector(globalSelectors.selectIsFetching);

  const hasError = useSelector(errorSelectors.selectHasError); // TODO: looks like this is global error as well but catch somewhere else
  const isMainDataError = useSelector(globalSelectors.selectIsError); // TODO: reneame or change the logic
  const searchPaths = useSelector(globalSelectors.selectSearchPaths);

  const isLicenseActive = useSelector(authenticationSelectors.selectIsLicenseActive);
  const isViewAsUserMode = useSelector(authenticationSelectors.selectIsViewAsUser);
  const staffUserId = useSelector(authenticationSelectors.selectSumo1AdminUserId);
  const searchParams = new URLSearchParams(location.search); // params in URL after "?"
  const userWasDeleted = useSelector(authenticationSelectors.selectUserWasDeleted);
  const startOnes = useRef(false);

  useEffect(() => {
    if (isAuthenticated && !authenticationDataReceived && !isMainDataError && !startOnes.current) {
      startOnes.current = true;
      dispatch(globalActions.getMainDataRequest());
    }
  }, [isAuthenticated, authenticationDataReceived, isMainDataError, startOnes]);

  useEffect(() => {
    if (isMainDataError) {
      // dispatch(errorActions.setTheError(ErrorCodes.CODE_500));
      dispatch(authenticationActions.logoutUserRequest(Path.Landing));
    }
  }, [authenticationDataReceived, isMainDataError]);

  useEffect(() => {
    // navigate to Quick Setup if it's not finished
    if (authenticationDataReceived) {
      if (isViewAsUserMode) {
        // check if the View As User session closed
        setInterval(() => {
          const userId = localStorage.getItem(UserSettingsKeys.USER_ID);
          if (userId && userId === staffUserId) {
            navigationService.navigateTo(Path.OPSConsoleOrgs);
            window.location.reload();
          }
        }, 2000);
      } else {
        if (!isLicenseActive) {
          // if expired - redirect to License Expired
          navigationService.navigateTo(Path.LicenseExpired);
        }
        if (isLicenseActive && !isQuickSetupFinished) {
          navigationService.navigateTo(`${Path.QuickSetup}?${searchParams.toString()}`);
        }
      }
    }
  }, [authenticationDataReceived, isLicenseActive]);

  useEffect(() => {
    if (userWasDeleted) {
      dispatch(authenticationActions.logoutUserRequest(Path.Landing));
    }
  }, [userWasDeleted]);

  useEffect(() => {
    if (searchPaths && !searchPaths.some((name) => location.pathname.startsWith(name))) {
      dispatch(globalActions.setSearch(DEFAULT_GLOBAL_SEARCH));
    }
  }, [location]);

  useEffect(() => {
    if (!isAuthenticated) {
      dispatch(authenticationActions.logoutUserRequest(Path.Landing));
    }
  }, [isAuthenticated]);

  if (hasError) return <GlobalError />;

  if (!authenticationDataReceived || isGlobalFetching) return <Preloader />;

  // if (!isUserAuthenticated && isLicenseActive) return <Navigate to={Path.Landing} replace={true} />

  return (
    <RainbowLayout>
      <Outlet />
    </RainbowLayout>
  );
};
