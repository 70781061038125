export const CalendarConnections = Object.freeze({
  CALENDAR_INFO_MESSAGE: `Sie können nur 1 Kalender verbinden`, // Um dieses Limit zu erhöhen, kontaktieren Sie den Vertrieb, um Ihre Lizenzstufe zu aktualisieren.
  DESCRIPTION: `Verbinden Sie Ihren Kalender, damit SUMO weiß, wann Sie verfügbar sind und Ihren Kalender aktualisiert, wenn Termine gebucht werden.`,
  DISCONNECT_MESSAGE: `Sind Sie sicher, dass Sie Ihren Kalender trennen möchten?`,
  DISCONNECT_MESSAGE_DESCRIPTION: `Termine werden nicht gelöscht oder storniert.`,
  DISCONNECT_TITLE: `Kalender trennen`,
  EXCHANGE_CALENDAR_DESCRIPTION: `Exchange Server 2013, 2016 oder 2019`,
  EXCHANGE_CALENDAR_TITLE: `Exchange-Kalender`,
  GOOGLE_CALENDAR_DESCRIPTION: `Gmail, G Suite, Google Workspace`,
  GOOGLE_CALENDAR_TITLE: `Google-Kalender`,
  MICROSOFT_CALENDAR_DESCRIPTION: `Office 365, Outlook.com, Live.com oder Hotmail-Kalender`,
  MICROSOFT_CALENDAR_TITLE: `Office 365-Kalender`,
  SELECT_CALENDAR_TITLE: `Kalender auswählen`,
  TITLE: `Kalenderverbindungen`,
  TOOLTIP: `Verbinden Sie Ihren Google- oder Microsoft-Kalender mit SUMO`,
});

export const CookieSettings = Object.freeze({
  AGREE_CLOSE: `Zustimmen und Schließen`,
  DESCRIPTION: `Wenn Sie eine Website besuchen, hat sie die Möglichkeit, bestimmte Informationen in Ihrem Browser zu speichern oder abzurufen. Diese Informationen, oft in Form von Cookies, können sich auf Ihre Präferenzen, Ihr Gerät oder andere Details beziehen.`,
  DESCRIPTION_FIRST_PART: `Wenn Sie eine Website besuchen, hat sie die Möglichkeit, bestimmte Informationen in Ihrem Browser zu speichern oder abzurufen. Diese Informationen, oft in Form von Cookies, können sich auf Ihre Präferenzen, Ihr Gerät oder andere Details beziehen. Ihr Hauptzweck ist es, sicherzustellen, dass die Website wie erwartet funktioniert.`,
  DESCRIPTION_SECOND_PART: `Obwohl diese Informationen Sie in der Regel nicht direkt identifizieren, können sie Ihr Surferlebnis verbessern, indem sie Inhalte personalisieren.`,
  DESCRIPTION_THIRD_PART: `Durch Klicken auf die verschiedenen Kategorieüberschriften können Sie mehr über jeden Cookie-Typ erfahren und unsere Standardeinstellungen ändern.`,
  FUNCTIONAL_NAME: `Funktionale Cookies`,
  MANAGE_TITLE: `Verwalten Sie Ihre Zustimmungseinstellungen`,
  NECESSARY_ONLY: `Nur unbedingt erforderliche Cookies`,
  PERFORMANCE_NAME: `Performance-Cookies`,
  STRICTLY_NAME: `Streng notwendige Cookies`,
  TARGETING_NAME: `Targeting-Cookies`,
  TITLE: `Cookie-Einstellungen`,
});

export const Login = Object.freeze({
  GOOGLE_ACCOUNT: `Google-Konto`,
  GOOGLE_ACCOUNTS_SWITCH: `Google-Konten wechseln`,
  GOOGLE_SWITCH: `Mit Google anmelden`,
  GOOGLE_SWITCH_DESC: `Wechseln Sie von der Microsoft-Authentifizierung zu Google`,
  MICROSOFT_ACCOUNT: `Microsoft-Konto`,
  MICROSOFT_ACCOUNTS_SWITCH: `Microsoft-Konten wechseln`,
  MICROSOFT_SWITCH: `Mit Microsoft anmelden`,
  MICROSOFT_SWITCH_DESC: `Wechseln Sie von der Google-Authentifizierung zu Microsoft`,
  SWITCH_ACCOUNT_MESSAGE: `Ihr SUMO1-Konto verliert den Zugriff auf Daten, die nur über Ihren ursprünglichen Anbieter zugänglich sind. Möglicherweise müssen Sie auch erneut Zugriff auf Integrationen und Kalender gewähren.`,
  SWITCH_DESCRIPTION_PART1: `Wechseln Sie von `,
  SWITCH_DESCRIPTION_PART2: ` Authentifizierung zu `,
});

export const Role = Object.freeze({
  ROLE_DESCRIPTION: `Ihre aktuelle Rolle`,
});

export const AccountSettings = Object.freeze({
  DATE_FORMAT: `Datumsformat`,
  LANGUAGE: `Sprache`,
  NAME: `Name`,
  WELCOME_MESSAGE: `Willkommensnachricht`,
  PHONE_NUMBER: `Telefonnummer`,
  PROFILE_IMAGE: `Profilbild`,
  PHONE_DETAILS: `Telefonangaben`,
  PHONE_DETAILS_PLACEHOLDER: `Fügen Sie hier zusätzliche Informationen oder Anweisungen hinzu.\r\nz. B. Ich freue mich auf unser Treffen. Senden Sie mir jederzeit eine SMS.`,
  TIME_FORMAT: `Zeitformat`,
  TIME_ZONE: `Zeitzone`,
  COUNTRY: `Land`,
});

export const Profile = Object.freeze({
  TITLE: `Profil`,
  ACCOUNT_SETTINGS: `Kontoeinstellungen`,
  ACCOUNT_DESCRIPTION: `Grundlegende Informationen und Einstellungen`,
  LOGIN: `Anmeldung`,
  LOGIN_DESCRIPTION: `Verknüpftes Konto einrichten`,
  MY_ROLE: `Meine Rolle`,
  MY_ROLE_DESCRIPTION: `Ihre aktuelle Rolle und Berechtigungen`,
  COOKIE_SETTINGS: `Cookie-Einstellungen`,
  COOKIE_DESCRIPTION: `Notwendige, Leistungs-, Funktionale, Targeting-Cookies`,
});

export const UserSettingsToastsNotifications = Object.freeze({
  CONNECT_EXTERNAL_CALENDAR_ERROR_TOAST: `Neue Kalenderanfrage fehlgeschlagen`,
  CONNECT_EXTERNAL_CALENDAR_SUCCESS_TOAST: `Neuer Kalender erfolgreich verbunden!`,
  CONNECT_ZOOM_ERROR_TOAST: `Fehler bei der Verbindung mit Zoom`,
  CONNECT_ZOOM_SUCCESS_TOAST: `Erfolgreich mit Zoom verbunden!`,
  GET_USER_SETTINGS_FAIL: `Benutzerdaten nicht gefunden, bitte kontaktieren Sie den Support`,
  SAVE_USER_SETTINGS_ERROR_TOAST: `Fehler beim Speichern der Einstellungen`,
  SAVE_USER_SETTINGS_SUCCESS_TOAST: `Ihre Einstellungen wurden erfolgreich gespeichert!`,
  SWITCH_ACCOUNT_SUCCESS_TOAST: `Sie haben erfolgreich das Konto gewechselt`,
  SWITCH_ACCOUNT_ERROR_TOAST: `Fehler beim Konto-Wechsel. Bitte versuchen Sie es später erneut`,
});
