export const CannotDeleteWorkspace = Object.freeze({
  DESCRIPTION: `Os seguintes usuários estão designados para usar apenas este espaço de trabalho`,
  NOTES: `Você deve primeiro atribuir esses usuários a outro espaço de trabalho antes de excluí-lo.`,
  TITLE: `Reatribuir Usuários`,
});

export const WorkspaceToastsNotifications = Object.freeze({
  ACTIVATE_INACTIVATE_WORKSPACE_ERROR_MESSAGE: `Erro ao ativar/desativar o espaço de trabalho`,
  ACTIVATE_WORKSPACE_SUCCESS_MESSAGE: `Espaço de trabalho ativado`,
  DELETE_WORKSPACE_ERROR_MESSAGE: `Erro ao excluir o espaço de trabalho`,
  DELETE_WORKSPACE_SUCCESS_MESSAGE: `Solicitação de exclusão do espaço de trabalho bem-sucedida`,
  GET_WORKSPACES_ERROR_MESSAGE: `Erro ao obter a solicitação de espaços de trabalho`,
  INACTIVATE_WORKSPACE_SUCCESS_MESSAGE: `Espaço de trabalho desativado`,
  REMOVE_INTEGRATION_SUCCESS_TOAST: `Integração do espaço de trabalho desconectada com sucesso`,
  SAVE_WORKSPACE_ERROR_MESSAGE: `Erro ao salvar a solicitação do espaço de trabalho`,
  SAVE_WORKSPACE_SUCCESS_MESSAGE: `Espaço de trabalho salvo com sucesso`,
  CLONE_WORKSPACE_ERROR_MESSAGE: `Falha na solicitação de clonagem do espaço de trabalho`,
  CLONE_WORKSPACE_SUCCESS_MESSAGE: `Espaço de trabalho clonado com sucesso`,
});

export const WorkspaceWhoStep = Object.freeze({
  ADMINS_TITLE: `Administradores`,
  ADMIN_DESCRIPTION: `As seguintes pessoas podem administrar este espaço de trabalho.`,
  INVITE_SETTINGS_TITLE: `Configurações de Convite`,
  NOTES_PART_1: `Alternativamente, você pode usar a seção de `,
  NOTES_PART_2: `Gestão de Usuários`,
  NOTES_PART_3: ` para encontrar um usuário e adicioná-lo a um espaço de trabalho.`,
  PHONE_NUMBER_DESCRIPTION: `Se você planeja usar o SUMO para agendar chamadas telefônicas, é recomendável mantê-lo ativado.`,
  PHONE_NUMBER_TITLE: `Tornar obrigatório o campo "Número de Telefone" para os usuários.`,
  SEARCH_ADD_ADMINS: `Pesquisar e adicionar administrador`,
  SEARCH_ADD_USERS: `Pesquisar e adicionar usuários`,
  SUPER_ADMINS_DESCRIPTION: `Os superadministradores serão sempre pré-preenchidos`,
  USERS_DESCRIPTION: `As seguintes pessoas podem usar este espaço de trabalho.`,
  USERS_TITLE: `Usuários`,
});

export const Workspaces = Object.freeze({
  ACTIVE: `Ativo`,
  ADMIN_TITLE: `Admin`,
  CLONE_MODAL_TITLE: `Clonar espaço de trabalho`,
  DEFAULT_WORKSPACE: `Espaço de trabalho padrão`,
  DELETE_DESCRIPTION: `Tem certeza de que deseja excluir`,
  INACTIVE: `Inativo`,
  NAME_TITLE: `Nome do espaço de trabalho`,
  NAME_PLACEHOLDER: `Digite um nome de espaço de trabalho`,
  NEW_WORKSPACE: `Novo espaço de trabalho`,
  SELECTED_WORKSPACES: `espaços de trabalho selecionados`,
  TITLE: `Espaços de trabalho`,
  BRANDING_TITLE: `Personalização da página de marca`,
  BRANDING_DESCRIPTION: `Personalize a aparência, estilos e rótulos para novas páginas de reserva.`,
});

export const EditWorkspace = Object.freeze({
  WHAT_TITLE: `O que`,
  WHAT_DESCRIPTION: `Nome e status`,
  WHO_TITLE: `Quem`,
  WHO_DESCRIPTION: `Administradores e usuários`,
  HOW_TITLE: `Marca`,
  HOW_DESCRIPTION: `Estilos e exibições de marca`,
  ADDITIONAL_CONFIGURATIONS: `Configurações adicionais`,
});
