import { AppThunk } from '../rootStore';
import { NavigationKey, NavigationSettings, Step } from './types';
import { findNextStepIndex, findPreviousStepIndex } from './utils';
import { navigationsSelectors } from './selectors';

export enum NavigationsActionTypes {
  INITIALIZE_NAVIGATION = 'navigations/INITIALIZE_NAVIGATION',
  DELETE_NAVIGATION = 'navigations/DELETE_NAVIGATION',
  SET_CURRENT_STEP = 'navigations/SET_CURRENT_STEP',
  UPDATE_STEP = 'navigations/UPDATE_STEP',
}

export type NavigationsAction =
  | { type: NavigationsActionTypes.INITIALIZE_NAVIGATION; payload: NavigationSettings }
  | { type: NavigationsActionTypes.DELETE_NAVIGATION; key: NavigationKey }
  | { type: NavigationsActionTypes.SET_CURRENT_STEP; key: NavigationKey; stepIndex: number }
  | { type: NavigationsActionTypes.UPDATE_STEP; key: NavigationKey; step: Step }
  | { type: 'never' }; // resolves typing error inside rootStore;

const initializeNavigation = (payload: NavigationSettings): NavigationsAction => ({
  type: NavigationsActionTypes.INITIALIZE_NAVIGATION,
  payload,
});
const deleteNavigation = (key: NavigationKey): NavigationsAction => ({
  type: NavigationsActionTypes.DELETE_NAVIGATION,
  key,
});
const setCurrentStep =
  (key: NavigationKey) =>
  (stepIndex: number): NavigationsAction => ({
    type: NavigationsActionTypes.SET_CURRENT_STEP,
    key,
    stepIndex,
  });
const updateStep =
  (key: NavigationKey) =>
  (step: Step): NavigationsAction => ({
    type: NavigationsActionTypes.UPDATE_STEP,
    key,
    step,
  });

// TODO: move to /thunks please
const setCurrentStepThunk =
  (key: NavigationKey) =>
  (payload: number | string): AppThunk =>
  (dispatch, getState) => {
    const stepIndex =
      typeof payload === 'number'
        ? payload
        : navigationsSelectors
            .selectSteps(key)(getState())
            .findIndex((step) => step.name === payload);
    dispatch(setCurrentStep(key)(stepIndex));
  };

// TODO: move to /thunks please
const navigateBackThunk = (key: NavigationKey) => (): AppThunk => (dispatch, getState) => {
  const state = getState();
  const step = navigationsSelectors.selectCurrentStepIndex(key)(state);
  const steps = navigationsSelectors.selectSteps(key)(state);
  const stepIndex = findPreviousStepIndex(step, steps);
  dispatch(setCurrentStep(key)(stepIndex));
};

// TODO: move to /thunks please
const navigateNextThunk = (key: NavigationKey) => (): AppThunk => (dispatch, getState) => {
  const state = getState();
  const step = navigationsSelectors.selectCurrentStepIndex(key)(state);
  const steps = navigationsSelectors.selectSteps(key)(state);
  const stepIndex = findNextStepIndex(step, steps);
  dispatch(setCurrentStep(key)(stepIndex));
};

export const navigationsActions = {
  initializeNavigation,
  deleteNavigation,
};

export const navigationsActionsFactory = (key: NavigationKey) => ({
  navigateBack: navigateBackThunk(key),
  navigateNext: navigateNextThunk(key),
  setCurrentStep: setCurrentStepThunk(key),
  updateStep: updateStep(key),
});
