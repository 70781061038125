import { t } from '../../../i18n/i18n';

export default {
  destinationTypeLabel: t('JourneysDestinationStep:TYPE_LABEL'),
  showsStepSeparately: t('JourneysDestinationStep:SHOWS_STEP_SEPARATLY'),
  active: t('Common:ACTIVE'),
  select: t('Common:SELECT'),
  who: t('EditBookingPage:WHO_TITLE'),
  whoDesc: t('JourneysDestinationStep:WHO_DESCRIPTION'),
  when: t('EditBookingPage:WHEN_TITLE'),
  whenDesc: t('JourneysDestinationStep:WHEN_DESCRIPTION'),
  where: t('EditBookingPage:WHERE_TITLE'),
  whereDesc: t('JourneysDestinationStep:WHERE_DESCRIPTION'),
  header: t('JourneysDestinationStep:HEADER_LABEL'),
  message: t('JourneysDestinationStep:MESSAGE_LABEL'),
  url: t('JourneysDestinationStep:URL_LABEL'),
  bookingPage: t('JourneyDefault:BOOKING_PAGE'),
};
