import { t } from '../../i18n/i18n';

export default {
  delete: t('Common:DELETE'),
  status: t('Common:STATUS'),
  emptyList: t('Common:EMPTY_LIST'),
  emptyListDesc: t('Journey:EMPTY_LIST_DESCRIPTION'),
  title: t('Journey:TITLE'),
  addLabel: t('Journey:ADD_LABEL'),
  createdBy: t('Journey:CREATED_BY'),
};
