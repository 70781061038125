import { t } from '../../../../i18n/i18n';

export default {
  save: t('Common:SAVE'),
  cancel: t('Common:CANCEL'),
  newQuestion: t('EditBookingPageInviteeStep:NEW_QUESTION'),
  editQuestion: t('EditBookingPageInviteeStep:EDIT_QUESTION'),
  titleName: t('EditBookingTemplateCustomField:TITLE_NAME'),
  titleEmail: t('EditBookingTemplateCustomField:TITLE_EMAIL'),
  titlePhone: t('EditBookingTemplateCustomField:TITLE_PHONE'),
  titleLocation: t('EditBookingTemplateCustomField:TITLE_LOCATION'),
  descriptionName: t('EditBookingTemplateCustomField:DESCRIPTION_NAME'),
  descriptionEmail: t('EditBookingTemplateCustomField:DESCRIPTION_EMAIL'),
  descriptionPhone: t('EditBookingTemplateCustomField:DESCRIPTION_PHONE'),
  descriptionLocation: t('EditBookingTemplateCustomField:DESCRIPTION_LOCATION'),
  questionLabel: t('EditBookingTemplateCustomField:QUESTION_LABEL'),
  questionNameLabel: t('EditBookingTemplateCustomField:QUESTION_NAME_LABEL'),
  questionEmailLabel: t('EditBookingTemplateCustomField:QUESTION_EMAIL_LABEL'),
  questionPhoneLabel: t('EditBookingTemplateCustomField:QUESTION_PHONE_LABEL'),
  questionLocationLabel: t('EditBookingTemplateCustomField:QUESTION_LOCATION_LABEL'),
  requiredLabel: t('EditBookingTemplateCustomField:REQUIRED_LABEL'),
  typeLabel: t('EditBookingTemplateCustomField:TYPE_LABEL'),
  typeCheckboxes: t('EditBookingTemplateCustomField:TYPE_CHECKBOXES'),
  typeRadioButtons: t('EditBookingTemplateCustomField:TYPE_RADIO_BUTTONS'),
  typeSelect: t('EditBookingTemplateCustomField:TYPE_SELECT'),
  typeTel: t('EditBookingTemplateCustomField:TYPE_TEL'),
  typeText: t('EditBookingTemplateCustomField:TYPE_TEXT'),
  typeTextarea: t('EditBookingTemplateCustomField:TYPE_TEXTAREA'),
  typeCheckboxesDescription: t('EditBookingTemplateCustomField:TYPE_CHECKBOXES_DESCRIPTION'),
  typeRadioButtonsDescription: t('EditBookingTemplateCustomField:TYPE_RADIO_BUTTONSS_DESCRIPTION'),
  typeSelectDescription: t('EditBookingTemplateCustomField:TYPE_SELECTS_DESCRIPTION'),
  typeTelDescription: t('EditBookingTemplateCustomField:TYPE_TELS_DESCRIPTION'),
  typeTextDescription: t('EditBookingTemplateCustomField:TYPE_TEXTS_DESCRIPTION'),
  typeTextareaDescription: t('EditBookingTemplateCustomField:TYPE_TEXTAREAS_DESCRIPTION'),
  answersLabel: t('EditBookingTemplateCustomField:ANSWERS_LABEL'),
  placeholder: t('EditBookingTemplateCustomField:ANSWER_PLACEHOLDER'),
};
