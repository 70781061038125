import { SelectItem } from 'primereact/selectitem';
import { AvailabilityInput, DateTimeFromToInput, IntegrationType, VideoConferenceType, WeeklyHoursInput } from '../API';
import { FormattedZone } from './interfaces';
import { MultiSelectChangeEvent } from 'primereact/multiselect';
import { DropdownChangeEvent, DropdownPassThroughOptions } from 'primereact/dropdown';
import { HeroIcons } from './constants';

export type CustomHubPayload = {
  payload: {
    event: string;
    data?: any;
    message?: string;
  };
};

export type AccountMenuItem = {
  name: string;
  path?: string;
  isModal?: boolean;
};

export type FormattedZoneMap = Map<string, FormattedZone[]>;

export type modifyAvailabilityData = (
  data: AvailabilityInput | null | undefined
) => AvailabilityInput | null | undefined;

export type ModifyWeeklyHours = (day: WeeklyHoursInput | null) => WeeklyHoursInput | null;

export type DateTimeFromToPartial = Partial<DateTimeFromToInput['from']> | Partial<DateTimeFromToInput['to']>;

export enum ImageType {
  avatar = 'avatar',
  backgroundImage = 'backgroundImage',
}

export type UploadImageRequestPayload = {
  imageType: ImageType;
  file: any;
};

export type UploadImageSuccessPayload = {
  imageType: ImageType;
  imageLink: string;
};

export type UploadedImage = {
  imageType: ImageType;
  imageLink: string | null; // null => use workspace image, '' => use no image, 'link' - use given image
};

export type MultiselectDataOption = {
  label?: string;
  value?: string;
  displayId?: string;
};

export type MultiselectData = {
  label?: string;
  value: (string | null)[];
  options: MultiselectDataOption[];
  onChange: (e: MultiSelectChangeEvent) => void;
  disabled?: boolean;
};

export type DropdownData = {
  label?: string;
  value: string;
  valueLabel?: string;
  onValueClick?: (e: SelectItem) => void;
  options: SelectItem[];
  onChange: (e: DropdownChangeEvent) => void;
  pt?: DropdownPassThroughOptions;
};

export enum MemberSubType {
  USER = 'USER',
  TEAM = 'TEAM',
}

export enum StatusFilter {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}

export type HeroIconKey = keyof typeof HeroIcons;
export type HeroIconComponent = React.ComponentType<React.SVGProps<SVGSVGElement>>;
export type HostOption = {
  value: string;
  label: string;
  isTeam: boolean;
  hasPhoneNumber: boolean;
  integrationTypes: IntegrationType[];
  email?: string;
  defaultVideoIntegration?: VideoConferenceType;
};

export type OptionType = {
  value: string;
  label: string;
};
