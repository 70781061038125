import { call, put, select, takeLatest } from 'redux-saga/effects';
import { CanceledEventInput, CreateUserEventInput } from '../../API';
import { scheduledMeetingsActions, BookedMeetingsActionTypes } from './actions';
import { fetchEvents, updateInternalFieldsMeeting } from './service';
import { notificationsActions } from '../notifications';
import {
  CANCEL_EVENT_ERROR_TOAST,
  CANCEL_EVENT_SUCCESS_TOAST,
  GET_SCHEDULED_MEETINGS_ERROR_TOAST,
  UPDATE_SCHEDULED_MEETING_ERROR_TOAST,
} from './constants';
import { editMeetingNotesModalActions } from './modal';
import { SortMethods, UpdateBookedMeeting, UpdateInternalFieldsUserEventRequest } from './types';
import { scheduledMeetingsSelectors } from './selectors';
import { cancelEvent } from '../publicBookingPage/service';
import { handleServiceError } from '../utils/reduxUtils';

function* getBookedMeetingsSaga(action: ReturnType<typeof scheduledMeetingsActions.getBookedMeetingsRequest>) {
  try {
    if (action.type === BookedMeetingsActionTypes.GET_SCHEDULED_MEETINGS_REQUEST) {
      const response: CreateUserEventInput[] = yield call(fetchEvents);

      if (action.payload.eventId) {
        // set filter range for particular event
        const event = response.find((event) => event.eventId === action.payload.eventId);
        if (event && event.eventDate) {
          yield put(scheduledMeetingsActions.setSortMethod(SortMethods.DATE_RANGE));
          yield put(scheduledMeetingsActions.setDateRange([event.eventDate, event.eventDate]));
        }
      }
      yield put(scheduledMeetingsActions.getBookedMeetingsSuccess(response));
    }
  } catch (error: unknown) {
    yield put(scheduledMeetingsActions.getBookedMeetingsFail(error?.toString()));
    yield call(handleServiceError, error, GET_SCHEDULED_MEETINGS_ERROR_TOAST, true);
  }
}

function* updateBookedMeetingSaga(action: ReturnType<typeof scheduledMeetingsActions.updateBookedMeetingRequest>) {
  try {
    if (action.type === BookedMeetingsActionTypes.UPDATE_SCHEDULED_MEETING_REQUEST) {
      const event: UpdateBookedMeeting = { ...action.payload };
      const updatedEvent: UpdateInternalFieldsUserEventRequest = {
        eventId: event.eventId,
        internalNotes: event.internalNotes || '',
        isNoShow: event.isNoShow || false,
      };
      yield call(updateInternalFieldsMeeting, updatedEvent);

      yield put(scheduledMeetingsActions.updateBookedMeetingSuccess(event));
      if (event.editMeetingNotes) {
        yield put(editMeetingNotesModalActions.closeModal());
      }
      yield put(scheduledMeetingsActions.getBookedMeetingsRequest({}));
    }
  } catch (error: unknown) {
    yield put(scheduledMeetingsActions.updateBookedMeetingFail(error?.toString()));
    yield call(handleServiceError, error, UPDATE_SCHEDULED_MEETING_ERROR_TOAST);
  }
}

function* cancelBookedMeetingSaga() {
  try {
    const eventId: string = yield select(scheduledMeetingsSelectors.selectEventId);
    const canceled: CanceledEventInput | null | undefined = yield select(
      scheduledMeetingsSelectors.selectEventCanceled
    );

    yield call(cancelEvent, { eventId, canceled });

    yield put(scheduledMeetingsActions.cancelBookedMeetingSuccess());
    yield put(notificationsActions.showToast(CANCEL_EVENT_SUCCESS_TOAST));
    yield put(scheduledMeetingsActions.getBookedMeetingsRequest({}));
  } catch (error: unknown) {
    yield put(scheduledMeetingsActions.cancelBookedMeetingFail(error?.toString() || ''));
    yield call(handleServiceError, error, CANCEL_EVENT_ERROR_TOAST);
  }
}

export function* watchBookedMeetingsSaga() {
  yield takeLatest(BookedMeetingsActionTypes.GET_SCHEDULED_MEETINGS_REQUEST, getBookedMeetingsSaga);
  yield takeLatest(BookedMeetingsActionTypes.UPDATE_SCHEDULED_MEETING_REQUEST, updateBookedMeetingSaga);
  yield takeLatest(BookedMeetingsActionTypes.CANCEL_SCHEDULED_MEETING_REQUEST, cancelBookedMeetingSaga);
}
