import { t } from '../../i18n/i18n';

export default {
  active: t('Roles:ACTIVE'),
  activate: t('Common:ACTIVATE'),
  clone: t('Common:CLONE'),
  custom: t('Roles:CUSTOM'),
  deactivate: t('Common:DEACTIVATE'),
  deactivateDesc: t('Roles:DEACTIVATE_DESCRIPTION'),
  delete: t('Common:DELETE'),
  deleteDesc: t('Roles:DELETE_DESCRIPTION'),
  deleteMessage: t('Roles:DELETE_MESSAGE'),
  edit: t('Common:EDIT'),
  lastModified: t('Roles:LAST_MODIFIED'),
  lastModifiedBy: t('Roles:LAST_MODIFIED_BY'),
  newRole: t('Roles:NEW_ROLE'),
  no: t('Common:NO'),
  noResults: t('Common:NO_RESULTS_FOUND'),
  role: t('Roles:ROLE'),
  search: t('Common:SEARCH'),
  status: t('Users:STATUS'),
  title: t('Common:ROLES'),
  yes: t('Common:YES'),
};
