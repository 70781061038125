import { t } from '../../../i18n/i18n';

export default {
  save: t('Common:SAVE'),
  cancel: t('Common:CANCEL'),
  from: t('Common:FROM'),
  to: t('Common:TO'),
  date: t('Common:DATE'),
  times: t('Common:TIMES'),
  chooseDate: t('Common:CHOOSE_DATE'),
  dateOverrideDescription: t('Availability:DATE_OVERRIDE_DESCRIPTION'),
  dateOverrideTitle: t('Availability:DATE_OVERRIDE_TITLE'),
  dateOverrideButton: t('Availability:DATE_OVERRIDE_BUTTON'),
  dateOverrideModalPrompt: t('Availability:DATE_OVERRIDE_MODAL_PROMPT'),
  dateOverrideModalHours: t('Availability:DATE_OVERRIDE_MODAL_HOURS'),
  dateOverrideModalUnavailable: t('Availability:DATE_OVERRIDE_MODAL_UNAVAILABLE'),
  availableText: t('Common:AVAILABLE'),
  availableDesc: t('Availability:AVAILABLE_DESCRIPTION'),
  unavailableText: t('Common:UNAVAILABLE'),
  unavailableDesc: t('Availability:UNAVAILABLE_DESCRIPTION'),
  dateOverlappingText: t('Availability:DATES_OVERLAPPING_TEXT'),
  timeOverlappingText: t('Availability:TIME_INTERVAL_OVERLAPPING'),
  timeInValidText: t('Availability:TIME_INVALID_TEXT'),

  dateOverrideAvailableTitle: t('Availability:DATE_OVERRIDE_AVAILABLE_TITLE'),
  dateOverrideUnavailableTitle: t('Availability:DATE_OVERRIDE_UNAVAILABLE_TITLE'),
};
