import { combineReducers } from 'redux';
import { BillingActionTypes, BillingAction } from './actions';
import { DEFAULT_SUBSCRIPTION, DEFAULT_SEATS_AMOUNT, CHARGEBEE_MONTHLY_TERM, ITEM_PRICES_DEFAULT } from './constants';
import { GlobalAction, GlobalActionTypes } from '../global/actions';

const subscription = (state = DEFAULT_SUBSCRIPTION, action: BillingAction | GlobalAction) => {
  switch (action.type) {
    case BillingActionTypes.GET_BILLING_SUCCESS:
      return action.payload;
    case BillingActionTypes.CHANGE_TERM_SUCCESS:
    case BillingActionTypes.CHANGE_SEATS_SUCCESS:
      return { ...state, ...action.payload.subscription };
    case GlobalActionTypes.RESET_WHOLE_STORE:
      return DEFAULT_SUBSCRIPTION;
    default:
      return state;
  }
};

const addOrRemoveSeats = (state = false, action: BillingAction | GlobalAction) => {
  switch (action.type) {
    case BillingActionTypes.SET_ADD_OR_REMOVE_SEATS:
      return action.payload;
    case GlobalActionTypes.RESET_WHOLE_STORE:
      return false;
    default:
      return state;
  }
};

const changeSeats = (state = DEFAULT_SEATS_AMOUNT, action: BillingAction | GlobalAction) => {
  switch (action.type) {
    case BillingActionTypes.SET_CHANGE_SEATS:
      return action.payload;
    case BillingActionTypes.CHANGE_SEATS_SUCCESS:
    case GlobalActionTypes.RESET_WHOLE_STORE:
      return DEFAULT_SEATS_AMOUNT;
    default:
      return state;
  }
};

const term = (state = CHARGEBEE_MONTHLY_TERM, action: BillingAction | GlobalAction) => {
  switch (action.type) {
    case BillingActionTypes.SET_TERM:
      return action.payload;
    case BillingActionTypes.GET_BILLING_SUCCESS:
      return action.payload?.subscription_items?.[0]?.item_price_id || CHARGEBEE_MONTHLY_TERM;
    case GlobalActionTypes.RESET_WHOLE_STORE:
      return CHARGEBEE_MONTHLY_TERM;
    default:
      return state;
  }
};

const itemPrices = (state = ITEM_PRICES_DEFAULT, action: BillingAction | GlobalAction) => {
  switch (action.type) {
    case BillingActionTypes.GET_ITEM_PRICES_SUCCESS:
      return action.payload;
    case GlobalActionTypes.RESET_WHOLE_STORE:
      return ITEM_PRICES_DEFAULT;
    default:
      return state;
  }
};

const isFetching = (state = false, action: BillingAction | GlobalAction) => {
  switch (action.type) {
    case BillingActionTypes.GET_BILLING_REQUEST:
    case BillingActionTypes.CHANGE_SEATS_REQUEST:
    case BillingActionTypes.CHANGE_TERM_REQUEST:
    case BillingActionTypes.DELETE_SCHEDULED_CHANGE_REQUEST:
      return true;
    case BillingActionTypes.GET_BILLING_SUCCESS:
    case BillingActionTypes.GET_BILLING_FAIL:
    case BillingActionTypes.CHANGE_SEATS_SUCCESS:
    case BillingActionTypes.CHANGE_SEATS_FAIL:
    case BillingActionTypes.CHANGE_TERM_SUCCESS:
    case BillingActionTypes.CHANGE_TERM_FAIL:
    case BillingActionTypes.DELETE_SCHEDULED_CHANGE_SUCCESS:
    case BillingActionTypes.DELETE_SCHEDULED_CHANGE_FAIL:
      return false;
    case GlobalActionTypes.RESET_WHOLE_STORE:
      return false;
    default:
      return state;
  }
};

const isRefreshLicencesFetching = (state = false, action: BillingAction | GlobalAction) => {
  switch (action.type) {
    case BillingActionTypes.REFRESH_TRIAL_LICENSE_REQUEST:
      return true;
    case BillingActionTypes.REFRESH_TRIAL_LICENSE_SUCCESS:
      return false;
    case GlobalActionTypes.RESET_WHOLE_STORE:
      return false;
    default:
      return state;
  }
};

const error = (state = '', action: BillingAction | GlobalAction) => {
  switch (action.type) {
    case BillingActionTypes.GET_BILLING_REQUEST:
    case BillingActionTypes.CHANGE_SEATS_REQUEST:
    case BillingActionTypes.CHANGE_TERM_REQUEST:
    case BillingActionTypes.DELETE_SCHEDULED_CHANGE_REQUEST:
    case BillingActionTypes.GET_ITEM_PRICES_REQUEST:
      return '';
    case BillingActionTypes.GET_BILLING_FAIL:
    case BillingActionTypes.CHANGE_SEATS_FAIL:
    case BillingActionTypes.CHANGE_TERM_FAIL:
    case BillingActionTypes.DELETE_SCHEDULED_CHANGE_FAIL:
    case BillingActionTypes.GET_ITEM_PRICES_FAIL:
      return action.error;
    case GlobalActionTypes.RESET_WHOLE_STORE:
      return '';
    default:
      return state;
  }
};

export default combineReducers({
  subscription,
  changeSeats,
  term,
  itemPrices,
  addOrRemoveSeats,
  isFetching,
  isRefreshLicencesFetching,
  error,
});
