export const AuthenticationToastsNotifications = Object.freeze({
  CHOOSE_AN_ACCOUNT_ERROR_TOAST: `Échec de la demande de choix de compte`,
  DELETE_USER_ERROR_MESSAGE: `Échec de la demande de suppression d'utilisateur`,
  DELETE_USER_SUCCESS_MESSAGE: `Demande de suppression d'utilisateur réussie`,
  GET_USER_AUTH_ERROR_MESSAGE: `Échec de la demande de connexion de l'utilisateur`,
  GET_USER_LOGIN_ERROR_MESSAGE: `Aucun utilisateur n'a été trouvé avec cet email`,
  GET_USER_LOGOUT_ERROR_MESSAGE: `Échec de la demande de déconnexion de l'utilisateur`,
  GET_MAIN_DATA_ERROR_MESSAGE: `Échec de la requête d'authentification`,
  ROLE_DEACTIVATED_ERROR_MESSAGE: `Le rôle a été désactivé. Veuillez contacter votre administrateur SUMO1.`,
  USER_DEACTIVATED_ERROR_MESSAGE: `L'utilisateur a été désactivé. Veuillez contacter votre administrateur SUMO1.`,
  TRIAL_LIMIT_ERROR_MESSAGE: `Vous avez dépassé la limite de 30 éléments pour la version d'essai`,
  INVITE_EXPIRED_ERROR_TITLE: `Invitation expirée`,
  INVITE_EXPIRED_ERROR_MESSAGE_PART1: `Votre invitation a expiré. Veuillez contacter votre administrateur pour obtenir un nouveau lien ou essayez de `,
  INVITE_EXPIRED_ERROR_MESSAGE_PART2: `s'inscrire sans invitation`,
  SWITCH_ACCOUNT_ERROR_MESSAGE: `Un autre compte est déjà associé à cet e-mail`,
});

export const Authorise = Object.freeze({
  SING_IN_TITLE_LINE_1: `Bienvenue`,
  SING_IN_TITLE_LINE_2: `de retour chez SUMO`,
  SING_IN_SUBTITLE: `Connectez-vous à votre centre de planification.`,
  SING_IN_GOOGLE: `Se connecter avec Google`,
  SING_IN_MICROSOFT: `Se connecter avec Microsoft`,
  SING_IN_DONT_HAVE_AN_ACCOUNT: `Vous n'avez pas de compte?`,
  SIGN_UP_COMMENT: `*SUMO1 ne nécessite pas de licence Salesforce.`,
  SING_UP_TITLE_LINE_1: `Planification en ligne`,
  SING_UP_TITLE_LINE_2: `et plus`,
  SING_UP_SUBTITLE: `Commencez à utiliser le service gratuitement en un seul clic.`,
  SING_UP_GOOGLE: `S'inscrire avec Google`,
  SING_UP_MICROSOFT: `S'inscrire avec Microsoft`,
  SIGN_UP_FREE_TRIAL: `Essai gratuit de 15 jours. Aucune carte de crédit requise`,
  SING_UP_HAVE_AN_ACCOUNT: `Vous avez déjà un compte?`,
  LOOKING_FOR_SUMO_CEP: `Vous cherchez SUMO pour Salesforce?`,
  LOADING_LABEL_1: `Autorisation de l'utilisateur`,
  LOADING_LABEL_2: `Vérification du calendrier`,
  LOADING_LABEL_3: `Création de jetons`,
});

export const DialogLeaveAccount = Object.freeze({
  ACCEPT_TEXT: `Souhaitez-vous accepter cette invitation et quitter l'autre compte SUMO1?`,
  INVITE_TEXT_PART_1: `Vous avez été invité par`,
  INVITE_TEXT_PART_2: `à rejoindre SUMO1, mais votre adresse e-mail est déjà associée à un autre compte SUMO1.`,
  LAST_SUPER_ADMIN_NOTE: `Vous êtes le seul Super Admin dans l'organisation. Vous devez d'abord vous connecter à votre organisation existante et transférer le rôle de "Super Admin" à un autre utilisateur avant d'accepter cette invitation. Contactez le support SUMO pour obtenir de l'aide.`,
  TITLE: `Quitter le compte`,
});

export const DialogUserAccounts = Object.freeze({
  ACCEPT_TEXT: `Souhaitez-vous accepter cette invitation et quitter l'autre compte SUMO1?`,
  INVITE_FROM: `Invitation de :`,
  INVITE_TEXT: `Vous avez été invité à rejoindre SUMO1, mais vous essayez de vous inscrire pour un nouveau compte SUMO1.`,
  SELECT_TEXT: `Sélectionnez ci-dessous si vous souhaitez accepter cette invitation au lieu de créer un nouveau compte d'entreprise SUMO1.`,
  STAY_IN_MY_ACCOUNT: `Rester dans mon compte`,
  TITLE: `Accepter l'invitation`,
});
