/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_appsync_graphqlEndpoint": "https://ftrjnl6mzfc3pbetwrvuv5egx4.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "AWS_IAM",
    "aws_cloud_logic_custom": [
        {
            "name": "publicApi",
            "endpoint": "https://h8w8xtcbu6.execute-api.us-east-1.amazonaws.com/stage",
            "region": "us-east-1"
        }
    ],
    "aws_cognito_identity_pool_id": "us-east-1:f56ce469-c6bf-4b12-a6d5-8e404c57606c",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_D1aVgzQ9s",
    "aws_user_pools_web_client_id": "5ln106trkbn17k6dcf9qb4sa67",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "upload181729-stage",
    "aws_user_files_s3_bucket_region": "us-east-1"
};


export default awsmobile;
