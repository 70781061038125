import { memo, useEffect, useRef, useState } from 'react';
import { CanceledType, CreateUserEventInput, CustomFieldInput, CustomFieldType, LocationType } from '../../../API';
import { useDispatch } from 'react-redux';
import { formatDateHHMM } from '../../../services/DateService';
import { Button } from 'primereact/button';
import { useSelector } from 'react-redux';
import {
  scheduledMeetingsActions,
  locationLabels,
  cancelEventModalActions,
  editMeetingNotesModalActions,
  rescheduleEventModalActions,
  noShowEventModalActions,
  cancelReasons,
} from '../../../store/bookedMeetings';
import labels from './labels';
import { TimeFormat, userSettingsSelectors } from '../../../store/userSettings';
import { Link } from 'react-router-dom';
import { Path } from '../../../routing';
import { authenticationSelectors } from '../../../store/authentication';
import { bookingPageSelectors } from '../../../store/bookingPages';
import { formatPhoneString } from '../../../store/publicBookingPage/utils';
import {
  Bars3BottomLeftIcon,
  CalendarDaysIcon,
  ChatBubbleOvalLeftIcon,
  ChevronDownIcon,
  ChevronRightIcon,
  InformationCircleIcon,
  NoSymbolIcon,
  PhoneIcon,
  PlusIcon,
  Squares2X2Icon,
  UserIcon,
  UserMinusIcon,
  UsersIcon,
  VideoCameraIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline';
import { UserAvatar } from '../../common';

interface IBookedMeeting {
  event: CreateUserEventInput;
  isFocusOn?: boolean;
}

export const BookedMeeting = memo(({ event, isFocusOn }: IBookedMeeting) => {
  const { canceled } = event;
  const dispatch = useDispatch();
  const [isDetailsOpen, setIsDetailsOpen] = useState(isFocusOn || false);
  const bookingPageData = useSelector(bookingPageSelectors.selectBookingPageById(event.bookingPageId));
  const currentUserId = useSelector(authenticationSelectors.selectUserId);
  const timeFormat = useSelector(userSettingsSelectors.selectTimeFormat) || TimeFormat.default;
  const ref = useRef<HTMLDivElement | null>(null);
  const canceledReason = (cancelReasons as { [key: string]: string })[canceled?.type || CanceledType.OTHER];

  const [isPastEvent, setIsPastEvent] = useState(new Date(event.startTime) <= new Date());

  const generateNamesString = (names: (string | null)[] | null | undefined) =>
    names?.length
      ? names.length > 2
        ? ', ' + names[0] + ', ' + names[1] + ' ... (+' + (names.length - 2) + ')'
        : ', ' + names.join(', ')
      : '';

  useEffect(() => {
    setIsPastEvent(new Date(event.startTime) <= new Date());
  }, [event.startTime]);

  useEffect(() => {
    isFocusOn && ref.current?.scrollIntoView({ block: 'nearest' });
  }, [isFocusOn]);

  const generateLocationTypeIcon = (locationType: LocationType | null | undefined) => {
    switch (locationType) {
      case LocationType.IN_PERSON:
        return <UserIcon width={16} height={16} />;
      case LocationType.PHONE_CALL:
        return <PhoneIcon width={16} height={16} />;
      case LocationType.VIDEO_CONFERENCE:
        return <VideoCameraIcon width={16} height={16} />;
      default:
        return <></>;
    }
  };

  const getLocationInfo = () => {
    if (event.location) {
      const label = locationLabels[event.location.type];
      return (
        <div className="flex-left-center gap-4px">
          {generateLocationTypeIcon(event.location.type)}
          <div className="text-label-xs-reg">{label}</div>
        </div>
      );
    }
    return <>{labels.noLocationGiven}</>;
  };

  const openRescheduleEventModal = () => {
    dispatch(scheduledMeetingsActions.selectBookedMeeting(event));
    dispatch(rescheduleEventModalActions.openModal());
  };

  const openEditBookedMeetingNotesModal = () => {
    dispatch(scheduledMeetingsActions.selectBookedMeeting(event));
    dispatch(editMeetingNotesModalActions.openModal());
  };

  const openCancelEventModal = () => {
    dispatch(scheduledMeetingsActions.selectBookedMeeting(event));
    dispatch(scheduledMeetingsActions.updateCancelReason(CanceledType.UNEXPECTED));
    dispatch(cancelEventModalActions.openModal());
  };

  const openNoShowEventModal = () => {
    dispatch(scheduledMeetingsActions.selectBookedMeeting(event));
    dispatch(noShowEventModalActions.openModal());
  };

  const undoNoShow = () => {
    dispatch(
      scheduledMeetingsActions.updateBookedMeetingRequest({
        ...event,
        isNoShow: false,
      })
    );
  };

  const isCustomFieldVisible = (field: CustomFieldInput | null): boolean => {
    if (!field?.enabled) {
      return false;
    }
    switch (field.fieldType) {
      case CustomFieldType.NAME:
      case CustomFieldType.EMAIL:
      case CustomFieldType.CUSTOM:
        return true;
      case CustomFieldType.PHONE:
        return event.location?.type === LocationType.PHONE_CALL || Boolean(field.value?.split('-')[1]);
      case CustomFieldType.LOCATION:
        return event.location?.type === LocationType.IN_PERSON;
      default:
        return false;
    }
  };

  return (
    <div
      className="sumo-card action-card border-none p-20px"
      onClick={() => setIsDetailsOpen((prev) => !prev)}
      ref={ref}
    >
      <div className="flex flex-column gap-16px">
        <div className="flex-left-center">
          <div className="flex-1 flex-left-center gap-16px">
            <div className="p-14px border-radius-8px bg-heavy-1">
              <div
                className="w-20px h-20px border-radius-4px"
                style={{ background: bookingPageData?.what?.color || '' }}
              />
            </div>
            <div className="flex flex-column gap-4px">
              <div className="flex-left-center gap-20px">
                <div className="text-title-lg-med text-heavy-100">
                  {formatDateHHMM(event.startTime, timeFormat) + ' - ' + formatDateHHMM(event.endTime, timeFormat)}
                </div>
                {canceled?.isCanceled && (
                  <div className="text-label-s-med text-tomato-main bg-tomato-light border-radius-6px px-8px py-6px">
                    {labels.canceled}
                  </div>
                )}
              </div>
              {getLocationInfo()}
            </div>
          </div>
          <Button className="button-blue card-button gap-8px" text>
            {isDetailsOpen ? <ChevronDownIcon width={18} height={18} /> : <ChevronRightIcon width={18} height={18} />}
            <div>{labels.details}</div>
          </Button>
        </div>

        <div className="flex-left-center -m-6px">
          <div className="flex-left-center gap-8px w-4 p-6px">
            <div className="flex p-4px border-radius-8px bg-heavy-1">
              <Squares2X2Icon width={24} height={24} />
            </div>
            <div className="flex flex-column gap-6px">
              <div className="text-label-xs-reg">{labels.bookingPage}</div>
              <Link
                className="text-label-md-med text-heavy-100 hover:underline"
                target="_blank"
                to={Path.EditBookingPage.replace(':bookingPageId', event.bookingPageId || '')}
              >
                {event.bookingPageName}
              </Link>
            </div>
          </div>

          <div className="flex-left-center gap-8px w-4 p-6px">
            <UserAvatar name={event.hostName || ''} className="border-radius-8px" noImage/>
            <div className="flex flex-column gap-6px">
              <div className="text-label-xs-reg">{labels.hosts}</div>
              <div className="text-label-md-med text-heavy-100">
                <span>{event.hostName}</span>
                <span className="text-label-md-reg">{generateNamesString(event.cohostNames)}</span>
              </div>
            </div>
          </div>

          <div className="flex-left-center gap-8px w-4 p-6px">
            <div className="flex p-4px border-radius-8px bg-heavy-1">
              <UserIcon width={24} height={24} />
            </div>
            <div className="flex-bottom gap-10px">
              <div className="flex flex-column gap-6px">
                <div className="text-label-xs-reg">{labels.guest}</div>
                <div className="text-label-md-med text-heavy-100 overflow-wrap">
                  {event.inputFields?.find((field) => field?.fieldType === CustomFieldType.NAME)?.value || ''}
                </div>
              </div>
              {event?.isNoShow && (
                <div className="flex-left-center gap-4px text-label-s-med text-tomato-main">
                  <div className="flex">
                    <UserMinusIcon width={13} height={13} strokeWidth={2.4} />
                  </div>
                  <div>{labels.noShowLabel}</div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      {isDetailsOpen && (
        <>
          <div className="flex -m-6px py-20px">
            <div className="flex gap-8px w-4 p-6px">
              <div className="flex p-4px border-radius-8px bg-heavy-1 h-fit">
                <UsersIcon width={24} height={24} />
              </div>
              <div className="flex flex-column gap-5px">
                <div className="text-label-xs-reg">{`${labels.attendees} (${event.guestEmails?.length || 0})`}</div>
                {event.guestEmails?.length ? (
                  <div className="text-body-s-reg text-heavy-100">
                    {event.guestEmails?.map((email) => (
                      <div key={event.eventId + email}>{email}</div>
                    ))}
                  </div>
                ) : (
                  <div className="text-body-s-reg text-heavy-80">{labels.noAttendees}</div>
                )}
              </div>
            </div>

            <div className="flex flex-column gap-20px w-8 p-6px">
              {!!canceled?.isCanceled && (
                <div className="flex gap-8px">
                  <div className="flex p-4px border-radius-8px bg-heavy-1 h-fit">
                    <NoSymbolIcon className="icon-24px" />
                  </div>
                  <div className="flex flex-column gap-5px">
                    <div className="text-label-xs-reg">{labels.cancelReason.slice(0, -1)}</div>
                    <div className="text-body-s-reg text-heavy-100">{canceledReason}</div>
                    {event.canceled?.note && (
                      <div className="text-body-s-reg text-heavy-80 white-space-pre-line overflow-wrap">
                        {event.canceled?.note}
                      </div>
                    )}
                  </div>
                </div>
              )}

              <div className="flex gap-8px">
                <div className="flex p-4px border-radius-8px bg-heavy-1 h-fit">
                  <ChatBubbleOvalLeftIcon width={24} height={24} />
                </div>
                <div className="flex flex-column gap-5px">
                  <div className="text-label-xs-reg">{labels.noteFromInvitee}</div>
                  {event.note ? (
                    <div className="text-body-s-reg text-heavy-100 white-space-pre-line overflow-wrap">
                      {event.note}
                    </div>
                  ) : (
                    <div className="text-body-s-reg text-heavy-80">{labels.noNote}</div>
                  )}
                </div>
              </div>

              <div className="flex gap-8px">
                <div className="flex p-4px border-radius-8px bg-heavy-1 h-fit">
                  <InformationCircleIcon width={24} height={24} />
                </div>
                <div className="flex-1 flex flex-column">
                  <div className="text-label-xs-reg pt-10px pb-6px">{labels.guestAnswers}</div>
                  <div className="flex flex-column gap-10px text-body-s-reg text-heavy-80">
                    {event.inputFields?.map(
                      (customField) =>
                        isCustomFieldVisible(customField) &&
                        customField && (
                          <div
                            key={customField.id}
                            className="flex flex-column gap-8px pb-16px text-body-s-reg text-heavy-80 border-bottom-1 border-heavy-20"
                          >
                            <div>{customField.label}</div>
                            <div className="text-heavy-100 white-space-pre-line overflow-wrap">
                              {customField?.fieldType === CustomFieldType.PHONE
                                ? formatPhoneString(customField.value)
                                : customField.value}
                            </div>
                          </div>
                        )
                    ) || labels.noAnswers}
                  </div>
                </div>
              </div>

              <div className="flex flex-column gap-8px">
                <div className="flex-left-center gap-8px">
                  <div className="flex p-4px border-radius-8px bg-heavy-1 h-fit">
                    <Bars3BottomLeftIcon width={24} height={24} />
                  </div>
                  <div className="flex flex-column gap-5px">
                    <div className="text-label-xs-reg">{labels.internalNote}</div>
                    <div className="text-body-s-reg text-heavy-80">{labels.internalNoteDescription}</div>
                  </div>
                  <Button
                    className="button-blue gap-8px ml-auto"
                    text
                    onClick={(e) => {
                      e.stopPropagation();
                      openEditBookedMeetingNotesModal();
                    }}
                  >
                    <PlusIcon width={18} height={18} />
                    <div>{labels.addNote}</div>
                  </Button>
                </div>
                {event.internalNotes ? (
                  <div
                    className="pl-40px text-body-s-reg text-heavy-100"
                    dangerouslySetInnerHTML={{ __html: event.internalNotes || '' }}
                  />
                ) : (
                  <div className="pl-40px text-body-s-reg text-heavy-80">{labels.noNote}</div>
                )}
              </div>
            </div>
          </div>

          <div className="flex-between pt-20px px-20px -mx-20px border-top-1 border-heavy-20">
            <div>
              {!canceled?.isCanceled && !isPastEvent && (
                <Button
                  className="gap-8px"
                  text
                  onClick={(e) => {
                    e.stopPropagation();
                    openCancelEventModal();
                  }}
                >
                  <XMarkIcon width={18} height={18} />
                  <div>{labels.cancel}</div>
                </Button>
              )}
            </div>
            <div className="flex-center gap-6px">
              {!canceled?.isCanceled && isPastEvent && event.userId === currentUserId && (
                <Button
                  className="gap-8px"
                  text
                  onClick={(e) => {
                    e.stopPropagation();
                    event.isNoShow ? undoNoShow() : openNoShowEventModal();
                  }}
                >
                  <UserMinusIcon width={18} height={18} />
                  <div>{event.isNoShow ? labels.undoNoShow : labels.markAsNoShow}</div>
                </Button>
              )}
              {!canceled?.isCanceled && !isPastEvent && (
                <Button
                  className="-my-8px gap-8px button-blue"
                  outlined
                  onClick={(e) => {
                    e.stopPropagation();
                    openRescheduleEventModal();
                  }}
                >
                  <CalendarDaysIcon width={18} height={18} />
                  <div>{labels.reschedule}</div>
                </Button>
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
});

BookedMeeting.displayName = 'BookedMeeting';
