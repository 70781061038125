import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { InputTextarea } from 'primereact/inputtextarea';
import { eventActions, eventSelectors, CANCEL_TYPE_OPTIONS } from '../../../store/publicBookingPage';
import { CanceledType } from '../../../API';
import labels from './labels';
import { Dropdown } from 'primereact/dropdown';
import { MAX_LENGTH_ABOUT_150_WORDS } from '../../../types/constants';
import { XCircleIcon } from '@heroicons/react/24/outline';

export const PublicBookingPageCancel = () => {
  const dispatch = useDispatch();

  const cancelPolicy = useSelector(eventSelectors.selectEventCancelPolicy);
  const type = useSelector(eventSelectors.selectEventCanceledType);
  const note = useSelector(eventSelectors.selectEventCanceledNote);

  const [localNote, setLocalNote] = useState(note || '');

  useEffect(() => {
    if (!type) {
      dispatch(eventActions.updateCanceled({ type: CanceledType.UNEXPECTED }));
    }
  }, []);

  const handleChangeNote = (note: string) => {
    setLocalNote(note);
  };

  const handleBlurNote = () => {
    dispatch(eventActions.updateCanceled({ note: localNote }));
  };

  const handleChangeType = (type: CanceledType) => {
    dispatch(eventActions.updateCanceled({ type }));
  };

  return (
    <div className="booking-info-container">
      <div className="booking-info gap-32px">
        {cancelPolicy && (
          <div className="flex flex-column gap-16px">
            <div className="flex-left-center gap-6px text-label-s-med text-heavy-60">
              <XCircleIcon className="icon-20px" />
              <div>{labels.cancelPolicy}</div>
            </div>
            <div className="text-body-lg-reg text-heavy-100">{cancelPolicy}</div>
          </div>
        )}

        <div className="flex flex-column gap-16px">
          <div className="text-label-s-med text-heavy-60">{labels.type}</div>
          <Dropdown value={type} options={CANCEL_TYPE_OPTIONS} onChange={(e) => handleChangeType(e.value)} />
        </div>

        <div className="flex flex-column gap-16px">
          <div className="text-label-s-med text-heavy-60">{labels.reason}</div>
          <InputTextarea
            autoResize
            rows={4}
            value={localNote}
            onChange={(e) => handleChangeNote(e.target.value.trimStart())}
            onBlur={handleBlurNote}
            maxLength={MAX_LENGTH_ABOUT_150_WORDS}
          />
        </div>
      </div>
    </div>
  );
};
