import { InformationCircleIcon, PlusIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { InputText } from 'primereact/inputtext';
import { useDispatch, useSelector } from 'react-redux';
import { bookingPageSelectors } from '../../../store/bookingPages';
import { MAX_LENGTH_NAME, MIN_ITEMS_FOR_SEARCH } from '../../../types/constants';
import { MultiSelect, MultiSelectChangeEvent } from 'primereact/multiselect';
import { Button } from 'primereact/button';
import { DescriptionBookingPageInput, UpdateBookingPageInput } from '../../../API';
import { getTimeUnitLabel } from '../../../services/utils';
import { groupBookingPagesActions, groupBookingPagesSelectors } from '../../../store/groupBookingPages';
import labels from './labels';
import { SumoTooltip } from '../../common';
import { bookingTemplatesSelectors } from '../../../store/bookingTemplates';

export const GroupBookingPageBasicSettings = () => {
  const dispatch = useDispatch();
  const groupBookingPage = useSelector(groupBookingPagesSelectors.selectGroupBookingPage);
  const isNameValid = useSelector(groupBookingPagesSelectors.selectIsGroupBookingPageNameValid);
  const bookingPageOptions = useSelector(bookingPageSelectors.selectFilteredBookingPages);
  const bookingPages = useSelector(groupBookingPagesSelectors.selectGroupBookingPageBookingPages);
  const bookingPageIds = useSelector(groupBookingPagesSelectors.selectGroupBookingPageBookingPageIds);
  const bookingPagesById = useSelector(bookingPageSelectors.selectBookingPagesById);
  const bookingTemplateNamesById = useSelector(bookingTemplatesSelectors.selectBookingTemplateNamesById);

  const handleNameChange = (name: string) => {
    dispatch(groupBookingPagesActions.updateGroupBookingPage({ name }));
  };

  // const handleAdminOnlyChange = (adminOnly: boolean) => {
  //   dispatch(groupBookingPagesActions.updateGroupBookingPage({ adminOnly }));
  // };

  const getBookingPageItemTemplate = (option: UpdateBookingPageInput) => (
    <div className="flex flex-column gap-4px">
      <div className="text-label-s-med text-heavy-80">{option.what?.customName}</div>
      <div className="flex-left-center text-label-xs-reg text-heavy-60">
        <div>{option.displayId}</div>
        <div className="w-4px h-4px border-radius-2px mx-6px bg-heavy-50" />
        <div>{`${option.when?.duration?.count} ${getTimeUnitLabel(option.when?.duration?.timeUnit)}`}</div>
        {option?.what?.bookingTemplateId && (
          <>
            <div className="w-4px h-4px border-radius-2px mx-6px bg-heavy-50" />
            <div>{bookingTemplateNamesById[option?.what?.bookingTemplateId]}</div>
          </>
        )}
      </div>
    </div>
  );

  const getBookingPageValueTemplate = () => (
    <Button className="button-add h-24px justify-content-center">
      <PlusIcon width={16} height={16} />
    </Button>
  );

  const handleBookingPageChange = (e: MultiSelectChangeEvent) => {
    const newIds: string[] = e.target.value;
    let newBookingPages: Array<DescriptionBookingPageInput | null> = [];
    if (newIds.length > bookingPageIds.length) {
      // new selected
      newBookingPages = [...bookingPages];
      newIds.forEach((id) => {
        !bookingPageIds.includes(id) && newBookingPages.push({ bookingPageId: id });
      });
    } else {
      // some options unselected
      newBookingPages = bookingPages.filter((record) => record?.bookingPageId && newIds.includes(record.bookingPageId));
    }
    dispatch(groupBookingPagesActions.updateGroupBookingPage({ bookingPages: newBookingPages }));
  };

  const handleBookingPageRemoveChange = (id: string) => {
    const newBookingPages = bookingPages.filter((record) => record?.bookingPageId && record.bookingPageId !== id);

    dispatch(groupBookingPagesActions.updateGroupBookingPage({ bookingPages: newBookingPages }));
  };

  return (
    <div className="flex flex-column text-heavy-100 gap-24px p-fluid">
      <div className="text-blue-main flex-left-center gap-6px p-6px border-radius-6px bg-blue-soft">
        <div className="w-18px h-18px">
          <InformationCircleIcon width={18} height={18} />
        </div>
        <div className="text-label-xs-reg">{labels.infoMessage}</div>
      </div>
      <div className="grid -mb-2">
        <div className="col-12 lg:col-6 lg:pr-4 flex flex-column gap-10px">
          <div className="flex-left-center gap-8px">
            <div className="text-title-xs-med">{labels.nameTitle}</div>
            <SumoTooltip text={labels.nameTooltip} />
          </div>
          <InputText
            value={groupBookingPage.name || ''}
            onChange={(e) => handleNameChange(e.target.value)}
            placeholder={labels.namePlaceholder}
            maxLength={MAX_LENGTH_NAME}
            className={`${!isNameValid ? 'p-invalid' : ''}`}
          />
        </div>
        {/* <div className="col-12 lg:col-6 lg:pl-4 flex-bottom">
          <div className="text-title-xs-med flex-left-center gap-12px h-fit py-10px">
            <InputSwitch
              inputId="adminOnly"
              checked={groupBookingPage.adminOnly || false}
              onChange={(e) => handleAdminOnlyChange(!!e.value)}
            />
            <label htmlFor="adminOnly" className="cursor-pointer -mr-4px">
              {labels.adminOnly}
            </label>
            <SumoTooltip text={labels.adminOnlyTooltip} />
          </div>
        </div> */}
      </div>

      <div>
        <div className="text-title-xs-med ">{labels.bookingPages}</div>
        <div className="text-body-s-reg text-heavy-60 mt-4px">{labels.bookingPagesDesc}</div>

        <div className="w-12 lg:w-6 lg:pr-4 pt-14px pb-10px">
          <MultiSelect
            options={bookingPageOptions}
            optionValue="id"
            onChange={handleBookingPageChange}
            value={bookingPageIds}
            filter={bookingPageOptions.length >= MIN_ITEMS_FOR_SEARCH}
            filterBy="displayId,what.customName"
            itemTemplate={getBookingPageItemTemplate}
            selectedItemTemplate={getBookingPageValueTemplate}
            pt={{
              trigger: { className: 'hidden' },
              label: { className: 'p-0' },
              root: { className: 'border-none pr-30px' },
            }}
          />
        </div>
        {!!bookingPageIds.length && (
          <div className="grid -mb-2">
            {bookingPageIds.map((id, index) => {
              const page = bookingPagesById[id];
              return (
                <div key={id} className={`col-12 lg:col-6 flex-center ${index % 2 === 0 ? 'lg:pr-4' : 'lg:pl-4'}`}>
                  <div className="border-1 border-heavy-20 border-radius-8px p-8px w-full flex-left-center gap-8px">
                    <div className="p-8px bg-heavy-1 border-radius-6px">
                      <div
                        className="w-16px h-16px border-radius-4px"
                        style={{ backgroundColor: page.what?.color || '' }}
                      />
                    </div>
                    {getBookingPageItemTemplate(page)}
                  </div>
                  <Button
                    text
                    onClick={() => handleBookingPageRemoveChange(page.id)}
                    className="ml-6px button-secondary w-fit"
                  >
                    <XMarkIcon width={16} height={16} className="m-4px" />
                  </Button>
                </div>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
};
