import { useDispatch } from 'react-redux';
import {
  bookingTemplatesActions,
  bookingTemplatesSelectors,
  SMART_ALERT_MODAL_NAME,
  smartAlertModalActions,
  SmartAlertModalRequest,
  smartAlertModalSelectors,
} from '../../../../store/bookingTemplates';
import { Modal } from '../../modal/Modal';
import labels from './labels';
import { SmartAlertCard, SmartAlertEdit } from '../../../smartAlerts';
import { useSelector } from 'react-redux';
import { DEFAULT_SMART_ALERT, smartAlertsActions, smartAlertsSelectors } from '../../../../store/smartAlerts';
import {
  bookingPagesActions,
  bookingPageSelectors,
  confirmOverrideSmartAlertModalActions,
} from '../../../../store/bookingPages';
import { SmartAlert } from '../../../../API';
import { useEffect, useState } from 'react';
import { userSettingsSelectors } from '../../../../store/userSettings';

type SmartAlertModalProps = {
  actions: typeof bookingTemplatesActions | typeof bookingPagesActions;
  selectors: typeof bookingTemplatesSelectors | typeof bookingPageSelectors;
  isReadOnly?: boolean;
};
export const SmartAlertModal = ({ actions, selectors, isReadOnly }: SmartAlertModalProps) => {
  const dispatch = useDispatch();
  const props: SmartAlertModalRequest = useSelector(
    smartAlertModalSelectors.selectModalRequest
  ) as SmartAlertModalRequest;
  const isBookingPage = useSelector(selectors.selectIsBookingPageSelector);
  const smartAlerts = useSelector(smartAlertsSelectors.selectSmartAlertsToUse);
  const smartAlertsInUse = useSelector(selectors.selectSmartAlerts);
  const isCreate = useSelector(userSettingsSelectors.selectSmartAlertsCreate);
  const [isCreateAlert, setIsCreateAlert] = useState(!!props?.isEdit);
  const alertId = useSelector(smartAlertsSelectors.selectSmartAlertId);
  const showWarning = useSelector(smartAlertsSelectors.selectShowWarningModal(alertId));
  const isAlertReadOnly = useSelector(smartAlertsSelectors.selectIsAlertReadOnly(alertId, 'alert'));

  useEffect(() => {
    setIsCreateAlert(!!props?.isEdit);
  }, [props]);

  const handleClose = () => {
    dispatch(smartAlertModalActions.closeModal());
    setIsCreateAlert(false);
  };

  const handleUseAlert = (alert: SmartAlert) => {
    dispatch(actions.updateSmartAlerts(alert.id));
    handleClose();
  };

  const handleCreateNew = () => {
    dispatch(smartAlertsActions.setSmartType('alert'));
    dispatch(smartAlertsActions.setSmartAlert(DEFAULT_SMART_ALERT));
    setIsCreateAlert(true);
  };

  const handleSave = () => {
    if (showWarning) {
      dispatch(
        confirmOverrideSmartAlertModalActions.openModal({
          upsertType: props?.isEdit ? 'none' : isBookingPage ? 'bookingPage' : 'bookingTemplate',
        })
      );
    } else {
      dispatch(
        smartAlertsActions.upsertSmartTypeRequest(
          props?.isEdit ? 'none' : isBookingPage ? 'bookingPage' : 'bookingTemplate'
        )
      );
    }
    handleClose();
  };

  return (
    <Modal.Container
      name={SMART_ALERT_MODAL_NAME}
      closable={true}
      onClose={handleClose}
      className="w-11 h-full"
      contentClassName="bg-heavy-1 py-24px px-56px"
    >
      <div className="flex-1 text-title-xl-med">
        {props?.isEdit ? labels.editTitle : isCreateAlert ? labels.createTitle : labels.title}
      </div>
      {isCreateAlert ? (
        <SmartAlertEdit
          hideTypeChange={true}
          hideApplyTo={true}
          handleCancel={handleClose}
          handleSave={handleSave}
          isReadOnly={isReadOnly || isAlertReadOnly}
        />
      ) : (
        <div className="flex flex-column gap-24px">
          <div className="text-body-lg-reg text-heavy-60 pt-8px">{labels.description}</div>
          <div className="grid -mb-2">
            {isCreate && (
              <div className="col-12 md:col-6 lg:col-4 " style={{ minHeight: '170px' }}>
                <div className="h-full sumo-card sumo-create-card action-card flex-center" onClick={handleCreateNew}>
                  {labels.createCustomTitle}
                </div>
              </div>
            )}

            {smartAlerts
              .filter((alert) => !smartAlertsInUse.includes(alert.id))
              .map((alert) => (
                <div className="col-12 md:col-6 lg:col-4" key={alert.id}>
                  <SmartAlertCard alert={alert} useAlert={() => handleUseAlert(alert)} />
                </div>
              ))}
          </div>
        </div>
      )}
    </Modal.Container>
  );
};
