import { PencilIcon, TrashIcon } from '@heroicons/react/24/outline';
import { JourneyCard } from '../../../API';
import { useEffect, useState } from 'react';
import { HeroIconComponent, HeroIconKey } from '../../../types/types';
import { HeroIcons } from '../../../types/constants';
import { ReactComponent as DragAndDrop } from '../../../assets/icons/20-drag-and-drop.svg';

type AddPageCardCardProps = {
  card: JourneyCard;
  handleEdit: () => void;
  handleDelete: () => void;
  isDeletable: boolean;
  isReadOnly: boolean;
};

export const AddPageCardCard = ({ card, handleEdit, handleDelete, isDeletable, isReadOnly }: AddPageCardCardProps) => {
  const [Icon, setIcon] = useState<HeroIconComponent | null>(null);

  useEffect(() => {
    setIcon(card.icon ? (HeroIcons[card.icon as HeroIconKey] as HeroIconComponent) : null);
  }, [card]);

  return (
    <div className="flex flex-row">
      {!isReadOnly && (
        <div className="relative w-0 h-20px drag-and-drop">
          <DragAndDrop className="absolute mr-2px right-0" />
        </div>
      )}
      <div className="journey-page-card">
        <div className="flex-left-center gap-8px">
          {Icon && <Icon width={28} height={28} className="text-heavy-60" />}
          <div className="text-title-xs-med text-heavy-100">{card.title || ''}</div>
        </div>
        <div className="text-body-s-reg text-heavy-60">{card.details || ''}</div>
      </div>
      
      {!isReadOnly && (
        <div className="flex gap-4px ml-20px">
          <div className="action-button-sm" onClick={handleEdit}>
            <PencilIcon className="icon-16px" />
          </div>
          <div className="w-24px h-24px">
            <div className={`action-button-sm ${isDeletable ? '' : 'cursor-auto p-disabled'}`} onClick={handleDelete}>
              <TrashIcon className="icon-16px" />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
