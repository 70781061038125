export const CannotDeleteWorkspace = Object.freeze({
  DESCRIPTION: `The following users are assigned to use only this workspace`,
  NOTES: `You must first assign these users to another workspace, before deleting this one.`,
  TITLE: `Reassign Users`,
});

export const WorkspaceToastsNotifications = Object.freeze({
  ACTIVATE_INACTIVATE_WORKSPACE_ERROR_MESSAGE: `Workspace activate/inactive request fail`,
  ACTIVATE_WORKSPACE_SUCCESS_MESSAGE: `Workspace successfully activated`,
  DELETE_WORKSPACE_ERROR_MESSAGE: `Delete workspace request fail`,
  DELETE_WORKSPACE_SUCCESS_MESSAGE: `Workspace successfully deleted`,
  GET_WORKSPACES_ERROR_MESSAGE: `Get workspaces request fail`,
  INACTIVATE_WORKSPACE_SUCCESS_MESSAGE: `Workspace successfully deactivated`,
  REMOVE_INTEGRATION_SUCCESS_TOAST: `Workspace integration successfully disconected`,
  SAVE_WORKSPACE_ERROR_MESSAGE: `Save workspace request fail`,
  SAVE_WORKSPACE_SUCCESS_MESSAGE: `Workspace successfully saved`,
  CLONE_WORKSPACE_ERROR_MESSAGE: `Clone workspace request fail`,
  CLONE_WORKSPACE_SUCCESS_MESSAGE: `Workspace successfully cloned`,
});

export const WorkspaceWhoStep = Object.freeze({
  ADMINS_TITLE: `Admins`,
  ADMIN_DESCRIPTION: `The following people may admin this workspace.`,
  INVITE_SETTINGS_TITLE: `Invite Settings`,
  NOTES_PART_1: `Alternatively, you may use the `,
  NOTES_PART_2: `User Management`,
  NOTES_PART_3: ` section to find a user and add them to a workspace.`,
  PHONE_NUMBER_DESCRIPTION: `If you plan to use SUMO to book phone calls, it is recommended that you keep it enabled.`,
  PHONE_NUMBER_TITLE: `Make Users "Phone Number" field required.`,
  SEARCH_ADD_ADMINS: `Search & Add Admins`,
  SEARCH_ADD_USERS: `Search & Add Users`,
  SUPER_ADMINS_DESCRIPTION: `Super Admins will be always prepopulated`,
  USERS_DESCRIPTION: `The following people may use this workspace.`,
  USERS_TITLE: `Users`,
});

export const Workspaces = Object.freeze({
  ACTIVE: `Active`,
  ADMIN_TITLE: `Admin`,
  CLONE_MODAL_TITLE: `Clone Workspace`,
  DEFAULT_WORKSPACE: `Default Workspace`,
  DELETE_DESCRIPTION: `Are you sure you would like to delete`,
  INACTIVE: `Inactive`,
  NAME_TITLE: `Workspace Name`,
  NAME_PLACEHOLDER: `Enter a workspace name`,
  NEW_WORKSPACE: `New Workspace`,
  SELECTED_WORKSPACES: `selected workspaces`,
  TITLE: `Workspaces`,
  BRANDING_TITLE: `Branding page customization`,
  BRANDING_DESCRIPTION: `Customize the appearance, styles, and labels for new booking pages.`,
});

export const EditWorkspace = Object.freeze({
  WHAT_TITLE: `What`,
  WHAT_DESCRIPTION: `Name and status`,
  WHO_TITLE: `Who`,
  WHO_DESCRIPTION: `Admins and users`,
  HOW_TITLE: `Branding`,
  HOW_DESCRIPTION: `Branding styles and displays`,
  ADDITIONAL_CONFIGURATIONS: `Additional Configurations`,
});
