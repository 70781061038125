import { Path } from '../../../routing';
import { Authentication } from '../../common';
import { navigationService } from '../../../services/NavigationService';

export const SignUp = () => {
  const userLogin = () => {
    navigationService.navigateTo(Path.Login);
  };

  return <Authentication linkAction={userLogin} login={false} />;
};
