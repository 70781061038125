// https://www.example.com
export const getUrlOrigin = () => window.location.origin;

// https://www.example.com/path/page?query=123
export const getUrlHref = () => window.location.href;

// https://www.example.com/path/page
export const getUrlWithoutParams = () => {
  const url = new URL(window.location.href);
  url.search = ''; // clear all query params
  return url.href;
};

// query=123 => {query: '123'}
export const getUrlParams = () => {
  const urlParams = new URLSearchParams(location.search);
  const params: { [key: string]: string | null } = {};
  urlParams.forEach((value, key) => {
    params[key] = value;
  });
  return params;
};

export const HTTP = 'http://';
export const HTTPS = 'https://';
export const validateURL = (url: string) => {
  const emailRegex = /^(https?:\/\/)(?:www\.)?[a-z0-9-]+(?:\.[a-z0-9-]+)+[^\s]*$/i;
  return emailRegex.test(url);
};
