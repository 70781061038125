import { PlusIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { TimeFromToInput } from '../../../API';
import { TimeFormat } from '../../../store/userSettings';
import { calcFromToTimeError } from '../../../services/DateService';

type AvailabilityTimeIntervalsProps = {
  time: (TimeFromToInput | null)[] | null | undefined;
  isReadonly?: boolean;
  timeFormat: string;
  addInterval: () => void;
  removeInterval: (index: number) => void;
  handleTimeFromChange: (index: number, value: Date) => void;
  handleTimeToChange: (index: number, value: Date) => void;
  isFirstIntervalRemovable?: boolean;
};

export const AvailabilityTimeIntervals = ({
  time,
  isReadonly,
  timeFormat,
  addInterval,
  removeInterval,
  handleTimeFromChange,
  handleTimeToChange,
  isFirstIntervalRemovable,
}: AvailabilityTimeIntervalsProps) => {
  return (
    <div className="flex flex-column gap-4px">
      {!!time?.length &&
        time.map((time, index) => (
          <div key={index} className="flex align-items-center">
            <Calendar
              inputClassName="w-80px text-body-s-reg text-center lowercase py-8px px-10px"
              dateFormat="HH:mm"
              hourFormat={timeFormat === TimeFormat.default ? '12' : '24'}
              value={new Date(`1970-01-01T${time?.from}:00`)}
              timeOnly
              onChange={(e) => {
                e.target.value instanceof Date && handleTimeFromChange(index, e.target.value);
              }}
              disabled={isReadonly}
            />
            <span className="mx-2px text-body-s-reg text-heavy-60">-</span>
            <Calendar
              inputClassName={`w-80px text-body-s-reg text-center lowercase py-8px px-10px ${
                calcFromToTimeError(time?.from, time?.to) ? 'border-tomato-dark' : ''
              }`}
              dateFormat="HH:mm"
              hourFormat={timeFormat === TimeFormat.default ? '12' : '24'}
              value={new Date(`1970-01-01T${time?.to}:00`)}
              timeOnly
              onChange={(e) => {
                e.target.value instanceof Date && handleTimeToChange(index, e.target.value);
              }}
              disabled={isReadonly}
            />
            <Button
              text
              onClick={() => removeInterval(index)}
              className="button-secondary ml-4px"
              disabled={isReadonly}
              style={{ visibility: index !== 0 || isFirstIntervalRemovable ? 'visible' : 'hidden' }}
            >
              <XMarkIcon width={16} height={16} />
            </Button>
          </div>
        ))}
      {!!time?.length && (
        <div className="flex">
          <Button
            onClick={() => addInterval()}
            className="button-add w-full justify-content-center"
            disabled={isReadonly}
          >
            <PlusIcon width={16} height={16} />
          </Button>
          <div className="w-24px"></div>
        </div>
      )}
    </div>
  );
};
