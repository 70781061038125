export const Journey = Object.freeze({
  TITLE: `Constructeur de parcours`,
  CLONE_TITLE: `Entrez le nom du parcours cloné`,
  ADD_LABEL: `Créer un parcours`,
  CREATED_BY: `Créé par`,
  EMPTY_LIST_DESCRIPTION: `Créez un parcours ou ajustez votre recherche ou vos filtres.`,
  RESPONSE: `réponse`,
  RESPONSES: `réponses`,
  LAST: `Dernier`,
  DEACTIVATE_TITLE: `Désactiver le parcours`,
  DEACTIVATE_DESCRIPTION: `Êtes-vous sûr de vouloir désactiver`,
  DELETE_TITLE: `Supprimer le parcours`,
  DELETE_DESCRIPTION: `Êtes-vous sûr de vouloir supprimer`,
  SELECTED_JOURNEY: `parcours sélectionnés`,
  NAME_LABEL: `Nom`,
});

export const EditJourney = Object.freeze({
  JOURNEY_TITLE: `Voyage`,
  RESPONSES_TITLE: `Réponses`,
  DELETE_STEP_TITLE: `Supprimer l'étape`,
  DELETE_STEP_DESCRIPTION: `Êtes-vous sûr de vouloir supprimer cette étape ?`,
  DELETE_STEP_ADDITIONAL_TEXT: `Veuillez noter que cette action supprimera également définitivement toutes les étapes enfants suivantes liées à celle-ci.`,
  START_NODE_TITLE: `Votre voyage commence ici`,
  CONDITION_TITLE: `Condition`,
  DESTINATION_TITLE: `Destination`,
  PAGE_TITLE: `Page`,
  WITH_CARDS: `avec cartes`,
  WITH_FORM: `avec formulaire`,
  IF_LABEL: `Si`,
  OR_LABEL: `Ou`,
  ADD_LABEL: `Ajouter`,
  CUSTOM_NAME: `Nom personnalisé`,
  CREATE_JOURNEY: `Créer un voyage`,
  EDIT_JOURNEY: `Modifier le voyage`,
  CARD: `Carte`,
  LOGIC: `Logique`,
  PREVIEW_JOURNEY: `Aperçu du voyage`,
});

export const JourneysPageStep = Object.freeze({
  SELECTION_BOX: `Boîte de sélection`,
  CARD_TITLE: `Titre ici*`,
  CARD_DETAILS: `Détails ici*`,
  FORM_INPUT_TILE: `Titre de l'entrée`,
  FORM_INPUT_SUBTITLE: `Sous-titre de l'entrée`,
  FORM_WRITE_QUESTION: `Écrire une question`,
  FORM_INPUT_REQUIRED: `Ce champ est obligatoire`,
  FORM_QUESTION_REQUIRED: `Cette question est obligatoire`,
  FORM_ANSWER: `Réponse`,
  FORM_ADD_ANSWER: `+ Ajouter une réponse`,
  TITLE: `Titre`,
  TITLE_PLACEHOLDER: `Saisir le titre de la page`,
  NAME: `Nom`,
  PAGE_NAME: `Nom de la page`,
  PAGE_TYPE: `Type de page`,
  NO_ELEMENTS: `Aucun élément ajouté`,
  INPUTS: `Entrées`,
  SELECTION: `Sélection`,
  SELECT_TYPE: `Sélectionnez d'abord le type`,
});

export const JourneysDestinationStep = Object.freeze({
  TYPE_LABEL: `Type de destination`,
  SHOWS_STEP_SEPARATLY: `Afficher les étapes séparément`,
  WHO_DESCRIPTION: `Liste des spécialistes à sélectionner`,
  WHEN_DESCRIPTION: `Calendrier et emploi du temps`,
  WHERE_DESCRIPTION: `Carte avec bureaux à sélectionner`,
  HEADER_LABEL: `En-tête`,
  MESSAGE_LABEL: `Message`,
  URL_LABEL: `URL externe`,
});

export const JourneyDefault = Object.freeze({
  JOURNEY_NAME: `Voyage personnalisé`,
  PAGE_NAME: `Personnalisé`,
  SUBMIT_LABEL: `Envoyer`,
  FORM: `Formulaire`,
  CARDS: `Cartes`,
  IMPORT: `Importer`,
  TITLE: `Titre`,
  SUBTITLE: `Sous-titre`,
  NAME: `Nom`,
  SINGLE: `Ligne unique`,
  EMAIL: `E-mail`,
  MULTIPLE: `Lignes multiples`,
  PHONE: `Numéro de téléphone`,
  ATTACHMENT: `Pièce jointe`,
  DROPDOWN: `Liste déroulante`,
  RADIO_BUTTONS: `Cases à cocher`,
  IS: `est`,
  IS_NOT: `n'est pas`,
  CONTAINS: `contient`,
  DOES_NOT_CONTAIN: `ne contient pas`,
  STARTS_WITH: `commence par`,
  IS_NOT_EMPTY: `n'est pas vide`,
  IS_EMPTY: `est vide`,
  RANGE: `est une plage`,
  FILETYPE_IS: `le type de fichier est`,
  FILETYPE_IS_NOT: `le type de fichier n'est pas`,
  SIZE_IS_BIGGER_THAN: `la taille est plus grande que`,
  SIZE_IS_SMALLER_THAN: `la taille est plus petite que`,
  BOOKING_PAGE: `Page de réservation`,
  MESSAGE: `Message`,
  EXTERNAL_URL: `URL externe`,
  CHAT_AI: `Chat IA`,
});

export const JourneysToastsNotifications = Object.freeze({
  GET_JOURNEYS_ERROR_MESSAGE: `Échec de la demande de parcours`,
  DEACTIVATE_JOURNEY_SUCCESS_MESSAGE: `Parcours désactivé avec succès`,
  ACTIVATE_JOURNEY_SUCCESS_MESSAGE: `Parcours activé avec succès`,
  DEACTIVATE_JOURNEY_ERROR_MESSAGE: `Échec de la demande d'activation/désactivation du parcours`,
  CLONE_JOURNEY_SUCCESS_MESSAGE: `Parcours cloné avec succès`,
  CLONE_JOURNEY_ERROR_MESSAGE: `Échec de la demande de clonage du parcours`,
  DELETE_JOURNEYS_SUCCESS_MESSAGE: `Parcours supprimés avec succès`,
  DELETE_JOURNEYS_ERROR_MESSAGE: `Échec de la demande de suppression des parcours`,
  GET_JOURNEY_ERROR_MESSAGE: `Échec de la demande de parcours`,
  SAVE_JOURNEY_ERROR_MESSAGE: `Échec de l'enregistrement du parcours`,
  CREATE_JOURNEY_SUCCESS_MESSAGE: `Parcours créé avec succès`,
  UPDATE_JOURNEY_SUCCESS_MESSAGE: `Parcours mis à jour avec succès`,
  GET_JOURNEY_EVENTS_ERROR_MESSAGE: `Échec de la demande d'événements du parcours`,
});

export const JourneysEvent = Object.freeze({
  VIEW_EVENT: `Voir l'événement`,
  TITLE: `Réponses`,
  NO_RESPONSES: `Aucune réponse pour l'instant`,
  EMPTY_LIST_DESCRIPTION: `Ajustez votre recherche ou vos filtres.`,
  DATE_RANGE: `Plage de dates`,
  RESPONSE: `Réponse`,
  EVENT_SCHEDULED: `Événement planifié`,
  CREATED_AT: `Créé le`,
  RESULT: `Résultat`,
  ANSWERS: `Réponses`,
  DESTINATION: `Destination`,
});
