import { createSelector } from 'reselect';
import { State } from '../rootStore';
import { globalSelectors } from '../global';
import { bookingPageSelectors } from '../bookingPages';
import { isObjectDeepEqual, isOldData } from '../../services/utils';
import { isStylesEqual } from '../bookingPages/utils';
import { getUrlOrigin } from '../../services/URLService';
import { Path } from '../../routing';

const groupBookingPagesState = (state: State) => state.groupBookingPages;

const selectError = createSelector(groupBookingPagesState, (groupBookingPages) => groupBookingPages.error);
const selectCloneName = createSelector(groupBookingPagesState, (groupBookingPages) => groupBookingPages.cloneName);
const selectIsEdited = createSelector(groupBookingPagesState, (groupBookingPages) => groupBookingPages.isEdited);
const selectGroupBookingPages = createSelector(
  groupBookingPagesState,
  (groupBookingPages) => groupBookingPages.groupBookingPages
);
const selectGroupBookingPage = createSelector(
  groupBookingPagesState,
  (groupBookingPages) => groupBookingPages.groupBookingPage
);

const selectIsFetching = createSelector(groupBookingPagesState, (groupBookingPages) => groupBookingPages.isFetching);
const selectLastLoadTime = createSelector(groupBookingPagesState, (state) => state.lastLoadTime);
const selectIsSpinnerFetching = createSelector(
  selectIsFetching,
  selectLastLoadTime,
  (isFetching, loadTime) => isFetching && !isOldData(loadTime)
);
const selectIsSkeletonFetching = createSelector(
  selectIsFetching,
  selectLastLoadTime,
  (isFetching, loadTime) => isFetching && isOldData(loadTime)
);

const selectGroupBookingPagesLength = createSelector(selectGroupBookingPages, (groupBookingPages) => groupBookingPages.length)

const selectSearchedGroupBookingPages = createSelector(
  bookingPageSelectors.selectFilteredBookingPages,
  selectGroupBookingPages,
  globalSelectors.selectSearchString,
  (filteredBookingPages, groupBookingPages, searchString) =>
    groupBookingPages
      .filter(
        (groupBookingPage) =>
          groupBookingPage.name?.toLowerCase().includes(searchString.toLowerCase()) &&
          groupBookingPage.bookingPages?.some((page) =>
            filteredBookingPages.some((bookingPage) => bookingPage.id === page?.bookingPageId)
          )
      )
      .sort((a, b) => (a.name || '').localeCompare(b.name || ''))
);

const selectGroupBookingPageName = createSelector(selectGroupBookingPage, (page) => page.name);
const selectIsGroupBookingPageNameValid = createSelector(selectGroupBookingPageName, (name) => Boolean(name));
const selectGroupBookingPageBookingPages = createSelector(selectGroupBookingPage, (page) => page.bookingPages || []);
const selectGroupBookingPageBookingPageIds = createSelector(
  selectGroupBookingPageBookingPages,
  (pages) => pages?.map((page) => page?.bookingPageId).filter((id): id is string => !!id) || []
);

const selectGroupBookingPageStyle = createSelector(selectGroupBookingPage, (page) => page.style || {});
const selectGroupBookingPageBackground = createSelector(selectGroupBookingPageStyle, (style) => style?.backgroundImage);
const selectGroupBookingPageLabels = createSelector(selectGroupBookingPage, (page) => page.labels);
const selectBrandingBookingPagId = createSelector(selectGroupBookingPage, (page) => page.brandingBookingPageId);

const selectIsGroupBookingPageValid = createSelector(
  selectGroupBookingPage,
  selectIsGroupBookingPageNameValid,
  (page, isNameValid) => (page.bookingPages?.length || 0) > 1 && isNameValid
);

const selectIsCloneNameValid = createSelector(selectCloneName, (name) => Boolean(name));

const selectGroupBookingPageOptions = createSelector(
  selectGroupBookingPageBookingPageIds,
  bookingPageSelectors.selectBookingPagesById,
  (ids, bookingPages) => ids.map((id) => id && { value: id, label: bookingPages[id].what?.customName || '' })
);

const selectIsBookingPageStyleEquals = createSelector(
  selectGroupBookingPageBookingPageIds,
  bookingPageSelectors.selectBookingPagesById,
  (ids, bookingPages) => {
    const bookingPageRecords = ids.map((id) => bookingPages[id]);

    return bookingPageRecords.every(
      (page) =>
        isStylesEqual(page.style, bookingPageRecords[0].style) &&
        isObjectDeepEqual(page.labels, bookingPageRecords[0].labels)
    );
  }
);

const selectGroupBookignPageLink = createSelector(selectGroupBookingPage, (groupBookingPage) =>
  groupBookingPage.shortLink
    ? groupBookingPage.shortLink
    : getUrlOrigin() + Path.PublicGroupBookingPage.replace(':groupBookingPageId', groupBookingPage.id)
);

export const groupBookingPagesSelectors = {
  selectIsFetching,
  selectError,
  selectCloneName,
  selectIsEdited,
  selectGroupBookingPages,
  selectGroupBookingPage,
  selectIsSpinnerFetching,
  selectIsSkeletonFetching,

  selectGroupBookingPagesLength,
  selectSearchedGroupBookingPages,
  selectGroupBookingPageName,
  selectIsGroupBookingPageNameValid,
  selectIsGroupBookingPageValid,
  selectGroupBookingPageBookingPages,
  selectGroupBookingPageBookingPageIds,
  selectIsCloneNameValid,
  selectGroupBookingPageOptions,
  selectIsBookingPageStyleEquals,
  selectGroupBookingPageStyle,
  selectGroupBookingPageLabels,
  selectGroupBookingPageBackground,
  selectBrandingBookingPagId,

  selectGroupBookignPageLink,
};
