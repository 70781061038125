import { t } from 'i18next';
import { ToastNotificationOptions } from '../../notifications';
import { AccountWithTenants } from './types';
import {
  TOAST_ERROR_COLOR,
  TOAST_ERROR_DURATION,
  TOAST_SUCCESS_COLOR,
  TOAST_SUCCESS_DURATION,
} from '../../../types/constants';

export const DEFAULT_ACCOUNT: Partial<AccountWithTenants> = {
  id: '',
  name: '',
  note: '',
  tenantIds: [],
  tenants: [],
};

export const SAVE_ACCOUNT_SUCCESS_TOAST: ToastNotificationOptions = Object.freeze({
  color: TOAST_SUCCESS_COLOR,
  autoHideDuration: TOAST_SUCCESS_DURATION,
  message: t('OPSConsoleAccounts:SAVE_ACCOUNT_SUCCESS_TOAST'),
});
export const SAVE_ACCOUNT_ERROR_TOAST: ToastNotificationOptions = Object.freeze({
  color: TOAST_ERROR_COLOR,
  autoHideDuration: TOAST_ERROR_DURATION,
  message: t('OPSConsoleAccounts:SAVE_ACCOUNT_ERROR_TOAST'),
});

export const DELETE_ACCOUNT_SUCCESS_TOAST: ToastNotificationOptions = Object.freeze({
  color: TOAST_SUCCESS_COLOR,
  autoHideDuration: TOAST_SUCCESS_DURATION,
  message: t('OPSConsoleAccounts:DELETE_ACCOUNT_SUCCESS_TOAST'),
});
export const DELETE_ACCOUNT_ERROR_TOAST: ToastNotificationOptions = Object.freeze({
  color: TOAST_ERROR_COLOR,
  autoHideDuration: TOAST_ERROR_DURATION,
  message: t('OPSConsoleAccounts:DELETE_ACCOUNT_ERROR_TOAST'),
});

export const GET_ACCOUNT_ERROR_TOAST: ToastNotificationOptions = Object.freeze({
  color: TOAST_ERROR_COLOR,
  autoHideDuration: TOAST_ERROR_DURATION,
  message: t('OPSConsoleAccounts:GET_ACCOUNT_ERROR_TOAST'),
});
export const GET_ACCOUNTS_ERROR_TOAST: ToastNotificationOptions = Object.freeze({
  color: TOAST_ERROR_COLOR,
  autoHideDuration: TOAST_ERROR_DURATION,
  message: t('OPSConsoleAccounts:GET_ACCOUNTS_ERROR_TOAST'),
});
