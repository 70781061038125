import { t } from '../../../i18n/i18n';

export default {
  clone: t('Common:CLONE'),
  delete: t('Common:DELETE'),
  edit: t('Common:EDIT'),
  activate: t('Common:ACTIVATE'),
  deactivate: t('Common:DEACTIVATE'),
  response: t('Journey:RESPONSE'),
  responses: t('Journey:RESPONSES'),
  last: t('Journey:LAST'),
  none: t('Common:NONE'),
  copyLink: t('EditBookingPage:COPY_LINK'),
  view: t('Common:VIEW'),
};
