import { TimeUnit } from '../API';
import { t } from '../i18n/i18n';
import { DATA_VALIDITY_PERIOD_MILLISECONDS, HeroIcons, languageNames } from '../types/constants';
import { HeroIconComponent, HeroIconKey } from '../types/types';

export const checkLink = (link: string) => {
  const validPattern = /^[a-z0-9-.&=_'-+,<>]*$/; // check if it only contains a-z, 0-9 and -.&=_'-+,<>
  return link.length > 2 && validPattern.test(link);
};

export const languageOptions = Object.keys(languageNames).map((key) => ({
  label: languageNames[key],
  value: key,
}));

export const changeTheme = (theme: string) => {
  const themeEl = document.getElementById('theme-link') as HTMLLinkElement;
  if (themeEl) {
    themeEl.href = `${process.env.PUBLIC_URL}/theme/theme-${theme}/blue/theme.css`;
  }
};

// handler to download CSV
export const downloadCSV = (csvContent: string, fileName: string) => {
  const blob = new Blob([csvContent], { type: 'text/csv' });
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  a.download = fileName;
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
};

export function isEqualArrays<T>(arr1: T[], arr2: T[]) {
  return JSON.stringify(arr1) === JSON.stringify(arr2);
}

export const appendPostfixToFileName = (fileName: string, postfix: string) => {
  const index = fileName.lastIndexOf('.');
  return fileName.substring(0, index) + postfix + fileName.substring(index);
};

export const validatePhoneNumber = (phoneNumber: string | null | undefined) => {
  const phoneLength = phoneNumber?.replaceAll('-', '').length || 0;
  return phoneLength >= 4 && phoneLength <= 16;
};

export const getTimeUnitLabel = (unit: TimeUnit | null | undefined) => {
  switch (unit) {
    case TimeUnit.DAY:
      return t('Common:DAYS');
    case TimeUnit.HOUR:
      return t('Common:HOURS');
    case TimeUnit.MINUTE:
    default:
      return t('Common:MINUTES');
  }
};

export const calculateMinutes = (count: number | null | undefined, unit: TimeUnit | null | undefined) => {
  switch (unit) {
    case TimeUnit.DAY:
      return 1440 * (count || 0);
    case TimeUnit.HOUR:
      return 60 * (count || 0);
    case TimeUnit.MINUTE:
    default:
      return count || 0;
  }
};

export const getInitials = (fullName: string) => {
  const words = fullName.split(' ');
  return words[1]
    ? words[0].charAt(0).toUpperCase() + words[1].charAt(0).toUpperCase()
    : words[0]?.charAt(0).toUpperCase() || 'A';
};

export const capitalizeFirstLetter = (text: string) => text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();

export const hasPreviousDuplicates = (array: (string | null)[] | null | undefined, index: number) =>
  array?.slice(0, index).find((previousValue) => previousValue === array[index]);

export const isObjectDeepEqual = (obj1: object | null | undefined, obj2: object | null | undefined) => {
  return JSON.stringify(obj1) === JSON.stringify(obj2);
};

export const HeroIconMap = Object.keys(HeroIcons).reduce((map, iconName) => {
  map[iconName] = HeroIcons[iconName as HeroIconKey] as HeroIconComponent;
  return map;
}, {} as Record<string, HeroIconComponent | null>);

export const getFileSizeString = (bytes: number): string => {
  if (bytes === 0) return '0 B';

  const k = 1024;
  const sizes = ['B', 'KB', 'MB', 'GB', 'TB'];
  const i = Math.floor(Math.log(bytes) / Math.log(k));

  const fileSize = parseFloat((bytes / Math.pow(k, i)).toFixed(2));
  return `${fileSize} ${sizes[i]}`;
};

export const isOldData = (dataLoadTime: number) =>
  new Date().getTime() - dataLoadTime > DATA_VALIDITY_PERIOD_MILLISECONDS;
