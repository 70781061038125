import { t } from '../../i18n/i18n';

export default {
  users: t('Common:USERS'),
  licenseTitle: t('License:TITLE'),
  loadingLabel1: t('Users:LOADING_LABEL_1'),
  loadingLabel2: t('Users:LOADING_LABEL_2'),
  loadingLabel3: t('Users:LOADING_LABEL_3'),
  loadingLabel4: t('Users:LOADING_LABEL_4'),
  inviteTitle: t('Users:INVITE_TITLE'),
  role: t('Users:ROLE'),
  status: t('Users:STATUS'),
  workspace: t('Users:WORKSPACE'),
};
