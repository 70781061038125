export const ConnectIntegrationPage = Object.freeze({
  LEADS: `Interessenten`,
  CONTACTS: `Kontakte`,
  CREATE: `Erstellen`,
  BENEFITS_TITLE: `Vorteile`,
  DISCONNECT_INTEGRATION: `Integration trennen`,
  DISCONNECT_GOOGLE_CALENDAR_DESCRIPTION: `Sind Sie sicher, dass Sie Google Kalender trennen möchten?`,
  DISCONNECT_GOOGLE_MEET_DESCRIPTION: `Sind Sie sicher, dass Sie Ihre Integration trennen möchten?

  Dies könnte Auswirkungen auf die Buchungsseiten haben, die früher mit dieser Integration erstellt wurden.`,
  DISCONNECT_MICROSOFT_CALENDAR_DESCRIPTION: `Sind Sie sicher, dass Sie Microsoft Office365 trennen möchten?`,
  DISCONNECT_TEAMS_DESCRIPTION: `Sind Sie sicher, dass Sie Microsoft Teams trennen möchten?

  Dies könnte Auswirkungen auf die Buchungsseiten haben, die früher mit dieser Integration erstellt wurden.`,
  DISCONNECT_ZOOM_DESCRIPTION: `Sind Sie sicher, dass Sie Zoom Meeting trennen möchten?

  Dies könnte Auswirkungen auf die Buchungsseiten haben, die früher mit dieser Integration erstellt wurden.`,
  DISCONNECT_HUBSPOT_DESCRIPTION: `Sind Sie sicher, dass Sie HubSpot trennen möchten?`,
  DISCONNECT_SALESFORCE_DESCRIPTION: `Sind Sie sicher, dass Sie Salesforce trennen möchten?`,
  DISCONNECT_GOOGLE_ANALYTICS_DESCRIPTION: `Sind Sie sicher, dass Sie Google Analytics trennen möchten?`,
  GOOGLE_ANALYTICS_BENEFITS: `Sammeln Sie aufschlussreiche Informationen darüber, wer Ihre öffentlichen Buchungsseiten besucht. /p Messen Sie die Konversionsraten und wie viele Personen Veranstaltungen buchen. /p Integrieren Sie Ihr SUMO-Konto mit Google Analytics`,
  GOOGLE_ANALYTICS_REQUIREMENTS: `Google Analytics-Konto`,
  GOOGLE_ANALYTICS_SETUP_DESC: `Geben Sie eine Universal Analytics-Tracking-ID oder eine Google Analytics 4-Mess-ID ein, um SUMO mit Ihrer Property zu verbinden. Wenn Sie beide eingeben, werden wir Conversions in beiden Ihrer Google Analytics-Properties verfolgen.`,
  GOOGLE_ANALYTICS_PLACEHOLDER_TRACKING_ID: `UA-12345678-9`,
  GOOGLE_ANALYTICS_VISIBLE_NAME_TRACKING_ID: `Universal Analytics tracking ID`,
  GOOGLE_ANALYTICS_PLACEHOLDER_MEASUREMENT_ID: `G-1234567890`,
  GOOGLE_ANALYTICS_VISIBLE_NAME_MEASUREMENT_ID: `Google Analytics 4 measurement ID`,
  GOOGLE_CALENDAR_BENEFITS: `Buchen und planen Sie automatisch Meetings in Ihrem Google-Kalender neu.`,
  GOOGLE_CALENDAR_REQUIREMENTS: `Sie müssen den Zugriff auf Ihren Google-Kalender gewähren.`,
  GOOGLE_MAPS_BENEFITS: `/p Stellen Sie Ihren Kunden eine Karte mit Ihren Standorten bereit /p Integrieren Sie Ihr SUMO-Konto mit Google Maps`,
  GOOGLE_MAPS_REQUIREMENTS: `Google Maps Platform-Konto`,
  GOOGLE_MEET_BENEFITS: `Erstellen Sie automatisch Google Meet-Meetings, wenn ein Ereignis geplant ist. Teilen Sie sofort einzigartige Konferenzdetails nach der Bestätigung.`,
  GOOGLE_MEET_REQUIREMENTS: `Je nach Konfiguration muss Ihr Google Meet-Konto-Administrator möglicherweise SUMO1 im Google Marketplace vorab genehmigen.`,
  HUBSPOT_BENEFITS: `Automatisch HubSpot-Ereignisse erstellen und mit Kunden als HubSpot-Leads, Kontakten.`,
  HUBSPOT_REQUIREMENTS: `HubSpot-Konto \n HubSpot-Admin-Rechte`,
  HUBSPOT_CREATE_EVENTS: `Hubspot-Ereignisse erstellen`,
  HUBSPOT_BOOK_USERS: `Buchen mit Hubspot-Datensätzen aus SUMO Inbox erlauben`,
  INBOX_BENEFITS: `Das beste SUMO1-Erlebnis erhalten Sie, indem Sie SUMO in Ihrer Umgebung verwenden, indem Sie die Chrome-Erweiterung / Edge-Add-on installieren. Verwenden Sie Sumo in:\n\u2022 Google Gmail im Browser\n\u2022 Microsoft Office Web im Browser`,
  INBOX_REQUIREMENTS: `Eines der folgenden muss mit SUMO1 verbunden sein:\n\u2022 Google-Konto\n\u2022 Microsoft Office365-Konto`,
  INBOX_INSTALL_CHROME_EXTENSION: `Chrome-Erweiterung installieren`,
  INBOX_INSTALL_EDGE_ADD_ON: `Edge-Add-on installieren`,
  MICROSOFT_CALENDAR_BENEFITS: `Buchen und planen Sie automatisch Meetings in Ihrem Microsoft-Kalender neu.`,
  MICROSOFT_CALENDAR_REQUIREMENTS: `Sie müssen den Zugriff auf Ihren Microsoft-Kalender gewähren.`,
  MICROSOFT_TEAMS_BENEFITS: `Erstellen Sie automatisch Microsoft Teams-Meetings, wenn ein Ereignis geplant ist. Teilen Sie sofort einzigartige Konferenzdetails nach der Bestätigung.`,
  MICROSOFT_TEAMS_REQUIREMENTS: `Je nach Konfiguration muss Ihr Microsoft Teams-Konto-Administrator möglicherweise SUMO1 im Microsoft Marketplace vorab genehmigen.`,
  NO_CANCEL: `Nein, abbrechen`,
  REQUIREMENTS_TITLE: `Anforderungen`,
  SALESFORCE_BENEFITS: `Automatisch Salesforce-Ereignisse erstellen und mit Kunden als Salesforce-Leads, -Kontakten oder -Personenkonten buchen.`,
  SALESFORCE_REQUIREMENTS: `Salesforce-Konto \n Salesforce-Admin-Rechte`,
  SALESFORCE_CREATE_EVENTS: `Salesforce-Ereignisse erstellen`,
  SALESFORCE_BOOK_USERS: `Buchen mit Salesforce-Datensätzen aus SUMO Inbox erlauben`,
  YES_DISCONNECT: `Ja, trennen`,
  ZOOM_BENEFITS: `Erstellen Sie automatisch Zoom-Meetings, wenn ein Ereignis geplant ist. Teilen Sie sofort einzigartige Konferenzdetails nach der Bestätigung.`,
  ZOOM_REQUIREMENTS: `Je nach Konfiguration muss Ihr Zoom-Konto-Administrator möglicherweise SUMO1 im Zoom Marketplace vorab genehmigen.`,
});

export const IntegrationPage = Object.freeze({
  DESCRIPTION: `Sparen Sie mehr Zeit durch Automatisierung von Videokonferenzen, Terminplanung und mehr.`,
  FILTER_ALL: `Alle`,
  FILTER_CALENDARS: `Kalender`,
  FILTER_VIDEO_CONFERENCE: `Videokonferenz`,
  FILTER_WORKSPACE_INTEGRATIONS: `Arbeitsbereich-Integrationen`,
  FILTER_EXTENSIONS: `Erweiterungen`,
  GOOGLE_ANALYTICS_DESC: `Verfolgen Sie das Engagement mit Ihren Buchungsseiten`,
  GOOGLE_ANALYTICS_TITLE: `Google Analytics`,
  GOOGLE_CALENDAR_DESC: `Automatisches Buchen und Verschieben von Meetings in Ihrem Google Calendar.`,
  GOOGLE_CALENDAR_TITLE: `Google Calendar`,
  GOOGLE_MAPS_DESC: `Fügen Sie Standorte zur Karte hinzu, um ein besseres persönliches Meeting-Erlebnis zu bieten`,
  GOOGLE_MAPS_TITLE: `Google Maps`,
  GOOGLE_MEET_DESC: `Automatisches Hinzufügen von Google Meet-Details zu Ihren Meetings.`,
  GOOGLE_MEET_TITLE: `Google Meet`,
  HUBSPOT_DESC: `Automatisches Hinzufügen von SUMO1 erstellten Meetings zu HubSpot CRM.`,
  HUBSPOT_TITLE: `HubSpot`,
  INBOX_TITLE: `SUMO Inbox`,
  INBOX_DESC: `Installieren Sie die Chrome-Erweiterung oder das Edge-Add-on, um Einladungslinks aus Gmail oder Outlook Web zu senden.`,
  MICROSOFT_CALENDAR_DESC: `Automatisches Buchen und Verschieben von Meetings in Ihrem Microsoft Calendar.`,
  MICROSOFT_CALENDAR_TITLE: `Microsoft Office365`,
  MICROSOFT_TEAMS_DESC: `Automatisches Hinzufügen von Microsoft Teams-Details zu Ihren Meetings.`,
  MICROSOFT_TEAMS_TITLE: `Microsoft Teams`,
  NO_ITEMS: `Keine Elemente gefunden.`,
  REQUIREMENTS_TITLE: `Anforderungen`,
  SALESFORCE_DESC: `Buchen Sie Salesforce-Benutzer, CRM-Datensätze und fügen Sie automatisch Salesforce-Meetings hinzu`,
  SALESFORCE_TITLE: `Salesforce`,
  TITLE: `Integration`,
  ZOOM_DESC: `Automatisches Hinzufügen von Zoom-Details zu Ihren SUMO-Meetings.`,
  ZOOM_TITLE: `Zoom-Meeting`,
  FILL_ONE_FIELD: `Füllen Sie mindestens ein Feld aus`,
});

export const IntegrationToastsNotifications = Object.freeze({
  CONNECT_WORKSPACE_INTEGRATION_SUCCESS_MESSAGE: `Arbeitsbereich-Integration erfolgreich verbunden`,
  CONNECT_WORKSPACE_INTEGRATION_ERROR_MESSAGE: `Arbeitsbereich-Integrationsanforderung fehlgeschlagen`,
});
