export const Billing = Object.freeze({
  TITLE: `Billing`,
  YOUR_SUBSCRIPTION: `Your Subscription`,
  TEAMS_MONTHLY: `Teams Monthly`,
  TEAMS_ANNUAL: `Teams Annual`,
  SEATS: `seats`,
  LAST_UPDATED: `Last updated on`,
  CHANGE_TERM: `Change Term`,
  ADD_SEATS: `Add Seats`,
  REMOVE_SEATS: `Remove Seats`,
  YOUR_NEXT_BILL: `Your Next Bill`,
  DUE_ON: `Due on`,
  BILL_IS_LATE_PART_1: `Bill is currently`,
  BILL_IS_LATE_PART_2_SINGLE: `day late.`,
  BILL_IS_LATE_PART_2_MULTIPLE: `days late.`,
  BILLING_DETAILS: `Billing Details`,
  CHARGEBEE_BUTTON: `Open Billing Portal`,
  BILLING_PORTAL_DESRIPTION: `You may view your transaction history and more.`,
  SCHEDULED_CHANGES: `Changes Scheduled for`,
  SCHEDULED_CHANGES_DESRIPTION: `The following changes have been requested to take place at the end of the current term and may be canceled before end of term.`,
  SCHEDULED_SEATS_CHANGE_PART_1: `Reduce`,
  SCHEDULED_SEATS_CHANGE_PART_2: `seats`,
  SCHEDULED_TERM_CHANGE: `Term changed to`,
  SCHEDULED_COLUMN_LABEL: `Order Change`,
  SCHEDULED_COLUMN_CHANGE_BY: `Change by`,
  SCHEDULED_COLUMN_DATE: `Request Date`,
  SCHEDULED_COLUMN_OPTIONS: `Options`,
  ADD_SEATS_DESCRIPTION_PART_1: `Adding`,
  ADD_SEATS_DESCRIPTION_PART_2: `seat(s) will add`,
  ADD_SEATS_DESCRIPTION_PART_3: `to your`,
  ADD_SEATS_DESCRIPTION_PART_4: `bill, starting`,
  ADD_SEATS_PRORATED_PART_1: `For the remaining `,
  ADD_SEATS_PRORATED_PART_2: ` days until your next payment, your credit card will be charged a prorated amount of`,
  ADD_SEATS_PRORATED_PART_3: `today.`,
  REMOVE_SEATS_DESCRIPTION_PART_1: `Removing`,
  REMOVE_SEATS_DESCRIPTION_PART_2: `seat(s) will reduce your`,
  REMOVE_SEATS_DESCRIPTION_PART_3: `bill by`,
  REMOVE_SEATS_DESCRIPTION_PART_4: `starting`,
  EXPIRE_MESSAGE_PART_1: `Bill is`,
  EXPIRE_MESSAGE_PART_2: `days late. Your org will be inoperable in`,
  EXPIRE_MESSAGE_PART_3: `days.`,
  NUMBER_OF_LICENSES: `# Licenses`,
  LICENSES_TITLE: `licenses`,
  LICENSES_PLACEHOLDER: `Enter a number`,
  LICENSE_MESSAGE_PART_1: `You already have`,
  LICENSE_MESSAGE_PART_2: `licenses assigned to users. Please enter more`,
  LICENSE_MESSAGE_PART_3: ` or deactivate/delete users to free up licenses`,
  LOADING_LABEL_1: `Connecting…`,
  LOADING_LABEL_2: `Adding Licenses`,
  PAY_NOW_TITLE: `Pay Now`,
  TRIAL_LICENSE_EXPIRED: `Your SUMO1 license has expired. To continue using SUMO1 without any interruptions, please purchase a new
  license.`,
  BUY_LICENSES_MESSAGE: `Your bill will be`,
  MONTH_TITLE: `month`,
  MONTHLY_TITLE: `monthly`,
  ANNUAL_TITLE: `annual`,
  ANNUALLY_TITLE: `annually`,
  REMOVE_SEATS_NOTE_PART1: `To remove more than`,
  REMOVE_SEATS_NOTE_PART2: `seat(s) you can deactivate or delete users to free up licenses.`,
  CANCELLATION_SCHEDULED: `Your subscription scheduled to be canceled on `,
  ACCOUNT_SUSPENDED_PART1: `Your account is suspended. You must`,
  ACCOUNT_SUSPENDED_PART2: `pay your outstanding invoice`,
  ACCOUNT_SUSPENDED_PART3: `to re-enable service.`,
});

export const BillingToastsNotifications = Object.freeze({
  GET_BILLING_ERROR_TOAST: `ChargeBee Connection Error`,
  GET_ITEM_TIERS_ERROR_TOAST: `Could no get the item prices of licenses`,
  BUY_LICENSE_SUCCESS_TOAST: `Subscription created successfully`,
  ADD_SEATS_SUCCESS_TOAST_TITLE: `Seats added.`,
  ADD_SEATS_SUCCESS_TOAST: `Please wait up to 1 minute before we receive your payment`,
  ADD_SEATS_ERROR_TOAST: `Could not add seats`,
  REMOVE_SEATS_SUCCESS_TOAST_TITLE: `Seats removed.`,
  REMOVE_SEATS_SUCCESS_TOAST: `You must un-assign seats before end of term.`,
  REMOVE_SEATS_ERROR_TOAST: `Could not remove seats`,
  CHANGE_TERM_SUCCESS_TOAST: `Term changed successfully.`,
  CHANGE_TERM_ERROR_TOAST: `Could not change the term.`,
  CHARGEBEE_PORTAL_CLOSE_TOAST: `Please wait up to 1 minute before we receive it if you have made any changes, then reopen the billing page again`,
  DELETE_CHANGE_SUCCESS_TOAST: `Scheduled change deleted successfully.`,
  DELETE_CHANGE_ERROR_TOAST: `Could not delete scheduled change.`,
});
