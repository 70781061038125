import { Checkbox } from 'primereact/checkbox';
import labels from './labels';
import {
  DISABLED_PERMISSIONS,
  FULL_PERMISSION_ACTION_LIST,
  FULL_PERMISSION_LIST,
  PERMISSION_LIST,
  Permission,
  rolesActions,
} from '../../../store/roles';
import { useDispatch } from 'react-redux';
import { CreateRoleInput, PermissionAction } from '../../../API';

type RolePermissionsProps = {
  role: CreateRoleInput;
  isPreview?: boolean;
};
export const RolePermissions = ({ role, isPreview }: RolePermissionsProps) => {
  const dispatch = useDispatch();

  const handleChange = (name: string, value: boolean) => {
    if (name === 'isAccessToAllWorkspaces' && value) {
      const allCRUD = FULL_PERMISSION_LIST.reduce(
        (prev, permission) => ({ ...prev, [permission]: FULL_PERMISSION_ACTION_LIST }),
        {}
      );
      dispatch(rolesActions.updateSelectedRole({ [name]: value, ...allCRUD }));
    } else {
      dispatch(rolesActions.updateSelectedRole({ [name]: value }));
    }
  };

  const handlePermissionChange = (permission: Permission, actionType: PermissionAction, checked: boolean) => {
    if (checked) {
      dispatch(rolesActions.checkPermission({ permission, actionType }));
    } else {
      dispatch(rolesActions.uncheckPermission({ permission, actionType }));
    }
  };

  return (
    <>
      {/* <div className="surface-50 text-sm p-2 uppercase">{labels.featureAccessTitle}</div>
      <div className="p-fluid grid p-1 py-3">
        <div className="align-self-end text-right col-8 md:col-4">{labels.chromeExtensionTitle}</div>
        <div className="align-items-center col-4 md:col-1 flex flex-column">
          <label>{labels.active}</label>
          <Checkbox
            checked={role.hasChromeAccess}
            name="hasChromeAccess"
            className="mt-2"
            onChange={(e) => handleChange(e.target.name, !!e.target.checked)}
            disabled={isPreview}
          />
        </div>
      </div> */}

      <div className="text-heavy-80">
        <div className="text-title-xs-med">{labels.permissionsTitle}</div>
        <table className="w-full" style={{ borderSpacing: 0 }}>
          <tr>
            <th />
            {labels.access.map((accessType) => (
              <th className="text-center text-body-s-reg pb-2px w-75px" key={accessType}>
                {accessType}
              </th>
            ))}
            <th className="w-16px" />
          </tr>
          {PERMISSION_LIST.map(
            (permission) =>
              permission !== 'resources' &&
              role[permission] && (
                <tr key={permission}>
                  <td className="text-body-lg-reg py-12px border-bottom-1 border-heavy-20">
                    {labels.permissionsLabel[permission]}
                  </td>
                  {FULL_PERMISSION_ACTION_LIST.map((action) => (
                    <td className="text-center py-12px border-bottom-1 border-heavy-20" key={action}>
                      <Checkbox
                        checked={!!role[permission]?.includes(action)}
                        onChange={(e) => handlePermissionChange(permission, action, !!e.target.checked)}
                        disabled={
                          isPreview ||
                          DISABLED_PERMISSIONS.some(
                            (value) => value.permission === permission && value.action.includes(action)
                          )
                        }
                      />
                    </td>
                  ))}
                </tr>
              )
          )}
        </table>
      </div>

      <div className="text-heavy-80">
        <div className="text-title-xs-med">{labels.adminTitle}</div>
        <table className="w-full" style={{ borderSpacing: 0 }}>
          {!role.isAccessToAllWorkspaces && (
            <>
              <tr>
                <th />
                {labels.access.map((accessType) => (
                  <th className="text-center text-body-s-reg pb-2px w-75px" key={accessType}>
                    {accessType}
                  </th>
                ))}
                <th className="w-16px" />
              </tr>
              <tr>
                <td className="text-body-lg-reg py-12px border-bottom-1 border-heavy-20">{labels.workspaceAdmin}</td>
                {FULL_PERMISSION_ACTION_LIST.map((action) => (
                  <td className="text-center py-12px border-bottom-1 border-heavy-20" key={action}>
                    <Checkbox
                      checked={!!role.workspaceAdminActions?.includes(action)}
                      onChange={(e) => handlePermissionChange('workspaceAdminActions', action, !!e.target.checked)}
                      disabled={isPreview}
                    />
                  </td>
                ))}
                <td className="w-16px border-bottom-1 border-heavy-20" />
              </tr>
            </>
          )}

          <tr>
            <td className="text-body-lg-reg py-12px">{labels.superAdmin}</td>
            <td className="text-center py-12px w-75px">
              <Checkbox
                checked={!!role.isAccessToAllWorkspaces}
                name="isAccessToAllWorkspaces"
                onChange={(e) => handleChange(e.target.name, !!e.target.checked)}
                disabled={isPreview}
              />
            </td>
            <td className="text-body-lg-reg py-12px w-75px">
              <div style={{ margin: '0 -172px 0 -22px' }}>{labels.accessToAllWorkspaces}</div>
            </td>
            <td className="w-75px" />
            <td className="w-75px" />
            <td className="w-16px" />
          </tr>
        </table>
      </div>
    </>
  );
};
