import { useDispatch, useSelector } from 'react-redux';
import { InputNumber } from 'primereact/inputnumber';
import { Button } from 'primereact/button';
import { Modal } from '../../common';
import {
  billingActions,
  billingSelectors,
  CHANGE_SEATS_MODAL,
  changeSeatsModalActions,
  CHARGEBEE_YEARLY_TERM,
  DEFAULT_SEATS_AMOUNT,
  MAX_SEATS_AMOUNT,
} from '../../../store/billing';
import labels from './labels';

export const BillingChangeSeatsModal = () => {
  const dispatch = useDispatch();
  const isAddOrRemove = useSelector(billingSelectors.selectIsAddOrRemoveSeats);
  const removeSeatsMax = useSelector(billingSelectors.selectRemoveSeatsMax);
  const changeSeats = useSelector(billingSelectors.selectChangeSeats);
  const changeSeatsPrice = useSelector(billingSelectors.selectChangeSeatsPrice);
  const addSeatsProratedPrice = useSelector(billingSelectors.selectAddSeatsProratedPrice);
  const isChangeSeatsValid = useSelector(billingSelectors.selectIsChangeSeatsValid);
  const nextBillingDate = useSelector(billingSelectors.selectNextBillingDate);
  const currencyLabel = useSelector(billingSelectors.selectCurrencyLabel);
  const termsDaysLeft = useSelector(billingSelectors.selectTermsDaysLeft);
  const nextTerm = useSelector(billingSelectors.selectNextTerm);
  const title = isAddOrRemove ? labels.addSeats : labels.removeSeats;
  const isFetching = useSelector(billingSelectors.selectIsFetching);

  const constructDescription = (isAdding: boolean) => {
    const termLabel = nextTerm === CHARGEBEE_YEARLY_TERM ? labels.yearly : labels.monthly;
    const price = currencyLabel + changeSeatsPrice.toFixed(2);
    if (isAdding) {
      return `${labels.addDescriptionPart1} ${changeSeats} ${labels.addDescriptionPart2} ${price} ${labels.addDescriptionPart3} ${termLabel} ${labels.addDescriptionPart4} ${nextBillingDate}.`;
    } else {
      return `${labels.removeDescriptionPart1} ${changeSeats} ${labels.removeDescriptionPart2} ${termLabel} ${labels.removeDescriptionPart3} ${price} ${labels.removeDescriptionPart4} ${nextBillingDate}.`;
    }
  };

  const constructProratedDescription = (isAdding: boolean) => {
    if (!isAdding) return '';
    const price = currencyLabel + addSeatsProratedPrice.toFixed(2);
    return `${labels.addProratedPart1} ${termsDaysLeft} ${labels.addProratedPart2} ${price} ${labels.addProratedPart3}`;
  };

  const description = constructDescription(isAddOrRemove);
  const proratedDescription = constructProratedDescription(isAddOrRemove);

  const handleSeatsChange = (seats: number) => {
    const validCountSeats =
      seats > (isAddOrRemove ? MAX_SEATS_AMOUNT : removeSeatsMax)
        ? isAddOrRemove
          ? MAX_SEATS_AMOUNT
          : removeSeatsMax
        : seats;

    dispatch(billingActions.setChangeSeats(validCountSeats));
  };

  const handleCancel = () => {
    dispatch(changeSeatsModalActions.closeModal());
    dispatch(billingActions.setChangeSeats(DEFAULT_SEATS_AMOUNT));
  };

  const handleAddSeats = () => {
    dispatch(billingActions.changeSeatsRequest());
  };

  return (
    <Modal.Container name={CHANGE_SEATS_MODAL}>
      <Modal.Header>
        <div className="font-bold">{title}</div>
      </Modal.Header>
      <div className="w-30rem">
        <div className="flex align-items-center gap-3 mt-1">
          <i className={`pi text-xl pi-${isAddOrRemove ? 'plus' : 'minus'}`}></i>
          <InputNumber
            showButtons
            min={0}
            max={isAddOrRemove ? MAX_SEATS_AMOUNT : removeSeatsMax}
            value={changeSeats}
            onChange={(e) => handleSeatsChange(e.value || 0)}
            className={(!isChangeSeatsValid && 'p-invalid') || ''}
          />
        </div>
        {!isAddOrRemove && (
          <div className="mt-2 text-xs">{`${labels.removeNotePart1} ${removeSeatsMax} ${labels.removeNotePart2}`}</div>
        )}
        <div className="mt-5">{description}</div>
        {isAddOrRemove && <div className="mt-5">{proratedDescription}</div>}
      </div>
      <Modal.Buttons>
        <Button className="ml-auto" label={labels.cancel} onClick={handleCancel} outlined />
        <Button label={title} onClick={handleAddSeats} disabled={!isChangeSeatsValid || isFetching} />
      </Modal.Buttons>
    </Modal.Container>
  );
};
