import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '../../../store/rootStore';
import { QuickSetupScreen } from '..';
import labels from './labels';
import { Button } from 'primereact/button';
import { quickSetupNavigationActions } from '../../../store/quickSetup';
import { userSettingsSelectors } from '../../../store/userSettings';
import { ArrowRightIcon, CalendarDaysIcon, LockClosedIcon, ShieldCheckIcon } from '@heroicons/react/24/outline';

export const SetupCalendarConnectedStep = () => {
  const dispatch = useDispatch<AppDispatch>();
  const email = useSelector(userSettingsSelectors.selectConnectedEmail);

  const handleNext = () => {
    dispatch(quickSetupNavigationActions.navigateNext());
  };

  return (
    <QuickSetupScreen.Container title={labels.stepMessage} titleIcon="👋">
      <div className="flex flex-column">
        <div className="text-display-md-med">{labels.title}</div>
        <div className="text-body-lg-reg text-heavy-80 pt-2px pb-32px w-580px">{labels.description}</div>

        <div className="w-fit border-1 bg-blue-soft border-blue-light border-radius-14px pt-20px pb-16px pl-24px pr-40px">
          <div className="text-title-lg-med">
            <span>{labels.instructionTitle}</span>
            <span className="text-blue-main">{email}</span>
          </div>
          <div className="flex flex-column gap-10px pt-24px text-label-input-reg">
            <div className="flex gap-8px">
              <ShieldCheckIcon className="w-20px text-blue-main" />
              <div className="mt-2px">{labels.instructionStepA1 + '"' + email + '"' + labels.instructionStepA2}</div>
            </div>
            <div className="flex gap-8px">
              <CalendarDaysIcon className="w-20px text-blue-main" />
              <div className="mt-2px">{labels.instructionStepB + '"' + email + '"'}</div>
            </div>
            <div className="flex gap-8px">
              <LockClosedIcon className="w-20px text-blue-main" />
              <div className="mt-2px">{labels.instructionStepC}</div>
            </div>
          </div>
        </div>
      </div>
      <QuickSetupScreen.Buttons>
        <Button className="min-w-120px justify-content-center button-xl" onClick={handleNext}>
          <span>{labels.next}</span>
          <ArrowRightIcon className="w-18px ml-8px" />
        </Button>
      </QuickSetupScreen.Buttons>
    </QuickSetupScreen.Container>
  );
};
