export const Availability = Object.freeze({
  ACTIVE_ON: `Aktiv am`,
  BOOKING_PAGE: `Buchungsseite`,
  BOOKING_PAGES: `Buchungsseiten`,
  CLONE_SCHEDULE_TITLE: `Name des geklonten Zeitplans`,
  DATE_OVERRIDE_BUTTON: `Neue Aufhebung`,
  DATE_OVERRIDE_DESCRIPTION: `Fügen Sie unten Ausnahmen zu diesem Zeitplan hinzu:`,
  DATE_OVERRIDE_MODAL_HOURS: `Verfügbare Stunden`,
  DATE_OVERRIDE_MODAL_PROMPT: `Wählen Sie das Datum (oder die Daten):`,
  DATE_OVERRIDE_MODAL_UNAVAILABLE: `Klicken Sie auf Speichern, um dieses Datum (oder diese Daten) als nicht verfügbar zu blockieren.`,
  DATE_OVERRIDE_TITLE: `Datumüberschreibung hinzufügen`,
  DATES_OVERLAPPING_TEXT: `Einige Daten wurden bereits überschrieben.`,
  DEFAULT_AVAILABILITY_NAME: `Arbeitszeiten`,
  DELETE_DESCRIPTION: `Sind Sie sicher, dass Sie löschen möchten`,
  DELETE_MESSAGE: `wird als der neue Standardzeitplan festgelegt.`,
  DESCRIPTION: `Wählen Sie unten einen Zeitplan zum Bearbeiten oder erstellen Sie einen neuen, den Sie auf Ihren Buchungsseiten anwenden können.`,
  DIALOG_EDIT_NAME_TITLE: `Zeitplantitel bearbeiten`,
  DIALOG_NEW_NAME_TITLE: `Neuer Zeitplanname`,
  DIALOG_PLACEHOLDER: `Arbeitszeiten, Exklusive Stunden, etc...`,
  DIALOG_TITLE: `Zeitplantitel`,
  DUPLICATE_NAME_MESSAGE: `Ein Zeitplan mit diesem Namen existiert bereits`,
  EDIT_NAME: `Namen bearbeiten`,
  IS_DEFAULT: `Standardzeitplan`,
  NEW_SCHEDULE: `Neuer Zeitplan`,
  SAVE_AVAILABILITY: `Verfügbarkeit speichern`,
  SET_AS_DEFAULT: `Als Standard festlegen`,
  SET_WEEKLY_HOURS: `Definieren Sie Ihre wöchentlichen Stunden:`,
  TIME_INTERVAL_OVERLAPPING: `Zeitintervalle dürfen nicht überlappen.`,
  TIME_INVALID_TEXT: `Wählen Sie eine Endzeit, die später als die Startzeit ist`,
  TIME_ZONE: `Zeitzone`,
  TITLE: `Verfügbarkeit`,
  TOOLTIP: `Verwalten Sie Ihre Verfügbarkeit mit Arbeitsplänen`,

  SCHEDULE: `Zeitplan`,
  OVERRIDES: `Überschreibungen`,
  AVAILABLE_HOURS: `Verfügbare Stunden`,
  ACTIVE_ON_PLACEHOLDER: `Buchungsseiten auswählen`,
  AVAILABLE_DESCRIPTION: `Zusätzliche Stunden für diesen Zeitplan`,
  UNAVAILABLE_DESCRIPTION: `Termine, die nicht verfügbar sind`,
  DELETE_WARNING_MESSAGE: `Diese Aktion kann nicht rückgängig gemacht werden.`,
  DELETE_TITLE: `Zeitplan löschen`,
  DATE_OVERRIDE_AVAILABLE_TITLE: `Verfügbare Überschreibungen hinzufügen`,
  DATE_OVERRIDE_UNAVAILABLE_TITLE: `Nicht verfügbare Termine hinzufügen`,
});

export const AvailabilityToastsNotifications = Object.freeze({
  CREATE_AVAILABILITY_ERROR_MESSAGE: `Fehler beim Anfordern der Erstellung der Verfügbarkeit`,
  CREATE_AVAILABILITY_SUCCESS_MESSAGE: `Erfolgreich angeforderte Erstellung der Verfügbarkeit`,
  DELETE_AVAILABILITY_ERROR_MESSAGE: `Fehler beim Anfordern der Löschung der Verfügbarkeit`,
  DELETE_AVAILABILITY_SUCCESS_MESSAGE: `Erfolgreich angeforderte Löschung der Verfügbarkeit`,
  GET_AVAILABILITY_ERROR_MESSAGE: `Fehler beim Anfordern der Verfügbarkeit`,
  SAVE_AVAILABILITY_ERROR_MESSAGE: `Fehler beim Anfordern der Speicherung der Verfügbarkeit`,
  SAVE_AVAILABILITY_SUCCESS_MESSAGE: `Erfolgreich angeforderte Speicherung der Verfügbarkeit`,
  SET_DEFAULT_AVAILABILITY_ERROR_MESSAGE: `Fehler beim Anfordern der Festlegung der Standardverfügbarkeit`,
  SET_DEFAULT_AVAILABILITY_SUCCESS_MESSAGE: `Erfolgreich angeforderte Festlegung der Standardverfügbarkeit`,
});

export const AvailabilityWeekDays = Object.freeze({
  MONDAY_SHORT: 'Mo',
  TUESDAY_SHORT: 'Di',
  WEDNESDAY_SHORT: 'Mi',
  THURSDAY_SHORT: 'Do',
  FRIDAY_SHORT: 'Fr',
  SATURDAY_SHORT: 'Sa',
  SUNDAY_SHORT: 'So',
});
