import { useSelector } from 'react-redux';
import {
  CONFIRM_OVERRIDE_SMART_ALERT_MODAL,
  confirmOverrideSmartAlertModalActions,
  confirmOverrideSmartAlertModalSelectors,
} from '../../../../store/bookingPages';
import { Modal } from '../../modal/Modal';
import { smartAlertsActions, smartAlertsSelectors } from '../../../../store/smartAlerts';
import { useDispatch } from 'react-redux';
import { Button } from 'primereact/button';
import { Link } from 'react-router-dom';
import { Path } from '../../../../routing';
import { WarningSmartAlertModalRequest } from '../../../../store/bookingTemplates';
import { AppDispatch } from '../../../../store/rootStore';
import labels from './labels';

export const SmartAlertWarningModal = () => {
  const dispatch = useDispatch<AppDispatch>();
  const id = useSelector(smartAlertsSelectors.selectSmartAlertId);
  const warningDescription = useSelector(smartAlertsSelectors.selectEditWarningDescription(id));
  const bookingPages = useSelector(smartAlertsSelectors.selectBookingPagesWhereUsed(id));
  const bookingTemplates = useSelector(smartAlertsSelectors.selectBookingTemplatesWhereUsed(id));
  const payload: WarningSmartAlertModalRequest = useSelector(
    confirmOverrideSmartAlertModalSelectors.selectModalRequest
  ) as WarningSmartAlertModalRequest;

  const handleCancel = () => {
    dispatch(smartAlertsActions.setDetachAlert(false));
    dispatch(confirmOverrideSmartAlertModalActions.closeModal());
  };

  const handleUpdateAll = () => {
    dispatch(smartAlertsActions.addToWithoutWarning(id));
    dispatch(smartAlertsActions.setDetachAlert(false));
    dispatch(smartAlertsActions.upsertSmartTypeRequest('none'));
    handleCancel();
  };

  const handleDetach = () => {
    dispatch(smartAlertsActions.setDetachAlert(true));
    payload?.upsertType && dispatch(smartAlertsActions.upsertSmartTypeRequest(payload.upsertType));
    handleCancel();
  };

  return (
    <Modal.Container
      name={CONFIRM_OVERRIDE_SMART_ALERT_MODAL}
      closable={true}
      onClose={handleCancel}
      className="w-560px"
      contentClassName="h-200px"
    >
      <Modal.Header>
        <div>{labels.title}</div>
      </Modal.Header>
      <div className="mb-24px">{warningDescription}</div>
      {bookingPages.map((page) => (
        <div key={page.id}>
          <Link to={Path.EditBookingPage.replace(':bookingPageId', page.id || '')}>{page.what?.customName || ''}</Link>
        </div>
      ))}
      {bookingTemplates.map((template) => (
        <div key={template.id}>
          <Link to={Path.EditBookingTemplate.replace(':bookingTemplateId', template.id || '')}>
            {template.what?.customName || ''}
          </Link>
        </div>
      ))}
      <Modal.Buttons>
        <Button onClick={handleUpdateAll} className="mr-24px">
          {labels.updateAll}
        </Button>
        <Button onClick={handleDetach} className="mr-auto" outlined>
          {labels.createNewSmartAlert}
        </Button>
        <Button onClick={handleCancel} text>
          {labels.cancel}
        </Button>
      </Modal.Buttons>
    </Modal.Container>
  );
};
