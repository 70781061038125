import { CreateAccountInput } from '../../../API';
import { AccountWithTenants, GetAllAccountsResponse, UpsertAccountResponse } from './types';

import { handleAPIRequest } from '../../utils/reduxUtils';
import { API_PUBLIC } from '../../../types/constants';

// ***
// When you change the requests do not forget to specify new data
// in docs/Endpoints.md
// ***

export const getAccounts = async (): Promise<AccountWithTenants[]> => {
  const response = await handleAPIRequest<GetAllAccountsResponse>(`${API_PUBLIC}/opsconsole/getAllAccounts`, {});
  return response.accounts;
};

export const getTenantsByAccountId = async (id: string): Promise<AccountWithTenants> => {
  return handleAPIRequest<AccountWithTenants>(`${API_PUBLIC}/opsconsole/getTenantsByAccountId`, { id });
};

export const upsertAccount = async (account: CreateAccountInput): Promise<string> => {
  const response = await handleAPIRequest<UpsertAccountResponse>(`${API_PUBLIC}/upsertAccount`, { account });
  return response.accountId;
};

export const removeAccount = async (id: string): Promise<void> => {
  return handleAPIRequest<void>(`${API_PUBLIC}/deleteAccount`, { id });
};
