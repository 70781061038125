import sumoLogo from '../../../assets/images/sumoLogo.svg';
import sumoLogoWhite from '../../../assets/images/sumoLogoWhite.svg';
import { ViewAsUserWarningBlock } from '../../opsConsole';
import { useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { userSettingsSelectors } from '../../../store/userSettings';
import { authenticationActions, authenticationSelectors } from '../../../store/authentication';
import { workspacesSelectors } from '../../../store/workspaces';
import { Path } from '../../../routing';
import { UserAvatar } from '../../common';
import { Dropdown } from 'primereact/dropdown';
import labels from './labels';
import urlConstants from '../../../shared/JSON/urlConstants.json';
import { Menu as PrimeMenu } from 'primereact/menu';
import { MenuItem } from 'primereact/menuitem';
import { NavigationMenu } from './NavigationMenu';
import { SumoistHelper } from '../sumoistHelper/SumoistHelper';
import {
  CalendarIcon,
  ChevronUpDownIcon,
  Cog6ToothIcon,
  InformationCircleIcon,
  PowerIcon,
  UserCircleIcon,
} from '@heroicons/react/24/outline';
import { Link } from 'react-router-dom';

export const Menu = () => {
  const menu = useRef<PrimeMenu>(null);

  const dispatch = useDispatch();
  const isOpConsole = useSelector(authenticationSelectors.selectIsOpsConsole);
  const nameOrEmail = useSelector(userSettingsSelectors.selectNameOrEmail) || '';
  const roleName = useSelector(userSettingsSelectors.selectUserRoleName);
  const staffNameOrEmail = useSelector(authenticationSelectors.selectStaffNameOrEmail);
  const staffRoleName = useSelector(authenticationSelectors.selectSumo1AdminRoleLabel);
  const userWasDeleted = useSelector(authenticationSelectors.selectUserWasDeleted);
  const selectedWorkspaceId = useSelector(authenticationSelectors.selectWorkspaceId);
  const userWorkspaces = useSelector(userSettingsSelectors.selectUserWorkspaces);
  const userWorkspacesOptions = useSelector(workspacesSelectors.selectWorkspaceOptionsByIds(userWorkspaces));
  const isQuickSetupFinished = useSelector(userSettingsSelectors.selectIsQuickSetupFinished);
  const isLicenseActive = useSelector(authenticationSelectors.selectIsLicenseActive);
  const isTrialLicenseExpired = useSelector(authenticationSelectors.selectIsTrialLicenseExpired);
  const isSuperAdmin = useSelector(userSettingsSelectors.selectIsSuperAdmin);
  const trialExpirationMessage = useSelector(authenticationSelectors.selectTrialExpirationMessage);
  const paidExpirationMessage = useSelector(authenticationSelectors.selectPaidExpirationMessage);
  const showExpirationUserMessage = useSelector(authenticationSelectors.selectShowExpirationUserMessage);
  const isViewAsUserMode = useSelector(authenticationSelectors.selectIsViewAsUser);

  const name = isOpConsole ? staffNameOrEmail : nameOrEmail;
  const role = isOpConsole ? staffRoleName : roleName;

  const accountVisible = Boolean(isQuickSetupFinished && !isOpConsole && isLicenseActive);

  useEffect(() => {
    userWasDeleted && handleSignOut();
  }, [userWasDeleted]);

  const handleWorkspaceChange = (value: string) => {
    dispatch(authenticationActions.updateUserDataCore({ workspaceId: value }));
    window.location.reload();
  };

  const handleSignOut = () => {
    dispatch(authenticationActions.logoutUserRequest(Path.Landing));
  };

  const itemTemplate: MenuItem['template'] = (item) => (
    <Link
      to={item.url || Path.Landing}
      onClick={() => item.label === labels.logOut && handleSignOut()}
      target={item.target}
      className={`p-menuitem-link px-16px py-10px ${
        location.pathname.startsWith(item.url || '') ? 'text-heavy-100' : 'text-heavy-80'
      }`}
    >
      <div className="p-menuitem-text flex align-items-center gap-8px">
        {item.data}
        <span>{item.label}</span>
      </div>
    </Link>
  );

  const menuItems: MenuItem[] = accountVisible
    ? [
        {
          label: labels.availability,
          url: Path.Availability,
          template: itemTemplate,
          data: <CalendarIcon className="icon-20px" />,
        },
        {
          label: labels.calendarConnections,
          url: Path.CalendarConnections,
          template: itemTemplate,
          data: <Cog6ToothIcon className="icon-20px" />,
        },
        {
          separator: true,
        },
        {
          label: labels.profile,
          url: Path.Account,
          template: itemTemplate,
          data: <UserCircleIcon className="icon-20px" />,
        },
        {
          label: labels.helpCenter,
          url: urlConstants.KNOWLEDGE_BASE_URL,
          target: 'blank',
          template: itemTemplate,
          data: <InformationCircleIcon className="icon-20px" />,
        },
        {
          separator: true,
        },
        {
          label: labels.logOut,
          template: itemTemplate,
          data: <PowerIcon className="icon-20px" />,
        },
      ]
    : [
        {
          label: labels.logOut,
          template: itemTemplate,
          data: <PowerIcon className="icon-20px" />,
        },
      ];

  return (
    <div className="flex flex-column gap-16px p-24px min-h-full">
      <div className="flex">
        <img src={isOpConsole ? sumoLogoWhite : sumoLogo} className="h-30px" />
      </div>

      {!isOpConsole && (userWorkspaces?.length || 0) > 1 && (
        <Dropdown
          options={userWorkspacesOptions}
          optionValue="id"
          optionLabel="name"
          value={selectedWorkspaceId}
          dropdownIcon={`${userWorkspacesOptions?.length ? 'pi pi-chevron-down' : null}`}
          valueTemplate={(option) =>
            userWorkspacesOptions?.length ? (
              <div>{option?.name}</div>
            ) : (
              <div className="w-4rem text-right">
                <span className="text-lg vertical-align-middle pi pi-spinner pi-spin" />
              </div>
            )
          }
          placeholder="Loading..." // no need in labels - never shown, used to hide dropdown standard css class
          disabled={!userWorkspacesOptions?.length}
          onChange={(e) => handleWorkspaceChange(e.value)}
        />
      )}

      {isLicenseActive === false || isQuickSetupFinished || isOpConsole ? (
        <div className="flex-1 mt-8px">
          <NavigationMenu />
        </div>
      ) : (
        <div className="flex-1" />
      )}

      {isViewAsUserMode && !isOpConsole && <ViewAsUserWarningBlock />}
      {isQuickSetupFinished && !isOpConsole && isLicenseActive && (
        <>
          {trialExpirationMessage && (
            <SumoistHelper
              title={trialExpirationMessage}
              message={labels.trialMessage}
              buttonUrl={isSuperAdmin ? Path.Users : undefined}
              buttonLabel={labels.buyLicenses}
            />
          )}
          {isSuperAdmin && paidExpirationMessage && (
            <SumoistHelper message={paidExpirationMessage} buttonUrl={Path.Billing} buttonLabel={labels.myBillings} />
          )}
          {showExpirationUserMessage && <SumoistHelper message={paidExpirationMessage} />}
        </>
      )}
      {/* Trial License Expired */}
      {!isOpConsole && isTrialLicenseExpired && (
        <SumoistHelper
          title={labels.expired}
          message={labels.trialIsOver}
          buttonUrl={isSuperAdmin ? Path.Billing : undefined}
          buttonLabel={isSuperAdmin ? labels.buyLicenses : undefined}
        />
      )}

      <div className="-mr-8px">
        <div
          className={`flex align-items-center gap-8px border-radius-6px cursor-pointer ${
            isOpConsole ? 'text-primary-white hover-bg-heavy-80' : 'hover-bg-blue-light'
          }`}
          onClick={(e) => menu.current?.toggle(e)}
        >
          <UserAvatar name={name} />
          <div className="flex flex-column gap-4px">
            <div className="text-label-s-med">{name}</div>
            <div className="text-label-xs-reg text-heavy-60">{role}</div>
          </div>
          <div className="ml-auto flex align-items-center">
            <ChevronUpDownIcon className="text-heavy-60 w-16px" />
          </div>
        </div>
        <PrimeMenu
          ref={menu}
          model={menuItems}
          popup
          className={`translate-up-10px ${accountVisible ? 'w-fit' : 'w-200px'}`}
        />
      </div>
    </div>
  );
};
