export const CannotDeleteWorkspace = Object.freeze({
  DESCRIPTION: `Die folgenden Benutzer sind zugewiesen, um nur diesen Arbeitsbereich zu verwenden`,
  NOTES: `Sie müssen diese Benutzer zuerst einem anderen Arbeitsbereich zuweisen, bevor Sie diesen löschen können.`,
  TITLE: `Benutzer neu zuweisen`,
});

export const WorkspaceToastsNotifications = Object.freeze({
  ACTIVATE_INACTIVATE_WORKSPACE_ERROR_MESSAGE: `Fehler beim Aktivieren/Deaktivieren des Arbeitsbereichs`,
  ACTIVATE_WORKSPACE_SUCCESS_MESSAGE: `Arbeitsbereich aktiviert`,
  DELETE_WORKSPACE_ERROR_MESSAGE: `Fehler beim Löschen des Arbeitsbereichs`,
  DELETE_WORKSPACE_SUCCESS_MESSAGE: `Anfrage zum Löschen des Arbeitsbereichs erfolgreich`,
  GET_WORKSPACES_ERROR_MESSAGE: `Fehler bei der Abfrage von Arbeitsbereichen`,
  INACTIVATE_WORKSPACE_SUCCESS_MESSAGE: `Arbeitsbereich deaktiviert`,
  REMOVE_INTEGRATION_SUCCESS_TOAST: `Arbeitsbereichsintegration erfolgreich getrennt`,
  SAVE_WORKSPACE_ERROR_MESSAGE: `Fehler beim Speichern des Arbeitsbereichs`,
  SAVE_WORKSPACE_SUCCESS_MESSAGE: `Arbeitsbereich erfolgreich gespeichert`,
  CLONE_WORKSPACE_ERROR_MESSAGE: `Anforderung zum Klonen des Arbeitsbereichs fehlgeschlagen`,
  CLONE_WORKSPACE_SUCCESS_MESSAGE: `Arbeitsbereich erfolgreich geklont`,
});

export const WorkspaceWhoStep = Object.freeze({
  ADMINS_TITLE: `Administratoren`,
  ADMIN_DESCRIPTION: `Die folgenden Personen dürfen diesen Arbeitsbereich verwalten.`,
  INVITE_SETTINGS_TITLE: `Einladungseinstellungen`,
  NOTES_PART_1: `Alternativ können Sie den `,
  NOTES_PART_2: `Benutzermanagement`,
  NOTES_PART_3: ` Abschnitt verwenden, um einen Benutzer zu finden und ihn einem Arbeitsbereich hinzuzufügen.`,
  PHONE_NUMBER_DESCRIPTION: `Wenn Sie SUMO für die Buchung von Telefonanrufen verwenden möchten, wird empfohlen, dies aktiviert zu lassen.`,
  PHONE_NUMBER_TITLE: `Benutzern das Feld "Telefonnummer" erforderlich machen.`,
  SEARCH_ADD_ADMINS: `Administrator suchen und hinzufügen`,
  SEARCH_ADD_USERS: `Benutzer suchen und hinzufügen`,
  SUPER_ADMINS_DESCRIPTION: `Super Admins werden immer vorab ausgefüllt`,
  USERS_DESCRIPTION: `Die folgenden Personen dürfen diesen Arbeitsbereich nutzen.`,
  USERS_TITLE: `Benutzer`,
});

export const Workspaces = Object.freeze({
  ACTIVE: `Aktiv`,
  ADMIN_TITLE: `Admin`,
  CLONE_MODAL_TITLE: `Arbeitsbereich duplizieren`,
  DEFAULT_WORKSPACE: `Standardarbeitsbereich`,
  DELETE_DESCRIPTION: `Möchten Sie wirklich löschen`,
  INACTIVE: `Inaktiv`,
  NAME_TITLE: `Arbeitsbereichsname`,
  NAME_PLACEHOLDER: `Geben Sie einen Arbeitsbereichsnamen ein`,
  NEW_WORKSPACE: `Neuer Arbeitsbereich`,
  SELECTED_WORKSPACES: `ausgewählte Arbeitsbereiche`,
  TITLE: `Arbeitsbereiche`,
  BRANDING_TITLE: `Anpassung der Marken-Seite`,
  BRANDING_DESCRIPTION: `Passen Sie das Erscheinungsbild, die Stile und Beschriftungen für neue Buchungsseiten an.`,
});

export const EditWorkspace = Object.freeze({
  WHAT_TITLE: `Was`,
  WHAT_DESCRIPTION: `Name und Status`,
  WHO_TITLE: `Wer`,
  WHO_DESCRIPTION: `Administratoren und Benutzer`,
  HOW_TITLE: `Branding`,
  HOW_DESCRIPTION: `Markenstile und Darstellungen`,
  ADDITIONAL_CONFIGURATIONS: `Zusätzliche Konfigurationen`,
});
