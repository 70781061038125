import { createSelector } from 'reselect';
import { State } from '../rootStore';
import { SortMethods, Statuses } from './types';
import dayjs from 'dayjs';
import { bookingPageSelectors } from '../bookingPages';
import { CreateUserEventInput, CustomFieldType } from '../../API';
import { isOldData } from '../../services/utils';

const scheduledMeetingsState = (state: State) => state.scheduledMeetings;

const selectEvents = createSelector(scheduledMeetingsState, (state) => state.bookedMeetings);
const selectIsFetching = createSelector(scheduledMeetingsState, (state) => state.isFetching);
const selectError = createSelector(scheduledMeetingsState, (state) => state.error);

const selectSortMethod = createSelector(scheduledMeetingsState, (state) => state.sortMethod);
const selectDateRange = createSelector(scheduledMeetingsState, (state) => state.dateRange);
const selectStatus = createSelector(scheduledMeetingsState, (state) => state.status);
const selectSelectedBookingPages = createSelector(scheduledMeetingsState, (state) => state.selectedBookingPages);
const selectSelectedWorkspaces = createSelector(scheduledMeetingsState, (state) => state.selectedWorkspaces);

const selectLastLoadTime = createSelector(scheduledMeetingsState, (state) => state.lastLoadTime);
const selectIsSpinnerFetching = createSelector(
  selectIsFetching,
  selectLastLoadTime,
  (isFetching, loadTime) => isFetching && !isOldData(loadTime)
);
const selectIsSkeletonFetching = createSelector(
  selectIsFetching,
  selectLastLoadTime,
  (isFetching, loadTime) => isFetching && isOldData(loadTime)
);

const selectFilteredEvents = createSelector(
  selectEvents,
  selectStatus,
  selectSelectedBookingPages,
  selectSelectedWorkspaces,
  selectSortMethod,
  selectDateRange,
  (events, status, bookingPagesIds, workspaceIds, method, dateRange) =>
    events.filter((event) => {
      if (bookingPagesIds.length && !bookingPagesIds.includes(event.bookingPageId)) {
        return false;
      }
      //TODO: need to save workspaceId on Event (or load all booking pages from all workspaces)
      // if (workspaceIds.length && !workspaceIds.includes(event.workspaceId)) {
      //   return false;
      // }
      if (status && status !== Statuses.ALL) {
        if (
          (!!event.canceled?.isCanceled && status === Statuses.BOOKED) ||
          (!event.canceled?.isCanceled && status === Statuses.CANCELED)
        ) {
          return false;
        }
      }

      if (method === SortMethods.UPCOMING || method === SortMethods.PAST) {
        const today = dayjs().format('YYYY-MM-DD');
        return method === SortMethods.UPCOMING ? event.eventDate >= today : event.eventDate < today;
      } else if (dateRange && dateRange.length === 2) {
        return (
          event.eventDate >= dayjs(dateRange[0]).format('YYYY-MM-DD') &&
          event.eventDate <= dayjs(dateRange[1]).format('YYYY-MM-DD')
        );
      }
    })
);

const selectEventsByDay = createSelector(selectFilteredEvents, (events) => {
  const eventsByDayMap: { [key: string]: CreateUserEventInput[] } = {};

  events.forEach((event) => {
    if (!eventsByDayMap[event.eventDate]) {
      eventsByDayMap[event.eventDate] = [];
    }
    eventsByDayMap[event.eventDate].push(event);
  });

  for (const day in eventsByDayMap) {
    eventsByDayMap[day].sort((aEvent, bEvent) => (aEvent.startTime > bEvent.startTime ? 1 : -1));
  }

  return eventsByDayMap;
});

const selectSelectedEvent = createSelector(scheduledMeetingsState, (state) => state.selectedBookedMeeting);
const selectPreviewBookingPage = createSelector(
  selectSelectedEvent,
  bookingPageSelectors.selectBookingPages,
  (event, bookingPages) => bookingPages.find((page) => page.id === event.bookingPageId)
);

const selectEventId = createSelector(selectSelectedEvent, (event) => event.eventId);
const selectEventCanceled = createSelector(selectSelectedEvent, (event) => event.canceled);
const selectEventCancelType = createSelector(selectEventCanceled, (canceled) => canceled?.type);
const selectEventCancelNote = createSelector(selectEventCanceled, (canceled) => canceled?.note);
const selectEventAttendeeName = createSelector(selectSelectedEvent, (event) => {
  const attendeeName = event.inputFields?.find((field) => field?.fieldType === CustomFieldType.NAME);
  return attendeeName?.value ? attendeeName.value : '';
});
const selectEventAttendeeEmail = createSelector(selectSelectedEvent, (event) => {
  const attendeeEmail = event.inputFields?.find((field) => field?.fieldType === CustomFieldType.EMAIL);
  return attendeeEmail?.value ? attendeeEmail.value : '';
});

const selectEventAttendeeInfo = createSelector(
  selectEventAttendeeEmail,
  selectEventAttendeeName,
  (email, name) => `${name} (${email})`
);

const selectHostId = createSelector(selectSelectedEvent, (event) => event.host);
const selectGuestsIds = createSelector(
  selectSelectedEvent,
  (event) => event.cohostsMembers?.map((member) => member?.id) || [] //TODO work with other members, not only SUMO
);

const selectIsFilterInUse = createSelector(
  selectSortMethod,
  selectStatus,
  selectSelectedBookingPages,
  (sortMethod, status, bookingPages) =>
    sortMethod !== SortMethods.UPCOMING || status !== Statuses.ALL || Boolean(bookingPages.length)
);

export const scheduledMeetingsSelectors = {
  selectEvents,
  selectIsFetching,
  selectIsSpinnerFetching,
  selectIsSkeletonFetching,
  selectError,
  selectSortMethod,
  selectDateRange,
  selectStatus,
  selectSelectedBookingPages,
  selectSelectedWorkspaces,
  selectFilteredEvents,
  selectEventsByDay,
  selectSelectedEvent,
  selectPreviewBookingPage,
  selectEventId,
  selectEventCanceled,
  selectEventCancelType,
  selectEventCancelNote,
  selectEventAttendeeName,
  selectEventAttendeeEmail,
  selectEventAttendeeInfo,
  selectHostId,
  selectGuestsIds,
  selectIsFilterInUse,
};
