import React, { ReactNode, isValidElement, memo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavigationSettings, navigationsActions, navigationsSelectors } from '../../../store/navigations';

interface IStep {
  name: string;
  children?: ReactNode;
}

interface IContainer {
  settings: NavigationSettings;
  children: ReactNode;
}

const Step = memo(({ children }: IStep) => <>{children}</>);

const Container = memo(({ settings, children }: IContainer) => {
  const dispatch = useDispatch();
  const currentStep = useSelector(navigationsSelectors.selectCurrentStepName(settings.key));

  useEffect(() => {
    dispatch(navigationsActions.initializeNavigation(settings));
    return () => {
      dispatch(navigationsActions.deleteNavigation(settings.key));
    };
  }, []);

  return (
    <>
      {React.Children.map(children, (step) => {
        if (!isValidElement(step)) return;
        const { name: stepName } = step.props as IStep;
        return stepName === currentStep && step;
      })}
    </>
  );
});

export const Navigation = { Container, Step };

Container.displayName = 'Navigation.Container';
Step.displayName = 'Navigation.Step';
