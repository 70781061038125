import { useSelector } from 'react-redux';
import { BookingPagePreviewModal } from '../../bookingPages';
import { workspacesActions, workspacesSelectors } from '../../../store/workspaces';
import { useDispatch } from 'react-redux';
import { Button } from 'primereact/button';
import labels from './labels';
import { UpdateBookingPageInput } from '../../../API';
import { useState } from 'react';
import { PencilSquareIcon } from '@heroicons/react/24/outline';

export const WorkspaceBrandingStep = () => {
  const dispatch = useDispatch();
  const bookingPage = useSelector(workspacesSelectors.selectBookingPageForPreview);
  const [brandingOpened, setBrandingOpened] = useState(false);

  const handleSaveBranding = (bookingPage?: UpdateBookingPageInput) => {
    if (bookingPage) {
      dispatch(workspacesActions.updateWorkspace({ style: bookingPage.style, labels: bookingPage.labels }));
    }
    setBrandingOpened(false);
  };

  return (
    <>
      <BookingPagePreviewModal
        visible={brandingOpened}
        bookingPage={bookingPage}
        onSave={handleSaveBranding}
        onClose={() => setBrandingOpened(false)}
      />
      <div className="flex-left-center gap-10px">
        <div
          className="flex-center w-70px h-70px border-radius-8px bg-heavy-1 text-heavy-60 hover-text-heavy-80 cursor-pointer"
          onClick={() => setBrandingOpened(true)}
        >
          <PencilSquareIcon className="icon-24px" />
        </div>
        <div className="flex flex-column">
          <div className="text-title-xs-med text-heavy-100">{labels.title}</div>
          <div className="text-body-s-reg text-heavy-60 mt-4px mb-10px">{labels.description}</div>
          <Button
            className="button-blue hover-bg-primary-white w-fit mt-auto"
            text
            label={labels.customize}
            onClick={() => setBrandingOpened(true)}
          />
        </div>
      </div>
    </>
  );
};
