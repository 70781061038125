import { GoogleOAuthProvider, useGoogleLogin } from '@react-oauth/google';
import { API, Auth } from 'aws-amplify';
import { Button } from 'primereact/button';
import { IntegrationCard } from '../../components/integration';
import { WorkspaceIntegrationType } from '../../API';

export const AuthPoC = () => {
  console.log(process.env.REACT_APP_GOOGLE_CLIENT_ID);
  return (
    <>
      <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID || ''}>
        <AuthPoCInner />
      </GoogleOAuthProvider>

      <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID || ''}>
        <SwitchAccountInner />
      </GoogleOAuthProvider>

      <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID || ''}>
        <JoinTenantInner />
      </GoogleOAuthProvider>

      <IntegrationCard
        integration={{
          type: WorkspaceIntegrationType.HUBSPOT,
          label: 'HubSpot',
          icon: '',
          description: 'HubSpot',
        }}
      />

      <IntegrationCard
        integration={{
          type: WorkspaceIntegrationType.SALESFORCE,
          label: 'Salesforce',
          icon: '',
          description: 'Salesforce',
        }}
      />
    </>
  );
};

const AuthPoCInner = () => {
  const login = useGoogleLogin({
    onSuccess: async (codeResponse) => {
      console.log(codeResponse);
      await API.post('publicApi', '/api/public/auth/google', {
        body: {
          code: codeResponse.code,
          needAuthorize: true,
        },
      })
        .then(async (response) => {
          console.log(response);
          console.log('Before signIn');
          let cognitoUser = await Auth.signIn(response.email);
          console.log('Before sendCustomChallengeAnswer');
          cognitoUser = await Auth.sendCustomChallengeAnswer(cognitoUser, response.secret);
          console.log('Before currentSession');
          console.log('cognitoUser', cognitoUser);
          console.log((await Auth.currentSession()).getIdToken());
        })
        .catch((reason) => alert(JSON.stringify(reason)));
    },
    onError: (errorResponse) => console.log(errorResponse),
    flow: 'auth-code',
    scope: 'openid https://www.googleapis.com/auth/calendar.events',
    ux_mode: 'popup',
  });
  return (
    <div>
      <Button onClick={() => login()}>Login with google</Button>
    </div>
  );
};

const SwitchAccountInner = () => {
  const login = useGoogleLogin({
    onSuccess: async (codeResponse) => {
      console.log(codeResponse);
      await API.post('publicApi', '/api/public/auth/google', {
        body: {
          code: codeResponse.code,
          switchAccount: true,
        },
      });
    },
    onError: (errorResponse) => console.log(errorResponse),
    flow: 'auth-code',
    scope: 'openid https://www.googleapis.com/auth/calendar.events',
    ux_mode: 'popup',
  });
  return (
    <div>
      <Button onClick={() => login()}>Switch account to another google</Button>
    </div>
  );
};

const JoinTenantInner = () => {
  const login = useGoogleLogin({
    onSuccess: async (codeResponse) => {
      console.log(codeResponse);
      await API.post('publicApi', '/api/public/auth/google', {
        body: {
          code: codeResponse.code,
          joinTenant: '6cd29b1c-6851-49fc-83a6-addefb0a5307',
        },
      });
    },
    onError: (errorResponse) => console.log(errorResponse),
    flow: 'auth-code',
    scope: 'openid https://www.googleapis.com/auth/calendar.events',
    ux_mode: 'popup',
  });
  return (
    <div>
      <Button onClick={() => login()}>Join tenant</Button>
    </div>
  );
};
