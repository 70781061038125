import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { Button } from 'primereact/button';
import { PublicBookingPageContainer } from '../../publicBookingPage';
import {
  scheduledMeetingsSelectors,
  RESCHEDULE_EVENT_MODAL_NAME,
  rescheduleEventModalActions,
} from '../../../store/bookedMeetings';
import { Modal } from '../../common';

export const BookedMeetingRescheduleModal = () => {
  const dispatch = useDispatch();
  const selectedEvent = useSelector(scheduledMeetingsSelectors.selectSelectedEvent);
  const previewBookingPage = useSelector(scheduledMeetingsSelectors.selectPreviewBookingPage);

  const handleClose = () => {
    dispatch(rescheduleEventModalActions.closeModal());
  };

  return (
    <Modal.Container
      name={RESCHEDULE_EVENT_MODAL_NAME}
      className="w-840px"
      contentClassName="p-0 bg-gray-300"
      headerClassName="hidden"
    >
      <>
        <Button icon="pi pi-times" className="absolute right-0 -mr-7 bg-white" outlined onClick={handleClose} />
        <PublicBookingPageContainer rescheduleEvent={selectedEvent} previewBookingPage={previewBookingPage} />
      </>
    </Modal.Container>
  );
};
