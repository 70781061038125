import { useEffect } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { authenticationSelectors } from '../store/authentication';
import { Path } from './pathes';
// import Layout from '../components/public/layout/layout';
import { ModalLeaveAccount, ModalUserAccounts } from '../components/public';
import { GlobalError } from '../components/common';
import { errorSelectors } from '../store/error/selectors';
import { useDispatch } from 'react-redux';
import { globalSelectors } from '../store/global';
import { errorActions } from '../store/error/actions';
import { ErrorCodes } from '../store/error/types';
import { navigationService } from '../services/NavigationService';
import { userSettingsSelectors } from '../store/userSettings';
import { useSession } from '../hooks/useSession';

export const PublicRoute = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const { setIsAuthenticated } = useSession();
  const authenticationDataReceived = useSelector(authenticationSelectors.selectAuthenticationDataReceived);

  const userId = useSelector(authenticationSelectors.selectUserId);
  const isQuickSetupFinished = useSelector(userSettingsSelectors.selectIsQuickSetupFinished);
  const searchParams = new URLSearchParams(location.search); // params in URL after "?"
  const isGlobalError = useSelector(globalSelectors.selectIsError);
  const hasError = useSelector(errorSelectors.selectHasError);
  const isLicenseActive = useSelector(authenticationSelectors.selectIsLicenseActive);
  const isSumoONEAdmin = useSelector(authenticationSelectors.selectIsSumo1Admin);

  useEffect(() => {
    if (isGlobalError) {
      dispatch(errorActions.setTheError(ErrorCodes.CODE_500));
    }
  }, [isGlobalError]);

  useEffect(() => {
    if (authenticationDataReceived || userId) {
      setIsAuthenticated(true); // set isAuthenticated to true in useUserNavigation
      if (isSumoONEAdmin) {
        navigationService.navigateTo(Path.OPSConsoleOrgs); // TODO navigate to Path.OPSConsole when Dashboard is done
      } else if (!isLicenseActive) {
        // The authentication request indicates that the license has expired.
        // if expired - redirect to License Expired
        navigationService.navigateTo(Path.LicenseExpired);
      } else if (isQuickSetupFinished === false) {
        navigationService.navigateTo(`${Path.QuickSetup}?${searchParams.toString()}`);
      } else {
        navigate(Path.BookingPages); // the bug, i mean side quest :) navigationService redirect only localhost here for some reason // navigationService.navigateTo(Path.BookingPages);
      }
    }
  }, [userId, authenticationDataReceived, isLicenseActive, isSumoONEAdmin]);

  if (hasError) return <GlobalError />;

  return (
    <>
      <Outlet />

      <ModalUserAccounts />
      <ModalLeaveAccount />
    </>
  );
};
