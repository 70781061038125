import { CloudArrowUpIcon, PencilIcon, TrashIcon } from '@heroicons/react/24/outline';
import { JourneyFormField, JourneyFormFieldType } from '../../../API';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { Dropdown } from 'primereact/dropdown';
import { RadioButton } from 'primereact/radiobutton';
import { ReactComponent as DragAndDrop } from '../../../assets/icons/20-drag-and-drop.svg';
import labels from './labels';

type AddPageFormFieldCardProps = {
  field: JourneyFormField;
  handleEdit: () => void;
  handleDelete: () => void;
  isDeletable: boolean;
  isReadOnly: boolean;
};
export const AddPageFormFieldCard = ({
  field,
  handleEdit,
  handleDelete,
  isDeletable,
  isReadOnly,
}: AddPageFormFieldCardProps) => {
  return (
    <div className="flex flex-row">
      {!isReadOnly && (
        <div className="relative w-0 h-20px drag-and-drop">
          <DragAndDrop className="absolute mr-2px right-0" />
        </div>
      )}
      <div className="flex flex-column gap-6px w-full mr-20px">
        <div
          className={`text-heavy-100 ${
            field.type === JourneyFormFieldType.TITLE
              ? 'text-title-lg-med'
              : field.type === JourneyFormFieldType.SUBTITLE
              ? 'text-title-s-med'
              : 'text-title-xs-med'
          }`}
        >
          {field.label || ''}
          {field.required ? '*' : ''}
        </div>
        {field.type &&
          [
            JourneyFormFieldType.NAME,
            JourneyFormFieldType.EMAIL,
            JourneyFormFieldType.PHONE,
            JourneyFormFieldType.SINGLE,
          ].includes(field.type) && <InputText className="pointer-events-none" />}

        {field.type === JourneyFormFieldType.MULTIPLE && <InputTextarea className="pointer-events-none" />}

        {field.type === JourneyFormFieldType.DROPDOWN && <Dropdown options={field.answers || []} value={''} />}

        {field.type === JourneyFormFieldType.RADIO_BUTTONS &&
          field.answers?.map((answer, index) => (
            <div key={`${answer}_${index}`} className="flex-left-center gap-10px mt-6px">
              <RadioButton checked={false} className="pointer-events-none" />
              <label className="text-label-input-reg text-heavy-100">{answer}</label>
            </div>
          ))}

        {field.type === JourneyFormFieldType.ATTACHMENT && (
          <div className="flex-center bg-heavy-1 py-8px border-radius-10px">
            <div className="flex-center gap-4px text-heavy-60">
              <div className="w-48px h-48px text-heavy-60">
                <CloudArrowUpIcon width={48} height={48} />
              </div>
              <div className="text-label-xs-reg">{labels.pictureSize}</div>
            </div>
          </div>
        )}
      </div>
      
      {!isReadOnly && (
        <div className="flex gap-4px">
          <div className="action-button-sm" onClick={handleEdit}>
            <PencilIcon className="icon-16px" />
          </div>
          <div className="w-24px h-24px">
            <div className={`action-button-sm ${isDeletable ? '' : 'cursor-auto p-disabled'}`} onClick={handleDelete}>
              <TrashIcon className="icon-16px" />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
