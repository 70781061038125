import { InputSwitch } from 'primereact/inputswitch';
import { workspacesSelectors } from '../../../store/workspaces';
import { useDispatch, useSelector } from 'react-redux';
import { workspacesActions } from '../../../store/workspaces/actions';
import { userSettingsSelectors } from '../../../store/userSettings';

export const WorkspaceSecurity = () => {
  const dispatch = useDispatch();
  const isWorkspacesReadOnly = useSelector(userSettingsSelectors.selectIsReadOnlyWorkspace);
  const isNoCustomerData = useSelector(workspacesSelectors.selectNoCustomerData);

  return (
    <div className="flex gap-10px">
      <InputSwitch
        checked={isNoCustomerData}
        onChange={(e) => dispatch(workspacesActions.updateWorkspace({ noCustomerData: !!e.value }))}
        disabled={isWorkspacesReadOnly}
      />
      <div className="flex-1 flex flex-column gap-4px">
        <div className="text-title-xs-med text-heavy-100">Do not store my customer data</div>
        <div className="text-body-s-reg">
          If you do not want SUMO1 to store the names and email addresses of attendees, Google Calendar and Microsoft
          Calendar for compliance reasons, you can do so without contacting support. Since SUMO1 will not store a cache
          of your customer data, we will pull this data in real-time from your source system, which may introduce a
          slight delay to load time of the Booking Page.
        </div>
      </div>
    </div>
  );
};
