import dayjs from 'dayjs';
import { Link } from 'react-router-dom';
import labels from './labels';
import urlConstants from '../../../shared/JSON/urlConstants.json';

const AppFooter = () => {
  return (
    <div className="flex justify-content-center">
      <div style={{ color: '#464C59', fontSize: '11px' }}>
        <span className="pr-1">{`${labels.footerTextPart1}${dayjs().year()}${labels.footerTextPart2}`}</span>|
        <Link
          to={urlConstants.PRIVACY_POLICY_URL}
          target="_blank"
          className="px-1 hover:underline"
          style={{ color: '#464C59' }}
        >
          {labels.privacyPolicy}
        </Link>
        |
        <Link
          to={urlConstants.SECURITY_POLICY_URL}
          target="_blank"
          className="px-1 hover:underline"
          style={{ color: '#464C59' }}
        >
          {labels.securityPolicy}
        </Link>
        |
        <Link
          to={urlConstants.TERMS_OF_USER_URL}
          target="_blank"
          className="px-1 hover:underline"
          style={{ color: '#464C59' }}
        >
          {labels.terms}
        </Link>
      </div>
      {/* <div className="py-2">
        <img src={sumoIconLight} alt="Logo" className="h-4rem w-4rem" />
      </div> */}
    </div>
  );
};

export default AppFooter;
