import { useDispatch, useSelector } from 'react-redux';
import {
  NO_SHOW_EVENT_MODAL_NAME,
  noShowEventModalActions,
  noShowEventModalSelectors,
  scheduledMeetingsActions,
  scheduledMeetingsSelectors,
} from '../../../store/bookedMeetings';
import { ConfirmationModal } from '../../common';
import { modalsActions } from '../../../store/modals';
import { useEffect } from 'react';
import labels from './labels';
import { Button } from 'primereact/button';
export const BookedMeetingNoShowModal = () => {
  const dispatch = useDispatch();
  const isVisible = useSelector(noShowEventModalSelectors.selectIsModalOpen);
  const selectedEvent = useSelector(scheduledMeetingsSelectors.selectSelectedEvent);
  const attendeeName = useSelector(scheduledMeetingsSelectors.selectEventAttendeeName);

  useEffect(() => {
    dispatch(modalsActions.initializeModal({ name: NO_SHOW_EVENT_MODAL_NAME }));
    return () => {
      dispatch(modalsActions.deleteModal(NO_SHOW_EVENT_MODAL_NAME));
    };
  }, []);

  const handleClose = () => {
    dispatch(noShowEventModalActions.closeModal());
  };

  const handleConfirm = () => {
    dispatch(
      scheduledMeetingsActions.updateBookedMeetingRequest({
        ...selectedEvent,
        isNoShow: true,
      })
    );
    dispatch(noShowEventModalActions.closeModal());
  };

  return (
    <ConfirmationModal
      visible={isVisible}
      title={labels.markAsNoShow}
      description={`${labels.markAsNoShowDescPart1} "${attendeeName}" ${labels.markAsNoShowDescPart2}`}
      CustomConfirmButton={<Button onClick={handleConfirm} label={labels.markAsNoShow} />}
      cancelButtonLabel={labels.cancel}
      onCancel={handleClose}
      onClose={handleClose}
    />
  );
};
