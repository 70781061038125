import { UpdateGroupBookingPageInput } from '../../API';

export const updateBrandingSettings = (
  state: UpdateGroupBookingPageInput,
  payload: Partial<UpdateGroupBookingPageInput>
) => {
  const res: Partial<UpdateGroupBookingPageInput> = {};
  if (
    payload.bookingPages &&
    payload.bookingPages.length &&
    !payload.bookingPages.some((page) => page?.bookingPageId === state.brandingBookingPageId)
  ) {
    // we remove page that style was in use
    res.brandingBookingPageId = payload.bookingPages[0]?.bookingPageId;
  }
  return res;
};
