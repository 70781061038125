import { CreateStaffInput } from '../../../API';
import { API_PUBLIC } from '../../../types/constants';
import { handleAPIRequest } from '../../utils/reduxUtils';
import { GetStaffListType } from './types';

// ***
// When you change the requests do not forget to specify new data
// in docs/Endpoints.md
// ***

export const getStaff = async (): Promise<GetStaffListType> => {
  return handleAPIRequest<GetStaffListType>(`${API_PUBLIC}/opsconsole/getStaffList`, {});
};

export const saveStaff = async (staffRecord: CreateStaffInput): Promise<void> => {
  return handleAPIRequest<void>(`${API_PUBLIC}/opsconsole/upsertStaff`, { staffRecord });
};

export const deleteStaff = async (email: string): Promise<void> => {
  return handleAPIRequest<void>(`${API_PUBLIC}/opsconsole/deleteStaff`, { email });
};
