import dayjs from 'dayjs';
import { LicenseType, TenantStatus } from '../../../API';
import { converLocalDateTimeObjectToDate } from '../../../services/DateService';
import { UserDetails, getRoleNameByRole } from '../users';
import {
  LicenseDataType,
  OPSConsoleRole,
  OPSConsoleTenant,
  OrgDetailsUserRecord,
  OrgsDataTableType,
  OrgsDataType,
} from './types';
import { ORG_STATUS_VALUES } from './constants';
import { DEFAULT_SUBSCRIPTION, Subscription, NO_SUBSCRIPTION_ID, getCurrencyLabelByCode } from '../../billing';

export const assignRoleToUser = (tenant: OPSConsoleTenant, users: OrgDetailsUserRecord[], roles: OPSConsoleRole[]) => {
  const usersWithRoles: UserDetails[] = users.map((user) => {
    const role = roles.find((role) => role.id === user.roleId);

    return {
      userId: user.userId,
      userName: user.name,
      lastActive: user.lastActive,
      email: user.email,
      accountId: '', // TODO add accountId
      accountName: '', // TODO add accountName
      tenantId: tenant.tenantId,
      status: user.status,
      role: role || ({} as OPSConsoleRole),
      roleName: role && getRoleNameByRole(role),
    };
  });

  return usersWithRoles;
};

export const convertToOrgsDataTableType = (orgsData: OrgsDataType[]) => {
  const convertedData: OrgsDataTableType[] = orgsData.map((org) => {
    const lastLicense = org.licenses.sort((a, b) => (a.endDate > b.endDate ? 1 : -1))[0];

    const daysLeft = dayjs(lastLicense.endDate).diff(dayjs().format('YYYY-MM-DD'), 'day');

    const status = defineStatus(lastLicense, daysLeft, org);

    let bookedMeetings = 0;
    const userEmails: string[] = [];
    const subscription: Subscription = org?.subscription || DEFAULT_SUBSCRIPTION;
    const currencyCode: string = subscription.currency_code;
    const amount: number = subscription.subscription_items[0]?.amount / 100 || 0;
    const unitPrice: number = subscription.subscription_items[0]?.unit_price / 100 || 0;
    const subscriptionId: string = subscription.id || NO_SUBSCRIPTION_ID;

    for (const user of org.userRecords) {
      bookedMeetings += user.statistics?.eventCreated || 0;
      user.email && userEmails.push(user.email.toLowerCase());
    }

    return {
      tenantId: org.tenantId,
      name: org.name,
      status,
      type: org.type,
      term: lastLicense.type,
      owned: lastLicense.owned,
      assigned: lastLicense.assigned,
      percentOfWeekAdoption: (+org.percentOfWeekAdoption * 100).toFixed(),
      percentOfMonthAdoption: (+org.percentOfMonthAdoption * 100).toFixed(),
      createdAt: converLocalDateTimeObjectToDate(org.createdAt).toISOString(),
      updatedAt: converLocalDateTimeObjectToDate(lastLicense.updatedAt).toISOString(),
      accountId: org.accountId,
      accountName: org.accountName,
      note: org.note,
      amount: getCurrencyLabelByCode(currencyCode) + amount,
      amountPerUser: getCurrencyLabelByCode(currencyCode) + unitPrice,
      subscriptionId,
      bookedMeetings,
      startDate: lastLicense.startDate,
      endDate: lastLicense.endDate,
      daysLeft,
      userEmails,
    };
  });

  return convertedData;
};

const defineStatus = (lastLicense: LicenseDataType, daysLeft: number, org: OrgsDataType) => {
  // check if the license is a trial and has expired or the tenant status is expired
  if ((lastLicense.type === LicenseType.TRIAL && daysLeft <= 0) || org.status === TenantStatus.EXPIRED) {
    return ORG_STATUS_VALUES.Expired;
  }

  // check if the license is not a trial and has expired or there are due invoices
  if (
    (lastLicense.type !== LicenseType.TRIAL && daysLeft <= 0) ||
    (org.subscription && org.subscription?.due_invoices_count > 0)
  ) {
    return ORG_STATUS_VALUES.PaymentLate;
  }

  return ORG_STATUS_VALUES.Active;
};
