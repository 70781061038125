import { useDispatch, useSelector } from 'react-redux';
import {
  USERS_CSV_HEADERS,
  getRoleNameByRole,
  OPSConsoleUsersActions,
  OPSConsoleUsersSelectors,
} from '../../../store/opsConsole/users';
import { SectionHeader } from '../../../components/common';
import labels from './labels';
import { useEffect } from 'react';
import { UsersTable } from '../../../components/opsConsole';
import { Button } from 'primereact/button';
import { convertUnixToFormattedDate, formatDateTimeThroughGap } from '../../../services/DateService';
import { DEFAULT_PRICING_TIER } from '../../../store/opsConsole/orgs';
import dayjs from 'dayjs';
import { DateFormat, TimeFormat, userSettingsSelectors } from '../../../store/userSettings';
import { downloadCSV } from '../../../services/utils';
import { CloudArrowDownIcon } from '@heroicons/react/24/outline';
import { Path } from '../../../routing';

export const OPSConsoleUsers = () => {
  const dispatch = useDispatch();
  const isFetching = useSelector(OPSConsoleUsersSelectors.selectIsFetching);
  const users = useSelector(OPSConsoleUsersSelectors.selectUsers);
  const dateFormat = useSelector(userSettingsSelectors.selectDateFormat) || DateFormat.default;
  const timeFormat = useSelector(userSettingsSelectors.selectTimeFormat) || TimeFormat.default;

  useEffect(() => {
    dispatch(OPSConsoleUsersActions.getUsersRequest());
  }, []);

  // utility to Convert Users Array to CSV
  const convertArrayToCSV = (): string => {
    const rows = users.map((user) =>
      [
        user.userName,
        user.email,
        user.accountId,
        user.accountName,
        user.tenantId,
        user.roleName || getRoleNameByRole(user.role),
        user.status,
        user.firstLogin && formatDateTimeThroughGap(user.firstLogin),
        user.lastActive && convertUnixToFormattedDate(Number(user.lastActive), dateFormat, timeFormat),
        user.bookedMeetings || 0,
        DEFAULT_PRICING_TIER,
        user?.license?.type || '',
        user?.license?.endDate ? dayjs(user?.license?.endDate).format(DateFormat.DDMMYYYY) : '',
        user?.license?.assigned || 0,
      ].join(',')
    );

    return [USERS_CSV_HEADERS.join(',').toUpperCase(), ...rows].join('\n');
  };

  return (
    <div className="sumo-card-bg flex flex-column pt-16px">
      <SectionHeader
        loading={isFetching}
        title={labels.title}
        itemsLength={users.length}
        searchPaths={[Path.OPSConsoleUsers, Path.OPSConsoleUserDetails]}
        hideButton
        extraContent={
          <Button
            className="flex-none gap-8px button-xl button-white"
            onClick={() => downloadCSV(convertArrayToCSV(), 'users.csv')}
          >
            <CloudArrowDownIcon className="icon-18px" />
            <div>{labels.export}</div>
          </Button>
        }
      />

      <UsersTable />
    </div>
  );
};
