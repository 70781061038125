export const AuthenticationToastsNotifications = Object.freeze({
  CHOOSE_AN_ACCOUNT_ERROR_TOAST: `Erro na solicitação de escolha de conta`,
  DELETE_USER_ERROR_MESSAGE: `Erro na solicitação de exclusão de usuário`,
  DELETE_USER_SUCCESS_MESSAGE: `Solicitação de exclusão de usuário bem-sucedida`,
  GET_USER_AUTH_ERROR_MESSAGE: `Erro na solicitação de login do usuário`,
  GET_USER_LOGIN_ERROR_MESSAGE: `Não foi encontrado nenhum utilizador com esse e-mail`,
  GET_USER_LOGOUT_ERROR_MESSAGE: `Erro na solicitação de logout do usuário`,
  GET_MAIN_DATA_ERROR_MESSAGE: `Falha na solicitação de autenticação`,
  ROLE_DEACTIVATED_ERROR_MESSAGE: `O papel foi desativado. Por favor, entre em contato com seu administrador SUMO1.`,
  USER_DEACTIVATED_ERROR_MESSAGE: `O usuário foi desativado. Por favor, entre em contato com seu administrador SUMO1.`,
  TRIAL_LIMIT_ERROR_MESSAGE: `Você excedeu o limite de 30 itens para a versão de teste`,
  INVITE_EXPIRED_ERROR_TITLE: `Convite Expirado`,
  INVITE_EXPIRED_ERROR_MESSAGE_PART1: `Seu convite expirou. Por favor, entre em contato com seu administrador para obter um novo link ou tente `,
  INVITE_EXPIRED_ERROR_MESSAGE_PART2: `se inscrever sem um convite`,
  SWITCH_ACCOUNT_ERROR_MESSAGE: `Outra conta já está associada a este e-mail`,
});

export const Authorise = Object.freeze({
  SING_IN_TITLE_LINE_1: `Bem-vindo`,
  SING_IN_TITLE_LINE_2: `de volta ao SUMO`,
  SING_IN_SUBTITLE: `Faça login no seu hub de agendamento.`,
  SING_IN_GOOGLE: `Entrar com Google`,
  SING_IN_MICROSOFT: `Entrar com Microsoft`,
  SING_IN_DONT_HAVE_AN_ACCOUNT: `Não tem uma conta?`,
  SIGN_UP_COMMENT: `*SUMO1 não requer uma licença Salesforce.`,
  SING_UP_TITLE_LINE_1: `Agendamento online`,
  SING_UP_TITLE_LINE_2: `e mais`,
  SING_UP_SUBTITLE: `Comece a usar o serviço gratuitamente com apenas um clique.`,
  SING_UP_GOOGLE: `Inscrever-se com Google`,
  SING_UP_MICROSOFT: `Inscrever-se com Microsoft`,
  SIGN_UP_FREE_TRIAL: `15 dias de teste gratuito. Não é necessário cartão de crédito`,
  SING_UP_HAVE_AN_ACCOUNT: `Já tem uma conta?`,
  LOOKING_FOR_SUMO_CEP: `Procurando SUMO para Salesforce?`,
  LOADING_LABEL_1: `Autorizando Usuário`,
  LOADING_LABEL_2: `Verificando Calendário`,
  LOADING_LABEL_3: `Criando Tokens`,
});

export const DialogLeaveAccount = Object.freeze({
  ACCEPT_TEXT: 'Gostaria de aceitar este convite e sair da outra conta do SUMO1?',
  INVITE_TEXT_PART_1: `Você foi convidado por`,
  INVITE_TEXT_PART_2: `para se juntar ao SUMO1, mas seu e-mail já está associado a outra conta do SUMO1.`,
  LAST_SUPER_ADMIN_NOTE: `Você é o único Super Administrador na organização. Você deve primeiro fazer login na sua organização existente e transferir o cargo de "Super Administrador" para outro usuário antes de aceitar este convite. Entre em contato com o suporte da SUMO para obter assistência.`,
  TITLE: `Sair da conta`,
});

export const DialogUserAccounts = Object.freeze({
  ACCEPT_TEXT: `Gostaria de aceitar este convite e sair da outra conta do SUMO1?`,
  INVITE_FROM: `Convite de:`,
  INVITE_TEXT: `Você foi convidado a se juntar ao SUMO1, mas está tentando se cadastrar para uma nova conta do SUMO1.`,
  SELECT_TEXT: `Selecione abaixo se deseja aceitar este convite em vez de criar uma nova conta corporativa para o SUMO1.`,
  STAY_IN_MY_ACCOUNT: `Ficar na minha conta`,
  TITLE: `Aceitar convite`,
});
