import { combineReducers } from 'redux';
import { BookedMeetingsActionTypes, BookedMeetingsAction } from './actions';
import { CreateUserEventInput } from '../../API';
import { SortMethods, Statuses } from './types';
import { GlobalAction, GlobalActionTypes } from '../global/actions';

const bookedMeetings = (state: CreateUserEventInput[] = [], action: BookedMeetingsAction | GlobalAction) => {
  switch (action.type) {
    case BookedMeetingsActionTypes.GET_SCHEDULED_MEETINGS_SUCCESS:
      return action.payload;
    case BookedMeetingsActionTypes.UPDATE_SCHEDULED_MEETING_SUCCESS:
      return [...state.filter((record) => record.eventId !== action.payload.eventId), action.payload];
    case GlobalActionTypes.RESET_WHOLE_STORE:
      return [];
    default:
      return state;
  }
};

const isFetching = (state = false, action: BookedMeetingsAction | GlobalAction) => {
  switch (action.type) {
    case BookedMeetingsActionTypes.GET_SCHEDULED_MEETINGS_REQUEST:
    case BookedMeetingsActionTypes.UPDATE_SCHEDULED_MEETING_REQUEST:
    case BookedMeetingsActionTypes.CANCEL_SCHEDULED_MEETING_REQUEST:
      return true;
    case BookedMeetingsActionTypes.GET_SCHEDULED_MEETINGS_SUCCESS:
    case BookedMeetingsActionTypes.GET_SCHEDULED_MEETINGS_FAIL:
    case BookedMeetingsActionTypes.UPDATE_SCHEDULED_MEETING_SUCCESS:
    case BookedMeetingsActionTypes.UPDATE_SCHEDULED_MEETING_FAIL:
    case BookedMeetingsActionTypes.CANCEL_SCHEDULED_MEETING_SUCCESS:
    case BookedMeetingsActionTypes.CANCEL_SCHEDULED_MEETING_FAIL:
      return false;
    case GlobalActionTypes.RESET_WHOLE_STORE:
      return false;
    default:
      return state;
  }
};

const error = (state: unknown | null = null, action: BookedMeetingsAction | GlobalAction) => {
  switch (action.type) {
    case BookedMeetingsActionTypes.GET_SCHEDULED_MEETINGS_REQUEST:
    case BookedMeetingsActionTypes.UPDATE_SCHEDULED_MEETING_REQUEST:
    case BookedMeetingsActionTypes.CANCEL_SCHEDULED_MEETING_REQUEST:
      return null;
    case BookedMeetingsActionTypes.GET_SCHEDULED_MEETINGS_FAIL:
    case BookedMeetingsActionTypes.UPDATE_SCHEDULED_MEETING_FAIL:
    case BookedMeetingsActionTypes.CANCEL_SCHEDULED_MEETING_FAIL:
      return action.error;
    case GlobalActionTypes.RESET_WHOLE_STORE:
      return null;
    default:
      return state;
  }
};

const sortMethod = (state = SortMethods.UPCOMING, action: BookedMeetingsAction | GlobalAction) => {
  switch (action.type) {
    case BookedMeetingsActionTypes.SET_SORT_METHOD:
      return action.payload;
    case GlobalActionTypes.RESET_WHOLE_STORE:
      return SortMethods.UPCOMING;
    default:
      return state;
  }
};

const dateRange = (state: string[] = [], action: BookedMeetingsAction | GlobalAction): string[] => {
  switch (action.type) {
    case BookedMeetingsActionTypes.SET_DATE_RANGE:
      return action.payload;
    case GlobalActionTypes.RESET_WHOLE_STORE:
      return [];
    default:
      return state;
  }
};

const status = (state: string = Statuses.ALL, action: BookedMeetingsAction | GlobalAction): string => {
  switch (action.type) {
    case BookedMeetingsActionTypes.SET_STATUS:
      return action.payload;
    case GlobalActionTypes.RESET_WHOLE_STORE:
      return Statuses.ALL;
    default:
      return state;
  }
};

const selectedBookingPages = (state: string[] = [], action: BookedMeetingsAction | GlobalAction): string[] => {
  switch (action.type) {
    case BookedMeetingsActionTypes.SET_SELECTED_BOOKING_PAGES:
      return action.payload;
    case GlobalActionTypes.RESET_WHOLE_STORE:
      return [];
    default:
      return state;
  }
};

const selectedWorkspaces = (state: string[] = [], action: BookedMeetingsAction | GlobalAction): string[] => {
  switch (action.type) {
    case BookedMeetingsActionTypes.SET_SELECTED_WORKSPACES:
      return action.payload;
    case GlobalActionTypes.RESET_WHOLE_STORE:
      return [];
    default:
      return state;
  }
};

const selectedBookedMeeting = (
  state: CreateUserEventInput = {} as CreateUserEventInput,
  action: BookedMeetingsAction | GlobalAction
): CreateUserEventInput => {
  switch (action.type) {
    case BookedMeetingsActionTypes.SELECT_SCHEDULED_MEETING:
      return action.payload;
    case BookedMeetingsActionTypes.UPDATE_CANCEL_REASON:
      return {
        ...state,
        canceled: {
          ...state.canceled,
          isCanceled: state.canceled?.isCanceled ?? true,
          type: action.reason,
        },
      };
    case BookedMeetingsActionTypes.UPDATE_CANCEL_NOTE:
      return {
        ...state,
        canceled: {
          ...state.canceled,
          isCanceled: state.canceled?.isCanceled ?? true,
          note: action.note,
        },
      };
    case GlobalActionTypes.RESET_WHOLE_STORE:
      return {} as CreateUserEventInput;
    default:
      return state;
  }
};

const lastLoadTime = (state = 0, action: BookedMeetingsAction | GlobalAction) => {
  switch (action.type) {
    case BookedMeetingsActionTypes.GET_SCHEDULED_MEETINGS_SUCCESS:
      return new Date().getTime();
    case GlobalActionTypes.RESET_WHOLE_STORE:
      return 0;
    default:
      return state;
  }
};

export default combineReducers({
  bookedMeetings,
  isFetching,
  error,
  sortMethod,
  dateRange,
  status,
  selectedBookingPages,
  selectedWorkspaces,
  selectedBookedMeeting,
  lastLoadTime,
});
