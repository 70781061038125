import {
  GetWorkspacesResponse,
  GetWorkspaceResponse,
  SaveWorkspaceResponse,
  UpsertWorkspaceResponse,
  DeteleWorkspaceResponse,
} from './types';
import { API_PUBLIC } from '../../types/constants';
import { handleAPIRequest } from '../utils/reduxUtils';

// ***
// When you change the requests do not forget to specify new data
// in docs/Endpoints.md
// ***

export const getWorkspace = async (workspaceId: string): Promise<GetWorkspaceResponse> => {
  return handleAPIRequest<GetWorkspaceResponse>(`${API_PUBLIC}/getWorkspaceById`, { workspaceId });
};

export const getWorkspaces = async (tenantId: string): Promise<GetWorkspacesResponse> => {
  return handleAPIRequest<GetWorkspacesResponse>(`${API_PUBLIC}/getWorkspacesByTenantId`, { tenantId });
};

export const saveWorkspace = async (data: UpsertWorkspaceResponse): Promise<SaveWorkspaceResponse> => {
  return handleAPIRequest<SaveWorkspaceResponse>(`${API_PUBLIC}/upsertWorkspace`, data);
};

export const deleteWorkspace = async (data: DeteleWorkspaceResponse): Promise<void> => {
  return handleAPIRequest<void>(`${API_PUBLIC}/deleteWorkspace`, data);
};
