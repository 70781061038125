import { Button } from 'primereact/button';
import labels from './labels';
import { Modal } from '../../../common';
import {
  CHARGEBEE_TERM_OPTIONS,
  getTotalAmount,
  CHARGEBEE_YEARLY_TERM,
  billingSelectors,
  getTiersByTerm,
  Tier,
  getPriceBreakdownByTiers,
} from '../../../../store/billing';
import { InputNumber } from 'primereact/inputnumber';
import { Dropdown } from 'primereact/dropdown';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import {
  PAY_LATER_MODAL_NAME,
  STATE_COUNTRIES,
  orgsActions,
  orgsSelectors,
  payLaterModalActions,
} from '../../../../store/opsConsole/orgs';
import { InputText } from 'primereact/inputtext';
import { MAX_LENGTH_EMAIL, MAX_LENGTH_NAME } from '../../../../types/constants';
import { validateEmail } from '../../../../services/EmailService';
import { countriesTimeZonesService } from '../../../../services/CountriesTimeZoneService';
import { useTranslation } from 'react-i18next';

export const OrgLicenseModal = () => {
  const dispatch = useDispatch();
  const { i18n } = useTranslation();
  const licensesAssigned = useSelector(orgsSelectors.selectLicenseAssigned);
  const email = useSelector(orgsSelectors.selectPayLaterEmail);
  const term = useSelector(orgsSelectors.selectPayLaterTerm);
  const quantity = useSelector(orgsSelectors.selectPayLaterQuantity);
  const itemPrices = useSelector(billingSelectors.selectItemPrices);
  const currencyLabel = useSelector(billingSelectors.selectCurrencyLabel);
  const firstName = useSelector(orgsSelectors.selectPayLaterAddressFirstName);
  const lastName = useSelector(orgsSelectors.selectPayLaterAddressLastName);
  const line1 = useSelector(orgsSelectors.selectPayLaterAddressLine1);
  const line2 = useSelector(orgsSelectors.selectPayLaterAddressLine2);
  const city = useSelector(orgsSelectors.selectPayLaterAddressCity);
  const state = useSelector(orgsSelectors.selectPayLaterAddressState);
  const country = useSelector(orgsSelectors.selectPayLaterAddressCountry);
  const countriesOptions = countriesTimeZonesService.getCountriesOptions(i18n.language, STATE_COUNTRIES);
  const tiers: Tier[] = getTiersByTerm(itemPrices, term);

  const amount = getTotalAmount(quantity, tiers);

  const handleEmailChange = (email: string) => {
    dispatch(orgsActions.updatePayLater({ email }));
  };

  const handleTermChange = (term: string) => {
    dispatch(orgsActions.updatePayLater({ term }));
  };

  const handleQuantityChange = (quantity: number) => {
    dispatch(orgsActions.updatePayLater({ quantity }));
  };

  const handleClose = () => {
    dispatch(payLaterModalActions.closeModal());
  };

  const handlePurchase = () => {
    dispatch(orgsActions.convertToPayLaterRequest());
  };

  const handleAddressChange = (field: string, value: string | number) => {
    dispatch(orgsActions.updatePayLaterAddress({ [field]: value }));
  };

  const nextIsDisabled: boolean =
    !email ||
    !validateEmail(email) ||
    !firstName ||
    !lastName ||
    !line1 ||
    !city ||
    (STATE_COUNTRIES.includes(country) && !state) ||
    !country ||
    quantity < licensesAssigned;

  return (
    <Modal.Container name={PAY_LATER_MODAL_NAME}>
      <Modal.Header>
        <div className="w-25rem text-center">{labels.buyLicenses}</div>
      </Modal.Header>
      <div className="p-fluid pb-2">
        <label>{labels.billingEmail}</label>
        <InputText
          value={email}
          onChange={(e) => handleEmailChange(e.target.value)}
          className={!email || !validateEmail(email) ? 'p-invalid' : ''}
          maxLength={MAX_LENGTH_EMAIL}
        />
      </div>

      <div className="pb-2 flex gap-2">
        <div className="flex flex-column w-full">
          <label>{labels.firstName}</label>
          <InputText
            value={firstName}
            onChange={(e) => handleAddressChange('first_name', e.target.value)}
            className={!firstName ? 'p-invalid' : ''}
            maxLength={MAX_LENGTH_NAME}
          />
        </div>
        <div className="flex flex-column w-full">
          <label>{labels.lastName}</label>
          <InputText
            value={lastName}
            onChange={(e) => handleAddressChange('last_name', e.target.value)}
            className={!lastName ? 'p-invalid' : ''}
            maxLength={MAX_LENGTH_NAME}
          />
        </div>
      </div>

      <div className="p-fluid pb-2">
        <label>{labels.addressLine1}</label>
        <InputText
          value={line1}
          onChange={(e) => handleAddressChange('line1', e.target.value)}
          className={!line1 ? 'p-invalid' : ''}
          maxLength={MAX_LENGTH_NAME}
        />
      </div>

      <div className="p-fluid pb-2">
        <label>{labels.addressLine2}</label>
        <InputText
          value={line2}
          onChange={(e) => handleAddressChange('line2', e.target.value)}
          maxLength={MAX_LENGTH_NAME}
        />
      </div>

      <div className="p-fluid pb-2">
        <label>{labels.city}</label>
        <InputText
          value={city}
          onChange={(e) => handleAddressChange('city', e.target.value)}
          className={!city ? 'p-invalid' : ''}
          maxLength={MAX_LENGTH_NAME}
        />
      </div>

      <div className="pb-2 flex gap-2">
        <div className="flex flex-column w-full">
          <label>{labels.state}</label>
          <InputText
            value={state}
            onChange={(e) => handleAddressChange('state', e.target.value)}
            className={STATE_COUNTRIES.includes(country) && !state ? 'p-invalid' : ''}
            maxLength={MAX_LENGTH_NAME}
          />
        </div>
        <div className="flex flex-column w-full">
          <label>{labels.country}</label>
          <Dropdown
            id="inputcountry"
            value={country}
            onChange={(e) => handleAddressChange('country', e.target.value as string)}
            optionLabel="label"
            optionValue="value"
            options={countriesOptions}
            className={!country ? 'p-invalid' : ''}
            filter
            filterBy="label"
          />
        </div>
      </div>

      <div className="p-fluid pb-2">
        <label htmlFor="quantity">{labels.numberLicenses}</label>
        <InputNumber
          inputId="quantity"
          value={quantity}
          onChange={(e) => handleQuantityChange(e.value || 0)}
          placeholder={labels.enterNumber}
          min={0}
          className={!quantity || quantity < licensesAssigned ? 'p-invalid' : ''}
        />
        {quantity < licensesAssigned && (
          <div className="text-sm p-error w-25rem">
            {`${labels.licenseMessagePart1} ${licensesAssigned} ${labels.licenseMessagePart2}`}
          </div>
        )}
      </div>
      <div className="p-fluid pb-2">
        <label htmlFor="term">{labels.term}</label>
        <Dropdown
          inputId="term"
          value={term}
          options={CHARGEBEE_TERM_OPTIONS}
          onChange={(e) => handleTermChange(e.target.value)}
        />
      </div>

      <div className="pt-2">
        {`${labels.buyLicensesMessage} $${amount} ${term === CHARGEBEE_YEARLY_TERM ? labels.yearly : labels.monthly}.`}
      </div>
      <div className="text-sm">
        {getPriceBreakdownByTiers(tiers, term, quantity || 0, currencyLabel).map(
          (value, index) => value && <div key={index}>{value}</div>
        )}
      </div>
      <Modal.Buttons>
        <Button onClick={handleClose} outlined>
          {labels.cancel}
        </Button>
        <Button className="ml-auto" onClick={handlePurchase} autoFocus disabled={nextIsDisabled}>
          {labels.next}
        </Button>
      </Modal.Buttons>
    </Modal.Container>
  );
};
