import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ConfirmationsPageType } from '../../../../API';
import { bookingPageSelectors, bookingPagesActions } from '../../../../store/bookingPages';
import {
  bookingTemplatesActions,
  bookingTemplatesSelectors,
  // sortListItemDown,
  // sortListItemUp,
} from '../../../../store/bookingTemplates';
import { MAX_LENGTH_NAME } from '../../../../types/constants';
import labels from './labels';
import { Checkbox } from 'primereact/checkbox';
import { InputTextarea } from 'primereact/inputtextarea';
import { LinkIcon, PencilIcon, PlusIcon, XMarkIcon } from '@heroicons/react/24/outline';
// import { Tooltip } from 'primereact/tooltip';
// import { InputTextarea } from 'primereact/inputtextarea';

type ConfirmationStepProps = {
  selectors: typeof bookingTemplatesSelectors | typeof bookingPageSelectors;
  actions: typeof bookingTemplatesActions | typeof bookingPagesActions;
  isReadOnly?: boolean;
};

export const ConfirmationStep = ({ selectors, actions, isReadOnly }: ConfirmationStepProps) => {
  const dispatch = useDispatch();
  const type = useSelector(selectors.selectConfirmationType);
  const externalLink = useSelector(selectors.selectConfirmationExternalLink);
  const anotherEnabled = useSelector(selectors.selectConfirmationAnotherEnabled);
  const rescheduleEnabled = useSelector(selectors.selectConfirmationRescheduleEnabled);
  const cancelEnabled = useSelector(selectors.selectConfirmationCancelEnabled);
  const cancelationPolicy = useSelector(selectors.selectCancelationPolicy);
  const customLinks = useSelector(selectors.selectConfirmationCustomLinks);
  const isLinkValid = useSelector(selectors.selectIsExternalLinkValid);

  const [externalLinkValue, setExternalLinkValue] = useState(externalLink);
  const [cancelationPolicyValue, setCancelationPolicyValue] = useState(cancelationPolicy);
  const [customLinksValue, setCustomLinksValue] = useState(customLinks || []);
  const [modalOpened, setModalOpened] = useState(false);
  const [selectedLinkIndex, setSelectedLinkIndex] = useState<number | null>(null);
  const [selectedLinkLabel, setSelectedLinkLabel] = useState<string | null>(null);
  const [selectedLinkUrl, setSelectedLinkUrl] = useState<string | null>(null);

  const handleCancelationPolicyBlur = () => {
    dispatch(actions.updateConfirmationStep({ cancelationPolicy: cancelationPolicyValue }));
  };

  const handleTypeChange = (type: ConfirmationsPageType) => {
    dispatch(actions.updateConfirmationStep({ type }));
  };

  const handleExteranlLinkChange = (value: string) => {
    setExternalLinkValue(value);
  };

  const handleExteranlLinkBlur = () => {
    dispatch(actions.updateConfirmationStep({ externalLink: externalLinkValue || null }));
  };

  const handleAnotherEnabledChange = (anotherEventEnabled: boolean) => {
    dispatch(actions.updateConfirmationStep({ anotherEventEnabled }));
  };

  const handleRescheduleEnabledChange = (rescheduleEnabled: boolean) => {
    dispatch(actions.updateConfirmationStep({ rescheduleEnabled }));
  };

  const handleCancelEnabledChange = (cancelEnabled: boolean) => {
    dispatch(actions.updateConfirmationStep({ cancelEnabled }));
  };

  const handleLinkLabelChange = (value: string) => {
    setSelectedLinkLabel(value);
  };

  const handleLinkUrlChange = (value: string) => {
    setSelectedLinkUrl(value);
  };

  const handleAddLink = () => {
    setSelectedLinkIndex(null);
    setSelectedLinkLabel(null);
    setSelectedLinkUrl(null);
    setModalOpened(true);
  };

  // const handleAnswerDown = (index: number) => {
  //   setCustomLinksValue(sortListItemDown(customLinksValue, index));
  // };
  // const handleAnswerUp = (index: number) => {
  //   setCustomLinksValue(sortListItemUp(customLinksValue, index));
  // };

  const handleEditLink = (index: number) => {
    const selectedLink = customLinksValue[index];
    setSelectedLinkIndex(index);
    setSelectedLinkLabel(selectedLink?.name || null);
    setSelectedLinkUrl(selectedLink?.link || null);
    setModalOpened(true);
  };

  const handleRemoveLink = (index: number) => {
    const updatedCustomLinks = [...customLinksValue.slice(0, index), ...customLinksValue.slice(index + 1)];
    setCustomLinksValue(updatedCustomLinks);
    dispatch(actions.updateConfirmationStep({ customLinks: updatedCustomLinks }));
  };

  const handleSaveLink = () => {
    const index = selectedLinkIndex === null ? customLinksValue.length : selectedLinkIndex;
    const updatedCustomLinks = [
      ...customLinksValue.slice(0, index),
      { name: selectedLinkLabel, link: selectedLinkUrl },
      ...customLinksValue.slice(index + 1),
    ];
    setCustomLinksValue(updatedCustomLinks);
    dispatch(actions.updateConfirmationStep({ customLinks: updatedCustomLinks }));
    setModalOpened(false);
  };

  const handleCloseLink = () => {
    setSelectedLinkIndex(null);
    setSelectedLinkLabel(null);
    setSelectedLinkUrl(null);
    setModalOpened(false);
  };

  return (
    <div className="flex flex-column gap-32px">
      <div className="flex flex-column gap-10px w-6">
        <div className="text-title-xs-med text-heavy-100">{labels.confirmationPage}</div>
        <Dropdown
          value={type}
          onChange={(e) => handleTypeChange(e.target.value)}
          options={[
            { value: ConfirmationsPageType.OUR_PAGE, label: labels.optionDisplayConfirmationPage },
            { value: ConfirmationsPageType.REDIRECT, label: labels.optionRedirectToExternalPage },
          ]}
          optionLabel="label"
          disabled={isReadOnly}
        />
        {type === ConfirmationsPageType.REDIRECT && (
          <InputText
            type="text"
            placeholder={labels.externalLinkPlaceholder}
            value={externalLinkValue || ''}
            onChange={(e) => handleExteranlLinkChange(e.target.value.trimStart())}
            onBlur={handleExteranlLinkBlur}
            className={`${!isLinkValid && 'p-invalid'}`}
            maxLength={MAX_LENGTH_NAME}
            disabled={isReadOnly}
          />
        )}

        <div className="flex flex-column gap-20px pt-14px">
          <div className="flex flex-column gap-4px">
            <div className="text-title-xs-med text-heavy-100">{labels.availableActions}</div>
            <div className="text-body-s-reg text-heavy-60">{labels.availableActionsDescription}</div>
          </div>
          <div className="flex flex-column gap-10px">
            <div className="flex-left-center gap-10px">
              <Checkbox
                inputId="rescheduleEnabled"
                checked={Boolean(rescheduleEnabled)}
                onChange={(e) => handleRescheduleEnabledChange(Boolean(e.checked))}
                disabled={isReadOnly}
              />
              <label
                htmlFor="rescheduleEnabled"
                className="text-body-lg-reg text-heavy-100 cursor-pointer hover-text-heavy-80"
              >
                {labels.optionReschedule}
              </label>
            </div>
            <div className="flex-left-center gap-10px">
              <Checkbox
                inputId="cancelEnabled"
                checked={Boolean(cancelEnabled)}
                onChange={(e) => handleCancelEnabledChange(Boolean(e.checked))}
                disabled={isReadOnly}
              />
              <label
                htmlFor="cancelEnabled"
                className="text-body-lg-reg text-heavy-100 cursor-pointer hover-text-heavy-80"
              >
                {labels.optionCancel}
              </label>
            </div>
            <div className="flex-left-center gap-10px">
              <Checkbox
                inputId="anotherEnabled"
                checked={Boolean(anotherEnabled)}
                onChange={(e) => handleAnotherEnabledChange(Boolean(e.checked))}
                disabled={isReadOnly}
              />
              <label
                htmlFor="anotherEnabled"
                className="text-body-lg-reg text-heavy-100 cursor-pointer hover-text-heavy-80"
              >
                {labels.optionBookAnother}
              </label>
            </div>
          </div>
        </div>
      </div>

      <div className="border-top-1 border-heavy-20" />

      <div className="flex flex-column gap-20px">
        <div className="flex flex-column gap-4px">
          <div className="text-title-xs-med text-heavy-100">{labels.customLinks}</div>
          <div className="text-body-s-reg text-heavy-60">{labels.customLinksDescription}</div>
        </div>
        <div className="flex flex-column gap-8px w-6">
          {customLinksValue.map((link, index) => (
            <div key={index} className="flex-left-center gap-6px">
              <div
                className="flex-1 flex-left-center gap-8px border-1 border-heavy-10 border-radius-6px py-8px px-10px text-heavy-80 hover-text-heavy-100 cursor-pointer"
                onClick={() => handleEditLink(index)}
              >
                <LinkIcon className="icon-18px" />
                <div className="text-button-md-med">{link?.name}</div>
              </div>

              <div className="flex gap-4px">
                <div className="action-button-sm" onClick={() => handleEditLink(index)}>
                  <PencilIcon className="icon-16px" />
                </div>
                <div className="action-button-sm" onClick={() => handleRemoveLink(index)}>
                  <XMarkIcon className="icon-16px" />
                </div>
              </div>
            </div>
          ))}
          <div style={{ paddingRight: 58 }}>
            <Button
              onClick={handleAddLink}
              className="w-full p-10px flex-center bg-heavy-10 border-none text-heavy-60"
              disabled={isReadOnly}
            >
              <PlusIcon className="icon-16px" />
            </Button>
          </div>
        </div>
      </div>

      <div className="border-top-1 border-heavy-20" />

      <div className="flex flex-column gap-24px">
        <div className="flex flex-column gap-4px">
          <div className="text-title-xs-med text-heavy-100">{labels.cancelationPolicy}</div>
          <div className="text-body-s-reg text-heavy-60">{labels.cancelationPolicyDescription}</div>
        </div>
        <InputTextarea
          placeholder={labels.cancelationPolicyPlaceholder}
          autoResize
          rows={2}
          value={cancelationPolicyValue || ''}
          onChange={(e) => setCancelationPolicyValue(e.target.value.trimStart())}
          onBlur={handleCancelationPolicyBlur}
          maxLength={2000}
          disabled={isReadOnly}
        />
      </div>

      <Dialog
        className="w-460px"
        header={selectedLinkIndex === null ? labels.addCustomLink : labels.changeCustomLink}
        visible={modalOpened}
        modal
        closable
        draggable={false}
        position="center"
        onHide={handleCloseLink}
      >
        <div className="flex flex-column gap-4px">
          <div className="text-title-xs-med text-heavy-100">{labels.linkUrl}</div>
          <InputText
            type="text"
            placeholder="https://example.link.com"
            value={selectedLinkLabel || ''}
            onChange={(e) => handleLinkLabelChange(e.target.value.trimStart())}
            className={`${!selectedLinkLabel && 'p-invalid'}`}
            maxLength={MAX_LENGTH_NAME}
          />
        </div>
        <div className="flex flex-column gap-4px mt-24px">
          <div className="text-title-xs-med text-heavy-100">{labels.customLinkLabel}</div>
          <InputText
            type="text"
            placeholder={labels.customLinkLabelPlaceholder}
            value={selectedLinkUrl || ''}
            onChange={(e) => handleLinkUrlChange(e.target.value.trimStart())}
            className={`${!selectedLinkUrl && 'p-invalid'}`}
            maxLength={MAX_LENGTH_NAME}
          />
        </div>
        <div className="border-top-1 border-heavy-20 mt-24px -mx-20px" />
        <div className="flex-left-center gap-6px py-12px -mb-20px">
          <Button
            className="button-xl min-w-120px"
            label={labels.confirm}
            disabled={!selectedLinkLabel || !selectedLinkUrl}
            onClick={handleSaveLink}
          />
          <Button className="button-xl" text label={labels.cancel} onClick={handleCloseLink} />
        </div>
      </Dialog>
    </div>
  );
};
