import { t } from '../../../../i18n/i18n';

export default {
  confirmationPage: t('EditBookingPageAfterStep:CONFIRMATION_PAGE'),
  optionDisplayConfirmationPage: t('EditBookingPageAfterStep:OPTION_DISAPLY_CONFIRMATION_PAGE'),
  optionRedirectToExternalPage: t('EditBookingPageAfterStep:OPTION_REDIRECT_TO_EXTERNAL_PAGE'),
  externalLinkPlaceholder: t('EditBookingPageAfterStep:EXTERNAL_LINK_PLACEHOLDER'),
  availableActions: t('EditBookingPageAfterStep:AVAILABLE_ACTIONS'),
  availableActionsDescription: t('EditBookingPageAfterStep:AVAILABLE_ACTIONS_DESCRIPTION'),
  optionReschedule: t('EditBookingPageAfterStep:OPTION_RESCHEDULE'),
  optionCancel: t('EditBookingPageAfterStep:OPTION_CANCEL'),
  optionBookAnother: t('EditBookingPageAfterStep:OPTION_BOOK_ANOTHER'),
  customLinks: t('EditBookingPageAfterStep:CUSTOM_LINKS'),
  customLinksDescription: t('EditBookingPageAfterStep:CUSTOM_LINKS_DESCRIPTION'),
  addCustomLink: t('EditBookingPageAfterStep:ADD_CUSTOM_LINK'),
  changeCustomLink: t('EditBookingPageAfterStep:CHANGE_CUSTOM_LINK'),
  linkUrl: t('EditBookingPageAfterStep:LINK_URL'),
  customLinkLabel: t('EditBookingPageAfterStep:CUSTOM_LINK_LABEL'),
  customLinkLabelPlaceholder: t('EditBookingPageAfterStep:CUSTOM_LINK_LABEL_PLACEHOLDER'),
  cancelationPolicy: t('EditBookingPageAfterStep:CANCELATION_POLICY'),
  cancelationPolicyDescription: t('EditBookingPageAfterStep:CANCELATION_POLICY_DESCRIPTION'),
  cancelationPolicyPlaceholder: t('EditBookingPageAfterStep:CANCELATION_POLICY_PLACEHOLDER'),
  cancel: t('Common:CANCEL'),
  confirm: t('Common:CONFIRM'),
};
