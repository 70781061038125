import { createSelector } from 'reselect';
import { State } from '../rootStore';
import { bookingTemplatesSelectors, DEFAULT_BOOKING_TEMPLATE_DATA, LOCATIONS_LIST } from '../bookingTemplates';
import { FILTER_OPTION_TEAMS } from './constants';
import {
  ConfirmationsPageType,
  CustomFieldType,
  DateRangeType,
  InPersonType,
  IntegrationType,
  LocationType,
  MemberType,
  PhoneCallType,
  TimeZoneType,
  UpdateBookingPageInput,
  UpdateGroupBookingPageInput,
} from '../../API';
import { workspacesSelectors } from '../workspaces';
import { authenticationSelectors } from '../authentication';
import { assignTime, generatePublicFileKey, getWhoData } from './utils';
import { TIME_ZONE_LIST } from '../../types/constants';
import { userSettingsSelectors } from '../userSettings';
import { calculateMinutes, isOldData, validatePhoneNumber } from '../../services/utils';
import { usersSelectors } from '../users';
import { HostOption, ImageType, MemberSubType } from '../../types/types';
import { UserAdminDataFullRecord } from '../users/types';
import { BookingPageOption, BookingPagesByIdType } from './types';
import { t } from '../../i18n/i18n';
import { globalSelectors } from '../global';
import { TeamRecord, teamsSelectors } from '../teams';

const bookingPagesState = (state: State) => state.bookingPages;

const selectIsFetching = createSelector(bookingPagesState, (bookingPages) => bookingPages.isFetching);
const selectIsFetchingAvatar = createSelector(bookingPagesState, (bookingPages) => bookingPages.isFetchingAvatar);
const selectIsFetchingBackground = createSelector(
  bookingPagesState,
  (bookingPages) => bookingPages.isFetchingBackground
);

const selectLastLoadTime = createSelector(bookingPagesState, (state) => state.lastLoadTime);
const selectIsSpinnerFetching = createSelector(
  selectIsFetching,
  selectLastLoadTime,
  (isFetching, loadTime) => isFetching && !isOldData(loadTime)
);
const selectIsSkeletonFetching = createSelector(
  selectIsFetching,
  selectLastLoadTime,
  (isFetching, loadTime) => isFetching && isOldData(loadTime)
);

const selectSelectedBookingPages = createSelector(
  bookingPagesState,
  (bookingPages) => bookingPages.selectedBookingPages
);

const selectIsEdited = createSelector(bookingPagesState, (bookingPages) => bookingPages.isEdited);
const selectBookingPages = createSelector(bookingPagesState, (bookingPages) => bookingPages.bookingPages);
const selectFilterByUser = createSelector(
  bookingPagesState,
  authenticationSelectors.selectUserId,
  (bookingPages, currentUserId) => bookingPages.filterByUser || currentUserId
);
const selectRecordWho = createSelector(bookingPagesState, (bookingPages) => bookingPages.recordWho);
const selectRecordWhoTeamIds = createSelector(selectRecordWho, (who) => [
  ...(who?.hostMembers
    ?.filter((member) => member?.type === MemberType.SUMO1 && member?.subType === MemberSubType.TEAM)
    .map((member) => member?.id) || []),
  ...(who?.guests
    ?.filter((member) => member?.type === MemberType.SUMO1 && member?.subType === MemberSubType.TEAM)
    .map((member) => member?.id) || []),
  ...(who?.mainGuest?.type === MemberType.SUMO1 && who?.mainGuest?.subType === MemberSubType.TEAM
    ? [who?.mainGuest?.id]
    : []),
]);

const selectAddToWebsiteLink = createSelector(bookingPagesState, (bookingPages) => bookingPages.addToWebSiteLink);

const selectIsSelectionMode = createSelector(
  selectSelectedBookingPages,
  (selectedBookingPages) => !!selectedBookingPages.length
);

const selectBookingPageIds = createSelector(selectBookingPages, (bookingPages) =>
  bookingPages.map((bookingPage) => bookingPage.id)
);

const selectMyDefaultBookingPage = createSelector(
  selectBookingPages,
  authenticationSelectors.selectUserId,
  (bookingPages, currentUserId) =>
    bookingPages.find((page) => page.who?.hostMembers && page.who?.hostMembers[0]?.id === currentUserId) || null
);

const selectBookingPagesLength = createSelector(selectBookingPages, (bookingPages) => bookingPages.length);

const selectFilteredBookingPages = createSelector(
  selectBookingPages,
  selectFilterByUser,
  (bookingPages, filterByUser) =>
    filterByUser === FILTER_OPTION_TEAMS
      ? bookingPages?.filter(
          (bookingPage) =>
            (bookingPage.who?.hostMembers &&
              bookingPage.who?.hostMembers[0]?.type === MemberType.SUMO1 &&
              bookingPage.who?.hostMembers[0]?.subType === MemberSubType.TEAM &&
              bookingPage.who?.hostMembers[0]?.id) ||
            (bookingPage.who?.guests &&
              bookingPage.who.guests.some(
                (cohost) =>
                  cohost && cohost.type === MemberType.SUMO1 && cohost.subType === MemberSubType.TEAM && cohost.id
              ))
        )
      : bookingPages?.filter((bookingPage) => {
          const hosts =
            bookingPage.who?.hostMembers
              ?.filter((member) => member?.type === MemberType.SUMO1 && member?.subType === MemberSubType.USER)
              .map((record) => record?.id) || [];
          const coHosts =
            bookingPage.who?.guests
              ?.filter((member) => member?.type === MemberType.SUMO1 && member?.subType === MemberSubType.USER)
              .map((record) => record?.id) || [];
          return hosts.includes(filterByUser) || coHosts.includes(filterByUser);
        })
);

const selectSearchedBookingPages = createSelector(
  selectFilteredBookingPages,
  bookingTemplatesSelectors.selectBookingTemplateNamesById,
  globalSelectors.selectSearchString,
  (bookingPages, bookingTemplateNamesById, searchString) =>
    bookingPages
      .filter((bookingPage) => {
        const name =
          (bookingPage.what?.bookingTemplateId
            ? bookingTemplateNamesById[bookingPage.what?.bookingTemplateId]
            : bookingPage?.what?.customName) || '';
        return name.toLowerCase().includes(searchString.toLowerCase());
      })
      .sort((a, b) => (a.what?.customName || '').localeCompare(b.what?.customName || ''))
);

const selectPersonalSearchedBookingPages = createSelector(selectSearchedBookingPages, (searchedBookingPages) =>
  searchedBookingPages.filter((record) => !record.who?.guests?.length || !record.who?.guests[0])
);
const selectSharedSearchedBookingPages = createSelector(selectSearchedBookingPages, (searchedBookingPages) =>
  searchedBookingPages.filter((record) => record.who?.guests?.length && record.who?.guests[0])
);

const selectBookingPagesById = createSelector(selectBookingPages, (pages) =>
  pages.reduce((res, page) => {
    res[page.id] = page;
    return res;
  }, {} as BookingPagesByIdType)
);

const selectSelectedBookingPagesImageNames = createSelector(
  selectBookingPages,
  selectSelectedBookingPages,
  (bookingPages, selectedIds) =>
    bookingPages
      .filter((bookingPage) => selectedIds.includes(bookingPage.id))
      .reduce(
        (imageNames, bookingPage) =>
          [...imageNames, bookingPage.style?.logoImage, bookingPage.style?.backgroundImage] as string[],
        [] as string[]
      )
      .filter((imageName) => Boolean(imageName)) as string[]
);

const selectImages = createSelector(bookingPagesState, (bookingPages) => bookingPages.images);

const selectBookingPage = createSelector(bookingPagesState, (bookingPages) => bookingPages.bookingPage);
const selectAccordionIndexes = createSelector(bookingPagesState, (bookingPages) => bookingPages.accordionIndexes);
const selectIsBookingPageSelector = () => true;

const selectBookingPageById = (id: string) =>
  createSelector(selectBookingPages, (bookingPages) => bookingPages.find((bookingPage) => bookingPage.id === id));
const selectBookingPagesOptions = createSelector(selectBookingPages, (bookingPages) =>
  bookingPages
    .map(
      (page) => ({ label: page.what?.customName || '', value: page.id, displayId: page.displayId } as BookingPageOption)
    )
    .sort((a, b) => (a.label || '').localeCompare(b.label || ''))
);

const selectId = createSelector(selectBookingPage, (bookingPage) => bookingPage.id);
const selectDisplayId = createSelector(selectBookingPage, (bookingPage) => bookingPage.displayId);
const selectIsAdminOnly = createSelector(selectBookingPage, (bookingPage) => bookingPage.adminOnly);

const selectWhat = createSelector(selectBookingPage, (bookingPage) => bookingPage.what);
const selectWhatColor = createSelector(selectWhat, (what) => what?.color);
const selectCustomName = createSelector(selectWhat, (what) => what?.customName);
const selectIsActive = createSelector(selectWhat, (what) => what?.isActive);
const selectInstructions = createSelector(selectWhat, (what) => what?.instructions);
const selectLastModify = createSelector(selectBookingPage, (bookingPage) => bookingPage.lastModify);
const selectBookingTemplateId = createSelector(selectWhat, (what) => what?.bookingTemplateId);
const selectBookingTemplateName = createSelector(
  selectBookingTemplateId,
  bookingTemplatesSelectors.selectBookingTemplateNamesById,
  (templateId, bookingTemplateNamesById) => (templateId && bookingTemplateNamesById[templateId]) || ''
);
const selectHasTemplate = createSelector(selectBookingTemplateId, (templateId) => Boolean(templateId));
const selectBookingPageNameById = (id: string) =>
  createSelector(
    selectBookingPageById(id),
    bookingTemplatesSelectors.selectBookingTemplateNamesById,
    (bookingPage, bookingTemplateNamesById) =>
      bookingPage?.what?.bookingTemplateId
        ? bookingTemplateNamesById[bookingPage?.what?.bookingTemplateId]
        : bookingPage?.what?.customName || ''
  );

const selectInviteOthers = createSelector(selectBookingPage, (bookingPage) => bookingPage.inviteOthers);
const selectEnterNote = createSelector(selectBookingPage, (bookingPage) => bookingPage.enterNote);
// const selectHow = createSelector(selectBookingPage, (bookingPage) => bookingPage.labels);
const selectPreviewBookingTemplate = createSelector(
  selectBookingTemplateId,
  bookingTemplatesSelectors.selectBookingTemplates,
  (bookingTemplateId, bookingTemplates) =>
    bookingTemplates.find((type) => bookingTemplateId === type.id) || DEFAULT_BOOKING_TEMPLATE_DATA
);

const selectShortLink = createSelector(selectBookingPage, (bookingPage) => bookingPage.shortLink);

const selectStyle = createSelector(selectBookingPage, (bookingPage) => bookingPage.style);
const selectLogo = createSelector(selectStyle, (style) => style?.logoImage);
const selectBackground = createSelector(selectStyle, (style) => style?.backgroundImage);
const selectCss = createSelector(selectStyle, (style) => style?.css);
const selectFooterHtml = createSelector(selectStyle, (style) => style?.footerHtml);
const selectBackgroundColor = createSelector(selectStyle, (style) => style?.backgroundColor);

const selectCurrentWorkspace = createSelector(
  authenticationSelectors.selectWorkspaceId,
  workspacesSelectors.selectWorkspaces,
  (workspaceId, workspaces) => workspaces.find((workspace) => workspace.id === workspaceId)
);
const selectWorkspaceLogo = createSelector(selectCurrentWorkspace, (workspace) => workspace?.style?.logoImage);
const selectWorkspaceBackground = createSelector(
  selectCurrentWorkspace,
  (workspace) => workspace?.style?.backgroundImage
);
const selectFinalLogo = createSelector(selectImages, selectLogo, (images, bookingPageLogo) => {
  const logos = images.filter((image) => image.imageType === ImageType.avatar);
  return logos.length ? logos[logos.length - 1].imageLink : bookingPageLogo;
});
const selectFinalBackground = createSelector(selectImages, selectBackground, (images, bookingPageBackground) => {
  const logos = images.filter((image) => image.imageType === ImageType.backgroundImage);
  return logos.length ? logos[logos.length - 1].imageLink : bookingPageBackground;
});
const selectIsWorkspaceLogoToUse = createSelector(
  selectWorkspaceLogo,
  selectFinalLogo,
  (workspaceLogo, logo) => Boolean(workspaceLogo) && logo === null
);
const selectIsWorkspaceBackgroundToUse = createSelector(
  selectWorkspaceBackground,
  selectFinalBackground,
  (workspaceBackground, background) => Boolean(workspaceBackground) && background === null
);
const selectFinalLogoImage = createSelector(
  selectIsWorkspaceLogoToUse,
  selectFinalLogo,
  selectWorkspaceLogo,
  (useWorkspace, logo, workspaceLogo) => (useWorkspace ? workspaceLogo : logo)
);
const selectFinalBackgroundImage = createSelector(
  selectIsWorkspaceBackgroundToUse,
  selectFinalBackground,
  selectWorkspaceBackground,
  (useWorkspace, background, workspaceBackground) => (useWorkspace ? workspaceBackground : background)
);
const selectStyleForPreview = createSelector(
  selectStyle,
  selectFinalLogoImage,
  selectFinalBackgroundImage,
  (style, avatar, backgroundImage) => ({ ...style, avatar, backgroundImage })
);
const selectImageNamesToRemove = createSelector(
  selectImages,
  selectLogo,
  selectBackground,
  selectFinalLogoImage,
  selectFinalBackgroundImage,
  (images, logo, background, finalLogo, finalBackground) =>
    [...images.map((image) => image.imageLink), logo, background]
      .filter((link) => Boolean(link) && link !== finalLogo && link !== finalBackground)
      .map((link) => generatePublicFileKey(link))
);

const selectCalendar = createSelector(selectBookingPage, (bookingPage) => bookingPage.calendar);
const selectTimeFormat = createSelector(selectCalendar, (calendar) => calendar?.timeFormat);
const selectCalendarFormat = createSelector(selectCalendar, (calendar) => calendar?.calendarFormat);
const selectTopOfInterval = createSelector(selectCalendar, (calendar) => calendar?.topOfInterval);
const selectTimeInterval = createSelector(selectCalendar, (calendar) => calendar?.timeInterval);
const selectTimeZoneType = createSelector(selectCalendar, (calendar) => calendar?.timeZoneType);
const selectSelectedTimeZone = createSelector(selectCalendar, (calendar) => calendar?.selectedTimeZone);
const selectDefaultTimeZone = createSelector(selectCalendar, (calendar) => calendar?.defaultTimeZone);

const selectAvailableTimeZones = createSelector(selectSelectedTimeZone, (timeZones) =>
  TIME_ZONE_LIST.filter((zone) => !timeZones?.includes(zone.timeZone))
);
const selectAvailableTimeZonesOptions = createSelector(
  selectAvailableTimeZones,
  userSettingsSelectors.selectUTC,
  selectTimeFormat,
  (timeZones, UTC, timeFormat) => assignTime(timeZones, UTC, timeFormat)
);

const selectAssignedTimeZones = createSelector(selectSelectedTimeZone, (timeZones) =>
  TIME_ZONE_LIST.filter((zone) => timeZones?.includes(zone.timeZone))
);
const selectAssignedTimeZonesOptions = createSelector(
  selectAssignedTimeZones,
  userSettingsSelectors.selectUTC,
  selectTimeFormat,
  (timeZones, UTC, timeFormat) => assignTime(timeZones, UTC, timeFormat)
);

const selectCloneName = createSelector(bookingPagesState, (bookingPages) => bookingPages.cloneName);
const selectIsCloneNameDuplicate = createSelector(selectCloneName, selectFilteredBookingPages, (name, pages) =>
  pages.find((page) => page.what?.customName === name)
);
const selectIsCloneNameValid = createSelector(
  selectCloneName,
  selectIsCloneNameDuplicate,
  (name, isDuplicate) => Boolean(name) && !isDuplicate
);

const selectIsExistingBookingPage = createSelector(selectId, (id) => Boolean(id));

// const selectIsNameDuplicate = createSelector(
//   selectFilteredBookingPages,
//   selectId,
//   selectCustomName,
//   (pages, id, name) => Boolean(pages.find((page) => page.id !== id && page.what?.customName === name))
// );
const selectIsNameValid = createSelector(
  selectCustomName,
  // selectIsNameDuplicate,
  (name) => Boolean(name)
);

const selectIsTimeZonesValid = createSelector(
  selectTimeZoneType,
  selectAssignedTimeZones,
  (timeZoneType, timeZones) => timeZoneType === TimeZoneType.DETECT_INVITEE || timeZones.length
);

const selectIsWhatStepValid = createSelector(selectIsNameValid, (isNameValid) => isNameValid);

const selectIsCalendarStepValid = createSelector(selectIsTimeZonesValid, (isTimeZonesValid) => isTimeZonesValid);

const selectWho = createSelector(selectBookingPage, (bookingPage) => bookingPage.who);
const selectHosts = createSelector(selectWho, (who) => who?.hostMembers || []);
const selectIsHostUser = createSelector(
  selectWho,
  (who) =>
    who?.hostMembers &&
    who?.hostMembers[0] &&
    who?.hostMembers[0].type === MemberType.SUMO1 &&
    who?.hostMembers[0].subType === MemberSubType.USER
);
const selectHostUserIds = createSelector(
  selectHosts,
  (hosts) =>
    hosts
      ?.filter((member) => member?.type === MemberType.SUMO1 && member?.subType === MemberSubType.USER)
      .map((host) => host?.id) || []
);
const selectHostTeamIds = createSelector(
  selectHosts,
  (hosts) =>
    hosts
      ?.filter((member) => member?.type === MemberType.SUMO1 && member?.subType === MemberSubType.TEAM)
      .map((host) => host?.id) || []
);
const selectAllHostsIds = createSelector(selectHostUserIds, selectHostTeamIds, (hostIds, teamsIds) => [
  ...hostIds,
  ...teamsIds,
]);
const selectAllHostsData = createSelector(
  usersSelectors.selectUsers,
  teamsSelectors.selectTeamsByCurrentWorkspace,
  selectHosts,
  (users, teams, hosts) => getWhoData(users, teams, hosts)
);
const selectGuests = createSelector(selectWho, (who) => who?.guests || []);
const selectGuestsUserIds = createSelector(
  selectGuests,
  (guests) =>
    guests
      ?.filter((member) => member?.type === MemberType.SUMO1 && member?.subType === MemberSubType.USER)
      .map((cohost) => cohost?.id) || []
);
const selectGuestsTeamIds = createSelector(
  selectGuests,
  (guests) =>
    guests
      ?.filter((member) => member?.type === MemberType.SUMO1 && member?.subType === MemberSubType.TEAM)
      .map((cohost) => cohost?.id) || []
);
const selectGuestsIds = createSelector(selectGuestsUserIds, selectGuestsTeamIds, (hostIds, teamsIds) => [
  ...hostIds,
  ...teamsIds,
]);
const selectGuestsData = createSelector(
  usersSelectors.selectUsers,
  teamsSelectors.selectTeamsByCurrentWorkspace,
  selectGuests,
  (users, teams, guests) => getWhoData(users, teams, guests)
);

const selectAllHostAndGuestIds = createSelector(selectAllHostsIds, selectGuestsUserIds, (hostsIds, userIds) => [
  ...userIds,
  ...hostsIds,
]);
const selectHostTeamAndGuestIds = createSelector(selectHostTeamIds, selectGuestsTeamIds, (teamIds, guestsIds) => [
  ...teamIds,
  ...guestsIds,
]);
const selectHostAndGuestsData = createSelector(
  usersSelectors.selectUsers,
  teamsSelectors.selectTeamsByCurrentWorkspace,
  selectHosts,
  selectGuests,
  (users, teams, hosts, guests) => getWhoData(users, teams, [...hosts, ...guests])
);

const selectHostIntegrations = createSelector(
  selectAllHostsData,
  (hosts) => (hosts.length && hosts[0] && hosts[0].integrationTypes) || []
);
const selectHostVideoConferences = createSelector(selectIsHostUser, selectHostIntegrations, (isUser, integrations) =>
  isUser
    ? integrations.filter((integration) =>
        [IntegrationType.GOOGLE_MEET, IntegrationType.MICROSOFT_TEAMS, IntegrationType.ZOOM].includes(integration)
      )
    : []
);

const selectIsUserRequiredHost = createSelector(
  selectIsExistingBookingPage,
  userSettingsSelectors.selectIsCreateOnlyMyBookingPages,
  userSettingsSelectors.selectIsEditOnlyMyBookingPages,
  (isExisting, isCreateOnlyMy, isEditOnlyMy) => (isExisting ? isEditOnlyMy : isCreateOnlyMy)
);

const selectIsWhoStepValid = createSelector(selectAllHostsIds, (allHostIds) => !!allHostIds.length);

const selectWhere = createSelector(selectBookingPage, (bookingPage) => bookingPage.where);
const selectLocationTypes = createSelector(selectWhere, (where) => where?.locationTypes || []);
const selectDefaultLocationType = createSelector(selectWhere, (where) => where?.defaultLocationType || null);
const selectSkipWhereStep = createSelector(selectWhere, (where) => where?.skipTheWhereStep);
const selectPhoneCallType = createSelector(selectWhere, (where) => where?.phoneCallType);
const selectIsPromptInviteCallType = createSelector(
  selectLocationTypes,
  selectPhoneCallType,
  (locations, callType) => locations.includes(LocationType.PHONE_CALL) && callType === PhoneCallType.PROMPT_INVITE
);
const selectPhoneCallCustomPhoneCode = createSelector(selectWhere, (where) => where?.customCountryCode);
const selectPhoneCallCustomPhoneNumber = createSelector(selectWhere, (where) => where?.customPhone);
const selectInPersonType = createSelector(selectWhere, (where) => where?.inPersonType);
const selectInPersonCustomAddress = createSelector(selectWhere, (where) => where?.customAddress);
const selectOrganizationLocations = createSelector(
  selectWhere,
  (where) => where?.locations?.filter((location): location is string => !!location) || []
);
const selectAvailableLocations = createSelector(selectOrganizationLocations, (locations) => {
  return LOCATIONS_LIST.filter((location) => !locations?.includes(location));
});
const selectIsVideoConference = createSelector(selectLocationTypes, (types) =>
  types.includes(LocationType.VIDEO_CONFERENCE)
);
const selectVideoConferenceType = createSelector(selectWhere, (where) => where?.videoConferenceType);

const selectIsVideoConferenceValid = createSelector(
  selectLocationTypes,
  selectAllHostsData,
  (types, hosts) =>
    !types.includes(LocationType.VIDEO_CONFERENCE) ||
    hosts.every(
      (host) =>
        host.integrationTypes?.includes(IntegrationType.GOOGLE_MEET) ||
        host.integrationTypes?.includes(IntegrationType.MICROSOFT_TEAMS) ||
        host.integrationTypes?.includes(IntegrationType.ZOOM)
    )
);

const selectVideoConferenceNotification = createSelector(selectIsVideoConferenceValid, (isValid) =>
  isValid ? '' : t('EditBookingTemplateWhereStep:VIDEO_CONFERENCE_NOTIFICATION')
);

const selectIsHostPhoneValid = createSelector(
  selectLocationTypes,
  selectPhoneCallType,
  selectAllHostsData,
  (types, type, hosts) =>
    !types.includes(LocationType.PHONE_CALL) ||
    type !== PhoneCallType.HOST_PHONE_NUMBER ||
    hosts.every((host) => !!host.hasPhoneNumber)
);
const selectHostPhoneNotification = createSelector(selectIsHostPhoneValid, (isValid) =>
  isValid ? '' : t('EditBookingTemplateWhereStep:HOSTS_PHONE_NOTIFICATION')
);

const selectIsCustomPhoneValid = createSelector(
  selectLocationTypes,
  selectPhoneCallType,
  selectPhoneCallCustomPhoneNumber,
  (types, type, customPhone) =>
    !types.includes(LocationType.PHONE_CALL) || type !== PhoneCallType.CUSTOM_PHONE || validatePhoneNumber(customPhone)
);
const selectIsCustomAddressValid = createSelector(
  selectLocationTypes,
  selectInPersonType,
  selectInPersonCustomAddress,
  (types, type, address) =>
    !types.includes(LocationType.IN_PERSON) || type !== InPersonType.CUSTOM_ADDRESS || Boolean(address)
);
const selectIsCompanyAddressValid = createSelector(
  selectLocationTypes,
  selectInPersonType,
  selectOrganizationLocations,
  (types, type, locations) =>
    !types.includes(LocationType.IN_PERSON) || type !== InPersonType.LOCATION || locations?.length
);
const selectIsDefaultLocationTypeValid = createSelector(
  selectLocationTypes,
  selectDefaultLocationType,
  (types, defaultLocationType) => types.includes(defaultLocationType)
);

const selectWhen = createSelector(selectBookingPage, (bookingPage) => bookingPage.when);
const selectDateRange = createSelector(selectWhen, (when) => when?.dateRange);
const selectDateRangeType = createSelector(selectDateRange, (dateRange) => dateRange?.type);
const selectDateRangeCount = createSelector(selectDateRange, (dateRange) => dateRange?.count);
const selectDateRangeList = createSelector(selectDateRange, (dateRange) => [
  ...(dateRange?.from ? [dateRange.from] : []),
  ...(dateRange?.to ? [dateRange.to] : []),
]);
const selectDuration = createSelector(selectWhen, (when) => when?.duration);
const selectDurationCount = createSelector(selectDuration, (duration) => duration?.count);
const selectDurationTimeUnit = createSelector(selectDuration, (duration) => duration?.timeUnit);
const selectScheduleBuffer = createSelector(selectWhen, (when) => when?.scheduleBuffer);
const selectScheduleBufferCount = createSelector(selectScheduleBuffer, (scheduleBuffer) => scheduleBuffer?.count);
const selectScheduleBufferTimeUnit = createSelector(selectScheduleBuffer, (scheduleBuffer) => scheduleBuffer?.timeUnit);
const selectTimeBeforeStartTime = createSelector(selectWhen, (when) => when?.beforeStartTime);
const selectTimeAfterEndTime = createSelector(selectWhen, (when) => when?.afterEndTime);
const selectIsDurationCountValid = createSelector(selectDurationCount, (count) => Boolean(Number(count)));
const selectIsDateRangeCountValid = createSelector(selectDateRangeCount, (count) => Boolean(Number(count)));
const selectIsDateRangeValid = createSelector(selectDateRangeList, (dateRangeList) => dateRangeList.length === 2);

const selectDurationMinutes = createSelector(selectDurationCount, selectDurationTimeUnit, (count, timeUnit) =>
  calculateMinutes(count, timeUnit)
);
const selectBeforeBufferMinutes = createSelector(
  selectTimeBeforeStartTime,
  (beforeStartTime) => Number(beforeStartTime?.replace('m', '')) || 0
);
const selectAfterBufferMinutes = createSelector(
  selectTimeAfterEndTime,
  (afterEndTime) => Number(afterEndTime?.replace('m', '')) || 0
);

const selectInputFields = createSelector(selectBookingPage, (bookingPage) => bookingPage.inputFields || []);
const selectPhoneInputFields = createSelector(selectInputFields, (inputFields) =>
  inputFields.find((field) => field?.fieldType === CustomFieldType.PHONE)
);
const selectLocationInputFields = createSelector(selectInputFields, (inputFields) =>
  inputFields.find((field) => field?.fieldType === CustomFieldType.LOCATION)
);

const selectNotifications = createSelector(selectBookingPage, (bookingPage) => bookingPage.notifications);
const selectConfirmation = createSelector(selectNotifications, (notifications) => notifications?.confirmations || {});
const selectReschedule = createSelector(selectNotifications, (notifications) => notifications?.reschedule || {});
const selectReminder = createSelector(selectNotifications, (notifications) => notifications?.reminders || {});
const selectReminderTiming = createSelector(selectReminder, (reminders) => reminders?.timing || []);
const selectReminderDuplicateTimerIndexes = createSelector(selectReminderTiming, (timing) =>
  timing.reduce(
    (result, timer, index, timers) =>
      timers.slice(0, index).find((item) => item === timer) ? [...result, index] : result,
    [] as number[]
  )
);
const selectCancelation = createSelector(selectNotifications, (notifications) => notifications?.cancelations || {});
const selectFollowUp = createSelector(selectNotifications, (notifications) => notifications?.followUp || {});

const selectSmsReminder = createSelector(selectNotifications, (notifications) => notifications?.smsReminder || {});
const selectSmsTiming = createSelector(selectSmsReminder, (smsReminder) => smsReminder?.timing || []);
const selectSmsDuplicateTimerIndexes = createSelector(selectSmsTiming, (timing) =>
  timing.reduce(
    (result, timer, index, timers) =>
      timers.slice(0, index).find((item) => item === timer) ? [...result, index] : result,
    [] as number[]
  )
);
const selectSmsReminderOptionEnabled = createSelector(selectSmsReminder, (smsReminder) => smsReminder.enabled);

const selectConfirmationPage = createSelector(selectBookingPage, (bookingPage) => bookingPage.confirmationsPage);
const selectConfirmationType = createSelector(selectConfirmationPage, (confirmationsPage) => confirmationsPage?.type);
const selectConfirmationExternalLink = createSelector(
  selectConfirmationPage,
  (confirmationsPage) => confirmationsPage?.externalLink
);
const selectConfirmationAnotherEnabled = createSelector(
  selectConfirmationPage,
  (confirmationsPage) => confirmationsPage?.anotherEventEnabled
);
const selectConfirmationRescheduleEnabled = createSelector(
  selectConfirmationPage,
  (confirmationsPage) => confirmationsPage?.rescheduleEnabled
);
const selectConfirmationCancelEnabled = createSelector(
  selectConfirmationPage,
  (confirmationsPage) => confirmationsPage?.cancelEnabled
);
const selectConfirmationCustomLinks = createSelector(
  selectConfirmationPage,
  (confirmationsPage) => confirmationsPage?.customLinks
);
const selectIsExternalLinkValid = createSelector(
  selectConfirmationType,
  selectConfirmationExternalLink,
  (type, link) => type !== ConfirmationsPageType.REDIRECT || Boolean(link)
);
const selectCancelationPolicy = createSelector(
  selectConfirmationPage,
  (confirmationsPage) => confirmationsPage?.cancelationPolicy
);

const selectIsHostLocationValid = createSelector(
  selectIsHostPhoneValid,
  selectIsVideoConferenceValid,
  (isHostPhoneValid, isVideoConferenceValid) => isHostPhoneValid && isVideoConferenceValid
);

const selectIsWhereStepValid = createSelector(
  selectIsCustomPhoneValid,
  selectIsCustomAddressValid,
  selectIsCompanyAddressValid,
  selectIsDefaultLocationTypeValid,
  (isPhoneValid, isCustomAddressValid, isCompanyAddressValid, isDefaultLocationTypeValid) =>
    isPhoneValid && isCustomAddressValid && isCompanyAddressValid && isDefaultLocationTypeValid
);
const selectIsWhenStepValid = createSelector(
  selectDateRangeType,
  selectIsDurationCountValid,
  selectIsDateRangeCountValid,
  selectIsDateRangeValid,
  (type, isDurationCountValid, isDateRangeCountValid, isDateRangeValid) =>
    isDurationCountValid &&
    (type === DateRangeType.DAYS_IN_FUTURE
      ? isDateRangeCountValid
      : type === DateRangeType.SPECIFIC_DATES
      ? isDateRangeValid
      : true)
);
const selectIsNotificationsStepValid = createSelector(
  selectConfirmation,
  selectReminder,
  selectCancelation,
  selectFollowUp,
  selectSmsReminder,
  selectReminderDuplicateTimerIndexes,
  selectSmsDuplicateTimerIndexes,
  (confirmations, reminders, cancelations, followUp, smsReminder, reminderDuplicateIndexes, smsDuplicateIndexes) =>
    (!confirmations.enabled || (Boolean(confirmations.subject) && Boolean(confirmations.body))) &&
    (!reminders.enabled || (Boolean(reminders.subject) && Boolean(reminders.body))) &&
    (!cancelations.enabled || (Boolean(cancelations.subject) && Boolean(cancelations.body))) &&
    (!followUp.enabled || (Boolean(followUp.subject) && Boolean(followUp.body))) &&
    (!smsReminder.enabled || Boolean(smsReminder.body)) &&
    reminderDuplicateIndexes.length === 0 &&
    smsDuplicateIndexes.length === 0
);
const selectIsConfirmationStepValid = createSelector(selectIsExternalLinkValid, (isLinkValid) => isLinkValid);

const selectPotentialHosts = createSelector(
  selectHasTemplate,
  selectBookingTemplateId,
  bookingTemplatesSelectors.selectBookingTemplates,
  usersSelectors.selectLoggedUsersInCurrentWorkspace,
  (hasTemplate, bookingTemplateId, bookingTemplates, workspaceUsers) => {
    let userLists: UserAdminDataFullRecord[] = [];
    if (hasTemplate) {
      const bookingTemplate = bookingTemplates.find((template) => template.id === bookingTemplateId);
      if (bookingTemplate?.potentialHosts) {
        const usersId = bookingTemplate.potentialHosts.filter((id) => !!id) as string[];
        userLists = workspaceUsers.filter((user) => user.userId && usersId.includes(user.userId));
      }
    } else {
      userLists = workspaceUsers;
    }
    return userLists.map(
      (user) =>
        ({
          value: user.userId || '',
          label: user.fullName || user.email,
          isTeam: false,
          hasPhoneNumber: !!user.phoneNumber,
          integrationTypes: user.integrationTypes || [],
          defaultVideoIntegration: user.defaultVideoIntegration,
        } as HostOption)
    );
  }
);
const selectPotentialTeams = createSelector(
  selectHasTemplate,
  selectBookingTemplateId,
  bookingTemplatesSelectors.selectBookingTemplates,
  teamsSelectors.selectTeamsByCurrentWorkspace,
  usersSelectors.selectLoggedUsers,
  selectRecordWhoTeamIds,
  (hasTemplate, bookingTemplateId, bookingTemplates, teams, users, recordTeamIds) => {
    let teamList: TeamRecord[] = [];
    if (hasTemplate) {
      const bookingTemplate = bookingTemplates.find((template) => template.id === bookingTemplateId);
      if (bookingTemplate?.potentialTeams) {
        const teamIds = bookingTemplate.potentialTeams.filter((id) => !!id) as string[];
        teamList = teams.filter(
          (team) => team.id && teamIds.includes(team.id) && (team.active || recordTeamIds.includes(team.id))
        );
      }
    } else {
      teamList = teams.filter((team) => team.id && (team.active || recordTeamIds.includes(team.id)));
    }
    return teamList.map((team) => {
      let hasPhoneNumber = false;
      const integrationTypes = new Set<IntegrationType>();
      team.members?.forEach((member) => {
        const user = users.find((user) => user.userId === member?.userId);
        if (user) {
          hasPhoneNumber = hasPhoneNumber || !!user.phoneNumber;
          user.integrationTypes?.forEach((integration) => integrationTypes.add(integration));
        }
      });
      return {
        value: team.id || '',
        label: team.name || '',
        isTeam: true,
        hasPhoneNumber,
        integrationTypes: Array.from(integrationTypes.values()),
      } as HostOption;
    });
  }
);

const selectPotentialHostsAndTeams = createSelector(selectPotentialHosts, selectPotentialTeams, (hosts, teams) => [
  ...hosts,
  ...teams,
]);

const selectIsAdminOnlyLocked = (bookingPage?: UpdateBookingPageInput) =>
  createSelector(
    selectIsAdminOnly,
    userSettingsSelectors.selectIsAdminRole,
    (isAdminOnly, isAdmin) => (bookingPage !== undefined ? !!bookingPage.adminOnly : isAdminOnly) && !isAdmin
  );

const selectIsMyBookingPage = (bookingPage?: UpdateBookingPageInput) =>
  createSelector(authenticationSelectors.selectUserId, selectHostUserIds, (userId, hostsIds) =>
    bookingPage !== undefined
      ? bookingPage.who?.hostMembers && bookingPage.who?.hostMembers[0]?.id === userId
      : hostsIds.includes(userId)
  );

const selectIsReadOnlyBookingPage = (bookingPage?: UpdateBookingPageInput) =>
  createSelector(
    selectIsExistingBookingPage,
    selectIsMyBookingPage(bookingPage),
    userSettingsSelectors.selectMyBookingPagesReadOnly,
    userSettingsSelectors.selectAllBookingPagesReadOnly,
    (isExisting, isMyBookingPage, isMyReadOnly, isAllReadOnly) =>
      (!!bookingPage || isExisting) && (isMyBookingPage ? isMyReadOnly : isAllReadOnly)
  );

const selectIsBookingPageCreate = (bookingPage?: UpdateBookingPageInput) =>
  createSelector(
    selectIsExistingBookingPage,
    selectIsMyBookingPage(bookingPage),
    userSettingsSelectors.selectMyBookingPagesCreate,
    userSettingsSelectors.selectAllBookingPagesCreate,
    (isExisting, isMyBookingPage, isMyCreate, isAllCreate) =>
      (!!bookingPage || isExisting) && (isMyBookingPage ? isMyCreate : isAllCreate)
  );

const selectIsBookingPageEdit = (bookingPage?: UpdateBookingPageInput) =>
  createSelector(
    selectIsExistingBookingPage,
    selectIsMyBookingPage(bookingPage),
    selectIsAdminOnlyLocked(bookingPage),
    userSettingsSelectors.selectMyBookingPagesEdit,
    userSettingsSelectors.selectAllBookingPagesEdit,
    (isExisting, isMyBookingPage, isAdminLocked, isMyEdit, isAllEdit) =>
      (!!bookingPage || isExisting) && !isAdminLocked && (isMyBookingPage ? isMyEdit : isAllEdit)
  );

const selectIsBookingPageDelete = (bookingPage?: UpdateBookingPageInput) =>
  createSelector(
    selectIsExistingBookingPage,
    selectIsMyBookingPage(bookingPage),
    selectIsAdminOnlyLocked(bookingPage),
    userSettingsSelectors.selectMyBookingPagesDelete,
    userSettingsSelectors.selectAllBookingPagesDelete,
    (isExisting, isMyBookingPage, isAdminLocked, isMyDelete, isAllDelete) =>
      (!!bookingPage || isExisting) && !isAdminLocked && (isMyBookingPage ? isMyDelete : isAllDelete)
  );

const selectIsBookingPageLocked = (bookingPage?: UpdateBookingPageInput) =>
  createSelector(
    selectIsReadOnlyBookingPage(bookingPage),
    selectIsAdminOnlyLocked(bookingPage),
    (isReadonly, isAdminLocked) => isReadonly || !!isAdminLocked
  );

const selectLockedTooltip = createSelector(
  selectIsReadOnlyBookingPage(),
  selectIsAdminOnlyLocked(),
  selectLastModify,
  (isReadonly, isAdminLocked, lastModify) =>
    isAdminLocked
      ? `${t('EditBookingPage:LOCKED_ADMIN_ONLY')} ${lastModify}`
      : isReadonly
      ? `${t('EditBookingPage:LOCKED_NO_PERMISSIONS')} ${lastModify}`
      : ''
);

const selectHostsInfoById = createSelector(
  usersSelectors.selectUserNamesById,
  teamsSelectors.selectTeamNamesById,
  selectFilteredBookingPages,
  (users, teams, bookingPages) => {
    const hostsInfoMap: Record<string, string> = {};
    bookingPages.forEach((bookingPage) => {
      const hosts = [
        ...(bookingPage.who?.hostMembers ? bookingPage.who.hostMembers : []),
        ...(bookingPage.who?.guests ? bookingPage.who.guests : []),
      ];
      let hostsInfo = '';
      hosts.forEach((host) => {
        const name = host?.id && (users[host?.id] || teams[host?.id] || host?.name);
        if (name) {
          hostsInfo += (hostsInfo.length ? ', ' : '') + name;
        }
      });
      hostsInfoMap[bookingPage.id] = hostsInfo;
    });

    return hostsInfoMap;
  }
);

const selectHostsInfo = createSelector(
  usersSelectors.selectUserNamesById,
  teamsSelectors.selectTeamNamesById,
  selectBookingPage,
  (users, teams, bookingPage) => {
    const hosts = [...(bookingPage.who?.hostMembers || []), ...(bookingPage.who?.guests || [])];

    let hostsInfo = '';
    hosts.forEach((host) => {
      const name = host?.id && (users[host?.id] || teams[host?.id] || host?.name);
      if (name) {
        hostsInfo += (hostsInfo.length ? ', ' : '') + name;
      }
    });

    return hostsInfo;
  }
);

const selectBookingPageForPreview = createSelector(
  selectBookingPage,
  selectStyleForPreview,
  (bookingPage, styleForPreview) => ({ ...bookingPage, style: styleForPreview } as UpdateBookingPageInput)
);

const selectSmartAlerts = createSelector(selectBookingPage, (page) => page.smartAlertIds || []);

const selectBookingPagesAvailForEdit = createSelector(
  selectBookingPages,
  (state: State) => state,
  (pages, state) => pages.filter((page) => selectIsBookingPageEdit(page)(state))
);

const selectLinkedBookingPages = (groupBookingPage: UpdateGroupBookingPageInput) =>
  createSelector(selectBookingPages, (bookingPages) => {
    const res: UpdateBookingPageInput[] = [];
    groupBookingPage.bookingPages?.forEach((record) => {
      if (record?.bookingPageId) {
        const page = bookingPages.find((page) => page?.id === record?.bookingPageId);
        page && res.push(page);
      }
    });
    return res;
  });

export const bookingPageSelectors = {
  selectIsFetching,
  selectIsFetchingAvatar,
  selectIsFetchingBackground,
  selectIsSpinnerFetching,
  selectIsSkeletonFetching,
  selectIsEdited,
  selectBookingPages,
  selectBookingPageIds,
  selectMyDefaultBookingPage,
  selectBookingPagesLength,
  selectFilteredBookingPages,
  selectSearchedBookingPages,
  selectPersonalSearchedBookingPages,
  selectSharedSearchedBookingPages,
  selectSelectedBookingPages,
  selectAddToWebsiteLink,
  selectIsSelectionMode,
  selectSelectedBookingPagesImageNames,
  selectImages,
  selectBookingPage,
  selectBookingPagesById,
  selectAccordionIndexes,
  selectFilterByUser,
  selectIsBookingPageSelector,

  selectBookingPageById,
  selectBookingPagesOptions,
  selectWhat,
  selectId,
  selectDisplayId,
  selectIsAdminOnly,
  selectCustomName,
  selectIsActive,
  selectInstructions,
  // selectLastModify,

  selectBookingTemplateId,
  selectBookingTemplateName,
  selectHasTemplate,
  selectBookingPageNameById,
  selectInviteOthers,
  selectEnterNote,
  selectShortLink,
  selectStyle,
  selectPreviewBookingTemplate,
  selectLogo,
  selectBackground,
  selectCss,
  selectFooterHtml,
  selectBackgroundColor,
  selectIsWorkspaceLogoToUse,
  selectIsWorkspaceBackgroundToUse,
  selectFinalLogoImage,
  selectFinalBackgroundImage,
  selectStyleForPreview,
  selectImageNamesToRemove,
  selectCalendar,
  selectTimeFormat,
  selectCalendarFormat,
  selectTopOfInterval,
  selectTimeInterval,
  selectTimeZoneType,
  selectSelectedTimeZone,
  selectDefaultTimeZone,
  selectAvailableTimeZones,
  selectAvailableTimeZonesOptions,
  selectAssignedTimeZones,
  selectAssignedTimeZonesOptions,
  selectCloneName,
  selectIsCloneNameDuplicate,
  selectIsCloneNameValid,
  selectIsExistingBookingPage,
  selectIsNameValid,
  selectIsTimeZonesValid,
  selectIsCalendarStepValid,

  selectWhatColor,

  selectWhere,
  selectLocationTypes,
  selectDefaultLocationType,
  selectSkipWhereStep,
  selectPhoneCallType,
  selectIsPromptInviteCallType,
  selectPhoneCallCustomPhoneCode,
  selectPhoneCallCustomPhoneNumber,
  selectInPersonType,
  selectInPersonCustomAddress,
  selectOrganizationLocations,
  selectAvailableLocations,
  selectIsVideoConference,
  selectVideoConferenceType,
  selectIsVideoConferenceValid,
  selectVideoConferenceNotification,
  selectIsHostPhoneValid,
  selectHostPhoneNotification,
  selectIsCustomPhoneValid,
  selectIsCustomAddressValid,
  selectIsCompanyAddressValid,
  selectIsDefaultLocationTypeValid,

  selectDateRangeType,
  selectDateRangeCount,
  selectDateRangeList,
  selectDuration,
  selectDurationCount,
  selectDurationTimeUnit,
  selectScheduleBufferCount,
  selectScheduleBufferTimeUnit,
  selectTimeBeforeStartTime,
  selectTimeAfterEndTime,
  selectIsDurationCountValid,
  selectIsDateRangeCountValid,
  selectIsDateRangeValid,

  selectDurationMinutes,
  selectBeforeBufferMinutes,
  selectAfterBufferMinutes,

  selectInputFields,
  selectPhoneInputFields,
  selectLocationInputFields,

  selectNotifications,
  selectConfirmation,
  selectReschedule,
  selectReminder,
  selectReminderDuplicateTimerIndexes,
  selectCancelation,
  selectFollowUp,

  selectSmsReminder,
  selectSmsDuplicateTimerIndexes,
  selectSmsReminderOptionEnabled,

  selectConfirmationType,
  selectConfirmationExternalLink,
  selectConfirmationAnotherEnabled,
  selectConfirmationRescheduleEnabled,
  selectConfirmationCancelEnabled,
  selectConfirmationCustomLinks,
  selectIsExternalLinkValid,
  selectCancelationPolicy,

  selectIsWhatStepValid,
  selectIsWhoStepValid,
  selectIsWhereStepValid,
  selectIsWhenStepValid,
  selectIsNotificationsStepValid,
  selectIsConfirmationStepValid,
  selectIsHostLocationValid,

  selectIsHostUser,
  selectAllHostsData,
  selectHostUserIds,
  selectHostTeamIds,
  selectAllHostsIds,
  selectHosts,
  selectGuests,
  selectGuestsData,
  selectGuestsIds,
  selectPotentialHosts,
  selectPotentialTeams,
  selectPotentialHostsAndTeams,
  selectAllHostAndGuestIds,
  selectHostTeamAndGuestIds,
  selectHostAndGuestsData,
  selectHostIntegrations,
  selectHostVideoConferences,
  selectIsUserRequiredHost,

  selectIsMyBookingPage,
  selectIsAdminOnlyLocked,
  selectIsBookingPageCreate,
  selectIsBookingPageEdit,
  selectIsBookingPageDelete,

  selectIsBookingPageLocked,
  selectLockedTooltip,
  selectHostsInfoById,
  selectHostsInfo,
  selectBookingPageForPreview,
  selectSmartAlerts,
  selectBookingPagesAvailForEdit,

  selectLinkedBookingPages,
};
