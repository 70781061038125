export const LookAndFeel = Object.freeze({
  BACKGROUND_LABEL: `Background`,
  BUTTONS_LABEL: `Buttons`,
  FONT_COLORS_TITLE: `Font Colors`,
  FONT_FAMILIES_TITLE: `Font Families`,
  HEADER_FONT_LABEL: `Header Font`,
  HEADER_LABEL: `Header`,
  MAIN_TEXT_FONT_LABEL: `Main Text Font`,
  MAIN_TEXT_LABEL: `Main Text`,
  PRIMARY_LABEL: `Primary`,
  SITE_COLORS_TITLE: `Site Colors`,
  SUB_HEADER_FONT_LABEL: `Sub-Header Font`,
  SUB_HEADER_LABEL: `Sub-Header`,
});

export const QuickSetupCalendarConnected = Object.freeze({
  DESCRIPTION: `SUMO will automatically check your calendar for busy times and add new meetings as they are scheduled. You may always disconnect your calendar later.`,
  INSTRUCTION_STEP_A1: `We'll check `,
  INSTRUCTION_STEP_A2: ` for conflicts.`,
  INSTRUCTION_STEP_B: `We'll add meetings to the calendar for `,
  INSTRUCTION_STEP_C: `We'll encrypt all data in transit and give you options regarding your data.`,
  INSTRUCTION_TITLE: `Here's how SUMO will work with `,
  STEP_MESSAGE: `Let's do this!`,
  TITLE: `Your calendar is connected!`,
});

export const QuickSetupChromeExtensionStep = Object.freeze({
  ACCEPT_TERMS_PART1: `I accept the`,
  ACCEPT_TERMS_PART2: `Terms of Use`,
  ACCEPT_TERMS_PART3: `and`,
  ACCEPT_TERMS_PART4: `Privacy Policy`,
  BENEFITS_DESCRIPTION: `The best SUMO1 Experience is using SUMO from within your inbox, by installing the Chrome Extension. Use SUMO in:`,
  BENEFITS_LIST_PART1: `Google Gmail & Calendar`,
  BENEFITS_LIST_PART2: `Microsoft Inbox & Calendar`,
  BENEFITS_LIST_PART3: `Salesforce CRM`,
  BENEFITS_TITLE: `Benefits`,
  CHROME_EXTENSION: `Chrome Extension`,
  REQUIREMENTS_DESCRIPTION: `You must use the Google Chrome Browser.`,
  REQUIREMENTS_TITLE: `Requirements`,
  SUMO1_FOR_GOOGLE: `Install "SUMO1 for Gmail"`,
  SUMO1_FOR_MICROSOFT: `Install "SUMO1 for Outlook"`,
  TITLE: `Install Chrome Extension`,
});

export const QuickSetupSetupAvailabilityStep = Object.freeze({
  TITLE_ADMIN: `Set team availability`,
  TITLE_USER: `Set your availability`,
  MESSAGE_ADMIN: `Create the initial default schedule indicating when your team is typically available for appointments.`,
  MESSAGE_USER: `Create the initial default schedule indicating when you are typically available for appointments.`,
  MINIMUM_DAYS_ERROR_TEXT: `Please select at least one day with available hours`,
  SETUP_DAYS_INPUT_LABEL: `Available days`,
  SETUP_HOURS_INPUT_LABEL: `Available hours`,
  STEP_MESSAGE: `Keep it up!`,
});

export const QuickSetupSetupPhoneCallsStep = Object.freeze({
  DESCRIPTION: `For scheduled calls, what number should guests dial? Most users provide their mobile for SMS alerts, but you can update this later.`,
  PHONE_DETAILS_LABELS: `Additional info or Instruction`,
  PHONE_DETAILS_PLACEHOLDER: `Ex: I look forward to meeting. SMS Text me anytime.`,
  PHONE_PLACEHOLDER: `Phone number`,
  STEP_MESSAGE: `You're almost there!`,
  TITLE: `Set phone number`,
});

export const QuickSetupToastsNotifications = Object.freeze({
  SAVE_QUICK_SETUP_ERROR_TOAST: `Quick Setup save request fail`,
  SAVE_QUICK_SETUP_SUCCESS_TOAST_DESC: `You are now ready for easy scheduling.`,
  SAVE_QUICK_SETUP_SUCCESS_TOAST_TITLE: `Setup complete!`,
});

export const QuickSetupVideoConferenceStep = Object.freeze({
  DEFAULT_DESCRIPTION: `Default Video Conference`,
  DESCRIPTION: `Optional, but recommended for virtual meetings.`,
  GOOGLE_MEET_TITLE: `Google Meet`,
  MICROSOFT_TEAMS_TITLE: `Microsoft Teams`,
  STEP_MESSAGE: `Let's do this!`,
  TITLE: `Connect video conference account`,
  ZOOM_TITLE: `ZOOM Meetings`,
});

export const QuickSetupYourBrandStep = Object.freeze({
  DESCRIPTION: `Upload your logo to appear on all booking pages. You can do more branding settings in workspace later.`,
  SAVE_BUTTON: `Save & Complete Setup `,
  STEP_MESSAGE: `You're almost there!`,
  TITLE: `Add your logo`,
});
