import { createSelector } from 'reselect';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { State } from '../rootStore';
import {
  CHARGEBEE_MONTH_UNIT,
  CHARGEBEE_STATUS_ACTIVE,
  billIsLatePart1Label,
  billIsLatePart2MultipleLabel,
  billIsLatePart2SingleLabel,
  expireMessage1Label,
  // expireMessage2Label,
  expireMessage3Label,
  teamsAnnualLabel,
  teamsMonthlyLabel,
} from './constants';
import { EXPIRATION_PERIOD_DAYS, authenticationSelectors } from '../authentication';
import { getCurrencyLabelByCode } from './utils';

dayjs.extend(utc);
dayjs.extend(timezone);

const eventState = (state: State) => state.billing;

const selectError = createSelector(eventState, (state) => state.error);
const selectIsFetching = createSelector(eventState, (state) => state.isFetching);
const selectIsRefreshLicencesFetching = createSelector(eventState, (state) => state.isRefreshLicencesFetching);
const selectDetails = createSelector(eventState, (state) => state.subscription);
const selectTerm = createSelector(eventState, (state) => state.term);
const selectItemPrices = createSelector(eventState, (state) => state.itemPrices);
const selectChangeSeats = createSelector(eventState, (state) => state.changeSeats);
const selectIsAddOrRemoveSeats = createSelector(eventState, (state) => state.addOrRemoveSeats);

const selectStatus = createSelector(selectDetails, (state) => state.status);
const selectBillingPeriodUnit = createSelector(selectDetails, (state) => state.billing_period_unit);
const selectCurrentTermStart = createSelector(selectDetails, (state) => state.current_term_start * 1000);
const selectCurrentTermEnd = createSelector(selectDetails, (state) => state.current_term_end * 1000);
const selectNextBillingAt = createSelector(selectDetails, (state) => state.next_billing_at * 1000);
const selectUpdatedAt = createSelector(selectDetails, (state) => state.updated_at * 1000);
const selectCurrencyCode = createSelector(selectDetails, (state) => state.currency_code);
const selectSubscriptionItem = createSelector(selectDetails, (state) => state.subscription_items[0]);
const selectSubscriptionQuantity = createSelector(selectSubscriptionItem, (state) => state.quantity);
const selectSubscriptionTerm = createSelector(selectSubscriptionItem, (state) => state.item_price_id);
const selectSubscriptionCurrentAmount = createSelector(selectSubscriptionItem, (state) => state.amount / 100);
const selectSumoScheduledChanges = createSelector(selectDetails, (state) => state.sumoScheduledChanges);
const selectSubscriptionTiers = createSelector(selectDetails, (state) => state.item_tiers);
const selectCancelledAt = createSelector(selectDetails, (state) => state.cancelled_at && state.cancelled_at * 1000);

const selectRemoveSeatsMax = createSelector(
  selectSubscriptionQuantity,
  selectSumoScheduledChanges,
  authenticationSelectors.selectAssigned,
  (quantity, changes, assigned) => {
    const removeSeats = changes?.reduce((result, change) => result + (change.seats || 0), 0) || 0;
    return quantity - removeSeats - assigned; // cannot delete more than assigned
  }
);

const selectIsRemoveSeatsAvailable = createSelector(selectRemoveSeatsMax, (removeSeatsMax) => removeSeatsMax > 0);
const selectScheduledChanges = createSelector(selectDetails, (state) => state.scheduledChanges);
const selectHasScheduledChanges = createSelector(selectDetails, (state) => state.has_scheduled_changes);
const selectHasScheduledChangeTheTerm = createSelector(
  selectScheduledChanges,
  selectTerm,
  (scheduledChanges, term) => scheduledChanges && scheduledChanges.some((change) => change.item_price_id !== term)
);

const selectNextTerm = createSelector(selectScheduledChanges, selectTerm, (scheduledChanges, currentTerm) => {
  const nextTermChange = scheduledChanges?.find((change) => change.item_price_id !== currentTerm);
  return nextTermChange ? nextTermChange.item_price_id : currentTerm;
});

const selectIsNextBillingReceived = createSelector(selectNextBillingAt, (time) => Boolean(time));
const selectIsActive = createSelector(selectStatus, (status) => status === CHARGEBEE_STATUS_ACTIVE);
const selectIsMonthly = createSelector(selectBillingPeriodUnit, (unit) => unit === CHARGEBEE_MONTH_UNIT);
const selectBillingLateDays = createSelector(selectCurrentTermEnd, (time) =>
  dayjs().isAfter(dayjs(time)) ? dayjs().diff(time, 'day') : 0
);
const selectNextBillingDate = createSelector(selectNextBillingAt, (time) => dayjs(time).format('MMMM D, YYYY'));
const selectLastUpdatedDate = createSelector(selectUpdatedAt, (time) => dayjs(time).format('MMMM D, YYYY'));

const selectNextBillAmount = createSelector(
  selectScheduledChanges,
  selectSubscriptionCurrentAmount,
  (changes, amount) => (changes?.length ? changes[0].amount / 100 : amount)
);
const selectIsCancelScheduled = createSelector(selectCancelledAt, (time) => Boolean(time));
const selectCancelDate = createSelector(selectCancelledAt, (time) => dayjs(time).format('MMMM D, YYYY'));

const selectIsChangeTermValid = createSelector(
  selectSubscriptionTerm,
  selectTerm,
  (term, changeTerm) => term !== changeTerm
);

const selectChangeSeatsTermPrice = createSelector(
  // a price which will be after the user will request new number of seats
  selectIsAddOrRemoveSeats,
  selectSubscriptionQuantity,
  selectSubscriptionTiers,
  selectChangeSeats,
  (iSAddOrRemoveSeats, quantity, tiers, seats) => {
    const finalQuantity = quantity + seats * (iSAddOrRemoveSeats ? 1 : -1);
    let remainingSeats = finalQuantity;
    let totalCost = 0;

    tiers?.forEach((tier) => {
      if (remainingSeats <= 0) return;

      const tierMax = tier.ending_unit || remainingSeats; // use tier's ending unit or remaining seats if it's the last tier
      const seatsInTier = Math.min(remainingSeats, tierMax - tier.starting_unit + 1); // calculate how many seats fall into the current tier

      totalCost += seatsInTier * (tier.price / 100); // add the cost for seats in the current tier
      remainingSeats -= seatsInTier; // subtract the number of seats allocated from the remaining seats
    });

    return totalCost;
  }
);

const selectChangeSeatsPrice = createSelector(
  // price of new seats which wants the user in the popup
  selectChangeSeatsTermPrice,
  selectSubscriptionCurrentAmount,
  (termPrice, currrentAmount) => {
    return Math.abs(termPrice - currrentAmount);
  }
);

const selectTermsDaysTotal = createSelector(
  // days of current term
  selectCurrentTermStart,
  selectCurrentTermEnd,
  (termStart, termEnd) => {
    return dayjs(termEnd).diff(dayjs(termStart), 'day');
  }
);

const selectTermsDaysLeft = createSelector(
  // left days of current term
  selectCurrentTermEnd,
  (termEnd) => {
    return dayjs(termEnd).diff(dayjs(), 'day');
  }
);

const selectAddSeatsProratedPrice = createSelector(
  // if the user adding the seats
  selectChangeSeatsPrice,
  selectTermsDaysLeft,
  selectTermsDaysTotal,
  (changeSeatsPrice, termDaysLeft, termDaysTotal) => {
    return (changeSeatsPrice * termDaysLeft) / termDaysTotal;
  }
);
const selectIsChangeSeatsValid = createSelector(selectChangeSeats, (seats) => Boolean(seats));

const selectCurrencyLabel = createSelector(selectCurrencyCode, (code) => getCurrencyLabelByCode(code));

const selectExpireMessage = createSelector(selectBillingLateDays, (billingLateDays) => {
  const messageDaysLate = billingLateDays < 1 ? '' : `${expireMessage1Label} ${billingLateDays}`;
  const messageDaysLeft = `${EXPIRATION_PERIOD_DAYS - billingLateDays} ${expireMessage3Label}`;

  return `${messageDaysLate} ${messageDaysLeft}`;
});

const selectTermLabel = createSelector(selectBillingPeriodUnit, (unit) =>
  unit === CHARGEBEE_MONTH_UNIT ? teamsMonthlyLabel : teamsAnnualLabel
);

const selectNextBillAmountLabel = createSelector(
  selectCurrencyLabel,
  selectNextBillAmount,
  (currencyLabel, nextBillAmount) => currencyLabel + nextBillAmount
);

const selectNextBillLateLabel = createSelector(selectBillingLateDays, (billingLateDays) =>
  billingLateDays
    ? `${billIsLatePart1Label} ${billingLateDays} ${
        billingLateDays === 1 ? billIsLatePart2SingleLabel : billIsLatePart2MultipleLabel
      }`
    : ''
);

export const billingSelectors = {
  selectError,
  selectIsFetching,
  selectIsRefreshLicencesFetching,
  selectDetails,
  selectTerm,
  selectChangeSeats,
  selectIsAddOrRemoveSeats,
  selectSubscriptionQuantity,
  selectSubscriptionCurrentAmount,
  selectSumoScheduledChanges,
  selectRemoveSeatsMax,
  selectIsRemoveSeatsAvailable,

  selectHasScheduledChanges,
  selectHasScheduledChangeTheTerm,

  selectNextTerm,
  selectIsNextBillingReceived,
  selectNextBillingDate,
  selectNextBillAmount,
  selectNextBillAmountLabel,
  selectIsCancelScheduled,
  selectCancelDate,

  selectIsActive,
  selectIsMonthly,

  selectLastUpdatedDate,
  selectCurrencyLabel,
  selectBillingLateDays,

  selectIsChangeTermValid,
  selectChangeSeatsPrice,
  selectAddSeatsProratedPrice,
  selectIsChangeSeatsValid,
  selectExpireMessage,
  selectTermLabel,

  selectNextBillLateLabel,
  selectTermsDaysLeft,
  selectItemPrices,
};
