import { ColorPicker, ColorPickerChangeEvent } from 'primereact/colorpicker';
import { InputText } from 'primereact/inputtext';
import { ChangeEvent } from 'react';
import { MAX_LENGTH_COLOR_PICKER } from '../../../types/constants';

type ColorPickerInputProps = {
  value: string;
  onChange: (value: string) => void;
  label?: string;
  disabled?: boolean;
};

export const ColorPickerInput = ({ value, onChange, label, disabled }: ColorPickerInputProps) => {
  const handleColorPickerChange = (e: ColorPickerChangeEvent) => {
    onChange(e.value ? '#' + e.value.toString() : '');
  };

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    onChange((e.target.value.startsWith('#') ? '' : '#') + e.target.value);
  };

  return (
    <div className="flex flex-column gap-10px">
      {label && <div className="text-title-xs-med">{label}</div>}
      <div className="flex-left-center gap-4px">
        <ColorPicker
          inputClassName="flex-none w-40px h-40px border-none"
          value={value}
          onChange={handleColorPickerChange}
          disabled={disabled}
        />
        <InputText
          value={value}
          onChange={handleInputChange}
          maxLength={MAX_LENGTH_COLOR_PICKER}
          className="flex-1 uppercase"
          disabled={disabled}
        />
      </div>
    </div>
  );
};
