import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'primereact/button';
import { globalActions } from '../../../store/global/actions';
import { EventSteps, eventActions, eventSelectors } from '../../../store/publicBookingPage';
import { Label } from '../../common';
import { LinkIcon, PencilIcon, PencilSquareIcon, PlusCircleIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { Link } from 'react-router-dom';
import { useState } from 'react';
import labels from './labels';

export const PublicBookingPageBookedActions = () => {
  const dispatch = useDispatch();
  const bookingPageId = useSelector(eventSelectors.selectBookingPageId);
  const isPreviewMode = useSelector(eventSelectors.selectIsPreviewMode);
  const isEditable = useSelector(eventSelectors.selectIsEditable);
  const updateButtonLabel = useSelector(eventSelectors.selectUpdateButtonLabel);
  const showDateTimeEditButton = useSelector(eventSelectors.selectShowDateTimeEditButton);
  const bookAnotherButtonLabel = useSelector(eventSelectors.selectBookAnotherButtonLabel);
  const cancelButtonLabel = useSelector(eventSelectors.selectCancelButtonLabel);
  const showBookAnotherButton = useSelector(eventSelectors.selectShowBookAnotherButton);
  const showCancelButton = useSelector(eventSelectors.selectShowCancelButton);
  const viewSettings = useSelector(eventSelectors.selectViewSettings);
  const customLinks = useSelector(eventSelectors.selectCustomLinks);
  const isReRequestSlots = useSelector(eventSelectors.selectIsReRequestSlots);

  const [isManageOpen, setIsManageOpen] = useState(false);

  const handleEdit = () => {
    dispatch(eventActions.setEventStep(EventSteps.WHEN));
    if (isReRequestSlots) {
      // re-request slots for reschedule or after error
      dispatch(eventActions.getAgendaRequest());
    }
  };

  const handleCancelMeeting = () => {
    if (isPreviewMode) {
      return;
    }
    dispatch(eventActions.setEventStep(EventSteps.CANCEL));
  };

  const handleBookAnotherMeeting = () => {
    if (isPreviewMode) {
      return;
    }
    if (bookingPageId) {
      dispatch(globalActions.resetWholeStore());
      dispatch(eventActions.setBookingPageId(bookingPageId));
      dispatch(eventActions.setViewSettings({ ...viewSettings, editKey: null }));
      dispatch(eventActions.getAgendaRequest());
    }
  };

  const handleUpdateLabelChange = (updateButtonLabel: string) => {
    dispatch(eventActions.updatePreviewLabel({ updateButtonLabel }));
  };

  const handleCancelLabelChange = (cancelButtonLabel: string) => {
    dispatch(eventActions.updatePreviewLabel({ cancelButtonLabel }));
  };

  const handleBookAnotherLabelChange = (bookAnotherButtonLabel: string) => {
    dispatch(eventActions.updatePreviewLabel({ bookAnotherButtonLabel }));
  };

  return (
    <>
      <div className="booked-actions">
        <div className={`booked-buttons ${isManageOpen ? 'show-buttons' : ''}`}>
          <div className="buttons-header" onClick={() => setIsManageOpen(false)}>
            <div className="text-title-lg-med">{labels.modifyBooking}</div>
            <XMarkIcon width={20} height={20} />
          </div>

          <div className="buttons-list">
            {showDateTimeEditButton && (
              <Button className="gap-8px pr-16px -mr-16px" text onClick={handleEdit}>
                <PencilIcon width={18} height={18} />
                <Label
                  textClassName="text-button-md-med"
                  editable={isEditable}
                  value={updateButtonLabel}
                  onChange={handleUpdateLabelChange}
                />
              </Button>
            )}
            {showCancelButton && (
              <Button className="gap-8px pr-16px -mr-16px" text onClick={handleCancelMeeting}>
                <XMarkIcon width={18} height={18} />
                <Label
                  textClassName="text-button-md-med"
                  editable={isEditable}
                  value={cancelButtonLabel}
                  onChange={handleCancelLabelChange}
                />
              </Button>
            )}
            {showBookAnotherButton && (
              <>
                {(showDateTimeEditButton || showCancelButton) && <div className="border-top-1 border-heavy-20" />}
                <Button className="gap-8px pr-16px -mr-16px" text onClick={handleBookAnotherMeeting}>
                  <PlusCircleIcon width={18} height={18} />
                  <Label
                    textClassName="text-button-md-med"
                    editable={isEditable}
                    value={bookAnotherButtonLabel}
                    onChange={handleBookAnotherLabelChange}
                  />
                </Button>
              </>
            )}
          </div>
        </div>

        <div className="booked-links">
          {customLinks.map((link, index) => (
            <Link
              key={index}
              to={link?.link || ''}
              target="_blank"
              className="p-button p-button-text gap-8px hover-text-heavy-100 hover:underline"
            >
              <LinkIcon width={18} height={18} />
              {link?.name}
            </Link>
          ))}
        </div>

        {(showDateTimeEditButton || showCancelButton || showBookAnotherButton) && (
          <>
            <div className="booked-manage-space" />
            <div className="booked-manage">
              <Button className="w-full justify-content-center gap-8px button-xl" onClick={() => setIsManageOpen(true)}>
                <PencilSquareIcon width={18} height={18} />
                <span>{labels.manageBooking}</span>
              </Button>
            </div>
          </>
        )}
      </div>
    </>
  );
};
