import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { useDispatch, useSelector } from 'react-redux';
import { JourneyFormField, JourneyFormFieldType } from '../../../API';
import { useEffect, useState } from 'react';
import { FORM_FIELD_LABELS, journeysActions, journeysSelectors } from '../../../store/journeyBuilder';
import { InputText } from 'primereact/inputtext';
import { Checkbox } from 'primereact/checkbox';
import { MAX_LENGTH_NAME, MAX_LENGTH_SHORT_ANSWER } from '../../../types/constants';
import { TrashIcon } from '@heroicons/react/24/outline';
import labels from './labels';

type AddPageFormFieldModalProps = {
  field: JourneyFormField;
  visible: boolean;
  onHide: () => void;
  isEdit?: boolean;
};
export const AddPageFormFieldModal = ({ field, visible, onHide, isEdit }: AddPageFormFieldModalProps) => {
  const dispatch = useDispatch();
  const pageFields = useSelector(journeysSelectors.selectPageFields);

  const [localField, setLocalField] = useState(field);
  const [isInvalid, setIsInvalid] = useState(false);

  useEffect(() => {
    setLocalField(field);
    setIsInvalid(false);
  }, [field]);

  useEffect(() => {
    isInvalid && validateField();
  }, [localField]);

  const isQuestionType = () => {
    return !!field.type && [JourneyFormFieldType.DROPDOWN, JourneyFormFieldType.RADIO_BUTTONS].includes(field.type);
  };

  const onConfirm = () => {
    if (validateField()) {
      const formFields = isEdit
        ? pageFields.map((el) => (el?.label === field.label ? localField : el))
        : [...pageFields, localField];
      dispatch(journeysActions.updatePageStep({ formFields }));
      onHide();
    }
  };

  const validateField = () => {
    const isInvalid = !localField.label || !!localField.answers?.some((answer) => !answer);
    setIsInvalid(isInvalid);
    return !isInvalid;
  };

  const handleLabelChange = (label: string) => {
    setLocalField((prev) => ({ ...prev, label }));
  };

  const handleRequiredChange = (required: boolean) => {
    setLocalField((prev) => ({ ...prev, required }));
  };

  const handleAddAnswer = () => {
    setLocalField((prev) => ({ ...prev, answers: [...(prev.answers || []), ''] }));
  };
  const handleRemoveAnswer = (index: number) => {
    setLocalField((prev) => ({
      ...prev,
      answers: (prev.answers || []).filter((answer, i) => i !== index),
      value: null,
    }));
  };
  const handleAnswerChange = (index: number, value: string) => {
    setLocalField((prev) => ({
      ...prev,
      answers: (prev.answers || []).map((answer, i) => (i === index ? value : answer)),
    }));
  };

  return (
    <Dialog
      visible={visible}
      className="form-field-modal overflow-hidden"
      contentClassName="p-20px"
      draggable={false}
      showHeader={false}
      position="right"
      footer={
        <div>
          <Button label={isEdit ? labels.save : labels.add} onClick={onConfirm} disabled={isInvalid} />
          <Button onClick={onHide} text label={labels.cancel} />
        </div>
      }
      blockScroll
      onHide={onHide}
    >
      {localField.type && (
        <>
          <div className="text-title-xs-med text-heavy-100">{FORM_FIELD_LABELS[localField.type]}</div>
          <InputText
            value={localField.label || ''}
            onChange={(e) => handleLabelChange(e.target.value)}
            className={`w-full mt-8px ${isInvalid && !localField.label ? 'p-invalid' : ''}`}
            placeholder={
              JourneyFormFieldType.SUBTITLE === localField.type
                ? labels.inputSubtitle
                : isQuestionType()
                ? labels.writeQuestion
                : labels.inputTitle
            }
            maxLength={isQuestionType() ? MAX_LENGTH_SHORT_ANSWER : MAX_LENGTH_NAME}
          />

          {isQuestionType() && (
            <>
              {!!localField.answers?.length && (
                <div className="flex flex-column gap-12px mt-16px">
                  {localField.answers.map((answer, index, { length }) => (
                    <div key={index} className="flex-left-center gap-6px">
                      <div className="flex-1">
                        <InputText
                          className={`w-full ${isInvalid && !answer ? 'p-invalid' : ''}`}
                          value={answer || ''}
                          placeholder={labels.answer + ' ' + (index + 1)}
                          onChange={(e) => handleAnswerChange(index, e.target.value.trimStart())}
                          maxLength={MAX_LENGTH_SHORT_ANSWER}
                        />
                      </div>
                      <div className="w-24px h-24px">
                        {length > 1 && (
                          <div className="action-button-sm" onClick={() => handleRemoveAnswer(index)}>
                            <TrashIcon className="icon-16px" />
                          </div>
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              )}
              <Button
                className="text-label-s-med text-blue-main hover-text-blue-dark mt-12px"
                label={labels.addAnswer}
                onClick={handleAddAnswer}
                text
              />
            </>
          )}

          {![JourneyFormFieldType.TITLE, JourneyFormFieldType.SUBTITLE].includes(localField.type) && (
            <div className="flex-left-center gap-8px mt-16px">
              <Checkbox
                inputId="required"
                value="required"
                checked={!!localField.required}
                onChange={(e) => handleRequiredChange(!!e.checked)}
              />
              <label htmlFor="required" className="text-body-s-reg text-heavy-80">
                {isQuestionType() ? labels.questionRequired : labels.inputRequired}
              </label>
            </div>
          )}
        </>
      )}
    </Dialog>
  );
};
