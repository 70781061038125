export const AuthenticationToastsNotifications = Object.freeze({
  CHOOSE_AN_ACCOUNT_ERROR_TOAST: `Error en la solicitud de elección de cuenta`,
  DELETE_USER_ERROR_MESSAGE: `Error en la solicitud de eliminación de usuario`,
  DELETE_USER_SUCCESS_MESSAGE: `Solicitud de eliminación de usuario exitosa`,
  GET_USER_AUTH_ERROR_MESSAGE: `Error en la solicitud de inicio de sesión de usuario`,
  GET_USER_LOGIN_ERROR_MESSAGE: `No se ha encontrado ningún usuario con esa dirección de correo electrónico`,
  GET_USER_LOGOUT_ERROR_MESSAGE: `Error en la solicitud de cierre de sesión de usuario`,
  GET_MAIN_DATA_ERROR_MESSAGE: `Error en la solicitud de autenticación`,
  ROLE_DEACTIVATED_ERROR_MESSAGE: `El rol ha sido desactivado. Por favor, contacte a su administrador de SUMO1.`,
  USER_DEACTIVATED_ERROR_MESSAGE: `El usuario ha sido desactivado. Por favor, contacte a su administrador de SUMO1.`,
  TRIAL_LIMIT_ERROR_MESSAGE: `Ha superado el límite de 30 elementos para la versión de prueba`,
  INVITE_EXPIRED_ERROR_TITLE: `Invitación caducada`,
  INVITE_EXPIRED_ERROR_MESSAGE_PART1: `Su invitación ha caducado. Por favor, póngase en contacto con su administrador para obtener un nuevo enlace o intente `,
  INVITE_EXPIRED_ERROR_MESSAGE_PART2: `registrarse sin una invitación`,
  SWITCH_ACCOUNT_ERROR_MESSAGE: `Otro cuenta ya está asociada con este correo electrónico`,
});

export const Authorise = Object.freeze({
  SING_IN_TITLE_LINE_1: `Bienvenido`,
  SING_IN_TITLE_LINE_2: `de nuevo a SUMO`,
  SING_IN_SUBTITLE: `Inicia sesión en tu centro de programación.`,
  SING_IN_GOOGLE: `Iniciar sesión con Google`,
  SING_IN_MICROSOFT: `Iniciar sesión con Microsoft`,
  SING_IN_DONT_HAVE_AN_ACCOUNT: `¿No tienes una cuenta?`,
  SIGN_UP_COMMENT: `*SUMO1 no requiere una licencia de Salesforce.`,
  SING_UP_TITLE_LINE_1: `Programación en línea`,
  SING_UP_TITLE_LINE_2: `y más`,
  SING_UP_SUBTITLE: `Empieza a usar el servicio gratis con solo un clic.`,
  SING_UP_GOOGLE: `Regístrate con Google`,
  SING_UP_MICROSOFT: `Regístrate con Microsoft`,
  SIGN_UP_FREE_TRIAL: `15 días de prueba gratuita. No se requiere tarjeta de crédito`,
  SING_UP_HAVE_AN_ACCOUNT: `¿Ya tienes una cuenta?`,
  LOOKING_FOR_SUMO_CEP: `¿Buscas SUMO para Salesforce?`,
  LOADING_LABEL_1: `Autorizando usuario`,
  LOADING_LABEL_2: `Verificando calendario`,
  LOADING_LABEL_3: `Creando tokens`,
});

export const DialogLeaveAccount = Object.freeze({
  ACCEPT_TEXT: '¿Te gustaría aceptar esta invitación y abandonar la otra cuenta de SUMO1?',
  INVITE_TEXT_PART_1: `Has sido invitado por`,
  INVITE_TEXT_PART_2: `a unirte a SUMO1, pero tu correo electrónico ya está asociado con otra cuenta de SUMO1.`,
  LAST_SUPER_ADMIN_NOTE: `Usted es el único Super Administrador en la organización. Primero debe iniciar sesión en su organización existente y transferir el rol de "Super Administrador" a otro usuario antes de aceptar esta invitación. Póngase en contacto con el soporte de SUMO para obtener asistencia.`,
  TITLE: `Abandonar cuenta`,
});

export const DialogUserAccounts = Object.freeze({
  ACCEPT_TEXT: `¿Te gustaría aceptar esta invitación y abandonar la otra cuenta de SUMO1?`,
  INVITE_FROM: `Invitación de:`,
  INVITE_TEXT: `Has sido invitado a unirte a SUMO1, pero estás intentando registrarte para una nueva cuenta de SUMO1.`,
  SELECT_TEXT: `Selecciona a continuación si deseas aceptar esta invitación en lugar de crear una nueva cuenta de empresa para SUMO1.`,
  STAY_IN_MY_ACCOUNT: `Permanecer en mi cuenta`,
  TITLE: `Aceptar invitación`,
});
