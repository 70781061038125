import { UpdateUserEventInput } from '../../API';
import {
  AgendaType,
  CancelEventRequestType,
  CreateEventRequest,
  CreateJourneyEventRequest,
  CreateUpdateEventResponse,
  GetAgendaRequestType,
} from './types';
import { cancelReasons } from '../bookedMeetings';

import { handleAPIRequest } from '../utils/reduxUtils';
import { API_PUBLIC } from '../../types/constants';

// ***
// When you change the requests do not forget to specify new data
// in docs/Endpoints.md
// ***

export const fetchAgenda = async (agenda: GetAgendaRequestType): Promise<AgendaType> => {
  return handleAPIRequest<AgendaType>(`${API_PUBLIC}/PreprocessEventCreation`, agenda);
};

export const createEvent = async (request: CreateEventRequest): Promise<CreateUpdateEventResponse> => {
  return handleAPIRequest<CreateUpdateEventResponse>(`${API_PUBLIC}/CreateUserEvent`, request);
};

export const updateEvent = async (event: UpdateUserEventInput): Promise<CreateUpdateEventResponse> => {
  return handleAPIRequest<CreateUpdateEventResponse>(`${API_PUBLIC}/updateUserEvent`, { event });
};

export const cancelEvent = async (event: CancelEventRequestType): Promise<void> => {
  return handleAPIRequest<void>(`${API_PUBLIC}/cancelEvent`, { event, cancelReasons });
};

export const createJourneyEvent = async (request: CreateJourneyEventRequest): Promise<void> => {
  return handleAPIRequest<void>(`${API_PUBLIC}/upsertJourneyEvent`, request);
};

