import {
  ConfirmationsPageInput,
  CustomFieldInput,
  DateRangeInput,
  LocationType,
  NotificationsInput,
  TimeInput,
  BookingTemplateWhatInput,
  WhereInput,
  WhenInput,
  UpdateBookingTemplateInput,
  BookingPageLabelsInput,
  BookingCalendarInput,
  BookingPageStyleInput,
} from '../../API';
import { AppThunk } from '../rootStore';
import { bookingTemplatesSelectors } from './selectors';
import { cloneModalActions } from './modal';
import { AccordionIndexes, FilterType } from './types';
import { ImageType, UploadImageRequestPayload, UploadImageSuccessPayload } from '../../types/types';
import { CLONE_NAME_POSTFIX } from '../../types/constants';

export enum BookingTemplatesActionTypes {
  GET_BOOKING_TEMPLATES_REQUEST = 'BookingTemplates/GET_BOOKING_TEMPLATES_REQUEST',
  GET_BOOKING_TEMPLATES_SUCCESS = 'BookingTemplates/GET_BOOKING_TEMPLATES_SUCCESS',
  GET_BOOKING_TEMPLATES_FAIL = 'BookingTemplates/GET_BOOKING_TEMPLATES_FAIL',
  GET_BOOKING_TEMPLATE_REQUEST = 'BookingTemplates/GET_BOOKING_TEMPLATE_REQUEST',
  GET_BOOKING_TEMPLATE_SUCCESS = 'BookingTemplates/GET_BOOKING_TEMPLATE_SUCCESS',
  GET_BOOKING_TEMPLATE_FAIL = 'BookingTemplates/GET_BOOKING_TEMPLATE_FAIL',
  CREATE_BOOKING_TEMPLATE_REQUEST = 'BookingTemplates/CREATE_BOOKING_TEMPLATE_REQUEST',
  CREATE_BOOKING_TEMPLATE_SUCCESS = 'BookingTemplates/CREATE_BOOKING_TEMPLATE_SUCCESS',
  CREATE_BOOKING_TEMPLATE_FAIL = 'BookingTemplates/CREATE_BOOKING_TEMPLATE_FAIL',
  CLONE_BOOKING_TEMPLATE_REQUEST = 'BookingTemplates/CLONE_BOOKING_TEMPLATE_REQUEST',
  CLONE_BOOKING_TEMPLATE_SUCCESS = 'BookingTemplates/CLONE_BOOKING_TEMPLATE_SUCCESS',
  CLONE_BOOKING_TEMPLATE_FAIL = 'BookingTemplates/CLONE_BOOKING_TEMPLATE_FAIL',
  SAVE_BOOKING_TEMPLATE_REQUEST = 'BookingTemplates/SAVE_BOOKING_TEMPLATE_REQUEST',
  SAVE_BOOKING_TEMPLATE_SUCCESS = 'BookingTemplates/SAVE_BOOKING_TEMPLATE_SUCCESS',
  SAVE_BOOKING_TEMPLATE_FAIL = 'BookingTemplates/SAVE_BOOKING_TEMPLATE_FAIL',
  ENABLE_BOOKING_TEMPLATE_REQUEST = 'BookingTemplates/ENABLE_BOOKING_TEMPLATE_REQUEST',
  ENABLE_BOOKING_TEMPLATE_SUCCESS = 'BookingTemplates/ENABLE_BOOKING_TEMPLATE_SUCCESS',
  ENABLE_BOOKING_TEMPLATE_FAIL = 'BookingTemplates/ENABLE_BOOKING_TEMPLATE_FAIL',
  DELETE_BOOKING_TEMPLATES_REQUEST = 'BookingTemplates/DELETE_BOOKING_TEMPLATES_REQUEST',
  DELETE_BOOKING_TEMPLATES_SUCCESS = 'BookingTemplates/DELETE_BOOKING_TEMPLATES_SUCCESS',
  DELETE_BOOKING_TEMPLATES_FAIL = 'BookingTemplates/DELETE_BOOKING_TEMPLATES_FAIL',
  SELECT_BOOKING_TEMPLATE = 'BookingTemplates/SELECT_BOOKING_TEMPLATE',
  UNSELECT_BOOKING_TEMPLATE = 'BookingTemplates/UNSELECT_BOOKING_TEMPLATE',
  UNSELECT_ALL_BOOKING_TEMPLATE = 'BookingTemplates/UNSELECT_ALL_BOOKING_TEMPLATE',
  SET_BOOKING_TEMPLATE = 'BookingTemplates/SET_BOOKING_TEMPLATE',
  ADD_BOOKING_TEMPLATE_CUSTOM_FIELD = 'BookingTemplates/ADD_BOOKING_TEMPLATE_CUSTOM_FIELD',
  SET_BOOKING_TEMPLATE_FIELDS = 'BookingTemplates/SET_BOOKING_TEMPLATE_FIELDS',
  REMOVE_BOOKING_TEMPLATE_CUSTOM_FIELD = 'BookingTemplates/REMOVE_BOOKING_TEMPLATE_CUSTOM_FIELD',
  UPDATE_BOOKING_TEMPLATE_CUSTOM_FIELD = 'BookingTemplates/UPDATE_BOOKING_TEMPLATE_CUSTOM_FIELD',
  CLEAR_BOOKING_TEMPLATE = 'BookingTemplates/CLEAR_BOOKING_TEMPLATE',
  UPDATE_LINK = 'BookingTemplates/UPDATE_LINK',
  UPDATE_WHAT_STEP = 'BookingTemplates/UPDATE_WHAT_STEP',
  UPDATE_POTENTIAL_HOSTS = 'BookingTemplates/UPDATE_POTENTIAL_HOSTS',
  UPDATE_POTENTIAL_TEAMS = 'BookingTemplates/UPDATE_POTENTIAL_TEAMS',
  UPDATE_WHERE_STEP = 'BookingTemplates/UPDATE_WHERE_STEP',
  TOGGLE_WHERE_LOCATION_TYPE = 'BookingTemplates/TOGGLE_WHERE_LOCATION_TYPE',
  UPDATE_WHEN_STEP = 'BookingTemplates/UPDATE_WHEN_STEP',
  UPDATE_WHEN_DURATION = 'BookingTemplates/UPDATE_WHEN_DURATION',
  UPDATE_WHEN_DATE_RANGE = 'BookingTemplates/UPDATE_WHEN_DATE_RANGE',
  UPDATE_WHEN_SCHEDULE_BUFFER = 'BookingTemplates/UPDATE_WHEN_SCHEDULE_BUFFER',
  UPDATE_NOTIFICATIONS_STEP = 'BookingTemplates/UPDATE_NOTIFICATIONS_STEP',
  UPDATE_CONFIRMATION_STEP = 'BookingTemplates/UPDATE_CONFIRMATION_STEP',
  UPDATE_RECORD = 'BookingTemplates/UPDATE_RECORD',
  UPDATE_HOW_STEP = 'BookingTemplates/UPDATE_HOW_STEP',
  UPDATE_CALENDAR_STEP = 'BookingTemplates/UPDATE_CALENDAR_STEP',
  UPDATE_STYLE_STEP = 'BookingTemplates/UPDATE_STYLE_STEP',
  UPLOAD_AVATAR_FILE_REQUEST = 'BookingTemplates/UPLOAD_AVATAR_FILE_REQUEST',
  UPLOAD_AVATAR_FILE_SUCCESS = 'BookingTemplates/UPLOAD_AVATAR_FILE_SUCCESS',
  UPLOAD_AVATAR_FILE_FAIL = 'BookingTemplates/UPLOAD_AVATAR_FILE_FAIL',
  UPLOAD_BACKGROUND_FILE_REQUEST = 'BookingTemplates/UPLOAD_BACKGROUND_FILE_REQUEST',
  UPLOAD_BACKGROUND_FILE_SUCCESS = 'BookingTemplates/UPLOAD_BACKGROUND_FILE_SUCCESS',
  UPLOAD_BACKGROUND_FILE_FAIL = 'BookingTemplates/UPLOAD_BACKGROUND_FILE_FAIL',
  RESET_IMAGE = 'BookingTemplates/RESET_IMAGE',
  CLEAR_IMAGE = 'BookingTemplates/CLEAR_IMAGE',
  SELECT_TIME_ZONES = 'BookingTemplates/SELECT_TIME_ZONES',
  SET_FILTER = 'BookingTemplates/SET_FILTER',
  SET_CLONE_NAME = 'BookingTemplates/SET_CLONE_NAME',
  UPDATE_SMART_ALERTS = 'BookingTemplates/UPDATE_SMART_ALERTS',
  UPDATE_ACCORDION_INDEXES = 'BookingTemplates/UPDATE_ACCORDION_INDEXES',
}

export type BookingTemplatesAction =
  | { type: BookingTemplatesActionTypes.GET_BOOKING_TEMPLATES_REQUEST }
  | { type: BookingTemplatesActionTypes.GET_BOOKING_TEMPLATES_SUCCESS; payload: UpdateBookingTemplateInput[] }
  | { type: BookingTemplatesActionTypes.GET_BOOKING_TEMPLATES_FAIL; error: unknown }
  | { type: BookingTemplatesActionTypes.GET_BOOKING_TEMPLATE_REQUEST; payload: string }
  | { type: BookingTemplatesActionTypes.GET_BOOKING_TEMPLATE_SUCCESS; payload: UpdateBookingTemplateInput }
  | { type: BookingTemplatesActionTypes.GET_BOOKING_TEMPLATE_FAIL; error: unknown }
  | { type: BookingTemplatesActionTypes.CREATE_BOOKING_TEMPLATE_REQUEST }
  | { type: BookingTemplatesActionTypes.CREATE_BOOKING_TEMPLATE_SUCCESS }
  | { type: BookingTemplatesActionTypes.CREATE_BOOKING_TEMPLATE_FAIL; error: unknown }
  | { type: BookingTemplatesActionTypes.CLONE_BOOKING_TEMPLATE_REQUEST }
  | { type: BookingTemplatesActionTypes.CLONE_BOOKING_TEMPLATE_SUCCESS }
  | { type: BookingTemplatesActionTypes.CLONE_BOOKING_TEMPLATE_FAIL; error: unknown }
  | { type: BookingTemplatesActionTypes.SAVE_BOOKING_TEMPLATE_REQUEST }
  | { type: BookingTemplatesActionTypes.SAVE_BOOKING_TEMPLATE_SUCCESS }
  | { type: BookingTemplatesActionTypes.SAVE_BOOKING_TEMPLATE_FAIL; error: unknown }
  | { type: BookingTemplatesActionTypes.ENABLE_BOOKING_TEMPLATE_REQUEST; payload: UpdateBookingTemplateInput }
  | { type: BookingTemplatesActionTypes.ENABLE_BOOKING_TEMPLATE_SUCCESS }
  | { type: BookingTemplatesActionTypes.ENABLE_BOOKING_TEMPLATE_FAIL; error: unknown }
  | { type: BookingTemplatesActionTypes.DELETE_BOOKING_TEMPLATES_REQUEST }
  | { type: BookingTemplatesActionTypes.DELETE_BOOKING_TEMPLATES_SUCCESS }
  | { type: BookingTemplatesActionTypes.DELETE_BOOKING_TEMPLATES_FAIL; error: unknown }
  | { type: BookingTemplatesActionTypes.SELECT_BOOKING_TEMPLATE; payload: string }
  | { type: BookingTemplatesActionTypes.UNSELECT_BOOKING_TEMPLATE; payload: string }
  | { type: BookingTemplatesActionTypes.UNSELECT_ALL_BOOKING_TEMPLATE }
  | { type: BookingTemplatesActionTypes.SET_BOOKING_TEMPLATE; payload: UpdateBookingTemplateInput }
  | { type: BookingTemplatesActionTypes.ADD_BOOKING_TEMPLATE_CUSTOM_FIELD; payload: CustomFieldInput }
  | { type: BookingTemplatesActionTypes.SET_BOOKING_TEMPLATE_FIELDS; payload: (CustomFieldInput | null)[] }
  | { type: BookingTemplatesActionTypes.REMOVE_BOOKING_TEMPLATE_CUSTOM_FIELD; payload: string }
  | { type: BookingTemplatesActionTypes.UPDATE_BOOKING_TEMPLATE_CUSTOM_FIELD; payload: CustomFieldInput }
  | { type: BookingTemplatesActionTypes.CLEAR_BOOKING_TEMPLATE }
  | { type: BookingTemplatesActionTypes.UPDATE_LINK; payload: string }
  | { type: BookingTemplatesActionTypes.UPDATE_WHAT_STEP; payload: Partial<BookingTemplateWhatInput> }
  | { type: BookingTemplatesActionTypes.UPDATE_POTENTIAL_HOSTS; payload: string[] }
  | { type: BookingTemplatesActionTypes.UPDATE_POTENTIAL_TEAMS; payload: string[] }
  | { type: BookingTemplatesActionTypes.UPDATE_WHERE_STEP; payload: Partial<WhereInput> }
  | { type: BookingTemplatesActionTypes.TOGGLE_WHERE_LOCATION_TYPE; payload: LocationType }
  | { type: BookingTemplatesActionTypes.UPDATE_WHEN_STEP; payload: Partial<WhenInput> }
  | { type: BookingTemplatesActionTypes.UPDATE_WHEN_DURATION; payload: Partial<TimeInput> }
  | { type: BookingTemplatesActionTypes.UPDATE_WHEN_DATE_RANGE; payload: Partial<DateRangeInput> }
  | { type: BookingTemplatesActionTypes.UPDATE_WHEN_SCHEDULE_BUFFER; payload: Partial<TimeInput> }
  | { type: BookingTemplatesActionTypes.UPDATE_NOTIFICATIONS_STEP; payload: Partial<NotificationsInput> }
  | { type: BookingTemplatesActionTypes.UPDATE_CONFIRMATION_STEP; payload: Partial<ConfirmationsPageInput> }
  | { type: BookingTemplatesActionTypes.UPDATE_RECORD; payload: Partial<UpdateBookingTemplateInput> }
  | { type: BookingTemplatesActionTypes.UPDATE_HOW_STEP; payload: Partial<BookingPageLabelsInput> }
  | { type: BookingTemplatesActionTypes.UPDATE_CALENDAR_STEP; payload: Partial<BookingCalendarInput> }
  | { type: BookingTemplatesActionTypes.UPDATE_STYLE_STEP; payload: Partial<BookingPageStyleInput> }
  | { type: BookingTemplatesActionTypes.UPLOAD_AVATAR_FILE_REQUEST; payload: UploadImageRequestPayload }
  | { type: BookingTemplatesActionTypes.UPLOAD_AVATAR_FILE_SUCCESS; payload: UploadImageSuccessPayload }
  | { type: BookingTemplatesActionTypes.UPLOAD_AVATAR_FILE_FAIL; error: unknown }
  | { type: BookingTemplatesActionTypes.UPLOAD_BACKGROUND_FILE_REQUEST; payload: UploadImageRequestPayload }
  | { type: BookingTemplatesActionTypes.UPLOAD_BACKGROUND_FILE_SUCCESS; payload: UploadImageSuccessPayload }
  | { type: BookingTemplatesActionTypes.UPLOAD_BACKGROUND_FILE_FAIL; error: unknown }
  | { type: BookingTemplatesActionTypes.RESET_IMAGE; payload: ImageType }
  | { type: BookingTemplatesActionTypes.CLEAR_IMAGE; payload: ImageType }
  | { type: BookingTemplatesActionTypes.SELECT_TIME_ZONES; payload: string[] }
  | { type: BookingTemplatesActionTypes.SET_FILTER; payload: FilterType }
  | { type: BookingTemplatesActionTypes.SET_CLONE_NAME; payload: string }
  | { type: BookingTemplatesActionTypes.UPDATE_SMART_ALERTS; payload: string }
  | { type: BookingTemplatesActionTypes.UPDATE_ACCORDION_INDEXES; payload: Partial<AccordionIndexes> };

const getBookingTemplatesRequest = (): BookingTemplatesAction => ({
  type: BookingTemplatesActionTypes.GET_BOOKING_TEMPLATES_REQUEST,
});
const getBookingTemplatesSuccess = (payload: UpdateBookingTemplateInput[]): BookingTemplatesAction => ({
  type: BookingTemplatesActionTypes.GET_BOOKING_TEMPLATES_SUCCESS,
  payload,
});
const getBookingTemplatesFail = (error: unknown): BookingTemplatesAction => ({
  type: BookingTemplatesActionTypes.GET_BOOKING_TEMPLATES_FAIL,
  error,
});
const getBookingTemplateRequest = (payload: string): BookingTemplatesAction => ({
  type: BookingTemplatesActionTypes.GET_BOOKING_TEMPLATE_REQUEST,
  payload,
});
const getBookingTemplateSuccess = (payload: UpdateBookingTemplateInput): BookingTemplatesAction => ({
  type: BookingTemplatesActionTypes.GET_BOOKING_TEMPLATE_SUCCESS,
  payload,
});
const getBookingTemplateFail = (error: unknown): BookingTemplatesAction => ({
  type: BookingTemplatesActionTypes.GET_BOOKING_TEMPLATE_FAIL,
  error,
});
const createBookingTemplateRequest = (): BookingTemplatesAction => ({
  type: BookingTemplatesActionTypes.CREATE_BOOKING_TEMPLATE_REQUEST,
});
const createBookingTemplatesuccess = (): BookingTemplatesAction => ({
  type: BookingTemplatesActionTypes.CREATE_BOOKING_TEMPLATE_SUCCESS,
});
const createBookingTemplateFail = (error: unknown): BookingTemplatesAction => ({
  type: BookingTemplatesActionTypes.CREATE_BOOKING_TEMPLATE_FAIL,
  error,
});
const cloneBookingTemplateRequest = (): BookingTemplatesAction => ({
  type: BookingTemplatesActionTypes.CLONE_BOOKING_TEMPLATE_REQUEST,
});
const cloneBookingTemplatesuccess = (): BookingTemplatesAction => ({
  type: BookingTemplatesActionTypes.CLONE_BOOKING_TEMPLATE_SUCCESS,
});
const cloneBookingTemplateFail = (error: unknown): BookingTemplatesAction => ({
  type: BookingTemplatesActionTypes.CLONE_BOOKING_TEMPLATE_FAIL,
  error,
});
const saveBookingTemplateRequest = (): BookingTemplatesAction => ({
  type: BookingTemplatesActionTypes.SAVE_BOOKING_TEMPLATE_REQUEST,
});
const saveBookingTemplatesuccess = (): BookingTemplatesAction => ({
  type: BookingTemplatesActionTypes.SAVE_BOOKING_TEMPLATE_SUCCESS,
});
const saveBookingTemplateFail = (error: unknown): BookingTemplatesAction => ({
  type: BookingTemplatesActionTypes.SAVE_BOOKING_TEMPLATE_FAIL,
  error,
});
const enableBookingTemplateRequest = (payload: UpdateBookingTemplateInput): BookingTemplatesAction => ({
  type: BookingTemplatesActionTypes.ENABLE_BOOKING_TEMPLATE_REQUEST,
  payload,
});
const enableBookingTemplatesuccess = (): BookingTemplatesAction => ({
  type: BookingTemplatesActionTypes.ENABLE_BOOKING_TEMPLATE_SUCCESS,
});
const enableBookingTemplateFail = (error: unknown): BookingTemplatesAction => ({
  type: BookingTemplatesActionTypes.ENABLE_BOOKING_TEMPLATE_FAIL,
  error,
});
const deleteBookingTemplatesRequest = (): BookingTemplatesAction => ({
  type: BookingTemplatesActionTypes.DELETE_BOOKING_TEMPLATES_REQUEST,
});
const deleteBookingTemplatesSuccess = (): BookingTemplatesAction => ({
  type: BookingTemplatesActionTypes.DELETE_BOOKING_TEMPLATES_SUCCESS,
});
const deleteBookingTemplatesFail = (error: unknown): BookingTemplatesAction => ({
  type: BookingTemplatesActionTypes.DELETE_BOOKING_TEMPLATES_FAIL,
  error,
});
const selectBookingTemplate = (payload: string): BookingTemplatesAction => ({
  type: BookingTemplatesActionTypes.SELECT_BOOKING_TEMPLATE,
  payload,
});
const unselectBookingTemplate = (payload: string): BookingTemplatesAction => ({
  type: BookingTemplatesActionTypes.UNSELECT_BOOKING_TEMPLATE,
  payload,
});
const unselectAllBookingTemplate = (): BookingTemplatesAction => ({
  type: BookingTemplatesActionTypes.UNSELECT_ALL_BOOKING_TEMPLATE,
});
const setBookingTemplate = (payload: UpdateBookingTemplateInput): BookingTemplatesAction => ({
  type: BookingTemplatesActionTypes.SET_BOOKING_TEMPLATE,
  payload,
});
const addCustomField = (payload: CustomFieldInput): BookingTemplatesAction => ({
  type: BookingTemplatesActionTypes.ADD_BOOKING_TEMPLATE_CUSTOM_FIELD,
  payload,
});
const setCustomFields = (payload: (CustomFieldInput | null)[]): BookingTemplatesAction => ({
  type: BookingTemplatesActionTypes.SET_BOOKING_TEMPLATE_FIELDS,
  payload,
});
const removeCustomField = (payload: string): BookingTemplatesAction => ({
  type: BookingTemplatesActionTypes.REMOVE_BOOKING_TEMPLATE_CUSTOM_FIELD,
  payload,
});
const updateCustomField = (payload: CustomFieldInput): BookingTemplatesAction => ({
  type: BookingTemplatesActionTypes.UPDATE_BOOKING_TEMPLATE_CUSTOM_FIELD,
  payload,
});
const clearBookingTemplate = (): BookingTemplatesAction => ({
  type: BookingTemplatesActionTypes.CLEAR_BOOKING_TEMPLATE,
});

const updateLink = (payload: string): BookingTemplatesAction => ({
  type: BookingTemplatesActionTypes.UPDATE_LINK,
  payload,
});
const updateWhatStep = (payload: Partial<BookingTemplateWhatInput>): BookingTemplatesAction => ({
  type: BookingTemplatesActionTypes.UPDATE_WHAT_STEP,
  payload,
});
const updatePotentialHosts = (payload: string[]): BookingTemplatesAction => ({
  type: BookingTemplatesActionTypes.UPDATE_POTENTIAL_HOSTS,
  payload,
});
const updatePotentialTeams = (payload: string[]): BookingTemplatesAction => ({
  type: BookingTemplatesActionTypes.UPDATE_POTENTIAL_TEAMS,
  payload,
});
const updateWhereStep = (payload: Partial<WhereInput>): BookingTemplatesAction => ({
  type: BookingTemplatesActionTypes.UPDATE_WHERE_STEP,
  payload,
});
const toggleWhereLocationType = (payload: LocationType): BookingTemplatesAction => ({
  type: BookingTemplatesActionTypes.TOGGLE_WHERE_LOCATION_TYPE,
  payload,
});
const updateWhenStep = (payload: Partial<WhenInput>): BookingTemplatesAction => ({
  type: BookingTemplatesActionTypes.UPDATE_WHEN_STEP,
  payload,
});
const updateWhenDuration = (payload: Partial<TimeInput>): BookingTemplatesAction => ({
  type: BookingTemplatesActionTypes.UPDATE_WHEN_DURATION,
  payload,
});
const updateWhenDateRange = (payload: Partial<DateRangeInput>): BookingTemplatesAction => ({
  type: BookingTemplatesActionTypes.UPDATE_WHEN_DATE_RANGE,
  payload,
});
const updateWhenScheduleBuffer = (payload: Partial<TimeInput>): BookingTemplatesAction => ({
  type: BookingTemplatesActionTypes.UPDATE_WHEN_SCHEDULE_BUFFER,
  payload,
});
const updateNotificationsStep = (payload: Partial<NotificationsInput>): BookingTemplatesAction => ({
  type: BookingTemplatesActionTypes.UPDATE_NOTIFICATIONS_STEP,
  payload,
});
const updateConfirmationStep = (payload: Partial<ConfirmationsPageInput>): BookingTemplatesAction => ({
  type: BookingTemplatesActionTypes.UPDATE_CONFIRMATION_STEP,
  payload,
});
const updateRecord = (payload: Partial<UpdateBookingTemplateInput>): BookingTemplatesAction => ({
  type: BookingTemplatesActionTypes.UPDATE_RECORD,
  payload,
});
const updateHowStep = (payload: Partial<BookingPageLabelsInput>): BookingTemplatesAction => ({
  type: BookingTemplatesActionTypes.UPDATE_HOW_STEP,
  payload,
});
const updateCalendarStep = (payload: Partial<BookingCalendarInput>): BookingTemplatesAction => ({
  type: BookingTemplatesActionTypes.UPDATE_CALENDAR_STEP,
  payload,
});
const updateStyleStep = (payload: Partial<BookingPageStyleInput>): BookingTemplatesAction => ({
  type: BookingTemplatesActionTypes.UPDATE_STYLE_STEP,
  payload,
});
const uploadAvatarFileRequest = (payload: UploadImageRequestPayload): BookingTemplatesAction => ({
  type: BookingTemplatesActionTypes.UPLOAD_AVATAR_FILE_REQUEST,
  payload,
});
const uploadAvatarFileSuccess = (payload: UploadImageSuccessPayload): BookingTemplatesAction => ({
  type: BookingTemplatesActionTypes.UPLOAD_AVATAR_FILE_SUCCESS,
  payload,
});
const uploadAvatarFileFail = (error: unknown): BookingTemplatesAction => ({
  type: BookingTemplatesActionTypes.UPLOAD_AVATAR_FILE_FAIL,
  error,
});
const uploadBackgroundFileRequest = (payload: UploadImageRequestPayload): BookingTemplatesAction => ({
  type: BookingTemplatesActionTypes.UPLOAD_BACKGROUND_FILE_REQUEST,
  payload,
});
const uploadBackgroundFileSuccess = (payload: UploadImageSuccessPayload): BookingTemplatesAction => ({
  type: BookingTemplatesActionTypes.UPLOAD_BACKGROUND_FILE_SUCCESS,
  payload,
});
const uploadBackgroundFileFail = (error: unknown): BookingTemplatesAction => ({
  type: BookingTemplatesActionTypes.UPLOAD_BACKGROUND_FILE_FAIL,
  error,
});
const resetImage = (payload: ImageType): BookingTemplatesAction => ({
  type: BookingTemplatesActionTypes.RESET_IMAGE,
  payload,
});
const clearImage = (payload: ImageType): BookingTemplatesAction => ({
  type: BookingTemplatesActionTypes.CLEAR_IMAGE,
  payload,
});
const setFilter = (payload: FilterType): BookingTemplatesAction => ({
  type: BookingTemplatesActionTypes.SET_FILTER,
  payload,
});
const setCloneName = (payload: string): BookingTemplatesAction => ({
  type: BookingTemplatesActionTypes.SET_CLONE_NAME,
  payload,
});
const selectTimeZones = (payload: string[]): BookingTemplatesAction => ({
  type: BookingTemplatesActionTypes.SELECT_TIME_ZONES,
  payload,
});
const updateAccordionIndexes = (payload: Partial<AccordionIndexes>): BookingTemplatesAction => ({
  type: BookingTemplatesActionTypes.UPDATE_ACCORDION_INDEXES,
  payload,
});

// TODO: move to /thunks please
const cloneBookingTemplateThunk = (): AppThunk => (dispatch, getState) => {
  const state = getState();
  const name = bookingTemplatesSelectors.selectWhatName(state) + CLONE_NAME_POSTFIX;
  dispatch(setCloneName(name));
  dispatch(cloneModalActions.openModal());
};

const updateSmartAlerts = (payload: string): BookingTemplatesAction => ({
  type: BookingTemplatesActionTypes.UPDATE_SMART_ALERTS,
  payload,
});

export const bookingTemplatesActions = {
  getBookingTemplatesRequest,
  getBookingTemplatesSuccess,
  getBookingTemplatesFail,
  getBookingTemplateRequest,
  getBookingTemplateSuccess,
  getBookingTemplateFail,
  createBookingTemplateRequest,
  createBookingTemplatesuccess,
  createBookingTemplateFail,
  cloneBookingTemplateRequest,
  cloneBookingTemplatesuccess,
  cloneBookingTemplateFail,
  saveBookingTemplateRequest,
  saveBookingTemplatesuccess,
  saveBookingTemplateFail,
  enableBookingTemplateRequest,
  enableBookingTemplatesuccess,
  enableBookingTemplateFail,
  deleteBookingTemplatesRequest,
  deleteBookingTemplatesSuccess,
  deleteBookingTemplatesFail,
  selectBookingTemplate,
  unselectBookingTemplate,
  unselectAllBookingTemplate,
  setBookingTemplate,
  addCustomField,
  setCustomFields,
  removeCustomField,
  updateCustomField,
  clearBookingTemplate,
  updateLink,
  updateWhatStep,
  updatePotentialHosts,
  updatePotentialTeams,
  updateWhereStep,
  toggleWhereLocationType,
  updateWhenStep,
  updateWhenDuration,
  updateWhenDateRange,
  updateWhenScheduleBuffer,
  updateNotificationsStep,
  updateConfirmationStep,
  updateRecord,
  updateHowStep,
  updateCalendarStep,
  updateStyleStep,
  uploadAvatarFileRequest,
  uploadAvatarFileSuccess,
  uploadAvatarFileFail,
  uploadBackgroundFileRequest,
  uploadBackgroundFileSuccess,
  uploadBackgroundFileFail,
  resetImage,
  clearImage,
  setFilter,
  setCloneName,
  selectTimeZones,
  updateAccordionIndexes,
  cloneBookingTemplateThunk,
  updateSmartAlerts,
};
