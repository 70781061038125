import { t } from '../../../i18n/i18n';

export default {
  noResults: t('Common:NO_RESULTS_FOUND'),
  emptyList: t('Common:EMPTY_LIST'),
  emptyListDesc: t('Locations:EMPTY_LIST_DESCRIPTION'),
  page: t('Table:PAGE'),
  of: t('Table:OF'),
  outOf: t('Table:OUT_OF'),
};
