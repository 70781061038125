import { t } from '../../../i18n/i18n';

export default {
  addNote: t('BookedMeetings:ADD_NOTE'),
  attendees: t('BookedMeetings:ATTENDEES'),
  bookingPage: t('BookedMeetings:BOOKING_PAGE'),
  cancel: t('Common:CANCEL'),
  canceled: t('BookedMeetings:CANCELED'),
  cancelReason: t('BookedMeetings:CANCEL_REASON'),
  description: t('Common:DESCRIPTION'),
  details: t('BookedMeetings:DETAILS'),
  editAttendeeLimit: t('BookedMeetings:EDIT_ATTENDEE_LIMIT'),
  email: t('Common:EMAIL'),
  guest: t('BookedMeetings:GUEST'),
  guestAnswers: t('BookedMeetings:GUEST_ANSWERS'),
  hosts: t('BookedMeetings:HOSTS'),
  locationTitle: t('BookedMeetings:LOCATION_TITLE'),
  markAsNoShow: t('BookedMeetings:MARK_AS_NO_SHOW'),
  markAsNoShowTooltip: t('BookedMeetings:MARK_AS_NO_SHOW_TOOLTIP'),
  internalNote: t('BookedMeetings:INTERNAL_MEETING_NOTES'),
  internalNoteDescription: t('BookedMeetings:INTERNAL_MEETING_NOTES_DESC'),
  no: t('Common:NO'),
  noAnswers: t('BookedMeetings:NO_ANSWERS'),
  noAttendees: t('BookedMeetings:NO_ATTENDEES'),
  noNote: t('BookedMeetings:NO_NOTE'),
  noLocationGiven: t('BookedMeetings:NO_LOCATION_GIVEN'),
  noShowLabel: t('BookedMeetings:NO_SHOW_LABEL'),
  noteFromInvitee: t('BookedMeetings:NOTE_FROM_INVITEE'),
  reschedule: t('BookedMeetings:RESCHEDULE'),
  timeZone: t('Common:TIMEZONE'),
  undoNoShow: t('BookedMeetings:UNDO_NO_SHOW'),
  yes: t('Common:YES'),
};
