import { call, put, select, takeLatest } from 'redux-saga/effects';
import { RolesActionTypes, rolesActions } from './actions';
import { authenticationSelectors } from '../authentication';
import { deleteRole, getRole, getRoles, saveRole } from './service';
import { rolesSelectors } from './selectors';
import { editRoleModalActions } from './modal';
import { notificationsActions } from '../notifications';
import {
  GET_ROLES_FAIL_TOAST,
  SAVE_ROLE_SUCCESS_TOAST,
  SAVE_ROLE_ERROR_TOAST,
  DELETE_ROLE_SUCCESS_TOAST,
  DELETE_ROLE_ERROR_TOAST,
  ACTIVATE_ROLE_SUCCESS_TOAST,
  DEACTIVATE_ROLE_SUCCESS_TOAST,
  DEACTIVATE_ACTIVATE_ROLE_ERROR_TOAST,
  GET_ROLE_FAIL_TOAST,
} from './constants';
import { CreateRoleInput, Role } from '../../API';
import { createSelector } from 'reselect';
import { handleServiceError } from '../utils/reduxUtils';
import { RoleResponse } from './types';
import { usersActions, usersSelectors } from '../users';

const selectSaveRoleRequest = createSelector(
  rolesSelectors.selectSelectedRole,
  authenticationSelectors.selectTenantId,
  (role, tenantId) => ({
    id: role.id,
    tenantId: role.tenantId ? role.tenantId : tenantId,
    name: role.name,
    isActive: role.isActive,
    isCustom: role.isCustom,
    hasChromeAccess: role.hasChromeAccess,
    users: role.users,
    availability: role.availability,
    myBookingPages: role.myBookingPages,
    allBookingPages: role.allBookingPages,
    bookingTemplates: role.bookingTemplates,
    resources: role.resources,
    isAccessToAllWorkspaces: role.isAccessToAllWorkspaces,
    workspaceAdminActions: role.workspaceAdminActions,
    teams: role.teams,
    smartAlerts: role.smartAlerts,
    smartAlertTemplates: role.smartAlertTemplates,
    locations: role.locations,
    journey: role.journey,
  })
);

function* getRolesSaga() {
  try {
    const prevRoles: Role[] = yield select(rolesSelectors.selectRoles);
    const roles: Role[] = yield call(getRoles);
    // if the list of roles updated
    if (prevRoles.length && prevRoles.length !== roles.length) {
      const usersRolesFilter: string[] = yield select(usersSelectors.selectFilterRoles);
      // and the default role filter is in use on Users page
      if (usersRolesFilter.length === prevRoles.length) {
        yield put(usersActions.setFilter({ roles: roles.map((role) => role.id) }));
      }
    }

    if (roles && roles.length > 0) {
      yield put(rolesActions.getRolesSuccess(roles));
    } else {
      throw new Error('Roles not found');
    }
  } catch (error: unknown) {
    yield put(rolesActions.getRolesFail(error?.toString()));
    yield call(handleServiceError, error, GET_ROLES_FAIL_TOAST, true);
  }
}

function* getRoleSaga(action: ReturnType<typeof rolesActions.getRoleRequest>) {
  try {
    if (action.type === RolesActionTypes.GET_ROLE_REQUEST) {
      const id = action.id;
      const response: RoleResponse = yield call(getRole, id);
      yield put(rolesActions.getRoleSuccess(response.role));
    }
  } catch (error: unknown) {
    yield put(rolesActions.getRoleFail(error?.toString()));
    yield call(handleServiceError, error, GET_ROLE_FAIL_TOAST, true);
  }
}

function* saveRoleSaga() {
  try {
    const role: CreateRoleInput = yield select(selectSaveRoleRequest);
    yield call(saveRole, role);

    yield put(editRoleModalActions.closeModal());
    yield put(rolesActions.saveRoleSuccess());
    yield put(rolesActions.getRolesRequest());
    yield put(notificationsActions.showToast(SAVE_ROLE_SUCCESS_TOAST));
  } catch (error: unknown) {
    yield put(rolesActions.saveRoleFail(error?.toString()));
    yield call(handleServiceError, error, SAVE_ROLE_ERROR_TOAST);
  }
}

function* deleteRoleSaga() {
  try {
    const role: Role = yield select(rolesSelectors.selectSelectedRole);
    yield call(deleteRole, role.tenantId, role.id);

    yield put(rolesActions.deleteRoleSuccess());
    yield put(rolesActions.getRolesRequest());
    yield put(notificationsActions.showToast(DELETE_ROLE_SUCCESS_TOAST));
  } catch (error: unknown) {
    yield put(rolesActions.deleteRoleFail(error?.toString()));
    yield call(handleServiceError, error, DELETE_ROLE_ERROR_TOAST);
  }
}

function* deactivateRoleSaga() {
  try {
    const role: CreateRoleInput = yield select(selectSaveRoleRequest);
    const isActive = !role.isActive;
    yield call(saveRole, { ...role, isActive });

    yield put(rolesActions.deactivateRoleSuccess());
    yield put(rolesActions.getRolesRequest());
    yield put(notificationsActions.showToast(isActive ? ACTIVATE_ROLE_SUCCESS_TOAST : DEACTIVATE_ROLE_SUCCESS_TOAST));
  } catch (error: unknown) {
    yield put(rolesActions.deactivateRoleFail(error?.toString()));
    yield call(handleServiceError, error, DEACTIVATE_ACTIVATE_ROLE_ERROR_TOAST);
  }
}

export function* watchRolesSaga() {
  yield takeLatest(RolesActionTypes.GET_ROLES_REQUEST, getRolesSaga);
  yield takeLatest(RolesActionTypes.GET_ROLE_REQUEST, getRoleSaga);
  yield takeLatest(RolesActionTypes.SAVE_ROLE_REQUEST, saveRoleSaga);
  yield takeLatest(RolesActionTypes.DELETE_ROLE_REQUEST, deleteRoleSaga);
  yield takeLatest(RolesActionTypes.DEACTIVATE_ROLE_REQUEST, deactivateRoleSaga);
}

export const rolesSagas = {
  getRoles: getRolesSaga,
};
