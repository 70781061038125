import { t } from '../../../i18n/i18n';

export default {
  title: t('BookedMeetings:TITLE'),
  bookingPage: t('BookedMeetings:BOOKING_PAGE'),
  bookingPagePlaceholder: t('BookedMeetings:BOOKING_PAGE_PLACEHOLDER'),
  date: t('Common:DATE'),
  dateRange: t('Common:DATE_RANGE'),
  export: t('Common:EXPORT'),
  filter: t('Common:FILTER'),
  no: t('Common:NO'),
  noLocationGiven: t('BookedMeetings:NO_LOCATION_GIVEN'),
  past: t('BookedMeetings:PAST'),
  status: t('BookedMeetings:STATUS'),
  statusPlaceholder: t('BookedMeetings:STATUS_PLACEHOLDER'),
  team: t('BookedMeetings:TEAM'),
  thisMonth: t('BookedMeetings:THIS_MONTH'),
  today: t('Common:TODAY'),
  upcoming: t('BookedMeetings:UPCOMING'),
  yes: t('Common:YES'),
  workspace: t('BookedMeetings:WORKSPACE'),
};
