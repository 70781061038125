export const EditRole = Object.freeze({
  ACCESS_TO_ALL_WORKSPACES: `Zugriff auf alle Arbeitsbereiche und Rollen`,
  ACTIVE: `Aktiv`,
  ADMIN_TITLE: `Administrator`,
  MY_BOOKING_PAGE: `Meine Buchungsseiten`,
  ALL_BOOKING_PAGE: `Alle Buchungsseiten`,
  BOOKING_TEMPLATES: `Buchungsvorlagen`,
  CHROME_EXTENSION_TITLE: `SUMO1 Posteingang (Chrome-Erweiterung)`,
  CREATE: `Erstellen`,
  DELETE: `Löschen`,
  EDIT: `Bearbeiten`,
  FEATURE_ACCESS_TITLE: `Zugriff auf Funktionen`,
  JOURNEY: `Reise-Builder`,
  LOCATIONS: `Standorte`,
  PERMISSIONS_TITLE: `Berechtigungen`,
  PRIMARY_TITLE: `Primär`,
  READ: `Lesen`,
  RESOURCES_TITLE: `Ressourcen (Standorte, Räume)`,
  ROLE_NAME: `Rollenname`,
  SEARCH_ADD_WORKSPACE: `Arbeitsbereich suchen und hinzufügen`,
  SMART_ALERT_TEMPLATES: `Intelligente Benachrichtigungsvorlagen`,
  STANDARD_USER: `Standardbenutzer`,
  SUPER_ADMIN: `Superadministrator`,
  TEAMS: `Teams`,
  EDIT_TITLE: 'Rolle bearbeiten',
  NEW_TITLE: 'Neue Rolle',
  WORKSPACE_ADMIN: `Arbeitsbereichsadministrator`,
  WORKSPACE_ADMIN_DESCRIPTION: `Standardmäßig kann ein Administrator mit dieser Rolle alle Arbeitsbereiche verwalten, es sei denn, Sie geben unten die spezifischen Arbeitsbereiche an, die er nur verwalten kann:`,
});

export const RoleToastsNotifications = Object.freeze({
  ACTIVATE_ROLE_SUCCESS_MESSAGE: `Rolle aktiviert`,
  DEACTIVATE_ACTIVATE_ROLE_ERROR_MESSAGE: `Aktivierungs-/Deaktivierungsanforderung fehlgeschlagen`,
  DEACTIVATE_ROLE_SUCCESS_MESSAGE: `Rolle deaktiviert`,
  DELETE_ROLE_ASSIGNED_ERROR_MESSAGE: `Kann nicht gelöscht werden. Benutzer sind diesem Rollen zugewiesen.`,
  DELETE_ROLE_ERROR_MESSAGE: `Löschanforderung fehlgeschlagen`,
  DELETE_ROLE_SUCCESS_MESSAGE: `Rolle erfolgreich gelöscht`,
  GET_ROLES_FAIL_MESSAGE: `Abruf der Rollen fehlgeschlagen`,
  GET_ROLE_FAIL_MESSAGE: `Abfrage der Rolle schlägt fehl`,
  SAVE_ROLE_ERROR_MESSAGE: `Speichern fehlgeschlagen`,
  SAVE_ROLE_SUCCESS_MESSAGE: `Rolle erfolgreich gespeichert`,
});

export const Roles = Object.freeze({
  ACTIVE: `Aktiv`,
  CUSTOM: `Benutzerdefiniert`,
  DEACTIVATE_DESCRIPTION: `Möchten Sie wirklich deaktivieren`,
  DELETE_DESCRIPTION: `Möchten Sie wirklich löschen`,
  DELETE_MESSAGE: `Benutzer werden nicht gelöscht, können jedoch den Zugriff verlieren und eine Rollenzuweisung erfordern.`,
  LAST_MODIFIED: `Zuletzt geändert`,
  LAST_MODIFIED_BY: `Zuletzt geändert von`,
  NEW_ROLE: `Neue Rolle`,
  ROLE: `Rolle`,
  TOOLTIP: `Verwalten Sie Rollen, um Benutzern die Berechtigung zum Anzeigen, Erstellen/Bearbeiten oder Löschen verschiedener Funktionen zu erteilen.`,
});
