import { createSelector } from 'reselect';
import { State } from '../rootStore';
import { IntegrationFilter } from './types';
import { INTEGRATION_CATEGORIES } from './constants';
import { AppType, IntegrationType, WorkspaceIntegrationType } from '../../API';
import { globalSelectors } from '../global';

const integrationState = (state: State) => state.integration;

const selectIsFetching = createSelector(integrationState, (state) => state.isFetching);
const selectIsError = createSelector(integrationState, (state) => !!state.error);
const selectIntegrationList = createSelector(integrationState, (state) => state.integration);
const selectIntegrationFilter = createSelector(integrationState, (state) => state.filter);
const selectConnectIntegration = createSelector(integrationState, (state) => state.connectIntegration);

const selectFilteredIntegrations = createSelector(
  selectIntegrationList,
  selectIntegrationFilter,
  globalSelectors.selectSearchString,
  (integrations, filter: IntegrationFilter, searchString) =>
    integrations.filter(
      (item) =>
        (filter === IntegrationFilter.ALL ||
          (INTEGRATION_CATEGORIES[filter] as (IntegrationType | WorkspaceIntegrationType | AppType)[]).includes(
            item.type
          )) &&
        item.label.toLowerCase().includes(searchString)
    )
);

const selectIsFilterInUse = createSelector(selectIntegrationFilter, (filter) => filter !== IntegrationFilter.ALL);

export const integrationSelectors = {
  selectIntegrationList,
  selectIsFetching,
  selectIsError,
  selectIntegrationFilter,
  selectConnectIntegration,
  selectFilteredIntegrations,
  selectIsFilterInUse,
};
