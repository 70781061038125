import { Handle } from '@xyflow/react';
import { HandleProps } from '@xyflow/system';
import { CSSProperties } from 'react';

type JourneyHandleProps = {
  style?: CSSProperties;
} & HandleProps;
export const JourneyHandle = ({ style, ...props }: JourneyHandleProps) => {
  return <Handle isConnectable={false} style={{ ...(style || { left: '50px' }), visibility: 'hidden' }} {...props} />;
};
