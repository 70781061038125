export const SmartAlerts = Object.freeze({
  TITLE: `Smart Alerts`,
  CREATE_ALERT: `Create Alert`,
  CLONE_ALERT_TITLE: `Cloned smart alert name`,
  CLONE_TEMPLATE_TITLE: `Cloned smart alert template name`,
  DIALOG_PLACEHOLDER: `Remainder to invitee, Send thank you, etc...`,
  EMPTY_LIST_MESSAGE: `Create an alert or adjust your search or filters.`,
  PERSONAL_LABEL: `Personal`,
  SHARED_LABEL: `Shared`,
  TEMPLATES_LABEL: `Templates`,
  HIDDEN_LABEL: `Hidden`,
  UNHIDDEN_LABEL: `Unhidden`,
  BASIC_ALERTS: `Basic Alerts`,
  ADD_SMART_ALERT: `+ Add Smart Alert`,
  DELETE_DESCRIPTION: `Are you sure you would like to delete`,
  BULK_DELETE_DESCRIPTION: `selected smart alerts`,
  DELETE_WARNING_ONE: `Warning: Deleting this Smart Alert will also remove it from the booking pages and booking templates where it is used.`,
  DELETE_WARNING_MULTIPLE: `Warning: Deleting these Smart Alerts will also remove them from the booking pages and booking templates where they are used.`,
});

export const SmartAlertsToastsNotifications = Object.freeze({
  GET_SMART_TYPES_ERROR_MESSAGE: `Get Smart Alerts request fail`,
  CLONE_SMART_TYPE_ERROR_MESSAGE: `Clone Smart Alerts request fail`,
  CLONE_SMART_ALERT_SUCCESS_MESSAGE: `Smart Alert successfully cloned`,
  CLONE_SMART_TEMPLATE_SUCCESS_MESSAGE: `Smart Alert Template successfully cloned`,
  DELETE_SMART_TYPES_SUCCESS_MESSAGE: `Smart Alerts successfully deleted`,
  DELETE_SMART_TYPES_ERROR_MESSAGE: `Delete Smart Alerts request fail`,
  GET_SMART_ALERT_ERROR_MESSAGE: `Get Smart Alert request fail`,
  GET_SMART_TEMPLATE_ERROR_MESSAGE: `Get Smart Alert Template request fail`,
  UPSERT_SMART_TYPE_ERROR_MESSAGE: `Upsert Smart Alerts request fail`,
  CREATE_SMART_ALERT_SUCCESS_MESSAGE: `Smart Alert successfully created`,
  CREATE_SMART_TEMPLATE_SUCCESS_MESSAGE: `Smart Alert Template successfully created`,
  UPDATE_SMART_ALERT_SUCCESS_MESSAGE: `Smart Alert successfully updated`,
  UPDATE_SMART_TEMPLATE_SUCCESS_MESSAGE: `Smart Alert Template successfully updated`,
});

export const EditSmartAlert = Object.freeze({
  CREATE_TITLE: `Create alert`,
  CREATE_CUSTOM_TITLE: `+ Create your custom alert`,
  DESCRIPTION: `Create your custom alert or use templates for quick alert creation`,
  EDIT_TITLE: `Edit alert`,
  ICON: `Icon`,
  NAME: `Name`,
  NAME_PLACEHOLDER: `Enter alert name`,
  HIDE: `Hide`,
  HIDE_DESCRIPTION: `Do not show in the Smart Alerts list`,
  ALERT: `Alert`,
  TEMPLATE: `Template`,
  APPLY_TO: `Apply to`,
  SHARE_WITH: `Share with`,
  WORKSPACES: `Workspaces`,
  WHEN_TITLE: `When this happens`,
  DO_THIS: `Do this`,
  PERSONALIZE: `Personalize`,
  ADD_VARIABLE: `Add variable`,
  SUBJECT: `Subject`,
  BODY: `Body`,
  USE_TEMPLATE: `Use Template`,
  HOW_LONG_AFTER: `How long after`,
  IMMEDIATELY: `Immediately`,
  CUSTOM_NAME: `Custom Name`,
  TEMPLATE_DESCRIPTION_PLACEHOLDER: `Enter smart template description`,
  USE_ALERT: `Use Alert`,
  SELECT_SMART_TITLE: `Select alert`,
  SELECT_ALERT_DESCRIPTION: `Select smart alert or create your custom`,
  BOOKING_PAGES: `Booking pages`,
  BOOKING_TEMPLATES: `Booking templates`,
  SMS_TO_HOST_TOOLTIP: `If the host doesn't have the phone number, the text reminder won't be sent`,
  NEEDS_YOUR_ATTENTION: `Needs your attention`,
});

export const SmartAlertsPersonalize = Object.freeze({
  EMAIL_TO_INVITE_SUBJECT: `Reminder: &ltMeeting Name&gt with &ltHost Name&gt at &ltMeeting Time&gt, &ltMeeting Date&gt`,
  EMAIL_TO_INVITE_BODY: `Hi &ltInvitee Name&gt,<br><br>This is a friendly reminder that your &ltMeeting Name&gt with &ltHost Name&gt is at &ltMeeting Time&gt, &ltMeeting Date&gt.<br><br><b>Location</b><br>&ltMeeting Location&gt<br><br>&ltView or Edit Meeting Link&gt`,
  EMAIL_TO_HOST_SUBJECT: `Reminder: &ltMeeting Name&gt with &ltInvitee Name&gt at &ltMeeting Time&gt, &ltMeeting Date&gt`,
  EMAIL_TO_HOST_BODY: `Hi &ltHost Name&gt,<br><br>This is a friendly reminder that your &ltMeeting Name&gt with &ltInvitee Name&gt is at &ltMeeting Time&gt, &ltMeeting Date&gt.<br><br><b>Location</b><br>&ltMeeting Location&gt<br><br>&ltView or Edit Meeting Link&gt`,
  SMS_TO_INVITE: `This is a friendly reminder that your &ltMeeting Name&gt with &ltHost Name&gt is at &ltMeeting Time&gt, &ltMeeting Date&gt. View or edit meeting &ltMeeting Link&gt`,
  SMS_TO_HOST: `This is a friendly reminder that your &ltMeeting Name&gt with &ltInvitee Name&gt is at &ltMeeting Time&gt, &ltMeeting Date&gt. View or edit meeting &ltMeeting Link&gt`,
  TITLE_EMAIL_TO_HOST: `Personalize email to host`,
  TITLE_EMAIL_TO_INVITE: `Personalize email to invitee`,
  TITLE_SMS_TO_HOST: `Personalize text reminder to host`,
  TITLE_SMS_TO_INVITE: `Personalize text reminder to invitee`,
});

export const SmartAlertsWhen = Object.freeze({
  HOW_LONG_MEETING_BOOKED: `How long after new meeting is booked?`,
  MEETING_ENDS: `Meeting ends`,
  MEETING_BEFORE_START: `Before meeting starts`,
  MEETING_CANCELLED: `Meeting is canceled`,
  MEETINGS_STARTS: `Meetings starts`,
  MEETING_RESCHEDULED: `Meeting is rescheduled`,
  HOW_LONG_MEETING_ENDS: `How long after meeting ends?`,
  HOW_LONG_MEETING_BEFORE_START: `How long before meeting starts?`,
  HOW_LONG_MEETING_CANCELLED: `How long after meeting is cancelled?`,
  HOW_LONG_MEETINGS_STARTS: `How long after meeting starts?`,
  HOW_LONG_MEETING_RESCHEDULED: `How long after meeting is rescheduled?`,
  MINUTE: `Minute`,
  MINUTES: `Minutes`,
  HOUR: `Hour`,
  HOURS: `Hours`,
  DAY: `Day`,
  DAYS: `Days`,
  WHEN_MEETING_BOOKED: `When new meeting is booked`,
  WHEN_MEETING_STARTS: `When meeting starts`,
  WHEN_MEETING_ENDS: `When meeting ends`,
  WHEN_MEETING_CANCELED: `When meeting is canceled`,
  WHEN_MEETING_RESCHEDULED: `When meeting is rescheduled`,
  AFTER: `after `,
});

export const SmartAlertsWhom = Object.freeze({
  EMAIL_TO_INVITEE: `Send email to invitee`,
  EMAIL_TO_HOST: `Send email to host`,
  SMS_TO_INVITEE: `Send text reminder to invitee`,
  SMS_TO_HOST: `Send text reminder to host`,
  SEND_ALL: `send email and text remainder to invite and host`,
  SEND_EMAIL_SMS_INVITEE: `send email and text remainder to invite`,
  SEND_EMAIL_SMS_HOST: `send email and text remainder to host`,
});

export const SmartAlertsWarningModal = Object.freeze({
  WARNING_BP_AND_BT: `Warning this change will affect next booking pages and booking templates:`,
  WARNING_BP: `Warning this change will affect next booking pages:`,
  WARNING_BT: `Warning this change will affect next booking templates:`,
  TITLE: `Warning`,
  UPDATE_ALL: `Update all`,
  CREATE_NEW: `Create new Smart Alert`,
});
