import { t } from '../../../i18n/i18n';

export default {
  yes: t('Common:YES'),
  no: t('Common:NO'),
  cancel: t('Common:CANCEL'),
  confirm: t('Common:CONFIRM'),
  cloneNotification: t('BookingPages:CLONE_NOTIFICATION'),
  deactivateTitle: t('EditGroupBookingPage:DEACTIVATE_TITLE'),
  deactivateDescription: t('EditGroupBookingPage:DEACTIVATE_DESCRIPTION'),
  deleteTitle: t('EditGroupBookingPage:DELETE_TITLE'),
  deleteDescription: t('EditGroupBookingPage:DELETE_DESCRIPTION'),
  cloneTitle: t('EditGroupBookingPage:CLONE_TITLE'),
  cloneNamePlaceholder: t('EditGroupBookingPage:NAME_PLACEHOLDER'),
};
