import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { SectionHeader } from '../../../components/common';
import { MAX_LENGTH_ABOUT_300_WORDS, MAX_LENGTH_NAME } from '../../../types/constants';
import { accountsActions, accountsSelectors } from '../../../store/opsConsole/accounts';
import { AccountsDeleteModal } from '../../../components/opsConsole/accounts/accountsDeleteModal/AccountsDeleteModal';
import { TenantsTable } from '../../../components/opsConsole/accounts/tenantsTable/TenantsTable';
import labels from './labels';
import { AccountsTableRowActions } from '../../../components/opsConsole/accounts/accountsTableRowActions/AccountsTableRowActions';
import { InputTextarea } from 'primereact/inputtextarea';
import { Path } from '../../../routing';
import { authenticationSelectors } from '../../../store/authentication';
import { navigationService } from '../../../services/NavigationService';
import { ArrowLeftIcon } from '@heroicons/react/24/outline';

export const AccountDetails = () => {
  const { accountId } = useParams();
  const dispatch = useDispatch();

  const isFetching = useSelector(accountsSelectors.selectIsFetching);
  const accountName = useSelector(accountsSelectors.selectAccountName);
  const accountNote = useSelector(accountsSelectors.selectAccountNote);
  const accountCreatedBy = useSelector(accountsSelectors.selectAccountCreatedBy);
  const accountCreatedAt = useSelector(accountsSelectors.selectAccountCreatedAtFormatted);
  const isSaveAvailable = useSelector(accountsSelectors.selectIsSaveAvailable);
  const isDuplicate = useSelector(accountsSelectors.selectIsAccountNameDuplicate);
  const isAccountHasTenants = useSelector(accountsSelectors.selectIsAccountHasTenants);
  const isMainAdmin = useSelector(authenticationSelectors.selectIsMainAdmin);
  const isMainAdminOperationsOrSupport = useSelector(authenticationSelectors.selectIsMainAdminOperationsOrSupport);
  const staffNameOrEmail = useSelector(authenticationSelectors.selectStaffNameOrEmail);

  const [name, setName] = useState<string>(accountName);
  const [note, setNote] = useState<string>(accountNote);

  const isExisting = accountId && accountId !== 'new';

  useEffect(() => {
    setName(accountName);
  }, [accountName]);

  useEffect(() => {
    setNote(accountNote);
  }, [accountNote]);

  useEffect(() => {
    if (isExisting) {
      dispatch(accountsActions.selectAccount(accountId));
      dispatch(accountsActions.getAccountDetailsRequest());
    } else {
      dispatch(accountsActions.updateAccount({ createdBy: staffNameOrEmail }));
    }
  }, []);

  const handleBack = () => {
    navigationService.navigateTo(Path.OPSConsoleAccounts);
  };

  const handleSave = () => {
    dispatch(accountsActions.saveAccountDetailsRequest());
  };

  const handleNameChange = (value: string) => {
    setName(value);
  };

  const handleNameBlur = () => {
    dispatch(accountsActions.updateAccount({ name: name.trim() }));
  };

  const handleNoteChange = (value: string) => {
    setNote(value);
  };

  const handleNoteBlur = () => {
    dispatch(accountsActions.updateAccount({ note: note.trim() }));
  };

  return (
    <>
      {/* <AccountsMergeModal /> */}
      <AccountsDeleteModal />

      <Button className="button-blue button-text-line mb-16px" text onClick={handleBack}>
        <ArrowLeftIcon className="icon-18px" />
        <div className="flex-1 pl-8px">{labels.back}</div>
      </Button>

      <div className="sumo-card-bg flex flex-column pt-16px">
        <SectionHeader loading={isFetching} title={labels.title} hideButton />

        <div className="grid">
          <div className="col-12 flex align-items-center gap-3 mb-4">
            <div className="text-xl mr-auto">{accountName}</div>
            {isExisting && isMainAdmin && (
              <AccountsTableRowActions accountId={accountId} showEdit={false} disableDelete={isAccountHasTenants} />
            )}
            {isMainAdminOperationsOrSupport && (
              <Button label={labels.save} onClick={handleSave} disabled={!isSaveAvailable} />
            )}
          </div>

          <div className="col-12 md:col-6 p-fluid">
            <label>{labels.accountName}</label>
            <InputText
              className={`${isDuplicate && 'p-invalid'}`}
              maxLength={MAX_LENGTH_NAME}
              value={name}
              onChange={(e) => handleNameChange(e.target.value)}
              onBlur={handleNameBlur}
              disabled={!isMainAdminOperationsOrSupport}
            />
            {isDuplicate && (
              <small id="accountName-help" className="p-error block">
                {labels.duplicateNameMessage}
              </small>
            )}
          </div>

          <div className="col-12 p-fluid">
            <label>{labels.accountNote}</label>
            <InputTextarea
              autoResize
              rows={4}
              value={note}
              onChange={(e) => handleNoteChange(e.target.value)}
              onBlur={handleNoteBlur}
              disabled={!isMainAdminOperationsOrSupport}
              maxLength={MAX_LENGTH_ABOUT_300_WORDS}
            />
          </div>

          <div className="col-12 md:col-6 p-fluid">
            <label>{labels.createdBy}</label>
            <InputText value={accountCreatedBy} maxLength={MAX_LENGTH_NAME} disabled />
          </div>

          <div className="col-12 md:col-6 p-fluid">
            <label>{labels.createdAt}</label>
            <InputText value={accountCreatedAt} maxLength={MAX_LENGTH_NAME} disabled />
          </div>

          <div className="col-12 mt-4">
            <div className="text-xl font-semibold mb-1">{labels.tenants}</div>
            <TenantsTable />
          </div>
        </div>
      </div>
    </>
  );
};
