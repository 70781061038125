export const ConnectIntegrationPage = Object.freeze({
  LEADS: `Interessenten`,
  CONTACTS: `Kontakte`,
  CREATE: `Crear`,
  BENEFITS_TITLE: `Avantages`,
  DISCONNECT_INTEGRATION: `Déconnecter l'intégration`,
  DISCONNECT_GOOGLE_CALENDAR_DESCRIPTION: `Êtes-vous sûr de vouloir déconnecter Google Calendar ?`,
  DISCONNECT_GOOGLE_MEET_DESCRIPTION: `Êtes-vous sûr de vouloir déconnecter votre intégration ?

  Cela pourrait avoir un impact sur les pages de réservation créées précédemment en utilisant cette intégration.`,
  DISCONNECT_MICROSOFT_CALENDAR_DESCRIPTION: `Êtes-vous sûr de vouloir déconnecter Microsoft Office365 ?`,
  DISCONNECT_TEAMS_DESCRIPTION: `Êtes-vous sûr de vouloir déconnecter Microsoft Teams ?

  Cela pourrait avoir un impact sur les pages de réservation créées précédemment en utilisant cette intégration.`,
  DISCONNECT_ZOOM_DESCRIPTION: `Êtes-vous sûr de vouloir déconnecter Zoom Meeting ?

  Cela pourrait avoir un impact sur les pages de réservation créées précédemment en utilisant cette intégration.`,
  DISCONNECT_HUBSPOT_DESCRIPTION: `Êtes-vous sûr de vouloir déconnecter HubSpot ?`,
  DISCONNECT_SALESFORCE_DESCRIPTION: `Êtes-vous sûr de vouloir déconnecter Salesforce ?`,
  DISCONNECT_GOOGLE_ANALYTICS_DESCRIPTION: `Êtes-vous sûr de vouloir déconnecter Google Analytics ?`,
  GOOGLE_ANALYTICS_BENEFITS: `Obtenez des informations précieuses sur qui visite vos pages de réservation publiques. /p Mesurez les taux de conversion et combien de personnes réservent des événements. /p Intégrez votre compte SUMO avec Google Analytics`,
  GOOGLE_ANALYTICS_REQUIREMENTS: `Compte Google Analytics`,
  GOOGLE_ANALYTICS_SETUP_DESC: `Entrez un identifiant de suivi Universal Analytics ou un identifiant de mesure Google Analytics 4 pour connecter SUMO à votre propriété. Si vous entrez les deux, nous suivrons les conversions dans vos deux propriétés Google Analytics.`,
  GOOGLE_ANALYTICS_PLACEHOLDER_TRACKING_ID: `UA-12345678-9`,
  GOOGLE_ANALYTICS_VISIBLE_NAME_TRACKING_ID: `Universal Analytics tracking ID`,
  GOOGLE_ANALYTICS_PLACEHOLDER_MEASUREMENT_ID: `G-1234567890`,
  GOOGLE_ANALYTICS_VISIBLE_NAME_MEASUREMENT_ID: `Google Analytics 4 measurement ID`,
  GOOGLE_CALENDAR_BENEFITS: `Réservez et reprogrammez automatiquement des réunions sur votre Google Calendar.`,
  GOOGLE_CALENDAR_REQUIREMENTS: `Vous devez accorder l'accès à votre Google Calendar.`,
  GOOGLE_MAPS_BENEFITS: `/p Fournissez à vos clients une carte avec vos emplacements /p Intégrez votre compte SUMO avec Google Maps`,
  GOOGLE_MAPS_REQUIREMENTS: `Compte Google Maps Platform`,
  GOOGLE_MEET_BENEFITS: `Créez automatiquement des réunions Google Meet au moment où un événement est planifié. Partagez instantanément les détails de la conférence unique après confirmation.`,
  GOOGLE_MEET_REQUIREMENTS: `Selon votre configuration, l'administrateur de votre compte Google Meet peut être amené à préapprouver SUMO1 dans le Google Marketplace.`,
  HUBSPOT_BENEFITS: `Créez automatiquement des événements HubSpot et réservez avec des clients en tant que leads et contacts.`,
  HUBSPOT_REQUIREMENTS: `Compte HubSpot \n Droits d'administrateur HubSpot`,
  HUBSPOT_CREATE_EVENTS: `Créer des événements Hubspot`,
  HUBSPOT_BOOK_USERS: `Autoriser la réservation avec les enregistrements de Hubspot à partir de SUMO Inbox`,
  INBOX_BENEFITS: `La meilleure expérience SUMO1 consiste à utiliser SUMO dans votre environnement en installant l'extension Chrome / le module complémentaire Edge. Utilisez Sumo dans:\n\u2022 Google Gmail dans le navigateur\n\u2022 Microsoft Office Web dans le navigateur`,
  INBOX_REQUIREMENTS: `L'un des éléments suivants doit être connecté à SUMO1:\n\u2022 Compte Google\n\u2022 Compte Microsoft Office365`,
  INBOX_INSTALL_CHROME_EXTENSION: `Installer l'extension Chrome`,
  INBOX_INSTALL_EDGE_ADD_ON: `Installer le module complémentaire Edge`,
  MICROSOFT_CALENDAR_BENEFITS: `Réservez et reprogrammez automatiquement des réunions sur votre Microsoft Calendar.`,
  MICROSOFT_CALENDAR_REQUIREMENTS: `Vous devez accorder l'accès à votre Microsoft Calendar.`,
  MICROSOFT_TEAMS_BENEFITS: `Créez automatiquement des réunions Microsoft Teams au moment où un événement est planifié. Partagez instantanément les détails de la conférence unique après confirmation.`,
  MICROSOFT_TEAMS_REQUIREMENTS: `Selon votre configuration, l'administrateur de votre compte Microsoft Teams peut être amené à préapprouver SUMO1 dans le Microsoft Marketplace.`,
  NO_CANCEL: `Non, annuler`,
  REQUIREMENTS_TITLE: `Exigences`,
  SALESFORCE_BENEFITS: `Créez automatiquement des événements Salesforce et réservez avec des clients en tant que leads, contacts ou comptes de personne Salesforce.`,
  SALESFORCE_REQUIREMENTS: `Compte Salesforce \n Droits d'administrateur Salesforce`,
  SALESFORCE_CREATE_EVENTS: `Créer des événements Salesforce`,
  SALESFORCE_BOOK_USERS: `Autoriser la réservation avec les enregistrements de Salesforce à partir de SUMO Inbox`,
  YES_DISCONNECT: `Oui, déconnecter`,
  ZOOM_BENEFITS: `Créez automatiquement des réunions Zoom au moment où un événement est planifié. Partagez instantanément les détails de la conférence unique après confirmation.`,
  ZOOM_REQUIREMENTS: `Selon votre configuration, l'administrateur de votre compte Zoom peut être amené à préapprouver SUMO1 dans le Zoom Marketplace.`,
});

export const IntegrationPage = Object.freeze({
  DESCRIPTION: `Gagnez plus de temps en automatisant la vidéoconférence, la planification, et plus encore.`,
  FILTER_ALL: `Tout`,
  FILTER_CALENDARS: `Calendriers`,
  FILTER_VIDEO_CONFERENCE: `Vidéoconférence`,
  FILTER_WORKSPACE_INTEGRATIONS: `Intégrations de l'espace de travail`,
  FILTER_EXTENSIONS: `Extensions`,
  GOOGLE_ANALYTICS_DESC: `Suivez l'engagement avec vos pages de réservation`,
  GOOGLE_ANALYTICS_TITLE: `Google Analytics`,
  GOOGLE_CALENDAR_DESC: `Réservation et reprogrammation automatiques des réunions dans votre Google Calendar.`,
  GOOGLE_CALENDAR_TITLE: `Google Calendar`,
  GOOGLE_MAPS_DESC: `Ajoutez des emplacements à la carte pour offrir une meilleure expérience de réunion en personne`,
  GOOGLE_MAPS_TITLE: `Google Maps`,
  GOOGLE_MEET_DESC: `Ajout automatique des détails Google Meet à vos réunions.`,
  HUBSPOT_DESC: `Ajout automatique des réunions créées par SUMO1 à HubSpot CRM`,
  GOOGLE_MEET_TITLE: `Google Meet`,
  HUBSPOT_TITLE: `HubSpot`,
  INBOX_TITLE: `SUMO Inbox`,
  INBOX_DESC: `Installez l'extension Chrome ou le module complémentaire Edge pour envoyer des liens d'invitation depuis Gmail ou Outlook Web.`,
  MICROSOFT_CALENDAR_DESC: `Réservation et reprogrammation automatiques des réunions dans votre Microsoft Calendar.`,
  MICROSOFT_CALENDAR_TITLE: `Microsoft Office365`,
  MICROSOFT_TEAMS_DESC: `Ajout automatique des détails Microsoft Teams à vos réunions.`,
  MICROSOFT_TEAMS_TITLE: `Microsoft Teams`,
  NO_ITEMS: `Aucun élément trouvé.`,
  REQUIREMENTS_TITLE: `Exigences`,
  SALESFORCE_DESC: `Réservez des utilisateurs de Salesforce, des enregistrements CRM et ajoutez automatiquement des réunions Salesforce`,
  SALESFORCE_TITLE: `Salesforce`,
  TITLE: `Intégration`,
  ZOOM_DESC: `Ajout automatique des détails Zoom à vos réunions SUMO.`,
  ZOOM_TITLE: `Réunion Zoom`,
  FILL_ONE_FIELD: `Remplissez au moins un champ.`,
});

export const IntegrationToastsNotifications = Object.freeze({
  CONNECT_WORKSPACE_INTEGRATION_SUCCESS_MESSAGE: `Intégration de l'espace de travail connectée avec succès`,
  CONNECT_WORKSPACE_INTEGRATION_ERROR_MESSAGE: `Échec de la demande d'intégration de l'espace de travail`,
});
