import { t } from '../../i18n/i18n';

export default {
  save: t('Common:SAVE'),
  back: t('Common:BACK'),
  cancel: t('Common:CANCEL'),
  cancelYes: t('Common:YES_CANCEL'),
  cancelNo: t('Common:NO'),
  cancelTextPart1: t('Common:ARE_YOU_SURE'),
  cancelTextPart2: t('Common:UNSAVED_CHANGES'),
  clone: t('Common:CLONE'),
  delete: t('Common:DELETE'),
  activate: t('Common:ACTIVATE'),
  deactivate: t('Common:DEACTIVATE'),
  newBookingPage: t('BookingPages:NEW_BOOKING_PAGE'),
  whatTitle: t('EditBookingPage:WHAT_TITLE'),
  whatDescription: t('EditBookingPage:WHAT_DESCRIPTION'),
  whoTitle: t('EditBookingPage:WHO_TITLE'),
  whoDescription: t('EditBookingPage:WHO_DESCRIPTION'),
  whereTitle: t('EditBookingPage:WHERE_TITLE'),
  whereDescription: t('EditBookingPage:WHERE_DESCRIPTION'),
  whenTitle: t('EditBookingPage:WHEN_TITLE'),
  whenDescription: t('EditBookingPage:WHEN_DESCRIPTION'),
  howTitle: t('EditBookingPage:HOW_TITLE'),
  howDescription: t('EditBookingPage:HOW_DESCRIPTION'),
  alertsTitle: t('EditBookingPage:ALERTS_TITLE'),
  alertsDescription: t('EditBookingPage:ALERTS_DESCRIPTION'),
  inviteeTitle: t('EditBookingPage:INVITEE_TITLE'),
  inviteeDescription: t('EditBookingPage:INVITEE_DESCRIPTION'),
  afterTitle: t('EditBookingPage:AFTER_TITLE'),
  afterDescription: t('EditBookingPage:AFTER_DESCRIPTION'),
  additionalConfigurations: t('EditBookingPage:ADDITIONAL_CONFIGURATIONS'),
  viewBookingPage: t('EditBookingPage:VIEW_BOOKING_PAGE'),
  copyLink: t('EditBookingPage:COPY_LINK'),
  addToWebsite: t('EditBookingPage:ADD_TO_WEBSITE'),
  active: t('EditBookingPage:ACTIVE'),
  bookingTemplateLinkTooltip: t('EditBookingPage:BOOKING_TEMPLATE_LINK_TOOLTIP'),
  bookingTemplateLinkTooltipOverride: t('EditBookingPage:BOOKING_TEMPLATE_LINK_TOOLTIP_OVERRIDE'),
};
