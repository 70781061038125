import { handleAPIRequest } from '../utils/reduxUtils';
import {
  ChargeeBeeResponse,
  ItemPrices,
  Subscription,
  updateSubscriptionRequest,
  BillingGetItemPricesResponse,
} from './types';
import { API_PUBLIC } from '../../types/constants';

// ***
// When you change the requests do not forget to specify new data
// in docs/Endpoints.md
// ***

export const getSubscriptionDetails = async (): Promise<Subscription> => {
  return handleAPIRequest<Subscription>(`${API_PUBLIC}/billing/getSubscriptionDetails`, {});
};

export const changeSeatsOrTerm = async (body: updateSubscriptionRequest): Promise<ChargeeBeeResponse> => {
  return handleAPIRequest<ChargeeBeeResponse>(`${API_PUBLIC}/billing/changeSeatsOrTerm`, body);
};

export const deleteScheduledChange = async (scheduledChangeId: string): Promise<void> => {
  return handleAPIRequest<void>(`${API_PUBLIC}/deletePaymentScheduledChange`, { scheduledChangeId });
};

export const getItemPrices = async (): Promise<ItemPrices[]> => {
  const response = await handleAPIRequest<BillingGetItemPricesResponse>(`${API_PUBLIC}/billing/getItemPrices`, {});
  return response.itemPrices;
};
