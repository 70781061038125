import { useState, Fragment, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { OverridesInput, TimeFromToInput } from '../../../API';
import { userSettingsSelectors } from '../../../store/userSettings';
import {
  AVAILABILITY_OVERRIDE_MODAL_NAME,
  DEFAULT_AVAILABILITY_TIME,
  availabilityOverrideModalActions,
  userAvailabilityActions,
  userAvailabilitySelectors,
} from '../../../store/availability';
import {
  calcFromToTimeError,
  checkTimeOverlapping,
  formatDateAvailabilityOverrides,
  formatDateHHMM,
  formatTimeHHMM,
  isValidDate,
} from '../../../services/DateService';
import { Modal } from '../../common';
import labels from './labels';
import { Calendar } from 'primereact/calendar';
import { Button } from 'primereact/button';
import { TimeFormat } from '../../../store/userSettings/types';
import i18n from '../../../i18n/i18n';
import { CalendarIcon, ClockIcon, PlusIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { AvailabilityTimeIntervals } from '../availabilityTimeIntervals/AvailabilityTimeIntervals';
import { ErrorText } from '../../common/errorText/ErrorText';

export const AvailabilityOverride = () => {
  const dispatch = useDispatch();
  const timeFormat = useSelector(userSettingsSelectors.selectTimeFormat) || TimeFormat.default;
  const overrides = useSelector(userAvailabilitySelectors.selectSortedOverrides);
  const availableOverrides = useSelector(userAvailabilitySelectors.selectSortedAvailableOverrides);
  const unavailableOverrides = useSelector(userAvailabilitySelectors.selectSortedUnavailableOverrides);
  const isEditAvail = useSelector(userSettingsSelectors.selectAvailabilityEdit);
  // Modal state and handlers
  const [selectedDate, setSelectedDate] = useState<Date[] | null>(null);
  const [selectedTimes, setSelectedTimes] = useState<TimeFromToInput[]>([DEFAULT_AVAILABILITY_TIME]);
  const [isAvailable, setIsAvailable] = useState(true);
  const [isTimeInvalid, setIsTimeInvalid] = useState(false);
  const [isTimeOverlapping, setIsTimeOverlapping] = useState(false);
  const [isDateOverlapping, setIsDateOverlapping] = useState(false);

  const handleOverridesChange = (overrides: Array<OverridesInput | null>) => {
    dispatch(userAvailabilityActions.updateUserAvailability({ overrides }));
  };

  const checkDateOverlapping = () => {
    let dateOverlapping = false;
    if (!selectedDate) return false;
    // convert selected date to appropriate string
    const from = selectedDate[0] instanceof Date ? convertDateToString(selectedDate[0]) : '';
    const to = selectedDate[1] instanceof Date ? convertDateToString(selectedDate[1]) : from;
    const days = {
      from: new Date(from).toISOString(),
      to: new Date(to).toISOString(),
    };

    for (let i = 0; overrides && i < overrides.length && !dateOverlapping; i++) {
      const anotherDays = overrides[i]?.days;
      if (!anotherDays?.from || !anotherDays?.to) {
        continue;
      }
      if (
        (days.from >= anotherDays.from && days.from <= anotherDays.to) ||
        (days.to >= anotherDays.from && days.to <= anotherDays.to) ||
        (days.from < anotherDays.from && days.to > anotherDays.to)
      ) {
        dateOverlapping = true;
        break;
      }
    }
    return dateOverlapping;
  };

  useEffect(() => {
    setIsTimeOverlapping(checkTimeOverlapping(selectedTimes));
    const fromToTimeInvalid = selectedTimes.some((selectedTime) =>
      calcFromToTimeError(selectedTime?.from, selectedTime?.to)
    );
    setIsTimeInvalid(fromToTimeInvalid);
  }, [selectedTimes]);

  useEffect(() => {
    setIsDateOverlapping(checkDateOverlapping());
  }, [selectedDate]);

  const convertDateToString = (date: Date): string => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // JS months start from 0
    const day = String(date.getDate()).padStart(2, '0');

    const dateValue = `${year}-${month}-${day}`;
    return dateValue;
  };

  const handleSave = () => {
    if (selectedDate) {
      const from = selectedDate[0] instanceof Date ? convertDateToString(selectedDate[0]) : '';
      // if there is no second SelectedDate, the day is selected, not the period
      const to = selectedDate[1] instanceof Date ? convertDateToString(selectedDate[1]) : from;
      if (isValidDate(from) && isValidDate(to)) {
        const days = {
          from: new Date(from).toISOString(),
          to: new Date(to).toISOString(),
        };

        // if unavailable set time from 00:00 to 00:00
        const time = isAvailable
          ? selectedTimes.map((time) => ({
              from: time.from ? time.from : null,
              to: time.to ? time.to : null,
            }))
          : [{ from: '00:00', to: '00:00' }];

        handleOverridesChange([...overrides, { days, time, isBlock: !isAvailable }]);
      }

      setSelectedDate(null);
      setSelectedTimes([DEFAULT_AVAILABILITY_TIME]);
      setIsAvailable(true);
    }
    handleClose();
  };

  const handleRemove = (dates: TimeFromToInput) => {
    handleOverridesChange(
      overrides.filter((override: OverridesInput | null) => {
        if (!override) return false;
        // Check if the date matches and remove the specific time entry
        return !(override.days?.from === dates.from && override.days?.to === dates.to);
      })
    );
  };

  const handleAddTime = (): void => {
    setSelectedTimes((prevDates: TimeFromToInput[]) => [...prevDates, DEFAULT_AVAILABILITY_TIME]);
  };

  const handleRemoveTime = (index: number): void => {
    setSelectedTimes((prevDates: TimeFromToInput[]) =>
      prevDates.filter((_: TimeFromToInput, i: number) => i !== index)
    );
  };

  const handleAvailableOpen = () => {
    setIsAvailable(true);
    dispatch(availabilityOverrideModalActions.openModal());
  };

  const handleUnavailableOpen = () => {
    setIsAvailable(false);
    dispatch(availabilityOverrideModalActions.openModal());
  };

  const handleClose = () => {
    dispatch(availabilityOverrideModalActions.closeModal());
    setSelectedDate(null);
    setSelectedTimes([DEFAULT_AVAILABILITY_TIME]);
  };

  const handleTimeFromChange = (index: number, value: Date) => {
    setSelectedTimes((prevTimes) =>
      prevTimes.map((time, i) =>
        i === index && value instanceof Date ? { ...time, from: formatDateHHMM(value, TimeFormat.military) } : time
      )
    );
  };

  const handleTimeToChange = (index: number, value: Date) => {
    setSelectedTimes((prevTimes) =>
      prevTimes.map((time, i) =>
        i === index && value instanceof Date ? { ...time, to: formatDateHHMM(value, TimeFormat.military) } : time
      )
    );
  };

  return (
    <>
      <div className="grid justify-content-between p-20px -mb-2">
        <div className="col-12 lg:col-5 flex flex-column gap-16px">
          <div className="">
            <div className="text-title-s-med text-heavy-100">{labels.availableText}</div>
            <div className="text-body-s-reg text-heavy-80">{labels.availableDesc}</div>
          </div>

          {!!availableOverrides.length && (
            <div className="flex flex-column">
              {availableOverrides.map((override: OverridesInput | null, index: number) => {
                if (!override || !override.days) {
                  return null;
                }
                return (
                  <div
                    key={`${override.days.from}-${index}`}
                    className={`flex flex-column text-label-input-reg gap-3px ${
                      index !== availableOverrides.length - 1 ? 'border-bottom-1 border-heavy-20 mb-12px pb-12px' : 0
                    }`}
                  >
                    <div className="flex flex-row align-items-center justify-content-between">
                      <div className="flex flex-row align-items-center gap-6px">
                        <div>
                          <CalendarIcon width={16} height={16} />
                        </div>

                        {formatDateAvailabilityOverrides(override.days.from) +
                          (override.days.to && override.days.to !== override.days.from
                            ? ' - ' + formatDateAvailabilityOverrides(override.days.to)
                            : '')}
                      </div>
                      <Button
                        text
                        onClick={() => handleRemove(override?.days ? override?.days : { from: null, to: null })}
                        disabled={!isEditAvail}
                        className="button-secondary"
                      >
                        <XMarkIcon width={16} height={16} className="m-4px" />
                      </Button>
                    </div>
                    <div className="flex flex-row gap-6px">
                      <div className="w-16px h-16px -mt-2px">
                        <ClockIcon width={16} height={16} />
                      </div>
                      <div className="flex flex-column gap-3px">
                        {override.time?.map((time, ind) => (
                          <div key={`${time}-${ind}`} className="lowercase">
                            {time
                              ? `${formatTimeHHMM(time.from || '', timeFormat)} - ${formatTimeHHMM(
                                  time.to || '',
                                  timeFormat
                                )}`
                              : ''}
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          )}
          <Button
            onClick={handleAvailableOpen}
            className="button-add w-full h-24px justify-content-center"
            disabled={!isEditAvail}
          >
            <PlusIcon width={16} height={16} />
          </Button>
        </div>
        <div className="col-12 lg:col-5 flex flex-column gap-16px">
          <div className="">
            <div className="text-title-s-med text-heavy-100">{labels.unavailableText}</div>
            <div className="text-body-s-reg text-heavy-80">{labels.unavailableDesc}</div>
          </div>

          {!!unavailableOverrides.length && (
            <div className="flex flex-column">
              {unavailableOverrides.map((override: OverridesInput | null, index: number) => {
                if (!override || !override.days) {
                  return null;
                }
                return (
                  <div
                    key={`${override.days.from}-${index}`}
                    className={`flex flex-column text-label-input-reg gap-3px ${
                      index !== unavailableOverrides.length - 1 ? 'border-bottom-1 border-heavy-20 mb-12px pb-12px' : 0
                    }`}
                  >
                    <div className="flex flex-row align-items-center justify-content-between">
                      <div className="flex flex-row align-items-center gap-6px">
                        <div>
                          <CalendarIcon width={16} height={16} />
                        </div>
                        {formatDateAvailabilityOverrides(override.days.from) +
                          (override.days.to && override.days.to !== override.days.from
                            ? ' - ' + formatDateAvailabilityOverrides(override.days.to)
                            : '')}
                      </div>
                      <Button
                        text
                        onClick={() => handleRemove(override?.days ? override?.days : { from: null, to: null })}
                        disabled={!isEditAvail}
                        className="button-secondary"
                      >
                        <XMarkIcon width={16} height={16} className="m-4px" />
                      </Button>
                    </div>
                  </div>
                );
              })}
            </div>
          )}
          <Button
            onClick={handleUnavailableOpen}
            className="button-add w-full h-24px justify-content-center"
            disabled={!isEditAvail}
          >
            <PlusIcon width={16} height={16} />
          </Button>
        </div>
      </div>

      <Modal.Container
        name={AVAILABILITY_OVERRIDE_MODAL_NAME}
        closable={true}
        onClose={handleClose}
        contentClassName={'p-8px'}
      >
        <Modal.Header>
          <div>{isAvailable ? labels.dateOverrideAvailableTitle : labels.dateOverrideUnavailableTitle}</div>
        </Modal.Header>
        <div className="flex flex-wrap gap-12px justify-content-center">
          <Calendar
            id="date"
            value={selectedDate}
            onChange={(e) => {
              if (Array.isArray(e.value)) {
                setSelectedDate(e.value);
              }
            }}
            inline={true}
            locale={i18n.language}
            selectionMode="range"
            panelClassName="w-max p-0"
            minDate={new Date()}
          />

          {isAvailable && (
            <div className="flex flex-column py-8px px-14px gap-10px">
              <div className="text-title-xs-med mb-6px">{labels.dateOverrideModalHours}</div>
              <AvailabilityTimeIntervals
                time={selectedTimes}
                timeFormat={timeFormat}
                addInterval={handleAddTime}
                removeInterval={handleRemoveTime}
                handleTimeFromChange={handleTimeFromChange}
                handleTimeToChange={handleTimeToChange}
                isFirstIntervalRemovable={false}
              />
              {selectedTimes && (isDateOverlapping || isTimeOverlapping || isTimeInvalid) && (
                <div className="flex flex-column gap-2px" style={{ maxWidth: '190px' }}>
                  {isTimeInvalid && <ErrorText text={labels.timeInValidText} />}
                  {isTimeOverlapping && <ErrorText text={labels.timeOverlappingText} />}
                  {isDateOverlapping && <ErrorText text={labels.dateOverlappingText} />}
                </div>
              )}
            </div>
          )}
        </div>
        {!isAvailable && isDateOverlapping && <ErrorText text={labels.dateOverlappingText} />}

        <Modal.Buttons>
          <Button
            onClick={handleSave}
            disabled={!selectedDate || isDateOverlapping || (isAvailable && (isTimeOverlapping || isTimeInvalid))}
            autoFocus
          >
            {labels.save}
          </Button>
          <Button onClick={handleClose} text>
            {labels.cancel}
          </Button>
        </Modal.Buttons>
      </Modal.Container>
    </>
  );
};
