import { memo } from 'react';
import { Button } from 'primereact/button';

interface IHubSpotAuthButton {
  label?: string;
  outlined?: boolean;
  severity: string;
  className?: string;
  onRedirect?: (url: string) => void;
  disabled: boolean;
}

export const HubSpotAuthButton = memo(
  ({ label, outlined, severity, className, onRedirect, disabled }: IHubSpotAuthButton) => {
    const handleAuth = () => {
      onRedirect && onRedirect(window.location.href);
    };

    return (
      <Button
        label={label}
        className={className}
        outlined={outlined}
        severity={severity as 'secondary' | 'success' | 'info' | 'warning' | 'danger' | 'help'}
        onClick={handleAuth}
        disabled={disabled}
      />
    );
  }
);

HubSpotAuthButton.displayName = 'HubSpotAuthButton';
