import { memo, useState, useEffect } from 'react';
import { InputText } from 'primereact/inputtext';
import { MAX_LENGTH_NAME } from '../../../types/constants';
import { PencilIcon } from '@heroicons/react/24/outline';

interface ILabel {
  value: string;
  textClassName?: string;
  iconClassName?: string;
  editable?: boolean;
  maxLength?: number;
  onChange?: (value: string) => void;
  onBlurUpdate?: (value: string) => void;
}

export const Label = memo(
  ({ value, textClassName, iconClassName, editable, maxLength, onChange, onBlurUpdate }: ILabel) => {
    const [label, setLabel] = useState(value);
    const [editMode, setEditMode] = useState(false);

    useEffect(() => setLabel(value), [value]);

    const handleChange = (value: string) => {
      setLabel(value);
      onChange && onChange(value);
    };

    const handleBlur = () => {
      setEditMode(false);
      onBlurUpdate && onBlurUpdate(label);
    };

    return editable ? (
      <div>
        <div className="w-full absolute">
          <InputText
            type="text"
            className={`w-full min-w-20px border-none shadow-none p-0 bg-transparent text-inherit ${textClassName} ${
              editMode ? '' : 'left-0 top-0 opacity-0'
            }`}
            value={label}
            onChange={(e) => handleChange(e.target.value.trimStart())}
            onFocus={() => setEditMode(true)}
            onBlur={handleBlur}
            maxLength={maxLength || MAX_LENGTH_NAME}
          />
        </div>
        <div className={`flex-left-center pointer-events-none ${textClassName} ${editMode ? 'opacity-0' : ''}`}>
          <div>{value}</div>
          <div className="w-0 h-0 flex-left-center">
            <PencilIcon className={`flex-none ${iconClassName || 'icon-12px ml-4px'}`} />
          </div>
        </div>
      </div>
    ) : (
      <div className={textClassName}>{value}</div>
    );
  }
);

Label.displayName = 'Label';
