import { Node } from '@xyflow/react';
import { Journey, JourneyCard, JourneyDestinationType, JourneyEvent, JourneyFormField, JourneyStep } from '../../API';
import { StatusFilter } from '../../types/types';
import { RefObject } from 'react';
import { Menu } from 'primereact/menu';

export type GetJourneysByWorkspaceIdResponse = {
  journeys: Journey[];
};

export type GetJourneyByIdResponse = {
  journey: Journey;
};

export type getJourneyEventsByJourneyIdResponse = {
  journeyEvents: JourneyEvent[];
};

export type GetJourneyEventsByIdResponse = {
  journeyEvent: JourneyEvent;
};

export type JourneyFilter = {
  createdBy: string[];
  status: StatusFilter[];
};

export type AddJourneyStepPayload = {
  step: JourneyStep;
  parentId: string;
};

export type EditJourneyStepPayload = {
  step: JourneyStep;
  id: string;
};

export type JourneyPlusProps = {
  parent: JourneyStep;
  ref?: RefObject<Menu>;
  isReadOnly: boolean;
};

export type JourneyStepProps = {
  journeyStep: JourneyStep;
  isLastChild?: boolean;
  conditionIndex?: number;
  isReadOnly: boolean;
};

export type JourneyStepNodeType = Node<JourneyStepProps, ''>;
export type JourneyPlusNodeType = Node<JourneyPlusProps, ''>;

export type JourneyNodes = Node | JourneyStepNodeType | JourneyPlusNodeType;

export enum COMING_SOON {
  'IMPORT' = 'IMPORT',
  'CHAT_AI' = 'CHAT_AI',
  'SEPARATE_STEP' = 'SEPARATE_STEP',
}

export type FieldsAndCards = {
  fields: JourneyFormField[];
  cards: JourneyCard[];
};

export type GetJourneyEventsByJourneyIdResponse = {
  journeyEvents: JourneyEvent[];
};

export type JourneyEventFilter = {
  response: JourneyDestinationType[];
  dateRange: Array<string | null>;
};
