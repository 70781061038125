import { t } from '../../i18n/i18n';

export default {
  calendarInfoMessage: t('CalendarConnections:CALENDAR_INFO_MESSAGE'),
  disconnectCalendar: t('CalendarConnections:DISCONNECT_TITLE'),
  google: t('Common:GOOGLE'),
  microsoft: t('Common:MICROSOFT'),
  description: t('CalendarConnections:DESCRIPTION'),
  googleCalendarDescription: t('CalendarConnections:GOOGLE_CALENDAR_DESCRIPTION'),
  googleCalendarTitle: t('CalendarConnections:GOOGLE_CALENDAR_TITLE'),
  microsoftCalendarDescription: t('CalendarConnections:MICROSOFT_CALENDAR_DESCRIPTION'),
  microsoftCalendarTitle: t('CalendarConnections:MICROSOFT_CALENDAR_TITLE'),
  exchangeCalendarDescription: t('CalendarConnections:EXCHANGE_CALENDAR_DESCRIPTION'),
  exchangeCalendarTitle: t('CalendarConnections:EXCHANGE_CALENDAR_TITLE'),
  title: t('CalendarConnections:TITLE'),
  tooltip: t('CalendarConnections:TOOLTIP'),
  selectCalendar: t('CalendarConnections:SELECT_CALENDAR_TITLE'),
  disconnectMessage: t('CalendarConnections:DISCONNECT_MESSAGE'),
  disconnectMessageDescription: t('CalendarConnections:DISCONNECT_MESSAGE_DESCRIPTION'),
  noCancel: t('ConnectIntegrationPage:NO_CANCEL'),
  yesDisconnect: t('ConnectIntegrationPage:YES_DISCONNECT'),
};
