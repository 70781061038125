import { t } from '../../../i18n/i18n';

export default {
  yes: t('Common:YES'),
  no: t('Common:NO'),
  cancel: t('Common:CANCEL'),
  confirm: t('Common:CONFIRM'),
  deactivateTitle: t('Journey:DEACTIVATE_TITLE'),
  deactivateDescription: t('Journey:DEACTIVATE_DESCRIPTION'),
  deleteTitle: t('Journey:DELETE_TITLE'),
  deleteDescription: t('Journey:DELETE_DESCRIPTION'),
  cloneTitle: t('Journey:CLONE_TITLE'),
  nameLabel: t('Journey:NAME_LABEL'),
  selected: t('Journey:SELECTED_JOURNEY'),
};
