import { useDispatch, useSelector } from 'react-redux';
import { BulkActionRow, CardSkeleton, EmptyListScreen, SectionHeader } from '../../components/common';
import { Path } from '../../routing';
import {
  DEFAULT_FILTER,
  deleteJourneysModalActions,
  JOURNEY_STATUS_OPTIONS,
  journeysActions,
  journeysSelectors,
} from '../../store/journeyBuilder';
import { usersSelectors } from '../../store/users';
import { useEffect } from 'react';
import { authenticationSelectors } from '../../store/authentication';
import { MultiSelectChangeEvent } from 'primereact/multiselect';
import { JourneyCard } from '../../components/journeyBuilder/journeyCard/JourneyCard';
import { Button } from 'primereact/button';
import { TrashIcon } from '@heroicons/react/24/outline';
import labels from './labels';
import { JourneyModals } from '../../components/journeyBuilder';
import { BookingPageAddToWebsiteModal } from '../../components/bookingPages';
import { navigationService } from '../../services/NavigationService';
import { userSettingsSelectors } from '../../store/userSettings';
import { SKELETON_LENGTH } from '../../types/constants';

export const Journeys = () => {
  const dispatch = useDispatch();
  const isSpinnerFetching = useSelector(journeysSelectors.selectIsSpinnerFetching);
  const isSkeletonFetching = useSelector(journeysSelectors.selectIsSkeletonFetching);
  const userId = useSelector(authenticationSelectors.selectUserId);
  const filterInUse = useSelector(journeysSelectors.selectIsFilterInUse);
  const isDefaultFilter = useSelector(journeysSelectors.selectIsDefaultFilter);
  const filterStatus = useSelector(journeysSelectors.selectFilterStatus);
  const filterCreatedBy = useSelector(journeysSelectors.selectFilterCreatedBy);
  const journeys = useSelector(journeysSelectors.selectJourneys);
  const searchedJourneys = useSelector(journeysSelectors.selectSearchedJourneys);
  const selectedJourneys = useSelector(journeysSelectors.selectSelectedJourneys);
  const usersOptions = useSelector(usersSelectors.selectLoggedUsersInCurrentWorkspaceOptions);
  const isCreate = useSelector(userSettingsSelectors.selectJourneyBuilderCreate);
  const isDelete = useSelector(userSettingsSelectors.selectJourneyBuilderDelete);

  useEffect(() => {
    dispatch(journeysActions.getJourneysRequest());
    if (isDefaultFilter) {
      dispatch(journeysActions.setFilter({ ...DEFAULT_FILTER, createdBy: [userId] }));
    }
  }, []);

  const resetFilters = () => {
    dispatch(journeysActions.setFilter({ ...DEFAULT_FILTER, createdBy: [userId] }));
  };

  const handleSelectUsers = (e: MultiSelectChangeEvent) => {
    dispatch(journeysActions.setFilter({ createdBy: e.target.value }));
  };

  const handleSelectStatus = (e: MultiSelectChangeEvent) => {
    dispatch(journeysActions.setFilter({ status: e.target.value }));
  };

  const handleCreateJourney = () => {
    navigationService.navigateTo(Path.EditJourney.replace(':journeyId', 'new'));
  };

  const handleBulkClose = () => {
    dispatch(journeysActions.selectJourneys([]));
  };

  const handleBulkDelete = () => {
    dispatch(deleteJourneysModalActions.openModal());
  };

  const bulkContent = (
    <>
      {isDelete && (
        <Button text className="text-button-md-med" onClick={handleBulkDelete}>
          <TrashIcon width={18} height={18} className="mr-8px" />
          {labels.delete}
        </Button>
      )}
    </>
  );

  return (
    <>
      <JourneyModals />
      <BookingPageAddToWebsiteModal />
      <BulkActionRow
        selectedCount={selectedJourneys.length}
        handleClose={handleBulkClose}
        actionsContent={bulkContent}
      />

      <div className="sumo-card-bg flex flex-column pt-16px">
        <SectionHeader
          loading={isSpinnerFetching}
          title={labels.title}
          itemsLength={journeys.length}
          searchPaths={[Path.Journeys]}
          buttonLabel={labels.addLabel}
          onButtonClick={handleCreateJourney}
          hideButton={!isCreate}
          filters={[
            { label: labels.createdBy, value: filterCreatedBy, options: usersOptions, onChange: handleSelectUsers },
            {
              label: labels.status,
              value: filterStatus,
              options: JOURNEY_STATUS_OPTIONS,
              onChange: handleSelectStatus,
            },
          ]}
          onFilterReset={resetFilters}
          isFilterActive={filterInUse}
        />

        <div className="flex flex-wrap -m-8px">
          {isSkeletonFetching ? (
            [...Array(SKELETON_LENGTH)].map((value, index) => (
              <div className="w-12 lg:w-6 p-8px" key={index}>
                <CardSkeleton hideDetails hideOptions />
              </div>
            ))
          ) : searchedJourneys.length ? (
            searchedJourneys.map((journey) => (
              <div className="w-12 lg:w-6 p-8px" key={journey.id}>
                <JourneyCard journey={journey} />
              </div>
            ))
          ) : (
            <div className="w-12 p-8px">
              <EmptyListScreen description={labels.emptyListDesc} />
            </div>
          )}
        </div>
      </div>
    </>
  );
};
