import { AppType } from '../../../API';
import { ConnectIntegration } from '../connectIntegration/ConnectIntegration';
import urlConstants from '../../../shared/JSON/urlConstants.json';
import labels from './labels';
import { Button } from 'primereact/button';
import { ReactComponent as SumoInboxLogo } from '../../../assets/images/sumoInboxLogo.svg';

export const InboxIntegration = () => {
  const handleChromeInstall = () => {
    window.open(urlConstants.INBOX_CHROME_EXTENSTION_URL, '_blank', 'noreferrer');
  };

  const handleEdgeInstall = () => {
    window.open(urlConstants.INBOX_EDGE_ADD_ON_URL, '_blank', 'noreferrer');
  };

  return (
    <ConnectIntegration
      integrationType={AppType.INBOX}
      CustomSettingsSection={
        <div className="flex gap-24px mt-24px">
          <Button className="button-xl" onClick={handleChromeInstall} label={labels.installChromeExtension} />
          <Button className="button-xl" onClick={handleEdgeInstall} label={labels.installEdgeAddOn} />
        </div>
      }
      IntegrationIcon={<SumoInboxLogo />}
    />
  );
};
