import { Auth } from 'aws-amplify';
import { CreateLicenseInput, CreateTenantInput } from '../../API';
import {
  DeleteAndCreateUserRequest,
  GenerateCookiesResponse,
  ThirdPartyAuthRequest,
  ThirdPartyLambdaResponse,
  GetLicensesResponse,
  GetTenantDataResponse,
} from './types';
import { handleAPIRequest } from '../utils/reduxUtils';
import { API_PUBLIC } from '../../types/constants';
import { AuthenticationData } from '../global';

// ***
// When you change the requests do not forget to specify new data
// in docs/Endpoints.md
// ***

export const getAuthentication = async (): Promise<AuthenticationData> => {
  return handleAPIRequest(`${API_PUBLIC}/authentication`, {});
};

export const googleAuth = async (input: ThirdPartyAuthRequest): Promise<ThirdPartyLambdaResponse> => {
  return handleAPIRequest<ThirdPartyLambdaResponse>(`${API_PUBLIC}/auth/googleAuth`, input);
};

export const microsoftAuth = async (input: ThirdPartyAuthRequest): Promise<ThirdPartyLambdaResponse> => {
  return handleAPIRequest<ThirdPartyLambdaResponse>(`${API_PUBLIC}/auth/microsoftAuth`, input);
};

export const getTenantData = async (tenantId: string): Promise<CreateTenantInput> => {
  const responseBody = await handleAPIRequest<GetTenantDataResponse>(`${API_PUBLIC}/getTenantByTenantId`, { tenantId });
  return responseBody.tenant;
};

export const deleteAndCreateUser = async (input: DeleteAndCreateUserRequest): Promise<string> => {
  return handleAPIRequest<string>(`${API_PUBLIC}/deleteAndCreateUser`, input);
};

export const generateCookies = async (userId: string): Promise<GenerateCookiesResponse> => {
  return handleAPIRequest<GenerateCookiesResponse>(`${API_PUBLIC}/generateCookies`, { userId });
};

export const getLicenses = async (tenantId: string): Promise<CreateLicenseInput[]> => {
  const response = await handleAPIRequest<GetLicensesResponse>(`${API_PUBLIC}/getLicenses`, { tenantId });
  return response.licenses;
};

export const signIn = async (response: ThirdPartyLambdaResponse): Promise<void> => {
  try {
    const currentUser = await Auth.currentUserInfo();
    if (response && !currentUser && !response.inviteExpired) {
      const cognitoUser = await Auth.signIn(response.email);
      await Auth.sendCustomChallengeAnswer(cognitoUser, response.secret);
    }
  } catch (error: unknown) {
    console.error('Redux authentication service; Error logoutUser function:', error);
    throw error;
  }
};

export const signOut = async (): Promise<void> => {
  try {
    await Auth.signOut();
  } catch (error: unknown) {
    console.error('Redux authentication service; Error logoutUser function:', error);
    throw error;
  }
};
