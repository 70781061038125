import { useSelector } from 'react-redux';
import { bookingPageSelectors, bookingPagesActions } from '../../../store/bookingPages';
import { WhereStep } from '../../common/meeting/whereStep/WhereStep';
import { HostCard } from '../../common/hostCard/HostCard';

export const BookingPageWhereStep = () => {
  const isBookingPageLocked = useSelector(bookingPageSelectors.selectIsBookingPageLocked());
  const hasTemplate = useSelector(bookingPageSelectors.selectHasTemplate);
  const hostAndGuests = useSelector(bookingPageSelectors.selectHostAndGuestsData);

  return (
    <div className="flex flex-column gap-32px">
      {hostAndGuests[0] && (
        <div className="w-6 pr-20px">
          <HostCard host={hostAndGuests[0]} isMainHost />
        </div>
      )}

      <WhereStep
        selectors={bookingPageSelectors}
        actions={bookingPagesActions}
        isReadOnly={isBookingPageLocked || hasTemplate}
      />
    </div>
  );
};
