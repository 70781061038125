import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'primereact/button';
import { SectionHeader } from '../../../components/common';
import { accountsActions, accountsSelectors } from '../../../store/opsConsole/accounts';
import { AccountsTable } from '../../../components/opsConsole/accounts/accountsTable/AccountsTable';
import { AccountsDeleteModal } from '../../../components/opsConsole/accounts/accountsDeleteModal/AccountsDeleteModal';
import { Path } from '../../../routing';
import labels from './labels';
import { downloadCSV } from '../../../services/utils';
import { DateFormat } from '../../../store/userSettings';
import { formatDateUTC } from '../../../services/DateService';
import { navigationService } from '../../../services/NavigationService';
import { authenticationSelectors } from '../../../store/authentication';
import { CloudArrowDownIcon } from '@heroicons/react/24/outline';

export const Accounts = () => {
  const dispatch = useDispatch();
  const isFetching = useSelector(accountsSelectors.selectIsFetching);
  const accounts = useSelector(accountsSelectors.selectAccounts);
  const isMainAdminOperationsOrSupport = useSelector(authenticationSelectors.selectIsMainAdminOperationsOrSupport);

  useEffect(() => {
    dispatch(accountsActions.getAccountsRequest());
  }, []);

  const createAccount = () => {
    navigationService.navigateTo(Path.OPSConsoleAccount.replace(':accountId', 'new'));
  };

  const exportAccounts = () => {
    const header = [labels.accountName, labels.accountNote, labels.accountOrgs, labels.createdBy, labels.createdDate]
      .join(',')
      .toUpperCase();

    const rows = accounts.map((account) =>
      [
        account.name,
        account.note,
        account.tenantIds?.join(';') || '',
        account.createdBy,
        formatDateUTC(account.createdAt, DateFormat.default),
      ].join(',')
    );

    downloadCSV([header, ...rows].join('\n'), 'accounts.csv');
  };

  return (
    <>
      <AccountsDeleteModal />

      <div className="sumo-card-bg flex flex-column pt-16px">
        <SectionHeader
          loading={isFetching}
          title={labels.title}
          itemsLength={accounts.length}
          searchPaths={[Path.OPSConsoleAccounts, Path.OPSConsoleAccount]}
          buttonLabel={labels.createAccount}
          onButtonClick={createAccount}
          hideButton={!isMainAdminOperationsOrSupport}
          extraContent={
            <Button className="flex-none gap-8px button-xl button-white" onClick={exportAccounts}>
              <CloudArrowDownIcon className="icon-18px" />
              <div>{labels.export}</div>
            </Button>
          }
        />

        <AccountsTable />
      </div>
    </>
  );
};
