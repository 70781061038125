import { t } from '../../../i18n/i18n';

export default {
  name: t('JourneysPageStep:NAME'),
  select: t('Common:SELECT'),
  pageName: t('JourneysPageStep:PAGE_NAME'),
  pageType: t('JourneysPageStep:PAGE_TYPE'),
  noElements: t('JourneysPageStep:NO_ELEMENTS'),
  title: t('JourneysPageStep:TITLE'),
  titlePlaceholder: t('JourneysPageStep:TITLE_PLACEHOLDER'),
  inputs: t('JourneysPageStep:INPUTS'),
  selection: t('JourneysPageStep:SELECTION'),
  selectType: t('JourneysPageStep:SELECT_TYPE'),
};
