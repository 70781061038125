import { t } from '../../../i18n/i18n';

export default {
  yes: t('Common:YES'),
  no: t('Common:NO'),
  deleteTitle: t('EditJourney:DELETE_STEP_TITLE'),
  deleteDescription: t('EditJourney:DELETE_STEP_DESCRIPTION'),
  deleteAdditionalText: t('EditJourney:DELETE_STEP_ADDITIONAL_TEXT'),
  save: t('Common:SAVE'),
  cancel: t('Common:CANCEL'),
};
