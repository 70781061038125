import { call, put, select, takeLatest } from 'redux-saga/effects';
import { createSelector } from 'reselect';
import {
  CanceledEventInput,
  CustomFieldInput,
  CustomFieldType,
  JourneyFormFieldType,
  JourneyStepInput,
  UpdateUserEventInput,
} from '../../API';
import { eventActions, EventActionTypes } from './actions';
import { eventSelectors } from './selectors';
import { cancelEvent, fetchAgenda, createEvent, updateEvent, createJourneyEvent } from './service';
import {
  AgendaType,
  CreateEventRequest,
  CreateJourneyEventRequest,
  CreateUpdateEventResponse,
  EventSteps,
  GetAgendaRequestType,
} from './types';
import { CANCEL_EVENT_ERROR_TOAST, scheduledMeetingsActions } from '../bookedMeetings';
import { agendaEndOfMonth, agendaStartOfMonth, getMeetingURLParams } from './utils';
import { SERVER_ERROR_CODES } from '../../types/constants';
import { BOOKING_PAGE_EXPIRED_LABEL, NO_USERS_AVAILABLE_LABEL } from './constants';
import { authenticationSelectors } from '../authentication';
import { handleServiceError } from '../utils/reduxUtils';

const selectGetAgendaRequest = createSelector(
  eventSelectors.selectJourneyId,
  eventSelectors.selectBookingPageId,
  eventSelectors.selectBookedMeetingId,
  eventSelectors.selectViewDate,
  eventSelectors.selectTimeZone,
  eventSelectors.selectBookingPage,
  eventSelectors.selectGroupBookingPageId,
  eventSelectors.selectSavedLastStep,
  eventSelectors.selectStep,
  eventSelectors.selectChosenHostMemberId,
  eventSelectors.selectEvent,
  (
    journeyId,
    bookingPageId,
    eventId,
    viewDate,
    timeZone,
    agendaBookingPage,
    groupBookingPageId,
    journeyLastStep,
    step,
    chosenHostMemberId,
    event,
  ) => {
    let input: GetAgendaRequestType = {
      journeyId: bookingPageId ? undefined : journeyId,
      journeyDestination: (step === EventSteps.JOURNEY && journeyLastStep?.destination) || undefined,
      groupBookingPageId,
      bookingPageId,
      eventId,
      timeZone,
      startTime: agendaBookingPage?.id ? agendaStartOfMonth(viewDate, timeZone) : undefined,
      endTime: agendaBookingPage?.id ? agendaEndOfMonth(viewDate, timeZone) : undefined,
      chosenHostMemberId,
      locationType: event.location?.type,
    };
    const { spot } = getMeetingURLParams();
    if (spot) {
      input = { ...input, spot: Number(spot) || undefined };
    }
    return input;
  }
);

const selectEventRequest = createSelector(
  eventSelectors.selectEvent,
  eventSelectors.selectBookingPage,
  (event, bookingPage) => ({
    ...event,
    bookingPageId: bookingPage?.id || '',
    bookingPageName: event.bookingPageName || bookingPage?.what?.customName || '',
    style: event.style || bookingPage?.style,
    labels: event.labels || bookingPage?.labels,
  })
);

const selectCreateEventRequest = createSelector(
  selectEventRequest,
  eventSelectors.selectJourneyId,
  eventSelectors.selectJourneySavedSteps,
  eventSelectors.selectChosenHostMemberId,
  (event, journeyId, steps, chosenHostMemberId) => ({ event, journeyId, steps, chosenHostMemberId } as CreateEventRequest)
);

const selectCreateJourneyEventRequest = createSelector(
  eventSelectors.selectJourneyId,
  eventSelectors.selectJourneySavedSteps,
  (journeyId, steps) => ({ journeyEvent: { journeyId, steps } } as CreateJourneyEventRequest)
);

function* getAgendaSaga() {
  try {
    const request: GetAgendaRequestType = yield select(selectGetAgendaRequest);
    const response: AgendaType = yield call(fetchAgenda, request);

    const isEventLoaded: boolean = yield select(eventSelectors.selectIsExisting);
    if (response.event && !isEventLoaded) {
      yield put(eventActions.setBookedMeeting(response.event));
    }

    // TODO: refactor (calculate styles in selector)
    if (response.event?.style) {
      response.bookingPage = {
        ...response.bookingPage,
        style: response.event.style,
        labels: response.event.labels,
        id: response.bookingPage?.id || '',
        workspaceId: response.bookingPage?.workspaceId || '',
      };
    } else if (response.groupBookingPage?.style) {
      response.bookingPage = {
        ...response.bookingPage,
        style: response.groupBookingPage.style,
        labels: response.groupBookingPage.labels,
        id: response.bookingPage?.id || '',
        workspaceId: response.bookingPage?.workspaceId || '',
      };
    }

    // TODO: refactor (move to thunk)
    yield put(eventActions.getAgendaSuccess(response));
    const isEdit:boolean = yield select(eventSelectors.selectIsEditKeyValid);

    if (response.bookingPage && !response.event?.eventId){
      yield put(eventActions.setEventStep(EventSteps.WHEN));

      // set journey inputs to booking page
      const steps: JourneyStepInput[] = yield select(eventSelectors.selectJourneySavedSteps);
      const fields: CustomFieldInput[] = yield select(eventSelectors.selectInputFields);
      let nameField, emailField, phoneField;
      for (const step of [...steps].reverse()) {
        nameField = nameField || step.page?.formFields?.find((field) => field?.type === JourneyFormFieldType.NAME);
        emailField = emailField || step.page?.formFields?.find((field) => field?.type === JourneyFormFieldType.EMAIL);
        phoneField = phoneField || step.page?.formFields?.find((field) => field?.type === JourneyFormFieldType.PHONE);
      }
      const nameBookingField = fields.find((field) => field.fieldType === CustomFieldType.NAME);
      const emailBookingField = fields.find((field) => field.fieldType === CustomFieldType.EMAIL);
      const phoneBookingField = fields.find((field) => field.fieldType === CustomFieldType.PHONE);

      if (nameField?.value && nameBookingField) {
        yield put(eventActions.updateCustomField({ ...nameBookingField, value: nameField.value[0] }));
      }
      if (emailField?.value && emailBookingField) {
        yield put(eventActions.updateCustomField({ ...emailBookingField, value: emailField.value[0] }));
      }
      if (phoneField?.value && phoneBookingField) {
        yield put(eventActions.updateCustomField({ ...phoneBookingField, value: phoneField.value[0] }));
      }
    } else if (response.potentialHosts) {
      yield put(eventActions.setEventStep(EventSteps.WHO));
    }

    const isLocationInPerson: boolean = yield select(eventSelectors.selectIsLocationInPerson);
    const isRescheduleMode: boolean = yield select(eventSelectors.selectIsRescheduleMode);
    if (isLocationInPerson && !isRescheduleMode) {
      yield put(eventActions.setEventStep(EventSteps.WHERE));
    }
  } catch (error: unknown) {
    const errorText = error?.toString() || '';
    const errorMessage = errorText.includes(SERVER_ERROR_CODES.BookingPageExpired)
      ? BOOKING_PAGE_EXPIRED_LABEL
      : errorText;
    yield put(eventActions.getAgendaFail(errorMessage));
  }
}

function* createEventSaga() {
  try {
    const request: CreateEventRequest = yield select(selectCreateEventRequest);

    const response: CreateUpdateEventResponse = yield call(createEvent, request);

    yield put(eventActions.createEventSuccess(response));
  } catch (error: unknown) {
    const errorText = error?.toString() || '';
    const errorMessage = errorText.includes(SERVER_ERROR_CODES.NoUsersAvailable) ? NO_USERS_AVAILABLE_LABEL : errorText;
    yield put(eventActions.createEventFail(errorMessage));
  }
}

function* updateEventSaga() {
  try {
    const request: UpdateUserEventInput = yield select(selectEventRequest);

    const response: CreateUpdateEventResponse = yield call(updateEvent, request);

    yield put(eventActions.updateEventSuccess(response));

    const isAuthenticated: boolean = yield select(authenticationSelectors.selectAuthenticationDataReceived);
    if (isAuthenticated) {
      yield put(scheduledMeetingsActions.getBookedMeetingsRequest({}));
    }
  } catch (error: unknown) {
    const errorText = error?.toString() || '';
    const errorMessage = errorText.includes(SERVER_ERROR_CODES.NoUsersAvailable) ? NO_USERS_AVAILABLE_LABEL : errorText;
    yield put(eventActions.updateEventFail(errorMessage));
  }
}

function* cancelEventSaga() {
  try {
    const eventId: string = yield select(eventSelectors.selectEventId);
    const canceled: CanceledEventInput | null | undefined = yield select(eventSelectors.selectEventCanceled);

    yield call(cancelEvent, { eventId, canceled });

    yield put(eventActions.cancelEventSuccess());

    yield put(eventActions.setEventStep(EventSteps.BOOKED));
  } catch (error: unknown) {
    yield put(eventActions.cancelEventFail(error?.toString() || ''));
    yield call(handleServiceError, error, CANCEL_EVENT_ERROR_TOAST);
  }
}

function* createJourneyEventSaga() {
  try {
    const request: CreateJourneyEventRequest = yield select(selectCreateJourneyEventRequest);

    yield call(createJourneyEvent, request);
  } catch (error: unknown) {
    const errorText = error?.toString() || '';
    const errorMessage = errorText.includes(SERVER_ERROR_CODES.NoUsersAvailable) ? NO_USERS_AVAILABLE_LABEL : errorText;
    yield put(eventActions.createEventFail(errorMessage));
  }
}

export function* watchEventSaga() {
  yield takeLatest(EventActionTypes.GET_AGENDA_REQUEST, getAgendaSaga);
  yield takeLatest(EventActionTypes.CREATE_EVENT_REQUEST, createEventSaga);
  yield takeLatest(EventActionTypes.UPDATE_EVENT_REQUEST, updateEventSaga);
  yield takeLatest(EventActionTypes.CANCEL_EVENT_REQUEST, cancelEventSaga);
  yield takeLatest(EventActionTypes.CREATE_JOURNEY_EVENT_REQUEST, createJourneyEventSaga);
}
