import { Dropdown } from 'primereact/dropdown';
import {
  COMING_SOON,
  DESTINATION_TYPE_OPTIONS,
  journeysActions,
  journeysSelectors,
} from '../../../store/journeyBuilder';
import { useDispatch, useSelector } from 'react-redux';
import { JourneyDestinationType } from '../../../API';
import { BookingPageOption, bookingPageSelectors } from '../../../store/bookingPages';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { InputSwitch } from 'primereact/inputswitch';
import labels from './labels';
import { ComingSoonLabel } from '../../common';
import { MAX_LENGTH_DESCRIPTION, MAX_LENGTH_NAME, MAX_LENGTH_SHORT_ANSWER } from '../../../types/constants';
import { useEffect, useState } from 'react';
import { HTTP, HTTPS, validateURL } from '../../../services/URLService';

type EditJourneyDestinationStepProps = {
  isValidate: boolean;
  isReadOnly: boolean;
};

export const EditJourneyDestinationStep = ({ isValidate, isReadOnly }: EditJourneyDestinationStepProps) => {
  const dispatch = useDispatch();
  const destination = useSelector(journeysSelectors.selectJourneyDestination);
  const bookingPageOptions = useSelector(bookingPageSelectors.selectBookingPagesOptions);

  const [localURL, setLocalURL] = useState(destination?.externalURL || '');

  useEffect(() => {
    setLocalURL(destination?.externalURL || '');
  }, [destination?.externalURL]);

  const handleDestinationTypeSelect = (type: JourneyDestinationType) => {
    dispatch(journeysActions.updateDestinationStep({ type }));
  };

  const handleBookingPageSelect = (bookingPageId: string) => {
    dispatch(journeysActions.updateDestinationStep({ bookingPageId }));
  };

  const handleWhoActive = (whoActive: boolean) => {
    dispatch(
      journeysActions.updateDestinationStep({
        whoActive,
      })
    );
  };

  const itemTemplate = (option: BookingPageOption) => {
    return (
      <div className="flex justify-content-between gap-1">
        <div className="flex-1 overflow-hidden text-overflow-ellipsis">{option.label}</div>
        <div className="bg-gray-200 px-2 border-round h-16px">{option.displayId}</div>
      </div>
    );
  };
  const valueTemplate = () => {
    const option = bookingPageOptions.find((option) => option.value === destination?.bookingPageId);
    return option ? itemTemplate(option) : <span>{labels.select}</span>;
  };

  const handleURLBlur = () => {
    const newUrl = (!localURL?.startsWith(HTTPS) && !localURL?.startsWith(HTTP) ? HTTPS : '') + (localURL || '');
    dispatch(journeysActions.updateDestinationStep({ externalURL: newUrl }));
  };

  const handleHeaderChange = (header: string) => {
    dispatch(
      journeysActions.updateDestinationStep({
        message: { __typename: 'DestinationMessage', ...destination?.message, header },
      })
    );
  };

  const handleBodyChange = (body: string) => {
    dispatch(
      journeysActions.updateDestinationStep({
        message: { __typename: 'DestinationMessage', ...destination?.message, body },
      })
    );
  };

  const typeItemTemplate = (option: { value: string; label: string }) => {
    return (
      <div className="flex-center gap-12px">
        {option.label}
        {Object.keys(COMING_SOON).includes(option.value) && <ComingSoonLabel />}
      </div>
    );
  };

  return (
    <>
      <div className="flex mt-16px justify-content-between gap-20px">
        <div className="flex flex-column gap-8px w-5">
          <div className="text-title-xs-med text-heavy-100">{labels.destinationTypeLabel}</div>
          <Dropdown
            value={destination?.type}
            options={DESTINATION_TYPE_OPTIONS}
            optionDisabled={(option) => Object.keys(COMING_SOON).includes(option.value)}
            onChange={(e) => handleDestinationTypeSelect(e.value)}
            itemTemplate={typeItemTemplate}
            placeholder={labels.select}
            className={`${isValidate && !destination?.type ? 'p-invalid' : ''}`}
            disabled={isReadOnly}
          />
        </div>
        {destination?.type === JourneyDestinationType.BOOKING_PAGE && (
          <div className="flex flex-column gap-8px w-7">
            <div className="text-title-xs-med text-heavy-100">{labels.bookingPage}</div>
            <Dropdown
              value={destination?.bookingPageId}
              options={bookingPageOptions}
              itemTemplate={itemTemplate}
              valueTemplate={valueTemplate}
              onChange={(e) => handleBookingPageSelect(e.value)}
              placeholder={labels.select}
              className={`${isValidate && !destination?.bookingPageId ? 'p-invalid' : ''}`}
              disabled={isReadOnly}
            />
          </div>
        )}
      </div>
      <div className="bg-heavy-20 h-1px my-24px -mx-32px px-32px" />
      {destination?.type === JourneyDestinationType.BOOKING_PAGE && (
        <div className="w-412px flex flex-column gap-16px">
          <div className="flex-left-center gap-12px h-fit mb-8px">
            <InputSwitch checked={false} disabled={true} />
            <label className="text-label-lg-med text-heavy-50">{labels.showsStepSeparately}</label>
            <ComingSoonLabel />
          </div>
          <div className="journey-page-card">
            <div className="flex justify-content-between gap-6px">
              <div className="text-title-lg-med text-heavy-100">{labels.who}</div>
              <div className="text-title-xs-med flex-left-center gap-12px h-fit">
                <InputSwitch
                  inputId="whoActive"
                  checked={!!destination.whoActive}
                  onChange={(e) => handleWhoActive(!!e.target.value)}
                  disabled={isReadOnly}
                />
                <label htmlFor="whoActive" className="cursor-pointer">
                  {labels.active}
                </label>
              </div>
            </div>
            <div className="text-body-s-reg text-heavy-60">{labels.whoDesc}</div>
          </div>
          <div className="journey-page-card">
            <div className="flex justify-content-between gap-6px">
              <div className="text-title-lg-med text-heavy-100">{labels.where}</div>
            </div>
            <div className="text-body-s-reg text-heavy-60">{labels.whereDesc}</div>
          </div>
          <div className="journey-page-card">
            <div className="flex justify-content-between gap-6px">
              <div className="text-title-lg-med text-heavy-100">{labels.when}</div>
            </div>
            <div className="text-body-s-reg text-heavy-60">{labels.whenDesc}</div>
          </div>
        </div>
      )}
      {destination?.type === JourneyDestinationType.MESSAGE && (
        <div className="flex flex-column gap-8px w-412px">
          <div className="text-title-xs-med text-heavy-100">{labels.header}</div>
          <InputText
            value={destination?.message?.header || ''}
            onChange={(e) => handleHeaderChange(e.target.value)}
            className={`${isValidate && !destination?.message?.header ? 'p-invalid' : ''}`}
            maxLength={MAX_LENGTH_NAME}
            disabled={isReadOnly}
          />
          <div className="text-title-xs-med text-heavy-100 mt-16px">{labels.message}</div>
          <InputTextarea
            value={destination?.message?.body || ''}
            onChange={(e) => handleBodyChange(e.target.value)}
            autoResize
            rows={8}
            className={`${isValidate && !destination?.message?.body ? 'p-invalid' : ''}`}
            maxLength={MAX_LENGTH_DESCRIPTION}
            disabled={isReadOnly}
          />
        </div>
      )}

      {destination?.type === JourneyDestinationType.EXTERNAL_URL && (
        <div className="flex flex-column gap-8px w-412px">
          <div className="text-title-xs-med text-heavy-100">{labels.url}</div>
          <InputText
            value={localURL}
            onChange={(e) => setLocalURL(e.target.value)}
            onBlur={handleURLBlur}
            className={`${isValidate && !validateURL(localURL) ? 'p-invalid' : ''}`}
            maxLength={MAX_LENGTH_SHORT_ANSWER}
            disabled={isReadOnly}
          />
        </div>
      )}
    </>
  );
};
