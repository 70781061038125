import { Role } from '../../API';
import { CLONE_NAME_POSTFIX } from '../../types/constants';
import { AppThunk } from '../rootStore';
import { editRoleModalActions } from './modal';
import { rolesSelectors } from './selectors';
import { ChangePermission, RoleFilterType } from './types';

export enum RolesActionTypes {
  GET_ROLES_REQUEST = 'roles/GET_ROLES_REQUEST',
  GET_ROLES_SUCCESS = 'roles/GET_ROLES_SUCCESS',
  GET_ROLES_FAIL = 'roles/GET_ROLES_FAIL',
  GET_ROLE_REQUEST = 'roles/GET_ROLE_REQUEST',
  GET_ROLE_SUCCESS = 'roles/GET_ROLE_SUCCESS',
  GET_ROLE_FAIL = 'roles/GET_ROLE_FAIL',
  SET_SELECTED_ROLE = 'roles/SET_SELECTED_ROLE',
  UPDATE_SELECTED_ROLE = 'roles/UPDATE_SELECTED_ROLE',
  CHECK_PERMISSION = 'roles/CHECK_PERMISSION',
  UNCHECK_PERMISSION = 'roles/UNCHECK_PERMISSION',
  DEACTIVATE_ROLE_REQUEST = 'roles/DEACTIVATE_ROLE_REQUEST',
  DEACTIVATE_ROLE_SUCCESS = 'roles/DEACTIVATE_ROLE_SUCCESS',
  DEACTIVATE_ROLE_FAIL = 'roles/DEACTIVATE_ROLE_FAIL',
  SAVE_ROLE_REQUEST = 'roles/SAVE_ROLE_REQUEST',
  SAVE_ROLE_SUCCESS = 'roles/SAVE_ROLE_SUCCESS',
  SAVE_ROLE_FAIL = 'roles/SAVE_ROLE_FAIL',
  DELETE_ROLE_REQUEST = 'roles/DELETE_ROLE_REQUEST',
  DELETE_ROLE_SUCCESS = 'roles/DELETE_ROLE_SUCCESS',
  DELETE_ROLE_FAIL = 'roles/DELETE_ROLE_FAIL',
  SET_FILTER = 'teams/SET_FILTER',
  SELECT_TEAMS = 'teams/SELECT_TEAMS',
}

export type RolesAction =
  | { type: RolesActionTypes.GET_ROLES_REQUEST }
  | { type: RolesActionTypes.GET_ROLES_SUCCESS; payload: Role[] }
  | { type: RolesActionTypes.GET_ROLES_FAIL; error: unknown }
  | { type: RolesActionTypes.GET_ROLE_REQUEST; id: string }
  | { type: RolesActionTypes.GET_ROLE_SUCCESS; payload: Role }
  | { type: RolesActionTypes.GET_ROLE_FAIL; error: unknown }
  | { type: RolesActionTypes.SET_SELECTED_ROLE; payload: Role }
  | { type: RolesActionTypes.UPDATE_SELECTED_ROLE; payload: Partial<Role> }
  | { type: RolesActionTypes.CHECK_PERMISSION; payload: ChangePermission }
  | { type: RolesActionTypes.UNCHECK_PERMISSION; payload: ChangePermission }
  | { type: RolesActionTypes.DEACTIVATE_ROLE_REQUEST }
  | { type: RolesActionTypes.DEACTIVATE_ROLE_SUCCESS }
  | { type: RolesActionTypes.DEACTIVATE_ROLE_FAIL; error: unknown }
  | { type: RolesActionTypes.SAVE_ROLE_REQUEST }
  | { type: RolesActionTypes.SAVE_ROLE_SUCCESS }
  | { type: RolesActionTypes.SAVE_ROLE_FAIL; error: unknown }
  | { type: RolesActionTypes.DELETE_ROLE_REQUEST }
  | { type: RolesActionTypes.DELETE_ROLE_SUCCESS }
  | { type: RolesActionTypes.DELETE_ROLE_FAIL; error: unknown }
  | { type: RolesActionTypes.SET_FILTER; payload: Partial<RoleFilterType> }
  | { type: RolesActionTypes.SELECT_TEAMS; payload: string[] };

const getRolesRequest = (): RolesAction => ({ type: RolesActionTypes.GET_ROLES_REQUEST });
const getRolesSuccess = (payload: Role[]): RolesAction => ({
  type: RolesActionTypes.GET_ROLES_SUCCESS,
  payload,
});
const getRolesFail = (error: unknown): RolesAction => ({ type: RolesActionTypes.GET_ROLES_FAIL, error });

const getRoleRequest = (id: string): RolesAction => ({
  type: RolesActionTypes.GET_ROLE_REQUEST,
  id,
});
const getRoleSuccess = (payload: Role): RolesAction => ({
  type: RolesActionTypes.GET_ROLE_SUCCESS,
  payload,
});
const getRoleFail = (error: unknown): RolesAction => ({ type: RolesActionTypes.GET_ROLE_FAIL, error });

const setSelectedRole = (payload: Role): RolesAction => ({
  type: RolesActionTypes.SET_SELECTED_ROLE,
  payload,
});

const updateSelectedRole = (payload: Partial<Role>): RolesAction => ({
  type: RolesActionTypes.UPDATE_SELECTED_ROLE,
  payload,
});

const cloneRoleThunk = (): AppThunk => (dispatch, getState) => {
  const state = getState();
  const role = rolesSelectors.selectSelectedRole(state);
  dispatch(setSelectedRole({ ...role, id: '', name: role.name + CLONE_NAME_POSTFIX, updatedBy: '', isCustom: true }));
  dispatch(editRoleModalActions.openModal());
};

const checkPermission = (payload: ChangePermission): RolesAction => ({
  type: RolesActionTypes.CHECK_PERMISSION,
  payload,
});
const uncheckPermission = (payload: ChangePermission): RolesAction => ({
  type: RolesActionTypes.UNCHECK_PERMISSION,
  payload,
});

const deactivateRoleRequest = (): RolesAction => ({
  type: RolesActionTypes.DEACTIVATE_ROLE_REQUEST,
});
const deactivateRoleSuccess = (): RolesAction => ({
  type: RolesActionTypes.DEACTIVATE_ROLE_SUCCESS,
});
const deactivateRoleFail = (error: unknown): RolesAction => ({
  type: RolesActionTypes.DEACTIVATE_ROLE_FAIL,
  error,
});

const saveRoleRequest = (): RolesAction => ({
  type: RolesActionTypes.SAVE_ROLE_REQUEST,
});
const saveRoleSuccess = (): RolesAction => ({
  type: RolesActionTypes.SAVE_ROLE_SUCCESS,
});
const saveRoleFail = (error: unknown): RolesAction => ({
  type: RolesActionTypes.SAVE_ROLE_FAIL,
  error,
});

const deleteRoleRequest = (): RolesAction => ({
  type: RolesActionTypes.DELETE_ROLE_REQUEST,
});
const deleteRoleSuccess = (): RolesAction => ({
  type: RolesActionTypes.DELETE_ROLE_SUCCESS,
});
const deleteRoleFail = (error: unknown): RolesAction => ({
  type: RolesActionTypes.DELETE_ROLE_FAIL,
  error,
});

const setFilter = (payload: Partial<RoleFilterType>): RolesAction => ({
  type: RolesActionTypes.SET_FILTER,
  payload,
});
const selectTeams = (payload: string[]): RolesAction => ({
  type: RolesActionTypes.SELECT_TEAMS,
  payload,
});

export const rolesActions = {
  getRolesRequest,
  getRolesSuccess,
  getRolesFail,
  getRoleRequest,
  getRoleSuccess,
  getRoleFail,
  setSelectedRole,
  updateSelectedRole,
  cloneRoleThunk,
  checkPermission,
  uncheckPermission,
  deactivateRoleRequest,
  deactivateRoleSuccess,
  deactivateRoleFail,
  saveRoleRequest,
  saveRoleSuccess,
  saveRoleFail,
  deleteRoleRequest,
  deleteRoleSuccess,
  deleteRoleFail,
  setFilter,
  selectTeams,
};
