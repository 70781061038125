import { Column } from 'primereact/column';
import { UserAdminDataFullRecord } from '../../../store/users/types';
import labels from './labels';
import { useSelector } from 'react-redux';
import { useState } from 'react';
import { usersSelectors } from '../../../store/users';
import { TimeFormat, userSettingsSelectors } from '../../../store/userSettings';
import { rolesSelectors } from '../../../store/roles';
import { formatDateUTC, formatDateHHMM } from '../../../services/DateService';
import { EditUser } from '../editUser/EditUser';
import { InviteDialog } from '../inviteDialog/InviteDialog';
import { ConfirmDialog } from '../confirmDialog/ConfirmDialog';
import { UserRowActions } from '../userRowActions/UserRowActions';
import { StatusLabels } from '../../../store/users/constants';
import { SumoTable } from '../../common';

export const UsersList = () => {
  const isSkeletonFetching = useSelector(usersSelectors.selectIsSkeletonFetching);
  const filteredUsers = useSelector(usersSelectors.selectFilteredUsers);
  const timeFormat = useSelector(userSettingsSelectors.selectTimeFormat) || TimeFormat.default;
  const dateFormat = useSelector(userSettingsSelectors.selectDateFormat);
  const roles = useSelector(rolesSelectors.selectRoles);

  const [isDisableUser, setIsDisableUser] = useState(false);
  const [isEditConfirm, setIsEditConfirm] = useState(false);

  const setConfirmDialogProps = (isEdit: boolean, isDisable: boolean) => {
    setIsEditConfirm(isEdit);
    setIsDisableUser(isDisable);
  };

  const getRoleNameById = (id: string) => {
    return roles.find((role) => role.id === id)?.name || 'None';
  };

  return (
    <>
      <InviteDialog key="InviteDialog" />
      <EditUser key="EditUserDialog" />
      <ConfirmDialog key="DisableUserDialog" isDisableUser={isDisableUser} isEditConfirm={isEditConfirm} />

      <SumoTable
        loading={isSkeletonFetching}
        actionsBody={(data) => UserRowActions({ data, setConfirmDialogProps })}
        value={filteredUsers.sort((userA, userB) => (userB.createdAt > userA.createdAt ? 1 : -1))}
        sortField="updatedAt"
        sortOrder={-1}
      >
        <Column field="fullName" header={labels.name} sortable bodyClassName="text-overflow-ellipsis max-w-150px" />
        <Column field="email" header={labels.email} sortable bodyClassName="text-overflow-ellipsis max-w-150px" />
        <Column
          body={(data: UserAdminDataFullRecord) => getRoleNameById(data.roleId)}
          field="roleId"
          header={labels.role}
          sortable
          bodyClassName="text-overflow-ellipsis max-w-150px"
          sortFunction={(e) =>
            [...e.data].sort((a: UserAdminDataFullRecord, b: UserAdminDataFullRecord) =>
              getRoleNameById(a.roleId) > getRoleNameById(b.roleId) ? e.order || 0 : -(e.order || 0)
            )
          }
        />
        <Column
          field="status"
          header={labels.status}
          sortable
          bodyClassName="text-overflow-ellipsis max-w-90px"
          body={(data: UserAdminDataFullRecord) => StatusLabels[data.status || ''] || data.status}
        />
        <Column
          field="updatedAt"
          header={labels.lastActive}
          body={(data: UserAdminDataFullRecord) =>
            formatDateUTC(data.updatedAt, dateFormat) + ' ' + formatDateHHMM(new Date(data.updatedAt), timeFormat)
          }
          sortable
          bodyClassName="text-overflow-ellipsis max-w-150px"
          frozen
        />
      </SumoTable>
    </>
  );
};
