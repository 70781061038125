import { InputSwitch } from 'primereact/inputswitch';
import { InputText } from 'primereact/inputtext';
import { MAX_LENGTH_NAME } from '../../../types/constants';
import labels from './labels';
import { workspacesSelectors } from '../../../store/workspaces';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { workspacesActions } from '../../../store/workspaces/actions';
import { userSettingsSelectors } from '../../../store/userSettings';
import { SumoTooltip } from '../../common';

export const WorkspaceWhatStep = () => {
  const dispatch = useDispatch();
  const name = useSelector(workspacesSelectors.selectName);
  const enabled = useSelector(workspacesSelectors.selectIsActive);
  const isNameDuplicate = useSelector(workspacesSelectors.selectIsNameDuplicate);
  const isWorkspacesReadOnly = useSelector(userSettingsSelectors.selectIsReadOnlyWorkspace);

  const [localName, setLocalName] = useState(name);

  const handleNameChange = (name: string) => {
    setLocalName(name);
  };

  const handleNameBlur = () => {
    dispatch(workspacesActions.updateWorkspace({ name: localName.trim() }));
  };

  const handleEnabledChange = (value: boolean) => {
    dispatch(workspacesActions.updateWorkspace({ isActive: value }));
  };

  return (
    <div className="flex">
      <div className="w-6 pr-10px flex flex-column gap-8px">
        <div className="flex-left-center gap-8px">
          <div className="text-title-xs-med text-heavy-100">{labels.name}</div>
          <SumoTooltip text={'do we need some workspace name tooltip here?'} />
        </div>
        <div className="p-fluid">
          <InputText
            type="text"
            placeholder={labels.namePlaceholder}
            value={localName || ''}
            onChange={(e) => handleNameChange(e.target.value.trimStart())}
            onBlur={handleNameBlur}
            className={`${!localName ? 'p-invalid' : ''}`}
            maxLength={MAX_LENGTH_NAME}
            disabled={isWorkspacesReadOnly}
          />
        </div>
        {isNameDuplicate && <div className="text-body-s-reg text-tomato-main">{labels.duplicate}</div>}
      </div>
      <div className="w-6 pl-10px flex flex-column gap-16px">
        <div className="text-title-xs-med text-heavy-100">{labels.active}</div>
        <div>
          <InputSwitch
            checked={!!enabled}
            onChange={(e) => handleEnabledChange(!!e.value)}
            disabled={isWorkspacesReadOnly}
          />
        </div>
      </div>
    </div>
  );
};
