import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../../store/rootStore';
import { QuickSetupScreen } from '..';
import labels from './labels';
import { Button } from 'primereact/button';
import { quickSetupActions, quickSetupNavigationActions, quickSetupSelectors } from '../../../store/quickSetup';
import { FileUpload } from 'primereact/fileupload';
import { Image } from 'primereact/image';
import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Checkbox } from 'primereact/checkbox';
import { userSettingsSelectors } from '../../../store/userSettings';
import urlConstants from '../../../shared/JSON/urlConstants.json';
import { FILE_IMAGE_TYPES, MAX_FILE_SIZE_BYTES } from '../../../types/constants';
import { ImageType } from '../../../types/types';
import { CloudArrowUpIcon, TrashIcon } from '@heroicons/react/24/outline';

export const SetupYourBrandStep = () => {
  const dispatch = useDispatch<AppDispatch>();
  const logo = useSelector(quickSetupSelectors.selectLogo);
  const isQuickSetupFinished = useSelector(userSettingsSelectors.selectIsQuickSetupFinished);
  const isAcceptedTerms = useSelector(quickSetupSelectors.selectIsAcceptTerms);

  const [localLogo, setLocalLogo] = useState(logo);
  const logoRef = useRef<FileUpload>(null);
  const backgroundRef = useRef<FileUpload>(null);

  useEffect(() => {
    setLocalLogo(logo);
  }, [logo]);

  const handleNext = () => {
    dispatch(quickSetupActions.saveQuickSetupRequest());
    // dispatch(quickSetupNavigationActions.navigateNext());
  };

  const handleBack = () => {
    dispatch(quickSetupNavigationActions.navigateBack());
  };

  const uploadImage = (imageType: ImageType, file: File) => {
    dispatch(
      quickSetupActions.uploadImageFile({
        imageType: imageType,
        fileName: file.name,
        url: URL.createObjectURL(file),
      })
    );
    if (imageType === ImageType.avatar) {
      logoRef.current && logoRef.current.clear();
    } else {
      backgroundRef.current && backgroundRef.current.clear();
    }
  };

  const handleFileRemove = (imageType: ImageType) => {
    dispatch(quickSetupActions.clearImageFile(imageType));
  };

  const handleAcceptTerms = (value: boolean) => {
    dispatch(quickSetupActions.setIsAcceptTerms(value));
  };

  return (
    <QuickSetupScreen.Container title={labels.stepMessage} titleIcon="🏆">
      <div className="flex flex-column">
        <div className="text-display-md-med">{labels.title}</div>
        <div className="text-body-lg-reg text-heavy-80 pt-2px pb-32px w-580px">{labels.description}</div>

        <FileUpload
          ref={logoRef}
          mode="basic"
          auto
          accept={FILE_IMAGE_TYPES}
          maxFileSize={MAX_FILE_SIZE_BYTES}
          customUpload
          uploadHandler={(e) => uploadImage(ImageType.avatar, e.files[0])}
          className="hidden"
        ></FileUpload>

        {localLogo ? (
          <div className="flex gap-8px">
            <div className="w-200px h-96px border-1 border-heavy-20 border-radius-10px bg-heavy-1 overflow-hidden">
              <Image
                src={localLogo}
                preview
                className="h-full w-full"
                imageClassName="w-full h-full"
                imageStyle={{
                  objectFit: 'contain',
                  objectPosition: 'center',
                }}
              />
            </div>
            <div
              className="text-heavy-50 hover-text-heavy-100 cursor-pointer pt-8px"
              onClick={() => handleFileRemove(ImageType.avatar)}
            >
              <TrashIcon width="20px" />
            </div>
          </div>
        ) : (
          <div
            className="w-200px h-96px flex-center gap-8px border-radius-10px bg-heavy-1 text-heavy-60 hover-text-heavy-100 cursor-pointer"
            onClick={() => logoRef.current?.getInput().click()}
          >
            <CloudArrowUpIcon width="48px" strokeWidth="0.75px" />
            <div>
              <div>{labels.pictureSize}</div>
              <div>{labels.pictureFormats}</div>
            </div>
          </div>
        )}

        <div
          className={`flex align-items-center gap-10px border-top-1 mt-20px pt-20px border-heavy-20 w-fit ${
            !isQuickSetupFinished && 'cursor-pointer'
          }`}
          onClick={() => !isQuickSetupFinished && handleAcceptTerms(!isAcceptedTerms)}
        >
          <Checkbox
            checked={isAcceptedTerms || !!isQuickSetupFinished}
            onChange={(e) => handleAcceptTerms(!!e.target.checked)}
            disabled={!!isQuickSetupFinished}
          />
          <div className="text-body-s-reg text-heavy-80">
            <span>{labels.acceptTerms_step1 + ' '}</span>
            <Link to={urlConstants.TERMS_OF_SERVICE_URL} target="_blank">
              {labels.acceptTerms_step2}
            </Link>
            <span>{' ' + labels.acceptTerms_step3 + ' '}</span>
            <Link to={urlConstants.PRIVACY_POLICY_URL} target="_blank">
              {labels.acceptTerms_step4}
            </Link>
          </div>
        </div>
      </div>
      <QuickSetupScreen.Buttons>
        <Button
          label={labels.back}
          className="min-w-120px justify-content-center button-xl"
          onClick={handleBack}
          outlined
        />
        <Button
          className="justify-content-center button-xl"
          label={labels.save}
          onClick={handleNext}
          disabled={!isAcceptedTerms && !isQuickSetupFinished}
        />
      </QuickSetupScreen.Buttons>
    </QuickSetupScreen.Container>
  );
};
