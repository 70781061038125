import { DELAY_UNITS, IconOptions, WHOM_SEND } from './constants';

import { ReactComponent as NoShow } from '../../assets/smartAlerts/noShow.svg';
import { ReactComponent as Cancel } from '../../assets/smartAlerts/cancel.svg';
import { ReactComponent as Confirm } from '../../assets/smartAlerts/confirm.svg';
import { ReactComponent as Custom } from '../../assets/smartAlerts/custom.svg';
import { ReactComponent as FollowUp } from '../../assets/smartAlerts/followUp.svg';
import { ReactComponent as ThankYou } from '../../assets/smartAlerts/thankYou.svg';
import { ReactComponent as AdditionalResources } from '../../assets/smartAlerts/additionalResources.svg';
import { ReactComponent as Remainder } from '../../assets/smartAlerts/reminder.svg';
import { ReactComponent as Statistic } from '../../assets/smartAlerts/statistic.svg';
import { EventSendType, IconName, SmartAlert, SmartAlertTemplate, WhenSend, WhomSend } from '../../API';
import { t } from '../../i18n/i18n';

export const getCardIcon = (name: string) => {
  switch (name) {
    case IconName.NO_SHOW:
      return NoShow;
    case IconName.CANCEL:
      return Cancel;
    case IconName.CONFIRM:
      return Confirm;
    case IconName.CUSTOM:
      return Custom;
    case IconName.FOLLOW_UP:
      return FollowUp;
    case IconName.THANK_YOU:
      return ThankYou;
    case IconName.ADDITIONAL_RESOURCES:
      return AdditionalResources;
    case IconName.REMAINDER:
      return Remainder;
    case IconName.STATISTIC:
      return Statistic;
    default:
      return Custom;
  }
};

const convertDelayToString = (delay: number) => {
  let delayNum = delay; // minutes

  if (delay % 1440 === 0) {
    // from minutes to days
    delayNum = delay / 1440;
    return delayNum + ' ' + (delayNum === 1 ? t('SmartAlertsWhen:DAY') : t('SmartAlertsWhen:DAYS')).toLowerCase();
  } else if (delay % 60 === 0) {
    // from minutes to hours
    delayNum = delay / 60;
    return delayNum + ' ' + (delayNum === 1 ? t('SmartAlertsWhen:HOUR') : t('SmartAlertsWhen:HOURS')).toLowerCase();
  } else {
    return delayNum + ' ' + (delayNum === 1 ? t('SmartAlertsWhen:MINUTE') : t('SmartAlertsWhen:MINUTES')).toLowerCase();
  }
};

const getWhenDesc = (when: WhenSend) => {
  let res = '';
  switch (when.eventSendType) {
    case EventSendType.HOW_LONG_EVENT_BOOKED:
      res += t('SmartAlertsWhen:WHEN_MEETING_BOOKED') + ', ';
      break;
    case EventSendType.EVENT_BEFORE_START:
      res += convertDelayToString(when.delay || 0) + ' ' + t('SmartAlertsWhen:MEETING_BEFORE_START').toLowerCase();
      break;
    case EventSendType.EVENTS_STARTS:
      res += t('SmartAlertsWhen:WHEN_MEETING_STARTS') + ', ';
      break;
    case EventSendType.EVENT_ENDS:
      res += t('SmartAlertsWhen:WHEN_MEETING_ENDS') + ', ';
      break;
    case EventSendType.EVENT_CANCELLED:
      res += t('SmartAlertsWhen:WHEN_MEETING_CANCELED') + ', ';
      break;
    case EventSendType.EVENT_RESCHEDULED:
      res += t('SmartAlertsWhen:WHEN_MEETING_RESCHEDULED') + ', ';
      break;
  }
  if (when.eventSendType !== EventSendType.EVENT_BEFORE_START) {
    if (when.immediately) {
      res += t('EditSmartAlert:IMMEDIATELY').toLowerCase();
    } else {
      res += t('SmartAlertsWhen:AFTER') + convertDelayToString(when.delay || 0);
    }
  }
  return res;
};

const getWhomDesc = (whomSend: WhomSend) => {
  let res = '';
  if (
    whomSend.emailToInvitee?.isActive &&
    whomSend.emailToHost?.isActive &&
    whomSend.smsToInvitee?.isActive &&
    whomSend.smsToHost?.isActive
  ) {
    return t('SmartAlertsWhom:SEND_ALL');
  } else if (whomSend.emailToInvitee?.isActive && whomSend.smsToInvitee?.isActive) {
    let res = t('SmartAlertsWhom:SEND_EMAIL_SMS_INVITEE');
    if (whomSend.emailToHost?.isActive) {
      res += ', ' + t('SmartAlertsWhom:EMAIL_TO_HOST');
    } else if (whomSend.smsToHost?.isActive) {
      res += ', ' + t('SmartAlertsWhom:SMS_TO_HOST');
    }
    return res.toLowerCase();
  } else if (whomSend.emailToHost?.isActive && whomSend.smsToHost?.isActive) {
    let res = t('SmartAlertsWhom:SEND_EMAIL_SMS_HOST');
    if (whomSend.emailToInvitee?.isActive) {
      res += ', ' + t('SmartAlertsWhom:EMAIL_TO_INVITEE');
    } else if (whomSend.smsToInvitee?.isActive) {
      res += ', ' + t('SmartAlertsWhom:SMS_TO_INVITEE');
    }
    return res.toLowerCase();
  }
  if (whomSend.emailToInvitee?.isActive) {
    res += t('SmartAlertsWhom:EMAIL_TO_INVITEE') + ', ';
  }
  if (whomSend.emailToHost?.isActive) {
    res += t('SmartAlertsWhom:EMAIL_TO_HOST') + ', ';
  }
  if (whomSend.smsToInvitee?.isActive) {
    res += t('SmartAlertsWhom:SMS_TO_INVITEE') + ', ';
  }
  if (whomSend.smsToHost?.isActive) {
    res += t('SmartAlertsWhom:SMS_TO_HOST') + ', ';
  }
  return res.slice(0, -2).toLowerCase();
};

export const getCardDesc = (whenSend: WhenSend | null | undefined, whomSend: WhomSend | null | undefined) => {
  const when = whenSend ? getWhenDesc(whenSend) : '';
  const whom = whomSend ? getWhomDesc(whomSend) : '';

  return `${when} ${whom}.`;
};

export const getHowLongText = (eventSendType: EventSendType) => {
  switch (eventSendType) {
    case EventSendType.HOW_LONG_EVENT_BOOKED:
      return t('SmartAlertsWhen:HOW_LONG_MEETING_BOOKED');
    case EventSendType.EVENT_BEFORE_START:
      return t('SmartAlertsWhen:HOW_LONG_MEETING_BEFORE_START');
    case EventSendType.EVENTS_STARTS:
      return t('SmartAlertsWhen:HOW_LONG_MEETINGS_STARTS');
    case EventSendType.EVENT_ENDS:
      return t('SmartAlertsWhen:HOW_LONG_MEETING_ENDS');
    case EventSendType.EVENT_CANCELLED:
      return t('SmartAlertsWhen:HOW_LONG_MEETING_CANCELLED');
    case EventSendType.EVENT_RESCHEDULED:
      return t('SmartAlertsWhen:HOW_LONG_MEETING_RESCHEDULED');
  }
};

export const getImmediatelyText = (eventSendType: EventSendType) => {
  let res = t('EditSmartAlert:IMMEDIATELY') + ' ';
  switch (eventSendType) {
    case EventSendType.HOW_LONG_EVENT_BOOKED:
      res += t('SmartAlertsWhen:WHEN_MEETING_BOOKED').toLowerCase();
      break;
    case EventSendType.EVENTS_STARTS:
      res += t('SmartAlertsWhen:WHEN_MEETING_STARTS').toLowerCase();
      break;
    case EventSendType.EVENT_ENDS:
      res += t('SmartAlertsWhen:WHEN_MEETING_ENDS').toLowerCase();
      break;
    case EventSendType.EVENT_CANCELLED:
      res += t('SmartAlertsWhen:WHEN_MEETING_CANCELED').toLowerCase();
      break;
    case EventSendType.EVENT_RESCHEDULED:
      res += t('SmartAlertsWhen:WHEN_MEETING_RESCHEDULED').toLowerCase();
      break;
  }
  return res;
};

export const convertToMinutes = (delayUnit: DELAY_UNITS, delayNum: number) => {
  const multiple = delayUnit === DELAY_UNITS.days ? 1440 : delayUnit === DELAY_UNITS.hours ? 60 : 1;
  return delayNum * multiple;
};

export const getPersonalizeTitle = (whomSend: WHOM_SEND) => {
  switch (whomSend) {
    case WHOM_SEND.emailToHost:
      return t('SmartAlertsPersonalize:TITLE_EMAIL_TO_HOST');
    case WHOM_SEND.emailToInvitee:
      return t('SmartAlertsPersonalize:TITLE_EMAIL_TO_INVITE');
    case WHOM_SEND.smsToHost:
      return t('SmartAlertsPersonalize:TITLE_SMS_TO_HOST');
    case WHOM_SEND.smsToInvitee:
      return t('SmartAlertsPersonalize:TITLE_SMS_TO_INVITE');
  }
};

import { ReactComponent as CustomIcon } from '../../assets/smartAlerts/customIcon.svg';
import { SelectedAlertsType } from './types';

export const getSmartAlertIcon = (name: IconName) =>
  IconOptions.find((icon) => icon.value === name)?.icon || CustomIcon;

export const convertSmartTemplateToAlert = (smartTemplate: SmartAlertTemplate) =>
  ({
    __typename: 'SmartAlert',
    id: smartTemplate.id,
    workspaceId: smartTemplate.workspaceId,
    name: smartTemplate.name,
    icon: smartTemplate.icon,
    isHide: smartTemplate.isHide,
    shareWith: smartTemplate.shareWith,
    whenSend: smartTemplate.whenSend,
    whomSend: smartTemplate.whomSend,
    createdBy: smartTemplate.createdBy,
    createdAt: smartTemplate.createdAt,
    updatedAt: smartTemplate.updatedAt,
  } as SmartAlert);

export const getDeleteModalDescription = (selectedAlerts: SelectedAlertsType[], name?: string) => {
  let res = t('SmartAlerts:DELETE_DESCRIPTION');
  if (selectedAlerts.length === 1 && name) {
    res += ` "${name}"?`;
  } else {
    res += ` ${selectedAlerts.length} ${t('SmartAlerts:BULK_DELETE_DESCRIPTION')}?`;
  }
  return res;
};

export const getDeleteModalWarning = (selectedTypes: SelectedAlertsType[]) => {
  const alerts = selectedTypes.filter((record) => record.type === 'alert');
  if (alerts.length) {
    return alerts.length === 1 ? [t('SmartAlerts:DELETE_WARNING_ONE')] : [t('SmartAlerts:DELETE_WARNING_MULTIPLE')];
  } else return undefined;
};

export const isTemplateValid = (bodyText: string) => {
  return !!bodyText.length && !bodyText.includes("href='#'") && !bodyText.includes('Insert Link');
};
