import { TeamFilterType, TeamRecord } from './types';

export enum TeamsActionTypes {
  GET_TEAMS_REQUEST = 'teams/GET_TEAMS_REQUEST',
  GET_TEAMS_SUCCESS = 'teams/GET_TEAMS_SUCCESS',
  GET_TEAMS_FAIL = 'teams/GET_TEAMS_FAIL',
  GET_TEAMS_PAGE_REQUEST = 'teams/GET_TEAMS_PAGE_REQUEST',
  SET_SELECTED_TEAM = 'teams/SET_SELECTED_TEAM',
  UPDATE_SELECTED_TEAM = 'teams/UPDATE_SELECTED_TEAM',
  DEACTIVATE_TEAM_REQUEST = 'teams/DEACTIVATE_TEAM_REQUEST',
  DEACTIVATE_TEAM_SUCCESS = 'teams/DEACTIVATE_TEAM_SUCCESS',
  DEACTIVATE_TEAM_FAIL = 'teams/DEACTIVATE_TEAM_FAIL',
  SAVE_TEAM_REQUEST = 'teams/SAVE_TEAM_REQUEST',
  SAVE_TEAM_SUCCESS = 'teams/SAVE_TEAM_SUCCESS',
  SAVE_TEAM_FAIL = 'teams/SAVE_TEAM_FAIL',
  DELETE_TEAM_REQUEST = 'teams/DELETE_TEAM_REQUEST',
  DELETE_TEAM_SUCCESS = 'teams/DELETE_TEAM_SUCCESS',
  DELETE_TEAM_FAIL = 'teams/DELETE_TEAM_FAIL',
  SET_FILTER = 'teams/SET_FILTER',
  SELECT_TEAMS = 'teams/SELECT_TEAMS',
}

export type TeamsAction =
  | { type: TeamsActionTypes.GET_TEAMS_REQUEST }
  | { type: TeamsActionTypes.GET_TEAMS_SUCCESS; payload: TeamRecord[] }
  | { type: TeamsActionTypes.GET_TEAMS_FAIL; error: unknown }
  | { type: TeamsActionTypes.GET_TEAMS_PAGE_REQUEST }
  | { type: TeamsActionTypes.SET_SELECTED_TEAM; payload: TeamRecord }
  | { type: TeamsActionTypes.UPDATE_SELECTED_TEAM; payload: Partial<TeamRecord> }
  | { type: TeamsActionTypes.DEACTIVATE_TEAM_REQUEST }
  | { type: TeamsActionTypes.DEACTIVATE_TEAM_SUCCESS }
  | { type: TeamsActionTypes.DEACTIVATE_TEAM_FAIL; error: unknown }
  | { type: TeamsActionTypes.SAVE_TEAM_REQUEST }
  | { type: TeamsActionTypes.SAVE_TEAM_SUCCESS }
  | { type: TeamsActionTypes.SAVE_TEAM_FAIL; error: unknown }
  | { type: TeamsActionTypes.DELETE_TEAM_REQUEST }
  | { type: TeamsActionTypes.DELETE_TEAM_SUCCESS }
  | { type: TeamsActionTypes.DELETE_TEAM_FAIL; error: unknown }
  | { type: TeamsActionTypes.SET_FILTER; payload: Partial<TeamFilterType> }
  | { type: TeamsActionTypes.SELECT_TEAMS; payload: string[] };

const getTeamsRequest = (): TeamsAction => ({ type: TeamsActionTypes.GET_TEAMS_REQUEST });
const getTeamsSuccess = (payload: TeamRecord[]): TeamsAction => ({
  type: TeamsActionTypes.GET_TEAMS_SUCCESS,
  payload,
});
const getTeamsFail = (error: unknown): TeamsAction => ({ type: TeamsActionTypes.GET_TEAMS_FAIL, error });

const getTeamsPageRequest = (): TeamsAction => ({ type: TeamsActionTypes.GET_TEAMS_PAGE_REQUEST });

const setSelectedTeam = (payload: TeamRecord): TeamsAction => ({
  type: TeamsActionTypes.SET_SELECTED_TEAM,
  payload,
});

const updateSelectedTeam = (payload: Partial<TeamRecord>): TeamsAction => ({
  type: TeamsActionTypes.UPDATE_SELECTED_TEAM,
  payload,
});

const deactivateTeamRequest = (): TeamsAction => ({
  type: TeamsActionTypes.DEACTIVATE_TEAM_REQUEST,
});
const deactivateTeamSuccess = (): TeamsAction => ({
  type: TeamsActionTypes.DEACTIVATE_TEAM_SUCCESS,
});
const deactivateTeamFail = (error: unknown): TeamsAction => ({
  type: TeamsActionTypes.DEACTIVATE_TEAM_FAIL,
  error,
});

const saveTeamRequest = (): TeamsAction => ({
  type: TeamsActionTypes.SAVE_TEAM_REQUEST,
});
const saveTeamSuccess = (): TeamsAction => ({
  type: TeamsActionTypes.SAVE_TEAM_SUCCESS,
});
const saveTeamFail = (error: unknown): TeamsAction => ({
  type: TeamsActionTypes.SAVE_TEAM_FAIL,
  error,
});

const deleteTeamRequest = (): TeamsAction => ({
  type: TeamsActionTypes.DELETE_TEAM_REQUEST,
});
const deleteTeamSuccess = (): TeamsAction => ({
  type: TeamsActionTypes.DELETE_TEAM_SUCCESS,
});
const deleteTeamFail = (error: unknown): TeamsAction => ({
  type: TeamsActionTypes.DELETE_TEAM_FAIL,
  error,
});

const setFilter = (payload: Partial<TeamFilterType>): TeamsAction => ({
  type: TeamsActionTypes.SET_FILTER,
  payload,
});
const selectTeams = (payload: string[]): TeamsAction => ({
  type: TeamsActionTypes.SELECT_TEAMS,
  payload,
});

export const teamsActions = {
  getTeamsRequest,
  getTeamsSuccess,
  getTeamsFail,
  getTeamsPageRequest,
  setSelectedTeam,
  updateSelectedTeam,
  deactivateTeamRequest,
  deactivateTeamSuccess,
  deactivateTeamFail,
  saveTeamRequest,
  saveTeamSuccess,
  saveTeamFail,
  deleteTeamRequest,
  deleteTeamSuccess,
  deleteTeamFail,
  setFilter,
  selectTeams,
};
