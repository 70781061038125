import { t } from '../../../../../i18n/i18n';

export const TIME_OPTIONS = Array.from({ length: 13 }, (_, i) => ({
  value: `${i * 5}m`,
  label: i === 0 ? `${t('Common:NONE')}` : `${i * 5} ${t('Common:MINUTES')}`,
}));

export const NONE_VALUE = TIME_OPTIONS[0].value;

export const BUFFER_BLOCK_HEIGHT = 76;
export const BUFFER_BLOCK_GAP = 4;
