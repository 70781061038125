import { t } from '../../i18n/i18n';

export default {
  save: t('Common:SAVE'),
  edit: t('Common:EDIT'),
  back: t('Common:BACK'),
  cancel: t('Common:CANCEL'),
  title: t('Workspaces:TITLE'),
  newWorkspace: t('Workspaces:NEW_WORKSPACE'),
  whatTitle: t('EditWorkspace:WHAT_TITLE'),
  whatDescription: t('EditWorkspace:WHAT_DESCRIPTION'),
  whoTitle: t('EditWorkspace:WHO_TITLE'),
  whoDescription: t('EditWorkspace:WHO_DESCRIPTION'),
  howTitle: t('EditWorkspace:HOW_TITLE'),
  howDescription: t('EditWorkspace:HOW_DESCRIPTION'),
  additionalConfigurations: t('EditWorkspace:ADDITIONAL_CONFIGURATIONS'),
};
