import { useDispatch, useSelector } from 'react-redux';
import { eventActions, eventSelectors } from '../../../store/publicBookingPage';
import { JourneyCardInput, JourneyFormFieldInput, JourneyPageType } from '../../../API';
import { AppDispatch } from '../../../store/rootStore';
import { eventThunks } from '../../../store/publicBookingPage/thunks';
import { Button } from 'primereact/button';
import { JourneyFormField } from '../../common/journeyFormField/JourneyFormField';
import { HeroIconMap } from '../../../services/utils';
import { ArrowLeftIcon } from '@heroicons/react/24/outline';
import labels from './labels';

export const PublicBookingPageJourneyPage = () => {
  const dispatch = useDispatch<AppDispatch>();
  const currentStep = useSelector(eventSelectors.selectJourneyCurrentStep);
  const selectedCard = useSelector(eventSelectors.selectJourneySelectedCard);
  const isBackAvailable = useSelector(eventSelectors.selectIsJourneyBackAvailable);
  const isNextAvailable = useSelector(eventSelectors.selectIsJourneyNextAvailable);

  const handleFieldChange = (field: JourneyFormFieldInput) => {
    if (field.id && currentStep?.id) {
      dispatch(eventActions.updateJourneyPageField({ stepId: currentStep.id, field }));
    }
  };

  const handleSelectCard = (card: JourneyCardInput) => {
    dispatch(eventActions.selectJourneyCard(card));
  };

  const handleBack = () => {
    dispatch(eventThunks.setPreviousJourneyStep());
  };

  const handleNext = () => {
    dispatch(eventThunks.setNextJourneyStep());
  };

  const generateCard = (card: JourneyCardInput | null) => {
    if (!card) {
      return null;
    }

    const IconComponent = HeroIconMap[card?.icon || ''];
    const isSelected = selectedCard?.id === card?.id;

    return (
      <div key={card.id} className="w-12 md:w-6 py-8px px-12px" onClick={() => !isSelected && handleSelectCard(card)}>
        <div
          className={`sumo-card action-card h-full py-14px px-18px flex flex-column gap-8px ${
            isSelected ? 'selected' : ''
          }`}
        >
          <div className="flex-left-center gap-8px">
            {IconComponent && <IconComponent className="icon-28px text-heavy-60" />}
            <div className="text-title-xs-med text-heavy-100">{card.title}</div>
          </div>
          <div className="text-body-s-reg text-heavy-60">{card.details}</div>
        </div>
      </div>
    );
  };

  return (
    <div className="flex flex-column h-full">
      {currentStep?.page?.type === JourneyPageType.CARD && (
        <div className="flex-1 overflow-auto py-20px px-28px">
          <div className="flex flex-wrap -my-8px -mx-12px">
            {currentStep?.page.cards?.map((card) => generateCard(card))}
          </div>
        </div>
      )}

      {currentStep?.page?.type === JourneyPageType.FORM && (
        <div className="flex-1 overflow-auto p-20px">
          <div className="w-400px mx-auto flex flex-column gap-12px">
            {currentStep?.page.formFields?.map(
              (field) => field && <JourneyFormField key={field?.id} formField={field} onChange={handleFieldChange} />
            )}
          </div>
        </div>
      )}

      <div className="flex-none px-28px py-12px flex-left-center gap-8px border-top-1 border-heavy-20">
        {isBackAvailable && (
          <Button className="button-xl min-w-120px flex-center gap-8px" outlined onClick={handleBack}>
            <ArrowLeftIcon className="icon-18px" />
            <div>{labels.back}</div>
          </Button>
        )}
        <Button
          className="button-xl min-w-120px"
          label={currentStep?.page?.submitLabel || labels.next}
          disabled={!isNextAvailable}
          onClick={handleNext}
        />
      </div>
    </div>
  );
};
