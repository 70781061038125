import { Button } from 'primereact/button';
import { Menu } from 'primereact/menu';
import { useRef } from 'react';
import labels from './labels';
import { useDispatch } from 'react-redux';
import { extendTrialModalActions, orgsActions, expireTrialModalActions } from '../../../../store/opsConsole/orgs';
import { Path } from '../../../../routing';
import { OrgType } from '../../../../API';
import { navigationService } from '../../../../services/NavigationService';

type OrgsTableRowActionsProps = {
  tenantId: string;
  orgType: OrgType;
  showExtend?: boolean;
  showExpire?: boolean;
  showView?: boolean;
};

export const OrgsTableRowActions = ({
  tenantId,
  orgType,
  showExtend,
  showExpire,
  showView,
}: OrgsTableRowActionsProps) => {
  const dispatch = useDispatch();
  const menu = useRef<Menu | null>(null);

  // Define menu items
  const menuItems = [
    {
      label: showView ? labels.view : labels.edit,
      command: () => {
        navigationService.navigateTo(Path.OPSConsoleOrgDetails.replace(':orgId', tenantId));
      },
    },
    {
      label: labels.extendTrial,
      command: () => {
        dispatch(orgsActions.getOrgDetailsRequest(tenantId));
        dispatch(extendTrialModalActions.openModal());
      },
      visible: !!showExtend && orgType === OrgType.TRIAL,
    },
    {
      label: labels.expireTrial,
      command: () => {
        dispatch(orgsActions.getOrgDetailsRequest(tenantId));
        dispatch(expireTrialModalActions.openModal());
      },
      visible: !!showExpire && orgType === OrgType.TRIAL,
    },
  ];

  return (
    <>
      <Button
        icon="pi pi-ellipsis-h text-color"
        text
        onClick={(e) => {
          return menu.current?.toggle && menu.current.toggle(e);
        }}
      />
      <Menu model={menuItems} popup ref={menu} />
    </>
  );
};
