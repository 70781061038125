import { Path } from '../../../routing';
import { Authentication } from '../../common';
import { navigationService } from '../../../services/NavigationService';

export const Login = () => {
  const openSignUp = () => {
    navigationService.navigateTo(Path.Landing);
  };

  return <Authentication linkAction={openSignUp} login={true} />;
};
