import { t } from '../../../i18n/i18n';

export default {
  title: t('JourneysEvent:TITLE'),
  emptyList: t('Common:EMPTY_LIST'),
  noResponses: t('JourneysEvent:NO_RESPONSES'),
  emptyListDesc: t('JourneysEvent:EMPTY_LIST_DESCRIPTION'),
  dateRange: t('JourneysEvent:DATE_RANGE'),
  response: t('JourneysEvent:RESPONSE'),
  card: t('EditJourney:CARD'),
};
