import { Link } from 'react-router-dom';
import labels from './labels';
import { useDispatch, useSelector } from 'react-redux';
import { userSettingsActions, userSettingsSelectors } from '../../../store/userSettings';
import urlConstants from '../../../shared/JSON/urlConstants.json';
import { XMarkIcon } from '@heroicons/react/24/outline';

export const InstallInbox = () => {
  const dispatch = useDispatch();
  const isInstallInboxClosed = useSelector(userSettingsSelectors.selectIsInstallInboxClosed);

  const handleClose = () => {
    dispatch(userSettingsActions.updateUserSettings({ isInstallInboxClosed: true }));
    dispatch(userSettingsActions.saveUserSettingsNoToastRequest());
  };

  return (
    <>
      {!isInstallInboxClosed && (
        <div className="sumo-card-bg flex flex-column gap-16px pt-24px mb-28px">
          <div className="flex-between-center">
            <div className="text-title-xs-med">
              <span> {labels.installInboxPart1}</span>
              <Link target="_blank" to={urlConstants.INBOX_CHROME_EXTENSTION_URL}>
                {labels.installInboxPart2}
              </Link>
              <span> {labels.installInboxPart3}</span>
              <Link target="_blank" to={urlConstants.INBOX_EDGE_ADD_ON_URL}>
                {labels.installInboxPart4}
              </Link>
              <span> {labels.installInboxPart5}</span>
            </div>
            <div className="flex-none action-button -mr-8px -my-8px">
              <XMarkIcon className="icon-20px" onClick={handleClose} />
            </div>
          </div>
        </div>
      )}
    </>
  );
};
