import { memo } from 'react';
import { CodeResponse, GoogleOAuthProvider, useGoogleLogin } from '@react-oauth/google';
import { Button } from 'primereact/button';
import { ReactComponent as GoogleIcon } from '../../../assets/icons/32-google.svg';

interface IGoogleAuthButton {
  disabled?: boolean;
  outlined?: boolean;
  label?: string;
  onSuccess: (codeResponse: CodeResponse) => void;
  className?: string;
  login?: boolean;
  hideIcon?: boolean;
  loading?: boolean;
}

export const GoogleAuthButton = memo(
  ({ disabled, outlined, label, onSuccess, className, login, hideIcon, loading }: IGoogleAuthButton) => (
    <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID || ''}>
      <GoogleSignUpButtonInner
        disabled={disabled}
        outlined={outlined}
        label={label}
        onSuccess={onSuccess}
        className={className}
        login={login}
        hideIcon={hideIcon}
        loading={loading}
      />
    </GoogleOAuthProvider>
  )
);

const GoogleSignUpButtonInner = memo(
  ({ disabled, outlined, label, onSuccess, className, login, hideIcon, loading }: IGoogleAuthButton) => {
    const googleLogin = useGoogleLogin({
      onSuccess: (response: any) => {
        onSuccess({
          code: response.code || response.access_token,
          ...response,
        } as CodeResponse);
      },
      onError: (errorResponse: any) => console.error(errorResponse),
      flow: login ? 'implicit' : 'auth-code',
      scope: login
        ? ''
        : 'https://www.googleapis.com/auth/calendar.events https://www.googleapis.com/auth/contacts.readonly',
      ux_mode: 'popup',
    } as any);

    return (
      <Button
        disabled={disabled}
        outlined={outlined}
        className={`${className} ${hideIcon ? 'justify-content-center' : 'gap-20px'}`}
        onClick={googleLogin as any}
      >
        {!hideIcon && (
          <div className="w-32px h-32px flex-center">
            {loading ? <i className="pi pi-spinner pi-spin text-title-xl-med"></i> : <GoogleIcon />}
          </div>
        )}
        <div>{label}</div>
      </Button>
    );
  }
);

GoogleAuthButton.displayName = 'GoogleAuthButton';
GoogleSignUpButtonInner.displayName = 'GoogleSignUpButtonInner';
