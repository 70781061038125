import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  PhoneCallType,
  InPersonType,
  LocationType,
  PhysicalLocation,
  VideoConferenceType,
  IntegrationType,
} from '../../../../API';
import {
  DEFAULT_INPUT_LOCATION_FIELD,
  DEFAULT_INPUT_PHONE_FIELD,
  bookingTemplatesActions,
  bookingTemplatesSelectors,
} from '../../../../store/bookingTemplates';
import { RadioButton } from 'primereact/radiobutton';
import { InputText } from 'primereact/inputtext';
import { MAX_LENGTH_EMAIL } from '../../../../types/constants';
import { PhoneNumber, SumoMultiSelect } from '../../../common';
import labels from './labels';
import { VIDEO_CONFERENCE_OPTIONS, bookingPageSelectors, bookingPagesActions } from '../../../../store/bookingPages';
import { AppDispatch } from '../../../../store/rootStore';
import { MultiSelectChangeEvent } from 'primereact/multiselect';
import { locationsSelectors } from '../../../../store/locations';
import { OrganizationLocationCard } from '../organizationLocationCard/OrganizationLocationCard';
import { smartAlertsSelectors } from '../../../../store/smartAlerts';
import { InputSwitch } from 'primereact/inputswitch';
import { ReactComponent as StarIcon } from '../../../../assets/icons/12-star.svg';

import { ReactComponent as GoogleMeetIcon } from '../../../../assets/icons/21-google-meet.svg';
import { ReactComponent as ZoomIcon } from '../../../../assets/icons/21-zoom.svg';
import { ReactComponent as TeamsIcon } from '../../../../assets/icons/21-teams.svg';

type WhereStepProps = {
  selectors: typeof bookingTemplatesSelectors | typeof bookingPageSelectors;
  actions: typeof bookingTemplatesActions | typeof bookingPagesActions;
  isReadOnly: boolean;
};

export const WhereStep = ({ selectors, actions, isReadOnly }: WhereStepProps) => {
  const dispatch = useDispatch<AppDispatch>();
  const locationTypes = useSelector(selectors.selectLocationTypes);
  const defaultLocationType = useSelector(selectors.selectDefaultLocationType);
  const inPersonType = useSelector(selectors.selectInPersonType);
  const inPersonCustomAddress = useSelector(selectors.selectInPersonCustomAddress);
  const organizationLocations = useSelector(selectors.selectOrganizationLocations);
  const phoneCallType = useSelector(selectors.selectPhoneCallType);
  const phoneCallCustomPhoneNumber = useSelector(selectors.selectPhoneCallCustomPhoneNumber);
  const phoneCallCustomPhoneCode = useSelector(selectors.selectPhoneCallCustomPhoneCode);
  // const skipWhereStep = useSelector(selectors.selectSkipWhereStep);
  // const isZoomValid = useSelector(selectors.selectIsZoomValid);
  // const isGoogleMeetValid = useSelector(selectors.selectIsGoogleMeetValid);
  // const isMicrosoftTeamsValid = useSelector(selectors.selectIsMicrosoftTeamsValid);
  // const isSkipWhereDisabled = useSelector(selectors.selectIsSkipWhereDisabled);
  const videoConferenceNotification = useSelector(selectors.selectVideoConferenceNotification);
  const hostPhoneNotification = useSelector(selectors.selectHostPhoneNotification);
  const isCustomPhoneValid = useSelector(selectors.selectIsCustomPhoneValid);
  const isCustomAddressValid = useSelector(selectors.selectIsCustomAddressValid);
  // const isCompanyAddressValid = useSelector(selectors.selectIsCompanyAddressValid);
  // const isDefaultLocationTypeValid = useSelector(selectors.selectIsDefaultLocationTypeValid);
  const phoneInputField = useSelector(selectors.selectPhoneInputFields);
  const locationInputField = useSelector(selectors.selectLocationInputFields);
  const smsReminderOptionEnabled = useSelector(selectors.selectSmsReminderOptionEnabled);
  const smartAlertInUse = useSelector(selectors.selectSmartAlerts);
  const hostVideoConferences = useSelector(selectors.selectHostVideoConferences);
  const videoConferenceType = useSelector(selectors.selectVideoConferenceType);
  const isSMSToInvite = useSelector(smartAlertsSelectors.selectIsThereSmartAlertSMSInvitee(smartAlertInUse));

  const locationsList = useSelector(locationsSelectors.selectActiveLocationsList);
  const locationsById = useSelector(locationsSelectors.selectLocationsById);

  const [customAddress, setCustomAddress] = useState(inPersonCustomAddress);
  const [customPhone, setCustomPhone] = useState(phoneCallCustomPhoneNumber);

  useEffect(() => {
    // select In-Person and Invite Location and no location custom field, than add it
    if (
      locationTypes.includes(LocationType.IN_PERSON) &&
      inPersonType === InPersonType.INVITEE_LOCATION &&
      !locationInputField
    ) {
      dispatch(actions.addCustomField(DEFAULT_INPUT_LOCATION_FIELD));
    }

    // unselect In-Person or Invite Location and there is location custom field, than remove it
    if (
      (!locationTypes.includes(LocationType.IN_PERSON) || inPersonType !== InPersonType.INVITEE_LOCATION) &&
      locationInputField &&
      locationInputField.id
    ) {
      dispatch(actions.removeCustomField(locationInputField.id));
    }

    // select Phone Call and Prompt Invite
    if (locationTypes.includes(LocationType.PHONE_CALL) && phoneCallType === PhoneCallType.PROMPT_INVITE) {
      if (phoneInputField && !phoneInputField.required) {
        // if field already exist and no required(from SMS reminder), than update
        dispatch(actions.updateCustomField({ ...phoneInputField, required: true }));
      } else if (!phoneInputField) {
        // if no phone field, than add
        dispatch(actions.addCustomField(DEFAULT_INPUT_PHONE_FIELD));
      }
    }

    // unselect Phone Call or Prompt Invite and there is phone custom field
    if (
      (!locationTypes.includes(LocationType.PHONE_CALL) || phoneCallType !== PhoneCallType.PROMPT_INVITE) &&
      phoneInputField &&
      phoneInputField.id
    ) {
      if (smsReminderOptionEnabled || isSMSToInvite) {
        // if sms reminder turn on, than update field to no required
        dispatch(actions.updateCustomField({ ...phoneInputField, required: false }));
      } else {
        // if sms reminder turn off, than remove field
        dispatch(actions.removeCustomField(phoneInputField.id));
      }
    }
  }, [locationTypes, inPersonType, phoneCallType]);

  const handleLocationTypeToggle = (value: LocationType) => {
    dispatch(actions.toggleWhereLocationType(value));
    if (value === LocationType.VIDEO_CONFERENCE && 'setDefaultVideoConference' in actions) {
      dispatch(actions.setDefaultVideoConference());
    }
  };

  const handleVideoConferenceType = (type: VideoConferenceType) => {
    dispatch(bookingPagesActions.updateWhereStep({ videoConferenceType: type }));
  };

  const handleDefaultLocationTypeChange = (value: LocationType) => {
    dispatch(actions.updateWhereStep({ defaultLocationType: value }));
  };

  const handleInPersonTypeChange = (value: InPersonType) => {
    dispatch(actions.updateWhereStep({ inPersonType: value }));
  };

  const handleAddressChange = (value: string) => {
    setCustomAddress(value);
  };

  const handleAddressBlur = () => {
    dispatch(actions.updateWhereStep({ customAddress }));
  };

  const handleLocationsChange = (e: MultiSelectChangeEvent) => {
    const selectedLocations: PhysicalLocation[] = e.value;
    dispatch(actions.updateWhereStep({ locations: selectedLocations.map((location) => location.id) }));
  };

  const handleLocationRemove = (locationId: string) => {
    dispatch(actions.updateWhereStep({ locations: organizationLocations.filter((id) => id != locationId) }));
  };

  const handlePhoneCallTypeChange = (value: PhoneCallType) => {
    dispatch(actions.updateWhereStep({ phoneCallType: value }));
  };

  const handlePhoneChange = (value: string) => {
    setCustomPhone(value);
  };

  const handlePhoneBlur = () => {
    dispatch(actions.updateWhereStep({ customPhone }));
  };

  const handlePhoneCodeChange = (value: string) => {
    dispatch(actions.updateWhereStep({ customCountryCode: value }));
  };

  const handleSelectAllLocations = (checked: boolean) => {
    dispatch(actions.updateWhereStep({ locations: checked ? locationsList.map((location) => location.id) : [] }));
  };

  // const handleSkipWhereStepChange = (value: boolean) => {
  //   dispatch(actions.updateWhereStep({ skipTheWhereStep: value }));
  // };

  const getLocationTypeIcon = (type: IntegrationType) => {
    switch (type) {
      case IntegrationType.GOOGLE_MEET:
        return <GoogleMeetIcon />;
      case IntegrationType.MICROSOFT_TEAMS:
        return <TeamsIcon />;
      case IntegrationType.ZOOM:
        return <ZoomIcon />;
      default:
        return null;
    }
  };

  const generateLocationTypeSection = () =>
    !!hostVideoConferences.length && (
      <div className="flex flex-column gap-16px">
        {hostVideoConferences.map((conference) => (
          <div
            key={conference}
            className={`flex-left-center cursor-pointer ${
              (videoConferenceType as string) === (conference as string)
                ? 'text-heavy-100'
                : 'text-heavy-60 hover-text-heavy-80'
            }`}
            onClick={() => handleVideoConferenceType(conference as string as VideoConferenceType)}
          >
            <RadioButton checked={(videoConferenceType as string) === (conference as string)} disabled={isReadOnly} />
            <div className="flex pl-16px pr-10px">{getLocationTypeIcon(conference)}</div>
            <div className="text-title-xs-med">{VIDEO_CONFERENCE_OPTIONS[conference]}</div>
          </div>
        ))}
      </div>
    );

  const generateLocationTypeToggle = (type: LocationType, title: string) => (
    <div className="flex-left-center gap-10px">
      <InputSwitch
        value={type}
        checked={locationTypes.includes(type)}
        onChange={() => handleLocationTypeToggle(type)}
        disabled={isReadOnly}
      />
      <div
        className="text-title-xs-med text-heavy-100 hover-text-heavy-80 cursor-pointer"
        onClick={() => handleLocationTypeToggle(type)}
      >
        {title}
      </div>
      {locationTypes.includes(type) && (
        <>
          <div className="border-left-1 border-heavy-20 h-20px" />
          {defaultLocationType === type ? (
            <div className="flex-left-center gap-4px">
              <StarIcon width={12} height={12} className="text-blue-main" />
              <div className="text-label-xs-reg text-heavy-80">{labels.defaultLocation}</div>
            </div>
          ) : (
            <div className="action-button-xs -m-4px">
              <StarIcon width={12} height={12} onClick={() => !isReadOnly && handleDefaultLocationTypeChange(type)} />
            </div>
          )}
        </>
      )}
    </div>
  );

  const generatePhoneOption = (type: PhoneCallType, title: string, content: React.ReactNode) => (
    <div className="flex gap-10px">
      <RadioButton
        inputId={`phone-${type}`}
        value={type}
        checked={phoneCallType === type}
        disabled={!locationTypes.includes(LocationType.PHONE_CALL) || isReadOnly}
        onChange={(e) => handlePhoneCallTypeChange(e.target.value as PhoneCallType)}
      />
      <div className="flex flex-column gap-4px pt-2px">
        <label
          htmlFor={`phone-${type}`}
          className="text-title-xs-med text-heavy-100 cursor-pointer hover-text-heavy-80"
        >
          {title}
        </label>
        <div className="text-body-s-reg">{content}</div>
      </div>
    </div>
  );

  const generateLocationOption = (type: InPersonType, title: string, content: React.ReactNode) => (
    <div className="flex gap-10px">
      <RadioButton
        inputId={`location-${type}`}
        value={type}
        checked={inPersonType === type}
        disabled={!locationTypes.includes(LocationType.IN_PERSON) || isReadOnly}
        onChange={(e) => handleInPersonTypeChange(e.target.value as InPersonType)}
      />
      <div className="flex-1 flex flex-column gap-4px pt-2px">
        <label
          htmlFor={`location-${type}`}
          className="text-title-xs-med text-heavy-100 cursor-pointer hover-text-heavy-80"
        >
          {title}
        </label>
        <div className="text-body-s-reg">{content}</div>
      </div>
    </div>
  );

  return (
    <div className="flex flex-column gap-32px">
      <div className="flex flex-column gap-28px">
        {generateLocationTypeToggle(LocationType.VIDEO_CONFERENCE, labels.videoConference)}
        {locationTypes.includes(LocationType.VIDEO_CONFERENCE) && videoConferenceNotification && (
          <div className="text-body-s-reg text-tomato-main">{videoConferenceNotification}</div>
        )}
        {locationTypes.includes(LocationType.VIDEO_CONFERENCE) && generateLocationTypeSection()}
      </div>

      <div className="border-top-1 border-heavy-20" />

      <div className="flex flex-column gap-28px">
        {generateLocationTypeToggle(LocationType.PHONE_CALL, labels.phoneCall)}
        {locationTypes.includes(LocationType.PHONE_CALL) && (
          <div className="flex flex-column gap-20px">
            {generatePhoneOption(
              PhoneCallType.HOST_PHONE_NUMBER,
              labels.hostsPhone,
              !hostPhoneNotification ? (
                <div className="text-heavy-60">{labels.hostsPhoneDescription}</div>
              ) : (
                <div className="text-tomato-main">{hostPhoneNotification}</div>
              )
            )}
            {generatePhoneOption(
              PhoneCallType.PROMPT_INVITE,
              labels.inviteePhone,
              <div className="text-heavy-60">{labels.inviteePhoneDescription}</div>
            )}
            {generatePhoneOption(
              PhoneCallType.CUSTOM_PHONE,
              labels.customPhone,
              <div className="flex flex-column gap-12px">
                <div className="text-heavy-60">{labels.customPhoneDescription}</div>
                {phoneCallType === PhoneCallType.CUSTOM_PHONE && (
                  <PhoneNumber
                    disabled={!locationTypes.includes(LocationType.PHONE_CALL) || isReadOnly}
                    placeholder={labels.customPhonePlaceholder}
                    countryCode={phoneCallCustomPhoneCode || ''}
                    handleChangeCode={handlePhoneCodeChange}
                    phoneNumber={customPhone || ''}
                    handleChangePhone={handlePhoneChange}
                    handleBlurPhone={handlePhoneBlur}
                    inputClassName={(!isCustomPhoneValid && 'p-invalid') || ''}
                  />
                )}
              </div>
            )}
          </div>
        )}
      </div>

      <div className="border-top-1 border-heavy-20" />

      <div className="flex flex-column gap-28px">
        {generateLocationTypeToggle(LocationType.IN_PERSON, labels.inPerson)}
        {locationTypes.includes(LocationType.IN_PERSON) && (
          <div className="flex flex-column gap-20px">
            {generateLocationOption(
              InPersonType.LOCATION,
              labels.organizationLocationTitle,
              <div className="flex flex-column gap-12px">
                <div className="text-heavy-60">{labels.organizationLocationDesc}</div>
                {inPersonType === InPersonType.LOCATION && (
                  <div className="flex flex-wrap -mx-20px -my-6px">
                    <div className="w-6 px-20px py-6px">
                      <SumoMultiSelect
                        className="pr-24px"
                        onSelectAllChange={handleSelectAllLocations}
                        options={locationsList}
                        optionValue="id"
                        value={locationsList.filter((location) => organizationLocations.includes(location.id))}
                        onChange={handleLocationsChange}
                        disabled={inPersonType !== InPersonType.LOCATION || isReadOnly}
                        filterBy="name,address.name,address.zip,address.state,address.country"
                        templateType="location"
                      />
                    </div>
                    <div className="w-6 px-20px py-6px"></div>
                    {organizationLocations.map((locationId) => (
                      <div key={locationId} className="w-6 px-20px py-6px">
                        <OrganizationLocationCard
                          location={locationsById[locationId]}
                          handleRemove={handleLocationRemove}
                          isReadOnly={inPersonType !== InPersonType.LOCATION || isReadOnly}
                        />
                      </div>
                    ))}
                  </div>
                )}
              </div>
            )}
            {generateLocationOption(
              InPersonType.CUSTOM_ADDRESS,
              labels.customAddress,
              <div className="flex flex-column gap-12px">
                <div className="text-heavy-60">{labels.customAddressDescription}</div>
                {inPersonType === InPersonType.CUSTOM_ADDRESS && (
                  <InputText
                    type="text"
                    placeholder={labels.customAddressPlaceholder}
                    value={customAddress || ''}
                    disabled={!locationTypes.includes(LocationType.IN_PERSON) || isReadOnly}
                    onChange={(e) => handleAddressChange(e.target.value.trimStart())}
                    onBlur={handleAddressBlur}
                    className={(!isCustomAddressValid && 'p-invalid') || ''}
                    maxLength={MAX_LENGTH_EMAIL}
                  />
                )}
              </div>
            )}
            {generateLocationOption(
              InPersonType.INVITEE_LOCATION,
              labels.inviteeLocation,
              <div className="text-heavy-60">{labels.inviteeLocationDescription}</div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};
