import { t } from 'i18next';
import { COMING_SOON, JourneyEventFilter, JourneyFilter } from './types';
import { StatusFilter } from '../../types/types';
import { ToastNotificationOptions } from '../notifications';
import {
  TOAST_ERROR_COLOR,
  TOAST_ERROR_DURATION,
  TOAST_SUCCESS_COLOR,
  TOAST_SUCCESS_DURATION,
} from '../../types/constants';
import { Edge } from '@xyflow/react';
import {
  AttachmentSizeType,
  AttachmentType,
  Journey,
  JourneyConditionType,
  JourneyDestinationType,
  JourneyFormFieldType,
  JourneyPageType,
  JourneyStep,
  JourneyStepType,
} from '../../API';
import {
  Bars3Icon,
  BarsArrowUpIcon,
  ChevronDownIcon,
  EnvelopeIcon,
  IdentificationIcon,
  MinusIcon,
  PhoneIcon,
  QueueListIcon,
} from '@heroicons/react/24/outline';
import { ReactComponent as Circle } from '../../assets/icons/20-circle.svg';
import { HTTPS } from '../../services/URLService';

export const DEFAULT_FILTER: JourneyFilter = {
  createdBy: [],
  status: [StatusFilter.ACTIVE, StatusFilter.INACTIVE],
};

export const JOURNEY_STATUS_OPTIONS = [
  { value: StatusFilter.ACTIVE, label: t('Common:ACTIVE') },
  { value: StatusFilter.INACTIVE, label: t('Common:INACTIVE') },
];

export const GET_JOURNEYS_ERROR_TOAST: ToastNotificationOptions = Object.freeze({
  color: TOAST_ERROR_COLOR,
  autoHideDuration: TOAST_ERROR_DURATION,
  message: t('JourneysToastsNotifications:GET_JOURNEYS_ERROR_MESSAGE'),
});

export const DEACTIVATE_JOURNEY_SUCCESS_TOAST: ToastNotificationOptions = Object.freeze({
  color: TOAST_SUCCESS_COLOR,
  autoHideDuration: TOAST_SUCCESS_DURATION,
  message: t('JourneysToastsNotifications:DEACTIVATE_JOURNEY_SUCCESS_MESSAGE'),
});
export const ACTIVATE_JOURNEY_SUCCESS_TOAST: ToastNotificationOptions = Object.freeze({
  color: TOAST_SUCCESS_COLOR,
  autoHideDuration: TOAST_SUCCESS_DURATION,
  message: t('JourneysToastsNotifications:ACTIVATE_JOURNEY_SUCCESS_MESSAGE'),
});
export const DEACTIVATE_JOURNEY_ERROR_TOAST: ToastNotificationOptions = Object.freeze({
  color: TOAST_ERROR_COLOR,
  autoHideDuration: TOAST_ERROR_DURATION,
  message: t('JourneysToastsNotifications:DEACTIVATE_JOURNEY_ERROR_MESSAGE'),
});

export const CLONE_JOURNEY_SUCCESS_TOAST: ToastNotificationOptions = Object.freeze({
  color: TOAST_SUCCESS_COLOR,
  autoHideDuration: TOAST_SUCCESS_DURATION,
  message: t('JourneysToastsNotifications:CLONE_JOURNEY_SUCCESS_MESSAGE'),
});
export const CLONE_JOURNEY_ERROR_TOAST: ToastNotificationOptions = Object.freeze({
  color: TOAST_ERROR_COLOR,
  autoHideDuration: TOAST_ERROR_DURATION,
  message: t('JourneysToastsNotifications:CLONE_JOURNEY_ERROR_MESSAGE'),
});

export const DELETE_JOURNEYS_SUCCESS_TOAST: ToastNotificationOptions = Object.freeze({
  color: TOAST_SUCCESS_COLOR,
  autoHideDuration: TOAST_SUCCESS_DURATION,
  message: t('JourneysToastsNotifications:DELETE_JOURNEYS_SUCCESS_MESSAGE'),
});
export const DELETE_JOURNEYS_ERROR_TOAST: ToastNotificationOptions = Object.freeze({
  color: TOAST_ERROR_COLOR,
  autoHideDuration: TOAST_ERROR_DURATION,
  message: t('JourneysToastsNotifications:DELETE_JOURNEYS_ERROR_MESSAGE'),
});

export const GET_JOURNEY_ERROR_TOAST: ToastNotificationOptions = Object.freeze({
  color: TOAST_ERROR_COLOR,
  autoHideDuration: TOAST_ERROR_DURATION,
  message: t('JourneysToastsNotifications:GET_JOURNEY_ERROR_MESSAGE'),
});

export const SAVE_JOURNEY_ERROR_TOAST: ToastNotificationOptions = Object.freeze({
  color: TOAST_ERROR_COLOR,
  autoHideDuration: TOAST_ERROR_DURATION,
  message: t('JourneysToastsNotifications:SAVE_JOURNEY_ERROR_MESSAGE'),
});
export const CREATE_JOURNEY_SUCCESS_TOAST: ToastNotificationOptions = Object.freeze({
  color: TOAST_SUCCESS_COLOR,
  autoHideDuration: TOAST_SUCCESS_DURATION,
  message: t('JourneysToastsNotifications:CREATE_JOURNEY_SUCCESS_MESSAGE'),
});
export const UPDATE_JOURNEY_SUCCESS_TOAST: ToastNotificationOptions = Object.freeze({
  color: TOAST_SUCCESS_COLOR,
  autoHideDuration: TOAST_SUCCESS_DURATION,
  message: t('JourneysToastsNotifications:UPDATE_JOURNEY_SUCCESS_MESSAGE'),
});

export const GET_JOURNEY_EVENTS_ERROR_TOAST: ToastNotificationOptions = Object.freeze({
  color: TOAST_ERROR_COLOR,
  autoHideDuration: TOAST_ERROR_DURATION,
  message: t('JourneysToastsNotifications:GET_JOURNEY_EVENTS_ERROR_MESSAGE'),
});

export const dX = 300;
export const NODE_HEIGHT = 108;
export const dY = 30;
export const EMPTY_VALUE = '--';
export const DEFAULT_EDGE: Edge = {
  id: '',
  source: '',
  target: '',
  type: 'step',
  selectable: false,
  style: { strokeWidth: '2px', stroke: 'var(--heavy-blue-80)' },
};
export const CARD = 'CARD';

export const DEFAULT_JOURNEY = {
  __typename: 'Journey',
  id: '',
  name: t('JourneyDefault:JOURNEY_NAME'),
  active: true,
  eventsCount: 0,
  createdAt: '',
  updatedAt: '',
  createdBy: '',
} as Journey;

export const DEFAULT_STEP_ID = 'newStep';

export const DEFAULT_PAGE_STEP = {
  __typename: 'JourneyStep',
  id: DEFAULT_STEP_ID,
  type: JourneyStepType.PAGE,
  page: {
    __typename: 'JourneyPage',
    name: t('JourneyDefault:PAGE_NAME'),
    submitLabel: t('JourneyDefault:SUBMIT_LABEL'),
  },
} as JourneyStep;

export const DEFAULT_CONDITION_STEP = {
  __typename: 'JourneyStep',
  id: DEFAULT_STEP_ID,
  type: JourneyStepType.CONDITION,
  condition: {
    __typename: 'JourneyCondition',
    value: [null],
  },
} as JourneyStep;

export const DEFAULT_DESTINATION_STEP = {
  __typename: 'JourneyStep',
  id: DEFAULT_STEP_ID,
  type: JourneyStepType.DESTINATION,
  destination: {
    __typename: 'JourneyDestination',
    externalURL: HTTPS,
    whoActive: false,
  },
} as JourneyStep;

export const NEW_DEFAULT_STEPS = {
  [JourneyStepType.PAGE]: DEFAULT_PAGE_STEP,
  [JourneyStepType.CONDITION]: DEFAULT_CONDITION_STEP,
  [JourneyStepType.DESTINATION]: DEFAULT_DESTINATION_STEP,
};

export const PAGE_TYPE_OPTIONS = [
  {
    value: JourneyPageType.FORM,
    label: t('JourneyDefault:FORM'),
  },
  {
    value: JourneyPageType.CARD,
    label: t('JourneyDefault:CARDS'),
  },
  {
    value: COMING_SOON.IMPORT,
    label: t('JourneyDefault:IMPORT'),
  },
];

export const PAGE_TITLE_FIELDS = [
  {
    label: t('JourneyDefault:TITLE'),
    type: JourneyFormFieldType.TITLE,
    Icon: QueueListIcon,
  },
  {
    label: t('JourneyDefault:SUBTITLE'),
    type: JourneyFormFieldType.SUBTITLE,
    Icon: BarsArrowUpIcon,
  },
];

export const PAGE_INPUT_FIELDS = [
  {
    label: t('JourneyDefault:NAME'),
    type: JourneyFormFieldType.NAME,
    Icon: IdentificationIcon,
  },
  {
    label: t('JourneyDefault:SINGLE'),
    type: JourneyFormFieldType.SINGLE,
    Icon: MinusIcon,
  },
  {
    label: t('JourneyDefault:EMAIL'),
    type: JourneyFormFieldType.EMAIL,
    Icon: EnvelopeIcon,
  },
  {
    label: t('JourneyDefault:MULTIPLE'),
    type: JourneyFormFieldType.MULTIPLE,
    Icon: Bars3Icon,
  },
  {
    label: t('JourneyDefault:PHONE'),
    type: JourneyFormFieldType.PHONE,
    Icon: PhoneIcon,
  },
  // {
  //   label: t('JourneyDefault:ATTACHMENT'),
  //   type: JourneyFormFieldType.ATTACHMENT,
  //   Icon: PaperClipIcon,
  // },
];

export const PAGE_SELECTION_FIELDS = [
  {
    label: t('JourneyDefault:DROPDOWN'),
    type: JourneyFormFieldType.DROPDOWN,
    Icon: ChevronDownIcon,
  },
  {
    label: t('JourneyDefault:RADIO_BUTTONS'),
    type: JourneyFormFieldType.RADIO_BUTTONS,
    Icon: Circle,
  },
];

export const FORM_FIELD_LABELS = {
  [JourneyFormFieldType.NAME]: t('JourneyDefault:NAME'),
  [JourneyFormFieldType.EMAIL]: t('JourneyDefault:EMAIL'),
  [JourneyFormFieldType.PHONE]: t('JourneyDefault:PHONE'),
  [JourneyFormFieldType.TITLE]: t('JourneyDefault:TITLE'),
  [JourneyFormFieldType.SUBTITLE]: t('JourneyDefault:SUBTITLE'),
  [JourneyFormFieldType.SINGLE]: t('JourneyDefault:SINGLE'),
  [JourneyFormFieldType.MULTIPLE]: t('JourneyDefault:MULTIPLE'),
  [JourneyFormFieldType.ATTACHMENT]: t('JourneyDefault:ATTACHMENT'),
  [JourneyFormFieldType.DROPDOWN]: t('JourneyDefault:DROPDOWN'),
  [JourneyFormFieldType.RADIO_BUTTONS]: t('JourneyDefault:RADIO_BUTTONS'),
};

export const CONDTION_TYPE_OPTIONS = [
  { value: JourneyConditionType.IS, label: t('JourneyDefault:IS') },
  { value: JourneyConditionType.IS_NOT, label: t('JourneyDefault:IS_NOT') },
  { value: JourneyConditionType.CONTAINS, label: t('JourneyDefault:CONTAINS') },
  { value: JourneyConditionType.DOES_NOT_CONTAINS, label: t('JourneyDefault:DOES_NOT_CONTAIN') },
  { value: JourneyConditionType.STARTS_WITH, label: t('JourneyDefault:STARTS_WITH') },
  { value: JourneyConditionType.IS_NOT_EMPTY, label: t('JourneyDefault:IS_NOT_EMPTY') },
  { value: JourneyConditionType.IS_EMPTY, label: t('JourneyDefault:IS_EMPTY') },
  { value: JourneyConditionType.RANGE, label: t('JourneyDefault:RANGE') },
];

export const CONDTION_ATTACHMENT_TYPE_OPTIONS = [
  { value: AttachmentType.FILETYPE_IS, label: t('JourneyDefault:FILETYPE_IS') },
  { value: AttachmentType.FILETYPE_IS_NOT, label: t('JourneyDefault:FILETYPE_IS_NOT') },
  { value: AttachmentType.SIZE_IS_BIGGER_THAN, label: t('JourneyDefault:SIZE_IS_BIGGER_THAN') },
  { value: AttachmentType.SIZE_IS_SMALLER_THAN, label: t('JourneyDefault:SIZE_IS_SMALLER_THAN') },
];

export const CONDTION_ATTACHMENT_SIZE_OPTIONS = [
  { value: AttachmentSizeType.KB, label: 'Kb' },
  { value: AttachmentSizeType.MB, label: 'Mb' },
];

export const DESTINATION_TYPE_OPTIONS = [
  {
    value: JourneyDestinationType.BOOKING_PAGE,
    label: t('JourneyDefault:BOOKING_PAGE'),
  },
  {
    value: JourneyDestinationType.MESSAGE,
    label: t('JourneyDefault:MESSAGE'),
  },
  {
    value: JourneyDestinationType.EXTERNAL_URL,
    label: t('JourneyDefault:EXTERNAL_URL'),
  },
  {
    value: COMING_SOON.CHAT_AI,
    label: t('JourneyDefault:CHAT_AI'),
  },
];

export const DEFAULT_EVENT_FILTER: JourneyEventFilter = {
  response: [JourneyDestinationType.BOOKING_PAGE, JourneyDestinationType.EXTERNAL_URL, JourneyDestinationType.MESSAGE],
  dateRange: [],
};

export const EVENT_FILTER_OPTIONS = [
  { value: JourneyDestinationType.BOOKING_PAGE, label: t('JourneysEvent:EVENT_SCHEDULED') },
  { value: JourneyDestinationType.MESSAGE, label: t('JourneysDestinationStep:MESSAGE_LABEL') },
  { value: JourneyDestinationType.EXTERNAL_URL, label: t('JourneysDestinationStep:URL_LABEL') },
];

export const JOURNEY_RESPONSES_CSV_HEADERS = [
  t('JourneysEvent:CREATED_AT'),
  t('JourneysEvent:RESULT'),
  t('JourneysEvent:ANSWERS'),
  t('JourneysEvent:DESTINATION'),
];
