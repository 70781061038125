export const AuthenticationToastsNotifications = Object.freeze({
  CHOOSE_AN_ACCOUNT_ERROR_TOAST: `Choose an account fail`,
  DELETE_USER_ERROR_MESSAGE: `Delete user request fail`,
  DELETE_USER_SUCCESS_MESSAGE: `User successfully deleted`,
  GET_USER_AUTH_ERROR_MESSAGE: `User login request fail`,
  GET_USER_LOGIN_ERROR_MESSAGE: `No account exists with such email`,
  GET_USER_LOGOUT_ERROR_MESSAGE: `User logout request fail`,
  GET_MAIN_DATA_ERROR_MESSAGE: `Authentication request fail`,
  ROLE_DEACTIVATED_ERROR_MESSAGE: `Role has been deactivated.  Please contact your SUMO1 administrator.`,
  USER_DEACTIVATED_ERROR_MESSAGE: `User has been deactivated.  Please contact your SUMO1 administrator.`,
  TRIAL_LIMIT_ERROR_MESSAGE: `You have exceeded the limit of 30 items for the Trial version`,
  INVITE_EXPIRED_ERROR_TITLE: `Invite Expired`,
  INVITE_EXPIRED_ERROR_MESSAGE_PART1: `Your invite has expired. Please contact your administrator to get a new link or try to `,
  INVITE_EXPIRED_ERROR_MESSAGE_PART2: `sign up without an invite`,
  SWITCH_ACCOUNT_ERROR_MESSAGE: `Another account already associated with this email`,
});

export const Authorise = Object.freeze({
  SING_IN_TITLE_LINE_1: `Welcome`,
  SING_IN_TITLE_LINE_2: `back to SUMO`,
  SING_IN_SUBTITLE: `Login to your scheduling hub.`,
  SING_IN_GOOGLE: `Login with Google`,
  SING_IN_MICROSOFT: `Login with Microsoft`,
  SING_IN_DONT_HAVE_AN_ACCOUNT: `Don't have an account?`,
  SIGN_UP_COMMENT: `*SUMO1 does not require a Salesforce license.`,
  SING_UP_TITLE_LINE_1: `Online Scheduling`,
  SING_UP_TITLE_LINE_2: `and more`,
  SING_UP_SUBTITLE: `Start using the service for free with just one click.`,
  SING_UP_GOOGLE: `Sign up with Google`,
  SING_UP_MICROSOFT: `Sign up with Microsoft`,
  SIGN_UP_FREE_TRIAL: `15 days free trial. No credit card required`,
  SING_UP_HAVE_AN_ACCOUNT: `Already have an account?`,
  LOOKING_FOR_SUMO_CEP: `Looking for SUMO For Salesforce?`,
  LOADING_LABEL_1: `Authorizing User`,
  LOADING_LABEL_2: `Checking Calendar`,
  LOADING_LABEL_3: `Creating Tokens`,
});

export const DialogLeaveAccount = Object.freeze({
  ACCEPT_TEXT: 'Would you like to accept this invite and leave the other SUMO1 account?',
  INVITE_TEXT_PART_1: `You've been invited by`,
  INVITE_TEXT_PART_2: `to join SUMO1, but your email is already associated with another SUMO1 account.`,
  LAST_SUPER_ADMIN_NOTE: `You are the only Super Admin in the org. You must first login to your existing org and transfer "Super Admin" to another user, before accepting this invite. Contact SUMO Support for assistance.`,
  TITLE: `Leave Account`,
});

export const DialogUserAccounts = Object.freeze({
  ACCEPT_TEXT: `Would you like to accept this invite and leave the other SUMO1 account?`,
  INVITE_FROM: `Invite from:`,
  INVITE_TEXT: `You've been invited to join SUMO1, but you are trying to register for a new SUMO1 account.`,
  SELECT_TEXT: `Select below if you would like to accept this invite instead of creating a new SUMO1 company account.`,
  STAY_IN_MY_ACCOUNT: `Stay in my account`,
  TITLE: `Accept Invite`,
});
