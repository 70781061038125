export const Billing = Object.freeze({
  TITLE: `Facturation`,
  YOUR_SUBSCRIPTION: `Votre Abonnement`,
  TEAMS_MONTHLY: `Teams Mensuel`,
  TEAMS_ANNUAL: `Teams Annuel`,
  SEATS: `sièges`,
  LAST_UPDATED: `Dernière mise à jour le`,
  CHANGE_TERM: `Changer de Durée`,
  ADD_SEATS: `Ajouter des Sièges`,
  REMOVE_SEATS: `Supprimer des Sièges`,
  YOUR_NEXT_BILL: `Votre Prochaine Facture`,
  DUE_ON: `Dû le`,
  BILL_IS_LATE_PART_1: `La facture est actuellement`,
  BILL_IS_LATE_PART_2_SINGLE: `jour en retard.`,
  BILL_IS_LATE_PART_2_MULTIPLE: `jours en retard.`,
  BILLING_DETAILS: `Détails de Facturation`,
  CHARGEBEE_BUTTON: `Ouvrir le Portail de Facturation`,
  BILLING_PORTAL_DESRIPTION: `Vous pouvez consulter votre historique de transactions et plus encore.`,
  SCHEDULED_CHANGES: `Changements programmés pour`,
  SCHEDULED_CHANGES_DESRIPTION: `Les changements suivants ont été demandés pour prendre effet à la fin du terme actuel et peuvent être annulés avant la fin du terme.`,
  SCHEDULED_SEATS_CHANGE_PART_1: `Réduire`,
  SCHEDULED_SEATS_CHANGE_PART_2: `sièges`,
  SCHEDULED_TERM_CHANGE: `Terme changé à`,
  SCHEDULED_COLUMN_LABEL: `Changement de commande`,
  SCHEDULED_COLUMN_CHANGE_BY: `Changé par`,
  SCHEDULED_COLUMN_DATE: `Date de la demande`,
  SCHEDULED_COLUMN_OPTIONS: `Options`,
  ADD_SEATS_DESCRIPTION_PART_1: `Ajouter`,
  ADD_SEATS_DESCRIPTION_PART_2: `siège(s) ajoutera`,
  ADD_SEATS_DESCRIPTION_PART_3: `à votre facture`,
  ADD_SEATS_DESCRIPTION_PART_4: `à partir de`,
  ADD_SEATS_PRORATED_PART_1: `Pendant les`,
  ADD_SEATS_PRORATED_PART_2: `jours restants jusqu'à votre prochain paiement, votre carte de crédit sera débitée aujourd'hui d'un montant au prorata de`,
  ADD_SEATS_PRORATED_PART_3: ``,
  REMOVE_SEATS_DESCRIPTION_PART_1: `Supprimer`,
  REMOVE_SEATS_DESCRIPTION_PART_2: `siège(s) réduira votre facture`,
  REMOVE_SEATS_DESCRIPTION_PART_3: `de`,
  REMOVE_SEATS_DESCRIPTION_PART_4: ``,
  EXPIRE_MESSAGE_PART_1: `La facture est`,
  EXPIRE_MESSAGE_PART_2: `jours en retard. Votre organisation sera inopérante dans`,
  EXPIRE_MESSAGE_PART_3: `jours.`,
  NUMBER_OF_LICENSES: `# Licences`,
  LICENSES_TITLE: `licences`,
  LICENSES_PLACEHOLDER: `Entrez un nombre`,
  LICENSE_MESSAGE_PART_1: `Vous avez déjà`,
  LICENSE_MESSAGE_PART_2: `licences attribuées aux utilisateurs. Veuillez en attribuer davantage`,
  LICENSE_MESSAGE_PART_3: `ou désactiver/supprimer des utilisateurs pour libérer des licences`,
  LOADING_LABEL_1: `Connexion…`,
  LOADING_LABEL_2: `Ajout de Licences`,
  PAY_NOW_TITLE: `Payer maintenant`,
  TRIAL_LICENSE_EXPIRED: `Votre licence SUMO1 a expiré. Pour continuer à utiliser SUMO1 sans interruption, veuillez acheter une nouvelle licence.`,
  BUY_LICENSES_MESSAGE: `Votre facture sera`,
  MONTH_TITLE: `mois`,
  MONTHLY_TITLE: `mensuel`,
  ANNUAL_TITLE: `annuel`,
  ANNUALLY_TITLE: `annuellement`,
  REMOVE_SEATS_NOTE_PART1: `Pour supprimer plus de`,
  REMOVE_SEATS_NOTE_PART2: `siège(s), vous pouvez désactiver ou supprimer des utilisateurs pour libérer des licences.`,
  CANCELLATION_SCHEDULED: `Votre abonnement est prévu d'être annulé le `,
  ACCOUNT_SUSPENDED_PART1: `Votre compte est suspendu. Vous devez`,
  ACCOUNT_SUSPENDED_PART2: `payer votre facture impayée`,
  ACCOUNT_SUSPENDED_PART3: `pour réactiver le service.`,
});

export const BillingToastsNotifications = Object.freeze({
  GET_BILLING_ERROR_TOAST: `Erreur de connexion ChargeBee`,
  GET_ITEM_TIERS_ERROR_TOAST: `Impossible d'obtenir les prix des licences`,
  BUY_LICENSE_SUCCESS_TOAST: `Abonnement créé avec succès`,
  ADD_SEATS_SUCCESS_TOAST_TITLE: `Sièges ajoutés.`,
  ADD_SEATS_SUCCESS_TOAST: `Veuillez patienter jusqu'à 1 minute avant que nous recevions votre paiement.`,
  ADD_SEATS_ERROR_TOAST: `Impossible d'ajouter des sièges`,
  REMOVE_SEATS_SUCCESS_TOAST_TITLE: `Sièges supprimés.`,
  REMOVE_SEATS_SUCCESS_TOAST: `Vous devez désattribuer les sièges avant la fin du terme.`,
  REMOVE_SEATS_ERROR_TOAST: `Impossible de supprimer des sièges`,
  CHANGE_TERM_SUCCESS_TOAST: `Terme changé avec succès.`,
  CHANGE_TERM_ERROR_TOAST: `Impossible de changer le terme.`,
  CHARGEBEE_PORTAL_CLOSE_TOAST: `Si vous avez effectué des modifications, veuillez attendre jusqu'à 1 minute avant que nous ne recevions votre demande.`,
  DELETE_CHANGE_SUCCESS_TOAST: `Modification planifiée supprimée avec succès.`,
  DELETE_CHANGE_ERROR_TOAST: `Impossible de supprimer la modification planifiée.`,
});
