export const Journey = Object.freeze({
  TITLE: `Journey Builder`,
  CLONE_TITLE: `Enter cloned journey name`,
  ADD_LABEL: `Create Journey`,
  CREATED_BY: `Created by`,
  EMPTY_LIST_DESCRIPTION: `Create a Journey or adjust your search or filters.`,
  RESPONSE: `response`,
  RESPONSES: `responses`,
  LAST: `Last`,
  DEACTIVATE_TITLE: `Deactivate Journey`,
  DEACTIVATE_DESCRIPTION: `Are you sure you would like to deactivate`,
  DELETE_TITLE: `Delete Journey`,
  DELETE_DESCRIPTION: `Are you sure you would like to delete`,
  SELECTED_JOURNEY: `selected journeys`,
  NAME_LABEL: `Name`,
});

export const EditJourney = Object.freeze({
  JOURNEY_TITLE: `Journey`,
  RESPONSES_TITLE: `Responses`,
  DELETE_STEP_TITLE: `Delete Step`,
  DELETE_STEP_DESCRIPTION: `Are you sure you would like to delete this step?`,
  DELETE_STEP_ADDITIONAL_TEXT: `Please note that this action will also permanently remove all subsequent child steps linked to it.`,
  START_NODE_TITLE: `Your journey starts here`,
  CONDITION_TITLE: `Condition`,
  DESTINATION_TITLE: `Destination`,
  PAGE_TITLE: `Page`,
  WITH_CARDS: `with cards`,
  WITH_FORM: `with form`,
  IF_LABEL: `If`,
  OR_LABEL: `Or`,
  ADD_LABEL: `Add`,
  CUSTOM_NAME: `Custom name`,
  CREATE_JOURNEY: `Create Journey`,
  EDIT_JOURNEY: `Edit Journey`,
  CARD: `Card`,
  LOGIC: `Logic`,
  PREVIEW_JOURNEY: `Preview Journey`,
});

export const JourneysPageStep = Object.freeze({
  SELECTION_BOX: `Selection box`,
  CARD_TITLE: `Title here*`,
  CARD_DETAILS: `Details here*`,
  FORM_INPUT_TILE: `Input Title`,
  FORM_INPUT_SUBTITLE: `Input Subtitle`,
  FORM_WRITE_QUESTION: `Write a question`,
  FORM_INPUT_REQUIRED: `This input is required`,
  FORM_QUESTION_REQUIRED: `This question is required`,
  FORM_ANSWER: `Answer`,
  FORM_ADD_ANSWER: `+ Add an answer`,
  TITLE: `Title`,
  TITLE_PLACEHOLDER: `Input page title`,
  NAME: `Name`,
  PAGE_NAME: `Page name`,
  PAGE_TYPE: `Page type`,
  NO_ELEMENTS: `No Elemnets added`,
  INPUTS: `Inputs`,
  SELECTION: `Selection`,
  SELECT_TYPE: `Select type first`,
});

export const JourneysDestinationStep = Object.freeze({
  TYPE_LABEL: `Destination type`,
  SHOWS_STEP_SEPARATLY: `Show steps separately`,
  WHO_DESCRIPTION: `List of Specialists to select`,
  WHEN_DESCRIPTION: `Calendar and Schedule`,
  WHERE_DESCRIPTION: `Map with offices to select`,
  HEADER_LABEL: `Header`,
  MESSAGE_LABEL: `Message`,
  URL_LABEL: `External URL`,
});

export const JourneyDefault = Object.freeze({
  JOURNEY_NAME: `Custom Journey`,
  PAGE_NAME: `Custom`,
  SUBMIT_LABEL: `Submit`,
  FORM: `Form`,
  CARDS: `Cards`,
  IMPORT: `Import`,
  TITLE: `Title`,
  SUBTITLE: `Subtitle`,
  NAME: `Name`,
  SINGLE: `Single Line`,
  EMAIL: `Email`,
  MULTIPLE: `Multiple Lines`,
  PHONE: `Phone Number`,
  ATTACHMENT: `Attachment`,
  DROPDOWN: `Dropdown`,
  RADIO_BUTTONS: `Radio Buttons`,
  IS: `is`,
  IS_NOT: `is not`,
  CONTAINS: `contains`,
  DOES_NOT_CONTAIN: `does not contain`,
  STARTS_WITH: `starts with`,
  IS_NOT_EMPTY: `is not empty`,
  IS_EMPTY: `is empty`,
  RANGE: `is range`,
  FILETYPE_IS: `filetype is`,
  FILETYPE_IS_NOT: `filetype is not`,
  SIZE_IS_BIGGER_THAN: `size is bigger than`,
  SIZE_IS_SMALLER_THAN: `size is smaller than`,
  BOOKING_PAGE: `Booking Page`,
  MESSAGE: `Message`,
  EXTERNAL_URL: `External URL`,
  CHAT_AI: `Chat AI`,
});

export const JourneysToastsNotifications = Object.freeze({
  GET_JOURNEYS_ERROR_MESSAGE: `Get journeys request fail`,
  DEACTIVATE_JOURNEY_SUCCESS_MESSAGE: `Journey successfully deactivated`,
  ACTIVATE_JOURNEY_SUCCESS_MESSAGE: `Journey successfully activated`,
  DEACTIVATE_JOURNEY_ERROR_MESSAGE: `Activate/Deactivate journey request fail`,
  CLONE_JOURNEY_SUCCESS_MESSAGE: `Journey successfully cloned`,
  CLONE_JOURNEY_ERROR_MESSAGE: `Clone journey request fail`,
  DELETE_JOURNEYS_SUCCESS_MESSAGE: `Journeys successfully deleted`,
  DELETE_JOURNEYS_ERROR_MESSAGE: `Delete journeys request fail`,
  GET_JOURNEY_ERROR_MESSAGE: `Get journey request fail`,
  SAVE_JOURNEY_ERROR_MESSAGE: `Save journey request fail`,
  CREATE_JOURNEY_SUCCESS_MESSAGE: `Journey successfully created`,
  UPDATE_JOURNEY_SUCCESS_MESSAGE: `Journey successfully updated`,
  GET_JOURNEY_EVENTS_ERROR_MESSAGE: `Get journey events request fail`,
});

export const JourneysEvent = Object.freeze({
  VIEW_EVENT: `View Event`,
  TITLE: `Reponses`,
  NO_RESPONSES: `No responses yet`,
  EMPTY_LIST_DESCRIPTION: `Adjust your search or filters.`,
  DATE_RANGE: `Date Range`,
  RESPONSE: `Response`,
  EVENT_SCHEDULED: `Event Scheduled`,
  CREATED_AT: `Created At`,
  RESULT: `Result`,
  ANSWERS: `Answers`,
  DESTINATION: `Destination`,
});
