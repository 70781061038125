import { t } from '../../../i18n/i18n';

export default {
  clone: t('Common:CLONE'),
  cloneAlertTitle: t('SmartAlerts:CLONE_ALERT_TITLE'),
  cloneTemplateTitle: t('SmartAlerts:CLONE_TEMPLATE_TITLE'),
  cancel: t('Common:CANCEL'),
  dialogPlaceholder: t('SmartAlerts:DIALOG_PLACEHOLDER'),
  confirm: t('Common:CONFIRM'),
};
