import { BackgroundType, BookingPageLabelsInput, BookingPageStyleInput } from '../../API';
import { t } from '../../i18n/i18n';
import { ToastNotificationOptions } from '../notifications';
import { AccordionIndexes, WorkspaceData } from './types';
import urlConstants from '../../shared/JSON/urlConstants.json';
import {
  TOAST_ERROR_COLOR,
  TOAST_ERROR_DURATION,
  TOAST_SUCCESS_COLOR,
  TOAST_SUCCESS_DURATION,
} from '../../types/constants';

export const DEFAULT_WORKSPACE_NAME = t('Workspaces:DEFAULT_WORKSPACE');
export const NEW_WORKSPACE_NAME = t('Workspaces:NEW_WORKSPACE');
export const DEFAULT_BOOKING_PAGE_INDEX = 0;

export const DEFAULT_BOOKING_PAGE_HOW: BookingPageLabelsInput = {
  eventNameLabel: t('DefaultBookingPage:BOOK_A_MEETING'),
  detailsLabel: t('DefaultBookingPage:MEETING_DETAILS'),
  guestsLabel: t('DefaultBookingPage:GUESTS_INFO'),
  cancelLabel: t('DefaultBookingPage:CANCEL'),
  bookButtonLabel: t('DefaultBookingPage:BOOK_MEETING_BUTTON'),
  updateButtonLabel: t('DefaultBookingPage:SAVE_MEETING_BUTTON'),
  bookAnotherButtonLabel: t('DefaultBookingPage:BOOK_ANOTHER_MEETING_BUTTON'),
  cancelButtonLabel: t('DefaultBookingPage:CANCEL_MEETING_BUTTON'),
  confirmCancelButtonLabel: t('DefaultBookingPage:CONFIRM_CANCEL_BUTTON'),
  bookTitle: t('DefaultBookingPage:MEETING_BOOKED_TITLE'),
  bookDescription: t('DefaultBookingPage:MEETING_BOOKED_DESCRIPTION'),
  rescheduleTitle: t('DefaultBookingPage:MEETING_RESCHEDULED_TITLE'),
  rescheduleDescription: t('DefaultBookingPage:MEETING_RESCHEDULED_DESCRIPTION'),
  cancelTitle: t('DefaultBookingPage:MEETING_CANCELED_TITLE'),
  cancelDescription: t('DefaultBookingPage:MEETING_CANCELED_DESCRIPTION'),
};

export const DEFAULT_STYLE: BookingPageStyleInput = {
  logoImage: null,
  backgroundImage: null,
  backgroundColor: '#f7f7f7',
  backgroundType: BackgroundType.BLANK,
  primaryColor: '#0066e0',
  css: null,
  footerHtml: `<a href="${urlConstants.NAME_URL_SITE}" target="_blank">Powered by SUMO</a>`,
};

export const DEFAULT_WORKSPACE: WorkspaceData = {
  id: '',
  name: NEW_WORKSPACE_NAME,
  isActive: true,
  adminList: [],
  userList: [],
  isPhoneRequired: true,
  labels: DEFAULT_BOOKING_PAGE_HOW,
  style: DEFAULT_STYLE,
};

export const GET_WORKSPACES_ERROR_TOAST: ToastNotificationOptions = Object.freeze({
  color: TOAST_ERROR_COLOR,
  autoHideDuration: TOAST_ERROR_DURATION,
  message: t('WorkspaceToastsNotifications:GET_WORKSPACES_ERROR_MESSAGE'),
});

export const ACTIVATE_WORKSPACE_SUCCESS_TOAST: ToastNotificationOptions = Object.freeze({
  color: TOAST_SUCCESS_COLOR,
  autoHideDuration: TOAST_SUCCESS_DURATION,
  message: t('WorkspaceToastsNotifications:ACTIVATE_WORKSPACE_SUCCESS_MESSAGE'),
});

export const INACTIVATE_WORKSPACE_SUCCESS_TOAST: ToastNotificationOptions = Object.freeze({
  color: TOAST_SUCCESS_COLOR,
  autoHideDuration: TOAST_SUCCESS_DURATION,
  message: t('WorkspaceToastsNotifications:INACTIVATE_WORKSPACE_SUCCESS_MESSAGE'),
});

export const ACTIVATE_INACTIVATE_WORKSPACE_ERROR_TOAST: ToastNotificationOptions = Object.freeze({
  color: TOAST_ERROR_COLOR,
  autoHideDuration: TOAST_ERROR_DURATION,
  message: t('WorkspaceToastsNotifications:ACTIVATE_INACTIVATE_WORKSPACE_ERROR_MESSAGE'),
});

export const DELETE_WORKSPACE_SUCCESS_TOAST: ToastNotificationOptions = Object.freeze({
  color: TOAST_SUCCESS_COLOR,
  autoHideDuration: TOAST_SUCCESS_DURATION,
  message: t('WorkspaceToastsNotifications:DELETE_WORKSPACE_SUCCESS_MESSAGE'),
});

export const DELETE_WORKSPACE_ERROR_TOAST: ToastNotificationOptions = Object.freeze({
  color: TOAST_ERROR_COLOR,
  autoHideDuration: TOAST_ERROR_DURATION,
  message: t('WorkspaceToastsNotifications:DELETE_WORKSPACE_ERROR_MESSAGE'),
});

export const SAVE_WORKSPACE_SUCCESS_TOAST: ToastNotificationOptions = Object.freeze({
  color: TOAST_SUCCESS_COLOR,
  autoHideDuration: TOAST_SUCCESS_DURATION,
  message: t('WorkspaceToastsNotifications:SAVE_WORKSPACE_SUCCESS_MESSAGE'),
});

export const SAVE_WORKSPACE_ERROR_TOAST: ToastNotificationOptions = Object.freeze({
  color: TOAST_ERROR_COLOR,
  autoHideDuration: TOAST_ERROR_DURATION,
  message: t('WorkspaceToastsNotifications:SAVE_WORKSPACE_ERROR_MESSAGE'),
});

export const CLONE_WORKSPACE_ERROR_TOAST: ToastNotificationOptions = Object.freeze({
  color: TOAST_ERROR_COLOR,
  autoHideDuration: TOAST_ERROR_DURATION,
  message: t('WorkspaceToastsNotifications:CLONE_WORKSPACE_ERROR_MESSAGE'),
});

export const CLONE_WORKSPACE_SUCCESS_TOAST: ToastNotificationOptions = Object.freeze({
  color: TOAST_SUCCESS_COLOR,
  autoHideDuration: TOAST_SUCCESS_DURATION,
  message: t('WorkspaceToastsNotifications:CLONE_WORKSPACE_SUCCESS_MESSAGE'),
});

export const REMOVE_INTEGRATION_SUCCESS_TOAST: ToastNotificationOptions = Object.freeze({
  color: TOAST_SUCCESS_COLOR,
  autoHideDuration: TOAST_SUCCESS_DURATION,
  message: t('WorkspaceToastsNotifications:REMOVE_INTEGRATION_SUCCESS_TOAST'),
});

export const EMPTY_ACCORDION_INDEXES: AccordionIndexes = {
  what: false,
  who: false,
  how: false,
  security: false,
};
