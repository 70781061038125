import { t } from '../../../i18n/i18n';

export default {
  confirmDeleteTitle: t('UsersConfirmDialog:DELETE_TITLE'),
  confirmDeleteDesc: t('UsersConfirmDialog:DELETE_DESCRIPTION'),
  confirmDeleteCommentF: t('UsersConfirmDialog:DELETE_COMMENT_1'),
  confirmDeleteNoteTitle: t('UsersConfirmDialog:DELETE_NOTE_TITLE'),
  confirmDeleteNoteDesc: t('UsersConfirmDialog:DELETE_NOTE_DESCRIPTION'),
  confirmDisableTitle: t('UsersConfirmDialog:DISABLE_TITLE'),
  confirmDisableDesc: t('UsersConfirmDialog:DISABLE_DESCRIPTION'),
  confirmDisableCommentF: t('UsersConfirmDialog:DISABLE_COMMENT_1'),
  confirmDisableCommentS: t('UsersConfirmDialog:DISABLE_COMMENT_2'),
  noCancel: t('Common:NO_CANCEL'),
  yes: t('Common:YES'),
  yesDelete: t('Common:YES_DELETE'),
};
