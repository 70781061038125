import { t } from '../../../i18n/i18n';

export default {
  access: [t('EditRole:READ'), t('EditRole:CREATE'), t('EditRole:EDIT'), t('EditRole:DELETE')],
  accessToAllWorkspaces: t('EditRole:ACCESS_TO_ALL_WORKSPACES'),
  active: t('EditRole:ACTIVE'),
  adminTitle: t('EditRole:ADMIN_TITLE'),
  chromeExtensionTitle: t('EditRole:CHROME_EXTENSION_TITLE'),
  featureAccessTitle: t('EditRole:FEATURE_ACCESS_TITLE'),
  permissionsLabel: {
    users: t('Common:USERS'),
    availability: t('Availability:TITLE'),
    myBookingPages: t('EditRole:MY_BOOKING_PAGE'),
    allBookingPages: t('EditRole:ALL_BOOKING_PAGE'),
    bookingTemplates: t('EditRole:BOOKING_TEMPLATES'),
    resources: t('EditRole:RESOURCES_TITLE'),
    teams: t('EditRole:TEAMS'),
    smartAlerts: t('SmartAlerts:TITLE'),
    smartAlertTemplates: t('EditRole:SMART_ALERT_TEMPLATES'),
    locations: t('EditRole:LOCATIONS'),
    journey: t('EditRole:JOURNEY'),
  },
  permissionsTitle: t('EditRole:PERMISSIONS_TITLE'),
  searchAddWorkspace: t('EditRole:SEARCH_ADD_WORKSPACE'),
  superAdmin: t('EditRole:SUPER_ADMIN'),
  workspaceAdmin: t('EditRole:WORKSPACE_ADMIN'),
  workspaceAdminDesc: t('EditRole:WORKSPACE_ADMIN_DESCRIPTION'),
};
