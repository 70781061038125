import { t } from '../../../i18n/i18n';

export default {
  name: t('Users:NAME'),
  email: t('Common:EMAIL'),
  role: t('Users:ROLE'),
  status: t('Users:STATUS'),
  lastLogin: t('OPSConsoleStaff:LAST_LOGIN'),
  title: t('OPSConsoleStaff:TITLE'),
  search: t('Common:SEARCH'),
  addStaff: t('OPSConsoleStaff:ADD_STAFF_TITLE'),
  deleteTitle: t('UsersConfirmDialog:DELETE_TITLE'),
  deleteMessage: t('UsersConfirmDialog:DELETE_DESCRIPTION'),
  deactivateTitle: t('UsersConfirmDialog:DISABLE_TITLE'),
  deactivateMessage: t('UsersConfirmDialog:DISABLE_DESCRIPTION'),
  yes: t('Common:YES'),
  no: t('Common:NO'),
};
