export const SmartAlerts = Object.freeze({
  TITLE: `Alertes intelligentes`,
  CREATE_ALERT: `Créer une alerte`,
  CLONE_ALERT_TITLE: `Nom de l'alerte intelligente clonée`,
  CLONE_TEMPLATE_TITLE: `Nom du modèle d'alerte intelligente clonée`,
  DIALOG_PLACEHOLDER: `Rappel pour l'invité, Envoyer des remerciements, etc...`,
  EMPTY_LIST_MESSAGE: `Créez une alerte ou ajustez votre recherche ou vos filtres.`,
  PERSONAL_LABEL: `Personnel`,
  SHARED_LABEL: `Partagé`,
  TEMPLATES_LABEL: `Modèles`,
  HIDDEN_LABEL: `Caché`,
  UNHIDDEN_LABEL: `Visible`,
  BASIC_ALERTS: `Alertes de base`,
  ADD_SMART_ALERT: `+ Ajouter une alerte intelligente`,
  DELETE_DESCRIPTION: `Êtes-vous sûr de vouloir supprimer`,
  BULK_DELETE_DESCRIPTION: `les alertes intelligentes sélectionnées`,
  DELETE_WARNING_ONE: `Attention : La suppression de cette alerte intelligente la retirera également des pages de réservation et des modèles de réservation où elle est utilisée.`,
  DELETE_WARNING_MULTIPLE: `Attention : La suppression de ces alertes intelligentes les retirera également des pages de réservation et des modèles de réservation où elles sont utilisées.`,
});

export const SmartAlertsToastsNotifications = Object.freeze({
  GET_SMART_TYPES_ERROR_MESSAGE: `Échec de la récupération des alertes intelligentes`,
  CLONE_SMART_TYPE_ERROR_MESSAGE: `Échec du clonage des alertes intelligentes`,
  CLONE_SMART_ALERT_SUCCESS_MESSAGE: `Alerte intelligente clonée avec succès`,
  CLONE_SMART_TEMPLATE_SUCCESS_MESSAGE: `Modèle d'alerte intelligente cloné avec succès`,
  DELETE_SMART_TYPES_SUCCESS_MESSAGE: `Alertes intelligentes supprimées avec succès`,
  DELETE_SMART_TYPES_ERROR_MESSAGE: `Échec de la suppression des alertes intelligentes`,
  GET_SMART_ALERT_ERROR_MESSAGE: `Échec de la récupération de l'alerte intelligente`,
  GET_SMART_TEMPLATE_ERROR_MESSAGE: `Échec de la récupération du modèle d'alerte intelligente`,
  UPSERT_SMART_TYPE_ERROR_MESSAGE: `Échec de l'insertion ou de la mise à jour des alertes intelligentes`,
  CREATE_SMART_ALERT_SUCCESS_MESSAGE: `Alerte intelligente créée avec succès`,
  CREATE_SMART_TEMPLATE_SUCCESS_MESSAGE: `Modèle d'alerte intelligente créé avec succès`,
  UPDATE_SMART_ALERT_SUCCESS_MESSAGE: `Alerte intelligente mise à jour avec succès`,
  UPDATE_SMART_TEMPLATE_SUCCESS_MESSAGE: `Modèle d'alerte intelligente mis à jour avec succès`,
});

export const EditSmartAlert = Object.freeze({
  CREATE_TITLE: `Créer une alerte`,
  CREATE_CUSTOM_TITLE: `+ Créez votre alerte personnalisée`,
  DESCRIPTION: `Créez votre alerte personnalisée ou utilisez des modèles pour créer rapidement des alertes`,
  EDIT_TITLE: `Modifier l'alerte`,
  ICON: `Icône`,
  NAME: `Nom`,
  NAME_PLACEHOLDER: `Entrez le nom de l'alerte`,
  HIDE: `Masquer`,
  HIDE_DESCRIPTION: `Ne pas afficher dans la liste des Smart Alerts`,
  ALERT: `Alerte`,
  TEMPLATE: `Modèle`,
  APPLY_TO: `Appliquer à`,
  SHARE_WITH: `Partager avec`,
  WORKSPACES: `Espaces de travail`,
  WHEN_TITLE: `Quand cela arrive`,
  DO_THIS: `Faites cela`,
  PERSONALIZE: `Personnaliser`,
  ADD_VARIABLE: `Ajouter une variable`,
  SUBJECT: `Sujet`,
  BODY: `Corps`,
  USE_TEMPLATE: `Utiliser le modèle`,
  HOW_LONG_AFTER: `Combien de temps après`,
  IMMEDIATELY: `Immédiatement`,
  CUSTOM_NAME: `Nom personnalisé`,
  TEMPLATE_DESCRIPTION_PLACEHOLDER: `Entrez la description du modèle intelligent`,
  USE_ALERT: `Utiliser l'alerte`,
  SELECT_SMART_TITLE: `Sélectionner l'alerte`,
  SELECT_ALERT_DESCRIPTION: `Sélectionnez une alerte intelligente ou créez la vôtre`,
  BOOKING_PAGES: `Pages de réservation`,
  BOOKING_TEMPLATES: `Modèles de réservation`,
  SMS_TO_HOST_TOOLTIP: `Si l'hôte n'a pas le numéro de téléphone, le rappel par SMS ne sera pas envoyé`,
  NEEDS_YOUR_ATTENTION: `Nécessite votre attention`,
});

export const SmartAlertsPersonalize = Object.freeze({
  EMAIL_TO_INVITE_SUBJECT: `Rappel : &ltMeeting Name&gt avec &ltHost Name&gt à &ltMeeting Time&gt, &ltMeeting Date&gt`,
  EMAIL_TO_INVITE_BODY: `Bonjour &ltInvitee Name&gt,<br><br>Ce rappel amical pour votre &ltMeeting Name&gt avec &ltHost Name&gt à &ltMeeting Time&gt, &ltMeeting Date&gt.<br><br><b>Lieu</b><br>&ltMeeting Location&gt<br><br>&ltVoir ou modifier le lien de la réunion&gt`,
  EMAIL_TO_HOST_SUBJECT: `Rappel : &ltMeeting Name&gt avec &ltInvitee Name&gt à &ltMeeting Time&gt, &ltMeeting Date&gt`,
  EMAIL_TO_HOST_BODY: `Bonjour &ltHost Name&gt,<br><br>Ce rappel amical pour votre &ltMeeting Name&gt avec &ltInvitee Name&gt à &ltMeeting Time&gt, &ltMeeting Date&gt.<br><br><b>Lieu</b><br>&ltMeeting Location&gt<br><br>&ltVoir ou modifier le lien de la réunion&gt`,
  SMS_TO_INVITE: `Ce rappel amical pour votre &ltMeeting Name&gt avec &ltHost Name&gt à &ltMeeting Time&gt, &ltMeeting Date&gt. Voir ou modifier la réunion &ltMeeting Link&gt`,
  SMS_TO_HOST: `Ce rappel amical pour votre &ltMeeting Name&gt avec &ltInvitee Name&gt à &ltMeeting Time&gt, &ltMeeting Date&gt. Voir ou modifier la réunion &ltMeeting Link&gt`,
  TITLE_EMAIL_TO_HOST: `Personnaliser l'email au hôte`,
  TITLE_EMAIL_TO_INVITE: `Personnaliser l'email à l'invité`,
  TITLE_SMS_TO_HOST: `Personnaliser le rappel par SMS au hôte`,
  TITLE_SMS_TO_INVITE: `Personnaliser le rappel par SMS à l'invité`,
});

export const SmartAlertsWhen = Object.freeze({
  HOW_LONG_EVENT_BOOKED: `Combien de temps après la réservation du nouvel événement?`,
  EVENT_ENDS: `L'événement se termine`,
  EVENT_BEFORE_START: `Avant le début de l'événement`,
  EVENT_CANCELLED: `L'événement est annulé`,
  EVENTS_STARTS: `L'événement commence`,
  EVENT_RESCHEDULED: `L'événement a été reprogrammé`,
  HOW_LONG_EVENT_ENDS: `Combien de temps après la fin de l'événement?`,
  HOW_LONG_EVENT_BEFORE_START: `Combien de temps avant le début de l'événement?`,
  HOW_LONG_EVENT_CANCELLED: `Combien de temps après l'annulation de l'événement?`,
  HOW_LONG_EVENTS_STARTS: `Combien de temps après le début de l'événement?`,
  HOW_LONG_EVENT_RESCHEDULED: `Combien de temps après la reprogrammation de l'événement?`,
  MINUTE: `Minute`,
  MINUTES: `Minutes`,
  HOUR: `Heure`,
  HOURS: `Heures`,
  DAY: `Jour`,
  DAYS: `Jours`,
  WHEN_EVENT_BOOKED: `Lorsque le nouvel événement est réservé`,
  WHEN_EVENT_STARTS: `Lorsque l'événement commence`,
  WHEN_EVENT_ENDS: `Lorsque l'événement se termine`,
  WHEN_EVENT_CANCELED: `Lorsque l'événement est annulé`,
  WHEN_EVENT_RESCHEDULED: `Lorsque l'événement est reprogrammé`,
  AFTER: `après `,
});

export const SmartAlertsWhom = Object.freeze({
  EMAIL_TO_INVITEE: `Envoyer un e-mail à l'invité`,
  EMAIL_TO_HOST: `Envoyer un e-mail à l'hôte`,
  SMS_TO_INVITEE: `Envoyer un rappel par SMS à l'invité`,
  SMS_TO_HOST: `Envoyer un rappel par SMS à l'hôte`,
  SEND_ALL: `Envoyer un e-mail et un rappel par SMS à l'invité et à l'hôte`,
  SEND_EMAIL_SMS_INVITEE: `Envoyer un e-mail et un rappel par SMS à l'invité`,
  SEND_EMAIL_SMS_HOST: `Envoyer un e-mail et un rappel par SMS à l'hôte`,
});

export const SmartAlertsWarningModal = Object.freeze({
  WARNING_BP_AND_BT: `Avertissement : ce changement affectera les prochaines pages et modèles de réservation :`,
  WARNING_BP: `Avertissement : ce changement affectera les prochaines pages de réservation :`,
  WARNING_BT: `Avertissement : ce changement affectera les prochains modèles de réservation :`,
  TITLE: `Avertissement`,
  UPDATE_ALL: `Tout mettre à jour`,
  CREATE_NEW: `Créer une nouvelle alerte intelligente`,
});
