import { t } from '../../../i18n/i18n';

export default {
  allowSms: t('PublicBookingPage:ALLOW_SMS'),
  name: t('PublicBookingPage:NAME_FIELD_LABEL'),
  email: t('PublicBookingPage:EMAIL_FIELD_LABEL'),
  inviteOthers: t('PublicBookingPage:INVITE_OTHERS_FIELD_LABEL'),
  inviteOthersPlaceholder: t('PublicBookingPage:INVITE_OTHERS_FIELD_PLACEHOLDER'),
  noteForAttendees: t('PublicBookingPage:NOTE_FOR_ATTENDEES_FIELD_LABEL'),
  notePlaceholder: t('PublicBookingPage:NOTE_FOR_ATTENDEES_FIELD_PLACEHOLDER'),
  personalInfo: t('PublicBookingPage:PERSONAL_INFO'),
  AttendeeEmail: t('PublicBookingPage:ATTENDEE_EMAIL'),
  addAttendees: t('PublicBookingPage:ADD_ATTENDEES'),
  yourAnswers: t('PublicBookingPage:YOUR_ANSWERS'),
};
