import {
  AttachmentSizeType,
  AttachmentType,
  JourneyCard,
  JourneyConditionType,
  JourneyDestinationType,
  JourneyFormField,
  JourneyFormFieldType,
  JourneyPageType,
  JourneyStep,
  JourneyStepType,
} from '../../API';
import { v4 as uuid } from 'uuid';
import {
  CARD,
  CONDTION_ATTACHMENT_SIZE_OPTIONS,
  CONDTION_ATTACHMENT_TYPE_OPTIONS,
  CONDTION_TYPE_OPTIONS,
  DESTINATION_TYPE_OPTIONS,
  EVENT_FILTER_OPTIONS,
  FORM_FIELD_LABELS,
  NODE_HEIGHT,
} from './constants';
import { t } from '../../i18n/i18n';

export const getColorByStepType = (stepType: JourneyStepType | null | undefined) => {
  switch (stepType) {
    case JourneyStepType.CONDITION:
      return '#DC334B';
    case JourneyStepType.DESTINATION:
      return '#181F30';
    case JourneyStepType.PAGE:
      return '#49A1FF';
    default:
      return '';
  }
};

export const getStepTypeDesc = (stepType: JourneyStepType | null | undefined) => {
  switch (stepType) {
    case JourneyStepType.CONDITION:
      return t('EditJourney:CONDITION_TITLE');
    case JourneyStepType.DESTINATION:
      return t('EditJourney:DESTINATION_TITLE');
    case JourneyStepType.PAGE: {
      return t('EditJourney:PAGE_TITLE');
    }
    default:
      return '';
  }
};

export const getStepTypeTitle = (stepType: JourneyStepType | null | undefined) => {
  switch (stepType) {
    case JourneyStepType.CONDITION:
      return t('EditJourney:LOGIC');
    case JourneyStepType.DESTINATION:
      return t('EditJourney:DESTINATION_TITLE');
    case JourneyStepType.PAGE: {
      return t('EditJourney:PAGE_TITLE');
    }
    default:
      return '';
  }
};

export const addJourneyStep = (
  root: JourneyStep | null | undefined,
  step: JourneyStep,
  parentId: string
): JourneyStep | null => {
  if (!root) {
    return null;
  }
  const isParent = root.id === parentId;
  if (isParent) {
    return {
      ...root,
      children: [...(root.children || []), step],
    };
  } else if (root.children && root.children.length) {
    const updatedChildren = root.children.map((child) => child && addJourneyStep(child, step, parentId));
    return {
      ...root,
      children: updatedChildren,
    };
  }
  return root;
};

export const editJourneyStep = (
  step: JourneyStep | null | undefined,
  updatedStep: JourneyStep,
  id: string
): JourneyStep | null => {
  if (!step) {
    return null;
  }
  const isStep = step.id === id;
  if (isStep) {
    return updatedStep;
  } else if (step.children && step.children.length) {
    const updatedChildren = step.children.map((child) => child && editJourneyStep(child, updatedStep, id));
    return {
      ...step,
      children: updatedChildren,
    };
  }
  return step;
};

export const copyJourneyStep = (root: JourneyStep | null | undefined, step: JourneyStep): JourneyStep | null => {
  if (!root) {
    return null;
  }
  const isParent = root.children?.some((childStep) => childStep?.id === step.id);
  if (isParent) {
    return {
      ...root,
      children: [...(root.children || []), { ...step, id: uuid(), children: [] }],
    };
  } else if (root.children && root.children.length) {
    const updatedChildren = root.children.map((child) => child && copyJourneyStep(child, step));
    return {
      ...root,
      children: updatedChildren,
    };
  }
  return root;
};

export const removeJourneyStep = (root: JourneyStep | null | undefined, id: string): JourneyStep | null => {
  if (!root) {
    return null;
  }
  const isStep = root.id === id;
  if (isStep) {
    return null;
  } else if (root.children && root.children.length) {
    const updatedChildren = root.children.map((child) => child && removeJourneyStep(child, id));
    return {
      ...root,
      children: updatedChildren.filter((child) => !!child),
    };
  }
  return root;
};

export const getJourneyStepNodeHeight = (step: JourneyStep) => {
  if (step.type !== JourneyStepType.CONDITION) {
    return NODE_HEIGHT;
  }
  let height = 28 + 16 + 32 + 32; // header + padding + condition + first answer
  let valueLength = step.condition?.value?.length || 0;
  if (step.condition?.type === JourneyConditionType.RANGE) {
    valueLength = valueLength / 2;
  }
  valueLength -= 1; // - first answer
  height += valueLength * 32;
  return Math.max(height, NODE_HEIGHT);
};

export const getDefaultFormFieldData = (type: JourneyFormFieldType) => {
  if ([JourneyFormFieldType.NAME, JourneyFormFieldType.EMAIL, JourneyFormFieldType.PHONE].includes(type)) {
    return { required: true, label: FORM_FIELD_LABELS[type] };
  } else if ([JourneyFormFieldType.DROPDOWN, JourneyFormFieldType.RADIO_BUTTONS].includes(type)) {
    return { required: false, answers: ['', ''] };
  }
  return { required: false };
};

export const getAllParents = (root: JourneyStep | null | undefined, targetId: string) => {
  const parents: JourneyStep[] = [];

  const search = (current: JourneyStep | null | undefined, parent: JourneyStep | null): boolean => {
    if (current?.id === targetId) {
      if (parent) {
        parents.push(parent);
      }
      return true;
    }

    let found = false;
    current?.children &&
      current.children.forEach((child) => {
        if (child && search(child, current)) {
          found = true;
          if (parent) {
            parents.push(parent);
          }
        }
      });

    return found;
  };

  search(root, null);
  return parents;
};

export const getAllFields = (root: JourneyStep | null | undefined) => {
  const fields: JourneyFormField[] = [];

  const search = (current: JourneyStep | null | undefined) => {
    if (current?.type === JourneyStepType.PAGE && current.page?.type === JourneyPageType.FORM) {
      current.page.formFields?.forEach((field) => field && fields.push(field));
    }

    current?.children &&
      current.children.forEach((child) => {
        child && search(child);
      });
  };

  search(root);
  return fields;
};

export const getAllCards = (root: JourneyStep | null | undefined) => {
  const cards: JourneyCard[] = [];

  const search = (current: JourneyStep | null | undefined) => {
    if (current?.type === JourneyStepType.PAGE && current.page?.type === JourneyPageType.CARD) {
      current.page.cards?.forEach((card) => card && cards.push(card));
    }

    current?.children &&
      current.children.forEach((child) => {
        child && search(child);
      });
  };

  search(root);
  return cards;
};

export const getAllConditionFieldCardIds = (root: JourneyStep | null | undefined) => {
  const ids: string[] = [];

  const search = (current: JourneyStep | null | undefined) => {
    if (current?.type === JourneyStepType.CONDITION) {
      if (current.condition?.conditionField === CARD) {
        current.condition.value?.forEach((id) => id && ids.push(id));
      } else if (current.condition?.conditionField) {
        ids.push(current.condition?.conditionField);
      }
    }

    current?.children &&
      current.children.forEach((child) => {
        child && search(child);
      });
  };

  search(root);
  return ids;
};

export const getDestinationLabel = (type: JourneyDestinationType | null | undefined) => {
  return DESTINATION_TYPE_OPTIONS.find((option) => option.value === type)?.label || '';
};

export const getConditionTypeLabel = (type: JourneyConditionType | null | undefined) => {
  return CONDTION_TYPE_OPTIONS.find((option) => option.value === type)?.label || '';
};

export const getConditionAttachmentTypeLabel = (type: AttachmentType | null | undefined) => {
  return CONDTION_ATTACHMENT_TYPE_OPTIONS.find((option) => option.value === type)?.label || '';
};

export const getConditionAttachmentSizeLabel = (type: AttachmentSizeType | null | undefined) => {
  return CONDTION_ATTACHMENT_SIZE_OPTIONS.find((option) => option.value === type)?.label || '';
};

export const getResponseLabel = (type: JourneyDestinationType | null | undefined) => {
  return EVENT_FILTER_OPTIONS.find((option) => option.value === type)?.label || '';
};
