import { useDispatch, useSelector } from 'react-redux';
import { userSettingsActions, userSettingsSelectors } from '../../../store/userSettings';
import labels from './labels';
import { ConfirmationModal } from '../../common';
import { Button } from 'primereact/button';
import { MicrosoftCodeResponse, ThirdPartyType, authenticationSelectors } from '../../../store/authentication';
import { CodeResponse } from '@react-oauth/google';
import { GoogleAuthButton, MicrosoftAuthButton } from '../../public';
import { useState } from 'react';
import { IntegrationType } from '../../../API';
import { ReactComponent as GoogleIcon } from '../../../assets/icons/32-google.svg';
import { ReactComponent as MicrosoftIcon } from '../../../assets/icons/32-microsoft.svg';
import { CheckIcon } from '@heroicons/react/24/outline';

export const MyLogin: React.FC = () => {
  const dispatch = useDispatch();
  const email = useSelector(userSettingsSelectors.selectEmail);
  const isGoogleConnected = useSelector(authenticationSelectors.selectIsThirdPartyGoogle);
  const isMicrosoftConnected = useSelector(authenticationSelectors.selectIsThirdPartyMicrosoft);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [switchTo, setSwitchTo] = useState<ThirdPartyType | null>(null);

  const handleSwitch = (switchTo: ThirdPartyType) => {
    setSwitchTo(switchTo);
    setIsConfirmModalOpen(true);
  };

  const handleSwitchGoogleAuthSuccess = (codeResponse: CodeResponse) => {
    dispatch(
      userSettingsActions.connectExternalCalendarRequest({
        type: ThirdPartyType.GOOGLE,
        integrationType: IntegrationType.GOOGLE_CALENDAR,
        codeResponse,
        switchAccount: true,
        login: false,
        updateIntegration: true,
      })
    );
    setIsConfirmModalOpen(false);
  };

  const handleSwitchMicrosoftAuthRedirect = (codeResponse: MicrosoftCodeResponse) => {
    dispatch(
      userSettingsActions.connectExternalCalendarRequest({
        type: ThirdPartyType.MICROSOFT,
        integrationType: IntegrationType.MICROSOFT_CALENDAR,
        codeResponse,
        switchAccount: true,
        login: false,
        updateIntegration: true,
      })
    );
    setIsConfirmModalOpen(false);
  };

  return (
    <div className="flex flex-column gap-32px">
      <div className="flex-between border-1 border-radius-10px border-heavy-20 p-20px">
        <div className="flex-left-center gap-12px">
          <GoogleIcon />
          <div className="flex flex-column gap-4px">
            <div className="text-title-xs-med text-heavy-80">{labels.googleAccount}</div>
            {isGoogleConnected && <div className="text-label-xs-reg">{email}</div>}
          </div>
          {isGoogleConnected && (
            <div className="flex-left-center text-cyan-main pl-10px">
              <CheckIcon width={18} height={18} strokeWidth={2} />
              <div className="text-label-s-med">{labels.connected}</div>
            </div>
          )}
        </div>
        <Button
          outlined
          label={isGoogleConnected ? labels.googleAccountSwitch : labels.googleSwitch}
          onClick={() => handleSwitch(ThirdPartyType.GOOGLE)}
        />
      </div>

      <div className="flex-between border-1 border-radius-10px border-heavy-20 p-20px">
        <div className="flex-left-center gap-12px">
          <MicrosoftIcon />
          <div className="flex flex-column gap-4px">
            <div className="text-title-xs-med text-heavy-80">{labels.microsoftAccount}</div>
            {isMicrosoftConnected && <div className="text-label-xs-reg">{email}</div>}
          </div>
          {isMicrosoftConnected && (
            <div className="flex-left-center text-cyan-main pl-10px">
              <CheckIcon width={18} height={18} strokeWidth={2} />
              <div className="text-label-s-med">{labels.connected}</div>
            </div>
          )}
        </div>
        <Button
          outlined
          label={isMicrosoftConnected ? labels.microsoftAccountSwitch : labels.microsoftSwitch}
          onClick={() => handleSwitch(ThirdPartyType.MICROSOFT)}
        />
      </div>

      <ConfirmationModal
        visible={isConfirmModalOpen}
        title={switchTo === ThirdPartyType.MICROSOFT ? labels.microsoftSwitch : labels.googleSwitch}
        description={
          labels.switchDescriptionPart1 +
          (isGoogleConnected ? labels.google : labels.microsoft) +
          labels.switchDescriptionPart2 +
          (switchTo === ThirdPartyType.GOOGLE ? labels.google : labels.microsoft)
        }
        additionalText={[labels.switchAccountMessage]}
        cancelButtonLabel={labels.no}
        onCancel={() => setIsConfirmModalOpen(false)}
        onClose={() => setIsConfirmModalOpen(false)}
        modalClassName={'max-w-30rem'}
        CustomConfirmButton={
          switchTo === ThirdPartyType.MICROSOFT ? (
            <MicrosoftAuthButton
              className="ml-3 mr-0 w-6rem font-semibold p-button-danger"
              label={labels.yes}
              hideIcon
              onRedirect={handleSwitchMicrosoftAuthRedirect}
            />
          ) : (
            <GoogleAuthButton
              className="ml-3 mr-0 w-6rem font-semibold p-button-danger"
              label={labels.yes}
              hideIcon
              onSuccess={handleSwitchGoogleAuthSuccess}
            />
          )
        }
      />
    </div>
  );
};
