import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { AppDispatch } from '../../store/rootStore';
import { useEffect, useState } from 'react';
import { smartAlertsActions, smartAlertsSelectors, SmartTypes } from '../../store/smartAlerts';
import { Preloader } from '../../components/common';
import { SmartAlertEdit, SmartAlertPreSelect } from '../../components/smartAlerts';
import { Button } from 'primereact/button';
import { ArrowLeftIcon } from '@heroicons/react/24/outline';
import { navigationService } from '../../services/NavigationService';
import { Path } from '../../routing';
import labels from './labels';
import { userSettingsSelectors } from '../../store/userSettings';

export const EditSmartAlert = () => {
  const { smartType, smartAlertId } = useParams();
  const dispatch = useDispatch<AppDispatch>();
  const isFetching = useSelector(smartAlertsSelectors.selectIsFetching);
  const selectedSmartType = useSelector(smartAlertsSelectors.selectSmartType);
  const [showPreSelect, setShowPreSelect] = useState(false);

  const isReadOnly = useSelector(
    smartAlertsSelectors.selectIsAlertReadOnly(
      smartAlertId === 'new' ? '' : smartAlertId || '',
      smartType === 'alert' ? 'alert' : 'template'
    )
  );
  const isTemplateCreate = useSelector(userSettingsSelectors.selectSmartAlertTemplatesCreate);

  useEffect(() => {
    if (smartAlertId && smartType) {
      if (smartAlertId === 'new') {
        setShowPreSelect(true);
        dispatch(smartAlertsActions.setSmartType('alert'));
        dispatch(smartAlertsActions.getSmartAlertRequest(smartAlertId));
      } else {
        setShowPreSelect(false);
        dispatch(smartAlertsActions.setSmartType(smartType as SmartTypes));
        if (smartType === 'alert') {
          dispatch(smartAlertsActions.getSmartAlertRequest(smartAlertId));
        } else {
          dispatch(smartAlertsActions.getSmartAlertTemplateRequest(smartAlertId));
        }
      }
    }
  }, []);

  const handleBack = () => {
    if (selectedSmartType === 'alert' && smartAlertId === 'new' && showPreSelect === false) {
      setShowPreSelect(true);
    } else {
      navigationService.navigateTo(Path.SmartAlerts);
    }
  };

  const handleCancel = () => {
    navigationService.navigateTo(Path.SmartAlerts);
  };

  const handleSave = () => {
    dispatch(smartAlertsActions.upsertSmartTypeRequest('smartAlert'));
  };

  return (
    <>
      {isFetching && <Preloader />}
      <div className="flex-left-center -mt-24px mb-4px">
        <Button className="button-blue" text onClick={handleBack}>
          <ArrowLeftIcon width={16} height={16} className="mr-8px" />
          {labels.back}
        </Button>
      </div>
      <div className="sumo-card-bg flex flex-column p-24px">
        <div className="flex-1 text-title-xl-med">{smartAlertId === 'new' ? labels.createTitle : labels.editTitle}</div>
        {showPreSelect ? (
          <SmartAlertPreSelect handleSelect={() => setShowPreSelect(false)} />
        ) : (
          <SmartAlertEdit
            handleCancel={handleCancel}
            handleSave={handleSave}
            isReadOnly={isReadOnly}
            hideTypeChange={!isTemplateCreate}
          />
        )}
      </div>
    </>
  );
};
