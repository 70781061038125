export const SmartAlerts = Object.freeze({
  TITLE: `Alertas inteligentes`,
  CREATE_ALERT: `Crear alerta`,
  CLONE_ALERT_TITLE: `Nombre de la alerta inteligente clonada`,
  CLONE_TEMPLATE_TITLE: `Nombre de la plantilla de alerta inteligente clonada`,
  DIALOG_PLACEHOLDER: `Recordatorio al invitado, Enviar agradecimiento, etc...`,
  EMPTY_LIST_MESSAGE: `Cree una alerta o ajuste su búsqueda o filtros.`,
  PERSONAL_LABEL: `Personal`,
  SHARED_LABEL: `Compartido`,
  TEMPLATES_LABEL: `Plantillas`,
  HIDDEN_LABEL: `Oculto`,
  UNHIDDEN_LABEL: `Visible`,
  BASIC_ALERTS: `Alertas básicas`,
  ADD_SMART_ALERT: `+ Agregar alerta inteligente`,
  DELETE_DESCRIPTION: `¿Está seguro de que desea eliminar`,
  BULK_DELETE_DESCRIPTION: `las alertas inteligentes seleccionadas`,
  DELETE_WARNING_ONE: `Advertencia: Eliminar esta Alerta Inteligente también la eliminará de las páginas de reserva y plantillas de reserva donde se usa.`,
  DELETE_WARNING_MULTIPLE: `Advertencia: Eliminar estas Alertas Inteligentes también las eliminará de las páginas de reserva y plantillas de reserva donde se usan.`,
});

export const SmartAlertsToastsNotifications = Object.freeze({
  GET_SMART_TYPES_ERROR_MESSAGE: `Error al obtener las alertas inteligentes`,
  CLONE_SMART_TYPE_ERROR_MESSAGE: `Error al clonar las alertas inteligentes`,
  CLONE_SMART_ALERT_SUCCESS_MESSAGE: `Alerta inteligente clonada con éxito`,
  CLONE_SMART_TEMPLATE_SUCCESS_MESSAGE: `Plantilla de alerta inteligente clonada con éxito`,
  DELETE_SMART_TYPES_SUCCESS_MESSAGE: `Alertas inteligentes eliminadas con éxito`,
  DELETE_SMART_TYPES_ERROR_MESSAGE: `Error al eliminar las alertas inteligentes`,
  GET_SMART_ALERT_ERROR_MESSAGE: `Error al obtener la alerta inteligente`,
  GET_SMART_TEMPLATE_ERROR_MESSAGE: `Error al obtener la plantilla de alerta inteligente`,
  UPSERT_SMART_TYPE_ERROR_MESSAGE: `Error al insertar o actualizar las alertas inteligentes`,
  CREATE_SMART_ALERT_SUCCESS_MESSAGE: `Alerta inteligente creada con éxito`,
  CREATE_SMART_TEMPLATE_SUCCESS_MESSAGE: `Plantilla de alerta inteligente creada con éxito`,
  UPDATE_SMART_ALERT_SUCCESS_MESSAGE: `Alerta inteligente actualizada con éxito`,
  UPDATE_SMART_TEMPLATE_SUCCESS_MESSAGE: `Plantilla de alerta inteligente actualizada con éxito`,
});

export const EditSmartAlert = Object.freeze({
  CREATE_TITLE: `Crear alerta`,
  CREATE_CUSTOM_TITLE: `+ Crear su alerta personalizada`,
  DESCRIPTION: `Cree su alerta personalizada o use plantillas para una rápida creación de alertas`,
  EDIT_TITLE: `Editar alerta`,
  ICON: `Icono`,
  NAME: `Nombre`,
  NAME_PLACEHOLDER: `Ingrese el nombre de la alerta`,
  HIDE: `Ocultar`,
  HIDE_DESCRIPTION: `No mostrar en la lista de Smart Alerts`,
  ALERT: `Alerta`,
  TEMPLATE: `Plantilla`,
  APPLY_TO: `Aplicar a`,
  SHARE_WITH: `Compartir con`,
  WORKSPACES: `Espacios de trabajo`,
  WHEN_TITLE: `Cuando esto suceda`,
  DO_THIS: `Hacer esto`,
  PERSONALIZE: `Personalizar`,
  ADD_VARIABLE: `Agregar variable`,
  SUBJECT: `Asunto`,
  BODY: `Cuerpo`,
  USE_TEMPLATE: `Usar plantilla`,
  HOW_LONG_AFTER: `Cuánto tiempo después`,
  IMMEDIATELY: `Inmediatamente`,
  CUSTOM_NAME: `Nombre personalizado`,
  TEMPLATE_DESCRIPTION_PLACEHOLDER: `Ingrese la descripción de la plantilla inteligente`,
  USE_ALERT: `Usar alerta`,
  SELECT_SMART_TITLE: `Seleccionar alerta`,
  SELECT_ALERT_DESCRIPTION: `Seleccione una alerta inteligente o cree la suya propia`,
  BOOKING_PAGES: `Páginas de reserva`,
  BOOKING_TEMPLATES: `Plantillas de reserva`,
  SMS_TO_HOST_TOOLTIP: `Si el anfitrión no tiene el número de teléfono, el recordatorio de texto no será enviado`,
  NEEDS_YOUR_ATTENTION: `Necesita su atención`,
});

export const SmartAlertsPersonalize = Object.freeze({
  EMAIL_TO_INVITE_SUBJECT: `Recordatorio: &ltMeeting Name&gt con &ltHost Name&gt a las &ltMeeting Time&gt, &ltMeeting Date&gt`,
  EMAIL_TO_INVITE_BODY: `Hola &ltInvitee Name&gt,<br><br>Este es un recordatorio amistoso de que su &ltMeeting Name&gt con &ltHost Name&gt es a las &ltMeeting Time&gt, &ltMeeting Date&gt.<br><br><b>Ubicación</b><br>&ltMeeting Location&gt<br><br>&ltVer o editar enlace de la reunión&gt`,
  EMAIL_TO_HOST_SUBJECT: `Recordatorio: &ltMeeting Name&gt con &ltInvitee Name&gt a las &ltMeeting Time&gt, &ltMeeting Date&gt`,
  EMAIL_TO_HOST_BODY: `Hola &ltHost Name&gt,<br><br>Este es un recordatorio amistoso de que su &ltMeeting Name&gt con &ltInvitee Name&gt es a las &ltMeeting Time&gt, &ltMeeting Date&gt.<br><br><b>Ubicación</b><br>&ltMeeting Location&gt<br><br>&ltVer o editar enlace de la reunión&gt`,
  SMS_TO_INVITE: `Este es un recordatorio amistoso de que su &ltMeeting Name&gt con &ltHost Name&gt es a las &ltMeeting Time&gt, &ltMeeting Date&gt. Ver o editar reunión &ltMeeting Link&gt`,
  SMS_TO_HOST: `Este es un recordatorio amistoso de que su &ltMeeting Name&gt con &ltInvitee Name&gt es a las &ltMeeting Time&gt, &ltMeeting Date&gt. Ver o editar reunión &ltMeeting Link&gt`,
  TITLE_EMAIL_TO_HOST: `Personalizar correo electrónico al anfitrión`,
  TITLE_EMAIL_TO_INVITE: `Personalizar correo electrónico al invitado`,
  TITLE_SMS_TO_HOST: `Personalizar recordatorio por texto al anfitrión`,
  TITLE_SMS_TO_INVITE: `Personalizar recordatorio por texto al invitado`,
});

export const SmartAlertsWhen = Object.freeze({
  HOW_LONG_EVENT_BOOKED: `¿Cuánto tiempo después de reservar el nuevo evento?`,
  EVENT_ENDS: `El evento termina`,
  EVENT_BEFORE_START: `Antes de que comience el evento`,
  EVENT_CANCELLED: `El evento está cancelado`,
  EVENTS_STARTS: `El evento comienza`,
  EVENT_RESCHEDULED: `El evento ha sido reprogramado`,
  HOW_LONG_EVENT_ENDS: `¿Cuánto tiempo después de que termine el evento?`,
  HOW_LONG_EVENT_BEFORE_START: `¿Cuánto tiempo antes de que comience el evento?`,
  HOW_LONG_EVENT_CANCELLED: `¿Cuánto tiempo después de que se cancele el evento?`,
  HOW_LONG_EVENTS_STARTS: `¿Cuánto tiempo después de que comience el evento?`,
  HOW_LONG_EVENT_RESCHEDULED: `¿Cuánto tiempo después de que se reprograma el evento?`,
  MINUTE: `Minuto`,
  MINUTES: `Minutos`,
  HOUR: `Hora`,
  HOURS: `Horas`,
  DAY: `Día`,
  DAYS: `Días`,
  WHEN_EVENT_BOOKED: `Cuando se reserva un nuevo evento`,
  WHEN_EVENT_STARTS: `Cuando el evento comienza`,
  WHEN_EVENT_ENDS: `Cuando el evento termina`,
  WHEN_EVENT_CANCELED: `Cuando el evento es cancelado`,
  WHEN_EVENT_RESCHEDULED: `Cuando el evento es reprogramado`,
  AFTER: `después `,
});

export const SmartAlertsWhom = Object.freeze({
  EMAIL_TO_INVITEE: `Enviar correo electrónico al invitado`,
  EMAIL_TO_HOST: `Enviar correo electrónico al anfitrión`,
  SMS_TO_INVITEE: `Enviar recordatorio por SMS al invitado`,
  SMS_TO_HOST: `Enviar recordatorio por SMS al anfitrión`,
  SEND_ALL: `Enviar correo electrónico y recordatorio por SMS al invitado y al anfitrión`,
  SEND_EMAIL_SMS_INVITEE: `Enviar correo electrónico y recordatorio por SMS al invitado`,
  SEND_EMAIL_SMS_HOST: `Enviar correo electrónico y recordatorio por SMS al anfitrión`,
});

export const SmartAlertsWarningModal = Object.freeze({
  WARNING_BP_AND_BT: `Advertencia: este cambio afectará a las próximas páginas y plantillas de reserva:`,
  WARNING_BP: `Advertencia: este cambio afectará a las próximas páginas de reserva:`,
  WARNING_BT: `Advertencia: este cambio afectará a las próximas plantillas de reserva:`,
  TITLE: `Advertencia`,
  UPDATE_ALL: `Actualizar todo`,
  CREATE_NEW: `Crear nueva alerta inteligente`,
});
