import { Sidebar } from 'primereact/sidebar';
import { useDispatch, useSelector } from 'react-redux';
import {
  DEFAULT_STEP_ID,
  getColorByStepType,
  getStepTypeTitle,
  journeysActions,
  journeysSelectors,
} from '../../../store/journeyBuilder';
import { Button } from 'primereact/button';
import { v4 as uuid } from 'uuid';
import { JourneyStep, JourneyStepType } from '../../../API';
import labels from './labels';
import { EditJourneyPageStep } from '../editJourneyPageStep/EditJourneyPageStep';
import { EditJourneyConditionStep } from '../editJourneyConditionStep/EditJourneyConditionStep';
import { EditJourneyDestinationStep } from '../editJourneyDestinationStep/EditJourneyDestinationStep';
import { useEffect, useState } from 'react';
import { AppDispatch } from '../../../store/rootStore';

export const EditJourneySidebar = () => {
  const dispatch = useDispatch<AppDispatch>();
  const visibility = useSelector(journeysSelectors.selectEditSidebar);
  const journeyStep = useSelector(journeysSelectors.selectJourneyStep);
  const isStepValid = useSelector(journeysSelectors.selectIsStepValid);
  const isReadOnly = useSelector(journeysSelectors.selectJourneyReadOnly);

  const [isValidate, setIsValidate] = useState(false);

  useEffect(() => {
    isStepValid && setIsValidate(false);
  }, [isStepValid]);

  const handleSave = () => {
    if (!isStepValid && !isValidate) {
      setIsValidate(true);
      return;
    }
    setIsValidate(false);
    if (journeyStep?.id === DEFAULT_STEP_ID) {
      const newStep = { ...journeyStep, id: uuid() };
      dispatch(journeysActions.editJourneyStep({ step: newStep, id: DEFAULT_STEP_ID }));
    } else {
      dispatch(
        journeysActions.editJourneyStep({ step: journeyStep || ({} as JourneyStep), id: journeyStep?.id || '' })
      );
    }
    if (journeyStep?.type === JourneyStepType.PAGE) {
      dispatch(journeysActions.regenerateFieldsAndCardsThunk());
    }
  };

  const handleCancel = () => {
    setIsValidate(false);
    if (journeyStep?.id === DEFAULT_STEP_ID) {
      dispatch(journeysActions.removeJourneyStep(journeyStep.id));
    } else {
      dispatch(journeysActions.resetJourneyStep());
    }
  };

  const getSidebarBody = () => {
    if (journeyStep?.type === JourneyStepType.PAGE) {
      return <EditJourneyPageStep isValidate={isValidate} isReadOnly={isReadOnly} />;
    } else if (journeyStep?.type === JourneyStepType.CONDITION) {
      return <EditJourneyConditionStep isValidate={isValidate} isReadOnly={isReadOnly} />;
    } else {
      return <EditJourneyDestinationStep isValidate={isValidate} isReadOnly={isReadOnly} />;
    }
  };

  return (
    <Sidebar
      visible={visibility}
      position="right"
      onHide={handleCancel}
      dismissable={false}
      showCloseIcon={false}
      className="journey-sidebar"
      pt={{ header: { className: 'hidden' }, content: { className: 'p-0' } }}
    >
      <div className="flex flex-column p-32px h-full">
        <div className="flex-left-center gap-16px">
          <div
            className="w-16px h-16px border-radius-4px"
            style={{ background: getColorByStepType(journeyStep?.type) }}
          />
          <div className="text-sidebar-header text-heavy-100">{getStepTypeTitle(journeyStep?.type)}</div>
        </div>
        {getSidebarBody()}
        <div className="border-top-1 border-heavy-20 mt-auto flex-left-center gap-6px pt-20px -mb-12px -mx-32px px-32px">
          <Button
            className="button-xl flex-center"
            style={{ minWidth: '120px' }}
            onClick={handleSave}
            disabled={(!isStepValid && isValidate) || isReadOnly}
          >
            {labels.save}
          </Button>
          <Button className="button-xl" text onClick={handleCancel}>
            {labels.cancel}
          </Button>
        </div>
      </div>
    </Sidebar>
  );
};
