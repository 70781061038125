import { CreateRoleInput, Role } from '../../API';
import { RoleResponse, RolesResponse } from './types';

import { handleAPIRequest } from '../utils/reduxUtils';
import { API_PUBLIC } from '../../types/constants';

// ***
// When you change the requests do not forget to specify new data
// in docs/Endpoints.md
// ***

export const getRoles = async (): Promise<Role[]> => {
  const response = await handleAPIRequest<RolesResponse>(`${API_PUBLIC}/getRolesByTenantId`, {});
  return response.roleRecords || [];
};

export const getRole = async (roleId: string): Promise<RoleResponse> => {
  return handleAPIRequest<RoleResponse>(`${API_PUBLIC}/getRoleById`, { roleId });
};

export const saveRole = async (role: CreateRoleInput): Promise<void> => {
  return handleAPIRequest<void>(`${API_PUBLIC}/upsertRole`, { data: role });
};

export const deleteRole = async (tenantId: string, roleId: string): Promise<void> => {
  return handleAPIRequest<void>(`${API_PUBLIC}/deleteRole`, { tenantId, roleId });
};
