import { t } from '../../../i18n/i18n';

export default {
  country: t('AccountSettings:COUNTRY'),
  dateFormat: t('AccountSettings:DATE_FORMAT'),
  language: t('AccountSettings:LANGUAGE'),
  name: t('AccountSettings:NAME'),
  phoneDetailsLabel: t('AccountSettings:PHONE_DETAILS'),
  phoneDetailsPlaceholder: t('AccountSettings:PHONE_DETAILS_PLACEHOLDER'),
  phoneNumber: t('AccountSettings:PHONE_NUMBER'),
  pictureFormats: t('Common:PICTURE_FORMATS'),
  pictureSize: t('Common:PICTURE_SIZE'),
  profileImage: t('AccountSettings:PROFILE_IMAGE'),
  timeFormat: t('AccountSettings:TIME_FORMAT'),
  timeZone: t('AccountSettings:TIME_ZONE'),
  uploadPicture: t('Common:UPLOAD_PICTURE'),
};
