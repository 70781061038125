import { Staff, StaffRoles, StaffStatus } from '../../../API';
import { t } from '../../../i18n/i18n';
import {
  TOAST_ERROR_COLOR,
  TOAST_ERROR_DURATION,
  TOAST_SUCCESS_COLOR,
  TOAST_SUCCESS_DURATION,
} from '../../../types/constants';
import { ToastNotificationOptions } from '../../notifications';

export const DEFAULT_STAFF_ROLE = StaffRoles.OPERATIONS;

export const STAFF_STATUS_OPTIONS = [
  { value: StaffStatus.ACTIVE, label: t('OPSConsoleStaff:ACTIVE') },
  { value: StaffStatus.INACTIVE, label: t('OPSConsoleStaff:INACTIVE') },
];

export const NEW_DEFAULT_STAFF_RECORD: Staff = {
  __typename: 'Staff',
  userName: '',
  email: '',
  roleName: DEFAULT_STAFF_ROLE,
  status: StaffStatus.ACTIVE,
  createdAt: '',
  updatedAt: '',
};

export const GET_STAFF_FAIL_TOAST: ToastNotificationOptions = Object.freeze({
  color: TOAST_ERROR_COLOR,
  autoHideDuration: TOAST_ERROR_DURATION,
  message: t('StaffToastsNotifications:GET_STAFF_FAIL_MESSAGE'),
});

export const SAVE_STAFF_SUCCESS_TOAST: ToastNotificationOptions = Object.freeze({
  color: TOAST_SUCCESS_COLOR,
  autoHideDuration: TOAST_SUCCESS_DURATION,
  message: t('StaffToastsNotifications:SAVE_STAFF_SUCCESS_MESSAGE'),
});

export const SAVE_STAFF_FAIL_TOAST: ToastNotificationOptions = Object.freeze({
  color: TOAST_ERROR_COLOR,
  autoHideDuration: TOAST_ERROR_DURATION,
  message: t('StaffToastsNotifications:SAVE_STAFF_FAIL_MESSAGE'),
});

export const CHANGE_STAFF_STATUS_FAIL_TOAST: ToastNotificationOptions = Object.freeze({
  color: TOAST_ERROR_COLOR,
  autoHideDuration: TOAST_ERROR_DURATION,
  message: t('StaffToastsNotifications:CHANGE_STAFF_STATUS_FAIL_MESSAGE'),
});

export const ACTIVATE_STAFF_SUCCESS_TOAST: ToastNotificationOptions = Object.freeze({
  color: TOAST_SUCCESS_COLOR,
  autoHideDuration: TOAST_SUCCESS_DURATION,
  message: t('StaffToastsNotifications:ACTIVATE_STAFF_SUCCESS_MESSAGE'),
});

export const DEACTIVATE_STAFF_SUCCESS_TOAST: ToastNotificationOptions = Object.freeze({
  color: TOAST_SUCCESS_COLOR,
  autoHideDuration: TOAST_SUCCESS_DURATION,
  message: t('StaffToastsNotifications:DEACTIVATE_STAFF_SUCCESS_MESSAGE'),
});

export const DELETE_STAFF_SUCCESS_TOAST: ToastNotificationOptions = Object.freeze({
  color: TOAST_SUCCESS_COLOR,
  autoHideDuration: TOAST_SUCCESS_DURATION,
  message: t('StaffToastsNotifications:DELETE_STAFF_SUCCESS_MESSAGE'),
});

export const DELETE_STAFF_FAIL_TOAST: ToastNotificationOptions = Object.freeze({
  color: TOAST_ERROR_COLOR,
  autoHideDuration: TOAST_ERROR_DURATION,
  message: t('StaffToastsNotifications:DELETE_STAFF_FAIL_MESSAGE'),
});

export const VIEW_AS_USER_FAIL_TOAST: ToastNotificationOptions = Object.freeze({
  color: TOAST_ERROR_COLOR,
  autoHideDuration: TOAST_ERROR_DURATION,
  message: t('StaffToastsNotifications:VIEW_AS_USER_FAIL_MESSAGE'),
});
