import { CodeResponse as GoogleCodeResponse } from '@react-oauth/google';
import { CreateAdminDataInput, CreateStaffInput, CreateTenantInput, CreateLicenseInput } from '../../API';

export interface UserDataCoreType {
  userId: string;
  tenantId: string;
  workspaceId: string;
  link: string;
}

export enum ThirdPartyType {
  GOOGLE = 'GOOGLE',
  MICROSOFT = 'MICROSOFT',
}

export type MicrosoftCodeResponse = {
  code: string;
  redirectUrl: string;
};

export type CodeThirdPartyResponse = GoogleCodeResponse | MicrosoftCodeResponse;

export interface GetAuthResponseType {
  type: ThirdPartyType;
  integrationType: string;
  codeResponse: CodeThirdPartyResponse;
  switchAccount: boolean;
  login: boolean;
  updateIntegration: boolean;
  userTimeZone?: string; // only for SignUp, for create the account
  joinTenant?: string; // only if user was invited
  redirectUrl?: string; // only for Microsoft
}

export type ThirdPartyAuthSagaAction = {
  payload: GetAuthResponseType;
};

export type ThirdPartyAuthRequest = Omit<GetAuthResponseType, 'type' | 'codeResponse'> & {
  code: string;
};

export type ThirdPartyLambdaResponse = {
  isNew: boolean;
  isQuickSetupFinished: boolean;
  adminDataRecords: CreateAdminDataInput[];
  email: string;
  fullName: string;
  secret: string;
  isSumoAdmin: boolean;
  staffRecord: CreateStaffInput;
  lastSuperAdmin: boolean;
  inviteExpired: boolean;
};

export type GetTenantDataResponse = {
  tenant: CreateTenantInput;
};

export type HandleAdminDataRecordsParams = Pick<GetAuthResponseType, 'login' | 'joinTenant'> & {
  adminDataRecords: CreateAdminDataInput[];
  isNew: boolean;
};

export type HandleMultipleAdminDataRecordsParams = Pick<GetAuthResponseType, 'login' | 'joinTenant'> & {
  adminDataRecords: CreateAdminDataInput[];
  isNew: boolean;
};

export type InviteToAccount = {
  newTenant: CreateAdminDataInput;
  existingTenant: CreateAdminDataInput;
};

export type ViewAsUserPayload = {
  tenantId: string;
  userId: string;
};

export type ResetViewAsUserPayload = {
  redirect?: boolean;
  removeLicenseType?: boolean;
};

export type DeleteAndCreateUserRequest = {
  email: string;
  newTenantId: string;
};

export type ThirdPartySettings = {
  access_token: string;
  email: string;
  expiry_date: string;
  id_token: string;
  refresh_token: string;
  scope: string;
  token_type: string;
};

export type CreateAdminDataType = Omit<CreateAdminDataInput, 'updatedAt'> & {
  updatedAt?: string;
};

export type GenerateCookiesResponse = {
  encryptedUserId: string;
};

export type GetLicensesResponse = {
  licenses: CreateLicenseInput[];
};
