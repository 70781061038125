import { t } from '../../i18n/i18n';

export default {
  back: t('Common:BACK'),
  cancel: t('Common:CANCEL'),
  addLocation: t('EditLocation:ADD_LOCATION'),
  save: t('Common:SAVE'),
  name: t('EditLocation:NAME'),
  namePlaceholder: t('EditLocation:NAME_PLACEHOLDER'),
  active: t('Locations:ACTIVE'),
  address: t('EditLocation:ADDRESS'),
};
