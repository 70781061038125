import { combineReducers } from 'redux';
import { UsersAction, UsersActionTypes } from './actions';
import { GlobalAction, GlobalActionTypes } from '../../global/actions';
import { UserDetails } from './types';
import { OrgsAction, OrgsActionTypes } from '../orgs/actions';

const users = (state: UserDetails[] = [], action: UsersAction | GlobalAction | OrgsAction) => {
  switch (action.type) {
    case UsersActionTypes.GET_USERS_SUCCESS:
      return action.payload;
    case GlobalActionTypes.RESET_WHOLE_STORE:
    case OrgsActionTypes.GET_ORGS_REQUEST:
      return [];
    default:
      return state;
  }
};

const userDetails = (state = {} as UserDetails, action: UsersAction | GlobalAction | OrgsAction) => {
  switch (action.type) {
    case UsersActionTypes.GET_USER_DETAILS_SUCCESS:
    case UsersActionTypes.SET_USER_RECORD:
      return action.payload;
    case GlobalActionTypes.RESET_WHOLE_STORE:
    case UsersActionTypes.GET_USERS_REQUEST:
    case OrgsActionTypes.GET_ORGS_REQUEST:
      return {} as UserDetails;
    default:
      return state;
  }
};

const isFetching = (state = false, action: UsersAction | GlobalAction) => {
  switch (action.type) {
    case UsersActionTypes.GET_USERS_REQUEST:
    case UsersActionTypes.GET_USER_DETAILS_REQUEST:
    case UsersActionTypes.CHANGE_USER_STATUS_REQUEST:
    case UsersActionTypes.DELETE_USER_REQUEST:
      return true;
    case UsersActionTypes.GET_USERS_SUCCESS:
    case UsersActionTypes.GET_USERS_FAIL:
    case UsersActionTypes.GET_USER_DETAILS_SUCCESS:
    case UsersActionTypes.GET_USER_DETAILS_FAIL:
    case UsersActionTypes.CHANGE_USER_STATUS_SUCCESS:
    case UsersActionTypes.CHANGE_USER_STATUS_FAIL:
    case UsersActionTypes.DELETE_USER_SUCCESS:
    case UsersActionTypes.DELETE_USER_FAIL:
      return false;
    case GlobalActionTypes.RESET_WHOLE_STORE:
      return false;
    default:
      return state;
  }
};

const error = (state: unknown | null = null, action: UsersAction | GlobalAction) => {
  switch (action.type) {
    case UsersActionTypes.GET_USERS_FAIL:
    case UsersActionTypes.GET_USER_DETAILS_FAIL:
    case UsersActionTypes.CHANGE_USER_STATUS_FAIL:
    case UsersActionTypes.DELETE_USER_FAIL:
      return action.error;
    case UsersActionTypes.GET_USERS_REQUEST:
    case UsersActionTypes.GET_USER_DETAILS_REQUEST:
    case UsersActionTypes.CHANGE_USER_STATUS_REQUEST:
    case UsersActionTypes.DELETE_USER_REQUEST:
      return null;
    case GlobalActionTypes.RESET_WHOLE_STORE:
      return null;
    default:
      return state;
  }
};

export default combineReducers({ isFetching, error, users, userDetails });
