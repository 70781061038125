import { combineReducers } from 'redux';
import { GlobalAction, GlobalActionTypes } from './actions';
import { DEFAULT_GLOBAL_SEARCH } from './constants';
import { GlobalSearch } from './types';

const isFetching = (state = false, action: GlobalAction) => {
  switch (action.type) {
    case GlobalActionTypes.GET_MAIN_DATA_REQUEST:
      return true;
    case GlobalActionTypes.GET_MAIN_DATA_SUCCESS:
    case GlobalActionTypes.GET_MAIN_DATA_FAIL:
    case GlobalActionTypes.RESET_WHOLE_STORE:
      return false;
    default:
      return state;
  }
};

const error = (state = false, action: GlobalAction) => {
  switch (action.type) {
    case GlobalActionTypes.GET_MAIN_DATA_FAIL:
      return true;
    case GlobalActionTypes.GET_MAIN_DATA_REQUEST:
    case GlobalActionTypes.RESET_WHOLE_STORE:
      return false;
    default:
      return state;
  }
};

const search = (state: GlobalSearch = DEFAULT_GLOBAL_SEARCH, action: GlobalAction) => {
  switch (action.type) {
    case GlobalActionTypes.SET_SEARCH:
      return { ...state, ...action.payload };
    case GlobalActionTypes.RESET_WHOLE_STORE:
      return DEFAULT_GLOBAL_SEARCH;
    default:
      return state;
  }
};

const fullScreen = (state = false, action: GlobalAction) => {
  switch (action.type) {
    case GlobalActionTypes.SET_FULL_SCREEN:
      return action.payload;
    case GlobalActionTypes.RESET_WHOLE_STORE:
      return false;
    default:
      return state;
  }
};

// isAuthenticated reducer in Authentication store

export default combineReducers({
  isFetching,
  error,
  search,
  fullScreen,
});
