import { useDispatch } from 'react-redux';
import { IntegrationType, WorkspaceIntegrationType } from '../../../API';
import { MicrosoftAuthButton } from '../../public';
import { MicrosoftCodeResponse } from '../../../store/authentication';
import { userSettingsActions } from '../../../store/userSettings';
import { ConnectIntegration } from '../connectIntegration/ConnectIntegration';
import { ThirdPartyType } from '../../../store/authentication';

interface IMicrosoftIntegration {
  integrationType: IntegrationType | WorkspaceIntegrationType;
  handleDisconnect: () => void;
}

export const MicrosoftIntegration = (props: IMicrosoftIntegration) => {
  const { integrationType, handleDisconnect } = props;
  const dispatch = useDispatch();

  const handleMicrosoftAuthRedirect = (codeResponse: MicrosoftCodeResponse) => {
    dispatch(
      userSettingsActions.connectExternalCalendarRequest({
        type: ThirdPartyType.MICROSOFT,
        integrationType: IntegrationType.MICROSOFT_CALENDAR,
        codeResponse,
        switchAccount: false,
        login: false,
        updateIntegration: true,
      })
    );
  };

  return (
    <ConnectIntegration
      integrationType={integrationType}
      handleDisconnect={handleDisconnect}
      ConnectButton={MicrosoftAuthButton}
      handleAuthRedirect={handleMicrosoftAuthRedirect}
    />
  );
};
