import { memo, useEffect, useMemo, useRef, useState } from 'react';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { InputSwitch } from 'primereact/inputswitch';
import { Dropdown } from 'primereact/dropdown';
import { CustomType, CustomFieldInput, CustomFieldType } from '../../../../API';
import { CUSTOM_FIELDS_OPTIONS, DEFAULT_ANSWERS, MULTI_ANSWERS_TYPES } from './constants';
import labels from './labels';
import { MAX_LENGTH_LONG_ANSWER, MAX_LENGTH_QUESTION, MAX_LENGTH_SHORT_ANSWER } from '../../../../types/constants';
import { PlusIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { hasPreviousDuplicates } from '../../../../services/utils';
import Sortable from 'sortablejs';
import { ReactComponent as DragAndDrop } from '../../../../assets/icons/20-drag-and-drop.svg';

interface IEditCustomField {
  customFieldInput: CustomFieldInput;
  onSave: (customField: CustomFieldInput) => void;
  onCancel: () => void;
}

export const EditCustomField = memo(({ customFieldInput, onSave, onCancel }: IEditCustomField) => {
  const [customField, setCustomField] = useState<CustomFieldInput>(customFieldInput);

  const list = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const sortable =
      list.current &&
      new Sortable(list.current, {
        handle: '.drag-and-drop',
        onSort: onSort,
      });

    return () => {
      sortable && sortable.destroy();
    };
  }, [customField.answers]);

  const onSort = (e: Sortable.SortableEvent) => {
    const newOrder = [...(customField.answers || [])];
    const [movedItem] = newOrder.splice(e.oldIndex || 0, 1);
    newOrder.splice(e.newIndex || 0, 0, movedItem);
    setCustomField({ ...customField, answers: newOrder });
  };

  const isSaveDisabled = useMemo(() => {
    return customField.type && MULTI_ANSWERS_TYPES.includes(customField.type)
      ? !customField.answers?.every(
          (answer, index) => Boolean(answer) && !hasPreviousDuplicates(customField.answers, index)
        ) || !customField.label
      : !customField.label;
  }, [customField.type, customField.answers, customField.label]);

  const handleLabelChange = (label: string) => {
    setCustomField({ ...customField, label });
  };

  const handleRequiredChange = (required: boolean) => {
    setCustomField({ ...customField, required });
  };

  const handleTypeChange = (type: CustomType) => {
    const label = customField.label;
    if (MULTI_ANSWERS_TYPES.includes(type)) {
      setCustomField({
        ...customField,
        type,
        label,
        answers: (customField.answers?.length && customField.answers) || DEFAULT_ANSWERS,
      });
    } else {
      setCustomField({ ...customField, type, label, answers: [] });
    }
  };

  const handleAddAnswer = () => {
    setCustomField({ ...customField, answers: [...(customField.answers || []), ''] });
  };
  const handleRemoveAnswer = (index: number) => {
    setCustomField({
      ...customField,
      answers: (customField.answers || []).filter((answer, i) => i !== index),
      value: null,
    });
  };
  const handleAnswerChange = (index: number, value: string) => {
    setCustomField({
      ...customField,
      answers: (customField.answers || []).map((answer, i) => (i === index ? value : answer)),
    });
  };

  const handleSave = () => {
    onSave && onSave(customField);
  };

  const getTitle = () => {
    switch (customField.fieldType) {
      case CustomFieldType.NAME:
        return labels.titleName;
      case CustomFieldType.EMAIL:
        return labels.titleEmail;
      case CustomFieldType.PHONE:
        return labels.titlePhone;
      case CustomFieldType.LOCATION:
        return labels.titleLocation;
      default:
        return customField?.id ? labels.editQuestion : labels.newQuestion;
    }
  };

  const getDescription = () => {
    switch (customField.fieldType) {
      case CustomFieldType.NAME:
        return labels.descriptionName;
      case CustomFieldType.EMAIL:
        return labels.descriptionEmail;
      case CustomFieldType.PHONE:
        return labels.descriptionPhone;
      case CustomFieldType.LOCATION:
        return labels.descriptionLocation;
      default:
        return '';
    }
  };

  const getQuestionLabel = () => {
    switch (customField.fieldType) {
      case CustomFieldType.NAME:
        return labels.questionNameLabel;
      case CustomFieldType.EMAIL:
        return labels.questionEmailLabel;
      case CustomFieldType.PHONE:
        return labels.questionPhoneLabel;
      case CustomFieldType.LOCATION:
        return labels.questionLocationLabel;
      default:
        return labels.questionLabel;
    }
  };

  const getTypeDescription = () => {
    switch (customField.type) {
      case CustomType.TEXT:
        return labels.typeTextDescription;
      case CustomType.TEXTAREA:
        return labels.typeTextareaDescription;
      case CustomType.PHONE:
        return labels.typeTelDescription;
      case CustomType.RADIO_BUTTONS:
        return labels.typeRadioButtonsDescription;
      case CustomType.CHECKBOXES:
        return labels.typeCheckboxesDescription;
      case CustomType.SELECT:
        return labels.typeSelectDescription;
      default:
        return '';
    }
  };

  return (
    <div className="flex flex-column h-full">
      <div className="flex-between-center py-14px pl-20px pr-10px border-bottom-1 border-heavy-20">
        <div className="text-title-lg-med ">{getTitle()}</div>
        <div className="action-button" onClick={onCancel}>
          <XMarkIcon className="icon-20px" />
        </div>
      </div>

      <div className="flex-1 flex flex-column gap-28px p-20px">
        {customField.fieldType !== CustomFieldType.CUSTOM && (
          <div className="text-body-s-reg text-heavy-60">{getDescription()}</div>
        )}

        <div className="flex flex-column gap-10px">
          <div className="text-title-xs-med">{getQuestionLabel()}</div>
          <InputTextarea
            autoResize
            rows={1}
            value={customField.label || ''}
            onChange={(e) => handleLabelChange(e.target.value.trimStart())}
            maxLength={MAX_LENGTH_QUESTION}
          />
        </div>

        <div className="flex flex-column gap-10px">
          <div className="text-title-xs-med">{labels.typeLabel}</div>
          <Dropdown
            disabled={customField.fieldType !== CustomFieldType.CUSTOM}
            options={CUSTOM_FIELDS_OPTIONS}
            optionLabel="label"
            value={customField.type}
            onChange={(e) => handleTypeChange(e.target.value)}
          />
          <div className="text-label-s-reg text-heavy-60">{getTypeDescription()}</div>
        </div>

        {Boolean(customField.answers?.length) && (
          <div className="flex flex-column gap-8px">
            <div className="text-title-xs-med mb-2px">{labels.answersLabel}</div>

            <div className="flex flex-column gap-8px -mx-20px" ref={list}>
              {customField.answers?.map((answer, index, { length }) => (
                <div key={index} className="flex-left-center gap-6px px-20px">
                  <div className="flex-center w-24px h-40px pr-4px -mr-4px drag-and-drop">
                    <DragAndDrop className="icon-20px" />
                  </div>

                  <div className="flex-1">
                    <InputText
                      className={`w-full ${hasPreviousDuplicates(customField.answers, index) ? 'p-invalid' : ''}`}
                      value={answer || ''}
                      placeholder={labels.placeholder + ' ' + (index + 1)}
                      onChange={(e) => handleAnswerChange(index, e.target.value.trimStart())}
                      maxLength={
                        [CustomType.TEXT, CustomType.CHECKBOXES, CustomType.RADIO_BUTTONS, CustomType.SELECT].includes(
                          customField.type || CustomType.TEXT
                        )
                          ? MAX_LENGTH_SHORT_ANSWER
                          : MAX_LENGTH_LONG_ANSWER
                      }
                    />
                  </div>

                  <div className="w-24px h-24px">
                    {length > 1 && (
                      <div className="action-button-sm" onClick={() => handleRemoveAnswer(index)}>
                        <XMarkIcon className="icon-16px" />
                      </div>
                    )}
                  </div>
                </div>
              ))}
            </div>

            <div className="flex-left-center gap-6px">
              <div className="w-20px" />
              <div className="flex-1 add-button-input" onClick={handleAddAnswer}>
                <PlusIcon className="icon-16px" />
              </div>
              <div className="w-24px" />
            </div>
          </div>
        )}

        <div className="flex-left-center gap-10px">
          <InputSwitch
            checked={!!customField.required}
            disabled={customField.fieldType !== CustomFieldType.CUSTOM}
            onChange={(e) => handleRequiredChange(!!e.value)}
          />
          <div className="text-label-lg-med text-heavy-80">{labels.requiredLabel}</div>
        </div>
      </div>

      <div className="flex gap-6px border-top-1 border-heavy-20 py-12px px-20px">
        <Button label={labels.save} className="button-xl w-120px" disabled={isSaveDisabled} onClick={handleSave} />
        <Button label={labels.cancel} className="button-xl" text onClick={onCancel} />
      </div>
    </div>
  );
});

EditCustomField.displayName = 'EditCustomField';
