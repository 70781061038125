import { Dropdown } from 'primereact/dropdown';
import { useSelector } from 'react-redux';
import { userSettingsSelectors } from '../../../store/userSettings';
import { timeZoneItem } from '../../../services/TimeZoneService';

type TimeZoneSelectorProps = {
  selectedMode: 'full' | 'short';
  options?: timeZoneItem[];
  value: string;
  target?: timeZoneItem[];
  onChange: (value: string) => void;
  className?: string;
  disabled?: boolean;
};

export const TimeZoneSelector = ({
  selectedMode,
  options,
  value,
  onChange,
  className,
  disabled,
}: TimeZoneSelectorProps) => {
  const groupedTimeZoneList = useSelector(userSettingsSelectors.selectGroupedTimeZoneLits);
  const groupedItemTemplate = (option: { region: string; zone: string }) => (
    <div className="font-bold text-lg">
      <div>{option.region}</div>
    </div>
  );

  const timeZoneItemTemplate = (option: { utcOffsetStr: string; city: string; time: string }) =>
    option && (
      <div className="w-full flex justify-content-between">
        <div>
          {option.utcOffsetStr} - {option.city}
        </div>
        <div>{option.time}</div>
      </div>
    );

  const timeZoneShortValueTemplate = (option: { utcOffsetStr: string; city: string; time: string }) =>
    option && (
      <div className="flex flex-column gap-5px">
        <div className="text-label-lg-med">{option.city}</div>
        <div className="text-label-xs-reg text-heavy-60">
          {option.utcOffsetStr.replace('+0', '+').replace('-0', '-').replace(':00', '')}
        </div>
      </div>
    );

  return (
    <>
      {options ? (
        <Dropdown
          id="inputtimezone"
          value={value}
          onChange={(e) => onChange(e.target.value as string)}
          className={className}
          options={options}
          optionLabel="city"
          optionValue="timeZone"
          valueTemplate={selectedMode === 'short' ? timeZoneShortValueTemplate : timeZoneItemTemplate}
          pt={selectedMode === 'short' ? { input: { className: 'p-0 pl-2px -ml-2px' } } : {}}
          itemTemplate={timeZoneItemTemplate}
          filter
          filterBy="city"
          resetFilterOnHide={true}
          disabled={disabled}
        />
      ) : (
        <Dropdown
          id="inputtimezone"
          value={value}
          onChange={(e) => onChange(e.target.value as string)}
          className={className}
          options={groupedTimeZoneList}
          optionLabel="city"
          optionValue="timeZone"
          optionGroupLabel="region"
          optionGroupChildren="zones"
          optionGroupTemplate={groupedItemTemplate}
          valueTemplate={selectedMode === 'short' ? timeZoneShortValueTemplate : timeZoneItemTemplate}
          pt={selectedMode === 'short' ? { input: { className: 'p-0 pl-2px -ml-2px' } } : {}}
          itemTemplate={timeZoneItemTemplate}
          filter
          filterBy="city"
          resetFilterOnHide={true}
          disabled={disabled}
        />
      )}
    </>
  );
};
