import { Button } from 'primereact/button';
import { QuickSetupScreen } from '../quickSetupScreen/QuickSetupScreen';
import { useDispatch } from 'react-redux';
import { quickSetupActions, quickSetupNavigationActions, quickSetupSelectors } from '../../../store/quickSetup';
import labels from './labels';
import { AppDispatch } from '../../../store/rootStore';
import { PhoneNumber } from '../../common';
import { useSelector } from 'react-redux';
import { InputTextarea } from 'primereact/inputtextarea';
import { MAX_LENGTH_DESCRIPTION } from '../../../types/constants';
import { useEffect } from 'react';
import { workspacesSelectors } from '../../../store/workspaces';
import { userSettingsSelectors } from '../../../store/userSettings';
import urlConstants from '../../../shared/JSON/urlConstants.json';
import { Checkbox } from 'primereact/checkbox';
import { Link } from 'react-router-dom';
import { ArrowRightIcon, ChatBubbleOvalLeftIcon } from '@heroicons/react/24/outline';

export const SetupPhoneCalls = () => {
  const dispatch = useDispatch<AppDispatch>();
  const workspace = useSelector(quickSetupSelectors.selectDefaultWorkspace);
  const currentWorkspace = useSelector(workspacesSelectors.selectCurrentWorkspaceData);
  const phoneCalls = useSelector(quickSetupSelectors.selectPhoneCalls);
  const phoneNumber = useSelector(quickSetupSelectors.selectPhoneNumber) || '';
  const phoneDetails = useSelector(quickSetupSelectors.selectPhoneDetails) || '';
  const isFirstAdmin = useSelector(userSettingsSelectors.selectIsFirstTenantUser);
  const isPhoneInvalid = useSelector(quickSetupSelectors.selectIsUserPhoneInvalid);
  const isAcceptedTerms = useSelector(quickSetupSelectors.selectIsAcceptTerms);
  const isQuickSetupFinished = useSelector(userSettingsSelectors.selectIsQuickSetupFinished);

  useEffect(() => {
    if (!Object.keys(workspace).length && currentWorkspace) {
      dispatch(quickSetupActions.setDefaultWorkspace(currentWorkspace));
    }
  }, [currentWorkspace]);

  const handleBack = () => {
    dispatch(quickSetupNavigationActions.navigateBack());
  };

  const handleNext = () => {
    isFirstAdmin
      ? dispatch(quickSetupNavigationActions.navigateNext())
      : dispatch(quickSetupActions.saveQuickSetupRequest());
  };

  const handlePhoneChange = (phoneNumber: string) => {
    dispatch(quickSetupActions.updatePhoneCalls({ phoneNumber }));
  };

  const handleCodeChange = (countryCode: string) => {
    dispatch(quickSetupActions.updatePhoneCalls({ countryCode }));
  };

  const handlePhoneDetails = (phoneDetails: string) => {
    dispatch(quickSetupActions.updatePhoneCalls({ phoneDetails }));
  };

  const handleAcceptTerms = (value: boolean) => {
    dispatch(quickSetupActions.setIsAcceptTerms(value));
  };

  return (
    <QuickSetupScreen.Container title={labels.stepMessage} titleIcon="🏆">
      <div className="flex flex-column">
        <div className="text-display-md-med">{labels.title}</div>
        <div className="text-body-lg-reg text-heavy-80 pt-2px pb-32px w-580px">{labels.description}</div>

        <div className="w-360px">
          <PhoneNumber
            countryCode={phoneCalls.countryCode || ''}
            handleChangeCode={handleCodeChange}
            phoneNumber={phoneNumber || ''}
            placeholder={labels.phonePlaceholder}
            handleChangePhone={handlePhoneChange}
            inputClassName={`w-full ${isPhoneInvalid ? 'p-invalid' : ''}`}
          />
        </div>

        <div className="flex flex-column gap-16px border-top-1 mt-20px pt-20px border-heavy-20 w-460px">
          <div className="flex align-items-center gap-6px text-label-s-med text-heavy-60">
            <ChatBubbleOvalLeftIcon className="w-20px" />
            <div>{labels.phoneDetailsLabel}</div>
          </div>
          <InputTextarea
            value={phoneDetails}
            onChange={(e) => handlePhoneDetails(e.target.value)}
            placeholder={labels.phoneDetailsPlaceholder}
            rows={1}
            autoResize
            maxLength={MAX_LENGTH_DESCRIPTION}
          />
        </div>

        {!isFirstAdmin && (
          <div
            className={`flex align-items-center gap-10px border-top-1 mt-20px pt-20px border-heavy-20 w-fit ${
              !isQuickSetupFinished && 'cursor-pointer'
            }`}
            onClick={() => !isQuickSetupFinished && handleAcceptTerms(!isAcceptedTerms)}
          >
            <Checkbox
              checked={isAcceptedTerms || !!isQuickSetupFinished}
              onChange={(e) => handleAcceptTerms(!!e.target.checked)}
              disabled={!!isQuickSetupFinished}
            />
            <div>
              <span>{labels.acceptTerms_step1 + ' '}</span>
              <Link to={urlConstants.TERMS_OF_SERVICE_URL} target="_blank">
                {labels.acceptTerms_step2}
              </Link>
              <span>{' ' + labels.acceptTerms_step3 + ' '}</span>
              <Link to={urlConstants.PRIVACY_POLICY_URL} target="_blank">
                {labels.acceptTerms_step4}
              </Link>
            </div>
          </div>
        )}
      </div>
      <QuickSetupScreen.Buttons>
        <Button
          label={labels.back}
          className="min-w-120px justify-content-center button-xl"
          onClick={handleBack}
          outlined
        />
        {isFirstAdmin ? (
          <Button className="min-w-120px justify-content-center button-xl" onClick={handleNext} disabled={isPhoneInvalid}>
            <span>{labels.next}</span>
            <ArrowRightIcon className="w-18px ml-8px" />
          </Button>
        ) : (
          <Button
            className="justify-content-center button-xl"
            label={labels.save}
            onClick={handleNext}
            disabled={(!isAcceptedTerms && !isQuickSetupFinished) || isPhoneInvalid}
          />
        )}
      </QuickSetupScreen.Buttons>
    </QuickSetupScreen.Container>
  );
};
