import { t } from '../../../../i18n/i18n';

export default {
  customize: t('Common:CUSTOMIZE'),
  brandingTitle: t('BookingPageHowStep:BRANDING_TITLE'),
  brandingDescriptionBookingPage: t('BookingPageHowStep:BRANDING_DESCRIPTION_BOOKING_PAGE'),
  brandingDescriptionBookingTemplate: t('BookingPageHowStep:BRANDING_DESCRIPTION_BOOKING_TEMPLATE'),
  calendarTitle: t('BookingPageHowStep:CALENDAR_TITLE'),
  calendarDescription: t('BookingPageHowStep:CALENDAR_DESCRIPTION'),
};
