import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  bookingPagesActions,
  bookingPageSelectors,
  deleteBookingPagesModalActions,
  FILTER_OPTION_TEAMS,
} from '../../store/bookingPages';
import { Path } from '../../routing';
import { BulkActionRow, CardSkeleton, EmptyListScreen, SectionHeader } from '../../components/common';
import { BookingPageCard } from '../../components/bookingPages/bookingPageCard/BookingPageCard';
import { BookingPageCloneModal } from '../../components/bookingPages/bookingPageCloneModal/BookingPageCloneModal';
import { BookingPageDeleteModal } from '../../components/bookingPages/bookingPageDeleteModal/BookingPageDeleteModal';
import labels from './labels';
import { Button } from 'primereact/button';
import { SKELETON_LENGTH } from '../../types/constants';
import { userSettingsSelectors } from '../../store/userSettings';
import { BookingStats, CalendarWarning, InstallInbox, InvitePeople } from '../../components/homePage/';
import { BookingPageAddToWebsiteModal } from '../../components/bookingPages/bookingPageAddToWebsiteModal/BookingPageAddToWebsiteModal';
import { authenticationSelectors } from '../../store/authentication';
import { navigationService } from '../../services/NavigationService';
import { usersSelectors } from '../../store/users';
import { Dropdown } from 'primereact/dropdown';
import { useSession } from '../../hooks/useSession';
import { FolderPlusIcon, TrashIcon, UsersIcon } from '@heroicons/react/24/outline';
import { SelectItem } from 'primereact/selectitem';
import { getInitials } from '../../services/utils';
import { bookingTemplatesSelectors } from '../../store/bookingTemplates';
import { GroupBookingPageCard } from '../../components/groupBookingPages/groupBookingPageCard/GroupBookingPageCard';
import { groupBookingPagesSelectors } from '../../store/groupBookingPages';
import { GroupBookingPageModals } from '../../components/groupBookingPages/groupBookingPageModals/GroupBookingPageModals';

export const BookingPages = () => {
  const dispatch = useDispatch();
  const { isInitialVisit } = useSession(); // checking if the app was initialized on current url
  const isSpinnerFetching = useSelector(bookingPageSelectors.selectIsSpinnerFetching);
  const isSkeletonFetching = useSelector(bookingPageSelectors.selectIsSkeletonFetching);
  const isGroupSpinnerFetching = useSelector(groupBookingPagesSelectors.selectIsSpinnerFetching);
  const isGroupSkeletonFetching = useSelector(groupBookingPagesSelectors.selectIsSkeletonFetching);
  const isUserSettingsReceived = useSelector(userSettingsSelectors.selectIsUserSettingsReceived);
  const personalBookingPages = useSelector(bookingPageSelectors.selectPersonalSearchedBookingPages);
  const sharedBookingPages = useSelector(bookingPageSelectors.selectSharedSearchedBookingPages);
  const groupBookingPages = useSelector(groupBookingPagesSelectors.selectSearchedGroupBookingPages);
  const selectedBookingPages = useSelector(bookingPageSelectors.selectSelectedBookingPages);
  const isLicenseActive = useSelector(authenticationSelectors.selectIsLicenseActive);
  const usersOptions = useSelector(usersSelectors.selectLoggedUsersOptions);
  const filterByUser = useSelector(bookingPageSelectors.selectFilterByUser);
  const allBookingPagesRead = useSelector(userSettingsSelectors.selectAllBookingPagesRead);
  const hostsInfo = useSelector(bookingPageSelectors.selectHostsInfoById);
  const templatesById = useSelector(bookingTemplatesSelectors.selectBookingTemplatesById);
  const groupBPLength = useSelector(groupBookingPagesSelectors.selectGroupBookingPagesLength);
  const bookingPagesLength = useSelector(bookingPageSelectors.selectBookingPagesLength);

  useEffect(() => {
    if (isUserSettingsReceived && isLicenseActive) {
      dispatch(bookingPagesActions.getBookingPagesPageRequest(isInitialVisit));
    }
  }, [isUserSettingsReceived, isInitialVisit]);

  const handleCheckboxChange = (id: string, selected: boolean | undefined) => {
    selected
      ? dispatch(bookingPagesActions.selectBookingPage(id))
      : dispatch(bookingPagesActions.unselectBookingPage(id));
  };

  const handleNewBookingPage = () => {
    dispatch(bookingPagesActions.clearBookingPage());
    navigationService.navigateTo(Path.EditBookingPage.replace(':bookingPageId', 'new'));
  };

  const handleCancelSelection = () => {
    dispatch(bookingPagesActions.unselectAllBookingPage());
  };

  const handleOpenDeleteModal = () => {
    if (selectedBookingPages.length > 0) {
      dispatch(deleteBookingPagesModalActions.openModal());
    }
  };

  const handleGroupBookingPages = () => {
    navigationService.navigateTo(Path.EditGroupBookingPage.replace(':groupBookingPageId', 'new'));
  };

  const handleFilterByUserChange = (value: string) => {
    dispatch(bookingPagesActions.setFilterByUser(value));
  };

  const filterValueTemplate = (option: SelectItem) => (
    <div className="-m-12px p-4px">
      <div className="p-avatar p-avatar-xl">
        {option.value === FILTER_OPTION_TEAMS ? (
          <UsersIcon width={20} height={20} />
        ) : (
          <div>{getInitials(option.label || '')}</div>
        )}
      </div>
    </div>
  );

  const filterItemTemplate = (option: SelectItem) => (
    <div className="flex align-items-center gap-8px -ml-8px">
      <div className="p-avatar p-avatar-lg">
        {option.value === FILTER_OPTION_TEAMS ? (
          <UsersIcon width={20} height={20} />
        ) : (
          <div>{getInitials(option.label || '')}</div>
        )}
      </div>
      <div>{option.label}</div>
    </div>
  );

  const bulkContent = (
    <>
      <Button text className="gap-8px text-button-md-med" onClick={handleOpenDeleteModal}>
        <TrashIcon className="icon-18px" />
        {labels.delete}
      </Button>
      <div className="bg-heavy-80 w-1px h-20px" />
      <Button
        text
        className="gap-8px text-button-md-med"
        onClick={handleGroupBookingPages}
        disabled={selectedBookingPages.length < 2}
      >
        <FolderPlusIcon className="icon-18px" />
        {labels.group}
      </Button>
    </>
  );

  return (
    <>
      <BookingPageCloneModal />
      <BookingPageDeleteModal />
      <BookingPageAddToWebsiteModal />
      <GroupBookingPageModals />
      <BulkActionRow
        selectedCount={selectedBookingPages.length}
        handleClose={handleCancelSelection}
        actionsContent={bulkContent}
      />

      <CalendarWarning />
      <InstallInbox />
      <InvitePeople />
      <BookingStats />

      <div className="sumo-card-bg flex flex-column pt-16px">
        <SectionHeader
          loading={isSpinnerFetching || isGroupSpinnerFetching}
          title={labels.title}
          itemsLength={bookingPagesLength + groupBPLength}
          searchPaths={[Path.BookingPages, Path.EditBookingPage]}
          buttonLabel={labels.newBookingPage}
          onButtonClick={handleNewBookingPage}
          extraContent={
            allBookingPagesRead && usersOptions.length > 1 ? (
              <Dropdown
                className="flex-none p-0 no-trigger p-button button-xl button-white"
                options={usersOptions}
                value={filterByUser}
                valueTemplate={filterValueTemplate}
                itemTemplate={filterItemTemplate}
                onChange={(e) => handleFilterByUserChange(e.target.value)}
              />
            ) : undefined
          }
        />

        <div className="flex flex-wrap -m-8px">
          {isSkeletonFetching || isGroupSkeletonFetching ? (
            [...Array(SKELETON_LENGTH)].map((value, index) => (
              <div className="w-12 lg:w-6 p-8px" key={index}>
                <CardSkeleton />
              </div>
            ))
          ) : (
            <>
              {personalBookingPages.map((bookingPage) => (
                <div className="w-12 lg:w-6 p-8px" key={bookingPage.id}>
                  <BookingPageCard
                    bookingPage={bookingPage}
                    selectMode={Boolean(selectedBookingPages.length)}
                    selected={selectedBookingPages.includes(bookingPage.id)}
                    hostsInfo={hostsInfo[bookingPage.id]}
                    template={templatesById[bookingPage.what?.bookingTemplateId || '']}
                    handleCheckboxChange={handleCheckboxChange}
                  />
                </div>
              ))}

              {!!groupBookingPages.length && (
                <>
                  <div className="w-12 p-8px pt-32px flex-left-center gap-6px text-label-s-med text-heavy-60">
                    <UsersIcon className="icon-20px" />
                    <span>{labels.groupsLabel}</span>
                  </div>
                  {groupBookingPages.map((groupBookingPage) => (
                    <div className="w-12 lg:w-6 p-8px" key={groupBookingPage.id}>
                      <GroupBookingPageCard groupBookingPage={groupBookingPage} />
                    </div>
                  ))}
                </>
              )}

              {!!sharedBookingPages.length && (
                <>
                  <div className="w-12 p-8px pt-32px flex-left-center gap-6px text-label-s-med text-heavy-60">
                    <UsersIcon className="icon-20px" />
                    <span>{labels.sharedLabel}</span>
                  </div>
                  {sharedBookingPages.map((bookingPage) => (
                    <div className="w-12 lg:w-6 p-8px" key={bookingPage.id}>
                      <BookingPageCard
                        bookingPage={bookingPage}
                        selectMode={Boolean(selectedBookingPages.length)}
                        selected={selectedBookingPages.includes(bookingPage.id)}
                        hostsInfo={hostsInfo[bookingPage.id]}
                        handleCheckboxChange={handleCheckboxChange}
                      />
                    </div>
                  ))}
                </>
              )}

              {!personalBookingPages.length && !sharedBookingPages.length && (
                <div className="w-12 p-8px">
                  <EmptyListScreen />
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
};
