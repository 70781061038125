import labels from './labels';
import { useSelector } from 'react-redux';
import { authenticationSelectors } from '../../../store/authentication';
import { CreditCardIcon, Square3Stack3DIcon, UserIcon, UserPlusIcon, WalletIcon } from '@heroicons/react/24/outline';
import { SectionHeader } from '../../common';
import { Button } from 'primereact/button';
import { navigationService } from '../../../services/NavigationService';
import { Path } from '../../../routing';
import { BuyLicenseButton } from '../../billing';
import { Skeleton } from 'primereact/skeleton';

export const LicenseListSection = () => {
  const isSpinnerFetching = useSelector(authenticationSelectors.selectIsSpinnerFetching);
  const isSkeletonFetching = useSelector(authenticationSelectors.selectIsSkeletonFetching);
  const licenseStatistics = useSelector(authenticationSelectors.selectLicenseStatistics);
  const isPaidLicense = useSelector(authenticationSelectors.selectIsPaidLicense);

  const statsClassName = 'flex-center w-40px h-60px border-radius-8px';
  const generateStatsIcon = (index: number) => {
    switch (index) {
      case 0:
        return <WalletIcon width={20} />;
      case 1:
        return <Square3Stack3DIcon width={20} />;
      case 2:
        return <UserPlusIcon width={20} />;
      case 3:
        return <UserIcon width={20} />;
      default:
        return <></>;
    }
  };
  const generateStatsClassName = (index: number) => {
    switch (index) {
      case 0:
        return statsClassName + ' bg-blue-light text-blue-dark';
      case 1:
        return statsClassName + ' bg-egg-blue-light text-egg-blue-dark';
      case 2:
        return statsClassName + ' bg-saffron-light text-saffron-dark';
      case 3:
        return statsClassName + ' bg-heavy-1 text-heavy-60';
      default:
        return '';
    }
  };

  const generateLicenseButton = () =>
    isPaidLicense ? (
      <Button className="flex-none button-xl" onClick={() => navigationService.navigateTo(Path.Billing)}>
        <CreditCardIcon className="icon-18px" />
        <span className="flex-1 pl-8px">{labels.myBillings}</span>
      </Button>
    ) : (
      <BuyLicenseButton />
    );

  return (
    <div className="sumo-card-bg flex flex-column pt-16px mb-28px">
      <SectionHeader loading={isSpinnerFetching} title={labels.licenseTitle} buttonNode={generateLicenseButton()} />
      <div className="sumo-card flex justify-content-between gap-10px p-10px">
        {licenseStatistics.map((item, index) => (
          <div className="flex align-items-center gap-10px" key={`statItems_${index}`}>
            <div className={generateStatsClassName(index)}>{generateStatsIcon(index)}</div>
            <div className="flex flex-column gap-6px">
              <div className="text-label-s-med text-heavy-60">{labels.statItemsLabels[index]}</div>
              {isSkeletonFetching ? (
                <Skeleton height="32px" />
              ) : (
                <div className="text-display-md-reg">{item.value}</div>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
