export const OPSConsoleStaff = Object.freeze({
  ACTIVE: `Ativo`,
  ADD_NEW_MEMBER: `Adicionar novo membro`,
  ADMIN_TITLE: `Administrador principal`,
  INACTIVE: `Inativo`,
  SALES_TITLE: `Vendas`,
  SUPPORT_TITLE: `Suporte`,
  OPERATIONS_TITLE: `Operações`,
  TITLE: `Equipe`,
  ADD_STAFF_TITLE: `Criar membro da equipe`,
  DUPLICATE_EMAIL: `Este e-mail já foi adicionado.`,
  LAST_LOGIN: `Último login`,
});

export const StaffToastsNotifications = Object.freeze({
  GET_STAFF_FAIL_MESSAGE: `Falha na solicitação de funcionários`,
  SAVE_STAFF_SUCCESS_MESSAGE: `Solicitação de funcionários salva com sucesso`,
  SAVE_STAFF_FAIL_MESSAGE: `Falha na solicitação de funcionários`,
  CHANGE_STAFF_STATUS_FAIL_MESSAGE: `Falha na solicitação de mudança de status de funcionários`,
  ACTIVATE_STAFF_SUCCESS_MESSAGE: `Registro de funcionários ativado com sucesso`,
  DEACTIVATE_STAFF_SUCCESS_MESSAGE: `Registro de funcionários desativado com sucesso`,
  DELETE_STAFF_SUCCESS_MESSAGE: `Registro de funcionários excluído com sucesso`,
  DELETE_STAFF_FAIL_MESSAGE: `Falha na solicitação de exclusão de funcionários`,
  VIEW_AS_USER_FAIL_MESSAGE: `Falha na solicitação de visualização como usuário`,
});
