import { t } from '../../i18n/i18n';

export default {
  title: t('Profile:TITLE'),
  accountSettings: t('Profile:ACCOUNT_SETTINGS'),
  accountDescription: t('Profile:ACCOUNT_DESCRIPTION'),
  login: t('Profile:LOGIN'),
  loginDescription: t('Profile:LOGIN_DESCRIPTION'),
  myRole: t('Profile:MY_ROLE'),
  myRoleDescription: t('Profile:MY_ROLE_DESCRIPTION'),
  cookieSettings: t('Profile:COOKIE_SETTINGS'),
  cookieDescription: t('Profile:COOKIE_DESCRIPTION'),
};
