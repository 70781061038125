import { Skeleton } from 'primereact/skeleton';

export const PublicBookingPageSkeleton = () => {
  return (
    <div className="booking-container bg-blank">
      <div className="booking-background"></div>
      <div className="booking-card-container">
        <div className="booking-card">
          <div className="booking-header">
            <div className="booking-title">
              <Skeleton width="240px" height="46px" />
            </div>
          </div>
          <div className="booking-content px-28px py-20px">
            <Skeleton height="100%" />
          </div>
        </div>
      </div>
    </div>
  );
};
