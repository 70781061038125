import { createSelector } from 'reselect';
import { checkTimeWrongOrder, checkTimeOverlapping } from '../../services/DateService';
import { State } from '../rootStore';
import { AvailabilityOption, AvailabilityOptions } from './types';
import { DEFAULT_AVAILABILITY } from './constants';
import { globalSelectors } from '../global';

// TODO: rename to availabilityState
const userAvailabilityState = (state: State) => state.userAvailability;

// TODO: start hadling availability.error
const selectIsFetching = createSelector(userAvailabilityState, (availability) => availability.isFetching);

const selectUserAvailabilities = createSelector(
  userAvailabilityState,
  (availability) => availability.userAvailabilities
);
const selectUserAvailabilitiesData = createSelector(selectUserAvailabilities, (availabilities) =>
  availabilities.map((availability) => availability?.availabilityData)
);
const selectUserAvailabilitiesNames = createSelector(selectUserAvailabilities, (availabilities) =>
  availabilities.map(({ availabilityData }) => availabilityData?.name)
);
const selectUserAvailabilitiesOptions = createSelector(
  selectUserAvailabilities,
  globalSelectors.selectSearchString,
  (availabilities, searchString) =>
    availabilities.length
      ? [...availabilities] // Create a shallow copy of the array
          .filter((availability) =>
            availability.availabilityData?.name.toLowerCase().includes(searchString.toLowerCase())
          )
          .sort((a, b) => {
            // Sorting that isDefault item should be the first
            if (a.availabilityData?.isDefault) {
              return -1;
            }
            if (b.availabilityData?.isDefault) {
              return 1;
            }
            const aCreatedAt = a.createdAt ? a.createdAt : '';
            const bCreatedAt = b.createdAt ? b.createdAt : '';
            return aCreatedAt <= bCreatedAt ? -1 : 1;
          })
          .map(
            ({ availabilityData, link }) =>
              ({
                label: availabilityData?.name,
                value: link,
                isDefault: !!availabilityData?.isDefault,
              } as AvailabilityOption)
          )
      : ([] as AvailabilityOptions)
);

const selectUserAvailability = createSelector(userAvailabilityState, (availability) => availability.userAvailability);

// selectedAvailability before changes
const selectInitUserAvailability = createSelector(
  selectUserAvailability,
  selectUserAvailabilities,
  (selectedAvailability, availabilities) => {
    if (selectedAvailability !== DEFAULT_AVAILABILITY) {
      return (
        availabilities.find((availability) => availability?.link === selectedAvailability.link) || selectedAvailability
      );
    } else {
      return DEFAULT_AVAILABILITY;
    }
  }
);

const selectUserAvailabilityLink = createSelector(selectUserAvailability, (availability) => availability.link);
const selectUserAvailabilityData = createSelector(
  selectUserAvailability,
  (availability) => availability.availabilityData
);
const selectActiveBookingPages = createSelector(
  selectUserAvailabilityData,
  (availabilityData) => availabilityData?.activeBookingPages
);
const selectIsDefault = createSelector(selectUserAvailabilityData, (availabilityData) => availabilityData?.isDefault);
const selectName = createSelector(selectUserAvailabilityData, (availabilityData) => availabilityData?.name);
const selectWeeklyHours = createSelector(
  selectUserAvailabilityData,
  (availabilityData) => availabilityData?.weeklyHours || []
);
const selectOverrides = createSelector(
  selectUserAvailabilityData,
  (availabilityData) => availabilityData?.overrides || []
);
const selectSortedOverrides = createSelector(selectOverrides, (overrides) =>
  overrides
    .filter((date) => {
      // '2024-06-20T14:37:22.986Z' -> '2024-06-20T00:00:00.000Z'
      const todayDate = new Date();
      todayDate.setHours(0, 0, 0, 0);
      return date?.days?.to && date?.days?.to >= todayDate.toISOString();
    })
    .sort((a, b) => {
      if (!a?.days?.from || !b?.days?.from) {
        return 0;
      }
      return a.days.from > b.days.from ? 1 : -1;
    })
);
const selectSortedAvailableOverrides = createSelector(selectSortedOverrides, (overrides) =>
  overrides.filter(
    (override) => override && override.time && override.time[0] && override.time[0].from !== override.time[0].to
  )
);
const selectSortedUnavailableOverrides = createSelector(selectSortedOverrides, (overrides) =>
  overrides.filter(
    (override) =>
      override && override.time?.length === 1 && override.time[0] && override.time[0].from === override.time[0].to
  )
);

const selectCloneName = createSelector(userAvailabilityState, (availability) => availability.cloneName);

const selectIsNameDuplicate = createSelector(
  selectUserAvailabilitiesData,
  selectUserAvailabilityData,
  selectCloneName,
  (availabilities, availability, name) =>
    Boolean(availabilities?.find((item) => item?.id !== availability?.id && item?.name === name))
);
const selectIsNameValid = createSelector(
  selectName,
  selectIsNameDuplicate,
  (name, isDuplicate) => Boolean(name) && !isDuplicate
);
const selectIsCloneNameDuplicate = createSelector(selectUserAvailabilitiesNames, selectCloneName, (names, cloneName) =>
  Boolean(names?.find((name) => name === cloneName))
);
const selectIsCloneNameValid = createSelector(
  selectCloneName,
  selectIsCloneNameDuplicate,
  (name, isDuplicate) => Boolean(name) && !isDuplicate
);
const selectIsWeeklyHoursValid = createSelector(selectWeeklyHours, (weeklyHours) =>
  weeklyHours.every((day) => !day?.time || (!checkTimeOverlapping(day?.time) && !checkTimeWrongOrder(day?.time)))
);

const selectDefaultAvailability = createSelector(selectUserAvailabilities, (availabilities) =>
  availabilities.find(({ availabilityData }) => availabilityData?.isDefault)
);
const selectDefaultAvailabilityWeeklyHours = createSelector(
  selectDefaultAvailability,
  (availability) => availability?.availabilityData?.weeklyHours
);

const selectAvailabilityByLink = (link?: string) =>
  createSelector(selectUserAvailabilities, (availabilities) =>
    availabilities.find((availability) => availability.link === link)
  );

const selectOldestAvailability = (exceptLink?: string) =>
  createSelector(selectUserAvailabilities, (availabilities) => {
    const sortedAvailabilities = (
      exceptLink ? [...availabilities].filter((availability) => availability.link !== exceptLink) : [...availabilities]
    ).sort((a, b) => {
      const aCreatedAt = a.createdAt ? a.createdAt : '';
      const bCreatedAt = b.createdAt ? b.createdAt : '';
      return aCreatedAt <= bCreatedAt ? -1 : 1;
    });
    return sortedAvailabilities[0];
  });

export const userAvailabilitySelectors = {
  selectIsFetching,
  selectUserAvailabilities,
  selectUserAvailabilitiesNames,
  selectUserAvailabilitiesOptions,
  selectDefaultAvailability,
  selectUserAvailability,
  selectUserAvailabilityLink,
  selectInitUserAvailability,
  selectActiveBookingPages,
  selectIsDefault,
  selectName,
  selectSortedOverrides,
  selectSortedAvailableOverrides,
  selectSortedUnavailableOverrides,
  selectWeeklyHours,
  selectCloneName,
  selectIsNameDuplicate,
  selectIsNameValid,
  selectIsCloneNameDuplicate,
  selectIsCloneNameValid,
  selectIsWeeklyHoursValid,
  selectDefaultAvailabilityWeeklyHours,
  selectAvailabilityByLink,
  selectOldestAvailability,
};
