import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  CHANGE_AVAILABILITY_NAME,
  LIMIT_30_ITEMS_ERROR_TOAST,
  changeAvailabilityNameModalActions,
  changeAvailabilityNameModalSelectors,
  userAvailabilityActions,
  userAvailabilitySelectors,
} from '../../../store/availability';

import { bookingPagesActions } from '../../../store/bookingPages';
import { ConfirmationModal, Modal, UserAvatar } from '../../common';
import { AvailabilityTable, AvailabilityOverride } from '../';
import labels from './labels';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Menu } from 'primereact/menu';
import { MAX_LENGTH_NAME } from '../../../types/constants';
import { AppDispatch } from '../../../store/rootStore';
import { userSettingsSelectors } from '../../../store/userSettings';
import { notificationsActions } from '../../../store/notifications';
import { authenticationSelectors } from '../../../store/authentication';
import { useSession } from '../../../hooks/useSession';
import { ReactComponent as StarIcon } from '../../../assets/icons/12-star.svg';
import { GlobeAltIcon, EllipsisVerticalIcon } from '@heroicons/react/24/outline';
import { TabPanel, TabView } from 'primereact/tabview';
import { ErrorText } from '../../common/errorText/ErrorText';

export const AvailabilityRecord = () => {
  const dispatch: AppDispatch = useDispatch();
  const { isInitialVisit } = useSession(); // checking if the app was initialized on current url
  const fullName = useSelector(userSettingsSelectors.selectFullName) || '';
  const availabilities = useSelector(userAvailabilitySelectors.selectUserAvailabilities);
  const link = useSelector(userAvailabilitySelectors.selectUserAvailabilityLink);
  const name = useSelector(userAvailabilitySelectors.selectName);
  const isDefault = useSelector(userAvailabilitySelectors.selectIsDefault);
  const cloneName = useSelector(userAvailabilitySelectors.selectCloneName);
  const isNameDuplicate = useSelector(userAvailabilitySelectors.selectIsNameDuplicate);
  const isNameValid = useSelector(userAvailabilitySelectors.selectIsNameValid);
  const isCloneNameDuplicate = useSelector(userAvailabilitySelectors.selectIsCloneNameDuplicate);
  const isCloneNameValid = useSelector(userAvailabilitySelectors.selectIsCloneNameValid);
  const isWeeklyHoursValid = useSelector(userAvailabilitySelectors.selectIsWeeklyHoursValid);
  const availabilitiesOptions = useSelector(userAvailabilitySelectors.selectUserAvailabilitiesOptions);
  const selectedAvailability = useSelector(userAvailabilitySelectors.selectUserAvailability);
  const selectedInitAvailability = useSelector(userAvailabilitySelectors.selectInitUserAvailability);
  const oldestAvailability = useSelector(userAvailabilitySelectors.selectOldestAvailability(link || ''));

  const isUserSettingsReceived = useSelector(userSettingsSelectors.selectIsUserSettingsReceived);
  const isCreateAvail = useSelector(userSettingsSelectors.selectAvailabilityCreate);
  const isEditAvail = useSelector(userSettingsSelectors.selectAvailabilityEdit);
  const isDeleteAvail = useSelector(userSettingsSelectors.selectAvailabilityDelete);

  const isLicenseActive = useSelector(authenticationSelectors.selectIsLicenseActive);

  const timeZoneCity = useSelector(userSettingsSelectors.selectTimeZoneCity);
  const timeZoneUTC = useSelector(userSettingsSelectors.selectTimeZoneUTC);

  const isChangeNameModalOpen = useSelector(changeAvailabilityNameModalSelectors.selectIsModalOpen);

  const [isClone, setIsClone] = useState(false);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const menu = useRef<Menu>(null);

  useEffect(() => {
    if (isUserSettingsReceived && isLicenseActive) {
      dispatch(bookingPagesActions.getBookingPagesPageRequest(isInitialVisit));
    }
  }, [isUserSettingsReceived, isInitialVisit]);

  useEffect(() => {
    // if the system will found valid changes after 0.5sec it will update the availability
    const timer = setTimeout(() => {
      if (
        selectedAvailability !== selectedInitAvailability &&
        isWeeklyHoursValid &&
        !isChangeNameModalOpen &&
        isEditAvail
      ) {
        dispatch(userAvailabilityActions.saveUserAvailabilityRequest());
      }
    }, 500);

    return () => clearTimeout(timer);
  }, [selectedAvailability, selectedInitAvailability, dispatch]);

  const handleEditName = () => {
    setIsClone(false);
    dispatch(userAvailabilityActions.editAvailabilityNameThunk());
  };

  const handleCloneAvailability = () => {
    if (availabilitiesOptions.length >= 30) {
      dispatch(notificationsActions.showToast(LIMIT_30_ITEMS_ERROR_TOAST));
    } else {
      setIsClone(true);
      dispatch(userAvailabilityActions.cloneAvailabilityThunk());
    }
  };

  const handleDeleteAvailability = () => {
    dispatch(userAvailabilityActions.deleteUserAvailabilityRequest(link));
    setIsConfirmModalOpen(false);
  };

  const handleNameChange = (name: string) => {
    dispatch(userAvailabilityActions.setCloneName(name));
  };

  const handleNameDialogCancel = () => {
    dispatch(changeAvailabilityNameModalActions.closeModal());
  };

  const handleNameSave = () => {
    if (isClone) {
      dispatch(userAvailabilityActions.cloneUserAvailabilityRequest());
    } else {
      dispatch(userAvailabilityActions.updateUserAvailability({ name: cloneName }));
      dispatch(userAvailabilityActions.saveUserAvailabilityRequest());
    }
    dispatch(changeAvailabilityNameModalActions.closeModal());
  };

  const handleSetAsDefault = () => {
    dispatch(userAvailabilityActions.setDefaultAvailabilityRequest(true));
  };

  const menuItems = [
    {
      label: labels.editName,
      command: handleEditName,
      visible: isEditAvail,
    },
    {
      label: labels.clone,
      command: handleCloneAvailability,
      visible: isCreateAvail,
    },
    ...(!isDefault
      ? [
          {
            label: labels.setDefault,
            command: handleSetAsDefault,
            visible: isEditAvail,
          },
        ]
      : []),
    ...(availabilities.length > 1
      ? [
          {
            label: labels.delete,
            command: () => setIsConfirmModalOpen(true),
            visible: isDeleteAvail,
          },
        ]
      : []),
  ];

  return (
    <div>
      <div className="sumo-card flex flex-column">
        <div className="flex m-12px">
          <UserAvatar large={true} name={fullName} className="w-48px h-48px" noImage/>
          <div className="flex flex-column justify-content-center gap-4px ml-16px mr-4px">
            <div className="text-title-lg-med">{name}</div>
            {isDefault && (
              <div className="flex align-items-center gap-4px text-label-xs-reg text-heavy-60">
                <div>
                  <StarIcon width={12} height={12} />
                </div>
                <span>{labels.isDefault}</span>
              </div>
            )}
          </div>
          {(isEditAvail || isCreateAvail || (availabilities.length > 1 && isDeleteAvail)) && (
            <div
              className="align-self-center action-button"
              onClick={(e) => {
                e.stopPropagation();
                menu.current?.toggle(e);
              }}
            >
              <Menu ref={menu} model={menuItems} popup appendTo="self" className="scroll-menu" />
              <EllipsisVerticalIcon width={20} height={20} />
            </div>
          )}
          <div className="flex align-items-center gap-8px ml-auto">
            <div className="h-40px w-40px p-11px bg-heavy-1 border-radius-6px">
              <GlobeAltIcon width={18} height={18} />
            </div>
            <div className="flex flex-column gap-5px">
              <div className="text-label-lg-med">{timeZoneCity}</div>
              <div className="text-label-xs-reg text-heavy-60">{timeZoneUTC}</div>
            </div>
            {/*   <ChevronDownIcon width={16} hanging={16} className="text-heavy-50" /> {/* className to show disabled */}
          </div>
        </div>
        <div className="-mx-2px mb-10px border-bottom-1 border-heavy-20" />

        <TabView className="-mx-2px">
          <TabPanel header={labels.schedule}>
            <AvailabilityTable />
          </TabPanel>
          <TabPanel header={labels.overrides}>
            <AvailabilityOverride />
          </TabPanel>
        </TabView>
      </div>

      <Modal.Container name={CHANGE_AVAILABILITY_NAME} closable={true} onClose={handleNameDialogCancel}>
        <Modal.Header>
          <div>{isClone ? labels.cloneTitle : labels.dialogEditTitleName}</div>
        </Modal.Header>
        <div className="w-440px">
          <InputText
            type="text"
            value={cloneName}
            onChange={(e) => {
              handleNameChange(e.target.value);
            }}
            placeholder={labels.dialogPlaceholder}
            className={`w-full ${(isClone ? !isCloneNameValid : !isNameValid) && 'p-invalid'}`}
            maxLength={MAX_LENGTH_NAME}
          />
          {(isClone ? isCloneNameDuplicate : isNameDuplicate) && (
            <ErrorText text={labels.duplicateNameMessage} className="mt-10px" />
          )}
        </div>
        <Modal.Buttons>
          <Button onClick={handleNameSave} disabled={isClone ? !isCloneNameValid : !isNameValid} autoFocus>
            {labels.confirm}
          </Button>
          <Button onClick={handleNameDialogCancel} text>
            {labels.cancel}
          </Button>
        </Modal.Buttons>
      </Modal.Container>

      <ConfirmationModal
        visible={isConfirmModalOpen}
        title={labels.delete}
        description={`${labels.deleteDesc} "${name}"? ${labels.deleteWarningMessage}`}
        additionalText={[isDefault ? `"${oldestAvailability?.availabilityData?.name}" ${labels.deleteMessage}` : '']}
        confirmButtonLabel={labels.yesDelete}
        cancelButtonLabel={labels.noCancel}
        onConfirm={handleDeleteAvailability}
        onCancel={() => setIsConfirmModalOpen(false)}
        onClose={() => setIsConfirmModalOpen(false)}
      />
    </div>
  );
};
