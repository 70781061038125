import { PlusIcon, TrashIcon } from '@heroicons/react/24/outline';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { useDispatch, useSelector } from 'react-redux';
import {
  CARD,
  CONDTION_ATTACHMENT_SIZE_OPTIONS,
  CONDTION_ATTACHMENT_TYPE_OPTIONS,
  journeysActions,
  journeysSelectors,
} from '../../../store/journeyBuilder';
import { AttachmentSize, AttachmentType, JourneyConditionType } from '../../../API';
import { InputText } from 'primereact/inputtext';
import { useEffect, useState } from 'react';
import labels from './labels';
import { MAX_LENGTH_NAME, MAX_LENGTH_SHORT_ANSWER } from '../../../types/constants';

type EditJourneyConditionStepProps = {
  isValidate: boolean;
  isReadOnly: boolean;
};

export const EditJourneyConditionStep = ({ isValidate, isReadOnly }: EditJourneyConditionStepProps) => {
  const dispatch = useDispatch();
  const parents = useSelector(journeysSelectors.selectAllStepParents);
  const step = useSelector(journeysSelectors.selectJourneyStep);
  const condition = useSelector(journeysSelectors.selectJourneyCondition);
  const conditionOptions = useSelector(journeysSelectors.selectConditionOptions);
  const conditionTypeOptions = useSelector(journeysSelectors.selectConditionTypeOptions);
  const conditionFieldAnswers = useSelector(journeysSelectors.selectJourneyConditionFieldAnswers);

  const cards = useSelector(journeysSelectors.selectAllCards);
  const isAttachment = useSelector(journeysSelectors.selectIsAttachmentConditionField);
  const isRange = useSelector(journeysSelectors.selectIsRange);
  const isFileSize = useSelector(journeysSelectors.selectIsFileSizeType);

  const [localValue, setLocalValue] = useState<Array<string | null>>(condition?.value || []);

  useEffect(() => {
    setLocalValue(condition?.value || []);
  }, [condition?.value]);

  const getConditionIndex = () => {
    const index = parents[0].children?.findIndex((child) => child?.id === step?.id);
    return (index || 0) + 1;
  };

  const handleSelectConditionField = (conditionField: string) => {
    dispatch(journeysActions.updateConditionStep({ conditionField, type: null, attachmentType: null, value: [null] }));
  };

  const handleAttachmentTypeChange = (attachmentType: AttachmentType) => {
    let value = condition?.value;
    // should we reset value or not
    if (
      condition?.attachmentType &&
      (([AttachmentType.FILETYPE_IS, AttachmentType.FILETYPE_IS_NOT].includes(condition?.attachmentType) &&
        [AttachmentType.SIZE_IS_BIGGER_THAN, AttachmentType.SIZE_IS_SMALLER_THAN].includes(attachmentType)) ||
        ([AttachmentType.FILETYPE_IS, AttachmentType.FILETYPE_IS_NOT].includes(condition?.attachmentType) &&
          [AttachmentType.FILETYPE_IS, AttachmentType.FILETYPE_IS_NOT].includes(attachmentType)))
    ) {
      value = [null];
    }
    dispatch(journeysActions.updateConditionStep({ attachmentType, value }));
  };

  const handleTypeChange = (type: JourneyConditionType) => {
    let value = condition?.value;
    // reset value only if new or previous type was range
    if (type === JourneyConditionType.RANGE || condition?.type === JourneyConditionType.RANGE) {
      value = [null];
    }
    dispatch(journeysActions.updateConditionStep({ type, value }));
  };

  const handleValueChange = (value: string, index: number) => {
    const newValue = [...(condition?.value || [])];
    newValue[index] = value;
    dispatch(journeysActions.updateConditionStep({ value: newValue }));
  };

  const handleInputValueChange = (value: string, index: number) => {
    const newValue = [...(condition?.value || [])];
    newValue[index] = value;
    setLocalValue(newValue);
  };

  const handleInputValueBlur = () => {
    dispatch(journeysActions.updateConditionStep({ value: localValue }));
  };

  const handleAddValue = () => {
    // if type range - add two elements, otherwise one
    dispatch(
      journeysActions.updateConditionStep({
        value: [...(condition?.value || []), ...(isRange ? [null, null] : [null])],
      })
    );
  };

  const handleRemoveValue = (index: number) => {
    const newValue = [...(condition?.value || [])];
    newValue.splice(index, isRange ? 2 : 1);
    dispatch(journeysActions.updateConditionStep({ value: newValue }));
  };

  const handleFileSizeTypeChange = (attachmentSize: Partial<AttachmentSize>) => {
    dispatch(
      journeysActions.updateConditionStep({
        attachmentSize: { __typename: 'AttachmentSize', ...condition?.attachmentSize, ...attachmentSize },
      })
    );
  };

  const getAnswerFieldsInput = (value: string, index: number) => {
    const disabled = !condition?.type || isReadOnly;

    if (condition?.conditionField === CARD) {
      return (
        <Dropdown
          options={cards}
          optionValue="id"
          optionLabel="title"
          value={value}
          onChange={(e) => handleValueChange(e.target.value, index)}
          className={`w-full ${isValidate && condition?.type && !value ? 'p-invalid' : ''}`}
          disabled={disabled}
        />
      );
    }
    if (conditionFieldAnswers.length) {
      return (
        <Dropdown
          options={conditionFieldAnswers}
          value={value}
          onChange={(e) => handleValueChange(e.target.value, index)}
          className={`w-full ${isValidate && condition?.type && !value ? 'p-invalid' : ''}`}
          disabled={disabled}

        />
      );
    } else if (isRange) {
      return index % 2 === 0 ? (
        <>
          <InputText
            value={localValue[index] || ''}
            onChange={(e) => handleInputValueChange(e.target.value, index)}
            onBlur={() => handleInputValueBlur()}
            className={`w-full ${isValidate && condition?.type && !localValue[index] ? 'p-invalid' : ''}`}
            disabled={disabled}
            maxLength={MAX_LENGTH_SHORT_ANSWER}
          />
          <span className="text-heavy-blue-80 text-label-input-reg px-8px">-</span>
          <InputText
            value={localValue[index + 1] || ''}
            onChange={(e) => handleInputValueChange(e.target.value, index + 1)}
            onBlur={() => handleInputValueBlur()}
            className={`w-full ${isValidate && condition?.type && !localValue[index + 1] ? 'p-invalid' : ''}`}
            disabled={disabled}
            maxLength={MAX_LENGTH_SHORT_ANSWER}
          />
        </>
      ) : undefined;
    }

    return (
      <InputText
        value={localValue[index] || ''}
        onChange={(e) => handleInputValueChange(e.target.value, index)}
        onBlur={() => handleInputValueBlur()}
        className={`w-full ${
          isValidate && !!(isAttachment ? condition?.attachmentType : condition?.type) && !localValue[index]
            ? 'p-invalid'
            : ''
        }`}
        disabled={(isAttachment ? !condition?.attachmentType : !condition?.type) || isReadOnly}
        maxLength={MAX_LENGTH_SHORT_ANSWER}
      />
    );
  };

  const getFileSizeAnswerInput = () => {
    const disabled = !condition?.attachmentType || isReadOnly;
    return (
      <>
        <InputText
          value={condition?.attachmentSize?.value || ''}
          onChange={(e) => handleFileSizeTypeChange({ value: e.target.value })}
          className={`w-full ${
            isValidate && condition?.attachmentType && !condition?.attachmentSize?.value ? 'p-invalid' : ''
          }`}
          disabled={disabled}
          maxLength={MAX_LENGTH_NAME}
          keyfilter="num"
        />
        <Dropdown
          options={CONDTION_ATTACHMENT_SIZE_OPTIONS}
          value={condition?.attachmentSize?.type}
          onChange={(e) => handleFileSizeTypeChange({ type: e.target.value })}
          className={`ml-16px w-80px flex-shrink-0 ${
            isValidate && condition?.attachmentType && !condition?.attachmentSize?.type ? 'p-invalid' : ''
          }`}
          disabled={disabled}
        />
      </>
    );
  };

  return (
    <>
      <div className="bg-heavy-20 h-1px my-24px -mx-32px px-32px" />
      <div className="flex flex-column align-items-center w-fit">
        <div className="py-4px px-12px border-radius-4px bg-heavy-10 text-label-s-med -text-heavy-100">{labels.if}</div>
        <div className="bg-heavy-10 w-1px h-18px" />
      </div>
      <div className="border-1 border-radius-4px border-heavy-10 w-full p-16px">
        <div className="text-title-xs-med text-heavy-100">
          {labels.condition} {getConditionIndex()}
        </div>
        <Dropdown
          options={conditionOptions}
          value={condition?.conditionField || ''}
          onChange={(e) => handleSelectConditionField(e.target.value)}
          className={`w-full mt-8px mb-16px ${isValidate && !condition?.conditionField ? 'p-invalid' : ''}`}
          disabled={isReadOnly}
        />
        <div className="flex justify-content-between gap-20px">
          <Dropdown
            disabled={!condition?.conditionField || isReadOnly}
            options={isAttachment ? CONDTION_ATTACHMENT_TYPE_OPTIONS : conditionTypeOptions}
            value={isAttachment ? condition?.attachmentType : condition?.type}
            onChange={(e) =>
              isAttachment ? handleAttachmentTypeChange(e.target.value) : handleTypeChange(e.target.value)
            }
            className={`w-192px align-self-start flex-shrink-0 ${
              isValidate && condition?.conditionField && !(isAttachment ? condition?.attachmentType : condition?.type)
                ? 'p-invalid'
                : ''
            }`}
            pt={{ wrapper: { style: { maxHeight: 'fit-content' } } }}
          />

          <div className="flex flex-column w-full">
            {condition?.value?.map((value, index) => (
              <div
                key={`${value}_${index}`}
                className={`flex-center ${(isRange && index % 2 === 0) || !isRange ? 'mb-16px' : ''}`}
              >
                {isFileSize ? getFileSizeAnswerInput() : getAnswerFieldsInput(value || '', index)}
                {index !== 0 && (!isRange || index % 2 === 0) && !isReadOnly && (
                  <div className="action-button-sm ml-16px" onClick={() => handleRemoveValue(index)}>
                    <TrashIcon className="icon-16px" />
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
        {!isFileSize && !isReadOnly && (
          <div className="flex flex-column ml-auto" style={{ width: 'calc(100% - 210px)' }}>
            <Button className="button-add flex-center flex-shrink-0 mb-4px text-label-s-reg" onClick={handleAddValue}>
              <PlusIcon width={14} height={14} className="mr-4px" />
              {labels.or}
            </Button>
          </div>
        )}
      </div>
    </>
  );
};
