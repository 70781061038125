import { CSSProperties, PropsWithChildren, ReactNode } from 'react';
import { Map, MapProps } from '@vis.gl/react-google-maps';
import { US_CENTER_COORDINATES } from '../../../../types/constants';

const GOOGLE_MAP_ID = process.env.REACT_APP_GOOGLE_MAP_ID || '';

type GoogleMapProps = PropsWithChildren<MapProps> & {
  advancedMarkers?: ReactNode;
  style?: CSSProperties;
};
export const GoogleMap = ({ style, advancedMarkers, ...props }: GoogleMapProps) => {
  return (
    <Map
      style={style}
      mapId={GOOGLE_MAP_ID}
      defaultZoom={4}
      defaultCenter={US_CENTER_COORDINATES}
      gestureHandling={'greedy'}
      {...props}
    >
      {advancedMarkers}
      {props.children}
    </Map>
  );
};
