import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RadioButton } from 'primereact/radiobutton';
import { Dropdown } from 'primereact/dropdown';
import { InputSwitch } from 'primereact/inputswitch';
import { PickList, PickListChangeEvent } from 'primereact/picklist';
import { TimeFormat, TimeZoneType } from '../../../../API';
import { bookingPageSelectors, bookingPagesActions, TimeZoneList } from '../../../../store/bookingPages';
import { bookingTemplatesActions, bookingTemplatesSelectors } from '../../../../store/bookingTemplates';
import { userSettingsSelectors } from '../../../../store/userSettings';
import labels from './labels';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { ScrollPanel } from 'primereact/scrollpanel';
import { SumoTooltip } from '../../sumoTooltip/SumoTooltip';

type CalendarModalProps = {
  visible: boolean;
  selectors: typeof bookingTemplatesSelectors | typeof bookingPageSelectors;
  actions: typeof bookingTemplatesActions | typeof bookingPagesActions;
  isReadOnly?: boolean;
  onClose: () => void;
};

export const CalendarModal = ({ visible, selectors, actions, isReadOnly, onClose }: CalendarModalProps) => {
  const dispatch = useDispatch();
  const calendar = useSelector(selectors.selectCalendar);
  const timeFormat = useSelector(selectors.selectTimeFormat);
  const timeInterval = useSelector(selectors.selectTimeInterval);
  const topOfInterval = useSelector(selectors.selectTopOfInterval);
  const timeZoneType = useSelector(selectors.selectTimeZoneType);
  const defaultTimeZone = useSelector(selectors.selectDefaultTimeZone);
  const availableTimeZones = useSelector(selectors.selectAvailableTimeZonesOptions);
  const assignedTimeZones = useSelector(selectors.selectAssignedTimeZonesOptions);
  const isTimeZonesValid = useSelector(selectors.selectIsTimeZonesValid);
  const userTimeZone = useSelector(userSettingsSelectors.selectTimeZone);
  const isCalendarStepValid = useSelector(bookingPageSelectors.selectIsCalendarStepValid);

  const [initialCalendar] = useState(calendar);

  useEffect(() => {
    // when defaultTimeZone is null or when we revert to automatic TimeZone detection
    if (timeZoneType === TimeZoneType.DETECT_INVITEE || (!defaultTimeZone && userTimeZone)) {
      dispatch(actions.updateCalendarStep({ defaultTimeZone: userTimeZone }));
    }
    // when we get to the list of selected time zones and the list is not empty, select the first one
    if (timeZoneType === TimeZoneType.DISPLAY_SELECTED && !!assignedTimeZones.length) {
      dispatch(actions.updateCalendarStep({ defaultTimeZone: assignedTimeZones[0].timeZone }));
    }
  }, [userTimeZone, defaultTimeZone, timeZoneType]);

  const handleTimeFormatChange = (value: TimeFormat) => {
    dispatch(actions.updateCalendarStep({ timeFormat: value }));
  };

  const handleTimeIntervalChange = (value: number) => {
    dispatch(actions.updateCalendarStep({ timeInterval: value }));
  };

  const handleTopOfIntervalChange = (value: boolean) => {
    dispatch(actions.updateCalendarStep({ topOfInterval: value }));
  };

  const handleTimeZoneTypeChange = (value: TimeZoneType) => {
    dispatch(actions.updateCalendarStep({ timeZoneType: value }));
  };

  const handleTimeZoneChange = (e: PickListChangeEvent) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const selectedTimeZones = e.target.map((zone: any) => zone.value); // TODO: replace any
    dispatch(actions.selectTimeZones(selectedTimeZones));
  };

  const handleDefaultTimeZoneChange = (value: string) => {
    dispatch(actions.updateCalendarStep({ defaultTimeZone: value }));
  };

  const timeZoneItemTemplate = (item: TimeZoneList) => {
    return (
      <div className="flex justify-content-between">
        <div>
          {item.utcOffsetStr} - {item.city}
        </div>
        <div>{item.time}</div>
      </div>
    );
  };

  const handleCancel = () => {
    dispatch(actions.updateCalendarStep({ ...initialCalendar }));
    onClose();
  };

  const generateTimeFormatCheckbox = (value: TimeFormat) => (
    <div className="flex-left-center gap-10px">
      <RadioButton
        inputId={`format-${value}`}
        checked={timeFormat === value}
        onChange={() => handleTimeFormatChange(value)}
        disabled={isReadOnly}
      />
      <div className="flex gap-6px text-body-lg-reg">
        <label htmlFor={`format-${value}`} className="text-heavy-100 cursor-pointer hover-text-heavy-80">
          {value === TimeFormat.HOUR_12 ? labels.hour12 : labels.hour24}
        </label>
        <div className="text-heavy-60">
          {value === TimeFormat.HOUR_12 ? labels.hour12Example : labels.hour24Example}
        </div>
      </div>
    </div>
  );

  const generateTimeZoneCheckbox = (value: TimeZoneType) => (
    <div className="flex-left-center gap-10px">
      <RadioButton
        inputId={`timeZone-${value}`}
        checked={timeZoneType === value}
        onChange={() => handleTimeZoneTypeChange(value)}
        disabled={isReadOnly}
      />
      <label
        htmlFor={`timeZone-${value}`}
        className="text-body-lg-reg text-heavy-100 cursor-pointer hover-text-heavy-80"
      >
        {value === TimeZoneType.DETECT_INVITEE ? labels.timeZoneDetect : labels.timeZoneDisplay}
      </label>
    </div>
  );

  const generateTimeFormatSection = () => (
    <div className="flex flex-column gap-12px">
      <div className="text-title-xs-med text-heavy-100">{labels.timeFormat}</div>
      <div className="flex gap-32px">
        {generateTimeFormatCheckbox(TimeFormat.HOUR_12)}
        {generateTimeFormatCheckbox(TimeFormat.HOUR_24)}
      </div>
    </div>
  );

  const generateTimeIntervalsSection = () => (
    <div className="flex flex-column gap-24px">
      <div className="flex flex-column">
        <div className="flex-left-center gap-6px">
          <div className="text-title-xs-med text-heavy-100">{labels.startTimeIntervals}</div>
          <SumoTooltip text={labels.startTimeIntervalsTooltip} />
        </div>
        <div className="text-body-s-reg text-heavy-60 mt-4px mb-10px">{labels.startTimeIntervalsDescription}</div>
        <div>
          <Dropdown
            className="min-w-150px"
            value={timeInterval}
            onChange={(e) => handleTimeIntervalChange(e.target.value)}
            options={[
              { value: 15, label: labels.intervals15Min },
              { value: 20, label: labels.intervals20Min },
              { value: 30, label: labels.intervals30Min },
              { value: 60, label: labels.intervals60Min },
            ]}
            disabled={isReadOnly}
          />
        </div>
      </div>
      <div className="flex gap-10px">
        <InputSwitch
          checked={!!topOfInterval}
          onChange={(e) => handleTopOfIntervalChange(!!e.value)}
          disabled={isReadOnly}
        />
        <div className="flex flex-column gap-4px pt-4px">
          <div className="flex-left-center gap-6px">
            <div className="text-title-xs-med text-heavy-100">{labels.topOfTheInterval}</div>
            <SumoTooltip text={labels.topOfTheIntervalTooltip} />
          </div>
          <div className="text-body-s-reg text-heavy-60">{labels.topOfTheIntervalDescription}</div>
        </div>
      </div>
    </div>
  );

  const generateTimeZonesSection = () => (
    <div className="flex flex-column gap-12px">
      <div className="flex-left-center gap-6px">
        <div className="text-title-xs-med text-heavy-100">{labels.timeZones}</div>
        <SumoTooltip text={labels.timeZonesTooltip} />
      </div>
      {generateTimeZoneCheckbox(TimeZoneType.DETECT_INVITEE)}
      {generateTimeZoneCheckbox(TimeZoneType.DISPLAY_SELECTED)}
      {timeZoneType === TimeZoneType.DISPLAY_SELECTED && (
        <PickList
          className={`mt-8px ${isTimeZonesValid ? '' : 'p-invalid'}`}
          sourceStyle={{ height: '200px', borderRadius: '8px', padding: 0 }}
          targetStyle={{ height: '200px', borderRadius: '8px', padding: 0 }}
          showSourceControls={false}
          showTargetControls={false}
          source={availableTimeZones}
          target={assignedTimeZones}
          onChange={handleTimeZoneChange}
          itemTemplate={timeZoneItemTemplate}
        ></PickList>
      )}
    </div>
  );

  const generateDefaultTimeZoneSection = () => (
    <div className="flex flex-column gap-10px">
      <div className="text-title-xs-med text-heavy-100">{labels.defaultTimeZone}</div>
      <div className="w-6 pr-38px p-fluid">
        <Dropdown
          value={defaultTimeZone}
          onChange={(e) => handleDefaultTimeZoneChange(e.target.value)}
          options={timeZoneType === TimeZoneType.DETECT_INVITEE ? availableTimeZones : assignedTimeZones}
          itemTemplate={timeZoneItemTemplate}
          valueTemplate={(item) => (
            <div>
              {item?.city} - {item?.time}
            </div>
          )}
          optionLabel="city"
          disabled={isReadOnly}
          emptyMessage={labels.noResults}
          filter
          filterBy="city"
        />
      </div>
    </div>
  );

  return (
    <Dialog
      visible={visible}
      className="w-900px max-w-900px h-680px max-h-screen overflow-hidden"
      contentClassName="p-0 overflow-y-hidden"
      onHide={handleCancel}
      showHeader={false}
      resizable={false}
      focusOnShow={false}
    >
      <div className="flex flex-column h-full">
        <div className="flex-between-center py-14px pl-20px pr-10px border-bottom-1 border-heavy-20">
          <div className="text-title-lg-med ">{labels.title}</div>
          <div className="action-button" onClick={handleCancel}>
            <XMarkIcon className="icon-20px" />
          </div>
        </div>

        <div className="flex-1 overflow-y-hidden">
          <ScrollPanel>
            <div className="flex flex-column gap-32px p-20px">
              {generateTimeFormatSection()}
              <div className="border-top-1 border-heavy-20"></div>
              {generateTimeIntervalsSection()}
              <div className="border-top-1 border-heavy-20"></div>
              {generateTimeZonesSection()}
              {generateDefaultTimeZoneSection()}
            </div>
          </ScrollPanel>
        </div>

        <div className="flex-left-center gap-6px py-12px px-20px border-top-1 border-heavy-20">
          {!isReadOnly && (
            <Button
              className="button-xl min-w-120px"
              label={labels.save}
              onClick={onClose}
              disabled={!isCalendarStepValid}
            />
          )}
          <Button className="button-xl" text label={labels.cancel} onClick={handleCancel} />
        </div>
      </div>
    </Dialog>
  );
};
