export const Journey = Object.freeze({
  TITLE: `Constructor de viajes`,
  CLONE_TITLE: `Ingrese el nombre del viaje clonado`,
  ADD_LABEL: `Crear viaje`,
  CREATED_BY: `Creado por`,
  EMPTY_LIST_DESCRIPTION: `Cree un viaje o ajuste su búsqueda o filtros.`,
  RESPONSE: `respuesta`,
  RESPONSES: `respuestas`,
  LAST: `Último`,
  DEACTIVATE_TITLE: `Desactivar viaje`,
  DEACTIVATE_DESCRIPTION: `¿Está seguro de que desea desactivar`,
  DELETE_TITLE: `Eliminar viaje`,
  DELETE_DESCRIPTION: `¿Está seguro de que desea eliminar`,
  SELECTED_JOURNEY: `viajes seleccionados`,
  NAME_LABEL: `Nombre`,
});

export const EditJourney = Object.freeze({
  JOURNEY_TITLE: `Viaje`,
  RESPONSES_TITLE: `Respuestas`,
  DELETE_STEP_TITLE: `Eliminar paso`,
  DELETE_STEP_DESCRIPTION: `¿Está seguro de que desea eliminar este paso?`,
  DELETE_STEP_ADDITIONAL_TEXT: `Tenga en cuenta que esta acción también eliminará permanentemente todos los pasos secundarios posteriores vinculados a él.`,
  START_NODE_TITLE: `Tu viaje comienza aquí`,
  CONDITION_TITLE: `Condición`,
  DESTINATION_TITLE: `Destino`,
  PAGE_TITLE: `Página`,
  WITH_CARDS: `con tarjetas`,
  WITH_FORM: `con formulario`,
  IF_LABEL: `Si`,
  OR_LABEL: `O`,
  ADD_LABEL: `Añadir`,
  CUSTOM_NAME: `Nombre personalizado`,
  CREATE_JOURNEY: `Crear viaje`,
  EDIT_JOURNEY: `Editar viaje`,
  CARD: `Tarjeta`,
  LOGIC: `Lógica`,
  PREVIEW_JOURNEY: `Vista previa del viaje`,
});

export const JourneysPageStep = Object.freeze({
  SELECTION_BOX: `Caja de selección`,
  CARD_TITLE: `Título aquí*`,
  CARD_DETAILS: `Detalles aquí*`,
  FORM_INPUT_TILE: `Título de entrada`,
  FORM_INPUT_SUBTITLE: `Subtítulo de entrada`,
  FORM_WRITE_QUESTION: `Escribir una pregunta`,
  FORM_INPUT_REQUIRED: `Esta entrada es obligatoria`,
  FORM_QUESTION_REQUIRED: `Esta pregunta es obligatoria`,
  FORM_ANSWER: `Respuesta`,
  FORM_ADD_ANSWER: `+ Añadir una respuesta`,
  TITLE: `Título`,
  TITLE_PLACEHOLDER: `Introduzca el título de la página`,
  NAME: `Nombre`,
  PAGE_NAME: `Nombre de la página`,
  PAGE_TYPE: `Tipo de página`,
  NO_ELEMENTS: `No se han añadido elementos`,
  INPUTS: `Entradas`,
  SELECTION: `Selección`,
  SELECT_TYPE: `Seleccione el tipo primero`,
});

export const JourneysDestinationStep = Object.freeze({
  TYPE_LABEL: `Tipo de destino`,
  SHOWS_STEP_SEPARATLY: `Mostrar pasos por separado`,
  WHO_DESCRIPTION: `Lista de especialistas para seleccionar`,
  WHEN_DESCRIPTION: `Calendario y horario`,
  WHERE_DESCRIPTION: `Mapa con oficinas para seleccionar`,
  HEADER_LABEL: `Encabezado`,
  MESSAGE_LABEL: `Mensaje`,
  URL_LABEL: `URL externa`,
});

export const JourneyDefault = Object.freeze({
  JOURNEY_NAME: `Viaje personalizado`,
  PAGE_NAME: `Personalizado`,
  SUBMIT_LABEL: `Enviar`,
  FORM: `Formulario`,
  CARDS: `Tarjetas`,
  IMPORT: `Importar`,
  TITLE: `Título`,
  SUBTITLE: `Subtítulo`,
  NAME: `Nombre`,
  SINGLE: `Línea única`,
  EMAIL: `Correo electrónico`,
  MULTIPLE: `Varias líneas`,
  PHONE: `Número de teléfono`,
  ATTACHMENT: `Adjunto`,
  DROPDOWN: `Desplegable`,
  RADIO_BUTTONS: `Casillas de verificación`,
  IS: `es`,
  IS_NOT: `no es`,
  CONTAINS: `contiene`,
  DOES_NOT_CONTAIN: `no contiene`,
  STARTS_WITH: `comienza con`,
  IS_NOT_EMPTY: `no está vacío`,
  IS_EMPTY: `está vacío`,
  RANGE: `es rango`,
  FILETYPE_IS: `el tipo de archivo es`,
  FILETYPE_IS_NOT: `el tipo de archivo no es`,
  SIZE_IS_BIGGER_THAN: `el tamaño es mayor que`,
  SIZE_IS_SMALLER_THAN: `el tamaño es menor que`,
  BOOKING_PAGE: `Página de reserva`,
  MESSAGE: `Mensaje`,
  EXTERNAL_URL: `URL externa`,
  CHAT_AI: `Chat IA`,
});

export const JourneysToastsNotifications = Object.freeze({
  GET_JOURNEYS_ERROR_MESSAGE: `Fallo en la solicitud de obtención de viajes`,
  DEACTIVATE_JOURNEY_SUCCESS_MESSAGE: `Viaje desactivado con éxito`,
  ACTIVATE_JOURNEY_SUCCESS_MESSAGE: `Viaje activado con éxito`,
  DEACTIVATE_JOURNEY_ERROR_MESSAGE: `Fallo en la solicitud de activación/desactivación del viaje`,
  CLONE_JOURNEY_SUCCESS_MESSAGE: `Viaje clonado con éxito`,
  CLONE_JOURNEY_ERROR_MESSAGE: `Fallo en la solicitud de clonación del viaje`,
  DELETE_JOURNEYS_SUCCESS_MESSAGE: `Viajes eliminados con éxito`,
  DELETE_JOURNEYS_ERROR_MESSAGE: `Fallo en la solicitud de eliminación de viajes`,
  GET_JOURNEY_ERROR_MESSAGE: `Fallo en la solicitud de obtención del viaje`,
  SAVE_JOURNEY_ERROR_MESSAGE: `Fallo en la solicitud de guardado del viaje`,
  CREATE_JOURNEY_SUCCESS_MESSAGE: `Viaje creado con éxito`,
  UPDATE_JOURNEY_SUCCESS_MESSAGE: `Viaje actualizado con éxito`,
  GET_JOURNEY_EVENTS_ERROR_MESSAGE: `Error en la solicitud de eventos del viaje`,
});

export const JourneysEvent = Object.freeze({
  VIEW_EVENT: `Ver evento`,
  TITLE: `Respuestas`,
  NO_RESPONSES: `Aún no hay respuestas`,
  EMPTY_LIST_DESCRIPTION: `Ajuste su búsqueda o filtros.`,
  DATE_RANGE: `Rango de fechas`,
  RESPONSE: `Respuesta`,
  EVENT_SCHEDULED: `Evento programado`,
  CREATED_AT: `Creado en`,
  RESULT: `Resultado`,
  ANSWERS: `Respuestas`,
  DESTINATION: `Destino`,
});
