import { CreateUserDataInput, DeleteUserDataInput } from '../../API';
import { API_PUBLIC } from '../../types/constants';
import { UserDataInputCreatedAt } from '../global';
import { handleAPIRequest } from '../utils/reduxUtils';

// ***
// When you change the requests do not forget to specify new data
// in docs/Endpoints.md
// ***

// getAvailability we request through getUserDataById

export const upsertAvailability = async (input: CreateUserDataInput | UserDataInputCreatedAt): Promise<void> => {
  return handleAPIRequest<void>(`${API_PUBLIC}/upsertAvailability`, { data: input });
};

export const deleteAvailability = async (input: DeleteUserDataInput): Promise<void> => {
  return handleAPIRequest<void>(`${API_PUBLIC}/deleteAvailability`, { data: input });
};
