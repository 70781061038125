import { t } from '../../../../i18n/i18n';

export default {
  save: t('Common:SAVE'),
  cancel: t('Common:CANCEL'),
  noResults: t('Common:NO_RESULTS_FOUND'),
  title: t('BookingPageCalendarModal:TITLE'),
  timeFormat: t('BookingPageCalendarModal:TIME_FORMAT'),
  hour12: t('BookingPageCalendarModal:HOUR_12'),
  hour12Example: t('BookingPageCalendarModal:HOUR_12_EXAMPLE'),
  hour24: t('BookingPageCalendarModal:HOUR_24'),
  hour24Example: t('BookingPageCalendarModal:HOUR_24_EXAMPLE'),
  startTimeIntervals: t('BookingPageCalendarModal:START_TIME_INTERVALS'),
  startTimeIntervalsDescription: t('BookingPageCalendarModal:START_TIME_INTERVALS_DESCRIPTION'),
  startTimeIntervalsTooltip: t('BookingPageCalendarModal:START_TIME_INTERVALS_TOOLTIP'),
  intervals15Min: t('BookingPageCalendarModal:INTERVALS_15_MIN'),
  intervals20Min: t('BookingPageCalendarModal:INTERVALS_20_MIN'),
  intervals30Min: t('BookingPageCalendarModal:INTERVALS_30_MIN'),
  intervals60Min: t('BookingPageCalendarModal:INTERVALS_60_MIN'),
  topOfTheInterval: t('BookingPageCalendarModal:TOP_OF_THE_INTERVAL'),
  topOfTheIntervalDescription: t('BookingPageCalendarModal:TOP_OF_THE_INTERVAL_DESCRIPTION'),
  topOfTheIntervalTooltip: t('BookingPageCalendarModal:TOP_OF_THE_INTERVAL_TOOLTIP'),
  timeZones: t('BookingPageCalendarModal:TIME_ZONES'),
  timeZonesTooltip: t('BookingPageCalendarModal:TIME_ZONES_TOOLTIP'),
  timeZoneDetect: t('BookingPageCalendarModal:TIME_ZONE_DETECT'),
  timeZoneDisplay: t('BookingPageCalendarModal:TIME_ZONE_DISPLAY'),
  defaultTimeZone: t('BookingPageCalendarModal:DEFAULT_TIME_ZONE'),
};
