import { t } from '../../../i18n/i18n';

export default {
  account: t('PrivateRoute:ACCOUNT'),
  accountSettings: t('PrivateRoute:ACCOUNT_SETTINGS'),
  accountsTitle: t('AppMenu:ACCOUNTS_TITLE'),
  availability: t('PrivateRoute:AVAILABILITY'),
  billingTitle: t('Billing:TITLE'),
  bookedMeetingsTitle: t('BookedMeetings:TITLE'),
  bookingPagesTitle: t('BookingPages:TITLE'),
  bookingTemplatesTitle: t('BookingTemplates:TITLE'),
  calendarConnections: t('PrivateRoute:CALENDAR_CONNECTIONS'),
  helpCenter: t('PrivateRoute:HELP_CENTER'),
  integration: t('PrivateRoute:INTEGRATION'),
  integrationPageTitle: t('IntegrationPage:TITLE'),
  internal: t('Common:INTERNAL'),
  journeyBuilder: t('AppMenu:JOURNEY_TITLE'),
  logOut: t('Common:LOG_OUT'),
  openMySumo1: t('AppMenu:OPEN_MY_SUMO1'),
  opsConsole: t('AppMenu:OPS_CONSOLE'),
  operationsTitle: t('AppMenu:OPERATIONS_TITLE'),
  orgsTitle: t('AppMenu:ORGS_TITLE'),
  profile: t('PrivateRoute:PROFILE'),
  roles: t('Common:ROLES'),
  smartAlerts: t('AppMenu:SMART_ALERTS'),
  staffTitle: t('AppMenu:STAFF_TITLE'),
  supportTitle: t('AppMenu:SUPPORT_TITLE'),
  teamsTitle: t('Teams:TITLE'),
  toolsTitle: t('AppMenu:TOOLS_TITLE'),
  meetingsTitle: t('AppMenu:MEETINGS_TITLE'),
  peopleTitle: t('AppMenu:PEOPLE_TITLE'),
  organizationTitle: t('AppMenu:ORGANIZATION_TITLE'),
  userSettings: t('PrivateRoute:USER_SETTINGS'),
  userSetup: t('PrivateRoute:USER_SETUP'),
  users: t('Common:USERS'),
  workspacesTitle: t('Workspaces:TITLE'),
  trialMessage: t('License:TRIAL_EXPIRES_MESSAGE'),
  buyLicenses: t('License:BUY_LICENSES'),
  myBillings: t('License:MY_BILLINGS'),
  expired: t('LicenseExpired:EXPIRED'),
  trialIsOver: t('LicenseExpired:TRIAL_IS_OVER'),
  locationsTitle: t('Locations:TITLE'),
};
