import { t } from '../../i18n/i18n';

export default {
  journey: t('EditJourney:JOURNEY_TITLE'),
  responses: t('EditJourney:RESPONSES_TITLE'),
  name: t('JourneysPageStep:NAME'),
  namePlaceholder: t('EditJourney:CUSTOM_NAME'),
  active: t('Common:ACTIVE'),
  createJourney: t('EditJourney:CREATE_JOURNEY'),
  editJourney: t('EditJourney:EDIT_JOURNEY'),
};
