import { combineReducers } from 'redux';
import { UserAvailabilityActionTypes, UserAvailabilityAction } from './actions';
import { DEFAULT_AVAILABILITY } from './constants';
import { GlobalAction, GlobalActionTypes } from '../global/actions';
import { UserDataInputCreatedAt } from '../global/types';

// TODO: rename to availabilities
const userAvailabilities = (state: UserDataInputCreatedAt[] = [], action: UserAvailabilityAction | GlobalAction) => {
  switch (action.type) {
    case UserAvailabilityActionTypes.GET_USER_AVAILABILITY_SUCCESS:
      return action.payload;
    case GlobalActionTypes.RESET_WHOLE_STORE:
      return [];
    default:
      return state;
  }
};

// TODO: rename to availability
const userAvailability = (
  state: UserDataInputCreatedAt = DEFAULT_AVAILABILITY,
  action: UserAvailabilityAction | GlobalAction
) => {
  switch (action.type) {
    case UserAvailabilityActionTypes.GET_USER_AVAILABILITY_SUCCESS:
      if (state !== DEFAULT_AVAILABILITY) {
        // leave the selected availability when we update it
        return (
          action.payload.find(
            (availability) => availability?.availabilityData?.name === state.availabilityData?.name
          ) || state
        );
      } else {
        return action.payload.find((availability) => availability?.availabilityData?.isDefault) || action.payload[0];
      }
    case UserAvailabilityActionTypes.CREATE_USER_AVAILABILITY_SUCCESS:
      return action.payload;
    case UserAvailabilityActionTypes.CLONE_USER_AVAILABILITY_SUCCESS:
      return action.payload;
    case UserAvailabilityActionTypes.SET_USER_AVAILABILITY:
      return action.payload;
    case UserAvailabilityActionTypes.UPDATE_USER_AVAILABILITY:
      return {
        ...state,
        availabilityData: { ...state.availabilityData, ...action.payload },
      } as UserDataInputCreatedAt;
    case GlobalActionTypes.RESET_WHOLE_STORE:
      return DEFAULT_AVAILABILITY;
    default:
      return state;
  }
};

const cloneName = (state = '' as string, action: UserAvailabilityAction | GlobalAction) => {
  switch (action.type) {
    case UserAvailabilityActionTypes.SET_CLONE_NAME:
      return action.payload;
    case GlobalActionTypes.RESET_WHOLE_STORE:
      return '';
    default:
      return state;
  }
};

const isFetching = (state = false, action: UserAvailabilityAction | GlobalAction) => {
  switch (action.type) {
    case UserAvailabilityActionTypes.GET_USER_AVAILABILITY_REQUEST:
    case UserAvailabilityActionTypes.SAVE_USER_AVAILABILITY_REQUEST:
    case UserAvailabilityActionTypes.CREATE_USER_AVAILABILITY_REQUEST:
    case UserAvailabilityActionTypes.DELETE_USER_AVAILABILITY_REQUEST:
    case UserAvailabilityActionTypes.SET_DEFAULT_AVAILABILITY_REQUEST:
    case UserAvailabilityActionTypes.CLONE_USER_AVAILABILITY_REQUEST:
      return true;
    case UserAvailabilityActionTypes.GET_USER_AVAILABILITY_SUCCESS:
    case UserAvailabilityActionTypes.GET_USER_AVAILABILITY_FAIL:
    case UserAvailabilityActionTypes.SAVE_USER_AVAILABILITY_SUCCESS:
    case UserAvailabilityActionTypes.SAVE_USER_AVAILABILITY_FAIL:
    case UserAvailabilityActionTypes.CREATE_USER_AVAILABILITY_SUCCESS:
    case UserAvailabilityActionTypes.CREATE_USER_AVAILABILITY_FAIL:
    case UserAvailabilityActionTypes.DELETE_USER_AVAILABILITY_SUCCESS:
    case UserAvailabilityActionTypes.DELETE_USER_AVAILABILITY_FAIL:
    case UserAvailabilityActionTypes.SET_DEFAULT_AVAILABILITY_FAIL:
    case UserAvailabilityActionTypes.CLONE_USER_AVAILABILITY_SUCCESS:
    case UserAvailabilityActionTypes.CLONE_USER_AVAILABILITY_FAIL:
      return false;
    case GlobalActionTypes.RESET_WHOLE_STORE:
      return false;
    default:
      return state;
  }
};
const error = (state: unknown | null = null, action: UserAvailabilityAction | GlobalAction) => {
  switch (action.type) {
    case UserAvailabilityActionTypes.GET_USER_AVAILABILITY_REQUEST:
    case UserAvailabilityActionTypes.SAVE_USER_AVAILABILITY_REQUEST:
    case UserAvailabilityActionTypes.CREATE_USER_AVAILABILITY_REQUEST:
    case UserAvailabilityActionTypes.DELETE_USER_AVAILABILITY_REQUEST:
    case UserAvailabilityActionTypes.SET_DEFAULT_AVAILABILITY_REQUEST:
    case UserAvailabilityActionTypes.CLONE_USER_AVAILABILITY_REQUEST:
      return null;
    case UserAvailabilityActionTypes.GET_USER_AVAILABILITY_FAIL:
    case UserAvailabilityActionTypes.SAVE_USER_AVAILABILITY_FAIL:
    case UserAvailabilityActionTypes.CREATE_USER_AVAILABILITY_FAIL:
    case UserAvailabilityActionTypes.SET_DEFAULT_AVAILABILITY_FAIL:
    case UserAvailabilityActionTypes.CLONE_USER_AVAILABILITY_FAIL:
      return action.error;
    case GlobalActionTypes.RESET_WHOLE_STORE:
      return null;
    default:
      return state;
  }
};

export default combineReducers({
  userAvailabilities,
  userAvailability,
  cloneName,
  isFetching,
  error,
});
