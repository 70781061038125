import React, { useState } from 'react';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { WorkspaceIntegrationType } from '../../../API';
import { integrationIcon, integrationLabels } from '../../../store/integration';
import labels from './labels';

export type ConnectFormIntegrationSaveResponse = Record<string, {value: string}>

type IConnectFormIntegrationProps = {
  integrationType: WorkspaceIntegrationType;
  IntegrationIcon?: React.ReactElement;
  handleSave: (formData: ConnectFormIntegrationSaveResponse) => void;
  handleCancel: () => void;
  contentForm: {
    type: 'input'
    uniqueName: string
    placeholder: string
    visibleName: string
    value?: string
  }[]
}

export const ConnectFormIntegration: React.FC<IConnectFormIntegrationProps> = ({
  integrationType, IntegrationIcon, handleSave, handleCancel, contentForm
}) => {
  const initialFormDataState: {[uniqueName: string]: string} = {}
  contentForm.forEach(item => {
    initialFormDataState[item.uniqueName] = item.value || ''
  })

  const [formData, setFormData] = useState<typeof initialFormDataState>(initialFormDataState)
  const [isFailedValidationForm, setFailedValidationForm] = useState<boolean>(false)

  const setNewValueFormData = (uniqueName: string, value: string) => {
    setFormData(prevState => ({
      ...prevState,
      [uniqueName]: value
    }))
  }

  const handlerSave = () => {
    const responseFormData: ConnectFormIntegrationSaveResponse = {}
    let isValidateSuccess = false

    for (const [uniqueName, value] of Object.entries(formData)) {
      const formattedValue = value?.trim()

      if (formattedValue && formattedValue !== '') {
        isValidateSuccess = true
      }

      responseFormData[uniqueName] = {value}
    }

    if (!isValidateSuccess) {
      setFailedValidationForm(true)
      return
    } else {
      setFailedValidationForm(false)
    }

    handleSave(responseFormData)
  }

  return (
    <div className="sumo-card border-none flex gap-16px h-full py-24px px-20px">
      <div className="flex-initial gap-20px">
        {IntegrationIcon || (
          <img src={integrationIcon[integrationType as keyof typeof integrationIcon]} className="w-56px h-56px" />
        )}
      </div>
      <div className="flex-1">
        <div className="text-title-xs-med mb-4px">
          {labels.setUp} {integrationLabels[integrationType as keyof typeof integrationLabels]}
        </div>
        <div className="text-body-s-reg text-heavy-60 mb-2">
          {labels.setUpDesc[integrationType]?.split('\n').map((line, index) => (
            <div key={index}>{line}</div>
          ))}
          <div className="grid p-fluid formgrid mt-4">
            {contentForm.map((item) => {
              return (
                <div key={item.uniqueName} className="col-12 field">
                  <label htmlFor={item.uniqueName} className="text-800 font-semibold">
                    {item.visibleName}
                  </label>
                  <InputText
                    id={item.uniqueName}
                    type="text"
                    aria-required={true}
                    placeholder={item.placeholder}
                    value={formData[item.uniqueName] || ''}
                    onChange={(e) => setNewValueFormData(e.target.id, e.target.value)}
                  />
                </div>
              );
            })}
          </div>
          {isFailedValidationForm && (
            <div className="text-title-xs-med text-orange-500 mb-3">{labels.fillOneField}</div>
          )}
        </div>
        <div>
          <Button label={labels.save} className="button-xl mr-20px" onClick={handlerSave} />
          <Button label={labels.cancel} className="button-xl" hidden outlined onClick={handleCancel} />
        </div>
      </div>
    </div>
  );
}