import { t } from '../../../../../i18n/i18n';

export default {
  durationTitle: t('EditBookingPageDurationStep:DURATION'),
  durationDescription: t('EditBookingPageDurationStep:DURATION_DESCRIPTION'),
  afterCheckboxLabel: t('EditBookingPageDurationStep:AFTER_CHECKBOX_LABEL'),
  beforeCheckboxLabel: t('EditBookingPageDurationStep:BEFORE_CHECKBOX_LABEL'),
  bufferTitle: t('EditBookingPageDurationStep:BUFFER_TIME'),
  bufferTooltip: t('EditBookingPageDurationStep:BUFFER_TIME_TOOLTIP'),
};
