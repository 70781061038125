import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { scheduledMeetingsActions } from '../../store/bookedMeetings/actions';
import { scheduledMeetingsSelectors } from '../../store/bookedMeetings/selectors';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import labels from './labels';
import {
  CancelBookedMeetingModal,
  EditBookedMeetingNotesModal,
  BookedMeetingRescheduleModal,
  BookedFilters,
  BookedMeetingNoShowModal,
  BookedMeeting,
} from '../../components/bookedMeetings';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { bookingTemplatesActions } from '../../store/bookingTemplates';
import { bookingPagesActions } from '../../store/bookingPages';
import { usersActions } from '../../store/users';
import { useSession } from '../../hooks/useSession';
import { CalendarIcon, ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/24/outline';
import { CreateUserEventInput } from '../../API';
import { useSearchParams } from 'react-router-dom';
import { Skeleton } from 'primereact/skeleton';
import { formatBookedMeetingDate, isToday } from '../../services/DateService';
import { EmptyListScreen } from '../../components/common';

dayjs.extend(utc);
dayjs.extend(timezone);

export const BookedMeetings = () => {
  const dispatch = useDispatch();
  const { isInitialVisit } = useSession(); // checking if the app was initialized on current url
  const isSkeletonFetching = useSelector(scheduledMeetingsSelectors.selectIsSkeletonFetching);
  const eventsByDay = useSelector(scheduledMeetingsSelectors.selectEventsByDay);
  const [activeIndexes, setActiveIndexes] = useState<number[]>([]);
  const [initialSet, setInitialSet] = useState(true);

  const [searchParams] = useSearchParams();
  const eventId = searchParams.get('eventId');

  useEffect(() => {
    if (!isInitialVisit) {
      dispatch(bookingPagesActions.getBookingPagesRequest());
      dispatch(bookingTemplatesActions.getBookingTemplatesRequest());
      dispatch(usersActions.getUsersRequest());
    }
    // dispatch(scheduledMeetingsActions.setSortMethod(SortMethods.UPCOMING));
    dispatch(scheduledMeetingsActions.getBookedMeetingsRequest({ eventId: eventId || undefined }));
  }, [isInitialVisit]);

  useEffect(() => {
    if (initialSet) {
      const index = Object.entries(eventsByDay).findIndex(([_, events]) =>
        events.some((event) => event.eventId === eventId)
      );
      if (index >= 0 && !activeIndexes.includes(index)) {
        setInitialSet(false);
        setActiveIndexes([index]);
      }
    }
  }, [eventsByDay]);

  return (
    <>
      <EditBookedMeetingNotesModal />
      <CancelBookedMeetingModal />
      <BookedMeetingRescheduleModal />
      <BookedMeetingNoShowModal />

      <div className="sumo-card-bg flex flex-column pt-16px">
        <BookedFilters />

        {isSkeletonFetching ? (
          <div className="flex flex-column gap-12px">
            <Skeleton className="h-40px border-radius-8px" />
            <Skeleton className="h-40px border-radius-8px" />
          </div>
        ) : Object.keys(eventsByDay).length ? (
          <Accordion
            className="accordion-separate-header"
            multiple
            activeIndex={activeIndexes}
            onTabChange={(e) => setActiveIndexes(e.index as number[])}
          >
            {Object.entries(eventsByDay).map(([day, events], index) => {
              const date = formatBookedMeetingDate(day);
              return (
                <AccordionTab
                  key={index + day}
                  // className="mb-32px"
                  headerClassName={index > 0 ? 'mt-32px' : ''}
                  contentClassName="-mx-20px px-20px"
                  header={
                    <div className="flex-left-center gap-8px pr-20px">
                      <div className="flex bg-heavy-10 border-radius-8px p-8px">
                        <CalendarIcon className="icon-24px" />
                      </div>
                      <div className="flex-1 flex flex-column gap-2px text-title-xs-med">
                        <div className="text-title-s-med text-heavy-100">
                          {isToday(day) ? `${date} - ${labels.today}` : date}
                        </div>
                        <div className="text-label-xs-reg pb-1px">
                          {events.length === 1 ? `1 ${labels.meeting}` : `${events.length} ${labels.meetings}`}
                        </div>
                      </div>
                      <div className="flex-none">
                        {activeIndexes.includes(index) ? (
                          <ChevronUpIcon className="icon-20px" />
                        ) : (
                          <ChevronDownIcon className="icon-20px" />
                        )}
                      </div>
                    </div>
                  }
                >
                  <div className="flex flex-column gap-12px mt-16px">
                    {events.map((event: CreateUserEventInput) => (
                      <BookedMeeting event={event} key={event.eventId} isFocusOn={event.eventId === eventId} />
                    ))}
                  </div>
                </AccordionTab>
              );
            })}
          </Accordion>
        ) : (
          <div className="w-12">
            <EmptyListScreen description={labels.noBookedMeetings} />
          </div>
        )}
      </div>
    </>
  );
};
