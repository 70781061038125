import { t } from '../../../i18n/i18n';

export default {
  signIn: t('Common:LOGIN'),
  signUp: t('Common:SIGN_UP'),
  clickHere: t('Common:CLICK_HERE'),
  signInTitleLine1: t('Authorise:SING_IN_TITLE_LINE_1'),
  signInTitleLine2: t('Authorise:SING_IN_TITLE_LINE_2'),
  signInSubtitle: t('Authorise:SING_IN_SUBTITLE'),
  signInGoogle: t('Authorise:SING_IN_GOOGLE'),
  signInMicrosoft: t('Authorise:SING_IN_MICROSOFT'),
  signInDontHaveAnAccount: t('Authorise:SING_IN_DONT_HAVE_AN_ACCOUNT'),
  signUpComment: t('Authorise:SIGN_UP_COMMENT'),
  signUpTitleLine1: t('Authorise:SING_UP_TITLE_LINE_1'),
  signUpTitleLine2: t('Authorise:SING_UP_TITLE_LINE_2'),
  signUpSubtitle: t('Authorise:SING_UP_SUBTITLE'),
  signUpGoogle: t('Authorise:SING_UP_GOOGLE'),
  signUpMicrosoft: t('Authorise:SING_UP_MICROSOFT'),
  signUpFreeTrial: t('Authorise:SIGN_UP_FREE_TRIAL'),
  signUpHaveAnAccount: t('Authorise:SING_UP_HAVE_AN_ACCOUNT'),
  lookingForSumoCep: t('Authorise:LOOKING_FOR_SUMO_CEP'),
  loadingLabel1: t('Authorise:LOADING_LABEL_1'),
  loadingLabel2: t('Authorise:LOADING_LABEL_2'),
  loadingLabel3: t('Authorise:LOADING_LABEL_3'),
};
