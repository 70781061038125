import { combineReducers } from 'redux';
import { QuickSetupAction, QuickSetupActionTypes } from './actions';
import { WorkspaceInput } from '../../API';
import { GlobalAction, GlobalActionTypes } from '../global/actions';
import { PhoneCallsStep, QuickSetupFiles } from './types';
import { UserDataInputCreatedAt } from '../global/types';
import { ImageType } from '../../types/types';

const userAvailability = (state: UserDataInputCreatedAt | null = null, action: QuickSetupAction | GlobalAction) => {
  switch (action.type) {
    case QuickSetupActionTypes.SET_USER_AVAILABILITY:
    case QuickSetupActionTypes.GET_DEFAULT_DATA_SUCCESS:
      return action.payload;
    case GlobalActionTypes.RESET_WHOLE_STORE:
      return null;
    default:
      return state;
  }
};

const workspace = (state = {} as WorkspaceInput, action: QuickSetupAction | GlobalAction) => {
  switch (action.type) {
    case QuickSetupActionTypes.SET_DEFAULT_WORKSPACE:
      return action.payload;
    case QuickSetupActionTypes.UPDATE_WORKSPACE_STYLE:
      return {
        ...state,
        style: { ...state.style, ...action.payload },
      } as WorkspaceInput;
    case QuickSetupActionTypes.UPDATE_WORKSPACE_LABELS:
      return { ...state, labels: action.payload };
    /*   case QuickSetupActionTypes.UPLOAD_IMAGE_FILE_SUCCESS:
      return {
        ...state,
        style: { ...state.style, [action.payload.imageType]: action.payload.imageLink },
      } as WorkspaceInput; */
    case GlobalActionTypes.RESET_WHOLE_STORE:
      return {} as WorkspaceInput;
    default:
      return state;
  }
};

const isAcceptTerms = (state = false, action: QuickSetupAction | GlobalAction) => {
  switch (action.type) {
    case QuickSetupActionTypes.SET_ACCEPT_TERMS:
      return action.payload;
    case GlobalActionTypes.RESET_WHOLE_STORE:
      return false;
    default:
      return state;
  }
};

const phoneCalls = (state = {} as PhoneCallsStep, action: QuickSetupAction | GlobalAction) => {
  switch (action.type) {
    case QuickSetupActionTypes.UPDATE_PHONE_CALLS:
      return { ...state, ...action.payload };
    case GlobalActionTypes.RESET_WHOLE_STORE:
      return {} as PhoneCallsStep;
    default:
      return state;
  }
};

const imageFiles = (state = {} as QuickSetupFiles, action: QuickSetupAction | GlobalAction) => {
  switch (action.type) {
    case QuickSetupActionTypes.SET_DEFAULT_WORKSPACE:
      return {
        [ImageType.avatar]: {
          url: action.payload.style?.logoImage,
        },
        [ImageType.backgroundImage]: { url: action.payload.style?.backgroundImage },
      } as QuickSetupFiles;
    case QuickSetupActionTypes.UPLOAD_IMAGE_FILE:
      return { ...state, [action.payload.imageType]: { fileName: action.payload.fileName, url: action.payload.url } };
    case QuickSetupActionTypes.CLEAR_IMAGE_FILE:
      return { ...state, [action.payload]: null };
    case GlobalActionTypes.RESET_WHOLE_STORE:
      return {} as QuickSetupFiles;
    default:
      return state;
  }
};

const isFetching = (state = false, action: QuickSetupAction | GlobalAction) => {
  switch (action.type) {
    case QuickSetupActionTypes.SAVE_QUICK_SETUP_REQUEST:
      return true;
    case QuickSetupActionTypes.GET_DEFAULT_DATA_SUCCESS:
    case QuickSetupActionTypes.GET_DEFAULT_DATA_FAIL:
    case QuickSetupActionTypes.SAVE_QUICK_SETUP_SUCCESS:
    case QuickSetupActionTypes.SAVE_QUICK_SETUP_FAIL:
    case GlobalActionTypes.RESET_WHOLE_STORE:
      return false;
    default:
      return state;
  }
};
const error = (state: unknown | null = null, action: QuickSetupAction | GlobalAction) => {
  switch (action.type) {
    case QuickSetupActionTypes.SAVE_QUICK_SETUP_REQUEST:
      return null;
    case QuickSetupActionTypes.GET_DEFAULT_DATA_FAIL:
    case QuickSetupActionTypes.SAVE_QUICK_SETUP_FAIL:
      return action.error;
    case GlobalActionTypes.RESET_WHOLE_STORE:
      return null;
    default:
      return state;
  }
};

export default combineReducers({
  userAvailability,
  workspace,
  isAcceptTerms,
  phoneCalls,
  imageFiles,
  isFetching,
  error,
});
