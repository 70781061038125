import { ModalName, modalsActionsFactory, modalsSelectorsFactory } from '../modals';

export const CLONE_JOURNEYS_MODAL_NAME: ModalName = 'cloneJourneys';
export const cloneJourneysModalActions = modalsActionsFactory(CLONE_JOURNEYS_MODAL_NAME);
export const cloneJourneysModalSelectors = modalsSelectorsFactory(CLONE_JOURNEYS_MODAL_NAME);

export const DELETE_JOURNEYS_MODAL_NAME: ModalName = 'deleteJourneys';
export const deleteJourneysModalActions = modalsActionsFactory(DELETE_JOURNEYS_MODAL_NAME);
export const deleteJourneysModalSelectors = modalsSelectorsFactory(DELETE_JOURNEYS_MODAL_NAME);

export const DEACTIVATE_JOURNEYS_MODAL_NAME: ModalName = 'deactivateJourneys';
export const deactivateJourneysModalActions = modalsActionsFactory(DEACTIVATE_JOURNEYS_MODAL_NAME);
export const deactivateJourneysModalSelectors = modalsSelectorsFactory(DEACTIVATE_JOURNEYS_MODAL_NAME);

export const DELETE_JOURNEY_STEP_MODAL_NAME: ModalName = 'deleteJourneyStep';
export const deleteJourneyStepModalActions = modalsActionsFactory(DELETE_JOURNEY_STEP_MODAL_NAME);
export const deleteJourneyStepModalSelectors = modalsSelectorsFactory(DELETE_JOURNEY_STEP_MODAL_NAME);
