import { NodeProps, Position } from '@xyflow/react';
import { JourneyPlusNode } from '../journeyPlusNode/JourneyPlusNode';
import { JourneyPlusNodeType } from '../../../store/journeyBuilder';
import { useRef } from 'react';
import { Menu } from 'primereact/menu';
import { JourneyHandle } from '../journeyHandle/JourneyHandle';

export const JourneyStartPlusNode = (props: NodeProps<JourneyPlusNodeType>) => {
  const menu = useRef<Menu>(null);

  return (
    <div
      className={`start-plus-node ${props.data.isReadOnly ? 'plus-node-disabled' : ''}`}
      onClick={(e) => (!props.data.isReadOnly ? menu.current?.toggle(e) : undefined)}
    >
      <JourneyHandle type="target" position={Position.Top} />
      <JourneyPlusNode {...props} data={{ ...props.data, ref: menu }} />
    </div>
  );
};
