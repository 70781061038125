import { Dialog } from 'primereact/dialog';
import { useDispatch } from 'react-redux';
import { authenticationActions } from '../../../../store/authentication';
import { Button } from 'primereact/button';
import labels from './labels';

type ViewAsUserInfoModalProps = {
  visible: boolean;
};
export const ViewAsUserInfoModal = ({ visible }: ViewAsUserInfoModalProps) => {
  const dispatch = useDispatch();

  const handleResetViewAsUserMode = () => {
    dispatch(authenticationActions.resetViewAsUserRequest({ removeLicenseType: true }));
  };

  return (
    <Dialog
      visible={visible}
      onHide={handleResetViewAsUserMode}
      header={<div className="text-center text-3xl">{labels.viewAsUserMode}</div>}
      footer={
        <div className="text-center">
          <Button
            onClick={handleResetViewAsUserMode}
            outlined
            className="w-8rem"
            label={labels.reset}
            severity="danger"
          />
        </div>
      }
      resizable={false}
      draggable={false}
      focusOnShow={false}
      closable={false}
      className="w-30rem"
    >
      <div>
        <p>{labels.modalDescPart1}</p>
        <p>{labels.modalDescPart2}</p>
        <ul>
          <li>{labels.modalDescPart3}</li>
          <li>{labels.modalDescPart4}</li>
        </ul>
      </div>
    </Dialog>
  );
};
