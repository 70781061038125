import { t } from '../../../i18n/i18n';

export default {
  name: t('BookingPageWhatStep:NAME'),
  namePlaceholder: t('BookingPageWhatStep:NAME_PLACEHOLDER'),
  nameTooltip: t('BookingPageWhatStep:NAME_TOOLTIP'),
  color: t('BookingPageWhatStep:COLOR'),
  bookingTemplate: t('BookingPageWhatStep:BOOKING_TEMPLATE'),
  bookingTemplatePlaceholder: t('BookingPageWhatStep:BOOKING_TEMPLATE_PLACEHOLDER'),
  adminOnly: t('BookingPageWhatStep:ADMIN_ONLY'),
  adminOnlyTooltip: t('BookingPageWhatStep:ADMIN_ONLY_TOOLTIP'),
  meetingInstructions: t('BookingPageWhatStep:MEETING_INSTRUCTIONS'),
  meetingInstructionsPlaceholder: t('BookingPageWhatStep:MEETING_INSTRUCTIONS_PLACEHOLDER'),
  meetingInstructionsTooltip: t('BookingPageWhatStep:MEETING_INSTRUCTIONS_TOOLTIP'),
};
