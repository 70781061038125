export const CalendarConnections = Object.freeze({
  CALENDAR_INFO_MESSAGE: `Solo puede conectar 1 calendario`, // Para aumentar este límite, contacte con ventas para actualizar su nivel de licencia.
  DESCRIPTION: `Conecte su calendario para que SUMO sepa cuándo está disponible y actualice su calendario a medida que se reservan citas.`,
  DISCONNECT_MESSAGE: `¿Está seguro de que desea desconectar su calendario?`,
  DISCONNECT_MESSAGE_DESCRIPTION: `Las citas no serán eliminadas ni canceladas.`,
  DISCONNECT_TITLE: `Desconectar Calendario`,
  EXCHANGE_CALENDAR_DESCRIPTION: `Exchange Server 2013, 2016 o 2019`,
  EXCHANGE_CALENDAR_TITLE: `Calendario Exchange`,
  GOOGLE_CALENDAR_DESCRIPTION: `Gmail, G Suite, Google Workspace`,
  GOOGLE_CALENDAR_TITLE: `Calendario de Google`,
  MICROSOFT_CALENDAR_DESCRIPTION: `Office 365, Outlook.com, Live.com o Hotmail Calendar`,
  MICROSOFT_CALENDAR_TITLE: `Calendario de Office 365`,
  SELECT_CALENDAR_TITLE: `Seleccionar Calendario`,
  TITLE: `Conexiones de Calendario`,
  TOOLTIP: `Conecte su calendario de Google o Microsoft a SUMO`,
});

export const CookieSettings = Object.freeze({
  AGREE_CLOSE: `Aceptar y Cerrar`,
  DESCRIPTION: `Cuando visitas un sitio web, tiene la capacidad de almacenar o recuperar cierta información en tu navegador. Esta información, a menudo en forma de cookies, puede ser sobre tus preferencias, dispositivo u otros detalles. Su propósito principal es asegurar que el sitio web funcione como esperas. Aunque esta información no te identifica directamente, puede mejorar tu experiencia de navegación web personalizando el contenido. Entendemos y valoramos tu derecho a la privacidad, por lo que te ofrecemos la opción de controlar y personalizar tus preferencias de cookies. Al hacer clic en los diferentes encabezados de categoría, puedes aprender más sobre cada tipo de cookie y modificar nuestras configuraciones predeterminadas. Ten en cuenta que bloquear ciertos tipos de cookies puede afectar tu experiencia general en el sitio y limitar los servicios que podemos ofrecerte.`,
  DESCRIPTION_FIRST_PART: `Cuando visitas un sitio web, tiene la capacidad de almacenar o recuperar cierta información en tu navegador. Esta información, a menudo en forma de cookies, puede ser sobre tus preferencias, dispositivo u otros detalles. Su propósito principal es asegurar que el sitio web funcione como esperas.`,
  DESCRIPTION_SECOND_PART: `Aunque esta información no te identifica directamente, puede mejorar tu experiencia de navegación web personalizando el contenido. Entendemos y valoramos tu derecho a la privacidad, por lo que te ofrecemos la opción de controlar y personalizar tus preferencias de cookies.`,
  DESCRIPTION_THIRD_PART: `Al hacer clic en los diferentes encabezados de categoría, puedes aprender más sobre cada tipo de cookie y modificar nuestras configuraciones predeterminadas. Ten en cuenta que bloquear ciertos tipos de cookies puede afectar tu experiencia general en el sitio y limitar los servicios que podemos ofrecerte.`,
  FUNCTIONAL_NAME: `Cookies Funcionales`,
  MANAGE_TITLE: `Gestionar Preferencias de Consentimiento`,
  NECESSARY_ONLY: `Solo Estrictamente Necesarias`,
  PERFORMANCE_NAME: `Cookies de Rendimiento`,
  STRICTLY_NAME: `Cookies Estrictamente Necesarias`,
  TARGETING_NAME: `Cookies de Segmentación`,
  TITLE: `Configuración de Cookies`,
});

export const Login = Object.freeze({
  GOOGLE_ACCOUNT: `Cuenta de Google`,
  GOOGLE_ACCOUNTS_SWITCH: `Cambiar Cuentas de Google`,
  GOOGLE_SWITCH: `Iniciar sesión con Google`,
  GOOGLE_SWITCH_DESC: `Cambiar de autenticación de Microsoft a Google`,
  MICROSOFT_ACCOUNT: `Cuenta de Microsoft`,
  MICROSOFT_ACCOUNTS_SWITCH: `Cambiar Cuentas de Microsoft`,
  MICROSOFT_SWITCH: `Iniciar sesión con Microsoft`,
  MICROSOFT_SWITCH_DESC: `Cambiar de autenticación de Google a Microsoft`,
  SWITCH_ACCOUNT_MESSAGE: `Tu cuenta SUMO1 perderá acceso a datos disponibles solo a través de tu proveedor original. También es posible que necesites volver a otorgar acceso a integraciones y calendarios.`,
  SWITCH_DESCRIPTION_PART1: `Cambiar de `,
  SWITCH_DESCRIPTION_PART2: ` autenticación a `,
});

export const Role = Object.freeze({
  ROLE_DESCRIPTION: `Tu rol actual`,
});

export const AccountSettings = Object.freeze({
  DATE_FORMAT: `Formato de Fecha`,
  LANGUAGE: `Idioma`,
  NAME: `Nombre`,
  WELCOME_MESSAGE: `Mensaje de Bienvenida`,
  PHONE_NUMBER: `Número de Teléfono`,
  PROFILE_IMAGE: `Imagen de Perfil`,
  PHONE_DETAILS: `Detalles del Teléfono`,
  PHONE_DETAILS_PLACEHOLDER: `Agrega información adicional o instrucciones aquí.\r\nEj: Espero conocerte. Envíame un SMS en cualquier momento.`,
  TIME_FORMAT: `Formato de Hora`,
  TIME_ZONE: `Zona Horaria`,
  COUNTRY: `País`,
});

export const Profile = Object.freeze({
  TITLE: `Perfil`,
  ACCOUNT_SETTINGS: `Configuración de la Cuenta`,
  ACCOUNT_DESCRIPTION: `Información básica y configuraciones`,
  LOGIN: `Iniciar Sesión`,
  LOGIN_DESCRIPTION: `Configurar cuenta conectada`,
  MY_ROLE: `Mi Rol`,
  MY_ROLE_DESCRIPTION: `Tu rol y permisos actuales`,
  COOKIE_SETTINGS: `Configuración de Cookies`,
  COOKIE_DESCRIPTION: `Cookies Necesarias, de Rendimiento, Funcionales, de Segmentación`,
});

export const UserSettingsToastsNotifications = Object.freeze({
  CONNECT_EXTERNAL_CALENDAR_ERROR_TOAST: `Fallo en la solicitud de nuevo calendario`,
  CONNECT_EXTERNAL_CALENDAR_SUCCESS_TOAST: `¡Nuevo calendario conectado exitosamente!`,
  CONNECT_ZOOM_ERROR_TOAST: `Fallo en la solicitud de conexión de Zoom`,
  CONNECT_ZOOM_SUCCESS_TOAST: `¡Zoom conectado exitosamente!`,
  GET_USER_SETTINGS_FAIL: `Datos de usuario no encontrados, por favor contacta al soporte`,
  SAVE_USER_SETTINGS_ERROR_TOAST: `Fallo en la solicitud de guardar configuraciones`,
  SAVE_USER_SETTINGS_SUCCESS_TOAST: `¡Tus configuraciones se guardaron exitosamente!`,
  SWITCH_ACCOUNT_SUCCESS_TOAST: `Cambiado de cuenta exitosamente`,
  SWITCH_ACCOUNT_ERROR_TOAST: `Fallo al cambiar de cuenta. Por favor intenta de nuevo más tarde`,
});
