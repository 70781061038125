export const BookingPages = Object.freeze({
  NEW_BOOKING_PAGE: `Nouvelle page de réservation`,
  DELETE_BOOKING_PAGE_DIALOG_TEXT: `Êtes-vous sûr de vouloir supprimer cette page de réservation ?`,
  DELETE_BOOKING_PAGES_DIALOG_TEXT: `Êtes-vous sûr de vouloir supprimer ces pages de réservation ?`,
  TITLE: `Pages de réservation`,
  ADD_TO_SITE: `Ajouter au site web`,
  CLONE_MODAL_TITLE: `Cloner la page de réservation`,
  DEFAULT_FILTER: `Mes pages de réservation`,
  SHARED_LABEL: `Partagé`,
  CLONE_NOTIFICATION: `Veuillez noter que la propriété "Admin uniquement" est désactivée par défaut pour un enregistrement cloné.`,
});

export const BookingPagesToastsNotifications = Object.freeze({
  CLONE_BOOKING_PAGES_ERROR_MESSAGE: `Échec de la demande de clonage de la page de réservation`,
  CLONE_BOOKING_PAGES_SUCCESS_MESSAGE: `Page de réservation clonée avec succès`,
  DELETE_BOOKING_PAGES_ERROR_MESSAGE: `Échec de la demande de suppression des pages de réservation`,
  DELETE_BOOKING_PAGES_SUCCESS_MESSAGE: `Page de réservation supprimée avec succès`,
  GET_BOOKING_PAGE_ERROR_MESSAGE: `Impossible de récupérer la page de réservation, elle pourrait être supprimée`,
  GET_BOOKING_PAGES_ERROR_MESSAGE: `Échec de la demande de récupération des pages de réservation`,
  SAVE_BOOKING_PAGE_ERROR_MESSAGE: `Échec de la demande de sauvegarde de la page de réservation`,
  SAVE_BOOKING_PAGE_SUCCESS_MESSAGE: `Page de réservation sauvegardée avec succès`,
});

export const DefaultBookingPage = Object.freeze({
  NAME: `Réunion de 30 minutes`,
  BOOK_A_MEETING: `Réserver une réunion`,
  MEETING_DETAILS: `Détails de la réunion`,
  GUESTS_INFO: `Informations de l'invité`,
  BOOK_MEETING_BUTTON: `Confirmer`,
  BOOK_ANOTHER_MEETING_BUTTON: `Réserver une autre réunion`,
  CANCEL: `Annuler`,
  CANCEL_MEETING_BUTTON: `Cancelar reunião`,
  CONFIRM_CANCEL_BUTTON: `Confirmer`,
  SAVE_MEETING_BUTTON: `Replanifier`,
  MEETING_BOOKED_TITLE: `Vous êtes réservé!`,
  MEETING_BOOKED_DESCRIPTION: `Une confirmation par e-mail a été envoyée à votre boîte de réception.`,
  MEETING_RESCHEDULED_TITLE: `Réunion replanifiée!`,
  MEETING_RESCHEDULED_DESCRIPTION: `Une confirmation par e-mail a été envoyée à votre boîte de réception.`,
  MEETING_CANCELED_TITLE: `Réunion annulée!`,
  MEETING_CANCELED_DESCRIPTION: `Une confirmation par e-mail a été envoyée à votre boîte de réception.`,
});

export const EditBookingPage = Object.freeze({
  WHAT_TITLE: `Quoi`,
  WHAT_DESCRIPTION: `Modèles de réservation et détails`,
  WHO_TITLE: `Qui`,
  WHO_DESCRIPTION: `Hôte de la réunion`,
  WHERE_TITLE: `Où`,
  WHERE_DESCRIPTION: `Détails et lieux de l'événement`,
  WHEN_TITLE: `Quand`,
  WHEN_DESCRIPTION: `Durée et disponibilité`,
  HOW_TITLE: `À quoi ça devrait ressembler ?`,
  HOW_DESCRIPTION: `Styles de marque et affichages`,
  ALERTS_TITLE: `Alertes`,
  ALERTS_DESCRIPTION: `Emails et rappels`,
  INVITEE_TITLE: `Paramètres de l'invité`,
  INVITEE_DESCRIPTION: `Formulaire de réservation et permission de l'invité`,
  AFTER_TITLE: `Après la réservation`,
  AFTER_DESCRIPTION: `Page de confirmation et politique d'annulation`,
  ADDITIONAL_CONFIGURATIONS: `Configurations supplémentaires`,
  VIEW_BOOKING_PAGE: `Voir la page de réservation`,
  COPY_LINK: `Copier le lien`,
  ADD_TO_WEBSITE: `Ajouter au site web`,
  ACTIVE: `Actif`,
  BOOKING_TEMPLATE_LINK_TOOLTIP: `Enregistrement verrouillé. Ces paramètres proviennent du modèle de réservation intitulé :`,
  BOOKING_TEMPLATE_LINK_TOOLTIP_OVERRIDE: `Cliquez ici pour remplacer ces paramètres`,
  LOCKED_ADMIN_ONLY: `Seul un administrateur peut modifier ces paramètres. Dernière modification par :`,
  LOCKED_NO_PERMISSIONS: `Vous n'avez pas les autorisations pour modifier cette page de réservation. Dernière modification par :`,
});

export const BookingPageWhatStep = Object.freeze({
  NAME: `Nom`,
  NAME_PLACEHOLDER: `Nom personnalisé pour cette page de réservation`,
  NAME_TOOLTIP: `Ce texte sera affiché aux participants de la réunion sur leur calendrier et dans les emails. Par exemple "Réunion de 30min" ou "Examen dentaire".`,
  COLOR: `Couleur`,
  BOOKING_TEMPLATE: `Modèle de réservation`,
  BOOKING_TEMPLATE_PLACEHOLDER: `Sélectionner un modèle de réservation`,
  ADMIN_ONLY: `Admin uniquement`,
  ADMIN_ONLY_TOOLTIP: `Seul un administrateur peut modifier ces paramètres`,
  MEETING_INSTRUCTIONS: `Instructions de la réunion`,
  MEETING_INSTRUCTIONS_PLACEHOLDER: `Entrez les instructions de la réunion`,
  MEETING_INSTRUCTIONS_TOOLTIP: `Écrivez un résumé et tous les détails que votre invité doit connaître sur l'événement.`,
});

export const EditBookingPageWhoStep = Object.freeze({
  HOSTS_AND_TEAMS: `Hôtes et équipes`,
  HOSTS_DESCRIPTION: `Vous pouvez choisir un ou plusieurs hôtes pour assister à cette réunion`,
  SEARCH_PLACEHOLDER: `Rechercher des hôtes et des équipes`,
});

export const EditBookingPageDurationStep = Object.freeze({
  DURATION: `Durée`,
  DURATION_DESCRIPTION: `Définissez la durée de votre réunion. Ne doit pas dépasser 12 heures.`,
  BUFFER_TIME: `Temps de tampon`,
  BUFFER_TIME_TOOLTIP: `Définir les temps de tampon avant et après`,
  AFTER_CHECKBOX_LABEL: `après l'événement`,
  BEFORE_CHECKBOX_LABEL: `avant l'événement`,
});

export const EditBookingPageDateRangeStep = Object.freeze({
  SCHEDULE_AVAILABILITY: `Disponibilité du calendrier`,
  MINIMUM_NOTICE_PERIOD: `Délai de préavis minimum`,
  OPTION_DAYS_INTO_THE_FUTURE: `Jours à venir`,
  OPTION_DATE_RANGE: `Dans une plage de dates`,
  OPTION_INDEFINITELY: `Indéfiniment dans le futur`,
});

export const EditBookingPageInviteeStep = Object.freeze({
  QUESTIONS: `Questions du formulaire de réservation`,
  QUESTIONS_DESCRIPTION: `Améliorez le formulaire de réservation avec des questions d'invitation`,
  NEW_QUESTION: `Nouvelle question`,
  EDIT_QUESTION: `Modifier la question`,
  PERMISSIONS: `Permissions de l'invité`,
  PERMISSIONS_DESCRIPTION: `Définissez les actions disponibles pour vos invités`,
  OPTION_INVITE_OTHERS: `Les invités peuvent inviter d'autres personnes`,
  OPTION_INVITE_OTHERS_TOOLTIP: `En activant cette fonctionnalité, un champ "Inviter d'autres" sera affiché sur la page de réservation permettant à l'invité d'ajouter d'autres personnes à la liste des participants.`,
  OPTION_ENTER_NOTE: `Les invités peuvent entrer une note pour tous les invités`,
  OPTION_ENTER_NOTE_TOOLTIP: `En activant cette fonctionnalité, un champ "Note pour les participants" sera affiché, permettant à l'invité d'entrer une note à afficher sur le rendez-vous du calendrier réservé pour que tous les participants puissent la voir.`,
});

export const EditBookingPageAfterStep = Object.freeze({
  CONFIRMATION_PAGE: `Page de confirmation`,
  OPTION_DISAPLY_CONFIRMATION_PAGE: `Afficher la page de confirmation SUMO`,
  OPTION_REDIRECT_TO_EXTERNAL_PAGE: `Rediriger vers une page externe spécifique`,
  EXTERNAL_LINK_PLACEHOLDER: `URL de redirection externe`,
  AVAILABLE_ACTIONS: `Actions disponibles`,
  AVAILABLE_ACTIONS_DESCRIPTION: `Actions accessibles pour les invités depuis cette page de confirmation.`,
  OPTION_RESCHEDULE: `Replanifier`,
  OPTION_CANCEL: `Annuler la réunion`,
  OPTION_BOOK_ANOTHER: `Réserver une autre réunion`,
  CUSTOM_LINKS: `Liens personnalisés supplémentaires`,
  CUSTOM_LINKS_DESCRIPTION: `Ajouter des liens personnalisés sur la page de confirmation, tels qu'un sondage ou une ressource supplémentaire.`,
  ADD_CUSTOM_LINK: `Ajouter un lien personnalisé`,
  CHANGE_CUSTOM_LINK: `Changer le lien personnalisé`,
  LINK_URL: `URL du lien`,
  CUSTOM_LINK_LABEL: `Libellé du lien personnalisé`,
  CUSTOM_LINK_LABEL_PLACEHOLDER: `Accord de réunion`,
  CANCELATION_POLICY: `Politique d'annulation`,
  CANCELATION_POLICY_DESCRIPTION: `Fournissez des détails sur votre politique d'annulation pour clarifier les conséquences des changements.`,
  CANCELATION_POLICY_PLACEHOLDER: `Texte de la politique d'annulation`,
});

export const BookingPageHowStep = Object.freeze({
  BRANDING_TITLE: `Personnalisation de la page de réservation`,
  BRANDING_DESCRIPTION_BOOKING_PAGE: `Personnalisez l'apparence, les styles et les étiquettes de cette page de réservation.`,
  BRANDING_DESCRIPTION_BOOKING_TEMPLATE: `Personnalisez l'apparence, les styles et les étiquettes de ce modèle de réservation.`,
  CALENDAR_TITLE: `Paramètres du calendrier`,
  CALENDAR_DESCRIPTION: `Personnalisez le format d'affichage du calendrier, de la date et du fuseau horaire.`,
});

export const BookingPageBrandingModal = Object.freeze({
  TITLE: `Personnalisation de la page de réservation`,
  DESCRIPTION_BOOKING_PAGE: `Cette page de réservation utilise les paramètres de marque par défaut de l'espace de travail, mais vous pouvez les personnaliser ci-dessous.`,
  DESCRIPTION_BOOKING_TEMPLATE: `Ce modèle de réservation utilise les paramètres de marque par défaut de l'espace de travail, mais vous pouvez les personnaliser ci-dessous.`,
  LOGO: `Logo`,
  BACKGROUND: `Arrière-plan`,
  BACKGROUND_OPTION_BLANK: `Vide`,
  BACKGROUND_OPTION_WALLPAPER: `Fond d'écran`,
  BACKGROUND_OPTION_Color: `Couleur`,
  BACKGROUND_TYPE: `Type de fond`,
  BACKGROUND_TYPE_TOP: `Bannière`,
  BACKGROUND_TYPE_FULL: `Page complète`,
  BACKGROUND_TYPE_LEFT: `Côté gauche`,
  BACKGROUND_TYPE_RIGHT: `Côté droit`,
  BACKGROUND_COLOR: `Couleur de fond`,
  MAIN_COLOR: `Couleur principale`,
  FONT_COLOR: `Couleur de police`,
  CUSTOM_CSS: `CSS personnalisé`,
  CUSTOM_CSS_PLACEHOLDER: `Vous pouvez ajouter du code CSS personnalisé`,
  FOOTER_HTML: `Pied de page HTML`,
  FOOTER_HTML_PLACEHOLDER: `Vous pouvez ajouter du HTML de pied de page ou du texte simple`,
  RESET_DEFAULT_STYLES: `Réinitialiser aux styles par défaut`,
  STEP_JOURNEY: `Voyage`,
  STEP_BOOKING_PAGE: `Page de réservation`,
  STEP_BOOKING_FORM: `Formulaire de réservation`,
  STEP_BOOKED: `Réservé`,
  STEP_RESCHEDULED: `Reprogrammé`,
  STEP_CANCELED: `Annulé`,
  STEP_CANCEL: `Annuler`,
});

export const BookingPageCalendarModal = Object.freeze({
  TITLE: `Paramètres du calendrier`,
  TIME_FORMAT: `Format de l'heure`,
  HOUR_12: `12 heures`,
  HOUR_12_EXAMPLE: `1:00 p.m.`,
  HOUR_24: `24 heures`,
  HOUR_24_EXAMPLE: `13:00`,
  START_TIME_INTERVALS: `Intervalles de temps`,
  START_TIME_INTERVALS_DESCRIPTION: `Sélectionnez la durée de l'intervalle que vous souhaitez afficher lors de la planification d'un rendez-vous.`,
  START_TIME_INTERVALS_TOOLTIP: `Choisissez la durée des intervalles que vous souhaitez afficher lors de la planification d'un rendez-vous. Par défaut, nous avons sélectionné 15 minutes pour maximiser la disponibilité des créneaux. Cette option affichera les créneaux disponibles par cet intervalle.`,
  INTERVALS_15_MIN: `15 min`,
  INTERVALS_20_MIN: `20 min`,
  INTERVALS_30_MIN: `30 min`,
  INTERVALS_60_MIN: `60 min`,
  TOP_OF_THE_INTERVAL: `Commencez en haut de l'intervalle`,
  TOP_OF_THE_INTERVAL_DESCRIPTION: `Activez cette option pour commencer tous les rendez-vous en haut de chaque intervalle (par exemple, 8h00, 9h00)`,
  TOP_OF_THE_INTERVAL_TOOLTIP: `Activez "Commencez en haut de l'intervalle" si vous souhaitez que tous les rendez-vous commencent toujours en haut de l'intervalle, quel que soit la durée du modèle de réservation. Par exemple, les durées des créneaux seraient disponibles à 8h00, 9h00, 10h00, etc. REMARQUE : L'activation de cette fonctionnalité ne permettra pas à l'utilisateur de maximiser la disponibilité.`,
  TIME_ZONES: `Fuseaux horaires`,
  TIME_ZONES_TOOLTIP: `Les fuseaux horaires suivants seront disponibles sur la page de réservation orientée client. Si vous ne choisissez qu'un seul fuseau horaire, il sera fixé, de sorte que le champ de fuseau horaire sera grisé et l'utilisateur ne pourra pas le modifier.`,
  TIME_ZONE_DETECT: `Détecter automatiquement et afficher les créneaux horaires dans le fuseau horaire de l'invité.`,
  TIME_ZONE_DISPLAY: `Afficher uniquement les fuseaux horaires suivants (idéal pour les événements en personne)`,
  DEFAULT_TIME_ZONE: `Fuseau horaire par défaut`,
});

export const BookingPageAddToWebsite = Object.freeze({
  BOTTOM_LEFT: `En bas à gauche`,
  BOTTOM_RIGHT: `En bas à droite`,
  BUTTON_POPUP_TITLE: `Bouton vers Popup`,
  COPY_CODE_EMBED_DESCRIPTION: `Intégrez votre widget SUMO sur votre site web en collant le code HTML à l'emplacement souhaité.`,
  COPY_CODE_LINK_DESCRIPTION: `Affichez un bouton de réservation flottant sur les pages que vous souhaitez en plaçant ce code dans votre HTML.`,
  COPY_CODE_POPUP_DESCRIPTION: `Affichez votre lien de réservation partout en plaçant ce code HTML sur vos pages, signatures d'emails, et plus encore.`,
  COPY_CODE_TITLE: `Copier le code`,
  CUSTOMIZE_DESCRIPTION: `Personnalisez l'apparence pour s'intégrer parfaitement à votre site web.`,
  CUSTOMIZE_TITLE: `Personnaliser`,
  EMBED_DESCRIPTION: `Affichez votre page de réservation SUMO1 dans une iFrame sur votre site web.`,
  EMBED_TITLE: `Intégrer`,
  FLOATING_BUTTON_DESCRIPTION: `Bouton flottant`,
  HIDE_PAGE_DETAILS: `Masquer les détails de la page`,
  HIDE_COOKIE_BANNER: `Masquer la bannière des cookies`,
  LABEL: `Étiquette`,
  LINK_DESCRIPTION: `Ajoutez un lien texte à votre site qui lancera votre page de réservation SUMO1 dans une popup.`,
  LINK_TITLE: `Lien texte`,
  POSITION: `Position`,
  POPUP_DESCRIPTION: `Affichez votre page de réservation SUMO1 dans une popup lorsqu'un bouton est cliqué.`,
  POPUP_TITLE: `Popup`,
  TITLE: `Comment allez-vous ajouter SUMO à votre site web ?`,
  TOP_LEFT: `En haut à gauche`,
  TOP_RIGHT: `En haut à droite`,
});

export const BookingPagesOverrideModal = Object.freeze({
  TITLE: `Confirmer le remplacement`,
  DESCRIPTION: `En remplaçant les paramètres d'un modèle de réservation, vous n'utilisez techniquement plus ce modèle de réservation, mais créez plutôt une page de réservation personnalisée, qui nécessite un nom.`,
});
