import { DocumentTextIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { getFileSizeString } from '../../../services/utils';

type FileCardProps = {
  file: File;
  onRemove?: () => void;
};

export const FileCard = ({ file, onRemove }: FileCardProps) => {
  return (
    <div className="flex-left-center gap-4px">
      <div className="flex-1 border-1 border-heavy-20 border-radius-10px flex-between p-8px">
        <div className="flex-left-center gap-8px">
          <div className="flex-center w-32px h-32px bg-heavy-1 border-radius-6px">
            <DocumentTextIcon className="icon-20px" />
          </div>
          <div className="flex flex-column gap-4px">
            <div className="text-label-s-med text-heavy-80 text-overflow-ellipsis">{file.name}</div>
            <div className="text-label-xs-reg text-heavy-60 text-overflow-ellipsis">{getFileSizeString(file.size)}</div>
          </div>
        </div>
      </div>
      <div className="w-20px">
        {onRemove && (
          <div className="action-button-xs" onClick={onRemove}>
            <XMarkIcon className="icon-16px" />
          </div>
        )}
      </div>
    </div>
  );
};
