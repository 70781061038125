import { t } from '../../../i18n/i18n';

export default {
  title: t('Workspaces:CLONE_MODAL_TITLE'),
  name: t('Workspaces:NAME_TITLE'),
  namePlaceholder: t('Workspaces:NAME_PLACEHOLDER'),
  duplicate: t('Common:NAME_IS_DUPLICATE'),
  cancel: t('Common:CANCEL'),
  save: t('Common:SAVE'),
};
