import { useState, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import {
  CURRENT_TIME_ZONE,
  MAX_LENGTH_DESCRIPTION,
  MAX_LENGTH_NAME,
  FILE_IMAGE_TYPES,
  MAX_FILE_SIZE_BYTES,
  languageNames,
} from '../../../types/constants';
import { DateFormat, userSettingsActions, userSettingsSelectors, TimeFormat } from '../../../store/userSettings';
import { PhoneNumber, TimeZoneSelector } from '../../common';
import labels from './labels';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { FileUpload } from 'primereact/fileupload';
import { PhoneNumberObject } from '../../../types/interfaces';
import { countriesTimeZonesService } from '../../../services/CountriesTimeZoneService';
import { InputTextarea } from 'primereact/inputtextarea';
import { validatePhoneNumber } from '../../../services/utils';
import { Image } from 'primereact/image';
import { CloudArrowUpIcon, TrashIcon } from '@heroicons/react/24/outline';
import { UserSettingsInput } from '../../../API';

export const AccountSettings = () => {
  const { i18n } = useTranslation();
  const dispatch = useDispatch();

  const phoneInfo = useSelector(userSettingsSelectors.selectPhoneInfo);
  const fullName = useSelector(userSettingsSelectors.selectFullName) || '';
  const dateFormat = useSelector(userSettingsSelectors.selectDateFormat);
  const timeZone = useSelector(userSettingsSelectors.selectTimeZone);
  const timeFormat = useSelector(userSettingsSelectors.selectTimeFormat);
  const phoneDetails = useSelector(userSettingsSelectors.selectPhoneDetails);
  const country = useSelector(userSettingsSelectors.selectCountry);
  const avatarLink = useSelector(userSettingsSelectors.selectAvatar);
  const isPhoneRequired = useSelector(userSettingsSelectors.selectIsCurrentUserPhoneRequired);
  const countriesOptions = countriesTimeZonesService.getCountriesOptions(i18n.language);

  const [localPhoneNumber, setLocalPhoneNumber] = useState<PhoneNumberObject>(phoneInfo);
  const [localFullName, setLocalFullName] = useState(fullName);
  const [localPhoneDetails, setLocalPhoneDetails] = useState(phoneDetails);
  const [localCountry, setLocalCountry] = useState(country);
  const [language, setLanguage] = useState(i18n.language);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const fileUploadRef: any = useRef(null); // TODO: replace any
  // const deleteReqSentToast = useRef<Toast>(null);
  // const fileButtonOptions = {
  //   label: labels.uploadPicture,
  //   icon: 'hidden',
  //   className: 'p-button-outlined',
  // };

  useEffect(() => {
    setLocalFullName(fullName);
  }, [fullName]);

  useEffect(() => {
    setLocalPhoneDetails(phoneDetails);
  }, [phoneDetails]);

  useEffect(() => {
    if (phoneInfo.countryCode) {
      setLocalPhoneNumber(phoneInfo);
    }
  }, [phoneInfo]);

  useEffect(() => {
    if (country) {
      setLocalCountry(country);
    } else {
      const timezoneInf = countriesTimeZonesService.getTimezoneByName(timeZone || CURRENT_TIME_ZONE);
      const autoCountryCode = timezoneInf ? timezoneInf.countries[0] : '';
      dispatch(userSettingsActions.updateUserSettings({ country: autoCountryCode }));
      dispatch(userSettingsActions.saveUserSettingsNoToastRequest());
    }
  }, [country]);

  const handleSaveChanges = (payload: Partial<UserSettingsInput>) => {
    dispatch(userSettingsActions.updateUserSettings(payload));
    dispatch(userSettingsActions.saveUserSettingsRequest());
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleFileUpload = (files: any) => {
    // TODO: replace any
    files?.length && dispatch(userSettingsActions.uploadAvatarFileRequest(files[0]));
    fileUploadRef.current.clear();
  };

  const handleChangeFullName = (fullName: string) => {
    setLocalFullName(fullName);
  };
  const handleBlurFullName = () => {
    const newFullName = localFullName.trimEnd();
    setLocalFullName(newFullName);

    if (newFullName && fullName !== newFullName) {
      handleSaveChanges({ fullName: newFullName });
    }
  };

  const handleChangePhoneDetails = (phoneDetails: string) => {
    setLocalPhoneDetails(phoneDetails);
  };
  const handleBlurPhoneDetails = () => {
    if ((localPhoneDetails || localPhoneDetails === '') && localPhoneDetails !== phoneDetails)
      handleSaveChanges({ phoneDetails: localPhoneDetails });
  };

  const handleLanguageChange = (language: string) => {
    if (language) {
      setLanguage(language);
      handleSaveChanges({ language });

      if (i18n.language !== language) {
        i18n.changeLanguage(language);
        window.location.reload();
      }
    }
  };

  const handleChangeTimeFormat = (timeFormat: string) => {
    handleSaveChanges({ timeFormat });
  };

  const handleChangeDateFormat = (dateFormat: string) => {
    handleSaveChanges({ dateFormat });
  };

  const handleChangeTimeZone = (timeZone: string) => {
    handleSaveChanges({ timeZone });
  };

  const handleChangeCountryCode = (countryCode: string) => {
    setLocalPhoneNumber((prevState) => ({ ...prevState, countryCode }));
    handleSaveChanges({ countryCode });
  };

  const handleChangeCountry = (country: string) => {
    setLocalCountry(country);
    handleSaveChanges({ country });
  };

  const handlePhoneChange = (phoneNumber: string) => {
    setLocalPhoneNumber((prevState) => ({ ...prevState, phoneNumber }));
  };
  const handlePhoneBlur = () => {
    if (
      (!isPhoneRequired || validatePhoneNumber(localPhoneNumber.phoneNumber)) &&
      localPhoneNumber.phoneNumber !== phoneInfo.phoneNumber
    ) {
      handleSaveChanges({ phoneNumber: localPhoneNumber.phoneNumber });
    }
  };

  const handleAvatarRemove = () => {
    dispatch(userSettingsActions.clearAvatarFileRequest());
  };

  return (
    <div className="flex flex-wrap p-fluid -mx-20px">
      <div className="w-6 px-20px flex flex-column gap-24px">
        <div>
          <div className="mb-10px text-title-xs-med text-heavy-100">{labels.name}</div>
          <InputText
            type="text"
            value={localFullName || ''}
            onChange={(e) => handleChangeFullName(e.target.value.trimStart())}
            onBlur={handleBlurFullName}
            maxLength={MAX_LENGTH_NAME}
            className={`${!localFullName ? 'p-invalid' : ''}`}
          />
        </div>
        <div>
          <div className="mb-10px text-title-xs-med text-heavy-100">{labels.phoneNumber}</div>
          <PhoneNumber
            countryCode={localPhoneNumber.countryCode || ''}
            handleChangeCode={handleChangeCountryCode}
            phoneNumber={localPhoneNumber.phoneNumber || ''}
            handleChangePhone={handlePhoneChange}
            handleBlurPhone={handlePhoneBlur}
            inputClassName={`${
              isPhoneRequired && !validatePhoneNumber(localPhoneNumber.phoneNumber) ? 'p-invalid' : ''
            }`}
          />
        </div>
        <div>
          <div className="mb-10px text-title-xs-med text-heavy-100">{labels.phoneDetailsLabel}</div>
          <InputTextarea
            autoResize
            rows={4}
            value={localPhoneDetails || ''}
            onChange={(e) => handleChangePhoneDetails(e.target.value.trimStart())}
            onBlur={handleBlurPhoneDetails}
            placeholder={labels.phoneDetailsPlaceholder}
            maxLength={MAX_LENGTH_DESCRIPTION}
          />
        </div>
      </div>

      <div className="w-6 px-20px">
        <div className="mb-10px text-title-xs-med text-heavy-100">{labels.profileImage}</div>
        <FileUpload
          ref={fileUploadRef}
          mode="basic"
          auto
          accept={FILE_IMAGE_TYPES}
          maxFileSize={MAX_FILE_SIZE_BYTES}
          customUpload
          uploadHandler={(e) => handleFileUpload(e.files)}
          className="hidden"
        ></FileUpload>
        {avatarLink ? (
          <div className="flex-left-center gap-14px">
            <div className="h-96px border-radius-10px bg-heavy-1 overflow-hidden">
              <Image
                src={avatarLink}
                preview
                className="h-full w-full"
                imageClassName="w-full h-full"
                imageStyle={{
                  objectFit: 'contain',
                  objectPosition: 'center',
                }}
              />
            </div>
            <div className="text-heavy-60 hover-text-heavy-100 cursor-pointer" onClick={handleAvatarRemove}>
              <TrashIcon width={20} height={20} />
            </div>
          </div>
        ) : (
          <div
            className="h-96px flex-center gap-8px border-radius-10px bg-heavy-1 text-heavy-60 hover-text-heavy-100 cursor-pointer"
            onClick={() => fileUploadRef.current?.getInput().click()}
          >
            <CloudArrowUpIcon width={48} height={48} strokeWidth="0.75px" />
            <div className="text-label-xs-reg">
              <div>{labels.pictureSize}</div>
              <div>{labels.pictureFormats}</div>
            </div>
          </div>
        )}
      </div>

      <div className="w-6 px-20px pt-24px">
        <div className="mb-10px text-title-xs-med text-heavy-100">{labels.timeFormat}</div>
        <Dropdown
          value={timeFormat || TimeFormat.default}
          onChange={(e) => handleChangeTimeFormat(e.target.value as string)}
          options={Object.values(TimeFormat)}
        />
      </div>

      <div className="w-6 px-20px pt-24px">
        <div className="mb-10px text-title-xs-med text-heavy-100">{labels.dateFormat}</div>
        <Dropdown
          value={dateFormat || DateFormat.default}
          onChange={(e) => handleChangeDateFormat(e.target.value as string)}
          options={Object.values(DateFormat)}
        />
      </div>

      <div className="w-6 px-20px pt-24px">
        <div className="mb-10px text-title-xs-med text-heavy-100">{labels.timeZone}</div>
        <TimeZoneSelector selectedMode="full" onChange={handleChangeTimeZone} value={timeZone || CURRENT_TIME_ZONE} />
      </div>

      <div className="w-6 px-20px pt-24px">
        <div className="mb-10px text-title-xs-med text-heavy-100">{labels.country}</div>
        <Dropdown
          id="inputcountry"
          value={localCountry}
          onChange={(e) => handleChangeCountry(e.target.value as string)}
          optionLabel="label"
          optionValue="value"
          options={countriesOptions}
          filter
          filterBy="label"
        />
      </div>

      <div className="w-6 px-20px pt-24px">
        <div className="mb-10px text-title-xs-med text-heavy-100">{labels.language}</div>
        <Dropdown
          value={language}
          onChange={(e) => handleLanguageChange(e.target.value as string)}
          optionLabel="label"
          optionValue="value"
          options={
            i18n.options.resources &&
            Object.keys(i18n.options.resources).map((language: string) => ({
              value: language,
              label: languageNames[language],
            }))
          }
        />
      </div>
    </div>
  );
};
