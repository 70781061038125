import { EllipsisVerticalIcon } from '@heroicons/react/24/outline';
import { Skeleton } from 'primereact/skeleton';

type CardSkeletonProps = {
  hideDetails?: boolean;
  hideOptions?: boolean;
};
export const CardSkeleton = ({ hideDetails, hideOptions }: CardSkeletonProps) => {
  return (
    <div className="sumo-card action-card flex flex-column h-full">
      <div className="flex-between gap-32px pt-22px px-18px">
        <Skeleton width="200px" height="22px" />
        <div className="flex-center gap-4px -my-1px h-24px">
          <div className="-my-4px -mr-16px action-button">
            <EllipsisVerticalIcon className="icon-20px" />
          </div>
        </div>
      </div>

      {!hideDetails && (
        <div className="pt-4px px-18px">
          <Skeleton width="60px" height="16px" />
        </div>
      )}

      <div className="flex flex-wrap py-12px px-18px">
        <Skeleton width="90px" height="32px" className="border-radius-6px" />
      </div>

      <div className="card-delimeter flex-1" />

      <div className="flex-between-center p-10px pl-18px">
        <div className="flex gap-4px">
          {!hideOptions && (
            <>
              <Skeleton width="32px" height="32px" className="border-radius-8px" />
              <Skeleton width="32px" height="32px" className="border-radius-8px" />
              <Skeleton width="32px" height="32px" className="border-radius-8px" />
            </>
          )}
        </div>
        <Skeleton width="120px" height="38px" className="ml-autoborder-radius-8px" />
      </div>
    </div>
  );
};
