import { useEffect } from 'react';
import { MICROSOFT_AUTH_REDIRECT_EVENT } from './constants';

export const MicrosoftAuthRedirect = () => {
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get('code');
    const state = Number(urlParams.get('state'));

    if (code) {
      window.opener.postMessage(
        {
          code,
          state,
          type: MICROSOFT_AUTH_REDIRECT_EVENT,
        },
        // We need to support any chrome-extension:// origins in development.
        // In production, we can replace it with the actual static extension id.
        '*'
      );

      window.close();
    }
  }, []);

  return <div style={{ width: '1', height: '1' }} />;
};
