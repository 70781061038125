import { t } from '../../../i18n/i18n';

export default {
  add: t('EditJourney:ADD_LABEL'),
  save: t('Common:SAVE'),
  cancel: t('Common:CANCEL'),
  selectionBox: t('JourneysPageStep:SELECTION_BOX'),
  titlePlaceholder: t('JourneysPageStep:CARD_TITLE'),
  detailsPlaceholder: t('JourneysPageStep:CARD_DETAILS'),
};
