import { SelectItem } from 'primereact/selectitem';
import { PermissionAction, Role } from '../../API';
import { t } from '../../i18n/i18n';
import {
  TOAST_ERROR_COLOR,
  TOAST_ERROR_DURATION,
  TOAST_SUCCESS_COLOR,
  TOAST_SUCCESS_DURATION,
} from '../../types/constants';
import { ToastNotificationOptions } from '../notifications';
import { RoleFilterType } from './types';

export const GET_ROLES_FAIL_TOAST: ToastNotificationOptions = Object.freeze({
  color: TOAST_ERROR_COLOR,
  autoHideDuration: TOAST_ERROR_DURATION,
  message: t('RoleToastsNotifications:GET_ROLES_FAIL_MESSAGE'),
});
export const GET_ROLE_FAIL_TOAST: ToastNotificationOptions = Object.freeze({
  color: TOAST_ERROR_COLOR,
  autoHideDuration: TOAST_ERROR_DURATION,
  message: t('RoleToastsNotifications:GET_ROLE_FAIL_MESSAGE'),
});
export const SAVE_ROLE_SUCCESS_TOAST: ToastNotificationOptions = Object.freeze({
  color: TOAST_SUCCESS_COLOR,
  autoHideDuration: TOAST_SUCCESS_DURATION,
  message: t('RoleToastsNotifications:SAVE_ROLE_SUCCESS_MESSAGE'),
});
export const SAVE_ROLE_ERROR_TOAST: ToastNotificationOptions = Object.freeze({
  color: TOAST_ERROR_COLOR,
  autoHideDuration: TOAST_ERROR_DURATION,
  message: t('RoleToastsNotifications:SAVE_ROLE_ERROR_MESSAGE'),
});

export const DELETE_ROLE_SUCCESS_TOAST: ToastNotificationOptions = Object.freeze({
  color: TOAST_SUCCESS_COLOR,
  autoHideDuration: TOAST_SUCCESS_DURATION,
  message: t('RoleToastsNotifications:DELETE_ROLE_SUCCESS_MESSAGE'),
});
export const DELETE_ROLE_ERROR_TOAST: ToastNotificationOptions = Object.freeze({
  color: TOAST_ERROR_COLOR,
  autoHideDuration: TOAST_ERROR_DURATION,
  message: t('RoleToastsNotifications:DELETE_ROLE_ERROR_MESSAGE'),
});
export const DELETE_ROLE_ASSIGNED_ERROR_TOAST: ToastNotificationOptions = Object.freeze({
  color: TOAST_ERROR_COLOR,
  autoHideDuration: TOAST_ERROR_DURATION,
  message: t('RoleToastsNotifications:DELETE_ROLE_ASSIGNED_ERROR_MESSAGE'),
});

export const ACTIVATE_ROLE_SUCCESS_TOAST: ToastNotificationOptions = Object.freeze({
  color: TOAST_SUCCESS_COLOR,
  autoHideDuration: TOAST_SUCCESS_DURATION,
  message: t('RoleToastsNotifications:ACTIVATE_ROLE_SUCCESS_MESSAGE'),
});
export const DEACTIVATE_ROLE_SUCCESS_TOAST: ToastNotificationOptions = Object.freeze({
  color: TOAST_SUCCESS_COLOR,
  autoHideDuration: TOAST_SUCCESS_DURATION,
  message: t('RoleToastsNotifications:DEACTIVATE_ROLE_SUCCESS_MESSAGE'),
});
export const DEACTIVATE_ACTIVATE_ROLE_ERROR_TOAST: ToastNotificationOptions = Object.freeze({
  color: TOAST_ERROR_COLOR,
  autoHideDuration: TOAST_ERROR_DURATION,
  message: t('RoleToastsNotifications:DEACTIVATE_ACTIVATE_ROLE_ERROR_MESSAGE'),
});

export const FULL_PERMISSION_ACTION_LIST = [
  PermissionAction.READ,
  PermissionAction.CREATE,
  PermissionAction.EDIT,
  PermissionAction.DELETE,
];

export const DEFAULT_ROLE_DATA = {
  __typename: 'Role',
  id: '',
  tenantId: '',
  name: t('Roles:NEW_ROLE'),
  isCustom: true,
  isActive: true,
  hasChromeAccess: true,
  users: [PermissionAction.READ],
  availability: FULL_PERMISSION_ACTION_LIST,
  myBookingPages: FULL_PERMISSION_ACTION_LIST,
  allBookingPages: [PermissionAction.READ],
  bookingTemplates: [PermissionAction.READ],
  resources: [PermissionAction.READ],
  teams: [PermissionAction.READ],
  smartAlerts: FULL_PERMISSION_ACTION_LIST,
  smartAlertTemplates: [PermissionAction.READ],
  locations: [PermissionAction.READ],
  journey: [PermissionAction.READ],
  isAccessToAllWorkspaces: false,
  workspaceAdminActions: [],
  createdAt: '',
  updatedAt: '',
} as Role;

export const PERMISSION_LIST = [
  'users',
  //  'availability',
  'myBookingPages',
  'allBookingPages',
  'bookingTemplates',
  'resources',
  'teams',
  'smartAlerts',
  'smartAlertTemplates',
  'locations',
  'journey',
] as const;

export const FULL_PERMISSION_LIST = [...PERMISSION_LIST, 'workspaceAdminActions'] as const;

export const DISABLED_PERMISSIONS = [
  { permission: 'availability', action: [PermissionAction.READ] },
  { permission: 'myBookingPages', action: [PermissionAction.READ, PermissionAction.CREATE] },
  { permission: 'bookingTemplates', action: [PermissionAction.READ] },
  { permission: 'smartAlerts', action: [PermissionAction.READ] },
  { permission: 'smartAlertTemplates', action: [PermissionAction.READ] },
  { permission: 'locations', action: [PermissionAction.READ] },
  { permission: 'journey', action: [PermissionAction.READ] },
];

const activeLabel = t('Users:STATUS_ACTIVE');
const inactiveLabel = t('Users:STATUS_INACTIVE');

export const StatusOptions: SelectItem[] = [
  { value: 'true', label: activeLabel },
  { value: 'false', label: inactiveLabel },
];

export const DEFAULT_FILTER: RoleFilterType = {
  statuses: StatusOptions.map((option) => option.value),
  createdBy: [],
};
