import { useDispatch, useSelector } from 'react-redux';
import {
  DisconnectDescriptions,
  integrationActions,
  integrationLabels,
  integrationSelectors,
  VIDEO_CONFERENCES,
} from '../../store/integration';
import {
  GoogleAnalyticsIntegration,
  GoogleIntegration,
  HubspotIntegration,
  InboxIntegration,
  MicrosoftIntegration,
  SalesforceIntegration,
  ZoomIntegration,
} from '../../components/integration';
import { useEffect, useState } from 'react';
import { Path } from '../../routing';
import labels from './labels';
import { userSettingsActions, userSettingsSelectors } from '../../store/userSettings';
import { AppType, IntegrationType, VideoConferenceType, WorkspaceIntegrationType } from '../../API';
import { ConfirmationModal, Preloader } from '../../components/common';
import { navigationService } from '../../services/NavigationService';
import { generateVideoConference, getIntegrationURLParams } from '../../store/integration/utils';
import { ArrowLeftIcon } from '@heroicons/react/24/outline';
import { Button } from 'primereact/button';
import { workspacesSelectors } from '../../store/workspaces';
import { workspacesActions } from '../../store/workspaces/actions';
import { authenticationSelectors } from '../../store/authentication';

interface IIntegrationWrapper {
  integrationType: IntegrationType | WorkspaceIntegrationType | AppType;
  handleOpenModal: () => void;
}

const IntegrationWrapper = ({ integrationType, handleOpenModal }: IIntegrationWrapper) => {
  switch (integrationType) {
    case IntegrationType.GOOGLE_CALENDAR:
    case IntegrationType.GOOGLE_MEET:
      return <GoogleIntegration integrationType={integrationType} handleDisconnect={handleOpenModal} />;
    case IntegrationType.MICROSOFT_CALENDAR:
    case IntegrationType.MICROSOFT_TEAMS:
      return <MicrosoftIntegration integrationType={integrationType} handleDisconnect={handleOpenModal} />;
    case IntegrationType.ZOOM:
      return <ZoomIntegration integrationType={integrationType} handleDisconnect={handleOpenModal} />;
    case WorkspaceIntegrationType.HUBSPOT:
      return <HubspotIntegration integrationType={integrationType} handleDisconnect={handleOpenModal} />;
    case WorkspaceIntegrationType.SALESFORCE:
      return <SalesforceIntegration integrationType={integrationType} handleDisconnect={handleOpenModal} />;
    case AppType.INBOX:
      return <InboxIntegration />;
    case WorkspaceIntegrationType.GOOGLE_ANALYTICS:
      return <GoogleAnalyticsIntegration integrationType={integrationType} handleDisconnect={handleOpenModal} />
    default:
      return null;
  }
};

export const ConnectIntegrationPage = () => {
  const dispatch = useDispatch();
  const integrationType = useSelector(integrationSelectors.selectConnectIntegration);
  const isUserSettingsFetching = useSelector(userSettingsSelectors.selectIsFetching);
  const isWorkspacesFetching = useSelector(workspacesSelectors.selectIsFetching);
  const isAuthenticationFetching = useSelector(authenticationSelectors.selectIsFetching);
  const isIntegrationFetching = useSelector(integrationSelectors.selectIsFetching);
  const defaultVideoConference = useSelector(userSettingsSelectors.selectDefaultVideoIntegration);
  const [isDisconnectModalOpen, setIsDisconnectModalOpen] = useState(false);
  const integrations = useSelector(userSettingsSelectors.selectIntegrations);
  const isFetching =
    isUserSettingsFetching || isWorkspacesFetching || isAuthenticationFetching || isIntegrationFetching;

  useEffect(() => {
    const { type } = getIntegrationURLParams();

    if (type) {
      // checking if the system was redirected back from Integration site
      dispatch(integrationActions.setConnectIntegrationType(type));
    } else if (!integrationType) {
      // checking if the system even choosen integrationType for show
      handleBack();
    }
  }, [integrationType]);

  const handleDisconnect = (integrationType: IntegrationType | WorkspaceIntegrationType) => {
    // is it Workspace Integration?
    if (Object.values(WorkspaceIntegrationType).includes(integrationType as WorkspaceIntegrationType)) {
      dispatch(workspacesActions.removeIntegrationRequest(integrationType as WorkspaceIntegrationType));
      // or personal integration?
    } else {
      if (defaultVideoConference === (integrationType as unknown as VideoConferenceType)) {
        const newDefaultVideoIntegration =
          integrations &&
          integrations.find(
            (integration) =>
              integration && VIDEO_CONFERENCES.includes(integration.type) && integration.type !== integrationType
          );
        if (newDefaultVideoIntegration) {
          dispatch(
            userSettingsActions.updateUserSettings({
              defaultVideoIntegration: generateVideoConference(newDefaultVideoIntegration.type),
            })
          );
        }
      }
      // Combine these two actions
      dispatch(userSettingsActions.removeIntegration(integrationType));
      dispatch(userSettingsActions.saveUserSettingsRequest());
    }
    setIsDisconnectModalOpen(false);
  };

  const handleOpenModal = (): void => {
    setIsDisconnectModalOpen(true);
  };

  const handleBack = () => {
    navigationService.navigateTo(Path.Integration);
  };

  return (
    <div>
      {isFetching && <Preloader />}

      {integrationType && (
        <>
          <Button className="button-blue button-text-line mb-16px" text onClick={handleBack}>
            <ArrowLeftIcon className="icon-18px" />
            <span className="flex-1 pl-8px">{labels.back}</span>
          </Button>
          <div className="sumo-card-bg flex flex-column pt-28px">
            <div className="text-title-xl-med mb-32px">
              {integrationLabels[integrationType as keyof typeof integrationLabels]}
            </div>
            <IntegrationWrapper integrationType={integrationType} handleOpenModal={handleOpenModal} />
          </div>
        </>
      )}

      {integrationType && (
        <ConfirmationModal
          visible={isDisconnectModalOpen}
          title={labels.disconnectIntegration}
          description={DisconnectDescriptions[integrationType as keyof typeof DisconnectDescriptions]}
          confirmButtonLabel={labels.yesDisconnect}
          cancelButtonLabel={labels.noCancel}
          onConfirm={() => handleDisconnect(integrationType)}
          onCancel={() => setIsDisconnectModalOpen(false)}
          onClose={() => setIsDisconnectModalOpen(false)}
        />
      )}
    </div>
  );
};
