import { useDispatch } from 'react-redux';
import { authenticationActions } from '../../../../store/authentication';
import { Tooltip } from 'primereact/tooltip';
import labels from './labels';

export const ViewAsUserWarningBlock = () => {
  const dispatch = useDispatch();

  return (
    <>
      <Tooltip target=".view-as-user-icon" className="block md:hidden lg:block xl:hidden " />
      <div
        className="view-as-user-icon bg-pink-200 border-round mb-3 p-2 flex align-items-center"
        data-pr-tooltip={labels.viewingSumo1}
      >
        <span className="pi pi-exclamation-triangle text-xl lg:text-xl md:text-base xl:text-base " />
        <span className="ml-1 hidden lg:hidden md:block xl:block">{labels.viewingSumo1} </span>
        <span
          className="ml-1 font-semibold hover:underline cursor-pointer"
          onClick={() => {
            dispatch(authenticationActions.resetViewAsUserRequest({ redirect: true, removeLicenseType: true }));
          }}
        >
          {labels.backToOPSConsole}
        </span>
      </div>
    </>
  );
};
