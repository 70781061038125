import { CodeResponse } from '@react-oauth/google';
import { MicrosoftCodeResponse } from '../../../store/authentication';
import { Button } from 'primereact/button';
import labels from './labels';
import { useSelector } from 'react-redux';
import { userSettingsSelectors } from '../../../store/userSettings';
import { CheckIcon } from '@heroicons/react/24/outline';
import { ReactNode } from 'react';

interface ICalendarGroup {
  title: string;
  items: Array<{
    calendarIcon: ReactNode;
    calendarTitle: string;
    calendarDescription: string;
    isCalendarConnected: boolean;
    disconnectCalendar: () => void;
    AuthButton: React.ElementType;
    handleAuthSuccess?: (codeResponse: CodeResponse) => void;
    handleAuthRedirect?: (codeResponse: MicrosoftCodeResponse) => void;
  }>;
  email: string;
}
export const CalendarGroup = ({ title, items, email }: ICalendarGroup) => {
  const isFetching = useSelector(userSettingsSelectors.selectIsFetching);
  const isMicrosoftCalendarConnected = useSelector(userSettingsSelectors.selectIsMicrosoftCalendarConnected);
  const isGoogleCalendarConnected = useSelector(userSettingsSelectors.selectIsGoogleCalendarConnected);

  return (
    <div className="pt-24px">
      <div className="text-title-s-med mb-10px">{title}</div>
      {items.map((item, index) => (
        <div key={index} className="flex-between p-20px border-1 border-heavy-20">
          <div className="flex flex-column gap-16px">
            <div className="flex-left-center gap-12px">
              {item.calendarIcon}
              <div className="flex flex-column gap-4px">
                <div className="text-title-xs-med text-heavy-80">{item.calendarTitle}</div>
                {item.isCalendarConnected && <div className="text-label-xs-reg text-heavy-60">{email}</div>}
              </div>
              {item.isCalendarConnected && (
                <div className="flex-left-center text-cyan-main pl-10px">
                  <CheckIcon className="icon-18px" />
                  <div className="text-label-s-med">{labels.connected}</div>
                </div>
              )}
            </div>
            <div className="text-body-s-reg text-heavy-60">{item.calendarDescription}</div>
          </div>
          <div>
            {item.isCalendarConnected ? (
              <Button
                className="button-xl"
                outlined
                label={labels.disconnect}
                disabled={isFetching}
                onClick={item.disconnectCalendar}
              />
            ) : (
              <item.AuthButton
                className="button-xl"
                hideIcon
                outlined
                label={labels.connect}
                rounded={true}
                disabled={isFetching || isMicrosoftCalendarConnected || isGoogleCalendarConnected}
                onSuccess={item.handleAuthSuccess}
                onRedirect={item.handleAuthRedirect}
              />
            )}
          </div>
        </div>
      ))}
    </div>
  );
};
