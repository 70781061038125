import { t } from '../../i18n/i18n';

export default {
  title: t('SmartAlerts:TITLE'),
  search: t('Common:SEARCH'),
  delete: t('Common:DELETE'),
  type: t('Common:TYPE'),
  user: t('Common:USER'),
  status: t('Common:STATUS'),
  createAlert: t('SmartAlerts:CREATE_ALERT'),
  emptyList: t('Common:EMPTY_LIST'),
  emptyListDesc: t('SmartAlerts:EMPTY_LIST_MESSAGE'),
  sharedLabel: t('SmartAlerts:SHARED_LABEL'),
  templatesLabel: t('SmartAlerts:TEMPLATES_LABEL'),
  yes: t('Common:YES'),
  no: t('Common:NO'),
};
