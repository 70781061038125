import { handleAPIRequest } from '../utils/reduxUtils';
import { API_PUBLIC } from '../../types/constants';
import { SmartAlertResponse, SmartAlertTemplateResponse, SmartTypesResponse, UpsertSmartAlertResponse } from './types';
import { CreateSmartAlertInput, CreateSmartAlertTemplateInput } from '../../API';

// ***
// When you change the requests do not forget to specify new data
// in docs/Endpoints.md
// ***

export const getSmartTypesByWorkspaceId = async (workspaceId: string): Promise<SmartTypesResponse> => {
  return handleAPIRequest<SmartTypesResponse>(`${API_PUBLIC}/getSmartTypesByWorkspaceId`, { workspaceId });
};

export const getSmartAlertById = async (id: string): Promise<SmartAlertResponse> => {
  return handleAPIRequest<SmartAlertResponse>(`${API_PUBLIC}/getSmartAlertById`, { id });
};

export const getSmartAlertTemplateById = async (id: string): Promise<SmartAlertTemplateResponse> => {
  return handleAPIRequest<SmartAlertTemplateResponse>(`${API_PUBLIC}/getSmartAlertTemplateById`, { id });
};

export const upsertSmartAlert = async (
  smartAlert: CreateSmartAlertInput,
  bookingPageIds: string[] | null,
  bookingTemplateIds: string[] | null
): Promise<UpsertSmartAlertResponse> => {
  return handleAPIRequest<UpsertSmartAlertResponse>(`${API_PUBLIC}/upsertSmartAlert`, {
    smartAlert,
    bookingPageIds,
    bookingTemplateIds,
  });
};

export const upsertSmartAlertTemplate = async (smartAlertTemplate: CreateSmartAlertTemplateInput): Promise<void> => {
  return handleAPIRequest<void>(`${API_PUBLIC}/upsertSmartAlertTemplate`, { smartAlertTemplate });
};

export const deleteSmartAlert = async (ids: string[]): Promise<void> => {
  return handleAPIRequest<void>(`${API_PUBLIC}/deleteSmartAlert`, { ids });
};

export const deleteSmartAlertTemplate = async (ids: string[]): Promise<void> => {
  return handleAPIRequest<void>(`${API_PUBLIC}/deleteSmartAlertTemplate`, { ids });
};
