import { useEffect } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { PublicBookingPageContainer, PublicBookingPageSkeleton } from '../../components/publicBookingPage';
import { EventSteps, eventActions, eventSelectors } from '../../store/publicBookingPage';

export const PublicBookingPage = () => {
  const { bookingPageId, eventId, journeyId } = useParams();
  const dispatch = useDispatch();
  const agendaLoaded = useSelector(eventSelectors.selectAgendaLoaded);
  const error = useSelector(eventSelectors.selectError);

  const [searchParams] = useSearchParams();
  const hideHeader = searchParams.get('hideHeader') === 'true';
  const hideCookie = searchParams.get('hideCookie') === 'true';
  const hidePageDetails = searchParams.get('hidePageDetails') === 'true';
  const iframe = searchParams.get('iframe') === 'true';
  const editKey = searchParams.get('editKey');

  useEffect(() => {
    if (eventId) {
      dispatch(eventActions.setEventId(eventId));
      dispatch(eventActions.setEventStep(EventSteps.BOOKED));
      // searchParams.get('cancel') === 'true' && dispatch(eventActions.setEventStep(EventSteps.CANCEL));
    } else if (bookingPageId) {
      dispatch(eventActions.setBookingPageId(bookingPageId));
    } else if (journeyId) {
      dispatch(eventActions.setJourneyId(journeyId));
    }
    dispatch(
      eventActions.setViewSettings({
        editKey,
        hideHeader,
        hideCookie,
        hidePageDetails,
        iframe,
      })
    );
    dispatch(eventActions.getAgendaRequest());
  }, []);

  return agendaLoaded || error ? <PublicBookingPageContainer /> : <PublicBookingPageSkeleton />;
};
