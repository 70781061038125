import { t } from '../../../i18n/i18n';

export default {
  save: t('Common:SAVE'),
  cancel: t('Common:CANCEL'),
  miles: t('PublicBookingPage:MILES'),
  kilometers: t('PublicBookingPage:KILOMETERS'),
  locationsPlaceholder: t('PublicBookingPage:LOCATIONS_PLACEHOLDER'),
  learnMore: t('PublicBookingPage:LEARN_MORE'),
  locationAccessTooltip: t('PublicBookingPage:LOCATION_ACCESS_TOOLTIP'),
};
