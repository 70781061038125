import { combineReducers } from 'redux';
import { UserSettingsActionTypes, UserSettingsAction } from './actions';
import { CreateRoleInput, IntegrationInput, Role, StatisticsInput, UserSettingsInput } from '../../API';
import { GlobalAction, GlobalActionTypes } from '../global/actions';
import { UserDetails } from './types';

const integrations = (state: IntegrationInput[] = [], action: UserSettingsAction | GlobalAction) => {
  switch (action.type) {
    case UserSettingsActionTypes.ADD_INTEGRATION:
    case UserSettingsActionTypes.CONNECT_ZOOM_SUCCESS:
      return [...state, action.payload];
    case UserSettingsActionTypes.REMOVE_INTEGRATION:
      return state.filter((integration) => integration.type !== action.payload);
    case GlobalActionTypes.RESET_WHOLE_STORE:
      return [];
    default:
      return state;
  }
};

const userSettings = (
  state: UserSettingsInput = {} as UserSettingsInput,
  action: UserSettingsAction | GlobalAction
) => {
  switch (action.type) {
    case UserSettingsActionTypes.GET_USER_SETTINGS_SUCCESS:
      return action.payload;
    case UserSettingsActionTypes.UPDATE_USER_SETTINGS:
      return { ...state, ...action.payload };
    case UserSettingsActionTypes.ADD_INTEGRATION:
    case UserSettingsActionTypes.REMOVE_INTEGRATION:
    case UserSettingsActionTypes.CONNECT_ZOOM_SUCCESS:
      return { ...state, integrations: integrations(state.integrations as IntegrationInput[], action) };
    case UserSettingsActionTypes.UPLOAD_AVATAR_FILE_SUCCESS:
      return { ...state, avatar: action.payload };
    case UserSettingsActionTypes.CLEAR_AVATAR_FILE_SUCCESS:
      return { ...state, avatar: '' };
    case GlobalActionTypes.RESET_WHOLE_STORE:
      return {} as UserSettingsInput;
    default:
      return state;
  }
};

const statistics = (state = {} as StatisticsInput, action: UserSettingsAction | GlobalAction) => {
  switch (action.type) {
    case UserSettingsActionTypes.SET_STATISTICS:
      return action.payload;
    case GlobalActionTypes.RESET_WHOLE_STORE:
      return {} as StatisticsInput;
    default:
      return state;
  }
};

const lastLoadTime = (state = 0, action: UserSettingsAction | GlobalAction) => {
  switch (action.type) {
    case UserSettingsActionTypes.GET_USER_SETTINGS_SUCCESS:
      return new Date().getTime();
    case GlobalActionTypes.RESET_WHOLE_STORE:
      return 0;
    default:
      return state;
  }
};

const isFetching = (state = false, action: UserSettingsAction | GlobalAction) => {
  switch (action.type) {
    case UserSettingsActionTypes.GET_USER_SETTINGS_REQUEST:
    case UserSettingsActionTypes.CONNECT_EXTERNAL_CALENDAR_REQUEST:
    case UserSettingsActionTypes.CONNECT_ZOOM_REQUEST:
      return true;
    case UserSettingsActionTypes.GET_USER_SETTINGS_SUCCESS:
    case UserSettingsActionTypes.GET_USER_SETTINGS_FAIL:
    case UserSettingsActionTypes.CONNECT_EXTERNAL_CALENDAR_SUCCESS:
    case UserSettingsActionTypes.CONNECT_EXTERNAL_CALENDAR_FAIL:
    case UserSettingsActionTypes.CONNECT_ZOOM_SUCCESS:
    case UserSettingsActionTypes.CONNECT_ZOOM_FAIL:
      return false;
    case GlobalActionTypes.RESET_WHOLE_STORE:
      return false;
    default:
      return state;
  }
};
const error = (state: unknown | null = null, action: UserSettingsAction | GlobalAction) => {
  switch (action.type) {
    case UserSettingsActionTypes.GET_USER_SETTINGS_REQUEST:
    case UserSettingsActionTypes.SAVE_USER_SETTINGS_REQUEST:
    case UserSettingsActionTypes.CONNECT_EXTERNAL_CALENDAR_REQUEST:
    case UserSettingsActionTypes.CONNECT_ZOOM_REQUEST:
    case UserSettingsActionTypes.SAVE_USER_SETTINGS_NO_TOAST_REQUEST:
      return null;
    case UserSettingsActionTypes.GET_USER_SETTINGS_FAIL:
    case UserSettingsActionTypes.SAVE_USER_SETTINGS_FAIL:
    case UserSettingsActionTypes.CONNECT_EXTERNAL_CALENDAR_FAIL:
    case UserSettingsActionTypes.CONNECT_ZOOM_FAIL:
    case UserSettingsActionTypes.SAVE_USER_SETTINGS_NO_TOAST_FAIL:
      return action.error;
    case GlobalActionTypes.RESET_WHOLE_STORE:
      return null;
    default:
      return state;
  }
};

const details = (state = {} as UserDetails, action: UserSettingsAction | GlobalAction) => {
  switch (action.type) {
    case UserSettingsActionTypes.SET_USER_DETAILS:
      return action.payload;
    case UserSettingsActionTypes.UPDATE_USER_DETAILS:
      return { ...state, ...action.payload };
    case GlobalActionTypes.RESET_WHOLE_STORE:
      return {} as UserDetails;
    default:
      return state;
  }
};

const userSettingsReceived = (state = false, action: UserSettingsAction | GlobalAction) => {
  switch (action.type) {
    case UserSettingsActionTypes.GET_USER_SETTINGS_SUCCESS:
      return true;
    case GlobalActionTypes.RESET_WHOLE_STORE:
      return false;
    default:
      return state;
  }
};

const UTC = (state = 0, action: UserSettingsAction | GlobalAction) => {
  switch (action.type) {
    case UserSettingsActionTypes.SET_UTC:
      return action.payload;
    case GlobalActionTypes.RESET_WHOLE_STORE:
      return 0;
    default:
      return state;
  }
};

const permissions = (state = {} as CreateRoleInput, action: UserSettingsAction | GlobalAction) => {
  switch (action.type) {
    case UserSettingsActionTypes.SET_USER_PERMISSIONS:
      return action.payload;
    case GlobalActionTypes.RESET_WHOLE_STORE:
      return {} as Role;
    default:
      return state;
  }
};

export default combineReducers({
  userSettings,
  isFetching,
  error,
  details,
  userSettingsReceived,
  UTC,
  permissions,
  statistics,
  lastLoadTime,
});
