import { useDispatch, useSelector } from 'react-redux';
import {
  DEFAULT_EVENT_FILTER,
  EVENT_FILTER_OPTIONS,
  getResponseLabel,
  JOURNEY_RESPONSES_CSV_HEADERS,
  journeysActions,
  journeysSelectors,
} from '../../../store/journeyBuilder';
import { FilterDateRange, SectionHeader } from '../../common';
import { Path } from '../../../routing';
import { ReactComponent as NoItemsImage } from '../../../assets/images/noConnectionLarge.svg';
import labels from './labels';
import { ArrowDownTrayIcon, ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/24/outline';
import { Button } from 'primereact/button';
import { JourneyDestinationType, JourneyEvent } from '../../../API';
import dayjs from 'dayjs';
import { useState } from 'react';
import { MultiSelect } from 'primereact/multiselect';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { formatDateTimeJourney, formatDateTimeThroughGap } from '../../../services/DateService';
import { JourneyEventItem } from '../journeyEventItem/JourneyEventItem';
import { downloadCSV } from '../../../services/utils';
import { DateFormat, TimeFormat, userSettingsSelectors } from '../../../store/userSettings';
import { bookingPageSelectors } from '../../../store/bookingPages';

export const JourneyResponses = () => {
  const dispatch = useDispatch();
  const isFetching = useSelector(journeysSelectors.selectIsFetching);
  const journeyEvents = useSelector(journeysSelectors.selectJourneyEvents);
  const searchedEvents = useSelector(journeysSelectors.selectSearchedEvents);

  const eventFilter = useSelector(journeysSelectors.selectEventFilter);
  const eventFilterDateRange = useSelector(journeysSelectors.selectEventFilterDateRange);
  const filterDateRangeLabel = useSelector(journeysSelectors.selectetFilterDateRangeLabel);
  const isFilterActive = useSelector(journeysSelectors.selectIsFilterActive);
  const bookingPagesById = useSelector(bookingPageSelectors.selectBookingPagesById);

  const dateFormat = useSelector(userSettingsSelectors.selectDateFormat) || DateFormat.default;
  const timeFormat = useSelector(userSettingsSelectors.selectTimeFormat) || TimeFormat.default;

  const [showCalendar, setShowCalendar] = useState(false);
  const [activeIndexes, setActiveIndexes] = useState<string[]>([]);

  const resetFilters = () => {
    dispatch(journeysActions.setEventFilter(DEFAULT_EVENT_FILTER));
  };

  const handleResponseTypeSelect = (response: JourneyDestinationType[]) => {
    dispatch(journeysActions.setEventFilter({ response }));
  };

  const handleDateRangeSelect = (value?: string | Date | Date[] | null) => {
    if (Array.isArray(value)) {
      dispatch(
        journeysActions.setEventFilter({
          dateRange: value.map((date) => (date ? dayjs(date).format('YYYY-MM-DD') : null)),
        })
      );
    } else {
      dispatch(journeysActions.setEventFilter({ dateRange: [] }));
    }
  };

  const dateRangeTemplate = (
    <div className="flex-center">
      {labels.dateRange}
      {filterDateRangeLabel && <div className="sumo-badge ml-6px">{filterDateRangeLabel}</div>}
    </div>
  );

  const handleTabChange = (eventId: string) => {
    setActiveIndexes((prevIndexes) =>
      prevIndexes.includes(eventId) ? prevIndexes.filter((el) => el !== eventId) : [...prevIndexes, eventId]
    );
  };

  const getStepsData = (event: JourneyEvent) => {
    const answers: string[] = [];
    let destination = '';
    event.steps?.forEach((step) => {
      if (step?.page) {
        if (step.page.cards?.length) {
          answers.push(labels.card + ' - ' + (step.page.cards[0]?.title?.replaceAll(',', ' ') || ''));
        } else {
          step.page.formFields?.forEach((field) =>
            answers.push(
              (field?.label?.replaceAll(',', ' ') || '') +
                ' - ' +
                ((field?.value && field?.value[0]?.replaceAll(',', ' ')) || '')
            )
          );
        }
      } else {
        switch (step?.destination?.type) {
          case JourneyDestinationType.MESSAGE:
            destination = step?.destination.message?.header + ' - ' + step?.destination.message?.body;
            break;
          case JourneyDestinationType.EXTERNAL_URL:
            destination = step?.destination.externalURL || '';
            break;
          case JourneyDestinationType.BOOKING_PAGE:
            destination = bookingPagesById[step.destination.bookingPageId || '']?.what?.customName || '';
            break;
        }
      }
    });
    return [answers.join(';'), destination];
  };

  // utility to Convert Journey Events Array to CSV
  const convertArrayToCSV = (): string => {
    const rows = searchedEvents.map((event) => {
      const responseType = (event.steps?.length && event.steps[event.steps?.length - 1]?.destination?.type) || null;
      return [
        formatDateTimeThroughGap(event.createdAt, dateFormat, timeFormat),
        getResponseLabel(responseType),
        ...getStepsData(event),
      ].join(',');
    });

    return [JOURNEY_RESPONSES_CSV_HEADERS.join(',').toUpperCase(), ...rows].join('\n');
  };

  return (
    <div className="flex-center py-20px px-32px">
      <div className="sumo-card-bg flex flex-column pt-16px" style={{ width: 'min(100%, 864px)' }}>
        <SectionHeader
          title={labels.title}
          itemsLength={journeyEvents.length}
          searchPaths={[Path.EditJourney]}
          hideButton
          filters={[
            {
              label: labels.response,
              value: eventFilter.response,
              options: EVENT_FILTER_OPTIONS,
              onChange: (e) => handleResponseTypeSelect(e.target.value),
            },
          ]}
          onFilterReset={resetFilters}
          isFilterActive={isFilterActive}
          customFilter={
            <MultiSelect
              itemTemplate={dateRangeTemplate}
              selectedItemTemplate={dateRangeTemplate}
              onShow={() => setShowCalendar(true)}
              pt={{ panel: { className: 'hidden' } }}
            />
          }
          extraContent={
            <Button
              className="flex-none button-xl button-icon-xl button-white"
              onClick={() => downloadCSV(convertArrayToCSV(), 'journeyResponses.csv')}
            >
              <ArrowDownTrayIcon className="icon-24px" />
            </Button>
          }
        />
        <FilterDateRange
          showCalendar={showCalendar}
          setShowCalendar={setShowCalendar}
          selectedRange={eventFilterDateRange}
          handleDateChange={handleDateRangeSelect}
        />

        {!isFetching && !searchedEvents.length && (
          <div className="flex-center flex-column">
            <NoItemsImage width={600} height={400} />
            {!journeyEvents.length ? (
              <div className="text-title-md-med text-heavy-80">{labels.noResponses}</div>
            ) : (
              <>
                <div className="text-title-md-med text-heavy-80">{labels.emptyList}</div>
                <div className="text-body-s-reg text-heavy-60">{labels.emptyListDesc}</div>
              </>
            )}
          </div>
        )}

        {!!searchedEvents.length &&
          searchedEvents.map((event, index) => {
            const responseType =
              (event.steps?.length && event.steps[event.steps?.length - 1]?.destination?.type) || null;
            return (
              <Accordion
                className={`sumo-card border-none ${index > 0 ? 'mt-16px' : ''}`}
                key={event.id}
                activeIndex={activeIndexes.includes(event.id) ? 0 : null}
                onTabChange={() => handleTabChange(event.id)}
              >
                <AccordionTab
                  contentClassName="px-20px"
                  header={
                    <div className="flex-left-center px-20px py-8px">
                      <div className="flex-1 flex flex-column pb-4px">
                        <div className="text-title-s-med text-heavy-100">{formatDateTimeJourney(event.createdAt)}</div>
                        <div
                          className={`text-body-s-reg ${
                            responseType === JourneyDestinationType.BOOKING_PAGE ? 'text-egg-blue-dark' : ''
                          }`}
                        >
                          {getResponseLabel(responseType)}
                        </div>
                      </div>
                      <div className="flex-none">
                        {activeIndexes.includes(event.id) ? (
                          <ChevronUpIcon className="icon-20px" />
                        ) : (
                          <ChevronDownIcon className="icon-20px" />
                        )}
                      </div>
                    </div>
                  }
                >
                  <JourneyEventItem event={event} />
                </AccordionTab>
              </Accordion>
            );
          })}
      </div>
    </div>
  );
};
