import { ModalName, modalsActionsFactory, modalsSelectorsFactory } from '../modals';

export const DELETE_WORKSPACE_NAME: ModalName = 'deleteWorkspaceNameModal';
export const deleteWorkspaceNameModalActions = modalsActionsFactory(DELETE_WORKSPACE_NAME);
export const deleteWorkspaceNameModalSelectors = modalsSelectorsFactory(DELETE_WORKSPACE_NAME);

export const CANNOT_DELETE_WORKSPACE_NAME: ModalName = 'cannotDeleteWorkspaceNameModal';
export const cannotDeleteWorkspaceNameModalActions = modalsActionsFactory(CANNOT_DELETE_WORKSPACE_NAME);
export const cannotDeleteWorkspaceNameModalSelectors = modalsSelectorsFactory(CANNOT_DELETE_WORKSPACE_NAME);

export const CLONE_WORKSPACE_NAME: ModalName = 'cloneWorkspaceModal';
export const cloneWorkspaceModalActions = modalsActionsFactory(CLONE_WORKSPACE_NAME);
export const cloneWorkspaceModalSelectors = modalsSelectorsFactory(CLONE_WORKSPACE_NAME);
