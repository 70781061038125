import {
  BulkActionRow,
  CardSkeleton,
  ConfirmationModal,
  EmptyListScreen,
  SectionHeader,
} from '../../components/common';
import { Path } from '../../routing';
import labels from './labels';
import { useDispatch, useSelector } from 'react-redux';
import {
  DEFAULT_FILTER,
  DeleteSmartTypeModalRequest,
  deleteSmartTypesModalActions,
  deleteSmartTypesModalSelectors,
  SMART_ALERT_STATUS_OPTIONS,
  SMART_ALERT_TYPE_OPTIONS,
  smartAlertsActions,
  smartAlertsSelectors,
} from '../../store/smartAlerts';
import { useEffect } from 'react';
import { SmartAlertCard, SmartAlertCloneModal } from '../../components/smartAlerts';
import { authenticationSelectors } from '../../store/authentication';
import { MultiSelectChangeEvent } from 'primereact/multiselect';
import { usersSelectors } from '../../store/users';
import { TrashIcon, UsersIcon, ViewColumnsIcon } from '@heroicons/react/24/outline';
import { Button } from 'primereact/button';
import { navigationService } from '../../services/NavigationService';
import { userSettingsSelectors } from '../../store/userSettings';
import { getDeleteModalDescription, getDeleteModalWarning } from '../../store/smartAlerts/utils';
import { SKELETON_LENGTH } from '../../types/constants';

export const SmartAlerts = () => {
  const dispatch = useDispatch();
  const userId = useSelector(authenticationSelectors.selectUserId);
  const isSpinnerFetching = useSelector(smartAlertsSelectors.selectIsSpinnerFetching);
  const isSkeletonFetching = useSelector(smartAlertsSelectors.selectIsSkeletonFetching);
  const itemsToShowLength = useSelector(smartAlertsSelectors.selectItemsToShowLength);
  const personalSmartAlerts = useSelector(smartAlertsSelectors.selectPersonalSearched);
  const sharedSmartAlerts = useSelector(smartAlertsSelectors.selectSharedSearched);
  const templates = useSelector(smartAlertsSelectors.selectSearchedAlertTemplates);
  const usersOptions = useSelector(usersSelectors.selectLoggedUsersInCurrentWorkspaceOptions);
  const users = useSelector(smartAlertsSelectors.selectFilterUsers);
  const types = useSelector(smartAlertsSelectors.selectFilterTypes);
  const status = useSelector(smartAlertsSelectors.selectFilterStatus);
  const isFilterActive = useSelector(smartAlertsSelectors.selectIsFilterInUse);
  const isDefaultFilter = useSelector(smartAlertsSelectors.selectIsDefaultFilter);
  const selectedAlerts = useSelector(smartAlertsSelectors.selectSelectedAlerts);

  const isCreate = useSelector(userSettingsSelectors.selectSmartAlertsCreate);
  const isReadTemplates = useSelector(userSettingsSelectors.selectSmartAlertTemplatesRead);
  const isBulkDelete = useSelector(smartAlertsSelectors.selectIsBulkDeleteAvail);
  const isDeleteModalOpen = useSelector(deleteSmartTypesModalSelectors.selectIsModalOpen);
  const modalRequest: DeleteSmartTypeModalRequest = useSelector(
    deleteSmartTypesModalSelectors.selectModalRequest
  ) as DeleteSmartTypeModalRequest;

  useEffect(() => {
    dispatch(smartAlertsActions.getSmartTypesRequest());
    if (isDefaultFilter) {
      dispatch(smartAlertsActions.setFilter({ ...DEFAULT_FILTER, userIds: [userId] }));
    }
  }, []);

  const handleCreateAlerts = () => {
    navigationService.navigateTo(Path.SmartAlert.replace(':smartType', 'alert').replace(':smartAlertId', 'new'));
  };

  const handleBulkClose = () => {
    dispatch(smartAlertsActions.selectAlerts([]));
  };

  const handleBulkDelete = () => {
    dispatch(deleteSmartTypesModalActions.openModal({ selectedAlerts } as DeleteSmartTypeModalRequest));
  };

  const bulkContent = (
    <>
      {isBulkDelete && (
        <Button text className="text-button-md-med" onClick={handleBulkDelete}>
          <TrashIcon width={18} height={18} className="mr-8px" />
          {labels.delete}
        </Button>
      )}
    </>
  );

  const resetFilters = () => {
    dispatch(smartAlertsActions.setFilter({ ...DEFAULT_FILTER, userIds: [userId] }));
  };

  const handleSelectUsers = (e: MultiSelectChangeEvent) => {
    dispatch(smartAlertsActions.setFilter({ userIds: e.target.value }));
  };

  const handleSelectTypes = (e: MultiSelectChangeEvent) => {
    dispatch(smartAlertsActions.setFilter({ types: e.target.value }));
  };

  const handleSelectStatus = (e: MultiSelectChangeEvent) => {
    dispatch(smartAlertsActions.setFilter({ status: e.target.value }));
  };

  const handleClose = () => {
    dispatch(deleteSmartTypesModalActions.closeModal());
  };

  const handleConfirmDelete = () => {
    dispatch(smartAlertsActions.deleteSmartTypesRequest(modalRequest.selectedAlerts));
    handleClose();
  };

  return (
    <>
      <BulkActionRow selectedCount={selectedAlerts.length} handleClose={handleBulkClose} actionsContent={bulkContent} />
      <SmartAlertCloneModal />
      <ConfirmationModal
        visible={isDeleteModalOpen}
        title={labels.delete}
        description={getDeleteModalDescription(modalRequest?.selectedAlerts || [], modalRequest?.name)}
        additionalText={getDeleteModalWarning(modalRequest?.selectedAlerts || [])}
        confirmButtonLabel={labels.yes}
        cancelButtonLabel={labels.no}
        onConfirm={handleConfirmDelete}
        onCancel={handleClose}
        onClose={handleClose}
      />

      <div className="sumo-card-bg flex flex-column pt-16px">
        <SectionHeader
          title={labels.title}
          loading={isSpinnerFetching}
          itemsLength={itemsToShowLength}
          searchPaths={[Path.SmartAlerts, Path.SmartAlert]}
          buttonLabel={labels.createAlert}
          onButtonClick={handleCreateAlerts}
          filters={[
            { label: labels.user, value: users, options: usersOptions, onChange: handleSelectUsers },
            { label: labels.type, value: types, options: SMART_ALERT_TYPE_OPTIONS, onChange: handleSelectTypes },
            { label: labels.status, value: status, options: SMART_ALERT_STATUS_OPTIONS, onChange: handleSelectStatus },
          ]}
          onFilterReset={resetFilters}
          isFilterActive={isFilterActive}
          hideButton={!isCreate}
        />

        <div className="flex flex-column gap-40px">
          {isSkeletonFetching ? (
            <div className="flex flex-wrap -m-8px">
              {[...Array(SKELETON_LENGTH)].map((value, index) => (
                <div className="w-12 lg:w-6 p-8px" key={index}>
                  <CardSkeleton hideDetails hideOptions />
                </div>
              ))}
            </div>
          ) : personalSmartAlerts.length || sharedSmartAlerts.length || (templates.length && isReadTemplates) ? (
            <>
              {!!personalSmartAlerts.length && (
                <div className="flex flex-wrap -m-8px">
                  {personalSmartAlerts.map((alert) => (
                    <div className="w-12 lg:w-6 p-8px" key={alert.id}>
                      <SmartAlertCard alert={alert} />
                    </div>
                  ))}
                </div>
              )}

              {!!sharedSmartAlerts.length && (
                <div className="flex flex-wrap -m-8px">
                  <div className="w-12 p-8px flex-left-center gap-6px text-label-s-med text-heavy-60">
                    <UsersIcon className="icon-20px" />
                    <div>{labels.sharedLabel}</div>
                  </div>
                  {sharedSmartAlerts.map((alert) => (
                    <div className="w-12 lg:w-6 p-8px" key={alert.id}>
                      <SmartAlertCard alert={alert} />
                    </div>
                  ))}
                </div>
              )}

              {!!templates.length && isReadTemplates && (
                <div className="flex flex-wrap -m-8px">
                  <div className="w-12 p-8px flex-left-center gap-6px text-label-s-med text-heavy-60">
                    <ViewColumnsIcon className="icon-20px" />
                    <div>{labels.templatesLabel}</div>
                  </div>
                  {templates.map((template) => (
                    <div className="w-12 lg:w-6 p-8px" key={template.id}>
                      <SmartAlertCard template={template} />
                    </div>
                  ))}
                </div>
              )}
            </>
          ) : (
            <div className="w-12">
              <EmptyListScreen description={labels.emptyListDesc} />
            </div>
          )}
        </div>
      </div>
    </>
  );
};
