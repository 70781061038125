import { useState } from 'react';
import { Checkbox, CheckboxChangeEvent } from 'primereact/checkbox';
import { UserSettingsKeys } from '../../../store/userSettings';
import labels from './labels';

export const CookieConsent = () => {
  const localCookies = localStorage.getItem(UserSettingsKeys.COOKIES);

  const [cookies, setCookies] = useState(
    localCookies
      ? JSON.parse(localCookies)
      : {
          necessary: true,
          performance: true,
          functional: true,
          targeting: true,
        }
  );

  const handleChange = (event: CheckboxChangeEvent) => {
    setCookies({ ...cookies, [event.target.value]: event.target.checked });
    localStorage.setItem(UserSettingsKeys.COOKIES, JSON.stringify(cookies));
  };

  // do not open cookie automatically in v1, used only in account
  // useEffect(() => {
  //   const storedCookies = localStorage.getItem(UserSettingsKeys.COOKIES);
  //   if (!storedCookies) {
  //     dispatch(authenticationActions.setCookieOpen(true));
  //   } else {
  //     setCookies(JSON.parse(storedCookies));
  //   }
  // }, []);

  return (
    <div className="text-heavy-80">
      <div className="text-title-xs-med text-heavy-100 mb-12px">{labels.manageTitle}</div>

      <div className="flex">
        <div className="flex-1 text-body-s-reg">
          {labels.descriptionFirst}
          <br />
          <br />
          {labels.descriptionSecond}
          <br />
          <br />
          {labels.descriptionThird}
        </div>
        <div className="flex flex-column gap-12px px-40px text-body-lg-reg">
          <div className="flex-left-center gap-8px">
            <Checkbox
              inputId="necessary"
              name="option"
              value="necessary"
              checked={cookies.necessary}
              onChange={handleChange}
              disabled={true}
            />
            <label htmlFor="necessary">{labels.strictlyName}</label>
          </div>
          <div className="flex-left-center gap-8px">
            <Checkbox
              inputId="performance"
              name="option"
              value="performance"
              checked={cookies.performance}
              onChange={handleChange}
            />
            <label htmlFor="performance">{labels.performanceName}</label>
          </div>
          <div className="flex-left-center gap-8px">
            <Checkbox
              inputId="functional"
              name="option"
              value="functional"
              checked={cookies.functional}
              onChange={handleChange}
            />
            <label htmlFor="functional">{labels.functionalName}</label>
          </div>
          <div className="flex-left-center gap-8px">
            <Checkbox
              inputId="targeting"
              name="option"
              value="targeting"
              checked={cookies.targeting}
              onChange={handleChange}
            />
            <label htmlFor="targeting">{labels.targetingName}</label>
          </div>
        </div>
      </div>
    </div>
  );
};
