import { GetUserDetailsRequest, UserDetails } from './types';

export enum UsersActionTypes {
  GET_USERS_REQUEST = 'opsConsoleUsers/GET_USERS_REQUEST',
  GET_USERS_SUCCESS = 'opsConsoleUsers/GET_USERS_SUCCESS',
  GET_USERS_FAIL = 'opsConsoleUsers/GET_USERS_FAIL',
  SET_SEARCH_STRING = 'opsConsoleUsers/SET_SEARCH_STRING',
  GET_USER_DETAILS_REQUEST = 'opsConsoleUsers/GET_USER_DETAILS_REQUEST',
  GET_USER_DETAILS_SUCCESS = 'opsConsoleUsers/GET_USER_DETAILS_SUCCESS',
  GET_USER_DETAILS_FAIL = 'opsConsoleUsers/GET_USER_DETAILS_FAIL',
  SET_USER_RECORD = 'opsConsoleUsers/SET_USER_RECORD',
  CHANGE_USER_STATUS_REQUEST = 'opsConsoleUsers/CHANGE_USER_STATUS_REQUEST',
  CHANGE_USER_STATUS_SUCCESS = 'opsConsoleUsers/CHANGE_USER_STATUS_SUCCESS',
  CHANGE_USER_STATUS_FAIL = 'opsConsoleUsers/CHANGE_USER_STATUS_FAIL',
  DELETE_USER_REQUEST = 'opsConsoleUsers/DELETE_USER_REQUEST',
  DELETE_USER_SUCCESS = 'opsConsoleUsers/DELETE_USER_SUCCESS',
  DELETE_USER_FAIL = 'opsConsoleUsers/DELETE_USER_FAIL',
}

export type UsersAction =
  | { type: UsersActionTypes.GET_USERS_REQUEST }
  | { type: UsersActionTypes.GET_USERS_SUCCESS; payload: UserDetails[] }
  | { type: UsersActionTypes.GET_USERS_FAIL; error: unknown }
  | { type: UsersActionTypes.SET_SEARCH_STRING; payload: string }
  | { type: UsersActionTypes.GET_USER_DETAILS_REQUEST; payload: GetUserDetailsRequest }
  | { type: UsersActionTypes.GET_USER_DETAILS_SUCCESS; payload: UserDetails }
  | { type: UsersActionTypes.GET_USER_DETAILS_FAIL; error: unknown }
  | { type: UsersActionTypes.SET_USER_RECORD; payload: UserDetails }
  | { type: UsersActionTypes.CHANGE_USER_STATUS_REQUEST }
  | { type: UsersActionTypes.CHANGE_USER_STATUS_SUCCESS }
  | { type: UsersActionTypes.CHANGE_USER_STATUS_FAIL; error: unknown }
  | { type: UsersActionTypes.DELETE_USER_REQUEST }
  | { type: UsersActionTypes.DELETE_USER_SUCCESS }
  | { type: UsersActionTypes.DELETE_USER_FAIL; error: unknown };

const getUsersRequest = (): UsersAction => ({ type: UsersActionTypes.GET_USERS_REQUEST });
const getUsersSuccess = (payload: UserDetails[]): UsersAction => ({
  type: UsersActionTypes.GET_USERS_SUCCESS,
  payload,
});
const getUsersFail = (error: unknown): UsersAction => ({ type: UsersActionTypes.GET_USERS_FAIL, error });

const setSearchString = (payload: string): UsersAction => ({
  type: UsersActionTypes.SET_SEARCH_STRING,
  payload,
});

const getUserDetailsRequest = (payload: GetUserDetailsRequest): UsersAction => ({
  type: UsersActionTypes.GET_USER_DETAILS_REQUEST,
  payload,
});
const getUserDetailsSuccess = (payload: UserDetails): UsersAction => ({
  type: UsersActionTypes.GET_USER_DETAILS_SUCCESS,
  payload,
});
const getUserDetailsFail = (error: unknown): UsersAction => ({ type: UsersActionTypes.GET_USER_DETAILS_FAIL, error });

const setUserRecord = (payload: UserDetails): UsersAction => ({
  type: UsersActionTypes.SET_USER_RECORD,
  payload,
});

const changeUserStatusRequest = (): UsersAction => ({
  type: UsersActionTypes.CHANGE_USER_STATUS_REQUEST,
});
const changeUserStatusSuccess = (): UsersAction => ({
  type: UsersActionTypes.CHANGE_USER_STATUS_SUCCESS,
});
const changeUserStatusFail = (error: unknown): UsersAction => ({
  type: UsersActionTypes.CHANGE_USER_STATUS_FAIL,
  error,
});

const deleteUserRequest = (): UsersAction => ({
  type: UsersActionTypes.DELETE_USER_REQUEST,
});
const deleteUserSuccess = (): UsersAction => ({
  type: UsersActionTypes.CHANGE_USER_STATUS_SUCCESS,
});
const deleteUserFail = (error: unknown): UsersAction => ({ type: UsersActionTypes.DELETE_USER_FAIL, error });

export const OPSConsoleUsersActions = {
  getUsersRequest,
  getUsersSuccess,
  getUsersFail,
  setSearchString,
  getUserDetailsRequest,
  getUserDetailsSuccess,
  getUserDetailsFail,
  setUserRecord,
  changeUserStatusRequest,
  changeUserStatusSuccess,
  changeUserStatusFail,
  deleteUserRequest,
  deleteUserSuccess,
  deleteUserFail,
};
