import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useSearchParams } from 'react-router-dom';
import { groupBookingPagesActions, groupBookingPagesSelectors } from '../../store/groupBookingPages';
import { Preloader } from '../../components/common';
import { ReactComponent as SumoIcon } from '../../assets/images/sumoIcon.svg';
import { ReactComponent as SumoIconWhite } from '../../assets/images/sumoIconWhite.svg';
import errorImage from '../../assets/images/error_desert.png';
import { bookingPageSelectors } from '../../store/bookingPages';
import { getTimeUnitLabel } from '../../services/utils';
import { navigationService } from '../../services/NavigationService';
import { Path } from '../../routing';
import { eventActions } from '../../store/publicBookingPage';
import labels from './labels';
import { BackgroundType } from '../../API';
import { getBackgroundTypeClassName } from '../../store/publicBookingPage/utils';

export const PublicGroupBookingPage = () => {
  const dispatch = useDispatch();
  const { groupBookingPageId } = useParams();
  const isFetching = useSelector(groupBookingPagesSelectors.selectIsFetching);
  const error = useSelector(groupBookingPagesSelectors.selectError);
  const name = useSelector(groupBookingPagesSelectors.selectGroupBookingPageName);
  const style = useSelector(groupBookingPagesSelectors.selectGroupBookingPageStyle);
  const bookingPages = useSelector(groupBookingPagesSelectors.selectGroupBookingPageBookingPages);
  const bookingPagesById = useSelector(bookingPageSelectors.selectBookingPagesById);

  const [searchParams] = useSearchParams();
  const hideHeader = searchParams.get('hideHeader') === 'true';
  const iframe = searchParams.get('iframe') === 'true';

  useEffect(() => {
    dispatch(
      groupBookingPagesActions.getPublicGroupBookingPageRequest({
        id: groupBookingPageId || '',
      })
    );
  }, []);

  const handleCardClick = (id: string) => {
    dispatch(eventActions.setGroupBookingPageId(groupBookingPageId || ''));
    navigationService.navigateTo(Path.PublicBookingPage.replace(':bookingPageId', id));
  };

  const bookingPageStyles = `
    :root {
      --booking-page-color: ${style.primaryColor};
    }`;

  return !isFetching || error ? (
    <div className={`booking-container ${getBackgroundTypeClassName(style.backgroundType)}`}>
      <div
        className="booking-background"
        style={
          style.backgroundType === BackgroundType.BLANK
            ? undefined
            : style.backgroundType === BackgroundType.COLOR
            ? { backgroundColor: `${style.backgroundColor}` }
            : { backgroundImage: `url(${style.backgroundImage})` }
        }
      ></div>
      <div className="booking-card-container">
        <div className={`booking-card ${iframe ? 'card-iframe' : ''}`}>
          <style dangerouslySetInnerHTML={{ __html: bookingPageStyles + (style.css || '') }} />

          {!hideHeader && (
            <div className="booking-header">
              <div className="booking-title">
                {name}
                {/* <Label
                textClassName="text-heading-lg-med hover-text-blue-dark"
                iconClassName="icon-28px"
                editable={isEditable}
                value={pageTitle}
                onBlurUpdate={handleEventNameLabelChange}
              /> */}
              </div>
              <img src={style.logoImage || ''} className="booking-logo" />
            </div>
          )}

          {!error ? (
            <div className="group-content">
              <div className="text-title-lg-med text-heavy-80 mt-8px mb-16px">{labels.title}</div>
              <div className="grid -mb-2 p-16px -m-16px group-card-list">
                {bookingPages.map((item) => {
                  const id = item?.bookingPageId || '';
                  const page = bookingPagesById[id];
                  return (
                    <div key={id} className="col-12 lg:col-6">
                      <div className="sumo-card action-card p-20px h-full" onClick={() => handleCardClick(id)}>
                        <div className="flex-left-center gap-8px">
                          <div
                            className="w-24px h-24px border-radius-4px"
                            style={{ backgroundColor: page.what?.color || '' }}
                          />
                          <div className="text-title-s-med text-heavy-100">{page.what?.customName}</div>
                        </div>
                        <div className="flex flex-column ml-32px gap-8px text-body-s-reg text-heavy-60">
                          <div>{`${page.when?.duration?.count} ${getTimeUnitLabel(
                            page.when?.duration?.timeUnit
                          )}`}</div>
                          <div className="white-space-pre-line">{item?.description}</div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          ) : (
            <div className="card mb-2 border-1 surface-border shadow-none">
              <div className="flex flex-column justify-content-center align-items-center">
                <div className="text-xl my-4">{error?.toString()}</div>
                <div>
                  <img src={errorImage} alt="error_desert" />
                </div>
              </div>
            </div>
          )}
        </div>
        {!iframe && (
          <div className="booking-footer">
            <div className="flex-center flex-column gap-8px">
              {style.backgroundType === BackgroundType.FULL || style.backgroundType === BackgroundType.COLOR ? (
                <SumoIconWhite />
              ) : (
                <SumoIcon />
              )}
              {style.footerHtml && (
                <div className="text-label-s-med" dangerouslySetInnerHTML={{ __html: style.footerHtml || '' }} />
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  ) : (
    <Preloader />
  );
};
