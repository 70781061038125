import { createSelector } from 'reselect';
import { State } from '../rootStore';
import { TeamMemberInput } from '../../API';
import { authenticationSelectors } from '../authentication';
import { workspacesSelectors } from '../workspaces';
import { DEFAULT_FILTER } from './constants';
import { globalSelectors } from '../global';
import { SelectItem } from 'primereact/selectitem';
import { isOldData } from '../../services/utils';

const teamsState = (state: State) => state.teams;

const selectIsFetching = createSelector(teamsState, (teams) => teams.isFetching);
const selectError = createSelector(teamsState, (teams) => teams.error);
const selectTeams = createSelector(teamsState, (teams) => teams.teams);
const selectSelectedTeam = createSelector(teamsState, (teams) => teams.team);
const selectFilter = createSelector(teamsState, (state) => state.filter);
const selectSelectedTeams = createSelector(teamsState, (state) => state.selectedTeams);

const selectLastLoadTime = createSelector(teamsState, (state) => state.lastLoadTime);
const selectIsSpinnerFetching = createSelector(
  selectIsFetching,
  selectLastLoadTime,
  (isFetching, loadTime) => isFetching && !isOldData(loadTime)
);
const selectIsSkeletonFetching = createSelector(
  selectIsFetching,
  selectLastLoadTime,
  (isFetching, loadTime) => isFetching && isOldData(loadTime)
);

const selectIsDefaultFilter = createSelector(selectFilter, (filter) => filter === DEFAULT_FILTER);
const selectFilterWorkspaces = createSelector(selectFilter, (filter) => filter.workspaceIds);
const selectFilterCreatedBy = createSelector(selectFilter, (filter) => filter.createdBy);
const selectIsFilterInUse = (userOptions: SelectItem[]) =>
  createSelector(
    selectFilter,
    authenticationSelectors.selectWorkspaceId,
    (filter, workspaceId) =>
      filter.workspaceIds.length !== 1 ||
      filter.workspaceIds[0] !== workspaceId ||
      filter.createdBy.length < userOptions.length
  );

const selectFilteredTeams = createSelector(
  selectTeams,
  selectFilter,
  globalSelectors.selectSearchString,
  (teams, filter, searchString) =>
    teams
      .filter(
        (team) =>
          (filter.workspaceIds?.includes(team.workspaceId || '') ||
            filter.workspaceIds.some((id) => team.workspacesForRead?.includes(id))) &&
          filter.createdBy?.includes(team.createdBy || '') &&
          Boolean(!searchString || team.name?.toLowerCase().includes(searchString))
      )
      .sort((teamA, teamB) => (teamA.createdAt > teamB.createdAt ? 1 : -1))
);

const selectTeamId = createSelector(selectSelectedTeam, (team) => team.id);
const selectTeamName = createSelector(selectSelectedTeam, (team) => team.name);
const selectIsTeamActive = createSelector(selectSelectedTeam, (team) => team.active);
const selectMembers = createSelector(
  selectSelectedTeam,
  (team) => team.members?.filter((member): member is TeamMemberInput => member !== null) || []
);
const selectMembersIds = createSelector(selectMembers, (members) => members.map((member) => member?.userId || ''));
const selectWorkspaceId = createSelector(selectSelectedTeam, (team) => team.workspaceId || '');
const selectTeamWorkspace = createSelector(
  selectWorkspaceId,
  workspacesSelectors.selectWorkspacesById,
  (id, workspacesById) => workspacesById[id]
);
const selectWorkspacesForRead = createSelector(
  selectSelectedTeam,
  (team) => team.workspacesForRead?.filter((workspace): workspace is string => workspace !== null) || []
);
const selectSharedForReadTeam = createSelector(selectSelectedTeam, (team) => !!team.readOnly);

const selectIsExistingTeam = createSelector(selectSelectedTeam, (team) => Boolean(team.id));
const selectIsNameDuplicate = createSelector(selectTeamName, selectTeamId, selectTeams, (name, id, teams) =>
  teams.some((team) => team.name === name && team.id !== id)
);
const selectIsNameValid = createSelector(
  selectTeamName,
  selectIsNameDuplicate,
  (name, isDuplicate) => Boolean(name) && !isDuplicate
);
const selectTeamCreatedBy = createSelector(selectSelectedTeam, (team) => team.createdBy);

const selectTeamsByCurrentWorkspace = createSelector(
  authenticationSelectors.selectWorkspaceId,
  selectTeams,
  (workspaceId, teams) =>
    teams.filter((team) => team.workspaceId === workspaceId || team.workspacesForRead?.includes(workspaceId))
);
const selectTeamNamesById = createSelector(selectTeams, (teams) =>
  teams.reduce((namesById, team) => {
    if (team.id) namesById[team.id] = team.name || '';
    return namesById;
  }, {} as Record<string, string>)
);

const selectCreatedByFilterOptions = createSelector(selectTeams, (teams) => {
  const createdBy = new Set<string>();
  teams.forEach((team) => team.createdBy && createdBy.add(team.createdBy));
  return Array.from(createdBy).map((user) => ({ value: user, label: user }));
});

const selectIsDefaultCreatedByFilter = createSelector(
  selectCreatedByFilterOptions,
  selectFilterCreatedBy,
  (options, filter) => options.length === filter.length
);

export const teamsSelectors = {
  selectIsFetching,
  selectError,
  selectIsSpinnerFetching,
  selectIsSkeletonFetching,
  selectTeams,
  selectSelectedTeam,
  selectSelectedTeams,
  selectLastLoadTime,
  selectIsDefaultFilter,
  selectFilterWorkspaces,
  selectFilterCreatedBy,
  selectIsFilterInUse,
  selectFilteredTeams,

  selectTeamId,
  selectTeamName,
  selectIsTeamActive,
  selectMembers,
  selectMembersIds,
  selectWorkspaceId,
  selectTeamWorkspace,
  selectWorkspacesForRead,
  selectSharedForReadTeam,

  selectIsExistingTeam,
  selectIsNameDuplicate,
  selectIsNameValid,
  selectTeamCreatedBy,

  selectTeamsByCurrentWorkspace,
  selectTeamNamesById,
  selectCreatedByFilterOptions,
  selectIsDefaultCreatedByFilter,
};
