import {
  CreateRoleInput,
  CreateUserDataInput,
  IntegrationInput,
  IntegrationType,
  StatisticsInput,
  UserSettingsInput,
  WorkspaceIntegrationType,
} from '../../API';
import { GetAuthResponseType } from '../authentication';
import { ConncetToZoom, UserDetails } from './types';

export enum UserSettingsActionTypes {
  GET_USER_SETTINGS_REQUEST = 'userSettings/GET_USER_SETTINGS_REQUEST',
  GET_USER_SETTINGS_SUCCESS = 'userSettings/GET_USER_SETTINGS_SUCCESS',
  GET_USER_SETTINGS_FAIL = 'userSettings/GET_USER_SETTINGS_FAIL',
  SAVE_USER_SETTINGS_REQUEST = 'userSettings/SAVE_USER_SETTINGS_REQUEST',
  SAVE_USER_SETTINGS_SUCCESS = 'userSettings/SAVE_USER_SETTINGS_SUCCESS',
  SAVE_USER_SETTINGS_FAIL = 'userSettings/SAVE_USER_SETTINGS_FAIL',
  UPDATE_USER_SETTINGS = 'userSettings/UPDATE_USER_SETTINGS',
  CONNECT_EXTERNAL_CALENDAR_REQUEST = 'userSettings/CONNECT_EXTERNAL_CALENDAR_REQUEST',
  CONNECT_EXTERNAL_CALENDAR_SUCCESS = 'userSettings/CONNECT_MICROSOFT_CALENDAR_SUCCESS',
  CONNECT_EXTERNAL_CALENDAR_FAIL = 'userSettings/CONNECT_MICROSOFT_CALENDAR_FAIL',
  SET_USER_SETTINGS = 'userSettings/SET_USER_SETTINGS',
  SET_THIRD_PARTY_LOGIN = 'userSettings/SET_THIRD_PARTY_LOGIN',
  UPDATE_USER_DATA = 'userSettings/UPDATE_USER_DATA',
  UPDATE_THIRD_PARTY_LOGIN = 'userSettings/SET_THIRD_PARTY_LOGIN',
  ADD_INTEGRATION = 'userSettings/ADD_INTEGRATION',
  REMOVE_INTEGRATION = 'userSettings/REMOVE_INTEGRATION',
  UPLOAD_AVATAR_FILE_REQUEST = 'userSettings/UPLOAD_AVATAR_FILE_REQUEST',
  UPLOAD_AVATAR_FILE_SUCCESS = 'userSettings/UPLOAD_AVATAR_FILE_SUCCESS',
  UPLOAD_AVATAR_FILE_FAIL = 'userSettings/UPLOAD_AVATAR_FILE_FAIL',
  CLEAR_AVATAR_FILE_REQUEST = 'userSettings/CLEAR_AVATAR_FILE_REQUEST',
  CLEAR_AVATAR_FILE_SUCCESS = 'userSettings/CLEAR_AVATAR_FILE_SUCCESS',
  CLEAR_AVATAR_FILE_FAIL = 'userSettings/CLEAR_AVATAR_FILE_FAIL',
  SET_USER_DETAILS = 'userSettings/SET_USER_DETAILS',
  UPDATE_USER_DETAILS = 'userSettings/UPDATE_USER_DETAILS',
  CONNECT_ZOOM_REQUEST = 'authentication/CONNECT_ZOOM_REQUEST',
  CONNECT_ZOOM_SUCCESS = 'authentication/CONNECT_ZOOM_SUCCESS',
  CONNECT_ZOOM_FAIL = 'authentication/CONNECT_ZOOM_FAIL',
  SET_UTC = 'userSettings/SET_UTC',
  SET_USER_PERMISSIONS = 'userSettings/SET_USER_PERMISSIONS',
  SET_STATISTICS = 'userSettings/SET_STATISTICS',
  SAVE_USER_SETTINGS_NO_TOAST_REQUEST = 'userSettings/SAVE_USER_SETTINGS_NO_TOAST_REQUEST',
  SAVE_USER_SETTINGS_NO_TOAST_SUCCESS = 'userSettings/SAVE_USER_SETTINGS_NO_TOAST_SUCCESS',
  SAVE_USER_SETTINGS_NO_TOAST_FAIL = 'userSettings/SAVE_USER_SETTINGS_NO_TOAST_FAIL',
}

export type UserSettingsAction =
  | { type: UserSettingsActionTypes.GET_USER_SETTINGS_REQUEST }
  | { type: UserSettingsActionTypes.GET_USER_SETTINGS_SUCCESS; payload: UserSettingsInput }
  | { type: UserSettingsActionTypes.GET_USER_SETTINGS_FAIL; error: unknown }
  | { type: UserSettingsActionTypes.SAVE_USER_SETTINGS_REQUEST }
  | { type: UserSettingsActionTypes.SAVE_USER_SETTINGS_SUCCESS }
  | { type: UserSettingsActionTypes.SAVE_USER_SETTINGS_FAIL; error: unknown }
  | { type: UserSettingsActionTypes.UPDATE_USER_SETTINGS; payload: Partial<UserSettingsInput> }
  | { type: UserSettingsActionTypes.CONNECT_EXTERNAL_CALENDAR_FAIL; error: unknown }
  | { type: UserSettingsActionTypes.CONNECT_EXTERNAL_CALENDAR_REQUEST; payload: GetAuthResponseType }
  | { type: UserSettingsActionTypes.CONNECT_EXTERNAL_CALENDAR_SUCCESS }
  | { type: UserSettingsActionTypes.CONNECT_EXTERNAL_CALENDAR_FAIL; error: unknown }
  | { type: UserSettingsActionTypes.SET_USER_SETTINGS; payload: UserSettingsInput }
  | { type: UserSettingsActionTypes.UPDATE_USER_DATA; payload: Partial<CreateUserDataInput> }
  | { type: UserSettingsActionTypes.UPDATE_USER_SETTINGS; payload: Partial<UserSettingsInput> }
  | { type: UserSettingsActionTypes.ADD_INTEGRATION; payload: IntegrationInput }
  | { type: UserSettingsActionTypes.REMOVE_INTEGRATION; payload: IntegrationType | WorkspaceIntegrationType }
  | { type: UserSettingsActionTypes.UPLOAD_AVATAR_FILE_REQUEST; payload: any }
  | { type: UserSettingsActionTypes.UPLOAD_AVATAR_FILE_SUCCESS; payload: string }
  | { type: UserSettingsActionTypes.UPLOAD_AVATAR_FILE_FAIL; error: unknown }
  | { type: UserSettingsActionTypes.CLEAR_AVATAR_FILE_REQUEST }
  | { type: UserSettingsActionTypes.CLEAR_AVATAR_FILE_SUCCESS }
  | { type: UserSettingsActionTypes.CLEAR_AVATAR_FILE_FAIL; error: unknown }
  | { type: UserSettingsActionTypes.SET_USER_DETAILS; payload: UserDetails }
  | { type: UserSettingsActionTypes.UPDATE_USER_DETAILS; payload: Partial<UserDetails> }
  | { type: UserSettingsActionTypes.CONNECT_ZOOM_REQUEST; payload: ConncetToZoom }
  | { type: UserSettingsActionTypes.CONNECT_ZOOM_SUCCESS; payload: IntegrationInput }
  | { type: UserSettingsActionTypes.CONNECT_ZOOM_FAIL; error: unknown }
  | { type: UserSettingsActionTypes.SET_UTC; payload: number }
  | { type: UserSettingsActionTypes.SET_USER_PERMISSIONS; payload: CreateRoleInput }
  | { type: UserSettingsActionTypes.SET_STATISTICS; payload: StatisticsInput }
  | { type: UserSettingsActionTypes.SAVE_USER_SETTINGS_NO_TOAST_REQUEST }
  | { type: UserSettingsActionTypes.SAVE_USER_SETTINGS_NO_TOAST_SUCCESS }
  | { type: UserSettingsActionTypes.SAVE_USER_SETTINGS_NO_TOAST_FAIL; error: unknown };

const getUserSettingsRequest = (): UserSettingsAction => ({ type: UserSettingsActionTypes.GET_USER_SETTINGS_REQUEST }); // TODO: no one use it, comment or remove please
const getUserSettingsSuccess = (payload: UserSettingsInput): UserSettingsAction => ({
  type: UserSettingsActionTypes.GET_USER_SETTINGS_SUCCESS,
  payload,
});
const getUserSettingsFail = (error: unknown): UserSettingsAction => ({
  type: UserSettingsActionTypes.GET_USER_SETTINGS_FAIL,
  error,
});

const saveUserSettingsRequest = (): UserSettingsAction => ({
  type: UserSettingsActionTypes.SAVE_USER_SETTINGS_REQUEST,
});
const saveUserSettingsSuccess = (): UserSettingsAction => ({
  type: UserSettingsActionTypes.SAVE_USER_SETTINGS_SUCCESS,
});
const saveUserSettingsFail = (error: unknown): UserSettingsAction => ({
  type: UserSettingsActionTypes.SAVE_USER_SETTINGS_FAIL,
  error,
});

const connectExternalCalendarRequest = (payload: GetAuthResponseType): UserSettingsAction => ({
  type: UserSettingsActionTypes.CONNECT_EXTERNAL_CALENDAR_REQUEST,
  payload,
});
const connectExternalCalendarSuccess = (): UserSettingsAction => ({
  type: UserSettingsActionTypes.CONNECT_EXTERNAL_CALENDAR_SUCCESS,
});
const connectExternalCalendarFail = (error: unknown): UserSettingsAction => ({
  type: UserSettingsActionTypes.CONNECT_EXTERNAL_CALENDAR_FAIL,
  error,
});

const setUserSettings = (payload: UserSettingsInput): UserSettingsAction => ({
  // TODO: what's the difference between saveUserSettingsSuccess/setUserSettings/updateUserSettings/setUserDetails, rename/remove the same logic please
  type: UserSettingsActionTypes.SET_USER_SETTINGS,
  payload,
});

const updateUserData = (payload: Partial<CreateUserDataInput>): UserSettingsAction => ({
  // TODO: rename please to unique action name, now it has general name
  type: UserSettingsActionTypes.UPDATE_USER_DATA,
  payload,
});

const updateUserSettings = (payload: Partial<UserSettingsInput>): UserSettingsAction => ({
  // TODO: what's the difference between saveUserSettingsSuccess/setUserSettings/updateUserSettings/setUserDetails, rename/remove the same logic please
  type: UserSettingsActionTypes.UPDATE_USER_SETTINGS,
  payload,
});

const addIntegration = (payload: IntegrationInput): UserSettingsAction => ({
  // TODO: no one use it, comment or remove please
  type: UserSettingsActionTypes.ADD_INTEGRATION,
  payload,
});

const removeIntegration = (payload: IntegrationType | WorkspaceIntegrationType): UserSettingsAction => ({
  type: UserSettingsActionTypes.REMOVE_INTEGRATION,
  payload,
});

const uploadAvatarFileRequest = (payload: any): UserSettingsAction => ({
  type: UserSettingsActionTypes.UPLOAD_AVATAR_FILE_REQUEST,
  payload,
});
const uploadAvatarFileSuccess = (payload: string): UserSettingsAction => ({
  type: UserSettingsActionTypes.UPLOAD_AVATAR_FILE_SUCCESS,
  payload,
});
const uploadAvatarFileFail = (error: unknown): UserSettingsAction => ({
  type: UserSettingsActionTypes.UPLOAD_AVATAR_FILE_FAIL,
  error,
});

const clearAvatarFileRequest = (): UserSettingsAction => ({
  type: UserSettingsActionTypes.CLEAR_AVATAR_FILE_REQUEST,
});
const clearAvatarFileSuccess = (): UserSettingsAction => ({
  type: UserSettingsActionTypes.CLEAR_AVATAR_FILE_SUCCESS,
});
const clearAvatarFileFail = (error: unknown): UserSettingsAction => ({
  type: UserSettingsActionTypes.CLEAR_AVATAR_FILE_FAIL,
  error,
});

const setUserDetails = (payload: UserDetails): UserSettingsAction => ({
  // TODO: what's the difference between saveUserSettingsSuccess/setUserSettings/updateUserSettings/setUserDetails, rename/remove the same logic please
  type: UserSettingsActionTypes.SET_USER_DETAILS,
  payload,
});

const updateUserDetails = (payload: Partial<UserDetails>): UserSettingsAction => ({
  // TODO: what's the difference between saveUserSettingsSuccess/setUserSettings/updateUserSettings/setUserDetails, rename/remove the same logic please
  type: UserSettingsActionTypes.UPDATE_USER_DETAILS,
  payload,
});

const connectZoomRequest = (payload: ConncetToZoom): UserSettingsAction => ({
  type: UserSettingsActionTypes.CONNECT_ZOOM_REQUEST,
  payload,
});
const connectZoomSuccess = (payload: IntegrationInput): UserSettingsAction => ({
  type: UserSettingsActionTypes.CONNECT_ZOOM_SUCCESS,
  payload,
});
const connectZoomFail = (error: unknown): UserSettingsAction => ({
  type: UserSettingsActionTypes.CONNECT_ZOOM_FAIL,
  error,
});

const setUTC = (payload: number): UserSettingsAction => ({ type: UserSettingsActionTypes.SET_UTC, payload });

const setUserPermissions = (payload: CreateRoleInput): UserSettingsAction => ({
  type: UserSettingsActionTypes.SET_USER_PERMISSIONS,
  payload,
});

const setStatistics = (payload: StatisticsInput): UserSettingsAction => ({
  type: UserSettingsActionTypes.SET_STATISTICS,
  payload,
});

const saveUserSettingsNoToastRequest = (): UserSettingsAction => ({
  type: UserSettingsActionTypes.SAVE_USER_SETTINGS_NO_TOAST_REQUEST,
});
const saveUserSettingsNoToastSuccess = (): UserSettingsAction => ({
  type: UserSettingsActionTypes.SAVE_USER_SETTINGS_NO_TOAST_SUCCESS,
});
const saveUserSettingsNoToastFail = (error: unknown): UserSettingsAction => ({
  type: UserSettingsActionTypes.SAVE_USER_SETTINGS_NO_TOAST_FAIL,
  error,
});

export const userSettingsActions = {
  getUserSettingsRequest,
  getUserSettingsSuccess,
  getUserSettingsFail,
  saveUserSettingsRequest,
  saveUserSettingsSuccess,
  saveUserSettingsFail,
  updateUserSettings,
  connectExternalCalendarRequest, // TODO: rename to connectExternalThirdPartyRequest
  connectExternalCalendarSuccess,
  connectExternalCalendarFail,
  setUserSettings,
  updateUserData,
  addIntegration,
  removeIntegration,
  uploadAvatarFileRequest,
  uploadAvatarFileSuccess,
  uploadAvatarFileFail,
  clearAvatarFileRequest,
  clearAvatarFileSuccess,
  clearAvatarFileFail,
  setUserDetails,
  updateUserDetails,
  connectZoomRequest,
  connectZoomSuccess,
  connectZoomFail,
  setUTC,
  setUserPermissions,
  setStatistics,
  saveUserSettingsNoToastRequest,
  saveUserSettingsNoToastSuccess,
  saveUserSettingsNoToastFail,
};
