import { NodeProps, Position } from '@xyflow/react';
import {
  deleteJourneyStepModalActions,
  EMPTY_VALUE,
  getColorByStepType,
  getDestinationLabel,
  journeysActions,
  journeysSelectors,
  JourneyStepNodeType,
} from '../../../store/journeyBuilder';
import { JourneyStepType } from '../../../API';
import { JourneyHandle } from '../journeyHandle/JourneyHandle';
import { DocumentDuplicateIcon, EyeIcon, PencilIcon, TrashIcon } from '@heroicons/react/24/outline';
import { useDispatch, useSelector } from 'react-redux';
import { getConditionDescription, getStepTitle } from './utils';
import { AppDispatch } from '../../../store/rootStore';

export const JourneyStepNode = ({ data }: NodeProps<JourneyStepNodeType>) => {
  const dispatch = useDispatch<AppDispatch>();
  const cards = useSelector(journeysSelectors.selectCards);
  const fields = useSelector(journeysSelectors.selectFields);

  const handleEdit = () => {
    dispatch(journeysActions.setJourneyStep(data.journeyStep));
    dispatch(journeysActions.setEditSidebar(true));
  };

  const handleCopy = () => {
    dispatch(journeysActions.copyJourneyStep(data.journeyStep));
  };

  const handleRemove = () => {
    dispatch(journeysActions.setJourneyStep(data.journeyStep));
    dispatch(deleteJourneyStepModalActions.openModal());
    if (data.journeyStep?.type === JourneyStepType.PAGE) {
      dispatch(journeysActions.regenerateFieldsAndCardsThunk());
    }
  };

  const getStepDescription = () => {
    switch (data.journeyStep?.type) {
      case JourneyStepType.CONDITION:
        return getConditionDescription(data.journeyStep.condition, fields, cards);
      case JourneyStepType.DESTINATION:
        return (
          <div className="step-node-text text-body-lg-reg">
            {getDestinationLabel(data.journeyStep.destination?.type) || EMPTY_VALUE}
          </div>
        );
      case JourneyStepType.PAGE:
        return <div className="step-node-text text-body-lg-reg">{data.journeyStep.page?.name || EMPTY_VALUE}</div>;
      default:
        return '';
    }
  };

  return (
    <div className="journey-step flex-top gap-4px cursor-auto" key={data.journeyStep.id}>
      <div
        className="step-node"
        style={{
          borderLeftColor: getColorByStepType(data.journeyStep.type),
        }}
        onClick={handleEdit}
      >
        <div className="bg-heavy-1 text-label-s-med text-heavy-60 px-24px py-8px">
          {getStepTitle(data.journeyStep)} {data.conditionIndex || ''}
        </div>

        <div className="pl-24px pr-12px py-8px">{getStepDescription()}</div>
        <JourneyHandle type="target" position={Position.Top} />
        {data.journeyStep.type !== JourneyStepType.DESTINATION && (
          <JourneyHandle type="source" position={Position.Bottom} />
        )}
      </div>
      <div className="journey-step-options flex flex-column gap-4px">
        <div className="step-toolbar-item" onClick={handleEdit}>
          {!data.isReadOnly ? <PencilIcon width={16} height={16} /> : <EyeIcon width={16} height={16} />}
        </div>
        {data.journeyStep.type === JourneyStepType.CONDITION && !!data.isLastChild && !data.isReadOnly && (
          <div className="step-toolbar-item" onClick={handleCopy}>
            <DocumentDuplicateIcon width={16} height={16} />
          </div>
        )}
        {!data.isReadOnly && (
          <div className="step-toolbar-item" onClick={handleRemove}>
            <TrashIcon width={16} height={16} />
          </div>
        )}
      </div>
    </div>
  );
};
