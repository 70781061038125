import { useDispatch, useSelector } from 'react-redux';
import { MAX_LENGTH_NAME, isUserFromCanadaOrUS } from '../../../types/constants';
import { GoogleMap, Search } from '../../common';
import { eventActions, eventSelectors, EventSteps } from '../../../store/publicBookingPage';
import { globalSelectors } from '../../../store/global';
import {
  Coordinates,
  getAddressCoordinates,
  getFullAddress,
  GOOGLE_MAP_ACCESS_LOCATION_LINK,
  isEqualAddress,
  requestGeolocation,
} from '../../../store/locations';
import { Path } from '../../../routing';
import { Button } from 'primereact/button';
import { useEffect, useRef, useState } from 'react';
import { PhysicalLocation } from '../../../API';
import { AdvancedMarker, ControlPosition, MapControl, useMap } from '@vis.gl/react-google-maps';
import { ReactComponent as MyLocation } from '../../../assets/icons/myLocation.svg';
import { ReactComponent as CurrentLocationMarker } from '../../../assets/icons/currentLocationMarker.svg';
import { ReactComponent as MapPin } from '../../../assets/icons/mapPin.svg';
import { ProgressSpinner } from 'primereact/progressspinner';
import labels from './labels';
import { Tooltip } from 'primereact/tooltip';
import { navigationService } from '../../../services/NavigationService';

export const PublicBookingPageLocations = () => {
  const dispatch = useDispatch();
  const searchString = useSelector(globalSelectors.selectSearchString);
  const address = useSelector(eventSelectors.selectLocationAddress);
  const showSaveButton = useSelector(eventSelectors.selectShowSaveButton);
  const showCancelButton = useSelector(eventSelectors.selectShowLocationCancelButton);
  const defaultLocationType = useSelector(eventSelectors.selectDefaultLocationType);
  const isLocationInPerson = useSelector(eventSelectors.selectIsLocationInPerson);
  const groupBookingPageId = useSelector(eventSelectors.selectGroupBookingPageId);

  const [localAddress, setLocalAddress] = useState(address);
  const [navigatorInUse, setNavigatorInUse] = useState(false);
  const [userLocation, setUserLocation] = useState<Coordinates | null>(null);
  const userLocationRef = useRef<HTMLDivElement>(null);

  const locations = useSelector(eventSelectors.selectLocationsWithDistance(userLocation));
  const nearestLocations = useSelector(eventSelectors.selectNearestLocations(userLocation));

  const ZOOM_LVL_MAX = 15;
  const ZOOM_LVL_MIN = 6;

  const map = useMap();
  const geocoder = new google.maps.Geocoder();

  useEffect(() => {
    requestGeolocation(geocoder, setUserLocation, setNavigatorInUse);
  }, []);

  useEffect(() => {
    // if we use search and locations list changed - move map
    const isSelectedInList = locations.some((location) => isEqualAddress(location, localAddress));
    if (map && !isSelectedInList && locations.length) {
      // to the nearest location from the list, if no selected
      const nearestLocation = locations[0];
      map.setZoom(ZOOM_LVL_MIN);
      map.panTo({ lat: +(nearestLocation.address?.lat || 0), lng: +(nearestLocation.address?.lng || 0) });
    } else if (isSelectedInList) {
      // to the selected location
      navigateToLocationAndZoom(getAddressCoordinates(localAddress), ZOOM_LVL_MAX);
    }
  }, [locations.length]);

  useEffect(() => {
    !address ? navigateToNearestLocations() : navigateToLocationAndZoom(getAddressCoordinates(address), ZOOM_LVL_MAX);
  }, [userLocation, address]);

  const smoothZoom = (max: number) => {
    if (map) {
      const currentZoom = map.getZoom() || 0;
      if (currentZoom >= max) {
        return;
      } else {
        map.setZoom(currentZoom + 0.5);
        setTimeout(() => {
          smoothZoom(max);
        }, 30);
      }
    }
  };

  const navigateToLocation = (Coordinates: Coordinates) => {
    if (map) {
      map.panTo(Coordinates);
    }
  };

  const navigateToLocationAndZoom = (Coordinates: Coordinates, zoomLvl: number) => {
    navigateToLocation(Coordinates);
    smoothZoom(zoomLvl);
  };

  const navigateToUserLocation = () => {
    if (userLocation) {
      navigateToLocationAndZoom(userLocation, ZOOM_LVL_MAX);
    }
  };

  const navigateToNearestLocations = () => {
    if (map) {
      const bound = new google.maps.LatLngBounds();
      userLocation && bound.extend(userLocation);
      if (nearestLocations?.length) {
        // if there locations in 100 miles
        nearestLocations.forEach((location) =>
          bound.extend({ lat: +(location.address?.lat || 0), lng: +(location.address?.lng || 0) })
        );
      } else {
        // the first one
        const nearestLocation = locations[0];
        bound.extend({ lat: +(nearestLocation.address?.lat || 0), lng: +(nearestLocation.address?.lng || 0) });
      }
      map.fitBounds(bound);
    }
  };

  const handleSelectLocation = (location: PhysicalLocation) => {
    setLocalAddress(location.address);
    navigateToLocationAndZoom(getAddressCoordinates(location.address), ZOOM_LVL_MAX);
  };

  const handleSave = () => {
    dispatch(eventActions.updateLocation({ address: localAddress }));
    dispatch(eventActions.setEventStep(EventSteps.WHEN));
  };

  const handleCancel = () => {
    if (isLocationInPerson && groupBookingPageId) {
      dispatch(eventActions.resetEventStore());
      navigationService.navigateTo(Path.PublicGroupBookingPage.replace(':groupBookingPageId', groupBookingPageId));
    } else {
      if (!address && defaultLocationType) {
        dispatch(eventActions.updateLocation({ type: defaultLocationType }));
      }
      dispatch(eventActions.setPreviousStep());
    }
  };

  const getMarkers = () => {
    const locationMarkers = locations.map((location) => (
      <AdvancedMarker
        key={location.id}
        position={getAddressCoordinates(location.address)}
        onClick={() => handleSelectLocation(location)}
      >
        <MapPin
          height={35}
          width={30}
          className={`${
            isEqualAddress(location, localAddress)
              ? 'text-blue-main hover-text-blue-dark'
              : 'text-heavy-100 hover-text-heavy-80'
          }`}
        />
      </AdvancedMarker>
    ));

    if (userLocation && navigatorInUse) {
      locationMarkers.push(
        <AdvancedMarker position={userLocation} key="userLocation">
          <CurrentLocationMarker height={30} width={30} />
        </AdvancedMarker>
      );
    }
    return locationMarkers;
  };

  return (
    <div className="location-info">
      <div className="w-12 md:w-6 lg:w-4 h-full">
        <div className="px-20px pt-20px">
          <Search
            placeholder={labels.locationsPlaceholder}
            value={searchString}
            maxTextLength={MAX_LENGTH_NAME}
            paths={[Path.PublicBookingPage]}
            className="location-search"
            open={true}
          />
        </div>
        <div className="location-cards p-20px">
          {locations.map((location) => (
            <div
              key={location.id}
              className={`location-card ${isEqualAddress(location, localAddress) ? 'location-card-selected' : ''}`}
              onClick={() => handleSelectLocation(location)}
            >
              <div className="flex flex-column gap-4px">
                <div className="text-label-s-med text-heavy-80">{location.name || ''}</div>
                <div className="text-label-xs-reg text-heavy-60">{getFullAddress(location.address)}</div>
                <div className="text-body-xs-reg text-heavy-60">
                  {isUserFromCanadaOrUS ? location.distanceMI + labels.miles : location.distanceKM + labels.kilometers}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="w-12 md:w-6 lg:w-8 flex flex-column">
        <div className="location-map">
          {!userLocation && (
            <div className="w-full h-full flex-center bg-heavy-1">
              <ProgressSpinner />
            </div>
          )}
          <GoogleMap
            style={{ width: '100%', height: '100%' }}
            streetViewControl={false}
            mapTypeControl={false}
            advancedMarkers={getMarkers()}
            className={`${!userLocation ? 'hidden' : ''}`}
          >
            <MapControl position={ControlPosition.INLINE_END_BLOCK_END} key="userLocationControl">
              <Tooltip
                target={(!navigatorInUse && (userLocationRef.current as HTMLElement)) || undefined}
                autoHide={false}
                pt={{ text: { className: 'bg-primary-white text-heavy-100 w-120px text-label-s-reg' } }}
                position="left"
              >
                <span>{labels.locationAccessTooltip}</span>
                <a target="_blank" rel="noreferrer" href={GOOGLE_MAP_ACCESS_LOCATION_LINK}>
                  {labels.learnMore}
                </a>
              </Tooltip>
              <div
                ref={userLocationRef}
                className={`bg-primary-white w-40px h-40px flex-center mr-10px ${
                  navigatorInUse ? 'cursor-pointer text-heavy-80 hover-text-heavy-100' : ' text-heavy-50'
                }`}
                onClick={navigatorInUse ? navigateToUserLocation : undefined}
              >
                <MyLocation width={28} height={28} />
              </div>
            </MapControl>
          </GoogleMap>
        </div>
        {(showSaveButton || showCancelButton) && (
          <div className="locations-buttons">
            {showSaveButton && (
              <Button
                className="justify-content-center"
                style={{ minWidth: '90px' }}
                onClick={handleSave}
                disabled={!localAddress}
              >
                <span>{labels.save}</span>
              </Button>
            )}
            {showCancelButton && (
              <Button className="justify-content-center" style={{ minWidth: '90px' }} onClick={handleCancel} text>
                <span>{labels.cancel}</span>
              </Button>
            )}
          </div>
        )}
      </div>
    </div>
  );
};
