import { Button } from 'primereact/button';
import labels from './labels';
import { Modal } from '../../common';
import {
  BUY_LICENSE_MODAL,
  CHARGEBEE_TERM_OPTIONS,
  CHARGEBEE_MONTHLY_TERM,
  buyLicenseModalActions,
  getTotalAmount,
  CHARGEBEE_YEARLY_TERM,
  billingSelectors,
  getTiersByTerm,
  Tier,
  billingActions,
  getPriceBreakdownByTiers,
} from '../../../store/billing';
import { useEffect, useState } from 'react';
import { InputNumber } from 'primereact/inputnumber';
import { Dropdown } from 'primereact/dropdown';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { userSettingsSelectors } from '../../../store/userSettings';
import { authenticationSelectors } from '../../../store/authentication';
import { subscribe } from '../../../services/ChargebeeService';
import { ShoppingCartIcon } from '@heroicons/react/24/outline';
import { useRotatingText } from '../../../hooks/useRotatingText';

export const BuyLicenseButton = () => {
  const dispatch = useDispatch();
  const customerInfo = useSelector(userSettingsSelectors.selectCustomerInfo);
  const licensesAssigned = useSelector(authenticationSelectors.selectAssigned);
  const isLicenseActive = useSelector(authenticationSelectors.selectIsLicenseActive);
  const tenantId = useSelector(authenticationSelectors.selectTenantId);
  const isLicenseFetching = useSelector(authenticationSelectors.selectLicensesIsFetching);
  const isRefreshLicenseFetching = useSelector(billingSelectors.selectIsRefreshLicencesFetching);
  const currencyLabel = useSelector(billingSelectors.selectCurrencyLabel);
  const itemPrices = useSelector(billingSelectors.selectItemPrices);
  const [quantity, setQuantity] = useState<number | null>(licensesAssigned);
  const [term, setTerm] = useState(CHARGEBEE_MONTHLY_TERM);
  const [amount, setAmount] = useState(0);
  const [isSuccess, setIsSuccess] = useState(false);
  const tiers: Tier[] = getTiersByTerm(itemPrices, term);
  const rotatingLoadingLabel = useRotatingText([labels.loadingLabel1, labels.loadingLabel2], isRefreshLicenseFetching);

  useEffect(() => {
    setQuantity(licensesAssigned);
  }, [licensesAssigned]);

  useEffect(() => {
    setAmount(getTotalAmount(quantity || 0, tiers));
  }, [quantity, term]);

  const resetState = () => {
    setQuantity(licensesAssigned);
    setTerm(CHARGEBEE_MONTHLY_TERM);
  };

  const handleOpenModal = () => {
    dispatch(buyLicenseModalActions.openModal());
  };

  const handleClose = () => {
    dispatch(buyLicenseModalActions.closeModal());
    resetState();
  };

  const chargeBeeCheckoutSuccess = () => {
    setIsSuccess(true);
    dispatch(billingActions.refreshTrialLicenseRequest());
  };

  const handlePurchase = () => {
    if (quantity) {
      subscribe(quantity, term, customerInfo, tenantId, chargeBeeCheckoutSuccess);
      handleClose();
    }
  };

  return (
    <>
      <Modal.Container name={BUY_LICENSE_MODAL}>
        <Modal.Header>
          <div className="w-25rem text-center">{labels.buyLicenses}</div>
        </Modal.Header>
        <div className="p-fluid pb-2">
          <label htmlFor="quantity">{labels.numberLicenses}</label>
          <InputNumber
            showButtons
            inputId="quantity"
            value={quantity}
            onChange={(e) => setQuantity(e.value)}
            placeholder={labels.enterNumber}
            min={0}
            className={!quantity || quantity < licensesAssigned ? 'p-invalid' : ''}
          />
          {!!quantity && quantity < licensesAssigned && (
            <small id="quantity-help" className="p-error block w-25rem">
              {`${labels.licenseMessagePart1} ${licensesAssigned} ${labels.licenseMessagePart2}${
                isLicenseActive ? labels.licenseMessagePart3 : ''
              }.`}
            </small>
          )}
        </div>
        <div className="p-fluid pb-2">
          <label htmlFor="term">{labels.term}</label>
          <Dropdown
            inputId="term"
            value={term}
            options={CHARGEBEE_TERM_OPTIONS}
            onChange={(e) => setTerm(e.target.value)}
          />
        </div>
        <div className="pt-2">
          {`${labels.buyLicensesMessage} ${currencyLabel}${amount} ${
            term === CHARGEBEE_YEARLY_TERM ? labels.annually : labels.monthly
          }.`}
        </div>
        <div className="text-sm">
          {getPriceBreakdownByTiers(tiers, term, quantity || 0, currencyLabel).map(
            (value, index) => value && <div key={index}>{value}</div>
          )}
        </div>
        <Modal.Buttons>
          <Button onClick={handleClose} outlined>
            {labels.cancel}
          </Button>
          <Button
            className="ml-auto"
            onClick={handlePurchase}
            autoFocus
            disabled={!quantity || quantity < licensesAssigned}
          >
            {labels.next}
          </Button>
        </Modal.Buttons>
      </Modal.Container>
      <Button
        className="flex-none button-xl"
        disabled={isSuccess || isLicenseFetching}
        onClick={handleOpenModal}
        loading={isRefreshLicenseFetching}
        style={isRefreshLicenseFetching ? { width: '167px' } : undefined}
      >
        {!isRefreshLicenseFetching && <ShoppingCartIcon width={18} />}
        <span className="flex-1 pl-8px">{isRefreshLicenseFetching ? rotatingLoadingLabel : labels.buyLicenses}</span>
      </Button>
    </>
  );
};
