import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { errorActions } from '../../../store/error/actions';
import { ErrorCodes } from '../../../store/error/types';
import { authenticationActions, authenticationSelectors } from '../../../store/authentication';
import { useDispatch, useSelector } from 'react-redux';
import { Preloader } from '../../../components/common';

export const ViewAsUser = () => {
  const { tenantId, userId } = useParams();
  const dispatch = useDispatch();
  const isViewAsUserMode = useSelector(authenticationSelectors.selectIsViewAsUser);

  useEffect(() => {
    if (tenantId && userId) {
      !isViewAsUserMode && dispatch(authenticationActions.viewAsUserRequest({ tenantId, userId }));
    } else {
      dispatch(errorActions.setTheError(ErrorCodes.CODE_403));
    }
  }, []);

  return <Preloader />;
};
