import { GlobalSearch } from './types';

export enum GlobalActionTypes {
  RESET_WHOLE_STORE = 'global/RESET_WHOLE_STORE',
  GET_MAIN_DATA_REQUEST = 'global/GET_MAIN_DATA_REQUEST',
  GET_MAIN_DATA_SUCCESS = 'global/GET_MAIN_DATA_SUCCESS',
  GET_MAIN_DATA_FAIL = 'global/GET_MAIN_DATA_FAIL',
  SET_SEARCH = 'global/SET_SEARCH',
  SET_FULL_SCREEN = 'global/SET_FULL_SCREEN',
}

export type GlobalAction =
  | { type: GlobalActionTypes.RESET_WHOLE_STORE }
  | { type: GlobalActionTypes.GET_MAIN_DATA_REQUEST }
  | { type: GlobalActionTypes.GET_MAIN_DATA_SUCCESS }
  | { type: GlobalActionTypes.GET_MAIN_DATA_FAIL }
  | { type: GlobalActionTypes.SET_SEARCH; payload: Partial<GlobalSearch> }
  | { type: GlobalActionTypes.SET_FULL_SCREEN; payload: boolean };

const resetWholeStore = (): GlobalAction => ({ type: GlobalActionTypes.RESET_WHOLE_STORE });

const getMainDataRequest = (): GlobalAction => ({ type: GlobalActionTypes.GET_MAIN_DATA_REQUEST });
const getMainDataSuccess = (): GlobalAction => ({ type: GlobalActionTypes.GET_MAIN_DATA_SUCCESS });
const getMainDataFail = (): GlobalAction => ({ type: GlobalActionTypes.GET_MAIN_DATA_FAIL });

const setSearch = (payload: Partial<GlobalSearch>): GlobalAction => ({ type: GlobalActionTypes.SET_SEARCH, payload });

const setFullScreen = (payload: boolean): GlobalAction => ({ type: GlobalActionTypes.SET_FULL_SCREEN, payload });

export const globalActions = {
  resetWholeStore,
  getMainDataRequest,
  getMainDataSuccess,
  getMainDataFail,
  setSearch,
  setFullScreen,
};
