import { ModalName, modalsActionsFactory, modalsSelectorsFactory } from '../modals';

export const GROUP_CLONE_MODAL: ModalName = 'GroupBookingPageCloneModal';
export const groupCloneModalActions = modalsActionsFactory(GROUP_CLONE_MODAL);
export const groupCloneModalSelectors = modalsSelectorsFactory(GROUP_CLONE_MODAL);

export const GROUP_DELETE_MODAL_NAME: ModalName = 'GroupBookingPageDeleteModal';
export const groupDeleteModalActions = modalsActionsFactory(GROUP_DELETE_MODAL_NAME);
export const groupDeleteModalSelectors = modalsSelectorsFactory(GROUP_DELETE_MODAL_NAME);

export const GROUP_DEACTIVATE_MODAL_NAME: ModalName = 'GroupBookingPageDeactivateModal';
export const groupDeactivateModalActions = modalsActionsFactory(GROUP_DEACTIVATE_MODAL_NAME);
export const groupDeactivateModalSelectors = modalsSelectorsFactory(GROUP_DEACTIVATE_MODAL_NAME);
