import { t } from 'i18next';
import { SmartAlertFilterType, SmartAlertStatus, SmartAlertType } from './types';
import {
  TOAST_ERROR_COLOR,
  TOAST_ERROR_DURATION,
  TOAST_SUCCESS_COLOR,
  TOAST_SUCCESS_DURATION,
} from '../../types/constants';
import { ToastNotificationOptions } from '../notifications';
import { EventSendType, IconName, SmartAlert, SmartAlertTemplate, WhenSend, WhomSend } from '../../API';

export const DEFAULT_FILTER: SmartAlertFilterType = {
  userIds: [],
  types: [SmartAlertType.PERSONAL, SmartAlertType.SHARED, SmartAlertType.TEMPLATE],
  status: [SmartAlertStatus.UNHIDDEN],
};

export const EMPTY_FILTER: SmartAlertFilterType = {
  userIds: [],
  types: [],
  status: [],
};

export const SMART_ALERT_TYPE_OPTIONS = [
  { value: SmartAlertType.PERSONAL, label: t('SmartAlerts:PERSONAL_LABEL') },
  { value: SmartAlertType.SHARED, label: t('SmartAlerts:SHARED_LABEL') },
  { value: SmartAlertType.TEMPLATE, label: t('SmartAlerts:TEMPLATES_LABEL') },
];

export const SMART_ALERT_STATUS_OPTIONS = [
  { value: SmartAlertStatus.UNHIDDEN, label: t('SmartAlerts:UNHIDDEN_LABEL') },
  { value: SmartAlertStatus.HIDDEN, label: t('SmartAlerts:HIDDEN_LABEL') },
];

/* const EMAIL_TO_INVITE_SUBJECT = t('SmartAlertsPersonalize:EMAIL_TO_INVITE_SUBJECT');
const EMAIL_TO_INVITE_BODY = t('SmartAlertsPersonalize:EMAIL_TO_INVITE_BODY');
const EMAIL_TO_HOST_SUBJECT = t('SmartAlertsPersonalize:EMAIL_TO_HOST_SUBJECT');
const EMAIL_TO_HOST_BODY = t('SmartAlertsPersonalize:EMAIL_TO_HOST_BODY');
const SMS_TO_INVITE = t('SmartAlertsPersonalize:SMS_TO_INVITE');
const SMS_TO_HOST = t('SmartAlertsPersonalize:SMS_TO_HOST'); */

const EMAIL_TO_INVITE_SUBJECT =
  'Reminder: &ltMeeting Name&gt with &ltHost Name&gt at &ltMeeting Time&gt, &ltMeeting Date&gt';
const EMAIL_TO_INVITE_BODY =
  'Hi &ltInvitee Name&gt,<br><br>This is a friendly reminder that your &ltMeeting Name&gt with &ltHost Name&gt is at &ltMeeting Time&gt, &ltMeeting Date&gt.<br><br>&ltMeeting Location&gt<br><br>&ltMeeting Button&gt';
const EMAIL_TO_HOST_SUBJECT =
  'Reminder: &ltMeeting Name&gt with &ltInvitee Name&gt at &ltMeeting Time&gt, &ltMeeting Date&gt';
const EMAIL_TO_HOST_BODY =
  'Hi &ltHost Name&gt,<br><br>This is a friendly reminder that your &ltMeeting Name&gt with &ltInvitee Name&gt is at &ltMeeting Time&gt, &ltMeeting Date&gt.<br><br>&ltMeeting Location&gt<br><br>&ltMeeting Button&gt';
const SMS_TO_INVITE =
  'This is a friendly reminder that your &ltMeeting Name&gt with &ltHost Name&gt is at &ltMeeting Time&gt, &ltMeeting Date&gt. View or edit meeting &ltMeeting Link&gt';
const SMS_TO_HOST =
  'This is a friendly reminder that your &ltMeeting Name&gt with &ltInvitee Name&gt is at &ltMeeting Time&gt, &ltMeeting Date&gt. View or edit meeting &ltMeeting Link&gt';

const DEFAULT_WHOM_SEND = {
  __typename: 'WhomSend',
  emailToInvitee: {
    __typename: 'AlertTemplate',
    isActive: false,
    subject: EMAIL_TO_INVITE_SUBJECT,
    body: EMAIL_TO_INVITE_BODY,
  },
  smsToInvitee: {
    __typename: 'AlertTemplate',
    isActive: false,
    subject: '',
    body: SMS_TO_INVITE,
  },
  emailToHost: {
    __typename: 'AlertTemplate',
    isActive: false,
    subject: EMAIL_TO_HOST_SUBJECT,
    body: EMAIL_TO_HOST_BODY,
  },
  smsToHost: {
    __typename: 'AlertTemplate',
    isActive: false,
    subject: '',
    body: SMS_TO_HOST,
  },
} as WhomSend;

const DEFAULT_WHEN_SEND = {
  __typename: 'WhenSend',
  eventSendType: EventSendType.EVENT_BEFORE_START,
  delay: 1440,
} as WhenSend;

export const DEFAULT_SMART_ALERT: SmartAlert = {
  __typename: 'SmartAlert',
  id: '',
  name: t('EditSmartAlert:CUSTOM_NAME'),
  icon: IconName.CUSTOM,
  shareWith: [],
  whenSend: DEFAULT_WHEN_SEND,
  whomSend: DEFAULT_WHOM_SEND,
  createdAt: '',
  updatedAt: '',
};

export const DEFAULT_SMART_ALERT_TEMPLATE: SmartAlertTemplate = {
  __typename: 'SmartAlertTemplate',
  id: '',
  name: t('EditSmartAlert:CUSTOM_NAME'),
  icon: IconName.CUSTOM,
  shareWith: [],
  whenSend: DEFAULT_WHEN_SEND,
  whomSend: DEFAULT_WHOM_SEND,
  createdAt: '',
  updatedAt: '',
};

export const GET_SMART_TYPES_ERROR_TOAST: ToastNotificationOptions = Object.freeze({
  color: TOAST_ERROR_COLOR,
  autoHideDuration: TOAST_ERROR_DURATION,
  message: t('SmartAlertsToastsNotifications:GET_SMART_TYPES_ERROR_MESSAGE'),
});

export const CLONE_SMART_TYPE_ERROR_TOAST: ToastNotificationOptions = Object.freeze({
  color: TOAST_ERROR_COLOR,
  autoHideDuration: TOAST_ERROR_DURATION,
  message: t('SmartAlertsToastsNotifications:CLONE_SMART_TYPE_ERROR_MESSAGE'),
});
export const CLONE_SMART_ALERT_SUCCESS_TOAST: ToastNotificationOptions = Object.freeze({
  color: TOAST_SUCCESS_COLOR,
  autoHideDuration: TOAST_SUCCESS_DURATION,
  message: t('SmartAlertsToastsNotifications:CLONE_SMART_ALERT_SUCCESS_MESSAGE'),
});
export const CLONE_SMART_TEMPLATE_SUCCESS_TOAST: ToastNotificationOptions = Object.freeze({
  color: TOAST_SUCCESS_COLOR,
  autoHideDuration: TOAST_SUCCESS_DURATION,
  message: t('SmartAlertsToastsNotifications:CLONE_SMART_TEMPLATE_SUCCESS_MESSAGE'),
});

export const DELETE_SMART_TYPES_ERROR_TOAST: ToastNotificationOptions = Object.freeze({
  color: TOAST_ERROR_COLOR,
  autoHideDuration: TOAST_ERROR_DURATION,
  message: t('SmartAlertsToastsNotifications:DELETE_SMART_TYPES_ERROR_MESSAGE'),
});
export const DELETE_SMART_TYPES_SUCCESS_TOAST: ToastNotificationOptions = Object.freeze({
  color: TOAST_SUCCESS_COLOR,
  autoHideDuration: TOAST_SUCCESS_DURATION,
  message: t('SmartAlertsToastsNotifications:DELETE_SMART_TYPES_SUCCESS_MESSAGE'),
});

export const GET_SMART_ALERT_ERROR_TOAST: ToastNotificationOptions = Object.freeze({
  color: TOAST_ERROR_COLOR,
  autoHideDuration: TOAST_ERROR_DURATION,
  message: t('SmartAlertsToastsNotifications:GET_SMART_ALERT_ERROR_MESSAGE'),
});
export const GET_SMART_TEMPLATE_ERROR_TOAST: ToastNotificationOptions = Object.freeze({
  color: TOAST_ERROR_COLOR,
  autoHideDuration: TOAST_ERROR_DURATION,
  message: t('SmartAlertsToastsNotifications:GET_SMART_TEMPLATE_ERROR_MESSAGE'),
});

export const UPSERT_SMART_TYPE_ERROR_TOAST: ToastNotificationOptions = Object.freeze({
  color: TOAST_ERROR_COLOR,
  autoHideDuration: TOAST_ERROR_DURATION,
  message: t('SmartAlertsToastsNotifications:UPSERT_SMART_TYPE_ERROR_MESSAGE'),
});
export const CREATE_SMART_ALERT_SUCCESS_TOAST: ToastNotificationOptions = Object.freeze({
  color: TOAST_SUCCESS_COLOR,
  autoHideDuration: TOAST_SUCCESS_DURATION,
  message: t('SmartAlertsToastsNotifications:CREATE_SMART_ALERT_SUCCESS_MESSAGE'),
});
export const CREATE_SMART_TEMPLATE_SUCCESS_TOAST: ToastNotificationOptions = Object.freeze({
  color: TOAST_SUCCESS_COLOR,
  autoHideDuration: TOAST_SUCCESS_DURATION,
  message: t('SmartAlertsToastsNotifications:CREATE_SMART_TEMPLATE_SUCCESS_MESSAGE'),
});
export const UPDATE_SMART_ALERT_SUCCESS_TOAST: ToastNotificationOptions = Object.freeze({
  color: TOAST_SUCCESS_COLOR,
  autoHideDuration: TOAST_SUCCESS_DURATION,
  message: t('SmartAlertsToastsNotifications:UPDATE_SMART_ALERT_SUCCESS_MESSAGE'),
});
export const UPDATE_SMART_TEMPLATE_SUCCESS_TOAST: ToastNotificationOptions = Object.freeze({
  color: TOAST_SUCCESS_COLOR,
  autoHideDuration: TOAST_SUCCESS_DURATION,
  message: t('SmartAlertsToastsNotifications:UPDATE_SMART_TEMPLATE_SUCCESS_MESSAGE'),
});

import { ReactComponent as NoShow } from '../../assets/smartAlerts/noShowIcon.svg';
import { ReactComponent as Cancel } from '../../assets/smartAlerts/cancelIcon.svg';
import { ReactComponent as Confirm } from '../../assets/smartAlerts/confirmIcon.svg';
import { ReactComponent as Custom } from '../../assets/smartAlerts/customIcon.svg';
import { ReactComponent as FollowUp } from '../../assets/smartAlerts/followUpIcon.svg';
import { ReactComponent as ThankYou } from '../../assets/smartAlerts/thankYouIcon.svg';
import { ReactComponent as AdditionalResources } from '../../assets/smartAlerts/additionalResourcesIcon.svg';
import { ReactComponent as Remainder } from '../../assets/smartAlerts/reminderIcon.svg';
import { ReactComponent as Statistic } from '../../assets/smartAlerts/statisticIcon.svg';

export const IconOptions = [
  { value: IconName.NO_SHOW, icon: NoShow },
  { value: IconName.CANCEL, icon: Cancel },
  { value: IconName.CONFIRM, icon: Confirm },
  { value: IconName.CUSTOM, icon: Custom },
  { value: IconName.FOLLOW_UP, icon: FollowUp },
  { value: IconName.THANK_YOU, icon: ThankYou },
  { value: IconName.ADDITIONAL_RESOURCES, icon: AdditionalResources },
  { value: IconName.REMAINDER, icon: Remainder },
  { value: IconName.STATISTIC, icon: Statistic },
];

export const WhenOptions = [
  { value: EventSendType.HOW_LONG_EVENT_BOOKED, label: t('SmartAlertsWhen:HOW_LONG_MEETING_BOOKED') },
  { value: EventSendType.EVENT_ENDS, label: t('SmartAlertsWhen:MEETING_ENDS') },
  { value: EventSendType.EVENT_BEFORE_START, label: t('SmartAlertsWhen:MEETING_BEFORE_START') },
  { value: EventSendType.EVENT_CANCELLED, label: t('SmartAlertsWhen:MEETING_CANCELLED') },
  { value: EventSendType.EVENTS_STARTS, label: t('SmartAlertsWhen:MEETINGS_STARTS') },
  { value: EventSendType.EVENT_RESCHEDULED, label: t('SmartAlertsWhen:MEETING_RESCHEDULED') },
];

export enum DELAY_UNITS {
  'minutes' = 'minutes',
  'hours' = 'hours',
  'days' = 'days',
}

export const DelayUnitsOptions = [
  { value: DELAY_UNITS.minutes, label: t('SmartAlertsWhen:MINUTES') },
  { value: DELAY_UNITS.hours, label: t('SmartAlertsWhen:HOURS') },
  { value: DELAY_UNITS.days, label: t('SmartAlertsWhen:DAYS') },
];

export enum WHOM_SEND {
  'emailToInvitee' = 'emailToInvitee',
  'smsToInvitee' = 'smsToInvitee',
  'emailToHost' = 'emailToHost',
  'smsToHost' = 'smsToHost',
}

export const WhomOptions = [
  { value: WHOM_SEND.emailToInvitee, label: t('SmartAlertsWhom:EMAIL_TO_INVITEE') },
  { value: WHOM_SEND.smsToInvitee, label: t('SmartAlertsWhom:SMS_TO_INVITEE') },
  { value: WHOM_SEND.emailToHost, label: t('SmartAlertsWhom:EMAIL_TO_HOST') },
  { value: WHOM_SEND.smsToHost, label: t('SmartAlertsWhom:SMS_TO_HOST') },
];
