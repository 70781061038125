import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Path } from '../../routing';
import { AppDispatch } from '../../store/rootStore';
import { Preloader } from '../../components/common';
import labels from './labels';

import { Button } from 'primereact/button';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { AccordionIndexes, workspacesSelectors } from '../../store/workspaces';
import {
  WorkspaceBrandingStep,
  WorkspaceSecurity,
  WorkspaceWhatStep,
  WorkspaceWhoStep,
} from '../../components/workspaces';
import { workspacesActions } from '../../store/workspaces/actions';
import { navigationService } from '../../services/NavigationService';
import { userSettingsSelectors } from '../../store/userSettings';
import { ArrowLeftIcon, BoltIcon, ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/24/outline';

export const EditWorkspace = () => {
  const dispatch = useDispatch<AppDispatch>();
  const isFetching = useSelector(workspacesSelectors.selectIsFetching);
  const accordionIndexes = useSelector(workspacesSelectors.selectAccordionIndexes);
  const name = useSelector(workspacesSelectors.selectName);
  const isExistingWorkspace = useSelector(workspacesSelectors.selectIsExistingWorkspace);
  const isSelectedWorkspaceEmpty = useSelector(workspacesSelectors.selectIsSelectedWorkspaceEmpty);
  const isWhatStepValid = useSelector(workspacesSelectors.selectIsWhatStepValid);
  const isWorkspacesReadOnly = useSelector(userSettingsSelectors.selectIsReadOnlyWorkspace);

  useEffect(() => {
    isSelectedWorkspaceEmpty && navigationService.navigateTo(Path.Workspaces);
  }, [isSelectedWorkspaceEmpty]);

  const handleAccordionIndexChange = (value: Partial<AccordionIndexes>) => {
    dispatch(workspacesActions.updateAccordionIndexes(value));
  };

  const handleSave = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation(); // for no close the accordion
    if (!isWhatStepValid) {
      handleAccordionIndexChange({ what: true });
      return;
    }

    dispatch(workspacesActions.saveWorkspaceRequest());
  };

  const handleBack = () => {
    navigationService.navigateTo(Path.Workspaces);
  };

  const generateAccordion = (
    title: string,
    description: string,
    isOpen: boolean,
    setIsOpen: (value: boolean) => void,
    content: React.ReactNode
  ) => (
    <Accordion
      className="sumo-card border-none"
      activeIndex={isOpen ? 0 : null}
      onTabChange={(e) => setIsOpen(e.index === 0)}
    >
      <AccordionTab
        header={
          <div className="flex-left-center px-20px py-8px">
            <div className="flex-1 flex flex-column pb-4px">
              <div className="text-title-s-med text-heavy-100">{title}</div>
              <div className="text-body-s-reg">{description}</div>
            </div>
            <div className="flex-none">
              {isOpen ? <ChevronUpIcon className="icon-20px" /> : <ChevronDownIcon className="icon-20px" />}
            </div>
          </div>
        }
      >
        <div className="px-20px pt-12px pb-24px">{content}</div>
        <div className="border-top-1 border-heavy-20" />
        <div className="flex-left-center gap-6px px-20px py-12px">
          <Button className="w-90px" label={labels.save} onClick={handleSave} disabled={isWorkspacesReadOnly} />
          <Button label={labels.cancel} text onClick={handleBack} />
        </div>
      </AccordionTab>
    </Accordion>
  );

  return (
    <div>
      {isFetching && <Preloader />}

      <Button className="button-blue button-text-line mb-16px" text onClick={handleBack}>
        <ArrowLeftIcon className="icon-18px" />
        <div className="flex-1 pl-8px">{labels.back}</div>
      </Button>

      <div className="sumo-card-bg flex flex-column gap-16px">
        <div className="text-title-lg-med">{isExistingWorkspace ? name : labels.newWorkspace}</div>

        {generateAccordion(
          labels.whatTitle,
          labels.whatDescription,
          accordionIndexes.what,
          (isOpen) => handleAccordionIndexChange({ what: isOpen }),
          <WorkspaceWhatStep />
        )}
        {generateAccordion(
          labels.whoTitle,
          labels.whoDescription,
          accordionIndexes.who,
          (isOpen) => handleAccordionIndexChange({ who: isOpen }),
          <WorkspaceWhoStep />
        )}

        <div className="flex-left-center gap-6px text-heavy-60 pt-24px">
          <BoltIcon className="icon-20px" />
          <div className="text-label-s-med">{labels.additionalConfigurations}</div>
        </div>

        {generateAccordion(
          labels.howTitle,
          labels.howDescription,
          accordionIndexes.how,
          (isOpen) => handleAccordionIndexChange({ how: isOpen }),
          <WorkspaceBrandingStep />
        )}

        {generateAccordion(
          'Security',
          'Export and delete data',
          accordionIndexes.security,
          (isOpen) => handleAccordionIndexChange({ security: isOpen }),
          <WorkspaceSecurity />
        )}
      </div>
    </div>
  );
};
