import { combineReducers } from 'redux';
import { GlobalAction, GlobalActionTypes } from '../global/actions';
import { RolesAction, RolesActionTypes } from './actions';
import { Role } from '../../API';
import { generatePermissionsListOnCheck, generatePermissionsListOnUncheck } from './utils';
import { DEFAULT_FILTER } from './constants';

const roles = (state: Role[] = [], action: RolesAction | GlobalAction) => {
  switch (action.type) {
    case RolesActionTypes.GET_ROLES_SUCCESS:
      return action.payload;
    case GlobalActionTypes.RESET_WHOLE_STORE:
      return [];
    default:
      return state;
  }
};

const role = (state = {} as Role, action: RolesAction | GlobalAction) => {
  switch (action.type) {
    case RolesActionTypes.GET_ROLE_SUCCESS:
    case RolesActionTypes.SET_SELECTED_ROLE:
      return action.payload;
    case RolesActionTypes.UPDATE_SELECTED_ROLE:
      return { ...state, ...action.payload };
    case RolesActionTypes.CHECK_PERMISSION: {
      const { permission, actionType } = action.payload;
      return {
        ...state,
        ...generatePermissionsListOnCheck(permission, actionType, state),
      };
    }
    case RolesActionTypes.UNCHECK_PERMISSION: {
      const { permission, actionType } = action.payload;
      return {
        ...state,
        ...generatePermissionsListOnUncheck(permission, actionType, state),
      };
    }
    case GlobalActionTypes.RESET_WHOLE_STORE:
      return {} as Role;
    default:
      return state;
  }
};

const filter = (state = DEFAULT_FILTER, action: RolesAction | GlobalAction) => {
  switch (action.type) {
    case RolesActionTypes.SET_FILTER:
      return { ...state, ...action.payload };
    case GlobalActionTypes.RESET_WHOLE_STORE:
      return DEFAULT_FILTER;
    default:
      return state;
  }
};

const selectedRoles = (state: string[] = [], action: RolesAction | GlobalAction) => {
  switch (action.type) {
    case RolesActionTypes.SELECT_TEAMS:
      return action.payload;
    case RolesActionTypes.GET_ROLES_REQUEST:
    case GlobalActionTypes.RESET_WHOLE_STORE:
      return [];
    default:
      return state;
  }
};

const lastLoadTime = (state = 0, action: RolesAction | GlobalAction) => {
  switch (action.type) {
    case RolesActionTypes.GET_ROLES_SUCCESS:
      return new Date().getTime();
    case GlobalActionTypes.RESET_WHOLE_STORE:
      return 0;
    default:
      return state;
  }
};

const isFetching = (state = false, action: RolesAction | GlobalAction) => {
  switch (action.type) {
    case RolesActionTypes.GET_ROLES_REQUEST:
    case RolesActionTypes.GET_ROLE_REQUEST:
    case RolesActionTypes.SAVE_ROLE_REQUEST:
    case RolesActionTypes.DELETE_ROLE_REQUEST:
    case RolesActionTypes.DEACTIVATE_ROLE_REQUEST:
      return true;
    case RolesActionTypes.GET_ROLES_SUCCESS:
    case RolesActionTypes.GET_ROLES_FAIL:
    case RolesActionTypes.GET_ROLE_SUCCESS:
    case RolesActionTypes.GET_ROLE_FAIL:
    case RolesActionTypes.SAVE_ROLE_SUCCESS:
    case RolesActionTypes.SAVE_ROLE_FAIL:
    case RolesActionTypes.DELETE_ROLE_SUCCESS:
    case RolesActionTypes.DELETE_ROLE_FAIL:
    case RolesActionTypes.DEACTIVATE_ROLE_SUCCESS:
    case RolesActionTypes.DEACTIVATE_ROLE_FAIL:
      return false;
    case GlobalActionTypes.RESET_WHOLE_STORE:
      return false;
    default:
      return state;
  }
};
const error = (state: unknown | null = null, action: RolesAction | GlobalAction) => {
  switch (action.type) {
    case RolesActionTypes.GET_ROLES_FAIL:
    case RolesActionTypes.GET_ROLE_FAIL:
    case RolesActionTypes.SAVE_ROLE_FAIL:
    case RolesActionTypes.DELETE_ROLE_FAIL:
    case RolesActionTypes.DEACTIVATE_ROLE_FAIL:
      return action.error;
    case RolesActionTypes.GET_ROLES_REQUEST:
    case RolesActionTypes.GET_ROLE_REQUEST:
    case RolesActionTypes.SAVE_ROLE_REQUEST:
    case RolesActionTypes.DELETE_ROLE_REQUEST:
    case RolesActionTypes.DEACTIVATE_ROLE_REQUEST:
      return null;
    case GlobalActionTypes.RESET_WHOLE_STORE:
      return null;
    default:
      return state;
  }
};

export default combineReducers({ isFetching, error, roles, role, filter, selectedRoles, lastLoadTime });
