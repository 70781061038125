import { combineReducers } from 'redux';
import { StaffAction, StaffActionTypes } from './actions';
import { GlobalAction, GlobalActionTypes } from '../../global/actions';
import { Staff } from '../../../API';

const staff = (state: Staff[] = [], action: StaffAction | GlobalAction) => {
  switch (action.type) {
    case StaffActionTypes.GET_STAFF_SUCCESS:
      return action.payload;
    case GlobalActionTypes.RESET_WHOLE_STORE:
      return [];
    default:
      return state;
  }
};

const staffRecord = (state = {} as Staff, action: StaffAction | GlobalAction) => {
  switch (action.type) {
    case StaffActionTypes.SET_STAFF_RECORD:
      return action.payload;
    case StaffActionTypes.UPDATE_STAFF_RECORD:
      return { ...state, ...action.payload };
    case GlobalActionTypes.RESET_WHOLE_STORE:
      return {} as Staff;
    default:
      return state;
  }
};

const isAddNew = (state = false, action: StaffAction | GlobalAction) => {
  switch (action.type) {
    case StaffActionTypes.SET_IS_ADD_NEW:
      return action.payload;
    case StaffActionTypes.SET_STAFF_RECORD:
    case GlobalActionTypes.RESET_WHOLE_STORE:
      return false;
    default:
      return state;
  }
};

const isEdited = (state = false, action: StaffAction | GlobalAction) => {
  switch (action.type) {
    case StaffActionTypes.UPDATE_STAFF_RECORD:
      return true;
    case StaffActionTypes.SET_STAFF_RECORD:
    case GlobalActionTypes.RESET_WHOLE_STORE:
      return false;
    default:
      return state;
  }
};

const isFetching = (state = false, action: StaffAction | GlobalAction) => {
  switch (action.type) {
    case StaffActionTypes.GET_STAFF_REQUEST:
    case StaffActionTypes.SAVE_STAFF_REQUEST:
    case StaffActionTypes.CHANGE_STAFF_STATUS_REQUEST:
    case StaffActionTypes.DELETE_STAFF_REQUEST:
      return true;
    case StaffActionTypes.GET_STAFF_SUCCESS:
    case StaffActionTypes.GET_STAFF_FAIL:
    case StaffActionTypes.SAVE_STAFF_SUCCESS:
    case StaffActionTypes.SAVE_STAFF_FAIL:
    case StaffActionTypes.CHANGE_STAFF_STATUS_SUCCESS:
    case StaffActionTypes.CHANGE_STAFF_STATUS_FAIL:
    case StaffActionTypes.DELETE_STAFF_SUCCESS:
    case StaffActionTypes.DELETE_STAFF_FAIL:
      return false;
    case GlobalActionTypes.RESET_WHOLE_STORE:
      return false;
    default:
      return state;
  }
};

const error = (state: unknown | null = null, action: StaffAction | GlobalAction) => {
  switch (action.type) {
    case StaffActionTypes.GET_STAFF_FAIL:
    case StaffActionTypes.SAVE_STAFF_FAIL:
    case StaffActionTypes.CHANGE_STAFF_STATUS_FAIL:
    case StaffActionTypes.DELETE_STAFF_FAIL:
      return action.error;
    case StaffActionTypes.GET_STAFF_REQUEST:
    case StaffActionTypes.SAVE_STAFF_REQUEST:
    case StaffActionTypes.CHANGE_STAFF_STATUS_REQUEST:
    case StaffActionTypes.DELETE_STAFF_REQUEST:
      return null;
    case GlobalActionTypes.RESET_WHOLE_STORE:
      return null;
    default:
      return state;
  }
};

export default combineReducers({
  isFetching,
  error,
  staff,
  staffRecord,
  isEdited,
  isAddNew,
});
