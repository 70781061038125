import { t } from '../../i18n/i18n';

export default {
  save: t('Common:SAVE'),
  back: t('Common:BACK'),
  cancel: t('Common:CANCEL'),
  cancelYes: t('Common:YES_CANCEL'),
  cancelNo: t('Common:NO'),
  cancelTextPart1: t('Common:ARE_YOU_SURE'),
  cancelTextPart2: t('Common:UNSAVED_CHANGES'),
  clone: t('Common:CLONE'),
  delete: t('Common:DELETE'),
  activate: t('Common:ACTIVATE'),
  deactivate: t('Common:DEACTIVATE'),
  newBookingTemplate: t('BookingTemplates:NEW_BOOKING_TEMPLATE'),
  whatTitle: t('EditBookingTemplate:WHAT_TITLE'),
  whatDescription: t('EditBookingTemplate:WHAT_DESCRIPTION'),
  whoTitle: t('EditBookingTemplate:WHO_TITLE'),
  whoDescription: t('EditBookingTemplate:WHO_DESCRIPTION'),
  whereTitle: t('EditBookingTemplate:WHERE_TITLE'),
  whereDescription: t('EditBookingTemplate:WHERE_DESCRIPTION'),
  whenTitle: t('EditBookingTemplate:WHEN_TITLE'),
  whenDescription: t('EditBookingTemplate:WHEN_DESCRIPTION'),
  howTitle: t('EditBookingTemplate:HOW_TITLE'),
  howDescription: t('EditBookingTemplate:HOW_DESCRIPTION'),
  alertsTitle: t('EditBookingTemplate:ALERTS_TITLE'),
  alertsDescription: t('EditBookingTemplate:ALERTS_DESCRIPTION'),
  inviteeTitle: t('EditBookingTemplate:INVITEE_TITLE'),
  inviteeDescription: t('EditBookingTemplate:INVITEE_DESCRIPTION'),
  afterTitle: t('EditBookingTemplate:AFTER_TITLE'),
  afterDescription: t('EditBookingTemplate:AFTER_DESCRIPTION'),
  additionalConfigurations: t('EditBookingTemplate:ADDITIONAL_CONFIGURATIONS'),
  active: t('EditBookingTemplate:ACTIVE'),
  lockedTooltip: t('EditBookingTemplate:LOCKED_TOOLTIP'),
};
