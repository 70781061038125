import { CreateAdminDataInput, CreateLicenseInput, CreateStaffInput, CreateTenantInput } from '../../API';
import { Path } from '../../routing';
import {
  UserDataCoreType,
  ThirdPartyType,
  InviteToAccount,
  ViewAsUserPayload,
  ResetViewAsUserPayload,
  GetAuthResponseType,
} from './types';

export enum AuthenticationActionTypes {
  THIRD_PARTY_AUTH_REQUEST = 'authentication/THIRD_PARTY_AUTH_REQUEST',
  THIRD_PARTY_AUTH_SUCCESS = 'authentication/THIRD_PARTY_AUTH_SUCCESS',
  THIRD_PARTY_AUTH_FAIL = 'authentication/THIRD_PARTY_AUTH_FAIL',
  DELETE_USER_REQUEST = 'authentication/DELETE_USER_REQUEST',
  DELETE_USER_SUCCESS = 'authentication/DELETE_USER_SUCCESS',
  DELETE_USER_FAIL = 'authentication/USER_DELETE_FAIL',
  LOGOUT_USER_REQUEST = 'authentication/LOGOUT_USER_REQUEST',
  LOGOUT_USER_SUCCESS = 'authentication/LOGOUT_USER_SUCCESS',
  LOGOUT_USER_FAIL = 'authentication/LOGOUT_USER_FAIL',
  UPDATE_USER_DATA_CORE = 'authentication/UPDATE_USER_DATA_CORE',
  SET_THIRD_PARTY = 'authentication/SET_THIRD_PARTY',
  SET_IS_QUICK_SETUP_FINISHED = 'authentication/SET_IS_QUICK_SETUP_FINISHED',
  SET_ACCOUNTS = 'authentication/SET_ACCOUNTS',
  CHOOSE_AN_ACCOUNT_REQUEST = 'authentication/CHOOSE_AN_ACCOUNT_REQUEST',
  CHOOSE_AN_ACCOUNT_SUCCESS = 'authentication/CHOOSE_AN_ACCOUNT_SUCCESS',
  CHOOSE_AN_ACCOUNT_FAIL = 'authentication/CHOOSE_AN_ACCOUNT_FAIL',
  SET_INVITE_TO_ACCOUNT = 'authentication/SET_INVITE_TO_ACCOUNT',
  SET_IS_LAST_SUPER_ADMIN = 'authentication/SET_IS_LAST_SUPER_ADMIN',
  SET_SUMO1_ADMIN_DATA = 'authentication/SET_SUMO1_ADMIN_DATA',
  GET_TENANT_REQUEST = 'authentication/GET_TENANT_REQUEST',
  GET_TENANT_SUCCESS = 'authentication/GET_TENANT_SUCCESS',
  GET_TENANT_FAIL = 'authentication/GET_TENANT_FAIL',
  VIEW_AS_USER_REQUEST = 'authentication/VIEW_AS_USER_REQUEST',
  VIEW_AS_USER_SUCCESS = 'authentication/VIEW_AS_USER_SUCCESS',
  VIEW_AS_USER_FAIL = 'authentication/VIEW_AS_USER_FAIL',
  RESET_VIEW_AS_USER_REQUEST = 'authentication/RESET_VIEW_AS_USER_REQUEST',
  RESET_VIEW_AS_USER_SUCCESS = 'authentication/RESET_VIEW_AS_USER_SUCCESS',
  RESET_VIEW_AS_USER_FAIL = 'authentication/RESET_VIEW_AS_USER_FAIL',
  GET_LICENSE_REQUEST = 'authentication/GET_LICENSE_REQUEST',
  GET_LICENSE_SUCCESS = 'authentication/GET_LICENSE_SUCCESS',
  GET_LICENSE_FAIL = 'authentication/GET_LICENSE_FAIL',
  // SET_COOCKIE_OPEN = 'authentication/SET_COOCKIE_OPEN',
  EXPIRE_LICENSE = 'authentication/EXPIRE_LICENSE',
  SET_IS_OPS_CONSOLE = 'authentication/SET_IS_OPS_CONSOLE',
}

export type AuthenticationAction =
  | { type: AuthenticationActionTypes.THIRD_PARTY_AUTH_REQUEST; payload: GetAuthResponseType }
  | { type: AuthenticationActionTypes.THIRD_PARTY_AUTH_SUCCESS }
  | { type: AuthenticationActionTypes.THIRD_PARTY_AUTH_FAIL; error: unknown }
  | { type: AuthenticationActionTypes.DELETE_USER_REQUEST }
  | { type: AuthenticationActionTypes.DELETE_USER_SUCCESS }
  | { type: AuthenticationActionTypes.DELETE_USER_FAIL; error: unknown }
  | { type: AuthenticationActionTypes.LOGOUT_USER_REQUEST; redirectTo: Path }
  | { type: AuthenticationActionTypes.LOGOUT_USER_SUCCESS }
  | { type: AuthenticationActionTypes.LOGOUT_USER_FAIL; error: unknown }
  | { type: AuthenticationActionTypes.UPDATE_USER_DATA_CORE; payload: Partial<UserDataCoreType> }
  | { type: AuthenticationActionTypes.SET_THIRD_PARTY; payload: ThirdPartyType }
  | { type: AuthenticationActionTypes.SET_IS_QUICK_SETUP_FINISHED; payload: boolean }
  | { type: AuthenticationActionTypes.SET_ACCOUNTS; payload: CreateAdminDataInput[] }
  | { type: AuthenticationActionTypes.CHOOSE_AN_ACCOUNT_REQUEST; payload: CreateAdminDataInput }
  | { type: AuthenticationActionTypes.CHOOSE_AN_ACCOUNT_SUCCESS }
  | { type: AuthenticationActionTypes.CHOOSE_AN_ACCOUNT_FAIL; error: unknown }
  | { type: AuthenticationActionTypes.SET_INVITE_TO_ACCOUNT; payload: InviteToAccount }
  | { type: AuthenticationActionTypes.SET_IS_LAST_SUPER_ADMIN; payload: boolean }
  | { type: AuthenticationActionTypes.SET_SUMO1_ADMIN_DATA; payload: Partial<CreateStaffInput> }
  | { type: AuthenticationActionTypes.GET_TENANT_REQUEST }
  | { type: AuthenticationActionTypes.GET_TENANT_SUCCESS; payload: CreateTenantInput }
  | { type: AuthenticationActionTypes.GET_TENANT_FAIL; error: unknown }
  | { type: AuthenticationActionTypes.VIEW_AS_USER_REQUEST; payload: ViewAsUserPayload }
  | { type: AuthenticationActionTypes.VIEW_AS_USER_SUCCESS }
  | { type: AuthenticationActionTypes.VIEW_AS_USER_FAIL; error: unknown }
  | { type: AuthenticationActionTypes.RESET_VIEW_AS_USER_REQUEST; payload: ResetViewAsUserPayload }
  | { type: AuthenticationActionTypes.RESET_VIEW_AS_USER_SUCCESS }
  | { type: AuthenticationActionTypes.RESET_VIEW_AS_USER_FAIL; error: unknown }
  | { type: AuthenticationActionTypes.GET_LICENSE_REQUEST }
  | { type: AuthenticationActionTypes.GET_LICENSE_SUCCESS; payload: CreateLicenseInput[] }
  | { type: AuthenticationActionTypes.GET_LICENSE_FAIL; error: unknown }
  // | { type: AuthenticationActionTypes.SET_COOCKIE_OPEN; payload: boolean }
  | { type: AuthenticationActionTypes.EXPIRE_LICENSE }
  | { type: AuthenticationActionTypes.SET_IS_OPS_CONSOLE; payload: boolean };

const thirdPartyAuthRequest = (payload: GetAuthResponseType): AuthenticationAction => ({
  type: AuthenticationActionTypes.THIRD_PARTY_AUTH_REQUEST,
  payload,
});
const thirdPartyAuthSuccess = (): AuthenticationAction => ({
  type: AuthenticationActionTypes.THIRD_PARTY_AUTH_SUCCESS,
});
const thirdPartyAuthFail = (error: unknown): AuthenticationAction => ({
  type: AuthenticationActionTypes.THIRD_PARTY_AUTH_FAIL,
  error,
});
const deleteUserRequest = (): AuthenticationAction => ({
  type: AuthenticationActionTypes.DELETE_USER_REQUEST,
});
const deleteUserSuccess = (): AuthenticationAction => ({
  type: AuthenticationActionTypes.DELETE_USER_SUCCESS,
});
const deleteUserFail = (error: unknown): AuthenticationAction => ({
  type: AuthenticationActionTypes.DELETE_USER_FAIL,
  error,
});
const logoutUserRequest = (redirectTo: Path): AuthenticationAction => ({
  type: AuthenticationActionTypes.LOGOUT_USER_REQUEST,
  redirectTo,
});
const logoutUserSuccess = (): AuthenticationAction => ({
  type: AuthenticationActionTypes.LOGOUT_USER_SUCCESS,
});
const logoutUserFail = (error: unknown): AuthenticationAction => ({
  type: AuthenticationActionTypes.LOGOUT_USER_FAIL,
  error,
});
const updateUserDataCore = (payload: Partial<UserDataCoreType>): AuthenticationAction => ({
  type: AuthenticationActionTypes.UPDATE_USER_DATA_CORE,
  payload,
});
const setThirdParty = (payload: ThirdPartyType): AuthenticationAction => ({
  type: AuthenticationActionTypes.SET_THIRD_PARTY,
  payload,
});
const setIsQuickSetupFinished = (payload: boolean): AuthenticationAction => ({
  type: AuthenticationActionTypes.SET_IS_QUICK_SETUP_FINISHED,
  payload,
});
const setAccounts = (payload: CreateAdminDataInput[]): AuthenticationAction => ({
  type: AuthenticationActionTypes.SET_ACCOUNTS,
  payload,
});
const chooseAnAccountRequest = (payload: CreateAdminDataInput): AuthenticationAction => ({
  type: AuthenticationActionTypes.CHOOSE_AN_ACCOUNT_REQUEST,
  payload,
});
const chooseAnAccountSuccess = (): AuthenticationAction => ({
  type: AuthenticationActionTypes.CHOOSE_AN_ACCOUNT_SUCCESS,
});
const chooseAnAccountFail = (error: unknown): AuthenticationAction => ({
  type: AuthenticationActionTypes.CHOOSE_AN_ACCOUNT_FAIL,
  error,
});
const setInviteToAccount = (payload: InviteToAccount): AuthenticationAction => ({
  type: AuthenticationActionTypes.SET_INVITE_TO_ACCOUNT,
  payload,
});
const setIsLastSuperAdmin = (payload: boolean): AuthenticationAction => ({
  type: AuthenticationActionTypes.SET_IS_LAST_SUPER_ADMIN,
  payload,
});
const setSUMO1AdminData = (payload: Partial<CreateStaffInput>): AuthenticationAction => ({
  type: AuthenticationActionTypes.SET_SUMO1_ADMIN_DATA,
  payload,
});
const getTenantRequest = (): AuthenticationAction => ({
  type: AuthenticationActionTypes.GET_TENANT_REQUEST,
});
const getTenantSuccess = (payload: CreateTenantInput): AuthenticationAction => ({
  type: AuthenticationActionTypes.GET_TENANT_SUCCESS,
  payload,
});
const getTenantFail = (error: unknown): AuthenticationAction => ({
  type: AuthenticationActionTypes.GET_TENANT_FAIL,
  error,
});

const viewAsUserRequest = (payload: ViewAsUserPayload): AuthenticationAction => ({
  type: AuthenticationActionTypes.VIEW_AS_USER_REQUEST,
  payload,
});
const viewAsUserSuccess = (): AuthenticationAction => ({
  type: AuthenticationActionTypes.VIEW_AS_USER_SUCCESS,
});
const viewAsUseFail = (error: unknown): AuthenticationAction => ({
  type: AuthenticationActionTypes.VIEW_AS_USER_FAIL,
  error,
});

const resetViewAsUserRequest = (payload: ResetViewAsUserPayload): AuthenticationAction => ({
  type: AuthenticationActionTypes.RESET_VIEW_AS_USER_REQUEST,
  payload,
});
const resetViewAsUserSuccess = (): AuthenticationAction => ({
  type: AuthenticationActionTypes.RESET_VIEW_AS_USER_SUCCESS,
});
const resetViewAsUserFail = (error: unknown): AuthenticationAction => ({
  type: AuthenticationActionTypes.RESET_VIEW_AS_USER_FAIL,
  error,
});

const getLicenseRequest = (): AuthenticationAction => ({
  type: AuthenticationActionTypes.GET_LICENSE_REQUEST,
});
const getLicenseSuccess = (payload: CreateLicenseInput[]): AuthenticationAction => ({
  type: AuthenticationActionTypes.GET_LICENSE_SUCCESS,
  payload,
});
const getLicenseFail = (error: unknown): AuthenticationAction => ({
  type: AuthenticationActionTypes.GET_LICENSE_FAIL,
  error,
});
const expireLicense = (): AuthenticationAction => ({
  type: AuthenticationActionTypes.EXPIRE_LICENSE,
});

const setIsOpsConsole = (payload: boolean): AuthenticationAction => ({
  type: AuthenticationActionTypes.SET_IS_OPS_CONSOLE,
  payload,
});

// const setCookieOpen = (payload: boolean): AuthenticationAction => ({
//   type: AuthenticationActionTypes.SET_COOCKIE_OPEN,
//   payload,
// });

export const authenticationActions = {
  thirdPartyAuthRequest,
  thirdPartyAuthSuccess,
  thirdPartyAuthFail,
  deleteUserRequest,
  deleteUserSuccess,
  deleteUserFail,
  getLicenseRequest,
  getLicenseSuccess,
  getLicenseFail,
  logoutUserRequest,
  logoutUserSuccess,
  logoutUserFail,
  updateUserDataCore,
  setThirdParty,
  setIsQuickSetupFinished,
  setAccounts,
  chooseAnAccountRequest,
  chooseAnAccountSuccess,
  chooseAnAccountFail,
  setInviteToAccount,
  setIsLastSuperAdmin,
  setSUMO1AdminData,
  getTenantRequest,
  getTenantSuccess,
  getTenantFail,
  viewAsUserRequest,
  viewAsUserSuccess,
  viewAsUseFail,
  resetViewAsUserRequest,
  resetViewAsUserSuccess,
  resetViewAsUserFail,
  setIsOpsConsole,
  expireLicense,
};
