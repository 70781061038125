import { GetUserDetailsRequest, UserDetails, GetUserDetailsListResponse } from './types';
import { handleAPIRequest } from '../../utils/reduxUtils';
import { API_PUBLIC } from '../../../types/constants';

// ***
// When you change the requests do not forget to specify new data
// in docs/Endpoints.md
// ***

export const getUsers = async (): Promise<UserDetails[]> => {
  const response = await handleAPIRequest<GetUserDetailsListResponse>(
    `${API_PUBLIC}/opsconsole/getUserDetailsList`,
    {}
  );
  return response.userDetails;
};

export const getUserDetails = async (payload: GetUserDetailsRequest): Promise<UserDetails> => {
  return handleAPIRequest<UserDetails>(`${API_PUBLIC}/opsconsole/getUserDetails`, payload);
};
