import { useDispatch, useSelector } from 'react-redux';
import { eventSelectors } from '../../../store/publicBookingPage';
import { eventThunks } from '../../../store/publicBookingPage/thunks';
import { AppDispatch } from '../../../store/rootStore';
import { Button } from 'primereact/button';
import { ArrowLeftIcon } from '@heroicons/react/24/outline';
import labels from './labels';

export const PublicBookingPageJourneyDestination = () => {
  const dispatch = useDispatch<AppDispatch>();
  const currentStep = useSelector(eventSelectors.selectJourneyCurrentStep);

  const handleBack = () => {
    dispatch(eventThunks.setPreviousJourneyStep());
  };

  return (
    <div className="flex flex-column h-full">
      <div className="flex-1 flex flex-column gap-24px py-32px px-28px">
        <div className="text-heading-lg-med text-heavy-100">{currentStep?.destination?.message?.header}</div>
        <div className="text-body-lg-reg text-heavy-80 white-space-pre-line">
          {currentStep?.destination?.message?.body}
        </div>
      </div>

      <div className="flex-none px-28px py-12px">
        <Button className="button-xl min-w-120px flex-center gap-8px" outlined onClick={handleBack}>
          <ArrowLeftIcon className="icon-18px" />
          <div>{labels.back}</div>
        </Button>
      </div>
    </div>
  );
};
