import { t } from '../../i18n/i18n';

export default {
  title: t('Availability:TITLE'),
  cancel: t('Common:CANCEL'),
  description: t('Availability:DESCRIPTION'),
  dialogTitle: t('Availability:DIALOG_TITLE'),
  dialogNewTitleName: t('Availability:DIALOG_NEW_NAME_TITLE'),
  dialogPlaceholder: t('Availability:DIALOG_PLACEHOLDER'),
  duplicateNameMessage: t('Availability:DUPLICATE_NAME_MESSAGE'),
  newScheduleButton: t('Availability:NEW_SCHEDULE'),
  save: t('Common:SAVE'),
  create: t('Common:CREATE'),
  search: t('Common:SEARCH'),
};
