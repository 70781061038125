import { useDispatch, useSelector } from 'react-redux';
import {
  bookingPagesActions,
  bookingPageSelectors,
  DELETE_BOOKING_PAGES_MODAL_NAME,
  deleteBookingPagesModalActions,
} from '../../../store/bookingPages';
import { Modal } from '../../common';
import labels from './labels';
import { Button } from 'primereact/button';
import { globalActions } from '../../../store/global';

export const BookingPageDeleteModal = () => {
  const dispatch = useDispatch();
  const selectedBookingPages = useSelector(bookingPageSelectors.selectSelectedBookingPages);

  const handleSave = () => {
    dispatch(bookingPagesActions.deleteBookingPagesRequest());
    dispatch(deleteBookingPagesModalActions.closeModal());
    dispatch(globalActions.setSearch({ searchString: '' })); // clear the search value
  };

  const handleCancel = () => {
    dispatch(deleteBookingPagesModalActions.closeModal());
    dispatch(bookingPagesActions.unselectAllBookingPage());
  };

  return (
    <Modal.Container name={DELETE_BOOKING_PAGES_MODAL_NAME} className="w-440px">
      <Modal.Header>{labels.confirmDelete}</Modal.Header>
      <div className="text-body-lg-reg">
        {selectedBookingPages.length > 1 ? labels.deleteBookingPagesDialogText : labels.deleteBookingPageDialogText}
      </div>
      <Modal.Buttons>
        <Button label={labels.delete} className="min-w-120px" onClick={handleSave} autoFocus />
        <Button label={labels.cancel} onClick={handleCancel} text />
      </Modal.Buttons>
    </Modal.Container>
  );
};
