import { t } from '../../../i18n/i18n';
import { WorkspaceIntegrationType } from '../../../API';

type SetUpDescType = Partial<Record<WorkspaceIntegrationType, string>>;

const setUpDesc: SetUpDescType = {
  [WorkspaceIntegrationType.GOOGLE_ANALYTICS]: t('ConnectIntegrationPage:GOOGLE_ANALYTICS_SETUP_DESC') as string,
};

export default {
  setUp: t('Common:SET_UP'),
  save: t('Common:SAVE'),
  cancel: t('Common:CANCEL'),
  fillOneField: t('IntegrationPage:FILL_ONE_FIELD'),
  setUpDesc
};