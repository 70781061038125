import ct from 'countries-and-timezones';
import countries from 'i18n-iso-countries';

(async () => {
  const localeEn = await import('i18n-iso-countries/langs/en.json');
  const localeFr = await import('i18n-iso-countries/langs/fr.json');
  const localeEs = await import('i18n-iso-countries/langs/es.json');
  const localeDe = await import('i18n-iso-countries/langs/de.json');
  const localePt = await import('i18n-iso-countries/langs/pt.json');
  countries.registerLocale(localeEn);
  countries.registerLocale(localeFr);
  countries.registerLocale(localeEs);
  countries.registerLocale(localeDe);
  countries.registerLocale(localePt);
})();

class CountriesTimeZonesService {
  getCountryById(id: ct.CountryCode): ct.Country {
    return ct.getCountry(id);
  }

  getTimezoneByName(name: string): ct.Timezone | null {
    return ct.getTimezone(name);
  }

  getAllTimeZones(): ct.Timezone[] {
    const timeZonesObject: Record<ct.TimezoneName, ct.Timezone> = ct.getAllTimezones();
    return Object.values(timeZonesObject);
  }

  getCountries() {
    return countries;
  }

  getCountriesOptions(lang: string, pinnedValues: string[] = []) {
    return Object.keys(countries.getNames(lang))
      .map((country: string) => ({
        value: country || '',
        label: countries.getName(country, lang) || '',
      }))
      .sort((a, b) => {
        const aIsPinned = pinnedValues.includes(a.value);
        const bIsPinned = pinnedValues.includes(b.value);

        if (aIsPinned !== bIsPinned) {
          return aIsPinned ? -1 : 1; // pinned value at the top
        }

        return a.label.localeCompare(b.label);
      });
  }
}

export const countriesTimeZonesService = new CountriesTimeZonesService();
