import { v4 as getId } from 'uuid';
import { t } from '../../i18n/i18n';
import {
  CustomFieldType,
  TimeUnit,
  DateRangeType,
  UpdateBookingTemplateInput,
  InPersonType,
  PhoneCallType,
  ConfirmationsPageType,
  CustomFieldInput,
  LocationType,
  CustomType,
  TimeFormat,
  CalendarFormat,
  BookingPageLabelsInput,
  BookingPageStyleInput,
  TimeZoneType,
  BackgroundType,
} from '../../API';
import { ToastNotificationOptions } from '../notifications';
import { AccordionIndexes, FilterType } from './types';
import urlConstants from '../../shared/JSON/urlConstants.json';
import {
  TOAST_ERROR_COLOR,
  TOAST_ERROR_DURATION,
  TOAST_SUCCESS_COLOR,
  TOAST_SUCCESS_DURATION,
} from '../../types/constants';

export const MIN_DURATION_COUNT = 5;

export const COLORS = [
  '#da4d00',
  '#df748b',
  '#c85cfc',
  '#7649f3',
  '#5d9bfe',
  '#8ce9f1',
  '#8be989',
  '#d7f024',
  '#f1e43d',
  '#e6a406',
];
export const DEFAULT_COLOR = COLORS[4];
export const DEFAULT_SMS_TIMING = 1440;
export const MAX_SMS_REMINDERS_COUNT = 3;
export const MULTISELECT_DELIMITER = ';';

export const DEFAULT_INVITEE_NAME_LABEL = t('EditBookingTemplateQuestionsStep:NAME_LABEL');
export const DEFAULT_INVITEE_EMAIL_LABEL = t('EditBookingTemplateQuestionsStep:EMAIL_LABEL');
export const DEFAULT_INVITEE_PHONE_LABEL = t('Common:PHONE_NUMBER');
export const DEFAULT_INVITEE_LOCATION_LABEL = t('BookedMeetings:LOCATION_TITLE');
export const DEFAULT_TEMPLATE_NAME = t('DefaultBookingTemplate:NAME');

/* export const DEFAULT_CONFIRMATION_SUBJECT = t('DefaultBookingTemplate:CONFIRMATION_SUBJECT');
export const DEFAULT_RESCHEDULE_SUBJECT = t('DefaultBookingTemplate:RESCHEDULE_SUBJECT');
export const DEFAULT_REMINDER_SUBJECT = t('DefaultBookingTemplate:REMINDER_SUBJECT');
export const DEFAULT_CANCELATION_SUBJECT = t('DefaultBookingTemplate:CANCELATION_SUBJECT');
export const DEFAULT_FOLLOW_UP_SUBJECT = t('DefaultBookingTemplate:FOLLOW_UP_SUBJECT');
export const DEFAULT_CONFIRMATION_BODY = t('DefaultBookingTemplate:CONFIRMATION_BODY');
export const DEFAULT_RESCHEDULE_BODY = t('DefaultBookingTemplate:RESCHEDULE_BODY');
export const DEFAULT_REMINDER_BODY = t('DefaultBookingTemplate:REMINDER_BODY');
export const DEFAULT_CANCELATION_BODY = t('DefaultBookingTemplate:CANCELATION_BODY');
export const DEFAULT_FOLLOW_UP_BODY = t('DefaultBookingTemplate:FOLLOW_UP_BODY');
export const DEFAULT_SMS_TEXT = t('DefaultBookingTemplate:SMS_TEXT'); */

export const DEFAULT_CONFIRMATION_SUBJECT =
  'New Meeting: <Meeting Name> with <Host Name> at <Meeting Time>, <Meeting Date>';
export const DEFAULT_CONFIRMATION_BODY =
  'Hi <Invitee Name>,\n\n' +
  'Your new meeting <Meeting Name> with <Host Name> at <Meeting Time>, <Meeting Date> is scheduled.\n\n' +
  '<Meeting Location>\n\n' +
  '<Note from Host>\n\n' +
  '<Note from Invitee>\n\n' +
  '<Additional Attendees>\n\n' +
  '<Invitee Questions>\n\n' +
  '<Meeting Button>\n\n' +
  '<Cancel Policy>';
export const DEFAULT_RESCHEDULE_SUBJECT =
  'Rescheduled: <Meeting Name> with <Host Name> at <Meeting Time>, <Meeting Date>';
export const DEFAULT_RESCHEDULE_BODY =
  'Hi <Invitee Name>,\n\n' +
  'Your meeting <Meeting Name> with <Host Name> is rescheduled.\n\n' +
  '<b>Updated Time and Date</b>\n' +
  '<Meeting Time>, <Meeting Date>\n\n' +
  '<Meeting Location>\n\n' +
  '<Meeting Button>';
export const DEFAULT_REMINDER_SUBJECT =
  'Reminder: <Meeting Name> with <Host Name> at <Meeting Time>, <Meeting Date>';
export const DEFAULT_REMINDER_BODY =
  'Hi <Invitee Name>,\n\n' +
  'This is a friendly reminder that your <Meeting Name> with <Host Name> is at <Meeting Time>, <Meeting Date>.\n\n' +
  '<Meeting Location>\n\n' +
  '<Meeting Button>';
export const DEFAULT_CANCELATION_SUBJECT =
  'Cancelled: <Meeting Name> with <Host Name> at <Meeting Time>, <Meeting Date>';
export const DEFAULT_CANCELATION_BODY =
  'Hi <Invitee Name>,\n\n' +
  'Your meeting <Meeting Name> with <Host Name> at <Meeting Time>, <Meeting Date> has been cancelled.';
export const DEFAULT_FOLLOW_UP_SUBJECT =
  'Follow-Up: <Meeting Name> with <Host Name> at <Meeting Time>, <Meeting Date>';
export const DEFAULT_FOLLOW_UP_BODY =
  'Hi <Invitee Name>,\n\n' +
  'We hope your meeting <Meeting Name> with <Host Name> at <Meeting Time>, <Meeting Date> was productive.\n\n' +
  'If you have any feedback or need further assistance, please feel free to reach out to us.\n\n';
export const DEFAULT_SMS_TEXT =
  'This is a friendly reminder that your <Meeting Name> with <Host Name> is at <Meeting Time>, <Meeting Date>. ' +
  'View or edit meeting <Meeting Link>';

export const DEFAULT_WHEN = {
  duration: {
    timeUnit: TimeUnit.MINUTE,
    count: 30,
  },
  scheduleBuffer: {
    timeUnit: TimeUnit.HOUR,
    count: 12,
  },
  dateRange: {
    type: DateRangeType.DAYS_IN_FUTURE,
    count: 30,
  },
  afterEndTime: null,
  beforeStartTime: null,
};
export const DEFAULT_WHERE = {
  locationTypes: [LocationType.PHONE_CALL, LocationType.VIDEO_CONFERENCE],
  defaultLocationType: LocationType.VIDEO_CONFERENCE,
  inPersonType: InPersonType.CUSTOM_ADDRESS,
  customAddress: null,
  locations: null,
  phoneCallType: PhoneCallType.HOST_PHONE_NUMBER,
  customPhone: '',
  skipTheWhereStep: true,
};
export const DEFAULT_INPUT_FIELDS = [
  {
    id: getId(),
    fieldType: CustomFieldType.NAME,
    type: CustomType.TEXT,
    enabled: true,
    required: true,
    label: DEFAULT_INVITEE_NAME_LABEL,
  },
  {
    id: getId(),
    fieldType: CustomFieldType.EMAIL,
    type: CustomType.TEXT,
    enabled: true,
    required: true,
    label: DEFAULT_INVITEE_EMAIL_LABEL,
  },
];

export const DEFAULT_INPUT_SMS_FIELD = {
  id: getId(),
  fieldType: CustomFieldType.PHONE,
  type: CustomType.PHONE,
  enabled: true,
  required: false,
  label: DEFAULT_INVITEE_PHONE_LABEL,
};

export const DEFAULT_INPUT_PHONE_FIELD = {
  id: getId(),
  fieldType: CustomFieldType.PHONE,
  type: CustomType.PHONE,
  enabled: true,
  required: true,
  label: DEFAULT_INVITEE_PHONE_LABEL,
};

export const DEFAULT_INPUT_LOCATION_FIELD = {
  id: getId(),
  fieldType: CustomFieldType.LOCATION,
  type: CustomType.TEXT,
  enabled: true,
  required: true,
  label: DEFAULT_INVITEE_LOCATION_LABEL,
};

export const DEFAULT_NOTIFICATIONS = {
  confirmations: {
    enabled: true,
    subject: DEFAULT_CONFIRMATION_SUBJECT,
    body: DEFAULT_CONFIRMATION_BODY,
  },
  reschedule: {
    enabled: true,
    subject: DEFAULT_RESCHEDULE_SUBJECT,
    body: DEFAULT_RESCHEDULE_BODY,
  },
  reminders: {
    enabled: false,
    subject: DEFAULT_REMINDER_SUBJECT,
    body: DEFAULT_REMINDER_BODY,
    timing: [DEFAULT_SMS_TIMING],
  },
  cancelations: {
    enabled: true,
    subject: DEFAULT_CANCELATION_SUBJECT,
    body: DEFAULT_CANCELATION_BODY,
  },
  followUp: {
    enabled: false,
    subject: DEFAULT_FOLLOW_UP_SUBJECT,
    body: DEFAULT_FOLLOW_UP_BODY,
    timing: [DEFAULT_SMS_TIMING],
  },
  smsReminder: {
    enabled: false,
    body: DEFAULT_SMS_TEXT,
    timing: [DEFAULT_SMS_TIMING],
  },
};
export const DEFAULT_CONFIRMATIONS = {
  type: ConfirmationsPageType.OUR_PAGE,
  externalLink: null,
  anotherEventEnabled: true,
  rescheduleEnabled: true,
  cancelEnabled: true,
  customLinks: null,
};

export const TIME_FORMAT_OPTIONS = {
  [TimeFormat.HOUR_12]: t('BookingPageCalendarModal:HOUR_12'),
  [TimeFormat.HOUR_24]: t('BookingPageCalendarModal:HOUR_24'),
};

export const DEFAULT_INTERVAL_COUNT = 15;
export const DEFAULT_BOOKING_PAGE_NAME = t('DefaultBookingPage:NAME');
export const DEFAULT_BOOKING_PAGE_HOW: BookingPageLabelsInput = {
  eventNameLabel: t('DefaultBookingPage:BOOK_A_MEETING'),
  detailsLabel: t('DefaultBookingPage:MEETING_DETAILS'),
  guestsLabel: t('DefaultBookingPage:GUESTS_INFO'),
  cancelLabel: t('DefaultBookingPage:CANCEL'),
  bookButtonLabel: t('DefaultBookingPage:BOOK_MEETING_BUTTON'),
  updateButtonLabel: t('DefaultBookingPage:SAVE_MEETING_BUTTON'),
  bookAnotherButtonLabel: t('DefaultBookingPage:BOOK_ANOTHER_MEETING_BUTTON'),
  cancelButtonLabel: t('DefaultBookingPage:CANCEL_MEETING_BUTTON'),
  confirmCancelButtonLabel: t('DefaultBookingPage:CONFIRM_CANCEL_BUTTON'),
  bookTitle: t('DefaultBookingPage:MEETING_BOOKED_TITLE'),
  bookDescription: t('DefaultBookingPage:MEETING_BOOKED_DESCRIPTION'),
  rescheduleTitle: t('DefaultBookingPage:MEETING_RESCHEDULED_TITLE'),
  rescheduleDescription: t('DefaultBookingPage:MEETING_RESCHEDULED_DESCRIPTION'),
  cancelTitle: t('DefaultBookingPage:MEETING_CANCELED_TITLE'),
  cancelDescription: t('DefaultBookingPage:MEETING_CANCELED_DESCRIPTION'),
};
export const DEFAULT_STYLE: BookingPageStyleInput = {
  logoImage: null,
  backgroundImage: null,
  backgroundColor: '#f7f7f7',
  backgroundType: BackgroundType.BLANK,
  primaryColor: '#0066e0',
  css: null,
  footerHtml: `<a href="${urlConstants.NAME_URL_SITE}" target="_blank">Powered by SUMO</a>`,
};

export const DEFAULT_BOOKING_TEMPLATE_DATA: UpdateBookingTemplateInput = {
  id: '',
  enabled: true,
  workspaceId: '',
  what: {
    customName: DEFAULT_TEMPLATE_NAME,
    color: DEFAULT_COLOR,
  },
  when: DEFAULT_WHEN,
  potentialHosts: [],
  where: DEFAULT_WHERE,
  inviteOthers: false,
  enterNote: false,
  labels: DEFAULT_BOOKING_PAGE_HOW,
  style: DEFAULT_STYLE,
  calendar: {
    timeFormat: TimeFormat.HOUR_12,
    calendarFormat: CalendarFormat.MONTH_VIEW,
    timeInterval: DEFAULT_INTERVAL_COUNT,
    topOfInterval: false,
    timeZoneType: TimeZoneType.DETECT_INVITEE,
    selectedTimeZone: [],
    defaultTimeZone: null,
  },
  inputFields: DEFAULT_INPUT_FIELDS,
  notifications: DEFAULT_NOTIFICATIONS,
  confirmationsPage: DEFAULT_CONFIRMATIONS,
  smartAlertIds: [],
};

export const DEFAULT_CUSTOM_FEILD: CustomFieldInput = {
  id: '',
  enabled: true,
  label: '',
  required: false,
  fieldType: CustomFieldType.CUSTOM,
  type: CustomType.TEXT,
  answers: [],
};

export const DURATION_TIME_UNIT_OPTIONS = [
  { value: TimeUnit.MINUTE, label: t('Common:MINUTES') },
  { value: TimeUnit.HOUR, label: t('Common:HOURS') },
];

export const SCHEDULE_BUFFER_OPTIONS = [
  { value: TimeUnit.MINUTE, label: t('Common:MINUTES') },
  { value: TimeUnit.HOUR, label: t('Common:HOURS') },
  { value: TimeUnit.DAY, label: t('Common:DAYS') },
];

export const LOCATION_TYPE_OPTIONS = [
  { value: LocationType.PHONE_CALL, label: t('EditBookingTemplateWhereStep:PHONE_CALL') },
  { value: LocationType.IN_PERSON, label: t('EditBookingTemplateWhereStep:IN_PERSON') },
  { value: LocationType.VIDEO_CONFERENCE, label: t('EditBookingTemplateWhereStep:VIDEO_CONFERENCE') },
];

// export const VIDEO_LOCATION_TYPES = [LocationType.ZOOM, LocationType.GOOGLE_MEET, LocationType.MICROSOFT_TEAMS];

export const CLONE_BOOKING_TEMPLATE_ERROR_TOAST: ToastNotificationOptions = Object.freeze({
  color: TOAST_ERROR_COLOR,
  autoHideDuration: TOAST_ERROR_DURATION,
  message: t('BookingTemplatesToastsNotifications:CLONE_BOOKING_TEMPLATES_ERROR_MESSAGE'),
});
export const CLONE_BOOKING_TEMPLATE_SUCCESS_TOAST: ToastNotificationOptions = Object.freeze({
  color: TOAST_SUCCESS_COLOR,
  autoHideDuration: TOAST_SUCCESS_DURATION,
  message: t('BookingTemplatesToastsNotifications:CLONE_BOOKING_TEMPLATES_SUCCESS_MESSAGE'),
});
export const DELETE_BOOKING_TEMPLATES_ERROR_TOAST: ToastNotificationOptions = Object.freeze({
  color: TOAST_ERROR_COLOR,
  autoHideDuration: TOAST_ERROR_DURATION,
  message: t('BookingTemplatesToastsNotifications:DELETE_BOOKING_TEMPLATES_ERROR_MESSAGE'),
});
export const DELETE_BOOKING_TEMPLATES_SUCCESS_TOAST: ToastNotificationOptions = Object.freeze({
  color: TOAST_SUCCESS_COLOR,
  autoHideDuration: TOAST_SUCCESS_DURATION,
  message: t('BookingTemplatesToastsNotifications:DELETE_BOOKING_TEMPLATES_SUCCESS_MESSAGE'),
});
export const GET_BOOKING_TEMPLATES_ERROR_TOAST: ToastNotificationOptions = Object.freeze({
  color: TOAST_ERROR_COLOR,
  autoHideDuration: TOAST_ERROR_DURATION,
  message: t('BookingTemplatesToastsNotifications:GET_BOOKING_TEMPLATES_ERROR_MESSAGE'),
});
export const SAVE_BOOKING_TEMPLATE_ERROR_TOAST: ToastNotificationOptions = Object.freeze({
  color: TOAST_ERROR_COLOR,
  autoHideDuration: TOAST_ERROR_DURATION,
  message: t('BookingTemplatesToastsNotifications:SAVE_BOOKING_TEMPLATE_ERROR_MESSAGE'),
});
export const SAVE_BOOKING_TEMPLATE_SUCCESS_TOAST: ToastNotificationOptions = Object.freeze({
  color: TOAST_SUCCESS_COLOR,
  autoHideDuration: TOAST_SUCCESS_DURATION,
  message: t('BookingTemplatesToastsNotifications:SAVE_BOOKING_TEMPLATE_SUCCESS_MESSAGE'),
});

export const LOCATIONS_LIST = ['Minsk', 'Vilnius', 'Warsaw', 'Blanes'];

export const MERGE_FIELD_OPTIONS = [
  { name: 'Meeting Name', code: 'name' },
  { name: 'Meeting Date', code: 'date' },
  { name: 'Meeting Time', code: 'time' },
  { name: 'Meeting Location', code: 'location' },
  { name: 'Meeting Description', code: 'description' },
  { name: 'Meeting Button', code: 'meetingButton' },
  { name: 'Meeting Link', code: 'link' },
  { name: 'Meeting Cancel Reason', code: 'cancelReason' },
  { name: 'Meeting Instructions', code: 'meetingInstructions' },

  { name: 'Host Name', code: 'hostName' },
  { name: 'Host Email', code: 'hostEmail' },
  { name: 'Host Phone', code: 'hostPhone' },
  { name: 'Host Phone Details', code: 'hostDetails' },

  { name: 'Invitee Name', code: 'inviteeName' },
  { name: 'Invitee Email', code: 'inviteeEmail' },
  { name: 'Invitee Phone', code: 'inviteePhone' },

  { name: 'Note from Host', code: 'noteFromHost' },
  { name: 'Note from Invitee', code: 'noteFromInvitee' },
  { name: 'Additional Attendees', code: 'additionalAttendees' },
  { name: 'Invitee Questions', code: 'inviteeQuestions' },
  { name: 'Cancel Policy', code: 'cancelPolicy' },

  { name: 'Booking Page', code: 'bookingPage' },
];

export const MERGE_FIELD_SUBJECT_OPTIONS = [
  { name: 'Meeting Name', code: 'name' },
  { name: 'Meeting Date', code: 'date' },
  { name: 'Meeting Time', code: 'time' },
  { name: 'Host Name', code: 'hostName' },
  { name: 'Host Email', code: 'hostEmail' },
  { name: 'Invitee Name', code: 'inviteeName' },
  { name: 'Invitee Email', code: 'inviteeEmail' },
];

export const FILTER_OPTIONS = [
  { value: FilterType.MY, label: t('BookingTemplates:FILTER_MY_TEMPLATES') },
  { value: FilterType.ALL, label: t('BookingTemplates:FILTER_ALL_TEMPLATES') },
];

export const EMPTY_ACCORDION_INDEXES: AccordionIndexes = {
  what: false,
  who: false,
  where: false,
  when: false,
  how: false,
  alerts: false,
  invitee: false,
  after: false,
};
