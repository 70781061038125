import { Button, ButtonProps } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { ReactNode } from 'react';

type ConfirmationModalProps = {
  visible: boolean;
  title: string;
  description?: string;
  additionalText?: string[];
  confirmButtonLabel?: string;
  cancelButtonLabel: string;
  onConfirm?: () => void;
  onCancel: () => void;
  confirmButtonProps?: ButtonProps;
  cancelButtonProps?: ButtonProps;
  onClose: () => void;
  CustomConfirmButton?: ReactNode;
  modalClassName?: string;
  ExtraElements?: ReactNode;
};

export const ConfirmationModal: React.FC<ConfirmationModalProps> = ({
  visible,
  title,
  description,
  additionalText,
  confirmButtonLabel,
  cancelButtonLabel,
  onConfirm,
  onCancel,
  confirmButtonProps,
  cancelButtonProps,
  onClose,
  CustomConfirmButton,
  modalClassName,
  ExtraElements,
}) => {
  return (
    <Dialog
      visible={visible}
      onHide={onClose}
      header={title}
      footer={
        <>
          {CustomConfirmButton ||
            (confirmButtonLabel && <Button label={confirmButtonLabel} {...confirmButtonProps} onClick={onConfirm} />)}
          <Button onClick={onCancel} text label={cancelButtonLabel} {...cancelButtonProps} />
        </>
      }
      resizable={false}
      focusOnShow={false}
      className={modalClassName || 'w-440px'}
    >
      <div className="flex flex-column gap-16px">
        <div className="white-space-pre-line">{description}</div>
        {additionalText && additionalText.map((text, index) => <div key={index}>{text}</div>)}
        {ExtraElements}
      </div>
    </Dialog>
  );
};
