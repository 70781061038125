import { useSelector } from 'react-redux';
import { userSettingsActions, userSettingsSelectors } from '../../../store/userSettings';
import { Button } from 'primereact/button';
import labels from './labels';
import { Path } from '../../../routing';
import { useDispatch } from 'react-redux';
import { inviteUsersModalActions } from '../../../store/users/modal';
import { navigationService } from '../../../services/NavigationService';
import { XMarkIcon } from '@heroicons/react/24/outline';

export const InvitePeople = () => {
  const dispatch = useDispatch();
  const isAdmin = useSelector(userSettingsSelectors.selectIsAdminRole);
  const isInvitePeopleClosed = useSelector(userSettingsSelectors.selectIsInvitePeopleClosed);

  const handleClose = () => {
    dispatch(userSettingsActions.updateUserSettings({ isInvitePeopleClosed: true }));
    dispatch(userSettingsActions.saveUserSettingsNoToastRequest());
  };

  const handleClickInvite = () => {
    navigationService.navigateTo(Path.Users);
    dispatch(inviteUsersModalActions.openModal());
  };
  return (
    <>
      {isAdmin && !isInvitePeopleClosed && (
        <div className="sumo-card-bg flex flex-column gap-16px mb-28px">
          <div className="flex justify-content-between">
            <div className="text-title-xs-med">{labels.description}</div>
            <div className="flex-none action-button -mr-8px -my-8px">
              <XMarkIcon className="icon-20px" onClick={handleClose} />
            </div>
          </div>
          <div>
            <Button className="button-xl" label={labels.invitePeople} onClick={handleClickInvite} />
          </div>
        </div>
      )}
    </>
  );
};
