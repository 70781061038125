import { XMarkIcon } from '@heroicons/react/24/outline';
import { WorkspaceInput } from '../../../API';
import { getInitials } from '../../../services/utils';
import { SumoTooltip } from '../sumoTooltip/SumoTooltip';

type WorkspaceCardProps = {
  workspace: WorkspaceInput;
  info?: string;
  onRemove?: (value: string) => void;
};

export const WorkspaceCard = ({ workspace, info, onRemove }: WorkspaceCardProps) => {
  return (
    <div className="flex-left-center gap-4px">
      <div className="flex-1 border-1 border-heavy-20 border-radius-10px flex-between p-8px">
        <div className="flex-left-center gap-8px">
          <div className="p-avatar p-avatar-lg flex-none">
            <div>{getInitials(workspace.name || '')}</div>
          </div>
          <div className="text-label-s-med text-heavy-80 text-overflow-ellipsis">{workspace.name}</div>
        </div>
      </div>
      <div className="w-20px flex-center">
        {info && <SumoTooltip text={info} />}
        {onRemove && (
          <div className="action-button-xs" onClick={() => onRemove(workspace.id)}>
            <XMarkIcon className="icon-16px" />
          </div>
        )}
      </div>
    </div>
  );
};
