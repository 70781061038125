import { call, put, takeLatest } from 'redux-saga/effects';
import { IntegrationActionTypes, integrationActions } from './actions';
import { IntegrationType, WorkspaceIntegrationType } from '../../API';
import {
  CONNECT_WORKSPACE_INTEGRATION_ERROR_TOAST,
  CONNECT_WORKSPACE_INTEGRATION_SUCCESS_TOAST,
  HUBSPOT_AUTH_URL,
  SALESFORCE_AUTH_URL,
  ZOOM_AUTH_URL,
} from './constants';
import { handleServiceError } from '../utils/reduxUtils';
import { notificationsActions } from '../notifications';
import { connectIntegration } from './service';
import { authenticationActions } from '../authentication';

const getZoomCodeRedirect = (action: ReturnType<typeof integrationActions.getZoomCodeRedirect>) => {
  if (action.type === IntegrationActionTypes.GET_ZOOM_CODE_REDIRECT) {
    const clientId = process.env.REACT_APP_ZOOM_CLIENT_ID;
    const scope = process.env.REACT_APP_ZOOM_SCOPE;
    const redirectUri = action.uri;
    const state = encodeURIComponent(
      JSON.stringify({
        type: IntegrationType.ZOOM,
      })
    );

    window.location.href = `${ZOOM_AUTH_URL}?response_type=code&client_id=${clientId}&redirect_uri=${redirectUri}&scope=${scope}&state=${state}`;
  }
};

const getHubSpotCodeRedirect = (action: ReturnType<typeof integrationActions.getHubSpotCodeRedirect>) => {
  if (action.type === IntegrationActionTypes.GET_HUBSPOT_CODE_REDIRECT) {
    const clientId = process.env.REACT_APP_HUBSPOT_CLIENT_ID;
    const scope = process.env.REACT_APP_HUBSPOT_SCOPE;
    const redirectUri = action.uri;
    const state = encodeURIComponent(
      JSON.stringify({
        type: WorkspaceIntegrationType.HUBSPOT,
        workspaceId: action.workspaceId,
        customParameters: action.customParameters,
      })
    );

    window.location.href = `${HUBSPOT_AUTH_URL}?client_id=${clientId}&redirect_uri=${redirectUri}&scope=${scope}&state=${state}`;
  }
};

const getSalesforceCodeRedirect = async (action: ReturnType<typeof integrationActions.getSalesforceCodeRedirect>) => {
  if (action.type === IntegrationActionTypes.GET_SALESFORCE_CODE_REDIRECT) {
    const clientId = process.env.REACT_APP_SALESFORCE_CLIENT_ID;
    const scope = process.env.REACT_APP_SALESFORCE_SCOPE;
    const redirectUri = action.uri;
    const verifier = (crypto.randomUUID() + crypto.randomUUID() + crypto.randomUUID() + crypto.randomUUID()).substring(
      0,
      128
    ); //128 random bytes
    const state = encodeURIComponent(
      JSON.stringify({
        type: WorkspaceIntegrationType.SALESFORCE,
        workspaceId: action.workspaceId,
        customParameters: {
          ...action.customParameters,
          verifier,
        },
      })
    );

    const utf8 = new TextEncoder().encode(verifier);
    const hashBuffer = await crypto.subtle.digest('SHA-256', utf8);
    const hashArray = Array.from(new Uint8Array(hashBuffer));
    const codeChallenge = btoa(String.fromCharCode.apply(null, hashArray))
      .replace(/=/g, '')
      .replace(/\+/g, '-')
      .replace(/\//g, '_');

    window.location.href = `${SALESFORCE_AUTH_URL}?response_type=code&code_challenge=${codeChallenge}&client_id=${clientId}&redirect_uri=${redirectUri}&scope=${scope}&state=${state}`;
  }
};

function* connectWorkspaceIntegrationSaga(
  action: ReturnType<typeof integrationActions.connectWorkspaceIntegrationRequest>
) {
  try {
    if (action.type === IntegrationActionTypes.CONNECT_WORKSPACE_INTEGRATION_REQUEST) {
      const input = action.payload;

      yield call(connectIntegration, input);

      yield put(authenticationActions.getTenantRequest());
      yield put(integrationActions.connectWorkspaceIntegrationSuccess());
      yield put(notificationsActions.showToast(CONNECT_WORKSPACE_INTEGRATION_SUCCESS_TOAST));
    }
  } catch (error: unknown) {
    yield put(integrationActions.connectWorkspaceIntegrationFail(error?.toString()));
    yield call(handleServiceError, error, CONNECT_WORKSPACE_INTEGRATION_ERROR_TOAST);
  }
}

// TODO: rename to watchIntergrationsSaga
export function* watchIntegrationSaga() {
  yield takeLatest(IntegrationActionTypes.GET_ZOOM_CODE_REDIRECT, getZoomCodeRedirect);
  yield takeLatest(IntegrationActionTypes.GET_HUBSPOT_CODE_REDIRECT, getHubSpotCodeRedirect);
  yield takeLatest(IntegrationActionTypes.GET_SALESFORCE_CODE_REDIRECT, getSalesforceCodeRedirect);
  yield takeLatest(IntegrationActionTypes.CONNECT_WORKSPACE_INTEGRATION_REQUEST, connectWorkspaceIntegrationSaga);
}
