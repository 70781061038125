import { useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { FileUpload } from 'primereact/fileupload';
import { Image } from 'primereact/image';
import { CloudArrowUpIcon, TrashIcon } from '@heroicons/react/24/outline';
import { FILE_IMAGE_TYPES, MAX_FILE_SIZE_BYTES } from '../../../types/constants';
import { authenticationSelectors } from '../../../store/authentication';
import { uploadPublicFile } from '../../../store/userSettings/service';
import labels from './labels';
import { ProgressSpinner } from 'primereact/progressspinner';

type ImageUploadProps = {
  value: string | null | undefined;
  onChange?: (value: string | null) => void;
  disabled?: boolean;
  download?: boolean;
};

export const ImageUpload = ({ value, onChange, disabled, download }: ImageUploadProps) => {
  const ref = useRef<FileUpload>(null);
  const folderPath = useSelector(authenticationSelectors.selectTenantFileFolderPath);
  const [isUploading, setIsUploading] = useState(false);
  const handleUploadImage = async (file: File) => {
    let imageLink: string | null = null;
    setIsUploading(true);
    try {
      imageLink = await uploadPublicFile(file, folderPath);
    } catch (error) {
      console.error('Error uploading file:', error);
    }
    onChange && onChange(imageLink);
    setIsUploading(false);
    ref.current && ref.current.clear();
  };

  return (
    <div className="relative">
      {isUploading && (
        <div className="absolute z-1 flex-center w-200px h-96px border-radius-10px bg-white-transparent">
          <ProgressSpinner className="w-48px h-48px" strokeWidth="4" />
        </div>
      )}

      <FileUpload
        ref={ref}
        mode="basic"
        auto
        accept={FILE_IMAGE_TYPES}
        maxFileSize={MAX_FILE_SIZE_BYTES}
        customUpload
        uploadHandler={(e) => handleUploadImage(e.files[0])}
        className="hidden"
      ></FileUpload>
      {value ? (
        <div className="flex gap-8px">
          <div className="w-200px h-96px border-1 border-heavy-20 border-radius-10px bg-heavy-1 overflow-hidden">
            <Image
              src={value}
              preview
              className="h-full w-full"
              imageClassName="w-full h-full"
              imageStyle={{
                objectFit: 'contain',
                objectPosition: 'center',
              }}
              downloadable={download}
            />
          </div>
          {!disabled && (
            <div className="text-heavy-50 hover-text-heavy-100 cursor-pointer pt-8px" onClick={() => onChange && onChange(null)}>
              <TrashIcon className="icon-20px" />
            </div>
          )}
        </div>
      ) : (
        <div
          className="w-200px h-96px flex-center gap-8px border-radius-10px bg-heavy-1 text-heavy-60 hover-text-heavy-100 cursor-pointer"
          onClick={() => !disabled && ref.current?.getInput().click()}
        >
          <CloudArrowUpIcon width="48px" strokeWidth="0.75px" />
          <div>
            <div>{labels.pictureSize}</div>
            <div>{labels.pictureFormats}</div>
          </div>
        </div>
      )}
    </div>
  );
};
