import { call, put, select, takeLatest } from 'redux-saga/effects';
import { StaffActionTypes, staffActions } from './actions';

import { notificationsActions } from '../../notifications';
import { deleteStaff, getStaff, saveStaff } from './service';
import {
  ACTIVATE_STAFF_SUCCESS_TOAST,
  CHANGE_STAFF_STATUS_FAIL_TOAST,
  DEACTIVATE_STAFF_SUCCESS_TOAST,
  DELETE_STAFF_FAIL_TOAST,
  DELETE_STAFF_SUCCESS_TOAST,
  GET_STAFF_FAIL_TOAST,
  SAVE_STAFF_FAIL_TOAST,
  SAVE_STAFF_SUCCESS_TOAST,
} from './constants';
import { staffSelectors } from './selectors';
import { deactivateStaffModalActions, deleteStaffModalActions } from './modal';
import { CreateStaffInput, StaffStatus } from '../../../API';
import { createSelector } from 'reselect';
import { navigationService } from '../../../services/NavigationService';
import { Path } from '../../../routing';
import { UserSettingsKeys } from '../../userSettings';
import { EMPTY_SUMO1_ADMIN, authenticationActions } from '../../authentication';
import { handleServiceError } from '../../utils/reduxUtils';
import { GetStaffListType } from './types';

const selectStaffRecordRequest = createSelector(staffSelectors.selectStaffRecord, (staff) => ({
  email: staff.email,
  userId: staff.userId,
  userName: staff.userName,
  roleName: staff.roleName,
  status: staff.status,
}));

function* getStaffSaga() {
  try {
    const response: GetStaffListType = yield call(getStaff);
    if (response && response.staffList && response.staffList.length > 0) {
      yield put(staffActions.getStaffSuccess(response.staffList));
    } else {
      throw new Error('Staff not found');
    }
  } catch (error: unknown) {
    yield put(staffActions.getStaffFail(error?.toString()));
    yield call(handleServiceError, error, GET_STAFF_FAIL_TOAST, true);
  }
}

function* saveStaffSaga() {
  try {
    const staffRecord: CreateStaffInput = yield select(selectStaffRecordRequest);
    yield call(saveStaff, staffRecord);

    const myStaffInfo: CreateStaffInput = JSON.parse(
      localStorage.getItem(UserSettingsKeys.SUMO1_STAFF_DATA) || JSON.stringify(EMPTY_SUMO1_ADMIN)
    );

    // I changing myself
    if (myStaffInfo.userId === staffRecord.userId) {
      yield put(authenticationActions.setSUMO1AdminData(staffRecord));
      localStorage.setItem(UserSettingsKeys.SUMO1_STAFF_DATA, JSON.stringify(staffRecord));
    }

    yield call(navigationService.navigateTo, Path.OPSConsoleStaff);

    yield put(staffActions.saveStaffSuccess());
    yield put(notificationsActions.showToast(SAVE_STAFF_SUCCESS_TOAST));
  } catch (error: unknown) {
    yield put(staffActions.saveStaffFail(error?.toString()));
    yield call(handleServiceError, error, SAVE_STAFF_FAIL_TOAST);
  }
}

function* changeStaffStatusSaga() {
  try {
    const staffRecord: CreateStaffInput = yield select(selectStaffRecordRequest);
    const isActive = staffRecord.status === StaffStatus.ACTIVE;
    yield call(saveStaff, {
      ...staffRecord,
      status: isActive ? StaffStatus.INACTIVE : StaffStatus.ACTIVE,
    });

    yield put(staffActions.changeStaffStatusSuccess());
    yield put(notificationsActions.showToast(isActive ? DEACTIVATE_STAFF_SUCCESS_TOAST : ACTIVATE_STAFF_SUCCESS_TOAST));
    if (isActive) {
      yield put(deactivateStaffModalActions.closeModal());
    }
    yield put(staffActions.getStaffRequest());
  } catch (error: unknown) {
    yield put(staffActions.changeStaffStatusFail(error?.toString()));
    yield call(handleServiceError, error, CHANGE_STAFF_STATUS_FAIL_TOAST);
  }
}

function* deleteStaffSaga() {
  try {
    const email: string = yield select(staffSelectors.selectStaffEmail);
    yield call(deleteStaff, email);

    yield put(staffActions.deleteStaffSuccess());
    yield put(notificationsActions.showToast(DELETE_STAFF_SUCCESS_TOAST));
    yield put(deleteStaffModalActions.closeModal());
    yield put(staffActions.getStaffRequest());
  } catch (error: unknown) {
    yield put(staffActions.deleteStaffFail(error?.toString()));
    yield call(handleServiceError, error, DELETE_STAFF_FAIL_TOAST);
  }
}

export function* watchOPSConsoleStaffSaga() {
  yield takeLatest(StaffActionTypes.GET_STAFF_REQUEST, getStaffSaga);
  yield takeLatest(StaffActionTypes.SAVE_STAFF_REQUEST, saveStaffSaga);
  yield takeLatest(StaffActionTypes.CHANGE_STAFF_STATUS_REQUEST, changeStaffStatusSaga);
  yield takeLatest(StaffActionTypes.DELETE_STAFF_REQUEST, deleteStaffSaga);
}
