import {
  UpdateBookingPageInput,
  UpdateUserEventInput,
  CanceledEventInput,
  PhysicalLocation,
  UpdateGroupBookingPageInput,
  JourneyDestinationInput,
  CreateUserEventInput,
  JourneyEventStepInput,
  CreateJourneyInput,
  JourneyFormFieldInput,
  CreateJourneyEventInput,
  LocationType,
} from '../../API';

export type GetAgendaRequestType = {
  groupBookingPageId?: string;
  bookingPageId?: string;
  eventId?: string;
  timeZone?: string;
  startTime?: number;
  endTime?: number;
  spot?: number;
  journeyId?: string;
  chosenHostMemberId?: string;
  journeyDestination?: JourneyDestinationInput;
  locationType?: LocationType;
};

export type HostData = {
  id?: string;
  name?: string;
  email?: string;
  avatar?: string;
  phone?: string;
  countryCode?: string;
  phoneDetails?: string;
  isTeam?: boolean;
};

export type GoogleAnalyticsData = {
  trackingId: string;
  measurementId: string;
};

export type AgendaType = {
  groupBookingPage?: UpdateGroupBookingPageInput;
  bookingPage?: UpdateBookingPageInput;
  event?: UpdateUserEventInput;
  spots?: string[];
  initialHost?: HostData;
  initialCohosts?: HostData[];
  host?: HostData;
  cohosts?: HostData[];
  locations?: PhysicalLocation[];
  googleAnalytics?: GoogleAnalyticsData
  journey?: CreateJourneyInput;
  potentialHosts?: HostData[];
};

export type RescheduleData = {
  event: UpdateUserEventInput;
  bookingPage?: UpdateBookingPageInput;
};

export type PreviewData = {
  bookingPage?: UpdateBookingPageInput;
  journey?: CreateJourneyInput;
};

export enum EventSteps {
  // WHAT = 'WHAT',
  JOURNEY = 'JOURNEY',
  WHERE = 'WHERE',
  WHO = 'WHO',
  WHEN = 'WHEN',
  INFO = 'INFO',
  BOOKED = 'BOOKED',
  CANCEL = 'CANCEL',
}

export type ViewSettings = {
  editKey: string | null;
  hideHeader: boolean;
  hidePageDetails: boolean;
  hideCookie: boolean;
  iframe: boolean;
};

export type CancelEventRequestType = {
  eventId: string;
  canceled?: CanceledEventInput | null;
};

export type CreateEventRequest = {
  event: CreateUserEventInput;
  journeyId?: string;
  steps?: JourneyEventStepInput[];
  chosenHostMemberId?: string;
};

export type CreateUpdateEventResponse = {
  event: UpdateUserEventInput;
  host?: HostData;
  cohosts?: HostData[];
};

export type CreateJourneyEventRequest = {
  journeyEvent?: CreateJourneyEventInput;
};

export type GetMeetingURLParamsType = {
  spot: string | null;
};

export type NavigationStep = {
  step: EventSteps;
  active: boolean;
  visited: boolean;
};

export type FieldUpdateData = {
  stepId: string;
  field: JourneyFormFieldInput;
};
