import { put, select, takeLatest } from 'redux-saga/effects';
import { NotificationsActionTypes, notificationsActions } from './actions';
import { notificationsSelectors } from './selectors';
import { TOASTS_QUEUE_MAX_LENGTH } from './constants';
import { ToastNotification } from './types';
import { v4 as getId } from 'uuid';

function* showToastSaga(action: ReturnType<typeof notificationsActions.showToast>) {
  if (action.type === NotificationsActionTypes.SHOW_TOAST) {
    const { payload } = action;
    const toastNotificationsQueue: ToastNotification[] = yield select(
      notificationsSelectors.selectToastNotificationsQueue
    );

    if (toastNotificationsQueue.length >= TOASTS_QUEUE_MAX_LENGTH) {
      const firstToastId = toastNotificationsQueue[0].id;
      yield put(notificationsActions.hideToast(firstToastId));
    }

    yield put(notificationsActions.addToastToQueue({ id: getId(), ...payload }));
  }
}

// function* hideToastSaga(action: ReturnType<typeof notificationsActions.hideToast>) {
//   if (action.type === NotificationsActionTypes.HIDE_TOAST) {
//     const { payload } = action;
//     const toastNotificationsQueue: ToastNotification[] = yield select(
//       notificationsSelectors.selectToastNotificationsQueue
//     );

//     const currentToast = toastNotificationsQueue.find((toast) => toast.id === payload);

//     currentToast?.timerId && clearTimeout(currentToast.timerId);
//     yield put(notificationsActions.removeToastFromQueue(payload));
//   }
// }

export function* watchNotificationsSaga() {
  yield takeLatest(NotificationsActionTypes.SHOW_TOAST, showToastSaga);
  // yield takeLatest(NotificationsActionTypes.HIDE_TOAST, hideToastSaga);
}
