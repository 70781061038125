import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { TERM_OPTIONS, OPSConsoleUsersActions, OPSConsoleUsersSelectors } from '../../../store/opsConsole/users';
import { useEffect } from 'react';
import { Path } from '../../../routing';
import { SectionHeader } from '../../../components/common';
import labels from './labels';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { DEFAULT_PRICING_TIER, PRICING_TIER_OPTIONS } from '../../../store/opsConsole/orgs';
import { navigationService } from '../../../services/NavigationService';
import { UserStatus } from '../../../store/users/types';
import { authenticationSelectors } from '../../../store/authentication';
import { MAX_LENGTH_ABOUT_300_WORDS, MAX_LENGTH_NAME } from '../../../types/constants';
import { ArrowLeftIcon } from '@heroicons/react/24/outline';

export const UserDetails = () => {
  const { tenantId, email } = useParams();
  const dispatch = useDispatch();
  const currentUserId = useSelector(authenticationSelectors.selectUserId);
  const isFetching = useSelector(OPSConsoleUsersSelectors.selectIsFetching);
  const userId = useSelector(OPSConsoleUsersSelectors.selectUserId);
  const userName = useSelector(OPSConsoleUsersSelectors.selectUserName);
  const accountName = useSelector(OPSConsoleUsersSelectors.selectAccountName);
  const roleName = useSelector(OPSConsoleUsersSelectors.selectRoleName);
  const userStatus = useSelector(OPSConsoleUsersSelectors.selectUserStatus);
  const firstLogin = useSelector(OPSConsoleUsersSelectors.selectFirstLogin);
  const lastActive = useSelector(OPSConsoleUsersSelectors.selectLastActive);
  const bookedMeetings = useSelector(OPSConsoleUsersSelectors.selectBookedMeetings);
  const term = useSelector(OPSConsoleUsersSelectors.selectTerm);
  const usersAssigned = useSelector(OPSConsoleUsersSelectors.selectUsersAssigned);
  const licenseEndDate = useSelector(OPSConsoleUsersSelectors.selectLicenseEndDate);
  const isMainAdminOrSupport = useSelector(authenticationSelectors.selectIsMainAdminOrSupport);

  useEffect(() => {
    if (tenantId && email) {
      dispatch(OPSConsoleUsersActions.getUserDetailsRequest({ tenantId, email }));
    } else {
      navigateBack();
    }
  }, []);

  const navigateBack = () => {
    navigationService.navigateTo(Path.OPSConsoleUsers);
  };

  return (
    <>
      <Button className="button-blue button-text-line mb-16px" text onClick={navigateBack}>
        <ArrowLeftIcon className="icon-18px" />
        <div className="flex-1 pl-8px">{labels.back}</div>
      </Button>

      <div className="sumo-card-bg flex flex-column pt-16px">
        <SectionHeader loading={isFetching} title={labels.title} hideButton />

        <div className="flex justify-content-between align-items-center mb-3">
          <div className="text-xl">{`${userName} (${email || ''})`}</div>
          {isMainAdminOrSupport && userStatus !== UserStatus.InviteSent && currentUserId !== userId && (
            <Button
              label={labels.viewAsUser}
              onClick={() => {
                window.open(
                  window.location.origin +
                    Path.OPSConsoleViewAsUser.replace(':userId', userId).replace(':tenantId', tenantId || ''),
                  '_blank'
                );
              }}
            />
          )}
        </div>

        <div className="border-1 border-round p-4">
          <div className="text-lg font-bold mb-2">{labels.userInfo}</div>
          <div className="grid justify-content-between">
            <div className="flex flex-column col-12 lg:col-5">
              <label htmlFor="accountName">{labels.accountName}</label>
              <Dropdown value={accountName} options={[accountName]} id="accountName" disabled />
            </div>
            <div className="flex flex-column col-12 lg:col-5">
              <label htmlFor="orgId">{labels.orgId}</label>
              <InputText value={tenantId || ''} id="orgId" maxLength={MAX_LENGTH_NAME} disabled />
            </div>
            <div className="flex flex-column col-12 lg:col-5">
              <label htmlFor="role">{labels.role}</label>
              <InputText value={roleName || ''} id="role" maxLength={MAX_LENGTH_NAME} disabled />
            </div>
            <div className="flex flex-column col-12 lg:col-5">
              <label htmlFor="status">{labels.status}</label>
              <InputText value={userStatus} id="status" maxLength={MAX_LENGTH_NAME} disabled />
            </div>
            <div className="flex flex-column col-12 lg:col-5">
              <label htmlFor="registrationDate">{labels.registrationDate}</label>
              <InputText value={firstLogin} id="registrationDate" maxLength={MAX_LENGTH_NAME} disabled />
            </div>
            <div className="flex flex-column col-12 lg:col-5">
              <label htmlFor="lastActive">{labels.lastActive}</label>
              <InputText value={lastActive} id="lastActive" maxLength={MAX_LENGTH_NAME} disabled />
            </div>
            <div className="flex flex-column col-12 md:col-5 col-offset-0 md:col-offset-7">
              <label htmlFor="bookedMeeting">{labels.bookedNumber}</label>
              <InputText value={'' + bookedMeetings} id="bookedMeeting" maxLength={MAX_LENGTH_NAME} disabled />
            </div>
          </div>
          <div className="text-lg font-bold mb-2">{labels.licenseStatus}</div>
          <div className="grid justify-content-between">
            <div className="flex flex-column col-12 md:col-5">
              <label htmlFor="pricingTier">{labels.featurePricingTier}</label>
              <Dropdown value={DEFAULT_PRICING_TIER} options={PRICING_TIER_OPTIONS} id="pricingTier" disabled />
            </div>
            <div className="flex flex-column col-12 md:col-5">
              <label htmlFor="term">{labels.term}</label>
              <Dropdown value={term} options={TERM_OPTIONS} id="term" disabled />
            </div>
            <div className="flex flex-column col-12 lg:col-5">
              <label htmlFor="renewalDate">{labels.renewalDate}</label>
              <InputText value={licenseEndDate} id="renewalDate" maxLength={MAX_LENGTH_NAME} disabled />
            </div>
            <div className="flex flex-column col-12 md:col-5">
              <label htmlFor="activeUsers">{labels.activeUsersNumber}</label>
              <InputText value={'' + usersAssigned} id="activeUsers" maxLength={MAX_LENGTH_ABOUT_300_WORDS} disabled />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
