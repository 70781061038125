import { t } from '../../../i18n/i18n';

export default {
  page: t('EditJourney:PAGE_TITLE'),
  condition: t('EditJourney:CONDITION_TITLE'),
  destination: t('EditJourney:DESTINATION_TITLE'),
  withCard: t('EditJourney:WITH_CARDS'),
  withForm: t('EditJourney:WITH_FORM'),
  if: t('EditJourney:IF_LABEL'),
  or: t('EditJourney:OR_LABEL'),
};
