import React from 'react';
import labels from './labels';
import { ReactComponent as NoItemsImage } from '../../../assets/images/noContentLarge.svg';

type EmptyListScreenProps = {
  title?: string;
  description?: string;
};

export const EmptyListScreen: React.FC<EmptyListScreenProps> = ({ title, description }) => {
  return (
    <div className="flex-center flex-column">
      <NoItemsImage width={600} height={400} />
      <div className="text-title-md-med text-heavy-80">{title || labels.emptyList}</div>
      <div className="text-body-s-reg text-heavy-60">{description}</div>
    </div>
  );
};
