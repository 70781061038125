import { t } from '../../../i18n/i18n';

export default {
  acceptTerms_step1: t('QuickSetupChromeExtensionStep:ACCEPT_TERMS_PART1'),
  acceptTerms_step2: t('QuickSetupChromeExtensionStep:ACCEPT_TERMS_PART2'),
  acceptTerms_step3: t('QuickSetupChromeExtensionStep:ACCEPT_TERMS_PART3'),
  acceptTerms_step4: t('QuickSetupChromeExtensionStep:ACCEPT_TERMS_PART4'),
  back: t('Common:BACK'),
  description: t('QuickSetupYourBrandStep:DESCRIPTION'),
  pictureFormats: t('Common:PICTURE_FORMATS'),
  pictureSize: t('Common:PICTURE_SIZE'),
  save: t('QuickSetupYourBrandStep:SAVE_BUTTON'),
  stepMessage: t('QuickSetupYourBrandStep:STEP_MESSAGE'),
  title: t('QuickSetupYourBrandStep:TITLE'),

  fontColorsTitle: t('LookAndFeel:FONT_COLORS_TITLE'),
  headerLabel: t('LookAndFeel:HEADER_LABEL'),
  subHeaderLabel: t('LookAndFeel:SUB_HEADER_LABEL'),
  mainTextLabel: t('LookAndFeel:MAIN_TEXT_LABEL'),
  fontFamiliesTitle: t('LookAndFeel:FONT_FAMILIES_TITLE'),
  headerFontLabel: t('LookAndFeel:HEADER_FONT_LABEL'),
  subHeaderFontLabel: t('LookAndFeel:SUB_HEADER_FONT_LABEL'),
  mainTextFontLabel: t('LookAndFeel:MAIN_TEXT_FONT_LABEL'),
  siteColorTitle: t('LookAndFeel:SITE_COLORS_TITLE'),
  primaryLabel: t('LookAndFeel:PRIMARY_LABEL'),
  buttonsLabel: t('LookAndFeel:BUTTONS_LABEL'),
  backgroundLabel: t('LookAndFeel:BACKGROUND_LABEL'),
};
