import { ModalOptions, ModalsRecord } from './types';
import { checkModal } from './utils';
import { ModalsActionTypes, ModalsAction } from './actions';
import { GlobalAction, GlobalActionTypes } from '../global/actions';

const options = (state: ModalOptions = {}, action: ModalsAction | GlobalAction) => {
  switch (action.type) {
    case ModalsActionTypes.OPEN_MODAL:
      return { open: true, ...(action.payload && { payload: action.payload }) };
    case ModalsActionTypes.CLOSE_MODAL:
      return { open: false };
    case GlobalActionTypes.RESET_WHOLE_STORE:
      return {};
    default:
      return state;
  }
};

export default (state: ModalsRecord = {}, action: ModalsAction | GlobalAction) => {
  switch (action.type) {
    case ModalsActionTypes.INITIALIZE_MODAL:
      return checkModal(state, action.payload);
    case ModalsActionTypes.DELETE_MODAL: {
      const newState = { ...state };
      delete newState[action.key];
      return newState;
    }
    case ModalsActionTypes.OPEN_MODAL:
    case ModalsActionTypes.CLOSE_MODAL:
      return { ...state, [action.key]: options(state[action.key], action) };
    case GlobalActionTypes.RESET_WHOLE_STORE:
      return {};
    default:
      return state;
  }
};
