import { useDispatch, useSelector } from 'react-redux';
import { bookingPageSelectors, bookingPagesActions } from '../../../../store/bookingPages';
import {
  DEFAULT_INPUT_SMS_FIELD,
  NotificationTypes,
  bookingTemplatesActions,
  bookingTemplatesSelectors,
  smartAlertModalActions,
} from '../../../../store/bookingTemplates';
import labels from './labels';
import { NotificationItem } from '../notificationItem/NotificationItem';
import { Button } from 'primereact/button';
import { SmartAlertModal } from '../smartAlertModal/SmartAlertModal';
import { SmartAlertItem } from '../smartAlertItem/SmartAlertItem';
import { SmartAlertWarningModal } from '../smartAlertWarningModal/SmartAlertWarningModal';
import { useEffect } from 'react';
import { smartAlertsSelectors } from '../../../../store/smartAlerts';
import { InformationCircleIcon } from '@heroicons/react/24/outline';
import { ProgressSpinner } from 'primereact/progressspinner';

type NotificationStepProps = {
  selectors: typeof bookingTemplatesSelectors | typeof bookingPageSelectors;
  actions: typeof bookingTemplatesActions | typeof bookingPagesActions;
  isReadOnly?: boolean;
};

export const NotificationStep = ({ selectors, actions, isReadOnly }: NotificationStepProps) => {
  const dispatch = useDispatch();
  const confirmation = useSelector(selectors.selectConfirmation);
  const reschedule = useSelector(selectors.selectReschedule);
  const reminder = useSelector(selectors.selectReminder);
  // const reminderDuplicateTimerIndexes = useSelector(selectors.selectReminderDuplicateTimerIndexes);
  const cancelation = useSelector(selectors.selectCancelation);
  const followUp = useSelector(selectors.selectFollowUp);
  const sms = useSelector(selectors.selectSmsReminder);
  // const smsDuplicateTimerIndexes = useSelector(selectors.selectSmsDuplicateTimerIndexes);
  const smartAlertInUse = useSelector(selectors.selectSmartAlerts);
  const phoneInputField = useSelector(selectors.selectPhoneInputFields);
  const IsPromptInviteCallType = useSelector(selectors.selectIsPromptInviteCallType);
  const smsReminderOptionEnabled = useSelector(selectors.selectSmsReminderOptionEnabled);
  const isFetchingSmartAlerts = useSelector(smartAlertsSelectors.selectIsFetching);
  const isSMSToInvite = useSelector(smartAlertsSelectors.selectIsThereSmartAlertSMSInvitee(smartAlertInUse));

  const handleAddSmartAlert = () => {
    dispatch(smartAlertModalActions.openModal({ isEdit: false }));
  };

  useEffect(() => {
    // if there is SMS remainder let's add required custom filed "Phone number"
    if ((isSMSToInvite || smsReminderOptionEnabled) && !phoneInputField) {
      dispatch(actions.addCustomField(DEFAULT_INPUT_SMS_FIELD));
    } else if (
      !isSMSToInvite &&
      !smsReminderOptionEnabled &&
      phoneInputField &&
      phoneInputField.id &&
      !IsPromptInviteCallType
    ) {
      dispatch(actions.removeCustomField(phoneInputField.id));
    }
  }, [isSMSToInvite, smsReminderOptionEnabled, phoneInputField]);

  return (
    <div className="flex flex-column gap-28px">
      <NotificationItem
        type={NotificationTypes.CONFIRMATION}
        value={confirmation}
        onSave={(confirmations) => dispatch(actions.updateNotificationsStep({ confirmations }))}
        disabled={isReadOnly}
        selectors={selectors}
        actions={actions}
      />
      <NotificationItem
        type={NotificationTypes.RESCHEDULE}
        value={reschedule}
        onSave={(reschedule) => dispatch(actions.updateNotificationsStep({ reschedule }))}
        disabled={isReadOnly}
        selectors={selectors}
        actions={actions}
      />
      <NotificationItem
        type={NotificationTypes.REMINDER}
        value={reminder}
        // timerDuplicateIndexes={reminderDuplicateTimerIndexes}
        onSave={(reminders) => dispatch(actions.updateNotificationsStep({ reminders }))}
        disabled={isReadOnly}
        selectors={selectors}
        actions={actions}
      />
      <NotificationItem
        type={NotificationTypes.CANCELATION}
        value={cancelation}
        onSave={(cancelations) => dispatch(actions.updateNotificationsStep({ cancelations }))}
        disabled={isReadOnly}
        selectors={selectors}
        actions={actions}
      />
      <NotificationItem
        type={NotificationTypes.FOLLOW_UP}
        value={followUp}
        onSave={(followUp) => dispatch(actions.updateNotificationsStep({ followUp }))}
        disabled={isReadOnly}
        selectors={selectors}
        actions={actions}
      />

      <div className="flex flex-column gap-16px">
        <div className="border-top-1 border-heavy-20 mt-4px" />

        <div className="flex-left-center gap-6px bg-blue-soft border-radius-6px p-6px text-blue-main">
          <InformationCircleIcon className="icon-18px" />
          <div className="text-label-xs-reg">
            {labels.smsLabelPart1} - {labels.smsLabelPart2}
          </div>
        </div>

        <NotificationItem
          type={NotificationTypes.SMS}
          value={sms}
          // timerDuplicateIndexes={smsDuplicateTimerIndexes}
          onSave={(smsReminder) => dispatch(actions.updateNotificationsStep({ smsReminder }))}
          disabled={isReadOnly}
          selectors={selectors}
          actions={actions}
        />
      </div>

      <div className="border-top-1 border-heavy-20 mt-4px" />

      <div className="flex-left-center gap-12px">
        <div className="text-label-s-med text-heavy-60">{labels.smartAlerts}</div>
        {isFetchingSmartAlerts && <ProgressSpinner className="w-32px h-32px mx-0 -my-10px" strokeWidth="8" />}
      </div>

      {smartAlertInUse.map((id) => (
        <div key={id}>
          <SmartAlertItem smartAlertId={id || ''} actions={actions} selectors={selectors} isReadOnly={isReadOnly} />
        </div>
      ))}
      <Button className="button-blue w-fit" label={labels.addSmartAlert} text onClick={handleAddSmartAlert} />

      <SmartAlertModal actions={actions} selectors={selectors} isReadOnly={isReadOnly} />
      <SmartAlertWarningModal />
    </div>
  );
};
