export const Locations = Object.freeze({
  TITLE: `Locais`,
  CLONE_TITLE: `Insira o nome do local clonado`,
  ADD_LABEL: `Novo Local`,
  DEACTIVATE_TITLE: `Desativar Local`,
  DEACTIVATE_DESCRIPTION: `Tem certeza de que deseja desativar`,
  DELETE_TITLE: `Excluir Local`,
  DELETE_DESCRIPTION: `Tem certeza de que deseja excluir`,
  NAME_LABEL: `Nome`,
  ADDRESS_LABEL: `Endereço`,
  ZIP_LABEL: `CEP`,
  STATE_LABEL: `Estado`,
  COUNTRY_LABEL: `País`,
  IMPORT_CSV: `Importar CSV`,
  IMPORT_LABEL: `Importar`,
  STATUS: `Status`,
  ACTIVE: `Ativo`,
  INACTIVE: `Inativo`,
  UPLOAD_DESCRIPTION_PART1: `Selecione o arquivo CSV no seu computador`,
  UPLOAD_DESCRIPTION_PART2: `ou transfira para esta área de upload`,
  IMPORT_DESCRIPTION_PART1: `Aqui você pode encontrar um `,
  IMPORT_DESCRIPTION_PART2: `arquivo de exemplo`,
  IMPORT_DESCRIPTION_PART3: ``,
  EMPTY_LIST_DESCRIPTION: `Crie um local ou ajuste sua busca ou filtros.`,
  SELECTED_LOCATIONS: `locais selecionados`,
  CITY_LABEL: `Cidade`,
});

export const EditLocation = Object.freeze({
  ADD_LOCATION: `Adicionar localização`,
  NAME: `Nome`,
  NAME_PLACEHOLDER: `Insira o nome do local`,
  ADDRESS: `Endereço`,
});

export const LocationsToastsNotifications = Object.freeze({
  GET_LOCATIONS_ERROR_MESSAGE: `Falha na solicitação de locais`,
  DEACTIVATE_LOCATION_SUCCESS_MESSAGE: `Local desativado com sucesso`,
  ACTIVATE_LOCATION_SUCCESS_MESSAGE: `Local ativado com sucesso`,
  DEACTIVATE_LOCATION_ERROR_MESSAGE: `Falha na solicitação de ativação/desativação do local`,
  CLONE_LOCATION_SUCCESS_MESSAGE: `Local clonado com sucesso`,
  CLONE_LOCATION_ERROR_MESSAGE: `Falha na solicitação de clonagem do local`,
  DELETE_LOCATION_SUCCESS_MESSAGE: `Local excluído com sucesso`,
  DELETE_LOCATION_ERROR_MESSAGE: `Falha na solicitação de exclusão do local`,
  VALIDATE_LOCATIONS_ERROR_MESSAGE: `Um ou mais registros têm campos vazios. Todos os campos são obrigatórios.`,
  IMPORT_LOCATIONS_SUCCESS_MESSAGE: `Locais importados com sucesso`,
  IMPORT_LOCATIONS_ERROR_MESSAGE: `Falha na solicitação de importação de locais`,
  GET_LOCATION_ERROR_MESSAGE: `Falha ao obter localização`,
  SAVE_LOCATION_ERROR_MESSAGE: `Falha ao salvar localização`,
  CREATE_LOCATION_SUCCESS_MESSAGE: `Localização criada com sucesso`,
  UPDATE_LOCATION_SUCCESS_MESSAGE: `Localização atualizada com sucesso`,
});
