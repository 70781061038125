import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { InputText } from 'primereact/inputtext';
import { InputSwitch } from 'primereact/inputswitch';
import { bookingPagesActions, bookingPageSelectors } from '../../../store/bookingPages';
import { BookingTemplateOption, bookingTemplatesSelectors } from '../../../store/bookingTemplates';
import { MAX_LENGTH_DESCRIPTION, MAX_LENGTH_NAME } from '../../../types/constants';
import { Path } from '../../../routing';
import labels from './labels';
import { Dropdown, DropdownChangeEvent } from 'primereact/dropdown';
import { userSettingsSelectors } from '../../../store/userSettings';
import { AppDispatch } from '../../../store/rootStore';
import { InputTextarea } from 'primereact/inputtextarea';
import { ColorPicker, SumoTooltip } from '../../common';

export const BookingPageWhatStep = () => {
  const dispatch: AppDispatch = useDispatch();
  const customName = useSelector(bookingPageSelectors.selectCustomName);
  const instructions = useSelector(bookingPageSelectors.selectInstructions);
  const adminOnly = useSelector(bookingPageSelectors.selectIsAdminOnly);
  const color = useSelector(bookingPageSelectors.selectWhatColor);
  const isUserRequiredHost = useSelector(bookingPageSelectors.selectIsUserRequiredHost);
  const bookingTemplateId = useSelector(bookingPageSelectors.selectBookingTemplateId);
  // const isNameDuplicate = useSelector(bookingPageSelectors.selectIsNameDuplicate);
  const isNameValid = useSelector(bookingPageSelectors.selectIsNameValid);
  const isBookingPageLocked = useSelector(bookingPageSelectors.selectIsBookingPageLocked());
  const bookingTemplateOptions = useSelector(
    bookingTemplatesSelectors.selectBookingTemplatesOptions(isBookingPageLocked ? false : isUserRequiredHost)
  );
  const isAdmin = useSelector(userSettingsSelectors.selectIsAdminRole);

  const [localName, setLocalName] = useState(customName);
  const [localInstructions, setLocalInstructions] = useState(instructions);

  useEffect(() => {
    setLocalName(customName);
  }, [customName]);

  useEffect(() => {
    setLocalInstructions(instructions);
  }, [instructions]);

  const handleNameChange = (name: string) => {
    setLocalName(name);
  };

  const handleNameBlur = () => {
    dispatch(bookingPagesActions.updateWhatStep({ customName: localName }));
  };

  const handleInstructionsChange = (instructions: string) => {
    setLocalInstructions(instructions);
  };

  const handleInstructionsBlur = () => {
    dispatch(bookingPagesActions.updateWhatStep({ instructions: localInstructions }));
  };

  const handleAdminOnlyChange = (value: boolean) => {
    dispatch(bookingPagesActions.updateRecord({ adminOnly: value }));
  };

  const handleColorChange = (color: string) => {
    dispatch(bookingPagesActions.updateWhatStep({ color }));
  };

  const handleTemplateChange = (e: DropdownChangeEvent) => {
    const bookingTemplateId: string = e.value;
    dispatch(
      bookingPagesActions.updateWhatStep({
        bookingTemplateId,
        customName: customName || bookingTemplateOptions.find((option) => option.id === bookingTemplateId)?.name || '',
      })
    );
    dispatch(bookingPagesActions.uploadBookingTemplate());
  };

  const selectedItemTemplate = (item: BookingTemplateOption) =>
    item && (
      <div>
        <Link
          className="text-color hover:underline hover:text-primary"
          target="_blank"
          to={Path.EditBookingTemplate.replace(':bookingTemplateId', item.id)}
        >
          {item.name}
        </Link>
      </div>
    );

  return (
    <div className="flex flex-column gap-28px">
      <div className="flex">
        <div className="w-6 pr-10px flex flex-column gap-8px">
          <div className="flex-left-center gap-8px">
            <div className="text-title-xs-med text-heavy-100">{labels.name}</div>
            <SumoTooltip text={labels.nameTooltip} />
          </div>
          <div className="p-fluid">
            <InputText
              type="text"
              placeholder={labels.namePlaceholder}
              value={localName || ''}
              onChange={(e) => handleNameChange(e.target.value.trimStart())}
              onBlur={handleNameBlur}
              className={`${!isNameValid ? 'p-invalid' : ''}`}
              maxLength={MAX_LENGTH_NAME}
              disabled={isBookingPageLocked}
            />
          </div>
        </div>
        <div className="w-6 pl-10px flex flex-column gap-8px">
          <div className="flex-left-center gap-8px">
            <div className="text-title-xs-med text-heavy-100">{labels.color}</div>
            <SumoTooltip text={'add color tooltip text'} />
          </div>
          <div>
            <ColorPicker color={color} onColorChange={handleColorChange} />
          </div>
        </div>
      </div>

      <div className="flex">
        <div className="w-6 pr-10px flex flex-column gap-8px">
          <div className="flex-left-center gap-8px">
            <div className="text-title-xs-med text-heavy-100">{labels.bookingTemplate}</div>
            <SumoTooltip text={'add template tooltip text'} />
          </div>
          <div className="p-fluid">
            <Dropdown
              value={bookingTemplateId}
              options={bookingTemplateOptions}
              optionLabel="name"
              optionValue="id"
              onChange={handleTemplateChange}
              filter
              filterBy="name"
              valueTemplate={bookingTemplateId ? selectedItemTemplate : undefined}
              showClear
              placeholder={labels.bookingTemplatePlaceholder}
              disabled={isBookingPageLocked}
            />
          </div>
        </div>
        <div className="w-6 pl-10px flex flex-column gap-16px">
          <div className="flex-left-center gap-8px">
            <div className="text-title-xs-med text-heavy-100">{labels.adminOnly}</div>
            <SumoTooltip text={labels.adminOnlyTooltip} />
          </div>
          <div>
            <InputSwitch
              checked={!!adminOnly}
              onChange={(e) => handleAdminOnlyChange(!!e.value)}
              disabled={!isAdmin || isBookingPageLocked}
            />
          </div>
        </div>
      </div>

      <div className="flex flex-column gap-8px">
        <div className="flex-left-center gap-8px">
          <div className="text-title-xs-med text-heavy-100">{labels.meetingInstructions}</div>
          <SumoTooltip text={labels.meetingInstructionsTooltip} />
        </div>
        <div className="p-fluid">
          <InputTextarea
            autoResize
            rows={4}
            value={localInstructions || ''}
            onChange={(e) => handleInstructionsChange(e.target.value.trimStart())}
            onBlur={handleInstructionsBlur}
            placeholder={labels.meetingInstructionsPlaceholder}
            maxLength={MAX_LENGTH_DESCRIPTION}
          />
        </div>
      </div>
    </div>
  );
};
