import { call, put, select, takeLatest } from 'redux-saga/effects';
import { authenticationSelectors } from '../authentication';
import { LocationsActionTypes, locationsActions } from './actions';
import { handleServiceError } from '../utils/reduxUtils';
import { LocationResponse, LocationsResponse } from './types';
import { deleteLocations, getLocationById, getLocationsByWorkspaceId, upsertLocations } from './service';
import { CreatePhysicalLocationInput } from '../../API';
import { locationsSelectors } from './selectors';
import { createSelector } from 'reselect';
import { cloneLocationModalActions } from './modal';
import {
  ACTIVATE_LOCATION_SUCCESS_TOAST,
  CLONE_LOCATION_ERROR_TOAST,
  CLONE_LOCATION_SUCCESS_TOAST,
  CREATE_LOCATION_SUCCESS_TOAST,
  DEACTIVATE_LOCATION_ERROR_TOAST,
  DEACTIVATE_LOCATION_SUCCESS_TOAST,
  DEFAULT_PHYSICAL_LOCATION,
  DELETE_LOCATION_ERROR_TOAST,
  DELETE_LOCATION_SUCCESS_TOAST,
  GET_LOCATION_ERROR_TOAST,
  GET_LOCATIONS_ERROR_TOAST,
  IMPORT_LOCATIONS_ERROR_TOAST,
  IMPORT_LOCATIONS_SUCCESS_TOAST,
  SAVE_LOCATION_ERROR_TOAST,
  UPDATE_LOCATION_SUCCESS_TOAST,
} from './constants';
import { notificationsActions } from '../notifications';
import { navigationService } from '../../services/NavigationService';
import { Path } from '../../routing';

const selectUpsertLocationRequest = createSelector(
  authenticationSelectors.selectWorkspaceId,
  locationsSelectors.selectLocation,
  (workspaceId, location) =>
    ({
      id: location.id,
      workspaceId: location.workspaceId || workspaceId,
      name: location.name,
      active: location.active,
      address: location.address,
    } as CreatePhysicalLocationInput)
);

const selectCloneLocationRequest = createSelector(
  authenticationSelectors.selectWorkspaceId,
  locationsSelectors.selectLocation,
  locationsSelectors.selectCloneName,
  (workspaceId, location, cloneName) =>
    ({
      id: '',
      workspaceId: workspaceId,
      name: cloneName,
      active: location.active,
      address: location.address,
    } as CreatePhysicalLocationInput)
);

function* getLocationsSaga() {
  try {
    const workspaceId: string = yield select(authenticationSelectors.selectWorkspaceId);
    const response: LocationsResponse = yield call(getLocationsByWorkspaceId, workspaceId);

    yield put(locationsActions.getLocationsSuccess(response.locations));
  } catch (error: unknown) {
    yield put(locationsActions.getLocationsFail(error?.toString()));
    yield call(handleServiceError, error, GET_LOCATIONS_ERROR_TOAST, true);
  }
}

function* deactivateLocationSaga() {
  try {
    const location: CreatePhysicalLocationInput = yield select(selectUpsertLocationRequest);
    yield call(upsertLocations, [{ ...location, active: !location.active }]);

    yield put(locationsActions.deactivateLocationSuccess());
    yield put(locationsActions.getLocationsRequest());
    yield put(
      notificationsActions.showToast(
        location.active ? DEACTIVATE_LOCATION_SUCCESS_TOAST : ACTIVATE_LOCATION_SUCCESS_TOAST
      )
    );
  } catch (error: unknown) {
    yield put(locationsActions.deactivateLocationFail(error?.toString()));
    yield call(handleServiceError, error, DEACTIVATE_LOCATION_ERROR_TOAST);
  }
}

function* cloneLocationSaga() {
  try {
    const location: CreatePhysicalLocationInput = yield select(selectCloneLocationRequest);
    yield call(upsertLocations, [location]);

    yield put(locationsActions.cloneLocationSuccess());
    yield put(cloneLocationModalActions.closeModal());
    yield put(locationsActions.getLocationsRequest());
    yield put(notificationsActions.showToast(CLONE_LOCATION_SUCCESS_TOAST));
  } catch (error: unknown) {
    yield put(locationsActions.cloneLocationFail(error?.toString()));
    yield call(handleServiceError, error, CLONE_LOCATION_ERROR_TOAST);
  }
}

function* deleteLocationsSaga(action: ReturnType<typeof locationsActions.deleteLocationRequest>) {
  try {
    if (action.type === LocationsActionTypes.DELETE_LOCATIONS_REQUEST) {
      yield call(deleteLocations, action.payload);

      yield put(locationsActions.deleteLocationSuccess());
      yield put(locationsActions.getLocationsRequest());
      yield put(notificationsActions.showToast(DELETE_LOCATION_SUCCESS_TOAST));
    }
  } catch (error: unknown) {
    yield put(locationsActions.deactivateLocationFail(error?.toString()));
    yield call(handleServiceError, error, DELETE_LOCATION_ERROR_TOAST);
  }
}

function* importLocationsSaga(action: ReturnType<typeof locationsActions.importLocationsRequest>) {
  try {
    if (action.type === LocationsActionTypes.IMPORT_LOCATIONS_REQUEST) {
      yield call(upsertLocations, action.payload);

      yield put(locationsActions.importLocationsSuccess());
      yield call(navigationService.navigateTo, Path.Locations);
      yield put(notificationsActions.showToast(IMPORT_LOCATIONS_SUCCESS_TOAST));
    }
  } catch (error: unknown) {
    yield put(locationsActions.deactivateLocationFail(error?.toString()));
    yield call(handleServiceError, error, IMPORT_LOCATIONS_ERROR_TOAST);
  }
}

function* getLocationSaga(action: ReturnType<typeof locationsActions.getLocationRequest>) {
  try {
    if (action.type === LocationsActionTypes.GET_LOCATION_REQUEST) {
      let location = DEFAULT_PHYSICAL_LOCATION;
      if (action.payload !== 'new') {
        const response: LocationResponse = yield call(getLocationById, action.payload);
        location = response.location;
      }

      yield put(locationsActions.getLocationSuccess(location));
    }
  } catch (error: unknown) {
    yield put(locationsActions.deactivateLocationFail(error?.toString()));
    yield call(handleServiceError, error, GET_LOCATION_ERROR_TOAST, true);
  }
}

function* upsertLocationSaga() {
  try {
    const location: CreatePhysicalLocationInput = yield select(selectUpsertLocationRequest);
    yield call(upsertLocations, [location]);

    yield put(locationsActions.upsertLocationSuccess());
    yield call(navigationService.navigateTo, Path.Locations);
    yield put(
      notificationsActions.showToast(location.id ? UPDATE_LOCATION_SUCCESS_TOAST : CREATE_LOCATION_SUCCESS_TOAST)
    );
  } catch (error: unknown) {
    yield put(locationsActions.upsertLocationFail(error?.toString()));
    yield call(handleServiceError, error, SAVE_LOCATION_ERROR_TOAST);
  }
}

export function* watchLocationsSaga() {
  yield takeLatest(LocationsActionTypes.GET_LOCATIONS_REQUEST, getLocationsSaga);
  yield takeLatest(LocationsActionTypes.DEACTIVATE_LOCATION_REQUEST, deactivateLocationSaga);
  yield takeLatest(LocationsActionTypes.CLONE_LOCATION_REQUEST, cloneLocationSaga);
  yield takeLatest(LocationsActionTypes.DELETE_LOCATIONS_REQUEST, deleteLocationsSaga);
  yield takeLatest(LocationsActionTypes.IMPORT_LOCATIONS_REQUEST, importLocationsSaga);
  yield takeLatest(LocationsActionTypes.GET_LOCATION_REQUEST, getLocationSaga);
  yield takeLatest(LocationsActionTypes.UPSERT_LOCATION_REQUEST, upsertLocationSaga);
}

export const locationsSagas = {
  getLocations: getLocationsSaga,
};
