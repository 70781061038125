import { useEffect } from 'react';
import { GOOGLE_AUTH_REDIRECT_EVENT } from './constants';

export const GoogleAuthRedirect = () => {
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search || window.location.hash.replaceAll('#', ''));
    const code = urlParams.get('code');
    const access_token = urlParams.get('access_token');
    const state = Number(urlParams.get('state'));

    if (code || access_token) {
      window.opener.postMessage(
        {
          code,
          access_token,
          state,
          type: GOOGLE_AUTH_REDIRECT_EVENT,
        },
        // We need to support any chrome-extension:// origins in development.
        // In production, we can replace it with the actual static extension id.
        '*'
      );

      window.close();
    }
  }, []);

  return <div style={{ width: '1', height: '1' }} />;
};
