export const Journey = Object.freeze({
  TITLE: `Construtor de jornada`,
  CLONE_TITLE: `Insira o nome da jornada clonada`,
  ADD_LABEL: `Criar Jornada`,
  CREATED_BY: `Criado por`,
  EMPTY_LIST_DESCRIPTION: `Crie uma jornada ou ajuste sua pesquisa ou filtros.`,
  RESPONSE: `resposta`,
  RESPONSES: `respostas`,
  LAST: `Última`,
  DEACTIVATE_TITLE: `Desativar Jornada`,
  DEACTIVATE_DESCRIPTION: `Tem certeza de que deseja desativar`,
  DELETE_TITLE: `Excluir Jornada`,
  DELETE_DESCRIPTION: `Tem certeza de que deseja excluir`,
  SELECTED_JOURNEY: `jornadas selecionadas`,
  NAME_LABEL: `Nome`,
});

export const EditJourney = Object.freeze({
  JOURNEY_TITLE: `Jornada`,
  RESPONSES_TITLE: `Respostas`,
  DELETE_STEP_TITLE: `Excluir Etapa`,
  DELETE_STEP_DESCRIPTION: `Tem certeza de que deseja excluir esta etapa?`,
  DELETE_STEP_ADDITIONAL_TEXT: `Observe que esta ação também removerá permanentemente todas as etapas subsequentes vinculadas a ela.`,
  START_NODE_TITLE: `Sua jornada começa aqui`,
  CONDITION_TITLE: `Condição`,
  DESTINATION_TITLE: `Destino`,
  PAGE_TITLE: `Página`,
  WITH_CARDS: `com cartões`,
  WITH_FORM: `com formulário`,
  IF_LABEL: `Se`,
  OR_LABEL: `Ou`,
  ADD_LABEL: `Adicionar`,
  CUSTOM_NAME: `Nome personalizado`,
  CREATE_JOURNEY: `Criar Jornada`,
  EDIT_JOURNEY: `Editar Jornada`,
  CARD: `Cartão`,
  LOGIC: `Lógica`,
  PREVIEW_JOURNEY: `Prévia da jornada`,
});

export const JourneysPageStep = Object.freeze({
  SELECTION_BOX: `Caixa de seleção`,
  CARD_TITLE: `Título aqui*`,
  CARD_DETAILS: `Detalhes aqui*`,
  FORM_INPUT_TILE: `Título de entrada`,
  FORM_INPUT_SUBTITLE: `Subtítulo de entrada`,
  FORM_WRITE_QUESTION: `Escrever uma pergunta`,
  FORM_INPUT_REQUIRED: `Este campo é obrigatório`,
  FORM_QUESTION_REQUIRED: `Esta pergunta é obrigatória`,
  FORM_ANSWER: `Resposta`,
  FORM_ADD_ANSWER: `+ Adicionar uma resposta`,
  TITLE: `Título`,
  TITLE_PLACEHOLDER: `Digite o título da página`,
  NAME: `Nome`,
  PAGE_NAME: `Nome da página`,
  PAGE_TYPE: `Tipo de página`,
  NO_ELEMENTS: `Nenhum elemento adicionado`,
  INPUTS: `Entradas`,
  SELECTION: `Seleção`,
  SELECT_TYPE: `Selecione o tipo primeiro`,
});

export const JourneysDestinationStep = Object.freeze({
  TYPE_LABEL: `Tipo de destino`,
  SHOWS_STEP_SEPARATLY: `Mostrar etapas separadamente`,
  WHO_DESCRIPTION: `Lista de especialistas para selecionar`,
  WHEN_DESCRIPTION: `Calendário e horário`,
  WHERE_DESCRIPTION: `Mapa com escritórios para selecionar`,
  HEADER_LABEL: `Cabeçalho`,
  MESSAGE_LABEL: `Mensagem`,
  URL_LABEL: `URL externa`,
});

export const JourneyDefault = Object.freeze({
  JOURNEY_NAME: `Jornada personalizada`,
  PAGE_NAME: `Personalizado`,
  SUBMIT_LABEL: `Enviar`,
  FORM: `Formulário`,
  CARDS: `Cartões`,
  IMPORT: `Importar`,
  TITLE: `Título`,
  SUBTITLE: `Subtítulo`,
  NAME: `Nome`,
  SINGLE: `Linha única`,
  EMAIL: `E-mail`,
  MULTIPLE: `Múltiplas linhas`,
  PHONE: `Número de telefone`,
  ATTACHMENT: `Anexo`,
  DROPDOWN: `Menu suspenso`,
  RADIO_BUTTONS: `Caixas de seleção`,
  IS: `é`,
  IS_NOT: `não é`,
  CONTAINS: `contém`,
  DOES_NOT_CONTAIN: `não contém`,
  STARTS_WITH: `começa com`,
  IS_NOT_EMPTY: `não está vazio`,
  IS_EMPTY: `está vazio`,
  RANGE: `é intervalo`,
  FILETYPE_IS: `tipo de arquivo é`,
  FILETYPE_IS_NOT: `tipo de arquivo não é`,
  SIZE_IS_BIGGER_THAN: `tamanho é maior que`,
  SIZE_IS_SMALLER_THAN: `tamanho é menor que`,
  BOOKING_PAGE: `Página de reserva`,
  MESSAGE: `Mensagem`,
  EXTERNAL_URL: `URL externa`,
  CHAT_AI: `Chat IA`,
});

export const JourneysToastsNotifications = Object.freeze({
  GET_JOURNEYS_ERROR_MESSAGE: `Falha na solicitação de obtenção de jornadas`,
  DEACTIVATE_JOURNEY_SUCCESS_MESSAGE: `Jornada desativada com sucesso`,
  ACTIVATE_JOURNEY_SUCCESS_MESSAGE: `Jornada ativada com sucesso`,
  DEACTIVATE_JOURNEY_ERROR_MESSAGE: `Falha na solicitação de ativação/desativação da jornada`,
  CLONE_JOURNEY_SUCCESS_MESSAGE: `Jornada clonada com sucesso`,
  CLONE_JOURNEY_ERROR_MESSAGE: `Falha na solicitação de clonagem da jornada`,
  DELETE_JOURNEYS_SUCCESS_MESSAGE: `Jornadas excluídas com sucesso`,
  DELETE_JOURNEYS_ERROR_MESSAGE: `Falha na solicitação de exclusão de jornadas`,
  GET_JOURNEY_ERROR_MESSAGE: `Falha na solicitação de obtenção da jornada`,
  SAVE_JOURNEY_ERROR_MESSAGE: `Falha na solicitação de salvamento da jornada`,
  CREATE_JOURNEY_SUCCESS_MESSAGE: `Jornada criada com sucesso`,
  UPDATE_JOURNEY_SUCCESS_MESSAGE: `Jornada atualizada com sucesso`,
  GET_JOURNEY_EVENTS_ERROR_MESSAGE: `Falha na solicitação de eventos da jornada`,
});

export const JourneysEvent = Object.freeze({
  VIEW_EVENT: `Ver evento`,
  TITLE: `Respostas`,
  NO_RESPONSES: `Ainda não há respostas`,
  EMPTY_LIST_DESCRIPTION: `Ajuste sua busca ou filtros.`,
  DATE_RANGE: `Intervalo de datas`,
  RESPONSE: `Resposta`,
  EVENT_SCHEDULED: `Evento agendado`,
  CREATED_AT: `Criado em`,
  RESULT: `Resultado`,
  ANSWERS: `Respostas`,
  DESTINATION: `Destino`,
});
