import { CreatePhysicalLocationInput, PhysicalLocation } from '../../API';
import { CLONE_NAME_POSTFIX } from '../../types/constants';
import { AppThunk } from '../rootStore';
import { cloneLocationModalActions } from './modal';
import { locationsSelectors } from './selectors';
import { LocationFilter } from './types';

export enum LocationsActionTypes {
  GET_LOCATIONS_REQUEST = 'locations/GET_LOCATIONS_REQUEST',
  GET_LOCATIONS_SUCCESS = 'locations/GET_LOCATIONS_SUCCESS',
  GET_LOCATIONS_FAIL = 'locations/GET_LOCATIONS_FAIL',
  SET_LOCATION = 'locations/SET_LOCATION',
  UPDATE_LOCATION = 'locations/UPDATE_LOCATION',
  DEACTIVATE_LOCATION_REQUEST = 'locations/DEACTIVATE_LOCATION_REQUEST',
  DEACTIVATE_LOCATION_SUCCESS = 'locations/DEACTIVATE_LOCATION_SUCCESS',
  DEACTIVATE_LOCATION_FAIL = 'locations/DEACTIVATE_LOCATION_FAIL',
  SET_CLONE_NAME = 'locations/SET_CLONE_NAME',
  SET_FILTER = 'locations/SET_FILTER',
  CLONE_LOCATION_REQUEST = 'locations/CLONE_LOCATION_REQUEST',
  CLONE_LOCATION_SUCCESS = 'locations/CLONE_LOCATION_SUCCESS',
  CLONE_LOCATION_FAIL = 'locations/CLONE_LOCATION_FAIL',
  DELETE_LOCATIONS_REQUEST = 'locations/DELETE_LOCATIONS_REQUEST',
  DELETE_LOCATIONS_SUCCESS = 'locations/DELETE_LOCATIONS_SUCCESS',
  DELETE_LOCATIONS_FAIL = 'locations/DELETE_LOCATIONS_FAIL',
  IMPORT_LOCATIONS_REQUEST = 'locations/IMPORT_LOCATIONS_REQUEST',
  IMPORT_LOCATIONS_SUCCESS = 'locations/IMPORT_LOCATIONS_SUCCESS',
  IMPORT_LOCATIONS_FAIL = 'locations/IMPORT_LOCATIONS_FAIL',
  GET_LOCATION_REQUEST = 'locations/GET_LOCATION_REQUEST',
  GET_LOCATION_SUCCESS = 'locations/GET_LOCATION_SUCCESS',
  GET_LOCATION_FAIL = 'locations/GET_LOCATION_FAIL',
  UPSERT_LOCATION_REQUEST = 'locations/UPSERT_LOCATION_REQUEST',
  UPSERT_LOCATION_SUCCESS = 'locations/UPSERT_LOCATION_SUCCESS',
  UPSERT_LOCATION_FAIL = 'locations/UPSERT_LOCATION_FAIL',
  SET_SELECTED_LOCATIONS = 'locations/SET_SELECTED_LOCATIONS',
}

export type LocationsAction =
  | { type: LocationsActionTypes.GET_LOCATIONS_REQUEST }
  | { type: LocationsActionTypes.GET_LOCATIONS_SUCCESS; payload: PhysicalLocation[] }
  | { type: LocationsActionTypes.GET_LOCATIONS_FAIL; error: unknown }
  | { type: LocationsActionTypes.SET_LOCATION; payload: PhysicalLocation }
  | { type: LocationsActionTypes.UPDATE_LOCATION; payload: Partial<PhysicalLocation> }
  | { type: LocationsActionTypes.DEACTIVATE_LOCATION_REQUEST }
  | { type: LocationsActionTypes.DEACTIVATE_LOCATION_SUCCESS }
  | { type: LocationsActionTypes.DEACTIVATE_LOCATION_FAIL; error: unknown }
  | { type: LocationsActionTypes.SET_CLONE_NAME; payload: string }
  | { type: LocationsActionTypes.SET_FILTER; payload: Partial<LocationFilter> }
  | { type: LocationsActionTypes.CLONE_LOCATION_REQUEST }
  | { type: LocationsActionTypes.CLONE_LOCATION_SUCCESS }
  | { type: LocationsActionTypes.CLONE_LOCATION_FAIL; error: unknown }
  | { type: LocationsActionTypes.DELETE_LOCATIONS_REQUEST; payload: string[] }
  | { type: LocationsActionTypes.DELETE_LOCATIONS_SUCCESS }
  | { type: LocationsActionTypes.DELETE_LOCATIONS_FAIL; error: unknown }
  | { type: LocationsActionTypes.IMPORT_LOCATIONS_REQUEST; payload: CreatePhysicalLocationInput[] }
  | { type: LocationsActionTypes.IMPORT_LOCATIONS_SUCCESS }
  | { type: LocationsActionTypes.IMPORT_LOCATIONS_FAIL; error: unknown }
  | { type: LocationsActionTypes.GET_LOCATION_REQUEST; payload: string }
  | { type: LocationsActionTypes.GET_LOCATION_SUCCESS; payload: PhysicalLocation }
  | { type: LocationsActionTypes.GET_LOCATION_FAIL; error: unknown }
  | { type: LocationsActionTypes.UPSERT_LOCATION_REQUEST }
  | { type: LocationsActionTypes.UPSERT_LOCATION_SUCCESS }
  | { type: LocationsActionTypes.UPSERT_LOCATION_FAIL; error: unknown }
  | { type: LocationsActionTypes.SET_SELECTED_LOCATIONS; payload: PhysicalLocation[] };

const getLocationsRequest = (): LocationsAction => ({ type: LocationsActionTypes.GET_LOCATIONS_REQUEST });
const getLocationsSuccess = (payload: PhysicalLocation[]): LocationsAction => ({
  type: LocationsActionTypes.GET_LOCATIONS_SUCCESS,
  payload,
});
const getLocationsFail = (error: unknown): LocationsAction => ({
  type: LocationsActionTypes.GET_LOCATIONS_FAIL,
  error,
});

const setLocation = (payload: PhysicalLocation): LocationsAction => ({
  type: LocationsActionTypes.SET_LOCATION,
  payload,
});
const updateLocation = (payload: Partial<PhysicalLocation>): LocationsAction => ({
  type: LocationsActionTypes.UPDATE_LOCATION,
  payload,
});

const deactivateLocationRequest = (): LocationsAction => ({
  type: LocationsActionTypes.DEACTIVATE_LOCATION_REQUEST,
});
const deactivateLocationSuccess = (): LocationsAction => ({
  type: LocationsActionTypes.DEACTIVATE_LOCATION_SUCCESS,
});
const deactivateLocationFail = (error: unknown): LocationsAction => ({
  type: LocationsActionTypes.DEACTIVATE_LOCATION_FAIL,
  error,
});

const setCloneName = (payload: string): LocationsAction => ({
  type: LocationsActionTypes.SET_CLONE_NAME,
  payload,
});
const setFilter = (payload: Partial<LocationFilter>): LocationsAction => ({
  type: LocationsActionTypes.SET_FILTER,
  payload,
});

const cloneLocationRequest = (): LocationsAction => ({
  type: LocationsActionTypes.CLONE_LOCATION_REQUEST,
});
const cloneLocationSuccess = (): LocationsAction => ({
  type: LocationsActionTypes.CLONE_LOCATION_SUCCESS,
});
const cloneLocationFail = (error: unknown): LocationsAction => ({
  type: LocationsActionTypes.CLONE_LOCATION_FAIL,
  error,
});
// TODO: move to /thunks please
const cloneLocationThunk = (): AppThunk => (dispatch, getState) => {
  const state = getState();
  const cloneName = locationsSelectors.selectLocationName(state) + CLONE_NAME_POSTFIX;
  dispatch(setCloneName(cloneName));
  dispatch(cloneLocationModalActions.openModal());
};

const deleteLocationRequest = (payload: string[]): LocationsAction => ({
  type: LocationsActionTypes.DELETE_LOCATIONS_REQUEST,
  payload,
});
const deleteLocationSuccess = (): LocationsAction => ({
  type: LocationsActionTypes.DELETE_LOCATIONS_SUCCESS,
});
const deleteLocationFail = (error: unknown): LocationsAction => ({
  type: LocationsActionTypes.DELETE_LOCATIONS_FAIL,
  error,
});

const importLocationsRequest = (payload: CreatePhysicalLocationInput[]): LocationsAction => ({
  type: LocationsActionTypes.IMPORT_LOCATIONS_REQUEST,
  payload,
});
const importLocationsSuccess = (): LocationsAction => ({
  type: LocationsActionTypes.IMPORT_LOCATIONS_SUCCESS,
});
const importLocationsFail = (error: unknown): LocationsAction => ({
  type: LocationsActionTypes.IMPORT_LOCATIONS_FAIL,
  error,
});

const getLocationRequest = (payload: string): LocationsAction => ({
  type: LocationsActionTypes.GET_LOCATION_REQUEST,
  payload,
});
const getLocationSuccess = (payload: PhysicalLocation): LocationsAction => ({
  type: LocationsActionTypes.GET_LOCATION_SUCCESS,
  payload,
});
const getLocationFail = (error: unknown): LocationsAction => ({
  type: LocationsActionTypes.GET_LOCATION_FAIL,
  error,
});

const upsertLocationRequest = (): LocationsAction => ({
  type: LocationsActionTypes.UPSERT_LOCATION_REQUEST,
});
const upsertLocationSuccess = (): LocationsAction => ({
  type: LocationsActionTypes.UPSERT_LOCATION_SUCCESS,
});
const upsertLocationFail = (error: unknown): LocationsAction => ({
  type: LocationsActionTypes.UPSERT_LOCATION_FAIL,
  error,
});

const setSelectedLocation = (payload: PhysicalLocation[]): LocationsAction => ({
  type: LocationsActionTypes.SET_SELECTED_LOCATIONS,
  payload,
});

export const locationsActions = {
  getLocationsRequest,
  getLocationsSuccess,
  getLocationsFail,

  setLocation,
  updateLocation,

  deactivateLocationRequest,
  deactivateLocationSuccess,
  deactivateLocationFail,

  setCloneName,
  setFilter,

  cloneLocationRequest,
  cloneLocationSuccess,
  cloneLocationFail,
  cloneLocationThunk,

  deleteLocationRequest,
  deleteLocationSuccess,
  deleteLocationFail,

  importLocationsRequest,
  importLocationsSuccess,
  importLocationsFail,

  getLocationRequest,
  getLocationSuccess,
  getLocationFail,

  upsertLocationRequest,
  upsertLocationSuccess,
  upsertLocationFail,

  setSelectedLocation,
};
