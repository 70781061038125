import { t } from '../../../i18n/i18n';

export default {
  duplicate: t('Common:NAME_IS_DUPLICATE'),
  name: t('BookingPageWhatStep:NAME'),
  namePlaceholder: t('BookingPageWhatStep:NAME_PLACEHOLDER'),
  nameTooltip: t('BookingPageWhatStep:NAME_TOOLTIP'),
  color: t('BookingPageWhatStep:COLOR'),
  meetingInstructions: t('BookingPageWhatStep:MEETING_INSTRUCTIONS'),
  meetingInstructionsPlaceholder: t('BookingPageWhatStep:MEETING_INSTRUCTIONS_PLACEHOLDER'),
  meetingInstructionsTooltip: t('BookingPageWhatStep:MEETING_INSTRUCTIONS_TOOLTIP'),
};
