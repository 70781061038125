export const LookAndFeel = Object.freeze({
  BACKGROUND_LABEL: `Fundo`,
  BUTTONS_LABEL: `Botões`,
  FONT_COLORS_TITLE: `Cores da Fonte`,
  FONT_FAMILIES_TITLE: `Famílias de Fontes`,
  HEADER_FONT_LABEL: `Fonte do Cabeçalho`,
  HEADER_LABEL: `Cabeçalho`,
  MAIN_TEXT_FONT_LABEL: `Fonte do Texto Principal`,
  MAIN_TEXT_LABEL: `Texto Principal`,
  PRIMARY_LABEL: `Primário`,
  SITE_COLORS_TITLE: `Cores do Site`,
  SUB_HEADER_FONT_LABEL: `Fonte do Subcabeçalho`,
  SUB_HEADER_LABEL: `Subcabeçalho`,
});

export const QuickSetupCalendarConnected = Object.freeze({
  DESCRIPTION: `SUMO verificará automaticamente o seu calendário para horários ocupados e adicionará novas reuniões conforme forem agendadas. Você pode desconectar seu calendário a qualquer momento.`,
  INSTRUCTION_STEP_A1: `Verificaremos `,
  INSTRUCTION_STEP_A2: ` para conflitos.`,
  INSTRUCTION_STEP_B: `Adicionaremos reuniões ao calendário para `,
  INSTRUCTION_STEP_C: `Criptografaremos todos os dados em trânsito e lhe daremos opções em relação aos seus dados.`,
  INSTRUCTION_TITLE: `Veja como o SUMO funcionará com `,
  STEP_MESSAGE: `Vamos fazer isso!`,
  TITLE: `Seu calendário está conectado!`,
});

export const QuickSetupChromeExtensionStep = Object.freeze({
  ACCEPT_TERMS_PART1: `Aceito os`,
  ACCEPT_TERMS_PART2: `Termos de Uso`,
  ACCEPT_TERMS_PART3: `e a`,
  ACCEPT_TERMS_PART4: `Política de Privacidade`,
  BENEFITS_DESCRIPTION: `A melhor experiência SUMO1 é usar o SUMO diretamente da sua caixa de entrada, instalando a Extensão do Chrome. Use o SUMO em:`,
  BENEFITS_LIST_PART1: `Google Gmail & Calendário`,
  BENEFITS_LIST_PART2: `Microsoft Caixa de entrada & Calendário`,
  BENEFITS_LIST_PART3: `Salesforce CRM`,
  BENEFITS_TITLE: `Benefícios`,
  CHROME_EXTENSION: `Extensão do Chrome`,
  REQUIREMENTS_DESCRIPTION: `Você deve usar o navegador Google Chrome.`,
  REQUIREMENTS_TITLE: `Requisitos`,
  SUMO1_FOR_GOOGLE: `Instalar "SUMO1 para Gmail"`,
  SUMO1_FOR_MICROSOFT: `Instalar "SUMO1 para Outlook"`,
  TITLE: `Instalar Extensão do Chrome`,
});

export const QuickSetupSetupAvailabilityStep = Object.freeze({
  TITLE_ADMIN: `Definir disponibilidade da equipe`,
  TITLE_USER: `Definir sua disponibilidade`,
  MESSAGE_ADMIN: `Crie a programação padrão inicial indicando quando sua equipe está geralmente disponível para compromissos.`,
  MESSAGE_USER: `Crie a programação padrão inicial indicando quando você está geralmente disponível para compromissos.`,
  MINIMUM_DAYS_ERROR_TEXT: `Por favor, selecione pelo menos um dia com horas disponíveis`,
  SETUP_DAYS_INPUT_LABEL: `Dias disponíveis`,
  SETUP_HOURS_INPUT_LABEL: `Horas disponíveis`,
  STEP_MESSAGE: `Continue assim!`,
});

export const QuickSetupSetupPhoneCallsStep = Object.freeze({
  DESCRIPTION: `Para chamadas agendadas, para qual número os convidados devem ligar? A maioria dos usuários fornece seu celular para alertas por SMS, mas você pode atualizar isso mais tarde.`,
  PHONE_DETAILS_LABELS: `Informações adicionais ou instruções`,
  PHONE_DETAILS_PLACEHOLDER: `Ex: Estou ansioso para a reunião. Envie-me um SMS a qualquer momento.`,
  PHONE_PLACEHOLDER: `Número de telefone`,
  STEP_MESSAGE: `Você está quase lá!`,
  TITLE: `Definir número de telefone`,
});

export const QuickSetupToastsNotifications = Object.freeze({
  SAVE_QUICK_SETUP_ERROR_TOAST: `Falha ao salvar a configuração rápida`,
  SAVE_QUICK_SETUP_SUCCESS_TOAST_DESC: `Agora você está pronto para agendar facilmente.`,
  SAVE_QUICK_SETUP_SUCCESS_TOAST_TITLE: `Configuração completa!`,
});

export const QuickSetupVideoConferenceStep = Object.freeze({
  DEFAULT_DESCRIPTION: `Videoconferência padrão`,
  DESCRIPTION: `Opcional, mas recomendado para reuniões virtuais.`,
  GOOGLE_MEET_TITLE: `Google Meet`,
  MICROSOFT_TEAMS_TITLE: `Microsoft Teams`,
  STEP_MESSAGE: `Vamos fazer isso!`,
  TITLE: `Conectar conta de videoconferência`,
  ZOOM_TITLE: `Reuniões ZOOM`,
});

export const QuickSetupYourBrandStep = Object.freeze({
  DESCRIPTION: `Carregue seu logotipo para aparecer em todas as páginas de reserva. Você pode fazer mais configurações de branding no espaço de trabalho mais tarde.`,
  SAVE_BUTTON: `Salvar & Concluir Configuração`,
  STEP_MESSAGE: `Você está quase lá!`,
  TITLE: `Adicione seu logotipo`,
});
