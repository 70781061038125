import { LEAVE_THE_ACCOUNT, leaveTheAccountModalActions } from '../../../store/authentication/modal';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { InviteToAccount, authenticationActions, authenticationSelectors } from '../../../store/authentication';
import { Button } from 'primereact/button';
import { Modal } from '../../common';
import labels from './labels';
import { InformationCircleIcon } from '@heroicons/react/24/outline';

export const ModalLeaveAccount = () => {
  const inviteToAccount: InviteToAccount | null = useSelector(authenticationSelectors.selectInviteToAccount);
  const isLastSuperAdmin = useSelector(authenticationSelectors.selectIsLastSuperAdmin);
  const dispatch = useDispatch();

  useEffect(() => {
    if (inviteToAccount) {
      dispatch(leaveTheAccountModalActions.openModal());
    }
  }, [inviteToAccount]);

  const handleChooseExistingTenant = () => {
    if (inviteToAccount?.existingTenant) {
      dispatch(authenticationActions.chooseAnAccountRequest(inviteToAccount.existingTenant));
      dispatch(leaveTheAccountModalActions.closeModal());
    }
  };

  const handleChooseNewTenant = () => {
    if (inviteToAccount?.newTenant) {
      dispatch(authenticationActions.chooseAnAccountRequest(inviteToAccount.newTenant));
      dispatch(leaveTheAccountModalActions.closeModal());
    }
  };

  return (
    <Modal.Container name={LEAVE_THE_ACCOUNT} closable className="w-500px">
      <Modal.Header>{labels.title}</Modal.Header>

      <div className="flex flex-column gap-24px">
        {isLastSuperAdmin && (
          <div className="flex gap-8px py-12px px-16px w-full border-1 border-blue-light border-radius-8px bg-blue-soft">
            <InformationCircleIcon className="icon-20px text-blue-main flex-none" />
            <div className="flex-1 text-label-xs-reg">{labels.lastSuperAdmin}</div>
          </div>
        )}

        <div className="flex flex-column gap-16px text-body-lg-reg">
          <div>{`${labels.inviteTextPart1} ${inviteToAccount?.newTenant?.invitedByName} ${labels.inviteTextPart2}`}</div>
          <div className="text-tomato-main">{labels.onlyOneAccount}</div>
          <div>{labels.acceptText}</div>
        </div>
      </div>

      <Modal.Buttons>
        <Button onClick={handleChooseNewTenant} className="min-w-120px" autoFocus disabled={isLastSuperAdmin}>
          {labels.acceptInvite}
        </Button>
        <Button onClick={handleChooseExistingTenant} text>
          {labels.cancel}
        </Button>
      </Modal.Buttons>
    </Modal.Container>
  );
};
