import { Modal } from '../../common';
import { CHOOSE_THE_ACCOUNT, chooseTheAccountModalActions } from '../../../store/authentication/modal';
import { CreateAdminDataInput } from '../../../API';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { CreateAdminDataType, authenticationActions, authenticationSelectors } from '../../../store/authentication';
import { Button } from 'primereact/button';
import labels from './labels';
import { InformationCircleIcon } from '@heroicons/react/24/outline';

export const ModalUserAccounts = () => {
  const allUserAccounts: CreateAdminDataInput[] | null = useSelector(authenticationSelectors.selectUserAccounts);
  const isLastSuperAdmin = useSelector(authenticationSelectors.selectIsLastSuperAdmin);
  const userAccounts = allUserAccounts?.filter((account) => account.invitedByName);
  const accountWithoutInvite = allUserAccounts?.find((account) => !account.invitedByName);
  const dispatch = useDispatch();

  useEffect(() => {
    if (allUserAccounts && allUserAccounts.length > 1) {
      dispatch(chooseTheAccountModalActions.openModal());
    }
  }, [allUserAccounts]);

  const handleCloseDeleteDialog = () => {
    if (accountWithoutInvite) {
      dispatch(authenticationActions.chooseAnAccountRequest(accountWithoutInvite));
    }
    dispatch(chooseTheAccountModalActions.closeModal());
  };

  const handleChooseAnAccount = (account: CreateAdminDataInput) => {
    dispatch(authenticationActions.chooseAnAccountRequest(account));
    dispatch(chooseTheAccountModalActions.closeModal());
  };

  return (
    <Modal.Container name={CHOOSE_THE_ACCOUNT} closable className="w-500px">
      <Modal.Header>{labels.title}</Modal.Header>

      <div className="flex flex-column gap-24px text-body-lg-reg">
        {isLastSuperAdmin && (
          <div className="flex gap-8px py-12px px-16px w-full border-1 border-blue-light border-radius-8px bg-blue-soft">
            <InformationCircleIcon className="icon-20px text-blue-main flex-none" />
            <div className="flex-1 text-label-xs-reg">{labels.lastSuperAdmin}</div>
          </div>
        )}

        <div className="flex flex-column gap-16px">
          <div>{labels.inviteText}</div>
          <div className="text-tomato-main">{labels.onlyOneAccount}</div>
          <div>{labels.selectText}</div>
        </div>

        <div className="flex flex-column gap-16px">
          <div className="flex font-bold justify-content-center align-items-center">{labels.inviteFrom}</div>
          {userAccounts &&
            userAccounts.map((account: CreateAdminDataType, index) => (
              <div key={account.updatedAt}>
                {index !== 0 && <div className="border-top-1 border-heavy-20" />}
                <div className="flex-between-center">
                  <div className="flex-1">{account.invitedByName}</div>
                  <div className="flex-none">
                    <Button
                      label={labels.acceptInvite}
                      onClick={() => {
                        handleChooseAnAccount(account);
                      }}
                      disabled={isLastSuperAdmin}
                    />
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div>

      <Modal.Buttons>
        <div className="flex-1 flex-center">
          <Button label={labels.cancel} onClick={handleCloseDeleteDialog} text className="min-w-120px" />
        </div>
      </Modal.Buttons>
    </Modal.Container>
  );
};
