import { combineReducers } from 'redux';
import { GlobalAction, GlobalActionTypes } from '../global/actions';
import { JourneysAction, JourneysActionTypes } from './actions';
import { Journey, JourneyEvent, JourneyStep } from '../../API';
import { DEFAULT_EVENT_FILTER, DEFAULT_FILTER } from './constants';
import { addJourneyStep, copyJourneyStep, editJourneyStep, removeJourneyStep } from './utils';
import { FieldsAndCards } from './types';

const journeys = (state: Journey[] = [], action: JourneysAction | GlobalAction) => {
  switch (action.type) {
    case JourneysActionTypes.GET_JOURNEYS_SUCCESS:
      return action.payload.journeys;
    case GlobalActionTypes.RESET_WHOLE_STORE:
      return [];
    default:
      return state;
  }
};

const journey = (state = {} as Journey, action: JourneysAction | GlobalAction) => {
  switch (action.type) {
    case JourneysActionTypes.SET_JOURNEY:
      return action.payload;
    case JourneysActionTypes.UPDATE_JOURNEY:
      return { ...state, ...action.payload };
    case JourneysActionTypes.GET_JOURNEY_SUCCESS:
      return action.payload.journey;
    case JourneysActionTypes.ADD_JOURNEY_STEP:
      return action.payload.parentId
        ? { ...state, root: addJourneyStep(state.root, action.payload.step, action.payload.parentId) }
        : { ...state, root: action.payload.step };
    case JourneysActionTypes.EDIT_JOURNEY_STEP:
      return { ...state, root: editJourneyStep(state.root, action.payload.step, action.payload.id) };
    case JourneysActionTypes.COPY_JOURNEY_STEP:
      return { ...state, root: copyJourneyStep(state.root, action.payload) };
    case JourneysActionTypes.REMOVE_JOURNEY_STEP:
      return { ...state, root: removeJourneyStep(state.root, action.payload) };
    case JourneysActionTypes.GET_JOURNEYS_REQUEST:
    case GlobalActionTypes.RESET_WHOLE_STORE:
      return {} as Journey;
    default:
      return state;
  }
};

const journeyStep = (state: JourneyStep | null = null, action: JourneysAction | GlobalAction) => {
  switch (action.type) {
    case JourneysActionTypes.SET_JOURNEY_STEP:
      return action.payload;
    case JourneysActionTypes.ADD_JOURNEY_STEP:
      return action.payload.step;
    case JourneysActionTypes.UPDATE_JOURNEY_STEP:
      return { ...state, ...action.payload } as JourneyStep;
    case JourneysActionTypes.UPDATE_PAGE_STEP:
      return { ...state, page: { ...state?.page, ...action.payload } } as JourneyStep;
    case JourneysActionTypes.UPDATE_CONDITION_STEP:
      return { ...state, condition: { ...state?.condition, ...action.payload } } as JourneyStep;
    case JourneysActionTypes.UPDATE_DESTINATION_STEP:
      return { ...state, destination: { ...state?.destination, ...action.payload } } as JourneyStep;
    case JourneysActionTypes.GET_JOURNEYS_REQUEST:
    case JourneysActionTypes.RESET_JOURNEY_STEP:
    case JourneysActionTypes.REMOVE_JOURNEY_STEP:
    case JourneysActionTypes.EDIT_JOURNEY_STEP:
    case GlobalActionTypes.RESET_WHOLE_STORE:
      return null;
    default:
      return state;
  }
};

const editStepSidebar = (state = false, action: JourneysAction | GlobalAction) => {
  switch (action.type) {
    case JourneysActionTypes.SET_EDIT_SIDEBAR:
      return action.payload;
    case JourneysActionTypes.RESET_JOURNEY_STEP:
    case JourneysActionTypes.REMOVE_JOURNEY_STEP:
    case JourneysActionTypes.EDIT_JOURNEY_STEP:
    case GlobalActionTypes.RESET_WHOLE_STORE:
      return false;
    default:
      return state;
  }
};

const fieldsAndCards = (state = {} as FieldsAndCards, action: JourneysAction | GlobalAction) => {
  switch (action.type) {
    case JourneysActionTypes.SET_FIELDS_AND_CARDS:
      return action.payload;
    case GlobalActionTypes.RESET_WHOLE_STORE:
      return {} as FieldsAndCards;
    default:
      return state;
  }
};

const isEdited = (state = false, action: JourneysAction | GlobalAction) => {
  switch (action.type) {
    case JourneysActionTypes.UPDATE_JOURNEY:
    case JourneysActionTypes.EDIT_JOURNEY_STEP:
      return true;
    case JourneysActionTypes.UPSERT_JOURNEY_SUCCESS:
    case JourneysActionTypes.SET_JOURNEY:
    case JourneysActionTypes.GET_JOURNEY_SUCCESS:
    case GlobalActionTypes.RESET_WHOLE_STORE:
      return false;
    default:
      return state;
  }
};

const filter = (state = DEFAULT_FILTER, action: JourneysAction | GlobalAction) => {
  switch (action.type) {
    case JourneysActionTypes.SET_FILTER:
      return { ...state, ...action.payload };
    case GlobalActionTypes.RESET_WHOLE_STORE:
      return DEFAULT_FILTER;
    default:
      return state;
  }
};

const selectedJourneys = (state: string[] = [], action: JourneysAction | GlobalAction) => {
  switch (action.type) {
    case JourneysActionTypes.SELECT_JOURNEYS:
      return action.payload;
    case JourneysActionTypes.GET_JOURNEYS_REQUEST:
    case GlobalActionTypes.RESET_WHOLE_STORE:
      return [];
    default:
      return state;
  }
};

const cloneName = (state = '', action: JourneysAction | GlobalAction) => {
  switch (action.type) {
    case JourneysActionTypes.SET_CLONE_NAME:
      return action.payload;
    case JourneysActionTypes.GET_JOURNEYS_REQUEST:
    case GlobalActionTypes.RESET_WHOLE_STORE:
      return '';
    default:
      return state;
  }
};

const journeyEvents = (state: JourneyEvent[] = [], action: JourneysAction | GlobalAction) => {
  switch (action.type) {
    case JourneysActionTypes.GET_JOURNEY_EVENTS_SUCCESS:
      return action.payload.journeyEvents;
    case GlobalActionTypes.RESET_WHOLE_STORE:
      return [];
    default:
      return state;
  }
};

const journeyEventFilter = (state = DEFAULT_EVENT_FILTER, action: JourneysAction | GlobalAction) => {
  switch (action.type) {
    case JourneysActionTypes.SET_EVENT_FILTER:
      return { ...state, ...action.payload };
    case GlobalActionTypes.RESET_WHOLE_STORE:
      return DEFAULT_EVENT_FILTER;
    default:
      return state;
  }
};

const lastLoadTime = (state = 0, action: JourneysAction | GlobalAction) => {
  switch (action.type) {
    case JourneysActionTypes.GET_JOURNEYS_SUCCESS:
      return new Date().getTime();
    case GlobalActionTypes.RESET_WHOLE_STORE:
      return 0;
    default:
      return state;
  }
};

const isFetching = (state = false, action: JourneysAction | GlobalAction) => {
  switch (action.type) {
    case JourneysActionTypes.GET_JOURNEYS_REQUEST:
    case JourneysActionTypes.CLONE_JOURNEY_REQUEST:
    case JourneysActionTypes.DELETE_JOURNEYS_REQUEST:
    case JourneysActionTypes.DEACTIVATE_JOURNEY_REQUEST:
    case JourneysActionTypes.GET_JOURNEY_REQUEST:
    case JourneysActionTypes.UPSERT_JOURNEY_REQUEST:
    case JourneysActionTypes.GET_JOURNEY_EVENTS_REQUEST:
      return true;
    case JourneysActionTypes.GET_JOURNEYS_SUCCESS:
    case JourneysActionTypes.GET_JOURNEYS_FAIL:
    case JourneysActionTypes.CLONE_JOURNEY_SUCCESS:
    case JourneysActionTypes.CLONE_JOURNEY_FAIL:
    case JourneysActionTypes.DELETE_JOURNEYS_SUCCESS:
    case JourneysActionTypes.DELETE_JOURNEYS_FAIL:
    case JourneysActionTypes.DEACTIVATE_JOURNEY_SUCCESS:
    case JourneysActionTypes.DEACTIVATE_JOURNEY_FAIL:
    case JourneysActionTypes.GET_JOURNEY_SUCCESS:
    case JourneysActionTypes.GET_JOURNEY_FAIL:
    case JourneysActionTypes.UPSERT_JOURNEY_SUCCESS:
    case JourneysActionTypes.UPSERT_JOURNEY_FAIL:
    case JourneysActionTypes.GET_JOURNEY_EVENTS_SUCCESS:
    case JourneysActionTypes.GET_JOURNEY_EVENTS_FAIL:
      return false;
    case GlobalActionTypes.RESET_WHOLE_STORE:
      return false;
    default:
      return state;
  }
};
const error = (state: unknown | null = null, action: JourneysAction | GlobalAction) => {
  switch (action.type) {
    case JourneysActionTypes.GET_JOURNEYS_FAIL:
    case JourneysActionTypes.CLONE_JOURNEY_FAIL:
    case JourneysActionTypes.DELETE_JOURNEYS_FAIL:
    case JourneysActionTypes.DEACTIVATE_JOURNEY_FAIL:
    case JourneysActionTypes.GET_JOURNEY_FAIL:
    case JourneysActionTypes.UPSERT_JOURNEY_FAIL:
    case JourneysActionTypes.GET_JOURNEY_EVENTS_FAIL:
      return action.error;
    case JourneysActionTypes.GET_JOURNEYS_REQUEST:
    case JourneysActionTypes.CLONE_JOURNEY_REQUEST:
    case JourneysActionTypes.DELETE_JOURNEYS_REQUEST:
    case JourneysActionTypes.DEACTIVATE_JOURNEY_REQUEST:
    case JourneysActionTypes.GET_JOURNEY_REQUEST:
    case JourneysActionTypes.UPSERT_JOURNEY_REQUEST:
    case JourneysActionTypes.GET_JOURNEY_EVENTS_REQUEST:
      return null;
    case GlobalActionTypes.RESET_WHOLE_STORE:
      return null;
    default:
      return state;
  }
};

export default combineReducers({
  journeys,
  journey,
  journeyStep,
  editStepSidebar,
  fieldsAndCards,
  isEdited,
  journeyEvents,
  journeyEventFilter,
  lastLoadTime,
  isFetching,
  error,
  filter,
  selectedJourneys,
  cloneName,
});
