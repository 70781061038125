import React from 'react';
import {
  JourneyDestination,
  JourneyDestinationType,
  JourneyEvent,
  JourneyFormField,
  JourneyFormFieldType,
  JourneyPage,
  JourneyPageType,
} from '../../../API';
import { Link } from 'react-router-dom';
import { Path } from '../../../routing';
import labels from './labels';
import { ImageUpload } from '../../common/imageUpload/ImageUpload';
import { PhoneNumber } from '../../common';

type JourneyEventItemProps = {
  event: JourneyEvent;
};

export const JourneyEventItem = ({ event }: JourneyEventItemProps) => {
  const getFieldValue = (field: JourneyFormField | null) => {
    const value = field?.value?.length ? field?.value[0] : null;
    
    if (field?.type === JourneyFormFieldType.ATTACHMENT) {
      return <ImageUpload value={value} disabled download />;
    }

    return (
      <div className="text-label-md-med text-heavy-100 white-space-pre-line">
        {field?.type === JourneyFormFieldType.PHONE ? `+${value?.split('_')[1] || ''}` : value || ''}
      </div>
    );
  };

  const getPageContent = (page: JourneyPage) => {
    if (page.type === JourneyPageType.CARD) {
      const card = page.cards?.length ? page.cards[0] : null;
      return (
        <>
          <div className="text-label-xs-reg text-heavy-60">{labels.card}</div>
          <div className="text-label-md-med text-heavy-100">{card?.title || ''}</div>
        </>
      );
    } else {
      return (
        <>
          {page.formFields?.map((field) => (
            <React.Fragment key={field?.id}>
              <div className="text-label-xs-reg text-heavy-60">
                {field?.label || ''}
                {field?.required ? '*' : ''}
              </div>
              {getFieldValue(field)}
            </React.Fragment>
          ))}
        </>
      );
    }
  };

  const getDestinationContent = (destination: JourneyDestination) => {
    if (destination.type === JourneyDestinationType.MESSAGE) {
      return (
        <>
          <div className="text-label-xs-reg text-heavy-60">{labels.message}</div>
          <div className="text-label-md-med text-heavy-100">{destination.message?.header}</div>
          <div className="text-label-md-med text-heavy-100">{destination.message?.body}</div>
        </>
      );
    } else if (destination.type === JourneyDestinationType.EXTERNAL_URL) {
      return (
        <>
          <div className="text-label-xs-reg text-heavy-60">{labels.externalURL}</div>
          <a className="text-label-md-med" target="_blank" href={destination.externalURL || '#'} rel="noreferrer">
            {destination.externalURL}
          </a>
        </>
      );
    } else {
      return (
        <Link target="_blank" to={`${Path.BookedMeetings}?eventId=${event.eventId}`} className="text-label-md-med">
          {labels.viewEvent}
        </Link>
      );
    }
  };

  return (
    <div className="flex flex-column gap-12px pb-24px mt-16px">
      {event.steps?.map((step, index) => (
        <div key={`step_${index}`} className="flex flex-column gap-12px">
          {index !== 0 && <div className="h-1px w-12 lg:w-6 bg-heavy-20 my-10px" />}
          {step?.page
            ? getPageContent(step?.page)
            : step?.destination
            ? getDestinationContent(step?.destination)
            : undefined}
        </div>
      ))}
    </div>
  );
};
