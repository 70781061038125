import { UpdateGroupBookingPageInput } from '../../API';
import { t } from '../../i18n/i18n';
import {
  TOAST_ERROR_COLOR,
  TOAST_ERROR_DURATION,
  TOAST_SUCCESS_COLOR,
  TOAST_SUCCESS_DURATION,
} from '../../types/constants';
import { ToastNotificationOptions } from '../notifications';

export const DEFAULT_GROUP_BOOKING_PAGE = {
  id: '',
  workspaceId: '',
  name: t('GroupBookingPage:GROUP_PAGE'),
  active: true,
} as UpdateGroupBookingPageInput;

export const CLONE_GROUP_PAGE_ERROR_TOAST: ToastNotificationOptions = Object.freeze({
  color: TOAST_ERROR_COLOR,
  autoHideDuration: TOAST_ERROR_DURATION,
  message: t('GroupBookingPagesToastsNotifications:CLONE_GROUP_PAGES_ERROR_MESSAGE'),
});
export const CLONE_GROUP_PAGE_SUCCESS_TOAST: ToastNotificationOptions = Object.freeze({
  color: TOAST_SUCCESS_COLOR,
  autoHideDuration: TOAST_SUCCESS_DURATION,
  message: t('GroupBookingPagesToastsNotifications:CLONE_GROUP_PAGES_SUCCESS_MESSAGE'),
});
export const DELETE_GROUP_PAGES_ERROR_TOAST: ToastNotificationOptions = Object.freeze({
  color: TOAST_ERROR_COLOR,
  autoHideDuration: TOAST_ERROR_DURATION,
  message: t('GroupBookingPagesToastsNotifications:DELETE_GROUP_PAGES_ERROR_MESSAGE'),
});
export const DELETE_GROUP_PAGES_SUCCESS_TOAST: ToastNotificationOptions = Object.freeze({
  color: TOAST_SUCCESS_COLOR,
  autoHideDuration: TOAST_SUCCESS_DURATION,
  message: t('GroupBookingPagesToastsNotifications:DELETE_GROUP_PAGES_SUCCESS_MESSAGE'),
});
export const GET_GROUP_PAGES_ERROR_TOAST: ToastNotificationOptions = Object.freeze({
  color: TOAST_ERROR_COLOR,
  autoHideDuration: TOAST_ERROR_DURATION,
  message: t('GroupBookingPagesToastsNotifications:GET_GROUP_PAGES_ERROR_MESSAGE'),
});
export const GET_GROUP_PAGE_ERROR_TOAST: ToastNotificationOptions = Object.freeze({
  color: TOAST_ERROR_COLOR,
  autoHideDuration: TOAST_ERROR_DURATION,
  message: t('GroupBookingPagesToastsNotifications:GET_GROUP_PAGE_ERROR_MESSAGE'),
});
export const SAVE_GROUP_PAGE_ERROR_TOAST: ToastNotificationOptions = Object.freeze({
  color: TOAST_ERROR_COLOR,
  autoHideDuration: TOAST_ERROR_DURATION,
  message: t('GroupBookingPagesToastsNotifications:SAVE_GROUP_PAGE_ERROR_MESSAGE'),
});
export const SAVE_GROUP_PAGE_SUCCESS_TOAST: ToastNotificationOptions = Object.freeze({
  color: TOAST_SUCCESS_COLOR,
  autoHideDuration: TOAST_SUCCESS_DURATION,
  message: t('GroupBookingPagesToastsNotifications:SAVE_GROUP_PAGE_SUCCESS_MESSAGE'),
});
export const ACTIVATE_GROUP_PAGE_SUCCESS_TOAST: ToastNotificationOptions = Object.freeze({
  color: TOAST_SUCCESS_COLOR,
  autoHideDuration: TOAST_SUCCESS_DURATION,
  message: t('GroupBookingPagesToastsNotifications:ACTIVATE_GROUP_PAGE_SUCCESS_MESSAGE'),
});
export const DEACTIVATE_GROUP_PAGE_SUCCESS_TOAST: ToastNotificationOptions = Object.freeze({
  color: TOAST_SUCCESS_COLOR,
  autoHideDuration: TOAST_SUCCESS_DURATION,
  message: t('GroupBookingPagesToastsNotifications:DEACTIVATE_GROUP_PAGE_SUCCESS_MESSAGE'),
});
