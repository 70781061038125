import { memo, useCallback, useEffect, useRef } from 'react';
import { Path } from '../../../routing';
import { MicrosoftCodeResponse } from '../../../store/authentication';
import { MICROSOFT_AUTH_REDIRECT_EVENT } from './constants';
import { Button } from 'primereact/button';
import { getUrlOrigin } from '../../../services/URLService';
import { ReactComponent as MicrosoftIcon } from '../../../assets/icons/32-microsoft.svg';

interface IMicrosoftAuthButton {
  disabled?: boolean;
  outlined?: boolean;
  label?: string;
  onRedirect?: (codeResponse: MicrosoftCodeResponse) => void;
  className?: string;
  login?: boolean;
  hideIcon?: boolean;
  loading?: boolean;
}

type MicrosoftAuthEvent = {
  type: string;
  state: number;
  code: string;
};

export const MicrosoftAuthButton = memo(
  ({ disabled, outlined, label, onRedirect, className, login, hideIcon, loading }: IMicrosoftAuthButton) => {
    const state = useRef<number>(0);

    const baseUrl = getUrlOrigin();
    const redirectUrl = `${baseUrl + Path.Landing}${Path.MicrosoftAuthRedirect}`;

    const handleRedirectEvent = useCallback((event: MessageEvent<MicrosoftAuthEvent>) => {
      if (
        event.origin === baseUrl &&
        event.data.type === MICROSOFT_AUTH_REDIRECT_EVENT &&
        event.data.state === state.current &&
        event.data.code
      ) {
        onRedirect && onRedirect({ code: event.data.code, redirectUrl });
        state.current = 0;
      }
    }, []);

    useEffect(() => {
      window.addEventListener('message', handleRedirectEvent);
      return () => {
        window.removeEventListener('message', handleRedirectEvent);
      };
    }, []);

    const handleLogin = () => {
      state.current = new Date().getTime();
      const clientId = process.env.REACT_APP_MICROSOFT_CLIENT_ID;
      const scopes = login
        ? 'openid profile'
        : ['Calendars.ReadWrite', 'User.Read', 'Contacts.Read', 'offline_access'].join(' ');
      const popupWidth = 520;
      const popupHeight = 630;
      // Fixes dual-screen position Most browsers      Firefox
      const dualScreenLeft = window.screenLeft || window.screenX;
      const dualScreenTop = window.screenTop || window.screenY;

      const width = window.innerWidth || document.documentElement.clientWidth || screen.width;
      const height = window.innerHeight || document.documentElement.clientHeight || screen.height;

      const systemZoom = width / window.screen.availWidth;
      const left = (width - popupWidth) / 2 / systemZoom + dualScreenLeft;
      const top = (height - popupHeight) / 2 / systemZoom + dualScreenTop;
      const features = `scrollbars=yes,toolbar=false,menubar=false,height=${popupHeight},width=${popupWidth},top=${top},left=${left}`;

      window.open(
        `https://login.microsoftonline.com/common/oauth2/v2.0/authorize?
        client_id=${clientId}
        &response_type=code
        &response_mode=query
        &prompt=select_account
        &redirect_uri=${encodeURIComponent(redirectUrl)}
        &scope=${encodeURIComponent(scopes)}
        &state=${state.current}
      `
          .split('\n')
          .map((s) => s.trim())
          .join(''),
        undefined,
        features
      );
    };

    return (
      <Button
        disabled={disabled}
        outlined={outlined}
        className={`${className} ${hideIcon ? 'justify-content-center' : 'gap-20px'}`}
        onClick={handleLogin}
      >
        {!hideIcon && (
          <div className="w-32px h-32px flex-center">
            {loading ? <i className="pi pi-spinner pi-spin text-title-xl-med"></i> : <MicrosoftIcon />}
          </div>
        )}
        <div>{label}</div>
      </Button>
    );
  }
);

MicrosoftAuthButton.displayName = 'MicrosoftAuthButton';
