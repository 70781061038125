import { useDispatch, useSelector } from 'react-redux';
import { bookingPageSelectors } from '../../../store/bookingPages';
import { InputTextarea } from 'primereact/inputtextarea';
import { groupBookingPagesActions, groupBookingPagesSelectors } from '../../../store/groupBookingPages';
import { InformationCircleIcon } from '@heroicons/react/24/outline';
import { Dropdown } from 'primereact/dropdown';
import labels from './labels';
import { SumoTooltip } from '../../common';
import { MAX_LENGTH_DESCRIPTION } from '../../../types/constants';

export const GroupBookingPageHowStep = () => {
  const dispatch = useDispatch();
  const groupBookingPages = useSelector(groupBookingPagesSelectors.selectGroupBookingPageBookingPages);
  const bookingPagesById = useSelector(bookingPageSelectors.selectBookingPagesById);
  const groupBookingPageOptions = useSelector(groupBookingPagesSelectors.selectGroupBookingPageOptions);
  const brandingBookingPagId = useSelector(groupBookingPagesSelectors.selectBrandingBookingPagId);
  const isEqualsStyles = useSelector(groupBookingPagesSelectors.selectIsBookingPageStyleEquals);

  const handleDescriptionChange = (id: string, description: string) => {
    const bookingPages = groupBookingPages.map((page) =>
      page?.bookingPageId === id ? { ...page, description } : page
    );
    dispatch(groupBookingPagesActions.updateGroupBookingPage({ bookingPages }));
  };

  const handleStyleFromChange = (id: string) => {
    const bookingPage = bookingPagesById[id];
    dispatch(
      groupBookingPagesActions.updateGroupBookingPage({
        brandingBookingPageId: id,
        style: bookingPage.style,
        labels: bookingPage.labels,
      })
    );
  };

  return (
    <div className="flex flex-column text-heavy-100 gap-24px p-fluid">
      {!isEqualsStyles && (
        <>
          <div className="text-saffron-dark flex-left-center gap-6px p-6px border-radius-6px bg-saffron-light">
            <div className="w-18px h-18px">
              <InformationCircleIcon width={18} height={18} />
            </div>
            <div className="text-label-xs-reg">{labels.brandingWarning}</div>
          </div>
          <div className="flex flex-column w-12 lg:w-6 gap-10px pr-4">
            <div className="text-title-xs-med">{labels.useBrandingTitle}</div>
            <Dropdown
              options={groupBookingPageOptions}
              value={brandingBookingPagId}
              onChange={(e) => handleStyleFromChange(e.target.value)}
            />
          </div>
        </>
      )}
      <div className="bg-heavy-20 h-1px w-full" />
      <div>
        <div className="flex-left-center gap-8px">
          <div className="text-title-xs-med">{labels.meetingDescTitle}</div>
          <SumoTooltip text={labels.meetingDescTooltip} />
        </div>
        <div className="text-body-s-reg text-heavy-60 mt-4px">{labels.addDescription}</div>
      </div>
      {!!groupBookingPages.length && (
        <div className="grid -mb-2">
          {groupBookingPages.map((record, index) => {
            const page = bookingPagesById[record?.bookingPageId || ''];
            return (
              <div
                key={page.id}
                className={`col-12 lg:col-6 flex flex-column ${index % 2 === 0 ? 'lg:pr-4' : 'lg:pl-4'}`}
              >
                <div className="flex flex-left-center gap-8px">
                  <div
                    className="w-16px h-16px border-radius-4px"
                    style={{ backgroundColor: page.what?.color || '' }}
                  />
                  <div className="text-label-md-med">{page.what?.customName}</div>
                </div>
                <InputTextarea
                  value={record?.description || ''}
                  onChange={(e) => handleDescriptionChange(page.id, e.target.value)}
                  placeholder={labels.addDescription}
                  autoResize={true}
                  rows={4}
                  className="mt-10px"
                  maxLength={MAX_LENGTH_DESCRIPTION}
                />
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};
