import { memo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CodeResponse as GoogleCodeResponse } from '@react-oauth/google';
import {
  GetAuthResponseType,
  MicrosoftCodeResponse,
  ThirdPartyType,
  authenticationActions,
  authenticationSelectors,
} from '../../../store/authentication';
import { GoogleAuthButton, MicrosoftAuthButton } from '../../public';
import { timeZoneService } from '../../../services/TimeZoneService';
import { Link, useLocation } from 'react-router-dom';
import { IntegrationType } from '../../../API';
import { globalSelectors } from '../../../store/global';
import labels from './labels';
import AppFooter from '../../public/appFooter/AppFooter';
import sumoLogo from '../../../assets/images/sumoLogo.svg';
import { Dropdown } from 'primereact/dropdown';
import i18n from '../../../i18n/i18n';
import { languageOptions } from '../../../services/utils';
import { useRotatingText } from '../../../hooks/useRotatingText';

interface IAuthentication {
  linkAction?: () => void;
  login: boolean;
}

export const Authentication = memo(({ linkAction, login }: IAuthentication) => {
  const dispatch = useDispatch();
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const tenantId = params.get('tenantId');
  const isFetching = useSelector(authenticationSelectors.selectIsFetching);
  const isGlobalFetching = useSelector(globalSelectors.selectIsFetching);

  const [thirdPartyType, setThirdPartyType] = useState<ThirdPartyType | null>(null);
  const userTimeZone = timeZoneService.getCurrentTimeZone();

  const isGoogleLoading = (isFetching || isGlobalFetching) && thirdPartyType === ThirdPartyType.GOOGLE;
  const isMicrosoftLoading = (isFetching || isGlobalFetching) && thirdPartyType === ThirdPartyType.MICROSOFT;
  const isThirdPartyLoading = isGoogleLoading || isMicrosoftLoading;

  const rotatingLoadingLabel = useRotatingText(
    [labels.loadingLabel1, labels.loadingLabel2, labels.loadingLabel3],
    isThirdPartyLoading
  );

  const handleGoogleAuthSuccess = (codeResponse: GoogleCodeResponse) => {
    setThirdPartyType(ThirdPartyType.GOOGLE);
    dispatch(
      authenticationActions.thirdPartyAuthRequest({
        type: ThirdPartyType.GOOGLE,
        integrationType: IntegrationType.GOOGLE_CALENDAR,
        codeResponse,
        switchAccount: false,
        login,
        userTimeZone,
        updateIntegration: false,
        joinTenant: tenantId,
      } as GetAuthResponseType)
    );
  };

  const handleMicrosoftAuthRedirect = (codeResponse: MicrosoftCodeResponse) => {
    setThirdPartyType(ThirdPartyType.MICROSOFT);
    dispatch(
      authenticationActions.thirdPartyAuthRequest({
        type: ThirdPartyType.MICROSOFT,
        integrationType: IntegrationType.MICROSOFT_CALENDAR,
        codeResponse,
        switchAccount: false,
        login,
        userTimeZone,
        updateIntegration: false,
        inviteTenantId: tenantId,
      } as GetAuthResponseType)
    );
  };

  const handleLanguageChange = (language: string) => {
    i18n.changeLanguage(language);
    window.location.reload();
  };

  return (
    <div className="flex h-screen">
      <div className="w-12 lg:w-6 h-full flex flex-column">
        <div className="flex-1 flex flex-column pl-120px">
          <div className="flex justify-content-between pt-48px pr-48px">
            <img src={sumoLogo} className="h-3rem cursor-pointer" />
            <div></div>
            <Dropdown
              className="auth-dropdown"
              placeholder="English" // TODO: transfer to labels
              options={languageOptions}
              value={i18n.language}
              onChange={(event) => handleLanguageChange(event.target.value)}
              valueTemplate={(option) => (
                <div className="flex align-items-center">
                  <i className="pi pi-globe text-xl text-heavy-60" />
                  <span className="text-label-input-reg text-heavy-80 ml-8px">{option.label}</span>
                </div>
              )}
            />
          </div>

          {!login && (
            <div className="h-0">
              <div className="pt-12px">{labels.signUpComment}</div>
            </div>
          )}

          <div style={{ flex: '15 15' }}></div>

          <div className="flex flex-column gap-12px pr-48px">
            <div className="text-display-lg-med">
              <div>{login ? labels.signInTitleLine1 : labels.signUpTitleLine1}</div>
              <div>{login ? labels.signInTitleLine2 : labels.signUpTitleLine2}</div>
            </div>
            <div className="text-body-lg-reg">{login ? labels.signInSubtitle : labels.signUpSubtitle}</div>
          </div>

          <div style={{ flex: '5 5' }}></div>

          <div className="flex flex-column gap-8px w-360px">
            <GoogleAuthButton
              label={isGoogleLoading ? rotatingLoadingLabel : login ? labels.signInGoogle : labels.signUpGoogle}
              className="auth-button"
              login={login}
              disabled={isFetching || isGlobalFetching}
              loading={isGoogleLoading}
              onSuccess={handleGoogleAuthSuccess}
            />
            <MicrosoftAuthButton
              label={
                isMicrosoftLoading ? rotatingLoadingLabel : login ? labels.signInMicrosoft : labels.signUpMicrosoft
              }
              className="auth-button"
              login={login}
              disabled={isFetching || isGlobalFetching}
              loading={isMicrosoftLoading}
              onRedirect={handleMicrosoftAuthRedirect}
            />
          </div>

          {!login && (
            <div className="h-0 w-360px">
              <div className="flex align-items-center gap-8px bg-blue-light border-radius-10px h-40px pl-10px pr-10px mt-8px">
                <div className="text-title-lg-med">👋</div>
                <div className="text-label-s-med text-blue-dark">{labels.signUpFreeTrial}</div>
              </div>
            </div>
          )}

          <div style={{ flex: '27 27' }}></div>

          <div className="flex flex-column gap-16px pb-24px text-body-lg-reg">
            <div>
              <span>{login ? labels.signInDontHaveAnAccount : labels.signUpHaveAnAccount}</span>
              <a href="#" onClick={linkAction} className="ml-2 text-button-md-med">
                {login ? labels.signUp : labels.signIn}
              </a>
            </div>
            <div>
              <span>{labels.lookingForSumoCep}</span>
              <Link to="https://www.sumoscheduler.com/" target="blank" className="ml-2 text-button-md-med">
                {labels.clickHere}
              </Link>
            </div>
          </div>
        </div>

        <div className="auth-footer-container">
          <AppFooter />
        </div>
      </div>
      <div className={`lg:w-6 bg-center bg-cover auth-image-${Math.floor(Math.random() * 10) + 1}`}></div>
    </div>
  );
});

Authentication.displayName = 'Authentication';
