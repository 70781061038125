export const DefaultBookingTemplate = Object.freeze({
  NAME: `30-Minuten-Meeting`,
  CONFIRMATION_SUBJECT: 'Neues Meeting: <Meeting Name> mit <Host Name> um <Meeting Time>, <Meeting Date>',
  RESCHEDULE_SUBJECT: 'Verschoben: <Meeting Name> mit <Host Name> um <Meeting Time>, <Meeting Date>',
  REMINDER_SUBJECT: 'Erinnerung: <Meeting Name> mit <Host Name> um <Meeting Time>, <Meeting Date>',
  CANCELATION_SUBJECT: 'Abgesagt: <Meeting Name> mit <Host Name> um <Meeting Time>, <Meeting Date>',
  FOLLOW_UP_SUBJECT: 'Nachverfolgung: <Meeting Name> mit <Host Name>',
  CONFIRMATION_BODY: `Hallo <Invitee Name>,

Ihr neues Meeting <Meeting Name> mit <Host Name> um <Meeting Time>, <Meeting Date> ist geplant.

<b>Ort</b>
<Meeting Location>

<View or Edit Meeting Link>`,
  RESCHEDULE_BODY: `Hallo <Invitee Name>,

Ihr Meeting <Meeting Name> mit <Host Name> wurde verschoben.

<b>Aktualisierte Zeit und Datum</b>
<Meeting Time>, <Meeting Date>

<b>Ort</b>
<Meeting Location>

<View or Edit Meeting Link>`,
  REMINDER_BODY: `Hallo <Invitee Name>,

Dies ist eine freundliche Erinnerung, dass Ihr <Meeting Name> mit <Host Name> um <Meeting Time>, <Meeting Date> stattfindet.

<b>Ort</b>
<Meeting Location>

<View or Edit Meeting Link>`,
  CANCELATION_BODY: `Hallo <Invitee Name>,

Ihr Meeting <Meeting Name> mit <Host Name> um <Meeting Time>, <Meeting Date> wurde abgesagt.`,
  FOLLOW_UP_BODY: `Hallo <Invitee Name>,

Wir hoffen, Ihr Meeting "<Meeting Name>" mit <Host Name> war produktiv.

Falls Sie Feedback haben oder weitere Unterstützung benötigen, zögern Sie bitte nicht, uns zu kontaktieren.`,
  SMS_TEXT:
    'Dies ist eine freundliche Erinnerung, dass Ihr <Meeting Name> mit <Host Name> um <Meeting Time>, <Meeting Date> stattfindet. Meeting anzeigen oder bearbeiten <Meeting Link>',
});

export const BookingTemplates = Object.freeze({
  NEW_BOOKING_TEMPLATE: `Neue Vorlage`,
  TITLE: `Buchungsvorlagen`,
  VIEW_DETAILS: `Details anzeigen`,
  MINUTE: `Min`,
  HOUR: `Std`,
  CLONE_MODAL_TITLE: `Buchungsvorlage klonen`,
  DELETE_MODAL_CONFIRM_TEXT: `Sind Sie sicher, dass Sie diese Buchungsvorlage löschen möchten?`,
  DELETE_MULTIPLE_MODAL_CONFIRM_TEXT: `Sind Sie sicher, dass Sie diese Buchungsvorlagen löschen möchten?`,
  DELETE_MODAL_USED_PAGES_TEXT: `Zuerst müssen Sie diese Buchungsvorlagen von den folgenden Buchungsseiten entfernen:`,
  FILTER_MY_TEMPLATES: `Meine Vorlagen`,
  FILTER_ALL_TEMPLATES: `Alle Vorlagen`,
  TOOLTIP: `Erstellen Sie Buchungsvorlagen für Buchungsseiten.`,
});

export const BookingTemplatesToastsNotifications = Object.freeze({
  CLONE_BOOKING_TEMPLATES_ERROR_MESSAGE: `Klonen der Buchungsvorlage fehlgeschlagen`,
  CLONE_BOOKING_TEMPLATES_SUCCESS_MESSAGE: `Buchungsvorlage erfolgreich geklont`,
  DELETE_BOOKING_TEMPLATES_ERROR_MESSAGE: `Das Entfernen der Buchungsvorlage ist fehlgeschlagen`,
  DELETE_BOOKING_TEMPLATES_SUCCESS_MESSAGE: `Buchungsvorlage erfolgreich entfernt`,
  GET_BOOKING_TEMPLATES_ERROR_MESSAGE: `Buchungsvorlage konnte nicht abgerufen werden`,
  SAVE_BOOKING_TEMPLATE_ERROR_MESSAGE: `Buchungsvorlage konnte nicht gespeichert werden`,
  SAVE_BOOKING_TEMPLATE_SUCCESS_MESSAGE: `Buchungsvorlage erfolgreich gespeichert`,
});

export const EditBookingTemplate = Object.freeze({
  WHAT_TITLE: `Was`,
  WHAT_DESCRIPTION: `Name und Farbe`,
  WHO_TITLE: `Wer`,
  WHO_DESCRIPTION: `Potenzielle Gastgeber und Team`,
  WHERE_TITLE: `Wo`,
  WHERE_DESCRIPTION: `Veranstaltungsdetails und Standorte`,
  WHEN_TITLE: `Wann`,
  WHEN_DESCRIPTION: `Dauer und Verfügbarkeit`,
  HOW_TITLE: `Wie sollte es aussehen?`,
  HOW_DESCRIPTION: `Markenstile und Darstellungen`,
  ALERTS_TITLE: `Benachrichtigungen`,
  ALERTS_DESCRIPTION: `E-Mails und Erinnerungen`,
  INVITEE_TITLE: `Eingeladene Einstellungen`,
  INVITEE_DESCRIPTION: `Buchungsformular und Berechtigungen des Eingeladenen`,
  AFTER_TITLE: `Nach der Buchung`,
  AFTER_DESCRIPTION: `Bestätigungsseite und Stornierungsrichtlinie`,
  ADDITIONAL_CONFIGURATIONS: `Zusätzliche Konfigurationen`,
  ACTIVE: `Aktiv`,
  LOCKED_TOOLTIP: `Datensatz gesperrt. Sie haben keine Berechtigung zum Bearbeiten. Zuletzt bearbeitet von:`,
});

export const EditBookingTemplateWhoStep = Object.freeze({
  HOSTS_AND_TEAMS: `Gastgeber und Teams`,
  SEARCH_PLACEHOLDER: `Gastgeber und Teams suchen`,
});

export const EditBookingTemplateWhereStep = Object.freeze({
  TITLE: `Wählen Sie eine oder mehrere Optionen aus, die dem Eingeladenen bei der Selbstplanung auf der Buchungsseite angezeigt werden sollen:`,
  ZOOM: `Zoom`,
  GOOGLE_MEET: `Google Meet`,
  MICROSOFT_TEAMS: `Microsoft Teams`,
  SKIP_WHERE_STEP: `Überspringen Sie den "Wo"-Schritt für virtuelle Besprechungen`,
  SKIP_WHERE_STEP_DESCRIPTION: `Der Video-Konferenzlink wird automatisch zur Besprechung hinzugefügt.`,
  PHONE_CALL: `Telefonanruf`,
  HOSTS_PHONE: `Telefonnummer des Moderators`,
  HOSTS_PHONE_DESCRIPTION: `Die Telefonnummer des Moderators wird automatisch zur Besprechung hinzugefügt.`,
  HOSTS_PHONE_NOTIFICATION: `Ein oder mehrere Gastgeber haben keine Telefonnummer`,
  INVITEE_PHONE: `Den Eingeladenen auffordern, ihre Telefonnummer einzugeben`,
  INVITEE_PHONE_DESCRIPTION: `Der Eingeladene wird aufgefordert, seine Telefonnummer hinzuzufügen.`,
  CUSTOM_PHONE: `Benutzerdefinierte Telefonnummer`,
  CUSTOM_PHONE_PLACEHOLDER: `Telefonnummer eingeben`,
  CUSTOM_PHONE_DESCRIPTION: `Diese Telefonnummer wird automatisch zur Besprechung hinzugefügt.`,
  IN_PERSON: `Persönlich`,
  CUSTOM_ADDRESS: `Benutzerdefinierte Adresse`,
  CUSTOM_ADDRESS_PLACEHOLDER: `Meeting-Adresse eingeben`,
  CUSTOM_ADDRESS_DESCRIPTION: `Diese Adresse wird automatisch zur Besprechung hinzugefügt.`,
  LOCATION: `Firmenstandort`,
  LOCATION_DESCRIPTION: `Der Eingeladene wird aufgefordert, einen dieser Standorte auszuwählen.`,
  INVITEE_LOCATION: `Standort des Eingeladenen`,
  INVITEE_LOCATION_DESCRIPTION: `Der Eingeladene wird aufgefordert, eine Meeting-Adresse hinzuzufügen.`,
  DEFAULT_LOCATION: `Standard-Meeting-Option`,
  VIDEO_CONFERENCE: `Videokonferenz`,
  VIDEO_CONFERENCE_NOTIFICATION: `Ein oder mehrere Gastgeber haben keine Videokonferenz`,
  ORGANIZATION_LOCATIONS_TITLE: `Standorte der Organisation`,
  ORGANIZATION_LOCATIONS_DESCRIPTION: `Der Eingeladene wird aufgefordert, einen dieser Standorte auszuwählen`,
});

export const EditBookingTemplateQuestionsStep = Object.freeze({
  NAME_LABEL: `Vollständiger Name`,
  EMAIL_LABEL: `E-Mail`,
  ADD_QUESTION_BUTTON: `Neue Frage hinzufügen`,
  QUESTION_TITLE_NEW: `Neue Frage`,
  QUESTION_TITLE_EDIT: `Frage bearbeiten`,
});

export const EditBookingTemplateCustomField = Object.freeze({
  TITLE_NAME: `Name des Eingeladenen`,
  TITLE_EMAIL: `E-Mail des Eingeladenen`,
  TITLE_PHONE: `Telefonnummer des Eingeladenen`,
  TITLE_LOCATION: `Ort des Eingeladenen`,
  DESCRIPTION_NAME: `Dieses Pflichtfeld erfasst den Namen des Eingeladenen. Sie können die Bezeichnungen ändern, sollten sie jedoch klar halten, um Verwirrung zu vermeiden.`,
  DESCRIPTION_EMAIL: `Dieses Pflichtfeld erfasst die E-Mail-Adresse des Eingeladenen. Sie können die Bezeichnungen ändern, sollten sie jedoch klar halten, um Verwirrung zu vermeiden.`,
  DESCRIPTION_PHONE: `Dieses Pflichtfeld erfasst die Telefonnummer des Eingeladenen. Sie können die Bezeichnungen ändern, sollten sie jedoch klar halten, um Verwirrung zu vermeiden.`,
  DESCRIPTION_LOCATION: `Dieses Pflichtfeld erfasst den Standort des Eingeladenen. Sie können die Bezeichnungen ändern, sollten sie jedoch klar halten, um Verwirrung zu vermeiden.`,
  QUESTION_LABEL: `Frage`,
  QUESTION_NAME_LABEL: `Namensfeld-Bezeichnung`,
  QUESTION_EMAIL_LABEL: `E-Mail-Feld-Bezeichnung`,
  QUESTION_PHONE_LABEL: `Telefonnummer-Feld-Bezeichnung`,
  QUESTION_LOCATION_LABEL: `Ortsfeld-Bezeichnung`,
  FIELD_LABEL: `Feldbezeichnung`,
  REQUIRED_LABEL: `Erforderlich`,
  TYPE_LABEL: `Antworttyp`,
  TYPE_CHECKBOXES: `Kontrollkästchen`,
  TYPE_RADIO_BUTTONS: `Optionsfelder`,
  TYPE_SELECT: `Dropdown`,
  TYPE_TEL: `Telefonnummer`,
  TYPE_TEXT: `Einzeilig`,
  TYPE_TEXTAREA: `Mehrzeilig`,
  TYPE_CHECKBOXES_DESCRIPTION: `Mehrfachauswahl-Optionen`,
  TYPE_RADIO_BUTTONSS_DESCRIPTION: `Einzelauswahl-Option`,
  TYPE_SELECTS_DESCRIPTION: `Der Eingeladene kann eine Option aus einer Liste auswählen`,
  TYPE_TELS_DESCRIPTION: `Telefonnummerneingabe mit Vorwahl`,
  TYPE_TEXTS_DESCRIPTION: `Einfaches Kurzantwortfeld`,
  TYPE_TEXTAREAS_DESCRIPTION: `Großes Antwortfeld`,
  ANSWERS_LABEL: `Antworten`,
  ANSWER_PLACEHOLDER: `Antwort`,
});

export const EditBookingTemplateNotificationsStep = Object.freeze({
  CONFIRMATION_TITLE: `E-Mail-Bestätigungen`,
  CONFIRMATION_DESCRIPTION: `Ein Termin wird immer im Kalender des SUMO1-Gastgebers erstellt und der Eingeladene wird als Teilnehmer hinzugefügt.
Durch Aktivieren dieser Funktion sendet SUMO1 auch eine E-Mail-Bestätigung.`,
  RESCHEDULE_TITLE: `E-Mail-Neuplanung`,
  RESCHEDULE_DESCRIPTION: `Der Eingeladene erhält eine E-Mail-Benachrichtigung über Änderungen an seinem Treffen.`,
  REMINDER_TITLE: `E-Mail-Erinnerungen`,
  REMINDER_DESCRIPTION: `Der Eingeladene erhält eine Erinnerungs-E-Mail vor dem gebuchten Treffen.`,
  CANCELATION_TITLE: `E-Mail-Stornierungen`,
  CANCELATION_DESCRIPTION: `Wenn Sie das Treffen absagen, wird eine E-Mail-Benachrichtigung an Ihren Eingeladenen gesendet.`,
  FOLLOW_UP_TITLE: `E-Mail-Nachverfolgung`,
  FOLLOW_UP_DESCRIPTION: `Automatisieren Sie die Nachverfolgung der nächsten Schritte mit einer E-Mail, die nach dem Treffen gesendet wird.`,
  SMS_TITLE: `SMS-Text-Erinnerung`,
  SMS_DESCRIPTION: `Der Eingeladene hat die Möglichkeit, Text-Erinnerungen vor einem gebuchten Treffen zu erhalten.`,
  SMS_BODY: `SMS-Text`,
  SMS_LABEL_PART1: `Nur USA & Kanada`,
  SMS_LABEL_PART2: `Derzeit sind Text-Erinnerungen kostenlos und nur für Telefonnummern in den USA und Kanada verfügbar.`,
  PERSONALIZE: `Personalisieren`,
  EMAIL_SUBJECT: `E-Mail-Betreff`,
  EMAIL_BODY: `E-Mail-Text`,
  MERGE_VARIABLES: `Variablen zusammenführen`,
  TIMING: `Timing`,
  RESET: `Zurücksetzen`,
  ADD_ANOTHER_REMINDER: `Weitere Erinnerung hinzufügen`,
  CANCELATION_POLICY: `Stornierungsrichtlinie`,
  CANCELATION_POLICY_TOOLTIP: `Jeder hier eingegebene Text erscheint in allen E-Mail-Benachrichtigungen, die an den Eingeladenen gesendet werden.`,
  BEFORE_EVENT: `vor dem Ereignis`,
  AFTER_EVENT: `nach dem Ereignis`,
});

export const EditBookingTemplateConfirmationStep = Object.freeze({
  TYPE_LABEL: `Nach der Buchung`,
  TYPE_DISPLAY_SUMO: `SUMO Bestätigungsseite anzeigen`,
  TYPE_DISPLAY_EXTERNAL: `Weiterleitung zu einer externen Seite`,
  EXTERNAL_LINK_LABEL: `Externe Weiterleitungs-URL`,
  EXTERNAL_LINK_PLACEHOLDER: `https://weiterleitung-hier.com`,
  DISPLAY_BOOK_ANOTHER_BUTTON_LABEL: `Schaltfläche "Weitere Buchung" anzeigen`,
  ALLOW_RESCHEDULE_LABEL: `Neu planen erlauben`,
  ALLOW_CANCEL_LABEL: `Stornieren erlauben`,
  ADD_CUSTOM_LINK_LABEL: `Benutzerdefinierten Link hinzufügen`,
  ADD_CUSTOM_LINK_DESCRIPTION: `Fügen Sie der Bestätigungsseite einen benutzerdefinierten Link hinzu, wie z.B. einen Link zu einer Umfrage.`,
  ADD_CUSTOM_LINK_TITLE: `Benutzerdefinierten Link hinzufügen`,
  EDIT_CUSTOM_LINK_TITLE: `Benutzerdefinierten Link bearbeiten`,
  CUSTOM_LINK_LABEL: `Beschriftung des benutzerdefinierten Links`,
  CUSTOM_LINK_PLACEHOLDER: `Beispiel-Link`,
  LINK_URL_LABEL: `Link-URL`,
  LINK_URL_PLACEHOLDER: `https://beispiel-link.com`,
});
