export const Journey = Object.freeze({
  TITLE: `Reiseplaner`,
  CLONE_TITLE: `Geben Sie den Namen der geklonten Reise ein`,
  ADD_LABEL: `Reise erstellen`,
  CREATED_BY: `Erstellt von`,
  EMPTY_LIST_DESCRIPTION: `Erstellen Sie eine Reise oder passen Sie Ihre Suche oder Filter an.`,
  RESPONSE: `Antwort`,
  RESPONSES: `Antworten`,
  LAST: `Letzte`,
  DEACTIVATE_TITLE: `Reise deaktivieren`,
  DEACTIVATE_DESCRIPTION: `Sind Sie sicher, dass Sie deaktivieren möchten`,
  DELETE_TITLE: `Reise löschen`,
  DELETE_DESCRIPTION: `Sind Sie sicher, dass Sie löschen möchten`,
  SELECTED_JOURNEY: `ausgewählte Reisen`,
  NAME_LABEL: `Name`,
});

export const EditJourney = Object.freeze({
  JOURNEY_TITLE: `Reise`,
  RESPONSES_TITLE: `Antworten`,
  DELETE_STEP_TITLE: `Schritt löschen`,
  DELETE_STEP_DESCRIPTION: `Sind Sie sicher, dass Sie diesen Schritt löschen möchten?`,
  DELETE_STEP_ADDITIONAL_TEXT: `Bitte beachten Sie, dass diese Aktion auch alle nachfolgenden untergeordneten Schritte dauerhaft entfernt.`,
  START_NODE_TITLE: `Ihre Reise beginnt hier`,
  CONDITION_TITLE: `Bedingung`,
  DESTINATION_TITLE: `Ziel`,
  PAGE_TITLE: `Seite`,
  WITH_CARDS: `mit Karten`,
  WITH_FORM: `mit Formular`,
  IF_LABEL: `Wenn`,
  OR_LABEL: `Oder`,
  ADD_LABEL: `Hinzufügen`,
  CUSTOM_NAME: `Benutzerdefinierter Name`,
  CREATE_JOURNEY: `Reise erstellen`,
  EDIT_JOURNEY: `Reise bearbeiten`,
  CARD: `Karte`,
  LOGIC: `Logik`,
  PREVIEW_JOURNEY: `Reisevorschau`,
});

export const JourneysPageStep = Object.freeze({
  SELECTION_BOX: `Auswahlbox`,
  CARD_TITLE: `Titel hier*`,
  CARD_DETAILS: `Details hier*`,
  FORM_INPUT_TILE: `Eingabetitel`,
  FORM_INPUT_SUBTITLE: `Eingabeuntertitel`,
  FORM_WRITE_QUESTION: `Frage schreiben`,
  FORM_INPUT_REQUIRED: `Diese Eingabe ist erforderlich`,
  FORM_QUESTION_REQUIRED: `Diese Frage ist erforderlich`,
  FORM_ANSWER: `Antwort`,
  FORM_ADD_ANSWER: `+ Antwort hinzufügen`,
  TITLE: `Titel`,
  TITLE_PLACEHOLDER: `Seitentitel eingeben`,
  NAME: `Name`,
  PAGE_NAME: `Seitenname`,
  PAGE_TYPE: `Seitentyp`,
  NO_ELEMENTS: `Keine Elemente hinzugefügt`,
  INPUTS: `Eingaben`,
  SELECTION: `Auswahl`,
  SELECT_TYPE: `Zuerst Typ auswählen`,
});

export const JourneysDestinationStep = Object.freeze({
  TYPE_LABEL: `Zieltyp`,
  SHOWS_STEP_SEPARATLY: `Schritte separat anzeigen`,
  WHO_DESCRIPTION: `Liste der Spezialisten zur Auswahl`,
  WHEN_DESCRIPTION: `Kalender und Zeitplan`,
  WHERE_DESCRIPTION: `Karte mit auszuwählenden Büros`,
  HEADER_LABEL: `Kopfzeile`,
  MESSAGE_LABEL: `Nachricht`,
  URL_LABEL: `Externe URL`,
});

export const JourneyDefault = Object.freeze({
  JOURNEY_NAME: `Benutzerdefinierte Reise`,
  PAGE_NAME: `Benutzerdefiniert`,
  SUBMIT_LABEL: `Absenden`,
  FORM: `Formular`,
  CARDS: `Karten`,
  IMPORT: `Importieren`,
  TITLE: `Titel`,
  SUBTITLE: `Untertitel`,
  NAME: `Name`,
  SINGLE: `Einzeilige`,
  EMAIL: `E-Mail`,
  MULTIPLE: `Mehrzeilige`,
  PHONE: `Telefonnummer`,
  ATTACHMENT: `Anhang`,
  DROPDOWN: `Dropdown`,
  RADIO_BUTTONS: `Kontrollkästchen`,
  IS: `ist`,
  IS_NOT: `ist nicht`,
  CONTAINS: `enthält`,
  DOES_NOT_CONTAIN: `enthält nicht`,
  STARTS_WITH: `beginnt mit`,
  IS_NOT_EMPTY: `ist nicht leer`,
  IS_EMPTY: `ist leer`,
  RANGE: `ist Bereich`,
  FILETYPE_IS: `Dateityp ist`,
  FILETYPE_IS_NOT: `Dateityp ist nicht`,
  SIZE_IS_BIGGER_THAN: `Größe ist größer als`,
  SIZE_IS_SMALLER_THAN: `Größe ist kleiner als`,
  BOOKING_PAGE: `Buchungsseite`,
  MESSAGE: `Nachricht`,
  EXTERNAL_URL: `Externe URL`,
  CHAT_AI: `Chat KI`,
});

export const JourneysToastsNotifications = Object.freeze({
  GET_JOURNEYS_ERROR_MESSAGE: `Abrufen der Reisen fehlgeschlagen`,
  DEACTIVATE_JOURNEY_SUCCESS_MESSAGE: `Reise erfolgreich deaktiviert`,
  ACTIVATE_JOURNEY_SUCCESS_MESSAGE: `Reise erfolgreich aktiviert`,
  DEACTIVATE_JOURNEY_ERROR_MESSAGE: `Aktivieren/Deaktivieren der Reise fehlgeschlagen`,
  CLONE_JOURNEY_SUCCESS_MESSAGE: `Reise erfolgreich geklont`,
  CLONE_JOURNEY_ERROR_MESSAGE: `Klonen der Reise fehlgeschlagen`,
  DELETE_JOURNEYS_SUCCESS_MESSAGE: `Reisen erfolgreich gelöscht`,
  DELETE_JOURNEYS_ERROR_MESSAGE: `Löschen der Reisen fehlgeschlagen`,
  GET_JOURNEY_ERROR_MESSAGE: `Abrufen der Reise fehlgeschlagen`,
  SAVE_JOURNEY_ERROR_MESSAGE: `Speichern der Reise fehlgeschlagen`,
  CREATE_JOURNEY_SUCCESS_MESSAGE: `Reise erfolgreich erstellt`,
  UPDATE_JOURNEY_SUCCESS_MESSAGE: `Reise erfolgreich aktualisiert`,
  GET_JOURNEY_EVENTS_ERROR_MESSAGE: `Abruf der Reiseereignisse fehlgeschlagen`,
});

export const JourneysEvent = Object.freeze({
  VIEW_EVENT: `Ereignis anzeigen`,
  TITLE: `Antworten`,
  NO_RESPONSES: `Noch keine Antworten`,
  EMPTY_LIST_DESCRIPTION: `Passen Sie Ihre Suche oder Filter an.`,
  DATE_RANGE: `Datumsbereich`,
  RESPONSE: `Antwort`,
  EVENT_SCHEDULED: `Ereignis geplant`,
  CREATED_AT: `Erstellt am`,
  RESULT: `Ergebnis`,
  ANSWERS: `Antworten`,
  DESTINATION: `Ziel`,
});
