import React, { ReactNode, isValidElement, memo } from 'react';
import { useSelector } from 'react-redux';
import { quickSetupNavigationSelectors } from '../../../store/quickSetup';

interface QuickSetupScreenButtons {
  children: ReactNode;
}

interface QuickSetupScreenContainer {
  children: ReactNode;
  title?: string;
  titleIcon?: string;
}

const Buttons = memo(({ children }: QuickSetupScreenButtons) => <div className="flex gap-16px">{children}</div>);

const Container = memo(({ children, title, titleIcon }: QuickSetupScreenContainer) => {
  const currentStepLabel = useSelector(quickSetupNavigationSelectors.selectCurrentStepLabel);

  let buttons: ReactNode;
  const content: ReactNode[] = [];

  React.Children.forEach(children, (child) => {
    if (!isValidElement(child)) return;

    if (child.type === Buttons) {
      buttons = child;
    } else {
      content.push(child);
    }
  });

  return (
    <div className="flex flex-column pt-150px">
      <div className="flex gap-10px text-label-s-med text-heavy-80 pb-48px">
        <div className="flex align-items-center bg-heavy-1 border-radius-10px px-10px h-40px">{currentStepLabel}</div>
        <div className="flex align-items-center gap-8px bg-heavy-1 border-radius-10px px-10px h-40px">
          <div className="text-title-lg-med">{titleIcon}</div>
          <div>{title}</div>
        </div>
      </div>
      {content}
      {buttons && <div className="pt-60px">{buttons}</div>}
    </div>
  );
});

export const QuickSetupScreen = { Buttons, Container };

Buttons.displayName = 'QuickSetupScreen.Buttons';
Container.displayName = 'QuickSetupScreen.Container';
