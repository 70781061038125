import { Account, UpdateAccountInput } from '../../../API';
import { AccountWithTenants } from './types';

export enum AccountsActionTypes {
  GET_ACCOUNTS_REQUEST = 'accounts/GET_ACCOUNTS_REQUEST',
  GET_ACCOUNTS_SUCCESS = 'accounts/GET_ACCOUNTS_SUCCESS',
  GET_ACCOUNTS_FAIL = 'accounts/GET_ACCOUNTS_FAIL',
  GET_ACCOUNT_DETAILS_REQUEST = 'accounts/GET_ACCOUNT_DETAILS_REQUEST',
  GET_ACCOUNT_DETAILS_SUCCESS = 'accounts/GET_ACCOUNT_DETAILS_SUCCESS',
  GET_ACCOUNT_DETAILS_FAIL = 'accounts/GET_ACCOUNT_DETAILS_FAIL',
  SAVE_ACCOUNT_DETAILS_REQUEST = 'accounts/SAVE_ACCOUNT_DETAILS_REQUEST',
  SAVE_ACCOUNT_DETAILS_SUCCESS = 'accounts/SAVE_ACCOUNT_DETAILS_SUCCESS',
  SAVE_ACCOUNT_DETAILS_FAIL = 'accounts/SAVE_ACCOUNT_DETAILS_FAIL',
  DELETE_ACCOUNT_REQUEST = 'accounts/DELETE_ACCOUNT_REQUEST',
  DELETE_ACCOUNT_SUCCESS = 'accounts/DELETE_ACCOUNT_SUCCESS',
  DELETE_ACCOUNT_FAIL = 'accounts/DELETE_ACCOUNT_FAIL',
  SET_ACCOUNT = 'accounts/SET_ACCOUNT',
  UPDATE_ACCOUNT = 'accounts/UPDATE_ACCOUNT',
  SET_SEARCH_STRING = 'accounts/SET_SEARCH_STRING',
  SET_MERGE_SEARCH_STRING = 'accounts/SET_MERGE_SEARCH_STRING',
  SELECT_ACCOUNT = 'accounts/SELECT_ACCOUNT',
  SET_MERGE_ACCOUNT_IDS = 'accounts/SET_MERGE_ACCOUNT_IDS',
}

export type AccountsAction =
  | { type: AccountsActionTypes.GET_ACCOUNTS_REQUEST }
  | { type: AccountsActionTypes.GET_ACCOUNTS_SUCCESS; payload: Account[] }
  | { type: AccountsActionTypes.GET_ACCOUNTS_FAIL; error: unknown }
  | { type: AccountsActionTypes.GET_ACCOUNT_DETAILS_REQUEST }
  | { type: AccountsActionTypes.GET_ACCOUNT_DETAILS_SUCCESS; payload: AccountWithTenants }
  | { type: AccountsActionTypes.GET_ACCOUNT_DETAILS_FAIL; error: unknown }
  | { type: AccountsActionTypes.SAVE_ACCOUNT_DETAILS_REQUEST }
  | { type: AccountsActionTypes.SAVE_ACCOUNT_DETAILS_SUCCESS }
  | { type: AccountsActionTypes.SAVE_ACCOUNT_DETAILS_FAIL; error: unknown }
  | { type: AccountsActionTypes.DELETE_ACCOUNT_REQUEST }
  | { type: AccountsActionTypes.DELETE_ACCOUNT_SUCCESS }
  | { type: AccountsActionTypes.DELETE_ACCOUNT_FAIL; error: unknown }
  | { type: AccountsActionTypes.SET_ACCOUNT; payload: Partial<UpdateAccountInput> }
  | { type: AccountsActionTypes.UPDATE_ACCOUNT; payload: Partial<UpdateAccountInput> }
  | { type: AccountsActionTypes.SET_SEARCH_STRING; payload: string }
  | { type: AccountsActionTypes.SET_MERGE_SEARCH_STRING; payload: string }
  | { type: AccountsActionTypes.SELECT_ACCOUNT; payload: string }
  | { type: AccountsActionTypes.SET_MERGE_ACCOUNT_IDS; payload: string[] };

const getAccountsRequest = (): AccountsAction => ({ type: AccountsActionTypes.GET_ACCOUNTS_REQUEST });
const getAccountsSuccess = (payload: Account[]): AccountsAction => ({
  type: AccountsActionTypes.GET_ACCOUNTS_SUCCESS,
  payload,
});
const getAccountsFail = (error: unknown): AccountsAction => ({ type: AccountsActionTypes.GET_ACCOUNTS_FAIL, error });

const getAccountDetailsRequest = (): AccountsAction => ({ type: AccountsActionTypes.GET_ACCOUNT_DETAILS_REQUEST });
const getAccountDetailsSuccess = (payload: AccountWithTenants): AccountsAction => ({
  type: AccountsActionTypes.GET_ACCOUNT_DETAILS_SUCCESS,
  payload,
});
const getAccountDetailsFail = (error: unknown): AccountsAction => ({
  type: AccountsActionTypes.GET_ACCOUNT_DETAILS_FAIL,
  error,
});

const saveAccountDetailsRequest = (): AccountsAction => ({
  type: AccountsActionTypes.SAVE_ACCOUNT_DETAILS_REQUEST,
});
const saveAccountDetailsSuccess = (): AccountsAction => ({
  type: AccountsActionTypes.SAVE_ACCOUNT_DETAILS_SUCCESS,
});
const saveAccountDetailsFail = (error: unknown): AccountsAction => ({
  type: AccountsActionTypes.SAVE_ACCOUNT_DETAILS_FAIL,
  error,
});

const deleteAccountRequest = (): AccountsAction => ({
  type: AccountsActionTypes.DELETE_ACCOUNT_REQUEST,
});
const deleteAccountSuccess = (): AccountsAction => ({
  type: AccountsActionTypes.DELETE_ACCOUNT_SUCCESS,
});
const deleteAccountFail = (error: unknown): AccountsAction => ({
  type: AccountsActionTypes.DELETE_ACCOUNT_FAIL,
  error,
});

const setAccount = (payload: Partial<UpdateAccountInput>): AccountsAction => ({
  type: AccountsActionTypes.SET_ACCOUNT,
  payload,
});
const updateAccount = (payload: Partial<UpdateAccountInput>): AccountsAction => ({
  type: AccountsActionTypes.UPDATE_ACCOUNT,
  payload,
});

const setSearchString = (payload: string): AccountsAction => ({
  type: AccountsActionTypes.SET_SEARCH_STRING,
  payload,
});

const setMergeSearchString = (payload: string): AccountsAction => ({
  type: AccountsActionTypes.SET_MERGE_SEARCH_STRING,
  payload,
});

const selectAccount = (payload: string): AccountsAction => ({
  type: AccountsActionTypes.SELECT_ACCOUNT,
  payload,
});

const setMergeIds = (payload: string[]): AccountsAction => ({
  type: AccountsActionTypes.SET_MERGE_ACCOUNT_IDS,
  payload,
});

export const accountsActions = {
  getAccountsRequest,
  getAccountsSuccess,
  getAccountsFail,
  getAccountDetailsRequest,
  getAccountDetailsSuccess,
  getAccountDetailsFail,
  saveAccountDetailsRequest,
  saveAccountDetailsSuccess,
  saveAccountDetailsFail,
  deleteAccountRequest,
  deleteAccountSuccess,
  deleteAccountFail,
  setAccount,
  updateAccount,
  setSearchString,
  setMergeSearchString,
  selectAccount,
  setMergeIds,
};
