import { useSelector } from 'react-redux';
import { Skeleton } from 'primereact/skeleton';
import { userSettingsSelectors } from '../../../store/userSettings';
import labels from './labels';
import { BellAlertIcon, CalendarIcon, Squares2X2Icon, XMarkIcon } from '@heroicons/react/24/outline';
import { SectionHeader } from '../../common';

export const BookingStats = () => {
  const isSpinnerFetching = useSelector(userSettingsSelectors.selectIsSpinnerFetching);
  const isSkeletonFetching = useSelector(userSettingsSelectors.selectIsSkeletonFetching);
  const eventStatistics = useSelector(userSettingsSelectors.selectEventsStatistics);

  const statsClassName = 'flex-center w-40px h-60px border-radius-8px';
  const generateStatsIcon = (index: number) => {
    switch (index) {
      case 0:
        return <Squares2X2Icon width={20} />;
      case 1:
        return <BellAlertIcon width={20} />;
      case 2:
        return <CalendarIcon width={20} />;
      case 3:
        return <XMarkIcon width={20} />;
      default:
        return <></>;
    }
  };
  const generateStatsClassName = (index: number) => {
    switch (index) {
      case 0:
        return statsClassName + ' bg-blue-light text-blue-dark';
      case 1:
        return statsClassName + ' bg-egg-blue-light text-egg-blue-dark';
      case 2:
        return statsClassName + ' bg-saffron-light text-saffron-dark';
      case 3:
        return statsClassName + ' bg-tomato-light text-tomato-dark';
      default:
        return '';
    }
  };

  return (
    <>
      <div className="sumo-card-bg flex flex-column gap-16px pt-16px mb-28px">
        <SectionHeader loading={isSpinnerFetching} title={labels.title} hideButton hideBottomMargin />

        <div className="sumo-card flex justify-content-between gap-10px p-10px">
          {eventStatistics.map((item, index) => (
            <div className="flex align-items-center gap-10px" key={`statItems_${index}`}>
              <div className={generateStatsClassName(index)}>{generateStatsIcon(index)}</div>
              <div className="flex flex-column gap-6px">
                <div className="text-label-s-med text-heavy-60">{labels.statItemsLabels[index]}</div>
                {isSkeletonFetching ? (
                  <Skeleton height="32px" />
                ) : (
                  <div className="flex align-items-end gap-2px">
                    <div className="text-display-md-reg">{item.value}</div>
                    {item.percent !== undefined && (
                      <div className="pb-4px text-heavy-60 text-label-input-reg">{item.percent + '%'}</div>
                    )}
                  </div>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};
