export const OPSConsoleOrgs = Object.freeze({
  ACCOUNT_TITLE: `Compte`,
  ACTIVE: `Actif`,
  ADDRESS_LINE_1: `Ligne d'adresse 1`,
  ADDRESS_LINE_2: `Ligne d'adresse 2`,
  ADOPTION_PERCENT: `% Adoption`,
  AMOUNT_TITLE: `ARR`,
  BILLING_EMAIL: `E-mail de facturation`,
  BOOKED_NUMBER: `# Réservés`,
  CUSTOMER: `Client`,
  DAYS_LEFT_TITLE: `Jours Restants`,
  EXPIRED: `Expiré`,
  EXPIRE_TRIAL: `Expire Essai`,
  EXPORT: `Exporter`,
  EXTEND_TRIAL: `Prolonger Essai`,
  FILTER_ALL_ORGS: `Toutes les Orgs`,
  FILTER_EXPIRED_SOON: `Expirant Bientôt`,
  FILTER_LOW_ADOPT: `Faible Adoption`,
  LICENSES_NUMBER: `# Licences`,
  LAST_ACTIVE_TITLE: `Dernière Activité`,
  ORG_ID_TITLE: `ID Org`,
  PAYMENT_LATE: `Paiement En Retard`,
  SEARCH_PLACEHOLDER: `Rechercher par nom de compte, e-mail utilisateur ou ID Org`,
  STATUS_TITLE: `Statut`,
  SUSPENDED: `Suspendu`,
  SUBSCRIPTION_ID: "ID d'abonnement",
  TERM_TITLE: `Terme`,
  TERM_ALL: `Tous`,
  TERM_ANNUAL: `Annuel`,
  TERM_TRIAL: `Essais`,
  TERM_MONTHLY: `Mensuel`,
  TEST: `Test`,
  TITLE: `Orgs`,
  TRIAL: `Essai`,
  TYPE_TITLE: `Type`,
  VIEW_ORG: `Voir Org`,
});

export const OrgsToastsNotifications = Object.freeze({
  CREATE_PAY_LATER_LICENSE_SUCCESS_TOAST: `Abonnement créé avec succès`,
  CREATE_PAY_LATER_LICENSE_SUCCESS_MESSAGE: `Veuillez patienter jusqu'à 1 minute`,
  GET_ORGS_FAIL_MESSAGE: `Échec de la requête de récupération des organisations`,
  GET_ORG_DETAILS_FAIL_MESSAGE: `Échec de la requête de récupération des détails de l'organisation`,
  SAVE_ORG_DETAILS_SUCCESS_MESSAGE: `Enregistrement des détails de l'organisation avec succès`,
  SAVE_ORG_DETAILS_FAIL_MESSAGE: `Échec de la requête d'enregistrement des détails de l'organisation`,
  DELETE_ORG_SUCCESS_MESSAGE: `Organisation supprimée avec succès`,
  DELETE_ORG_FAIL_MESSAGE: `Échec de la suppression de l'organisation`,
  EXTEND_LICENSE_SUCCESS_MESSAGE: `Prolongation de la licence avec succès`,
  EXTEND_LICENSE_FAIL_MESSAGE: `Échec de la requête de prolongation de la licence`,
  EXPIRE_TRIAL_SUCCESS_MESSAGE: `Expiration de l'essai avec succès`,
  EXPIRE_TRIAL_FAIL_MESSAGE: `Échec de la requête d'expiration de l'essai`,
  CONVERT_TO_TEST_SUCCESS_MESSAGE: `Conversion en mode test avec succès`,
  CONVERT_TO_TEST_FAIL_MESSAGE: `Échec de la requête de conversion en mode test`,
  CONVERT_TO_PAY_LATER_SUCCESS_MESSAGE: `L'abonnement client Pay Later a été créé avec succès`,
  CONVERT_TO_PAY_LATER_FAIL_MESSAGE: `La demande d'abonnement Customer Pay Later a échoué`,
});

export const OPSConsoleOrgDetails = Object.freeze({
  ACCOUNT_NAME: `Nom du Compte`,
  ADOPTION_PERCENT_WEEKLY: `% Adoption hebdomadaire`,
  ADOPTION_PERCENT_MONTHLY: `% Adoption mensuelle`,
  ACTIVE_USERS_NUMBER: `# d'utilisateurs assignés`,
  AMOUNT_PER_USER: `Par Utilisateur / Par Mois`,
  BOOKED_NUMBER: `# de Réunions Réservées`,
  CONVERT_TO_CUSTOMER: `Convertir en Client`,
  CONVERT_TO_TEST: `Convertir en Test`,
  DAYS_REMAINING: `Jours Restants`,
  EDIT_ORG: `Modifier Org`,
  EXPIRATION_DATE: `Date d'Expiration`,
  FEATURE_PRICING_TIER: `Fonction/Palier Tarifaire`,
  FULL_ACCESS: `Accès Complet`,
  LICENSES_NUMBER: `# de Licences`,
  ORG_ID: `ID Org`,
  ORG_NOTE: `Note de l'Org`,
  ORG_STATUS: `Statut de l'Org`,
  ORG_TITLE: `Org`,
  ORG_TYPE: `Type d'Org`,
  PARTIAL_ACCESS: `Accès Partiel`,
  PAYMENT_TERM: `Terme de Paiement`,
  PAYMENT_TITLE: `Paiement`,
  PAYMENT_TYPE: `Type de Paiement`,
  START_DATE: `Date de Début`,
  TERM: `Terme`,
  TERMS_TITLE: `Termes`,
  TOTAL_AMOUNT: `ARR`,
  USER_ADOPTION: `Adoption par l'Utilisateur`,
});

export const OrgDetailsLicenses = Object.freeze({
  CREATE_LICENSE: `Créer une licence`,
  LICENSES_NUMBER: `Nombre de licences`,
  PURCHASED_DATE: `Date d'achat`,
  PURCHASED_NAME: `Nom de l'acheteur`,
  TITLE: `Licences`,
});

export const OrgDetailsUsers = Object.freeze({
  TERM_TRIAL: `Essai`,
  VIEW: `Voir`,
  VIEW_ALL_PART1: `Voir tous les`,
  VIEW_ALL_PART2: `enregistrements`,
  VIEW_LESS: `Voir moins`,
});

export const OrgExtendTrialModal = Object.freeze({
  EXTEND_DESCRIPTION: `Prolonger le nombre de jours`,
  EXTEND_MESSAGE: `Impossible de prolonger davantage. L'essai a déjà été prolongé à 30 jours.`,
  EXTEND_TITLE: `Prolonger`,
  SAVE_AND_EXTEND: `Enregistrer et prolonger`,
  RULES_DESCRIPTION_PART1: `Les essais gratuits durent 15 jours.`,
  RULES_DESCRIPTION_PART2: `Le personnel de support peut prolonger de 15 jours supplémentaires.`,
  RULES_DESCRIPTION_PART3: `Une fois expiré, le client peut toujours se connecter, mais ne verra que la page de facturation.`,
  RULES_DESCRIPTION_PART4: `Encouragez-les à acheter simplement 1 licence sur une base mensuelle.`,
  RULES_TITLE: `Règles`,
  TITLE: `Prolonger l'essai`,
});

export const OrgExpireTrialModal = Object.freeze({
  EXPIRE_DESCRIPTION: `Oui, je souhaite expirer cet essai de force.`,
  EXPIRE_NOW: `Expire maintenant`,
  EXPIRE_TITLE: `Expire`,
  RULES_DESCRIPTION_PART1: `Si vous remarquez qu'un concurrent ou quelqu'un utilise un essai qui ne devrait pas l'être, vous pouvez forcer l'expiration de l'essai.`,
  RULES_DESCRIPTION_PART2: `Ils recevront une erreur lors de la connexion, leur demandant de contacter les ventes ou le support.`,
  RULES_TITLE: `Règles`,
  TITLE: `Expire l'essai`,
});

export const OrgConvertTrialModal = Object.freeze({
  AMOUNT_PER_USER: `Par utilisateur / Par mois`,
  ANNUAL_CONTRACT: `Contrat annuel`,
  BILLING_TITLE: `Facturation - Facture Année 1`,
  CONTRACT_END_DATE: `Date de fin du contrat`,
  CONTRACT_START_DATE: `Date de début du contrat`,
  CONVERT_TO_CUSTOMER: `Convertir en client payant`,
  IMMEDIATE_PROCESS_DESCRIPTION: `Encouragez les clients à payer par carte de crédit dans l'application en cliquant sur "Acheter des licences" dans la liste des utilisateurs, ce qui les convertira automatiquement.`,
  IMMEDIATE_PROCESS_TITLE: `Processus immédiat`,
  INVOICE_DUE_DATE: `Date d'échéance de la facture`,
  MANUAL_PROCESS_DESCRIPTION_PART1: `Seuls les opérations peuvent effectuer cette fonction.`,
  MANUAL_PROCESS_DESCRIPTION_PART2: `Pour >30 licences, terme annuel, les clients peuvent payer par ACH, virement ou chèque.`,
  MANUAL_PROCESS_DESCRIPTION_PART3: `Les clients doivent avoir signé un contrat (et le PO est facultatif).`,
  MANUAL_PROCESS_TITLE: `Processus manuel`,
  NET_TERMS: `Conditions nettes`,
  RULES_TITLE: `Règles`,
  SALESFORCE_OPPORTUNITY: `Opportunité Salesforce`,
  SALESFORCE_OPPORTUNITY_PLACEHOLDER: `Entrer le lien hypertexte ici`,
  TITLE: `Convertir l'essai en client payant`,
  TOTAL_AMOUNT: `Montant total annuel`,
  USER_LICENSES_NUMBER: `Nombre de licences utilisateur`,
  WARNING_MESSAGE: `Cette action ne peut pas être annulée.`,
});

export const OrgDeleteModal = Object.freeze({
  TITLE: `Supprimer l'organisation`,
  DESCRIPTION: `Cette organisation sera supprimée avec toutes les données`,
});
