import { useSelector } from 'react-redux';
import { DEFAULT_SMART_ALERT_TEMPLATE, smartAlertsActions, smartAlertsSelectors } from '../../../store/smartAlerts';
import { ViewColumnsIcon } from '@heroicons/react/24/outline';
import { SmartAlertCard } from '../smartAlertCard/SmartAlertCard';
import { useDispatch } from 'react-redux';
import { SmartAlertTemplate } from '../../../API';
import labels from './labels';
import { userSettingsSelectors } from '../../../store/userSettings';

type SmartAlertPreSelectProps = {
  handleSelect: () => void;
};

export const SmartAlertPreSelect = ({ handleSelect }: SmartAlertPreSelectProps) => {
  const dispatch = useDispatch();
  const templates = useSelector(smartAlertsSelectors.selectSmartAlertTemplates);
  const isReadTemplate = useSelector(userSettingsSelectors.selectSmartAlertTemplatesRead);

  const handleUseTemplate = (template: SmartAlertTemplate) => {
    dispatch(
      smartAlertsActions.updateSmartAlert({
        name: template.name,
        icon: template.icon,
        isHide: template.isHide,
        shareWith: template.shareWith,
        whenSend: template.whenSend,
        whomSend: template.whomSend,
      })
    );
    handleSelect();
  };

  return (
    <div className="flex flex-column gap-24px">
      <div className="text-body-lg-reg text-heavy-60 pt-8px">{labels.description}</div>
      <div className="w-12 lg:w-6">
        <div
          className="sumo-card sumo-create-card action-card flex-center h-170px"
          onClick={() => handleUseTemplate(DEFAULT_SMART_ALERT_TEMPLATE)}
        >
          {labels.createCustomTitle}
        </div>
      </div>
      {!!templates.length && isReadTemplate && (
        <div className="grid -mb-2">
          <div className="col-12 flex align-items-center gap-6px text-label-s-med text-heavy-60">
            <ViewColumnsIcon width={20} height={20} />
            <span>{labels.templatesLabel}</span>
          </div>
          {templates.map((template) => (
            <div className="col-12 lg:col-6" key={template.id}>
              <SmartAlertCard template={template} useTemplate={() => handleUseTemplate(template)} />
            </div>
          ))}
        </div>
      )}
    </div>
  );
};
