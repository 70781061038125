import { useDispatch, useSelector } from 'react-redux';
import { ConfirmationModal, Modal } from '../../common';
import labels from './labels';
import {
  GROUP_CLONE_MODAL,
  groupBookingPagesActions,
  groupBookingPagesSelectors,
  groupCloneModalActions,
  groupDeactivateModalActions,
  groupDeactivateModalSelectors,
  groupDeleteModalActions,
  groupDeleteModalSelectors,
} from '../../../store/groupBookingPages';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { MAX_LENGTH_NAME } from '../../../types/constants';

export const GroupBookingPageModals = () => {
  const dispatch = useDispatch();
  const groupBookingPage = useSelector(groupBookingPagesSelectors.selectGroupBookingPage);
  const deactivateIsOpen = useSelector(groupDeactivateModalSelectors.selectIsModalOpen);
  const deleteIsOpen = useSelector(groupDeleteModalSelectors.selectIsModalOpen);
  const cloneName = useSelector(groupBookingPagesSelectors.selectCloneName);
  const isCloneNameValid = useSelector(groupBookingPagesSelectors.selectIsCloneNameValid);

  const deactivateModalClose = () => {
    dispatch(groupDeactivateModalActions.closeModal());
  };
  const deactivateModalConfirm = () => {
    dispatch(groupBookingPagesActions.activateGroupBookingPageRequest());
    deactivateModalClose();
  };

  const deleteModalClose = () => {
    dispatch(groupDeleteModalActions.closeModal());
  };
  const deleteModalConfirm = () => {
    dispatch(groupBookingPagesActions.deleteGroupBookingPageRequest([groupBookingPage.id]));
    deleteModalClose();
  };

  const handleNameChange = (name: string) => {
    dispatch(groupBookingPagesActions.setCloneName(name));
  };
  const cloneModalClose = () => {
    dispatch(groupCloneModalActions.closeModal());
  };
  const cloneModalConfirm = () => {
    dispatch(groupBookingPagesActions.cloneGroupBookingPageRequest());
    cloneModalClose();
  };

  return (
    <>
      <ConfirmationModal
        title={labels.deactivateTitle}
        description={`${labels.deactivateDescription} "${groupBookingPage.name}"?`}
        visible={deactivateIsOpen}
        onClose={deactivateModalClose}
        onCancel={deactivateModalClose}
        onConfirm={deactivateModalConfirm}
        cancelButtonLabel={labels.no}
        confirmButtonLabel={labels.yes}
      />
      <ConfirmationModal
        title={labels.deleteTitle}
        description={`${labels.deleteDescription} "${groupBookingPage.name}"?`}
        visible={deleteIsOpen}
        onClose={deleteModalClose}
        onCancel={deleteModalClose}
        onConfirm={deleteModalConfirm}
        cancelButtonLabel={labels.no}
        confirmButtonLabel={labels.yes}
      />
      <Modal.Container name={GROUP_CLONE_MODAL} closable={true} onClose={cloneModalClose} className="w-440px">
        <Modal.Header>{labels.cloneTitle}</Modal.Header>
        <div className="flex flex-column gap-16px">
          <InputText
            type="text"
            placeholder={labels.cloneNamePlaceholder}
            value={cloneName}
            onChange={(e) => handleNameChange(e.target.value.trimStart())}
            className={(!isCloneNameValid && 'p-invalid') || ''}
            maxLength={MAX_LENGTH_NAME}
          />
          {groupBookingPage.adminOnly && <div className="text-body-lg-reg">{labels.cloneNotification}</div>}
        </div>
        <Modal.Buttons>
          <Button
            label={labels.confirm}
            onClick={cloneModalConfirm}
            disabled={!isCloneNameValid}
            autoFocus
            className="min-w-120px"
          />
          <Button label={labels.cancel} onClick={cloneModalClose} text />
        </Modal.Buttons>
      </Modal.Container>
    </>
  );
};
