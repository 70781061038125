import { CreateUserEventInput } from '../../API';
import { handleAPIRequest } from '../utils/reduxUtils';
import { API_PUBLIC } from '../../types/constants';
import { GetBookedMeetingsResponse, UpdateInternalFieldsUserEventRequest } from './types';

// ***
// When you change the requests do not forget to specify new data
// in docs/Endpoints.md
// ***

export const fetchEvents = async (): Promise<CreateUserEventInput[]> => {
  //TODO add startTime and endTime to request body
  const response = await handleAPIRequest<GetBookedMeetingsResponse>(`${API_PUBLIC}/getBookedMeetings`, {});
  return response.events;
};

export const updateInternalFieldsMeeting = async (input: UpdateInternalFieldsUserEventRequest): Promise<void> => {
  return handleAPIRequest<void>(`${API_PUBLIC}/updateInternalFieldsUserEvent`, { event: input });
};
