export const OPSConsoleOrgs = Object.freeze({
  ACCOUNT_TITLE: `Account`,
  ACTIVE: `Active`,
  ADDRESS_LINE_1: `Address Line 1`,
  ADDRESS_LINE_2: `Address Line 2`,
  ADOPTION_PERCENT: `% Adopt`,
  AMOUNT_TITLE: `ARR`,
  BILLING_EMAIL: `Billing Email`,
  BOOKED_NUMBER: `# Booked`,
  CUSTOMER: `Customer`,
  DAYS_LEFT_TITLE: `Days Left`,
  EXPIRED: `Expired`,
  EXPIRE_TRIAL: `Expire Trial`,
  EXPORT: `Export`,
  EXTEND_TRIAL: `Extend Trial`,
  FILTER_ALL_ORGS: `All Orgs`,
  FILTER_EXPIRED_SOON: `Expire Soon`,
  FILTER_LOW_ADOPT: `Low Adopt`,
  LICENSES_NUMBER: `# Licenses`,
  LAST_ACTIVE_TITLE: `Last Active`,
  ORG_ID_TITLE: `Org ID`,
  PAYMENT_LATE: `Payment Late`,
  SEARCH_PLACEHOLDER: `Search by Account Name, User Email, or Org ID`,
  STATUS_TITLE: `Status`,
  SUSPENDED: `Suspended`,
  SUBSCRIPTION_ID: 'Subscription ID',
  TERM_TITLE: `Term`,
  TERM_ALL: `All`,
  TERM_ANNUAL: `Annual`,
  TERM_TRIAL: `Trials`,
  TERM_MONTHLY: `Monthly`,
  TEST: `Test`,
  TITLE: `Orgs`,
  TRIAL: `Trial`,
  TYPE_TITLE: `Type`,
  VIEW_ORG: `View Org`,
});

export const OrgsToastsNotifications = Object.freeze({
  CREATE_PAY_LATER_LICENSE_SUCCESS_TOAST: `Subscription created successfully`,
  CREATE_PAY_LATER_LICENSE_SUCCESS_MESSAGE: `Please wait up to 1 minute`,
  GET_ORGS_FAIL_MESSAGE: `Get orgs request fail`,
  GET_ORG_DETAILS_FAIL_MESSAGE: `Get org details request fail`,
  SAVE_ORG_DETAILS_SUCCESS_MESSAGE: `Org details successfully saved`,
  SAVE_ORG_DETAILS_FAIL_MESSAGE: `Save org details request fail`,
  DELETE_ORG_SUCCESS_MESSAGE: `Org details successfully deleted`,
  DELETE_ORG_FAIL_MESSAGE: `Failed to delete org`,
  EXTEND_LICENSE_SUCCESS_MESSAGE: `License successfully extended`,
  EXTEND_LICENSE_FAIL_MESSAGE: `Extend License request fail`,
  EXPIRE_TRIAL_SUCCESS_MESSAGE: `Trial license successfully expired`,
  EXPIRE_TRIAL_FAIL_MESSAGE: `Expire trial request fail`,
  CONVERT_TO_TEST_SUCCESS_MESSAGE: `License successfully converted to Test type`,
  CONVERT_TO_TEST_FAIL_MESSAGE: `Convert to test request fail`,
  CONVERT_TO_PAY_LATER_SUCCESS_MESSAGE: `Customer Pay Later subscription succesfully created`,
  CONVERT_TO_PAY_LATER_FAIL_MESSAGE: `Customer Pay Later subscription request failed`,
});

export const OPSConsoleOrgDetails = Object.freeze({
  ACCOUNT_NAME: `Account Name`,
  ADOPTION_PERCENT_WEEKLY: `% Weekly Adoption`,
  ADOPTION_PERCENT_MONTHLY: `% Monthly Adoption`,
  ACTIVE_USERS_NUMBER: `# of Assigned Users`,
  AMOUNT_PER_USER: `Per User / Per Month`,
  BOOKED_NUMBER: `# of Booked Meetings`,
  CONVERT_TO_CUSTOMER: `Convert To Customer`,
  CONVERT_TO_TEST: `Convert To Test`,
  DAYS_REMAINING: `Days Remaining`,
  EDIT_ORG: `Edit Org`,
  EXPIRATION_DATE: `Expiration Date`,
  FEATURE_PRICING_TIER: `Feature/Pricing Tier`,
  FULL_ACCESS: `Full Access`,
  LICENSES_NUMBER: `# of Licenses`,
  ORG_ID: `Org ID`,
  ORG_NOTE: `Org Note`,
  ORG_STATUS: `Org Status`,
  ORG_TITLE: `Org`,
  ORG_TYPE: `Org Type`,
  PARTIAL_ACCESS: `Partial Access`,
  PAYMENT_TERM: `Payment Term`,
  PAYMENT_TITLE: `Payment`,
  PAYMENT_TYPE: `Payment Type`,
  START_DATE: `Start Date`,
  TERM: `Term`,
  TERMS_TITLE: `Terms`,
  TOTAL_AMOUNT: `ARR`,
  USER_ADOPTION: `User Adoption`,
});

export const OrgDetailsLicenses = Object.freeze({
  CREATE_LICENSE: `Create License`,
  LICENSES_NUMBER: `# of Licenses`,
  PURCHASED_DATE: `Purchase Date`,
  PURCHASED_NAME: `Purchaser Name`,
  TITLE: `Licenses`,
});

export const OrgDetailsUsers = Object.freeze({
  TERM_TRIAL: `Trial`,
  VIEW: `View`,
  VIEW_ALL_PART1: `View All`,
  VIEW_ALL_PART2: `Records`,
  VIEW_LESS: `View Less`,
});

export const OrgExtendTrialModal = Object.freeze({
  EXTEND_DESCRIPTION: `Extend # of Days`,
  EXTEND_MESSAGE: `Can not extend further. Trial already extended to 30 days.`,
  EXTEND_TITLE: `Extend`,
  SAVE_AND_EXTEND: `Save & Extend`,
  RULES_DESCRIPTION_PART1: `Free trials are for 15 days.`,
  RULES_DESCRIPTION_PART2: `Support staff may extend for an additional 15 days.`,
  RULES_DESCRIPTION_PART3: `Once expired, customer may still login, but will only see the Billing Page.`,
  RULES_DESCRIPTION_PART4: `Encourage them to just purchase 1 license on month-to-month basis.`,
  RULES_TITLE: `Rules`,
  TITLE: `Extend Trial`,
});

export const OrgExpireTrialModal = Object.freeze({
  EXPIRE_DESCRIPTION: `Yes, I wish to force expire this trial.`,
  EXPIRE_NOW: `Expire Now`,
  EXPIRE_TITLE: `Expire`,
  RULES_DESCRIPTION_PART1: `If you notice a competitor or someone is running a trial that should not be, you can force expire the trial.`,
  RULES_DESCRIPTION_PART2: `They will get an error upon logging in, being told to contact sales or support.`,
  RULES_TITLE: `Rules`,
  TITLE: `Expire Trial`,
});

export const OrgConvertTrialModal = Object.freeze({
  AMOUNT_PER_USER: `Per User / Per Month`,
  ANNUAL_CONTRACT: `Annual Contract`,
  BILLING_TITLE: `Billing - Year 1 Invoice`,
  CONTRACT_END_DATE: `Contract End Date`,
  CONTRACT_START_DATE: `Contract Start Date`,
  CONVERT_TO_CUSTOMER: `Convert to Customer`,
  IMMEDIATE_PROCESS_DESCRIPTION: `Encourage customers to pay by credit card in app by clicking "Buy Licenses" in the user list, which will auto-convert them.`,
  IMMEDIATE_PROCESS_TITLE: `Immediate Process`,
  INVOICE_DUE_DATE: `Invoice Due Date`,
  MANUAL_PROCESS_DESCRIPTION_PART1: `Only operations may perform this function.`,
  MANUAL_PROCESS_DESCRIPTION_PART2: `For >30 licenses, annual term, customers may pay via ACH, Wire, or Check.`,
  MANUAL_PROCESS_DESCRIPTION_PART3: `Customers must have signed contract (and PO is optional).`,
  MANUAL_PROCESS_TITLE: `Manual Process`,
  NET_TERMS: `NET Terms`,
  RULES_TITLE: `Rules`,
  SALESFORCE_OPPORTUNITY: `Salesforce Opportunity`,
  SALESFORCE_OPPORTUNITY_PLACEHOLDER: `Input hyperlink here`,
  TITLE: `Convert Trial to Paying Customer`,
  TOTAL_AMOUNT: `Total Annual Amount`,
  USER_LICENSES_NUMBER: `# of User Licenses`,
  WARNING_MESSAGE: `This action cannot be undone.`,
});

export const OrgDeleteModal = Object.freeze({
  TITLE: `Delete Org`,
  DESCRIPTION: `This org will be deleted with all data`,
});
