export const GroupBookingPage = Object.freeze({
  GROUP_TITLE: `Gruppe`,
  GROUPS_TITLE: `Gruppen`,
  GROUP_PAGE: `Gruppenseite`,
  SELECT_MEETING_TYPE: `Wählen Sie einen Meeting-Typ aus`,
});

export const GroupBookingPagesToastsNotifications = Object.freeze({
  CLONE_GROUP_PAGES_ERROR_MESSAGE: `Anfrage zum Klonen der Gruppenseite fehlgeschlagen`,
  CLONE_GROUP_PAGES_SUCCESS_MESSAGE: `Gruppenseite erfolgreich geklont`,
  DELETE_GROUP_PAGES_ERROR_MESSAGE: `Anfrage zum Löschen der Gruppenseite fehlgeschlagen`,
  DELETE_GROUP_PAGES_SUCCESS_MESSAGE: `Gruppenseite erfolgreich gelöscht`,
  GET_GROUP_PAGE_ERROR_MESSAGE: `Gruppenseite konnte nicht abgerufen werden, möglicherweise wurde sie gelöscht`,
  GET_GROUP_PAGES_ERROR_MESSAGE: `Anfrage zum Abrufen der Gruppenseiten fehlgeschlagen`,
  SAVE_GROUP_PAGE_ERROR_MESSAGE: `Anfrage zum Speichern der Gruppenseite fehlgeschlagen`,
  SAVE_GROUP_PAGE_SUCCESS_MESSAGE: `Gruppenseite erfolgreich gespeichert`,
  ACTIVATE_GROUP_PAGE_SUCCESS_MESSAGE: `Gruppenseite erfolgreich aktiviert`,
  DEACTIVATE_GROUP_PAGE_SUCCESS_MESSAGE: `Gruppenseite erfolgreich deaktiviert`,
});

export const EditGroupBookingPage = Object.freeze({
  ADD_DESCRIPTION: `Beschreibung hinzufügen`,
  ADMIN_ONLY_TITLE: `Nur Admin`,
  BASIC_SETTING_INFO_MESSAGE: `Beachten Sie, dass diese Gruppenseite auf den ausgewählten Buchungsseiten basiert und Änderungen an diesen Quellbuchungsseiten in diesem Gruppenbuchungslink widergespiegelt werden.`,
  BASIC_SETTINGS_DESCRIPTION: `Buchungsseiten und Gruppenname`,
  BASIC_SETTINGS_TITLE: `Grundeinstellungen`,
  BOOKING_PAGES_DESCRIPTION: `Sie können Buchungsseiten auswählen`,
  BOOKING_PAGES_TITLE: `Buchungsseiten`,
  BRANDING_DESCRIPTION: `Beschreibung, Branding-Stile und Anzeigen`,
  BRANDING_TITLE: `Wie soll es aussehen?`,
  BRANDING_WARNING: `Warnung: Sie haben unterschiedliche Branding-Stile für Buchungsseiten.`,
  CLONE_TITLE: `Name der geklonten Gruppenseite`,
  DEACTIVATE_DESCRIPTION: `Möchten Sie diese Gruppenseite wirklich deaktivieren?`,
  DEACTIVATE_TITLE: `Gruppenseite deaktivieren`,
  DELETE_DESCRIPTION: `Möchten Sie diese Gruppenseite wirklich löschen?`,
  DELETE_TITLE: `Gruppenseite löschen`,
  MEETING_DESCRIPTION_TITLE: `Meeting-Beschreibung`,
  NAME_PLACEHOLDER: `Benutzerdefinierter Name`,
  NAME_TITLE: `Gruppenname anzeigen`,
  USE_BRANDING_TITLE: `Branding-Einstellungen verwenden von:`,
  VIEW_GROUP_PAGE: `Gruppenseite anzeigen`,
  MEETING_DESCRIPTION_TOOLTIP: `Der Meeting-Beschreibungstext wird nur auf der öffentlichen Gruppenseite für Buchungen für den Eingeladenen angezeigt.`,
});
