import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CardSkeleton, EmptyListScreen, SectionHeader } from '../../components/common';
import { SKELETON_LENGTH } from '../../types/constants';
import labels from './labels';
import { DEFAULT_WORKSPACE, workspacesSelectors } from '../../store/workspaces';
import { workspacesActions } from '../../store/workspaces/actions';
import { DeleteWorkspaceModal, WorkspaceCard } from '../../components/workspaces';
import { Path } from '../../routing';
import { usersActions, usersSelectors } from '../../store/users';
import { rolesActions, rolesSelectors } from '../../store/roles';
import { CannotDeleteWorkspaceModal } from '../../components/workspaces/cannotDeleteWorkspaceModal/CannotDeleteWorkspaceModal';
import { userSettingsSelectors } from '../../store/userSettings';
import { errorActions } from '../../store/error/actions';
import { ErrorCodes } from '../../store/error/types';
import { navigationService } from '../../services/NavigationService';
import { useSession } from '../../hooks/useSession';
import { CloneWorkspaceModal } from '../../components/workspaces/cloneWorkspaceModal/CloneWorkspaceModal';

export const Workspaces = () => {
  const dispatch = useDispatch();
  const { isInitialVisit } = useSession(); // checking if the app was initialized on current url
  const isSpinnerFetching = useSelector(workspacesSelectors.selectIsSpinnerFetching);
  const isSkeletonFetching = useSelector(workspacesSelectors.selectIsSkeletonFetching);
  const isUsersFetching = useSelector(usersSelectors.selectIsFetching);
  const isRolesFetching = useSelector(rolesSelectors.selectIsFetching);
  const userWorkspacesIds =
    useSelector(usersSelectors.selectUserWorkspacesIds).filter((id): id is string => id !== null) || [];
  const availableWorkspaces = useSelector(workspacesSelectors.selectWorkspacesByIds(userWorkspacesIds));
  const filteredWorkspaces = useSelector(workspacesSelectors.selectFilteredWorkspacesByIds(userWorkspacesIds));
  const isWorkspacesRead = useSelector(userSettingsSelectors.selectWorkspacesRead);
  const isWorkspacesCreate = useSelector(userSettingsSelectors.selectWorkspacesCreate);
  const superAdminUsers = useSelector(usersSelectors.selectSuperAdmins);
  const currentUser = useSelector(usersSelectors.selectCurrentUser);
  const workspaceRoleIds = useSelector(rolesSelectors.selectWorkspaceIds);

  useEffect(() => {
    if (isWorkspacesRead) {
      if (!isInitialVisit) {
        dispatch(workspacesActions.getWorkspacesRequest());
      }
      dispatch(usersActions.getUsersRequest());
      dispatch(rolesActions.getRolesRequest());
    } else {
      dispatch(errorActions.setTheError(ErrorCodes.CODE_403));
    }
  }, [isInitialVisit]);

  const handleCreateNew = () => {
    const defaultAdminList = [...superAdminUsers]; // add all super admins

    // if current user is workspace admin let add him too
    if (workspaceRoleIds.includes(currentUser.roleId)) {
      defaultAdminList.push(currentUser);
    }

    // TODO: add isDefault to workspace schema? now using first workspace as default
    dispatch(
      workspacesActions.setWorkspace({
        ...DEFAULT_WORKSPACE,
        adminList: defaultAdminList,
        labels: filteredWorkspaces[0].labels,
        style: filteredWorkspaces[0].style,
      })
    );
    navigationService.navigateTo(Path.EditWorkspace);
  };

  return (
    <div>
      <DeleteWorkspaceModal />
      <CannotDeleteWorkspaceModal />
      <CloneWorkspaceModal />

      <div className="sumo-card-bg flex flex-column pt-16px">
        <SectionHeader
          loading={isSpinnerFetching || isUsersFetching || isRolesFetching}
          title={labels.title}
          itemsLength={availableWorkspaces.length}
          searchPaths={[Path.Workspaces, Path.EditWorkspace]}
          buttonLabel={labels.newWorkspace}
          onButtonClick={handleCreateNew}
          hideButton={!isWorkspacesCreate}
        />

        <div className="flex flex-wrap -m-8px">
          {isSkeletonFetching ? (
            [...Array(SKELETON_LENGTH)].map((value, index) => (
              <div className="w-12 lg:w-6 p-8px" key={index}>
                <CardSkeleton hideDetails hideOptions />
              </div>
            ))
          ) : filteredWorkspaces.length ? (
            filteredWorkspaces.map((workspace) => (
              <div className="w-12 lg:w-6 p-8px" key={workspace.id}>
                <WorkspaceCard workspace={workspace} />
              </div>
            ))
          ) : (
            <div className="w-12 p-8px">
              <EmptyListScreen />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
