import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { ConfirmationModal, Preloader } from '../../components/common';
import { Button } from 'primereact/button';
import { navigationService } from '../../services/NavigationService';
import { Path } from '../../routing';
import {
  ArrowLeftIcon,
  ChevronDownIcon,
  ChevronUpIcon,
  CodeBracketIcon,
  EyeIcon,
  LinkIcon,
} from '@heroicons/react/24/outline';
import labels from './labels';
import { Fragment, useEffect, useState } from 'react';
import { useSession } from '../../hooks/useSession';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { GroupBookingPageBasicSettings } from '../../components/groupBookingPages/groupBookingPageBasicSettings/GroupBookingPageBasicSettings';
import { GroupBookingPageHowStep } from '../../components/groupBookingPages/groupBookingPageHowStep/GroupBookingPageHowStep';
import { groupBookingPagesActions, groupBookingPagesSelectors } from '../../store/groupBookingPages';
import { addToWebsiteModalActions, bookingPagesActions, bookingPageSelectors } from '../../store/bookingPages';
import { InputSwitch } from 'primereact/inputswitch';
import { BookingPageAddToWebsiteModal } from '../../components/bookingPages';

export const EditGroupBookingPage = () => {
  const { groupBookingPageId } = useParams();
  const { isInitialVisit } = useSession(); // checking if the app was initialized on current url
  const dispatch = useDispatch();
  const isFetching = useSelector(groupBookingPagesSelectors.selectIsFetching);
  const isEdited = useSelector(groupBookingPagesSelectors.selectIsEdited);
  const groupBookingPage = useSelector(groupBookingPagesSelectors.selectGroupBookingPage);
  const linkedBookingPages = useSelector(bookingPageSelectors.selectLinkedBookingPages(groupBookingPage));
  const name = useSelector(groupBookingPagesSelectors.selectGroupBookingPageName);
  const link = useSelector(groupBookingPagesSelectors.selectGroupBookignPageLink);
  const isGroupPageValid = useSelector(groupBookingPagesSelectors.selectIsGroupBookingPageValid);

  const [index1, setIndex1] = useState<boolean>(false);
  const [index2, setIndex2] = useState<boolean>(false);
  const [isCancelModalOpen, setIsCancelModalOpen] = useState(false);

  const headerTemplate = (title: string, description: string, index: boolean) => (
    <div className="flex-left-center px-20px py-8px">
      <div className="flex-1 flex flex-column gap-2px text-title-xs-med pb-4px">
        <div className="text-title-s-med text-heavy-100">{title}</div>
        <div className="text-body-s-reg">{description}</div>
      </div>
      <div className="flex-none">
        {index ? <ChevronUpIcon width={20} height={20} /> : <ChevronDownIcon width={20} height={20} />}
      </div>
    </div>
  );

  useEffect(() => {
    setIndex1(false);
    setIndex2(false);
    dispatch(
      groupBookingPagesActions.getGroupBookingPageRequest({
        id: !groupBookingPageId || groupBookingPageId === 'new' ? '' : groupBookingPageId,
        isInitialVisit,
      })
    );
  }, []);

  const handleViewGroupBookingPage = () => {
    window.open(link, '_blank');
  };

  const handleCopyLink = () => {
    navigator.clipboard.writeText(link);
  };

  const handleActiveChange = (active: boolean) => {
    dispatch(groupBookingPagesActions.updateGroupBookingPage({ active }));
  };

  const handleAddToWebsite = () => {
    dispatch(
      bookingPagesActions.setAddToWebsiteLink(
        Path.PublicGroupBookingPage.replace(':groupBookingPageId', groupBookingPage.id)
      )
    );
    dispatch(addToWebsiteModalActions.openModal());
  };

  const handleBack = () => {
    if (isEdited) {
      setIsCancelModalOpen(true);
    } else {
      handleConfirmCancel();
    }
  };

  const handleConfirmCancel = () => {
    navigationService.navigateTo(Path.BookingPages);
    dispatch(groupBookingPagesActions.resetGroupBookingPage());
  };

  const handleSave = () => {
    dispatch(groupBookingPagesActions.upsertGroupBookingPageRequest());
  };

  return (
    <div>
      {isFetching && <Preloader />}
      <BookingPageAddToWebsiteModal />
      <ConfirmationModal
        visible={isCancelModalOpen}
        title={labels.cancel}
        additionalText={[labels.cancelTextPart1, labels.cancelTextPart2]}
        confirmButtonLabel={labels.cancelYes}
        cancelButtonLabel={labels.cancelNo}
        onConfirm={handleConfirmCancel}
        onCancel={() => setIsCancelModalOpen(false)}
        onClose={() => setIsCancelModalOpen(false)}
      />

      <div className="flex-left-center -mt-24px mb-4px">
        <Button className="button-blue" text onClick={handleBack}>
          <ArrowLeftIcon width={16} height={16} className="mr-8px" />
          {labels.back}
        </Button>
      </div>
      <div className="sumo-card-bg flex flex-column p-24px gap-16px">
        <div className="sumo-card p-20px pt-24px mt-16px">
          {/* {Boolean(lockedTooltip) && (
            <>
              <Tooltip target=".locked" />
              <LockClosedIcon
                className="icon-16px text-heavy-60 locked"
                data-pr-tooltip={lockedTooltip}
                data-pr-position="top"
              />
            </>
          )} */}

          <div className="flex-center">
            <div className="text-title-lg-med mr-auto">{name}</div>
            <div className="flex-center gap-8px text-button-md-med text-heavy-60">
              <Button
                outlined
                className="button-icon"
                onClick={handleViewGroupBookingPage}
                disabled={!groupBookingPage.id || !groupBookingPage.active}
              >
                <EyeIcon className="icon-18px" />
              </Button>
              <Button
                outlined
                className="button-icon"
                onClick={handleAddToWebsite}
                disabled={!groupBookingPage.id || !groupBookingPage.active}
              >
                <CodeBracketIcon className="icon-18px" />
              </Button>
              <Button
                outlined
                className="button-icon"
                onClick={handleCopyLink}
                disabled={!groupBookingPage.id || !groupBookingPage.active}
              >
                <LinkIcon className="icon-18px" />
              </Button>
            </div>
          </div>
          <div className="w-full h-1px bg-heavy-20 mt-20px mb-12px" />
          <div className="flex-left-center flex-wrap text-body-s-reg text-heavy-60">
            {linkedBookingPages.map((page, index) => (
              <Fragment key={`name_${page.id}`}>
                {index > 0 && <div className="w-4px h-4px border-radius-2px mx-6px bg-heavy-50" />}
                <div>{page.what?.customName}</div>
              </Fragment>
            ))}
          </div>
          <div className="text-title-xs-med flex-right-center gap-12px h-fit py-10px">
            <InputSwitch
              inputId="active"
              checked={groupBookingPage.active || false}
              onChange={(e) => handleActiveChange(!!e.value)}
            />
            <label htmlFor="active" className="cursor-pointer">
              {labels.active}
            </label>
          </div>

          <div className="flex-left-center gap-6px -mb-4px -mx-22px px-22px">
            <Button
              className="button-xl flex-center"
              style={{ minWidth: '120px' }}
              onClick={handleSave}
              disabled={!isGroupPageValid}
            >
              {labels.save}
            </Button>
            <Button className="button-xl" text onClick={handleBack}>
              {labels.cancel}
            </Button>
          </div>
        </div>
        <Accordion
          className="sumo-card border-none"
          activeIndex={index1 ? 0 : null}
          onTabChange={() => setIndex1((prev) => !prev)}
        >
          <AccordionTab
            contentClassName="px-20px pt-12px pb-24px"
            header={headerTemplate(labels.basicSettings, labels.basicSettingsDesc, index1)}
          >
            <GroupBookingPageBasicSettings />
          </AccordionTab>
        </Accordion>
        <Accordion
          className="sumo-card border-none"
          activeIndex={index2 ? 0 : null}
          onTabChange={() => setIndex2((prev) => !prev)}
        >
          <AccordionTab
            contentClassName="px-20px pt-12px pb-24px"
            header={headerTemplate(labels.branding, labels.brandingDesc, index2)}
          >
            <GroupBookingPageHowStep />
          </AccordionTab>
        </Accordion>
      </div>
    </div>
  );
};
