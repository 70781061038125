import { COLORS } from '../../../store/bookingTemplates';
import { Dropdown } from 'primereact/dropdown';

type ColorPickerProps = {
  color: string | null | undefined;
  onColorChange: (color: string) => void;
};
export const ColorPicker = ({ color, onColorChange }: ColorPickerProps) => {
  const valueTemplate = (color: string) => (
    <div className="w-20px h-20px border-radius-4px -my-2px" style={{ background: color }} />
  );

  return (
    <Dropdown
      value={color}
      options={COLORS}
      onChange={(e) => onColorChange(e.value)}
      valueTemplate={valueTemplate}
      itemTemplate={valueTemplate}
    />
  );
};
