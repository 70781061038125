import { SelectButton } from 'primereact/selectbutton';
import labels from './labels';
import { Dropdown } from 'primereact/dropdown';
import {
  ORG_STATUS_OPTIONS,
  ORG_STATUS_VALUES,
  TERM_FILTER_OPTIONS,
  TERM_FILTER_VALUES,
  orgsActions,
  orgsSelectors,
} from '../../../../store/opsConsole/orgs';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';

export const OrgsFilters = () => {
  const dispatch = useDispatch();
  const status = useSelector(orgsSelectors.selectFilterStatus);
  const term = useSelector(orgsSelectors.selectFilterTerm);

  const handleStatusChange = (status: ORG_STATUS_VALUES) => {
    dispatch(orgsActions.changeFilter({ status }));
  };

  const handleTermChange = (term: TERM_FILTER_VALUES) => {
    dispatch(orgsActions.changeFilter({ term }));
  };

  return (
    <div className="flex flex-wrap">
      <div className="flex flex-column mr-5 mb-3 sm:mb-0">
        <label htmlFor="term">{labels.term}</label>
        <SelectButton
          value={term}
          onChange={(e) => handleTermChange(e.value)}
          options={TERM_FILTER_OPTIONS}
          id="term"
        />
      </div>
      <div className="flex flex-column mb-3 sm:mb-0">
        <label htmlFor="status">{labels.status}</label>
        <Dropdown
          inputId="status"
          options={ORG_STATUS_OPTIONS}
          value={status}
          onChange={(e) => handleStatusChange(e.value)}
          className="w-10rem"
        />
      </div>
    </div>
  );
};
