import { t } from '../../../i18n/i18n';

export default {
  back: t('Common:BACK'),
  connect: t('Common:CONNECT'),
  connected: t('Common:CONNECTED'),
  description: t('QuickSetupVideoConferenceStep:DESCRIPTION'),
  googleMeetTitle: t('QuickSetupVideoConferenceStep:GOOGLE_MEET_TITLE'),
  microsoftTeamsTitle: t('QuickSetupVideoConferenceStep:MICROSOFT_TEAMS_TITLE'),
  next: t('Common:NEXT'),
  stepMessage: t('QuickSetupVideoConferenceStep:STEP_MESSAGE'),
  title: t('QuickSetupVideoConferenceStep:TITLE'),
  zoomTitle: t('QuickSetupVideoConferenceStep:ZOOM_TITLE'),
};
