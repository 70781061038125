export const Billing = Object.freeze({
  TITLE: `Abrechnung`,
  YOUR_SUBSCRIPTION: `Ihr Abonnement`,
  TEAMS_MONTHLY: `Teams Monatlich`,
  TEAMS_ANNUAL: `Teams Jährlich`,
  SEATS: `Sitze`,
  LAST_UPDATED: `Zuletzt aktualisiert am`,
  CHANGE_TERM: `Laufzeit ändern`,
  ADD_SEATS: `Sitze hinzufügen`,
  REMOVE_SEATS: `Sitze entfernen`,
  YOUR_NEXT_BILL: `Ihre nächste Rechnung`,
  DUE_ON: `Fällig am`,
  BILL_IS_LATE_PART_1: `Rechnung ist derzeit`,
  BILL_IS_LATE_PART_2_SINGLE: `Tag überfällig.`,
  BILL_IS_LATE_PART_2_MULTIPLE: `Tage überfällig.`,
  BILLING_DETAILS: `Abrechnungsdetails`,
  CHARGEBEE_BUTTON: `Abrechnungsportal öffnen`,
  BILLING_PORTAL_DESRIPTION: `Sie können Ihre Transaktionshistorie und mehr einsehen.`,
  SCHEDULED_CHANGES: `Geplante Änderungen für`,
  SCHEDULED_CHANGES_DESRIPTION: `Die folgenden Änderungen sollen am Ende des aktuellen Zeitraums erfolgen und können vor Ende des Zeitraums storniert werden.`,
  SCHEDULED_SEATS_CHANGE_PART_1: `Reduzieren`,
  SCHEDULED_SEATS_CHANGE_PART_2: `Sitze`,
  SCHEDULED_TERM_CHANGE: `Laufzeit geändert auf`,
  SCHEDULED_COLUMN_LABEL: `Änderungsauftrag`,
  SCHEDULED_COLUMN_CHANGE_BY: `Änderung durch`,
  SCHEDULED_COLUMN_DATE: `Anforderungsdatum`,
  SCHEDULED_COLUMN_OPTIONS: `Optionen`,
  ADD_SEATS_DESCRIPTION_PART_1: `Hinzufügen von`,
  ADD_SEATS_DESCRIPTION_PART_2: `Sitz(e) werden hinzugefügt`,
  ADD_SEATS_DESCRIPTION_PART_3: `Rechnung um`,
  ADD_SEATS_DESCRIPTION_PART_4: `beginnend`,
  ADD_SEATS_PRORATED_PART_1: `Für die verbleibenden`,
  ADD_SEATS_PRORATED_PART_2: `Tage bis zu Ihrer nächsten Zahlung wird Ihre Kreditkarte heute mit einem anteiligen Betrag von`,
  ADD_SEATS_PRORATED_PART_3: `belastet`,
  REMOVE_SEATS_DESCRIPTION_PART_1: `Entfernen von`,
  REMOVE_SEATS_DESCRIPTION_PART_2: `Sitz(en) reduziert die`,
  REMOVE_SEATS_DESCRIPTION_PART_3: `Rechnung um`,
  REMOVE_SEATS_DESCRIPTION_PART_4: `beginnend`,
  EXPIRE_MESSAGE_PART_1: `Rechnung ist`,
  EXPIRE_MESSAGE_PART_2: `Tage überfällig. Ihre Organisation wird in`,
  EXPIRE_MESSAGE_PART_3: `Tagen nicht mehr funktionieren.`,
  NUMBER_OF_LICENSES: `# Lizenzen`,
  LICENSES_TITLE: `Lizenzen`,
  LICENSES_PLACEHOLDER: `Geben Sie eine Zahl ein`,
  LICENSE_MESSAGE_PART_1: `Sie haben bereits`,
  LICENSE_MESSAGE_PART_2: `Lizenzen auf Benutzer zugewiesen. Bitte geben Sie mehr an`,
  LICENSE_MESSAGE_PART_3: `oder deaktivieren/löschen Sie Benutzer, um Lizenzen freizugeben`,
  LOADING_LABEL_1: `Verbinden…`,
  LOADING_LABEL_2: `Lizenzen hinzufügen`,
  PAY_NOW_TITLE: `Jetzt bezahlen`,
  TRIAL_LICENSE_EXPIRED: `Ihre SUMO1-Lizenz ist abgelaufen. Um SUMO1 ohne Unterbrechungen weiterhin nutzen zu können, erwerben Sie bitte eine neue Lizenz.`,
  BUY_LICENSES_MESSAGE: `Ihre Rechnung wird sein`,
  MONTH_TITLE: `Monat`,
  MONTHLY_TITLE: `monatlich`,
  ANNUAL_TITLE: `jährlich`,
  ANNUALLY_TITLE: `jährlich`,
  REMOVE_SEATS_NOTE_PART1: `Um mehr als`,
  REMOVE_SEATS_NOTE_PART2: `Sitz(e) zu entfernen, können Sie Benutzer deaktivieren oder löschen, um Lizenzen freizugeben.`,
  CANCELLATION_SCHEDULED: `Ihr Abonnement ist für den geplant, gekündigt zu werden `,
  ACCOUNT_SUSPENDED_PART1: `Ihr Konto ist gesperrt. Sie müssen`,
  ACCOUNT_SUSPENDED_PART2: `Ihre ausstehende Rechnung bezahlen`,
  ACCOUNT_SUSPENDED_PART3: `, um den Service wieder zu aktivieren.`,
});

export const BillingToastsNotifications = Object.freeze({
  GET_BILLING_ERROR_TOAST: `ChargeBee Verbindungsfehler`,
  GET_ITEM_TIERS_ERROR_TOAST: `Ich konnte die Artikelpreise für Lizenzen nicht erhalten.`,
  BUY_LICENSE_SUCCESS_TOAST: `Abonnement erfolgreich erstellt`,
  ADD_SEATS_SUCCESS_TOAST_TITLE: `Sitze hinzugefügt.`,
  ADD_SEATS_SUCCESS_TOAST: `Bitte warten Sie bis zu 1 Minute, bevor wir Ihre Zahlung erhalten.`,
  ADD_SEATS_ERROR_TOAST: `Sitze konnten nicht hinzugefügt werden`,
  REMOVE_SEATS_SUCCESS_TOAST_TITLE: `Sitze entfernt.`,
  REMOVE_SEATS_SUCCESS_TOAST: `Sie müssen Sitze vor Ende des Zeitraums freigeben.`,
  REMOVE_SEATS_ERROR_TOAST: `Sitze konnten nicht entfernt werden`,
  CHANGE_TERM_SUCCESS_TOAST: `Laufzeit erfolgreich geändert.`,
  CHANGE_TERM_ERROR_TOAST: `Laufzeit konnte nicht geändert werden.`,
  CHARGEBEE_PORTAL_CLOSE_TOAST: `Wenn Sie Änderungen vorgenommen haben, warten Sie bitte bis zu 1 Minute, bevor wir sie erhalten.`,
  DELETE_CHANGE_SUCCESS_TOAST: `Geplante Änderung erfolgreich gelöscht.`,
  DELETE_CHANGE_ERROR_TOAST: `Geplante Änderung konnte nicht gelöscht werden.`,
});
