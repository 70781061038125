export const Locations = Object.freeze({
  TITLE: `Locations`,
  CLONE_TITLE: `Enter cloned location name`,
  ADD_LABEL: `New Location`,
  DEACTIVATE_TITLE: `Deactivate Location`,
  DEACTIVATE_DESCRIPTION: `Are you sure you would like to deactivate`,
  DELETE_TITLE: `Delete Location`,
  DELETE_DESCRIPTION: `Are you sure you would like to delete`,
  NAME_LABEL: `Name`,
  ADDRESS_LABEL: `Address`,
  ZIP_LABEL: `ZIP`,
  STATE_LABEL: `State`,
  COUNTRY_LABEL: `Country`,
  IMPORT_CSV: `Import CSV`,
  IMPORT_LABEL: `Import`,
  STATUS: `Status`,
  ACTIVE: `Active`,
  INACTIVE: `Inactive`,
  UPLOAD_DESCRIPTION_PART1: `Select CSV file on your computer`,
  UPLOAD_DESCRIPTION_PART2: `or transfer it to this upload area`,
  IMPORT_DESCRIPTION_PART1: `Here you can find `,
  IMPORT_DESCRIPTION_PART2: `sample`,
  IMPORT_DESCRIPTION_PART3: ` file`,
  EMPTY_LIST_DESCRIPTION: `Create a Location or adjust your search or filters.`,
  SELECTED_LOCATIONS: `selected locations`,
  CITY_LABEL: `City`,
});

export const EditLocation = Object.freeze({
  ADD_LOCATION: `Add Location`,
  NAME: `Name`,
  NAME_PLACEHOLDER: `Enter location name`,
  ADDRESS: `Address`,
});

export const LocationsToastsNotifications = Object.freeze({
  GET_LOCATIONS_ERROR_MESSAGE: `Get locations request fail`,
  DEACTIVATE_LOCATION_SUCCESS_MESSAGE: `Location successfully deactivated`,
  ACTIVATE_LOCATION_SUCCESS_MESSAGE: `Location successfully activated`,
  DEACTIVATE_LOCATION_ERROR_MESSAGE: `Activate/Deactivate location request fail`,
  CLONE_LOCATION_SUCCESS_MESSAGE: `Location successfully cloned`,
  CLONE_LOCATION_ERROR_MESSAGE: `Clone location request fail`,
  DELETE_LOCATION_SUCCESS_MESSAGE: `Location successfully deleted`,
  DELETE_LOCATION_ERROR_MESSAGE: `Delete location request fail`,
  VALIDATE_LOCATIONS_ERROR_MESSAGE: `One or more records have blank fields. All fields are required.`,
  IMPORT_LOCATIONS_SUCCESS_MESSAGE: `Locations successfully imported`,
  IMPORT_LOCATIONS_ERROR_MESSAGE: `Import locations request fail`,
  GET_LOCATION_ERROR_MESSAGE: `Get location request fail`,
  SAVE_LOCATION_ERROR_MESSAGE: `Save location request fail`,
  CREATE_LOCATION_SUCCESS_MESSAGE: `Location successfully created`,
  UPDATE_LOCATION_SUCCESS_MESSAGE: `Location successfully updated`,
});
