import { useSelector } from 'react-redux';
import { userSettingsSelectors } from '../../../store/userSettings';
import { Avatar } from 'primereact/avatar';
import { getInitials } from '../../../services/utils';

interface IUserAvatar {
  large?: boolean;
  name: string;
  className?: string;
  noImage?: boolean;
}

export const UserAvatar: React.FC<IUserAvatar> = ({ large, name, className, noImage }) => {
  const avatarLink = useSelector(userSettingsSelectors.selectAvatar);

  return (
    <Avatar
      image={!noImage && avatarLink || ''}
      label={getInitials(name || '')}
      size={large ? 'xlarge' : 'large'}
      className={className}
    />
  );
};
