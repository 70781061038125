import { ConfirmationModal, SectionHeader } from '../../../components/common';
import labels from './labels';
import { STAFF_ROLE_OPTIONS } from '../../../types/constants';
import { useDispatch, useSelector } from 'react-redux';
import {
  NEW_DEFAULT_STAFF_RECORD,
  STAFF_STATUS_OPTIONS,
  deactivateStaffModalActions,
  deactivateStaffModalSelectors,
  deleteStaffModalActions,
  deleteStaffModalSelectors,
  staffActions,
  staffSelectors,
} from '../../../store/opsConsole/staff';
import { Column } from 'primereact/column';
import { formatDateTimeThroughGap } from '../../../services/DateService';
import { useEffect } from 'react';
import { StaffRowActions, Table } from '../../../components/opsConsole';
import { Path } from '../../../routing';
import { Staff } from '../../../API';
import { authenticationSelectors } from '../../../store/authentication';
import { navigationService } from '../../../services/NavigationService';
import { DataTableRowClickEvent } from 'primereact/datatable';

export const StaffPage = () => {
  const dispatch = useDispatch();
  const isFetching = useSelector(staffSelectors.selectIsFetching);
  const staff = useSelector(staffSelectors.selectStaff);
  const filteredStaff = useSelector(staffSelectors.selectSearchStaff);
  const staffName = useSelector(staffSelectors.selectStaffNameOrEmail);
  const isPaginatorEnabled = useSelector(staffSelectors.selectIsPaginatorEnabled);
  const isMainAdmin = useSelector(authenticationSelectors.selectIsMainAdmin);
  const isMainAdminOrOperations = useSelector(authenticationSelectors.selectIsMainAdminOrOperations);
  const userId = useSelector(authenticationSelectors.selectUserId);

  const deactivateModalOpen = useSelector(deactivateStaffModalSelectors.selectIsModalOpen);
  const deleteModalOpen = useSelector(deleteStaffModalSelectors.selectIsModalOpen);

  useEffect(() => {
    dispatch(staffActions.getStaffRequest());
  }, []);

  const openStaffDetails = (staffRecord: Staff) => {
    if (isMainAdminOrOperations && staffRecord.userId) {
      dispatch(staffActions.setStaffRecord(staffRecord));
      navigationService.navigateTo(Path.OPSConsoleStaffDetails);
    }
  };

  const handleAddNew = () => {
    dispatch(staffActions.setStaffRecord(NEW_DEFAULT_STAFF_RECORD));
    dispatch(staffActions.setIsAddNew(true));
    navigationService.navigateTo(Path.OPSConsoleStaffDetails);
  };

  const handleDeactivateClose = () => {
    dispatch(deactivateStaffModalActions.closeModal());
  };

  const handleDeleteClose = () => {
    dispatch(deleteStaffModalActions.closeModal());
  };

  const renderStaffRowActions = (
    data: Staff,
    isMainAdminOrOperations: boolean,
    isMainAdmin: boolean,
    userId: string
  ) => {
    const shouldShowActions = (isMainAdminOrOperations && !!data.userId) || (isMainAdmin && userId !== data.userId);
    return shouldShowActions ? <StaffRowActions staffRecord={data} showDelete={isMainAdmin} /> : null;
  };

  return (
    <>
      <ConfirmationModal
        title={labels.deactivateTitle}
        description={`${labels.deactivateMessage} "${staffName}"?`}
        visible={deactivateModalOpen}
        onClose={handleDeactivateClose}
        onCancel={handleDeactivateClose}
        onConfirm={() => {
          dispatch(staffActions.changeStaffStatusRequest());
        }}
        cancelButtonLabel={labels.no}
        confirmButtonLabel={labels.yes}
      />
      <ConfirmationModal
        title={labels.deleteTitle}
        description={`${labels.deleteMessage} "${staffName}"?`}
        visible={deleteModalOpen}
        onClose={handleDeleteClose}
        onCancel={handleDeleteClose}
        onConfirm={() => {
          dispatch(staffActions.deleteStaffRequest());
        }}
        cancelButtonLabel={labels.no}
        confirmButtonLabel={labels.yes}
      />

      <div className="sumo-card-bg flex flex-column pt-16px">
        <SectionHeader
          loading={isFetching}
          title={labels.title}
          itemsLength={staff.length}
          searchPaths={[Path.OPSConsoleStaff, Path.OPSConsoleStaffDetails]}
          buttonLabel={labels.addStaff}
          onButtonClick={handleAddNew}
          hideButton={!isMainAdminOrOperations}
        />

        <Table
          value={filteredStaff}
          paginator={isPaginatorEnabled}
          className="pt-3"
          onRowClick={(e: DataTableRowClickEvent) => openStaffDetails(e.data as Staff)}
          rowClassName={(data) => (isMainAdminOrOperations && data.userId ? 'cursor-pointer' : '')}
          rowHover
          paths={[Path.OPSConsoleStaff, Path.OPSConsoleStaffDetails]}
        >
          <Column field="userName" header={labels.name} sortable />
          <Column field="email" header={labels.email} sortable />
          <Column
            field="roleName"
            body={(data: Staff) => STAFF_ROLE_OPTIONS.find((role) => role.value === data.roleName)?.label}
            header={labels.role}
            sortable
          />
          <Column
            field="status"
            body={(data: Staff) => STAFF_STATUS_OPTIONS.find((status) => status.value === data.status)?.label}
            header={labels.status}
            sortable
          />

          <Column
            field="lastLogin"
            header={labels.lastLogin}
            sortable
            body={(data: Staff) => (data.userId && data.lastLogin ? formatDateTimeThroughGap(data.lastLogin) : '')}
          />
          <Column
            body={(data: Staff) => renderStaffRowActions(data, isMainAdminOrOperations, isMainAdmin, userId)}
            className="w-4rem"
            align="center"
            frozen
          />
        </Table>
      </div>
    </>
  );
};
