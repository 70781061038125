import { useState } from 'react';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { MAX_LENGTH_DESCRIPTION, MAX_LENGTH_SHORT_ANSWER } from '../../../types/constants';
import { PhoneNumber } from '../phoneNumber/PhoneNumber';
import { JourneyFormFieldInput, JourneyFormFieldType } from '../../../API';
import { validateFormField } from '../../../store/publicBookingPage/utils';
import { RadioButton, RadioButtonChangeEvent } from 'primereact/radiobutton';

interface IJourneyFormField {
  formField: JourneyFormFieldInput;
  onChange?: (formField: JourneyFormFieldInput) => void;
  hideLabel?: boolean;
}

export const JourneyFormField = ({ formField, onChange, hideLabel }: IJourneyFormField) => {
  const [values, setValues] = useState(formField.value || []);
  const className = validateFormField(formField) ? '' : 'p-invalid';

  const handleChange = (value: (string | null)[]) => {
    setValues(value);
    onChange && onChange({ ...formField, value });
  };

  const handleTextChange = (value: string) => {
    setValues([value]);
  };

  const handleTextBlur = () => {
    handleChange(values);
  };

  const handleRadioBtnChange = (e: RadioButtonChangeEvent) => {
    handleChange([e.value]);
  };

  const handleDropdownChange = (value: string) => {
    handleChange([value]);
  };

  const handlePhoneChange = (phone: string) => {
    setValues([`${values[0]?.split('-')[0]}-${phone}`]);
  };

  const handlePhoneBlur = () => {
    handleChange(values);
  };

  const handlePhoneCodeChange = (code: string) => {
    handleChange([`${code}-${values[0]?.slice((values[0]?.indexOf('-') || -1) + 1) || ''}`]);
  };

  // const handleFileChange = (value: string | null) => {
  //   if (!value) {
  //     handleChange(values.slice(0, -1));
  //   } else {
  //     handleChange([...values, value]);
  //   }
  // };

  if (formField.type === JourneyFormFieldType.TITLE) {
    return null;
  }

  if (formField.type === JourneyFormFieldType.SUBTITLE) {
    return <div className="text-body-s-reg text-heavy-60 pb-12px">{formField.label}</div>;
  }

  return (
    <div className="flex flex-column gap-6px">
      {!hideLabel && (
        <div className="text-title-xs-med">
          <span>{formField.label}</span>
          {formField.required && <span>*</span>}
        </div>
      )}

      {(formField.type === JourneyFormFieldType.SINGLE ||
        formField.type === JourneyFormFieldType.NAME ||
        formField.type === JourneyFormFieldType.EMAIL) && (
        <InputText
          className={className}
          value={(values && values[0]) || ''}
          placeholder={(hideLabel && formField.label) || ''}
          onChange={(e) => handleTextChange(e.target.value)}
          onBlur={handleTextBlur}
          maxLength={MAX_LENGTH_SHORT_ANSWER}
        />
      )}

      {formField.type === JourneyFormFieldType.MULTIPLE && (
        <InputTextarea
          className={className}
          autoResize
          rows={3}
          value={(values && values[0]) || ''}
          onChange={(e) => handleTextChange(e.target.value)}
          onBlur={handleTextBlur}
          maxLength={MAX_LENGTH_DESCRIPTION}
        />
      )}

      {formField.type === JourneyFormFieldType.PHONE && (
        // format BY_375-xxxxxxxx
        <PhoneNumber
          countryCode={values[0]?.split('-')[0] || ''}
          handleChangeCode={handlePhoneCodeChange}
          phoneNumber={values[0]?.slice((values[0]?.indexOf('-') || -1) + 1) || ''}
          handleChangePhone={handlePhoneChange}
          handleBlurPhone={handlePhoneBlur}
          inputClassName={className}
        />
      )}

      {formField.type === JourneyFormFieldType.RADIO_BUTTONS &&
        formField.answers?.map((answer, index) => (
          <div key={index} className="flex-left-center gap-8px">
            <RadioButton
              inputId={(formField.label || '') + index}
              className={className}
              value={answer}
              checked={Boolean(values.find((item) => item === answer))}
              onChange={handleRadioBtnChange}
            />
            <label htmlFor={(formField.label || '') + index} className="cursor-pointer text-label-s-med">
              {answer}
            </label>
          </div>
        ))}

      {formField.type === JourneyFormFieldType.DROPDOWN && (
        <Dropdown
          className={className}
          value={(values && values[0]) || ''}
          onChange={(e) => handleDropdownChange(e.target.value)}
          options={(formField.answers || []).map((answer) => answer)}
        />
      )}

      {/* {formField.type === JourneyFormFieldType.ATTACHMENT && (
        <ImageUpload value={(values.length && values[values.length - 1]) || null} onChange={handleFileChange} />
      )} */}
    </div>
  );
};
