import { InformationCircleIcon } from '@heroicons/react/24/outline';
import { Tooltip } from 'primereact/tooltip';
import { v4 as getId } from 'uuid';

type SumoTooltipProps = {
  text: string;
};

export const SumoTooltip = ({ text }: SumoTooltipProps) => {
  const uniqueId = `info-${getId()}`;

  return (
    <>
      <Tooltip target={`.${uniqueId}`} className="max-w-240px" />
      <InformationCircleIcon
        className={`${uniqueId} icon-16px text-heavy-60`}
        data-pr-position="top"
        data-pr-my="center bottom-10"
        data-pr-tooltip={text}
      />
    </>
  );
};
