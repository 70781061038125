import { createSelector } from 'reselect';
import { ModalName } from './types';
import { State } from '../rootStore';

const modalsState = (state: State) => state.modals;

const selectIsModalOpen = (key: ModalName) => createSelector(modalsState, (modals) => modals[key]?.open || false);
const selectModalRequest = (key: ModalName) => createSelector(modalsState, (modals) => modals[key]?.payload);

export const modalsSelectors = {
  selectIsModalOpen,
  selectModalRequest,
};

export const modalsSelectorsFactory = (key: ModalName) => ({
  selectIsModalOpen: selectIsModalOpen(key),
  selectModalRequest: selectModalRequest(key),
});
