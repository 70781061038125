export const Users = Object.freeze({
  ADD_TO_TEAM: `Add to Team`,
  ADD_TO_TEAM_PLACEHOLDER: `Select Team/s`,
  ALREADY_INVITED_COUNT: `Already a user`,
  ASSIGN_BOOKING_TEMPLATES: `Assign Booking Templates`,
  ASSIGN_BOOKING_TEMPLATES_PLACEHOLDER: `Select Booking Template/s`,
  ASSIGN_ROLE: `Assign Role`,
  ASSIGN_WORKSPACE: `Assign Workspace`,
  CLOSE: `Close`,
  CONFIRM_DIALOG_DESC: `To confirm, you will send an email invite as follows:`,
  CSV_INVITE_MESSAGE_TITLE: `Setup a large team in a minute`,
  CSV_TEMPLATE_DESC: `and populate it with your users' email addresses and roles.`,
  CSV_TEMPLATE_TITLE: `Download Template`,
  CSV_UPLOAD_DESC: `Select scv file on your computer`,
  CSV_UPLOAD_TITLE: `Upload CSV`,
  DELETE_TOOLTIP: `You can only delete "Invite Sent" users that you have invited. Super Admins can delete any user.`,
  EMAIL: `Email`,
  FULL_NAME: `Full name`,
  HIDE_INACTIVE_USERS: `Hide Inactive Users`,
  INVITE: `Invite`,
  INVITE_MESSAGE_DESC: `Type or paste emails, and we will send an invitation to your teammates. Separate email addresses with a comma.`,
  INVITE_MESSAGE_TITLE: `Email addresses`,
  INVITE_TITLE: `Invite Users`,
  LAST_ACTIVE: `Last active`,
  LOADING_LABEL_1: `Checking duplicates`,
  LOADING_LABEL_2: `Importing the users`,
  LOADING_LABEL_3: `Assigning attributes`,
  LOADING_LABEL_4: `Sending the invites`,
  BOOKING_TEMPLATE: `Booking Template`,
  NAME: `Name`,
  NO_EMAIL_ADDRESSES: `No email addresses found.`,
  OPTIONAL: `Optional`,
  PASTE_EMAILS_TITLE: `Add Email`,
  ROLE: `Role`,
  STATUS: `Status`,
  STATUS_INVITE_SENT: `Invite Sent`,
  STATUS_ACTIVE: `Active`,
  STATUS_INACTIVE: `Inactive`,
  SUPER_ADMIN_ACCESS_DESCRIPTION: `Super Admin will have access to all Workspaces`,
  TEAM: `Team`,
  TOOLTIP: `Invite and manage employees access to this SUMO1 account`,
  USER_COUNT: `Unique users to be invited`,
  WORKSPACE: `Workspace`,
});

export const UsersConfirmDialog = Object.freeze({
  DELETE_DESCRIPTION: `Are you sure you would like to delete`,
  DELETE_COMMENT_1: `Deactivating or deleting a user will free up a license.`,
  DELETE_NOTE_DESCRIPTION: `All user data will be permanently deleted from SUMO1 servers.`,
  DELETE_NOTE_TITLE: `GDPR Compliance`,
  DELETE_TITLE: `Delete`,
  DISABLE_COMMENT_1: `Deactivating a user will free up a license.`,
  DISABLE_COMMENT_2: `Appointments will not be deleted or canceled.`,
  DISABLE_DESCRIPTION: `Are you sure you would like to deactivate`,
  DISABLE_TITLE: `Deactivate User`,
});

export const EditUser = Object.freeze({
  ACCESS_TITLE: `Access`,
  ACTIVE: `Active`,
  ACTIVE_TOOLTIP: `Check this box to inactive this user and free up a license.`,
  CONTACT_DETAILS_TITLE: `Contact Details`,
  DEACTIVATE_HIMSELF: `You may not deactivate yourself.`,
  DAYS_10: `10 days`,
  DAYS_15: `15 days`,
  DAYS_20: `20 days`,
  DAYS_30: `30 days`,
  EXPIRATION_DATE: `Expiration date`,
  HIGHER_ROLE_DESCRIPTION: `This user has a higher role than you,`,
  INVITE_SENT_TOOLTIP: `User has not logged in yet. Deleting the record will free up the license.`,
  BOOKING_TEMPLATES: `Booking Templates`,
  BOOKING_TEMPLATES_MESSAGE: `This user supports the following booking templates`,
  BOOKING_TEMPLATES_TOOLTIP: `Select a workspace first`,
  NO_PERMISSION_DESCRIPTION: `You do not have permission to edit users,`,
  ONLY_ONE_SUPER_ADMIN: `There are no more super admins except you, to change the role invite at least one more super admin`,
  READONLY_DESCRIPTION: ` only read mode is available.`,
  TEAMS: `Teams`,
  TEAMS_MESSAGE: `This user is a member of the following teams`,
  TITLE: `Edit User`,
  USER_OF_WORKSPACE_TITLE: `User of Workspace`,
  WORKSPACE_MESSAGE: `This user may use the following workspaces`,
});

export const UsersToastsNotifications = Object.freeze({
  EDIT_USER_ERROR_TOAST: `Edit User request fail`,
  EDIT_USER_SUCCESS_TOAST: `Edit User request success`,
  ENABLE_USER_TOAST_HEADER: `User activated`,
  ENABLE_USER_SUCCESS_TOAST: `is now activated.`,
  GET_LICENSES_ERROR_MESSAGE: `Get Licenses request failed.`,
  GET_USERS_ERROR_MESSAGE: `Get Users request failed.`,
  INVITE_USERS_EMAIL_INVALID: `One or more of the email addresses entered are invalid.`,
  INVITE_USERS_ERROR_TOAST: `Invite Users request fail`,
  INVITE_USERS_SUCCESS_TOAST: `Invite Users request success`,
  DELETE_USER_TOAST_HEADER: `User deleted`,
  DELETE_USER_ERROR_TOAST: `Deleted User request fail`,
  DELETE_USER_SUCCESS_TOAST: `is now deleted.`,
  DISABLE_USER_TOAST_HEADER: `User deactivated`,
  DISABLE_USER_ERROR_TOAST: `Deactivate/Activate User request fail`,
  DISABLE_USER_SUCCESS_TOAST: `is now deactivated.`,
});

export const License = Object.freeze({
  ASSIGNED: `Assigned`,
  BUY_LICENSES: `Buy Licenses`,
  MY_BILLINGS: `My Billings`,
  SUBSCRIPTION: `Subscription`,
  LICENSES: `Licenses`,
  PAID_EXPIRES_MESSAGE_PART_1: `Payment Late:`,
  PAID_EXPIRES_MESSAGE_PART_2_AGO: `days ago`,
  PAID_EXPIRES_MESSAGE_PART_2_LEFT: `days left`,
  PAID_EXPIRES_MESSAGE_PART_2_TODAY: `today`,
  REMAINING: `Remaining`,
  REMAINING_MESSAGE: `You don't have enough remaining licenses`,
  TITLE: `User License Status`,
  TRIAL_EXPIRES_MESSAGE: `Free trial ends soon.`,
  SEATS_REMOVAL_SCHEDULED: `Scheduled changes to remove seats.`,
});
