import { useSelector } from 'react-redux';
import { RolePermissions } from '../../roles';
import { userSettingsSelectors } from '../../../store/userSettings';
import labels from './labels';

export const MyRole = () => {
  const role = useSelector(userSettingsSelectors.selectUserPermissions);
  return (
    <div className="flex flex-column gap-32px">
      <div className="flex flex-column gap-4px">
        <div className="text-title-xs-med text-heavy-80">{role.name}</div>
        <div className="text-label-xs-reg">{labels.roleDesc}</div>
      </div>

      <RolePermissions isPreview={true} role={role} />
    </div>
  );
};
