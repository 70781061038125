import { CreateUserEventInput } from '../../API';

export enum SortMethods {
  UPCOMING = 'UPCOMING',
  PAST = 'PAST',
  DATE_RANGE = 'DATE_RANGE',
}

export enum Statuses {
  ALL = 'ALL',
  BOOKED = 'BOOKED',
  CANCELED = 'CANCELED',
}

export type UpdateBookedMeeting = CreateUserEventInput & { editMeetingNotes?: boolean };

export type GetBookedMeetingsPayload = {
  eventId?: string;
};

export type GetBookedMeetingsResponse = {
  events: CreateUserEventInput[];
};

export type UpdateInternalFieldsUserEventRequest = {
  eventId: string;
  internalNotes?: string;
  isNoShow?: boolean;
};
