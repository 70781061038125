export const SmartAlerts = Object.freeze({
  TITLE: `Alertas inteligentes`,
  CREATE_ALERT: `Criar Alerta`,
  CLONE_ALERT_TITLE: `Nome do alerta inteligente clonado`,
  CLONE_TEMPLATE_TITLE: `Nome do modelo de alerta inteligente clonado`,
  DIALOG_PLACEHOLDER: `Lembrete para o convidado, Enviar agradecimento, etc...`,
  EMPTY_LIST_MESSAGE: `Crie um alerta ou ajuste sua busca ou filtros.`,
  PERSONAL_LABEL: `Pessoal`,
  SHARED_LABEL: `Compartilhado`,
  TEMPLATES_LABEL: `Modelos`,
  HIDDEN_LABEL: `Oculto`,
  UNHIDDEN_LABEL: `Visível`,
  BASIC_ALERTS: `Alertas Básicos`,
  ADD_SMART_ALERT: `+ Adicionar Alerta Inteligente`,
  DELETE_DESCRIPTION: `Tem certeza de que deseja excluir`,
  BULK_DELETE_DESCRIPTION: `os alertas inteligentes selecionados`,
  DELETE_WARNING_ONE: `Aviso: Excluir este Alerta Inteligente também o removerá das páginas de reserva e modelos de reserva onde é usado.`,
  DELETE_WARNING_MULTIPLE: `Aviso: Excluir esses Alertas Inteligentes também os removerá das páginas de reserva e modelos de reserva onde são usados.`,
});

export const SmartAlertsToastsNotifications = Object.freeze({
  GET_SMART_TYPES_ERROR_MESSAGE: `Falha ao obter as alertas inteligentes`,
  CLONE_SMART_TYPE_ERROR_MESSAGE: `Falha ao clonar as alertas inteligentes`,
  CLONE_SMART_ALERT_SUCCESS_MESSAGE: `Alerta inteligente clonada com sucesso`,
  CLONE_SMART_TEMPLATE_SUCCESS_MESSAGE: `Modelo de alerta inteligente clonada com sucesso`,
  DELETE_SMART_TYPES_SUCCESS_MESSAGE: `Alertas inteligentes excluídas com sucesso`,
  DELETE_SMART_TYPES_ERROR_MESSAGE: `Falha ao excluir as alertas inteligentes`,
  GET_SMART_ALERT_ERROR_MESSAGE: `Falha ao obter a alerta inteligente`,
  GET_SMART_TEMPLATE_ERROR_MESSAGE: `Falha ao obter o modelo de alerta inteligente`,
  UPSERT_SMART_TYPE_ERROR_MESSAGE: `Falha ao inserir ou atualizar as alertas inteligentes`,
  CREATE_SMART_ALERT_SUCCESS_MESSAGE: `Alerta Inteligente criado com sucesso`,
  CREATE_SMART_TEMPLATE_SUCCESS_MESSAGE: `Modelo de Alerta Inteligente criado com sucesso`,
  UPDATE_SMART_ALERT_SUCCESS_MESSAGE: `Alerta Inteligente atualizado com sucesso`,
  UPDATE_SMART_TEMPLATE_SUCCESS_MESSAGE: `Modelo de Alerta Inteligente atualizado com sucesso`,
});

export const EditSmartAlert = Object.freeze({
  CREATE_TITLE: `Criar alerta`,
  CREATE_CUSTOM_TITLE: `+ Criar seu alerta personalizado`,
  DESCRIPTION: `Crie seu alerta personalizado ou use modelos para criar alertas rapidamente`,
  EDIT_TITLE: `Editar alerta`,
  ICON: `Ícone`,
  NAME: `Nome`,
  NAME_PLACEHOLDER: `Digite o nome do alerta`,
  HIDE: `Ocultar`,
  HIDE_DESCRIPTION: `Não mostrar na lista de Smart Alerts`,
  ALERT: `Alerta`,
  TEMPLATE: `Modelo`,
  APPLY_TO: `Aplicar a`,
  SHARE_WITH: `Compartilhar com`,
  WORKSPACES: `Espaços de trabalho`,
  WHEN_TITLE: `Quando isso acontecer`,
  DO_THIS: `Faça isso`,
  PERSONALIZE: `Personalizar`,
  ADD_VARIABLE: `Adicionar variável`,
  SUBJECT: `Assunto`,
  BODY: `Corpo`,
  USE_TEMPLATE: `Usar modelo`,
  HOW_LONG_AFTER: `Quanto tempo depois`,
  IMMEDIATELY: `Imediatamente`,
  CUSTOM_NAME: `Nome personalizado`,
  TEMPLATE_DESCRIPTION_PLACEHOLDER: `Digite a descrição do modelo inteligente`,
  USE_ALERT: `Usar Alerta`,
  SELECT_SMART_TITLE: `Selecionar Alerta`,
  SELECT_ALERT_DESCRIPTION: `Selecione um alerta inteligente ou crie o seu próprio`,
  BOOKING_PAGES: `Páginas de reserva`,
  BOOKING_TEMPLATES: `Modelos de reserva`,
  SMS_TO_HOST_TOOLTIP: `Se o anfitrião não tiver o número de telefone, o lembrete por mensagem de texto não será enviado`,
  NEEDS_YOUR_ATTENTION: `Requer a sua atenção`,
});

export const SmartAlertsPersonalize = Object.freeze({
  EMAIL_TO_INVITE_SUBJECT: `Lembrete: &ltMeeting Name&gt com &ltHost Name&gt às &ltMeeting Time&gt, &ltMeeting Date&gt`,
  EMAIL_TO_INVITE_BODY: `Oi &ltInvitee Name&gt,<br><br>Este é um lembrete amigável de que sua &ltMeeting Name&gt com &ltHost Name&gt é às &ltMeeting Time&gt, &ltMeeting Date&gt.<br><br><b>Local</b><br>&ltMeeting Location&gt<br><br>&ltVer ou editar link da reunião&gt`,
  EMAIL_TO_HOST_SUBJECT: `Lembrete: &ltMeeting Name&gt com &ltInvitee Name&gt às &ltMeeting Time&gt, &ltMeeting Date&gt`,
  EMAIL_TO_HOST_BODY: `Oi &ltHost Name&gt,<br><br>Este é um lembrete amigável de que sua &ltMeeting Name&gt com &ltInvitee Name&gt é às &ltMeeting Time&gt, &ltMeeting Date&gt.<br><br><b>Local</b><br>&ltMeeting Location&gt<br><br>&ltVer ou editar link da reunião&gt`,
  SMS_TO_INVITE: `Este é um lembrete amigável de que sua &ltMeeting Name&gt com &ltHost Name&gt é às &ltMeeting Time&gt, &ltMeeting Date&gt. Ver ou editar reunião &ltMeeting Link&gt`,
  SMS_TO_HOST: `Este é um lembrete amigável de que sua &ltMeeting Name&gt com &ltInvitee Name&gt é às &ltMeeting Time&gt, &ltMeeting Date&gt. Ver ou editar reunião &ltMeeting Link&gt`,
  TITLE_EMAIL_TO_HOST: `Personalizar e-mail para o anfitrião`,
  TITLE_EMAIL_TO_INVITE: `Personalizar e-mail para o convidado`,
  TITLE_SMS_TO_HOST: `Personalizar lembrete por texto para o anfitrião`,
  TITLE_SMS_TO_INVITE: `Personalizar lembrete por texto para o convidado`,
});

export const SmartAlertsWhen = Object.freeze({
  HOW_LONG_EVENT_BOOKED: `Quanto tempo depois de reservar um novo evento?`,
  EVENT_ENDS: `O evento termina`,
  EVENT_BEFORE_START: `Antes do início do evento`,
  EVENT_CANCELLED: `O evento foi cancelado`,
  EVENTS_STARTS: `O evento começa`,
  EVENT_RESCHEDULED: `O evento foi reagendado`,
  HOW_LONG_EVENT_ENDS: `Quanto tempo depois que o evento termina?`,
  HOW_LONG_EVENT_BEFORE_START: `Quanto tempo antes do início do evento?`,
  HOW_LONG_EVENT_CANCELLED: `Quanto tempo depois que o evento é cancelado?`,
  HOW_LONG_EVENTS_STARTS: `Quanto tempo depois que o evento começa?`,
  HOW_LONG_EVENT_RESCHEDULED: `Quanto tempo depois que o evento é reagendado?`,
  MINUTE: `Minuto`,
  MINUTES: `Minutos`,
  HOUR: `Hora`,
  HOURS: `Horas`,
  DAY: `Dia`,
  DAYS: `Dias`,
  WHEN_EVENT_BOOKED: `Quando um novo evento é reservado`,
  WHEN_EVENT_STARTS: `Quando o evento começa`,
  WHEN_EVENT_ENDS: `Quando o evento termina`,
  WHEN_EVENT_CANCELED: `Quando o evento é cancelado`,
  WHEN_EVENT_RESCHEDULED: `Quando o evento é reagendado`,
  AFTER: `depois `,
});

export const SmartAlertsWhom = Object.freeze({
  EMAIL_TO_INVITEE: `Enviar e-mail para o convidado`,
  EMAIL_TO_HOST: `Enviar e-mail para o anfitrião`,
  SMS_TO_INVITEE: `Enviar lembrete por SMS para o convidado`,
  SMS_TO_HOST: `Enviar lembrete por SMS para o anfitrião`,
  SEND_ALL: `Enviar e-mail e lembrete por SMS para o convidado e o anfitrião`,
  SEND_EMAIL_SMS_INVITEE: `Enviar e-mail e lembrete por SMS para o convidado`,
  SEND_EMAIL_SMS_HOST: `Enviar e-mail e lembrete por SMS para o anfitrião`,
});

export const SmartAlertsWarningModal = Object.freeze({
  WARNING_BP_AND_BT: `Aviso: esta alteração afetará as próximas páginas e modelos de reserva:`,
  WARNING_BP: `Aviso: esta alteração afetará as próximas páginas de reserva:`,
  WARNING_BT: `Aviso: esta alteração afetará os próximos modelos de reserva:`,
  TITLE: `Aviso`,
  UPDATE_ALL: `Atualizar tudo`,
  CREATE_NEW: `Criar novo Alerta Inteligente`,
});
