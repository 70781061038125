import { checkTimeOverlapping, checkTimeWrongOrder, formatDateHHMM } from '../../../services/DateService';
import { TimeFromToInput, WeekDay, WeeklyHoursInput } from '../../../API';
import { ModifyWeeklyHours } from '../../../types/types';
import {
  DEFAULT_AVAILABILITY_TIME,
  WEEKDAY_LABEL_BY_TYPE,
  userAvailabilityActions,
  userAvailabilitySelectors,
} from '../../../store/availability';
import { TimeFormat } from '../../../store/userSettings/types';
import labels from './labels';
import { useDispatch, useSelector } from 'react-redux';
import { userSettingsSelectors } from '../../../store/userSettings';
import { BookingPageOption, bookingPageSelectors } from '../../../store/bookingPages';
import { InputSwitch } from 'primereact/inputswitch';
import { ErrorText } from '../../common/errorText/ErrorText';
import { AvailabilityTimeIntervals } from '../availabilityTimeIntervals/AvailabilityTimeIntervals';
import { MultiSelectWithChips } from '../../common/multiSelectWithChips/MultiSelectWithChips';

export const AvailabilityTable = () => {
  const dispatch = useDispatch();
  const weeklyHours = useSelector(userAvailabilitySelectors.selectWeeklyHours);
  const timeFormat = useSelector(userSettingsSelectors.selectTimeFormat) || TimeFormat.default;
  const activeBookingPages = useSelector(userAvailabilitySelectors.selectActiveBookingPages);
  const bookingPages = useSelector(bookingPageSelectors.selectBookingPagesOptions);
  const bookingPageIds = useSelector(bookingPageSelectors.selectBookingPageIds);
  const isEditAvail = useSelector(userSettingsSelectors.selectAvailabilityEdit);

  const handleBookingPageChange = (value: [string]) => {
    dispatch(userAvailabilityActions.updateUserAvailability({ activeBookingPages: value }));
  };

  const onRemoveBookingPage = (value: string) => {
    dispatch(
      userAvailabilityActions.updateUserAvailability({
        activeBookingPages: activeBookingPages?.filter((bookingPage) => bookingPage !== value),
      })
    );
  };

  const itemTemplate = (option: BookingPageOption) => {
    return (
      <div className="w-16rem flex justify-content-between gap-1">
        <div className="flex-1 overflow-hidden text-overflow-ellipsis">{option.label}</div>
        <div className="bg-gray-200 px-2 border-round">{option.displayId}</div>
      </div>
    );
  };
  const selectedItemContent = (value: string) => {
    const option = bookingPages.find((bookingPage) => bookingPage.value === value);
    return (
      <>
        {option?.label || ''} {option?.displayId || ''}
      </>
    );
  };

  const modifyWeeklyHours = (type: WeekDay, callback: ModifyWeeklyHours) => {
    dispatch(
      userAvailabilityActions.updateUserAvailability({
        weeklyHours:
          weeklyHours.map((day: WeeklyHoursInput | null) => (day?.type === type ? callback(day) : day)) || [],
      })
    );
  };

  const handleInputSwitch = (type: WeekDay, value: boolean) => {
    modifyWeeklyHours(type, (day: WeeklyHoursInput | null): WeeklyHoursInput | null => {
      if (!day) return null;

      return {
        ...day,
        time: value ? [DEFAULT_AVAILABILITY_TIME] : [],
      };
    });
  };

  const handleTimeFromChange = (type: WeekDay, index: number, value: Date) => {
    modifyWeeklyHours(type, (day: WeeklyHoursInput | null): WeeklyHoursInput | null => {
      if (!day) return null;

      return {
        ...day,
        time:
          day.time?.map((time: TimeFromToInput | null, i: number) => {
            if (!time) return null;
            return i === index ? { ...time, from: formatDateHHMM(value, TimeFormat.military) } : time;
          }) || [],
      };
    });
  };

  const handleTimeToChange = (type: WeekDay, index: number, value: Date) => {
    modifyWeeklyHours(type, (day: WeeklyHoursInput | null): WeeklyHoursInput | null => {
      if (!day) return null;
      const updatedDay = {
        ...day,
        time:
          day.time?.map((time: TimeFromToInput | null, i: number) => {
            if (!time) return null;
            return i === index ? { ...time, to: formatDateHHMM(value, TimeFormat.military) } : time;
          }) || [],
      };
      return updatedDay;
    });
  };

  const addInterval = (type: WeekDay) => {
    modifyWeeklyHours(type, (day: WeeklyHoursInput | null): WeeklyHoursInput | null => {
      if (!day) return null;

      return {
        ...day,
        time: [...(day.time || []), DEFAULT_AVAILABILITY_TIME],
      };
    });
  };

  const removeInterval = (type: WeekDay, index: number) => {
    modifyWeeklyHours(type, (day: WeeklyHoursInput | null): WeeklyHoursInput | null => {
      if (!day) return null;

      return {
        ...day,
        time: day.time?.filter((_: TimeFromToInput | null, i: number) => i !== index) || [],
      };
    });
  };

  return (
    <div className="grid justify-content-between p-20px -mb-2">
      <div className="col-12 lg:col-5">
        <div className="text-title-s-med text-heavy-100">{labels.availableHours}</div>
        <div className="mt-30px flex flex-column">
          {weeklyHours.map((day, idx, arr) => (
            <div
              key={day?.type}
              className={`flex flex-column gap-10px py-8px ${
                idx !== arr.length - 1 ? 'border-bottom-1 border-heavy-10' : ''
              }`}
            >
              <div className="flex flex-row align-items-start justify-content-between gap-10px">
                <div className="flex align-items-center my-6px">
                  <InputSwitch
                    checked={!!day?.time?.length}
                    className="size-small"
                    onChange={(e) => handleInputSwitch(day?.type as WeekDay, !!e.value)}
                    disabled={!isEditAvail}
                  />
                  <div className="ml-12px text-title-xs-med">{WEEKDAY_LABEL_BY_TYPE.get(day?.type || WeekDay.MON)}</div>
                </div>
                <AvailabilityTimeIntervals
                  time={day?.time}
                  isReadonly={!isEditAvail}
                  timeFormat={timeFormat}
                  addInterval={() => addInterval(day?.type as WeekDay)}
                  removeInterval={(index: number) => removeInterval(day?.type as WeekDay, index)}
                  handleTimeFromChange={(index: number, value: Date) =>
                    handleTimeFromChange(day?.type as WeekDay, index, value)
                  }
                  handleTimeToChange={(index: number, value: Date) =>
                    handleTimeToChange(day?.type as WeekDay, index, value)
                  }
                  isFirstIntervalRemovable={true}
                />
              </div>
              {day?.time && (checkTimeWrongOrder(day?.time) || checkTimeOverlapping(day?.time)) && (
                <div className="flex flex-column gap-2px">
                  {checkTimeWrongOrder(day?.time) && <ErrorText text={labels.timeInValidText} />}
                  {checkTimeOverlapping(day?.time) && <ErrorText text={labels.timeOverlappingText} />}
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
      <div className="col-12 lg:col-5">
        <div className="text-title-s-med text-heavy-100 mb-10px">{labels.activeOn}</div>
        <MultiSelectWithChips<BookingPageOption>
          value={activeBookingPages?.filter((id) => id && bookingPageIds.includes(id)) || []}
          options={bookingPages}
          onChange={(e) => handleBookingPageChange(e.value)}
          onRemove={onRemoveBookingPage}
          placeholder={labels.activeOnPlaceholder}
          disabled={!isEditAvail}
          className="w-full multiselect-wrap"
          itemTemplate={itemTemplate}
          selectedItemContent={selectedItemContent}
        />
      </div>
    </div>
  );
};
