import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '../../store/rootStore';
import {
  Coordinates,
  getAddressCoordinates,
  getCoordinatesByTimeZone,
  locationsActions,
  locationsSelectors,
} from '../../store/locations';
import { Preloader } from '../../components/common';
import { Button } from 'primereact/button';
import { ArrowLeftIcon } from '@heroicons/react/24/outline';
import labels from './labels';
import { navigationService } from '../../services/NavigationService';
import { Path } from '../../routing';
import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { InputText } from 'primereact/inputtext';
import { MAX_LENGTH_NAME } from '../../types/constants';
import { InputSwitch } from 'primereact/inputswitch';
import { GoogleMapSearch } from '../../components/common/GoogleMap/search/GoogleMapSearch';
import { GoogleMap } from '../../components/common/GoogleMap/map/GoogleMap';
import { Address, AddressInput } from '../../API';
import { AdvancedMarker, useMap } from '@vis.gl/react-google-maps';
import { ProgressSpinner } from 'primereact/progressspinner';

export const EditLocation = () => {
  const { locationId } = useParams();
  const dispatch = useDispatch<AppDispatch>();
  const isFetching = useSelector(locationsSelectors.selectIsFetching);
  const location = useSelector(locationsSelectors.selectLocation);

  const isNameValid = useSelector(locationsSelectors.selectIsLocationNameValid);
  const isLocationValid = useSelector(locationsSelectors.selectIsLocationValid);
  const isReadOnly = useSelector(locationsSelectors.selectLocationReadOnly);

  const [initialAddress, setInitialAddress] = useState<Address | null>(null);
  const [userLocation, setUserLocation] = useState<Coordinates | null>(null);

  const map = useMap();
  const geocoder = new google.maps.Geocoder();

  const handleBack = () => {
    navigationService.navigateTo(Path.Locations);
  };

  useEffect(() => {
    if (locationId) {
      dispatch(locationsActions.getLocationRequest(locationId));
      if (locationId === 'new') {
        getCoordinatesByTimeZone(geocoder, setUserLocation);
      }
    }
  }, []);

  useEffect(() => {
    if (initialAddress === null && location.address) {
      setInitialAddress(location.address);
    }
  }, [location.address]);

  useEffect(() => {
    if (userLocation && map) {
      map.panTo(userLocation);
    }
  }, [userLocation]);

  const handleSave = () => {
    dispatch(locationsActions.upsertLocationRequest());
  };

  const handleNameChange = (name: string) => {
    dispatch(locationsActions.updateLocation({ name }));
  };

  const handleActiveChange = (active: boolean) => {
    dispatch(locationsActions.updateLocation({ active }));
  };

  const onAddressSelect = (value: AddressInput) => {
    const address = { __typename: 'Address', ...value } as Address;
    dispatch(locationsActions.updateLocation({ address }));
  };

  return (
    <div>
      {isFetching && <Preloader />}
      <div className="flex-left-center -mt-24px mb-4px">
        <Button className="button-blue" text onClick={handleBack}>
          <ArrowLeftIcon width={16} height={16} className="mr-8px" />
          {labels.back}
        </Button>
      </div>
      <div className="sumo-card-bg flex flex-column p-24px">
        <div className="flex-1 text-title-xl-med">{labels.addLocation}</div>

        <div className="sumo-card p-20px pt-24px mt-16px">
          <div className="grid -mb-2">
            <div className="col-12 lg:col-6 flex flex-column gap-8px">
              <div className="text-title-xs-med">{labels.name}</div>
              <InputText
                value={location.name || ''}
                onChange={(e) => handleNameChange(e.target.value)}
                placeholder={labels.namePlaceholder}
                maxLength={MAX_LENGTH_NAME}
                className={`${!isNameValid ? 'p-invalid' : ''}`}
                disabled={isReadOnly}
              />
            </div>
            <div className="col-12 lg:col-6 flex-bottom">
              <div className="text-title-xs-med flex-left-center gap-12px h-fit py-10px">
                <InputSwitch
                  inputId="active"
                  checked={location.active || false}
                  className="size-small"
                  onChange={(e) => handleActiveChange(!!e.value)}
                  disabled={isReadOnly}
                />
                <label htmlFor="active" className="cursor-pointer">
                  {labels.active}
                </label>
              </div>
            </div>
            <div className="col-12 flex flex-column gap-8px">
              <div className="text-title-xs-med">{labels.address}</div>
              <GoogleMapSearch
                onAddressSelect={onAddressSelect}
                address={initialAddress}
                isReadOnly={isReadOnly}
                followLocation
              />
            </div>
            <div className="col-12 h-440px">
              {!userLocation && locationId === 'new' && (
                <div className="w-full h-full flex-center bg-heavy-1">
                  <ProgressSpinner />
                </div>
              )}
              <GoogleMap
                style={{ width: '100%', height: '100%' }}
                advancedMarkers={<AdvancedMarker position={getAddressCoordinates(location.address)} />}
                disableDefaultUI={true}
                className={`${!userLocation && locationId === 'new' ? 'hidden' : ''}`}
              />
            </div>
          </div>

          <div className="border-top-1 border-heavy-20 mt-16px flex-left-center gap-6px pt-16px -mb-4px -mx-22px px-22px">
            <Button
              className="button-xl flex-center"
              style={{ minWidth: '120px' }}
              onClick={handleSave}
              disabled={!isLocationValid || isReadOnly}
            >
              {labels.save}
            </Button>
            <Button className="button-xl" text onClick={handleBack}>
              {labels.cancel}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};
