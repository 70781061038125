import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { JourneyCard, JourneyFormField, JourneyFormFieldType, JourneyPageType } from '../../../API';
import {
  COMING_SOON,
  getDefaultFormFieldData,
  journeysActions,
  journeysSelectors,
  PAGE_INPUT_FIELDS,
  PAGE_SELECTION_FIELDS,
  PAGE_TITLE_FIELDS,
  PAGE_TYPE_OPTIONS,
} from '../../../store/journeyBuilder';
import labels from './labels';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'primereact/button';
import { PencilIcon, PlusIcon } from '@heroicons/react/24/outline';
import { OverlayPanel } from 'primereact/overlaypanel';
import { useEffect, useRef, useState } from 'react';
import { MAX_LENGTH_NAME } from '../../../types/constants';
import { AddPageFormFieldModal } from '../addPageFormFieldModal/AddPageFormFieldModal';
import { AddPageFormFieldCard } from '../addPageFormFieldCard/AddPageFormFieldCard';
import { AddPageCardModal } from '../addPageCardModal/AddPageCardModal';
import { AddPageCardCard } from '../addPageCardCard/AddPageCardCard';
import { v4 as uuid } from 'uuid';
import Sortable from 'sortablejs';
import { ComingSoonLabel } from '../../common';

type EditJourneyPageStepProps = {
  isValidate: boolean;
  isReadOnly: boolean;
};

export const EditJourneyPageStep = ({ isValidate, isReadOnly }: EditJourneyPageStepProps) => {
  const dispatch = useDispatch();
  const page = useSelector(journeysSelectors.selectJourneyPage);
  const pageName = useSelector(journeysSelectors.selectPageName);
  const pageType = useSelector(journeysSelectors.selectPageType);
  const fields = useSelector(journeysSelectors.selectPageFields);
  const cards = useSelector(journeysSelectors.selectPageCards);
  const hasElements = useSelector(journeysSelectors.selectPageHasElements);
  const nonDeletable = useSelector(journeysSelectors.selectAllNonDeletableFieldsCards);

  const formRef = useRef<OverlayPanel>(null);
  const editLabelRef = useRef<HTMLInputElement>(null);
  const list = useRef<HTMLDivElement>(null);

  const [isEditLabel, setIsEditLabel] = useState(false);
  const [isFieldModalOpen, setIsFieldModalOpen] = useState(false);
  const [isCardModalOpen, setIsCardModalOpen] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [formField, setFormField] = useState({} as JourneyFormField);
  const [card, setCard] = useState({} as JourneyCard);

  const handleNameChange = (name: string) => {
    dispatch(journeysActions.updatePageStep({ name }));
  };

  const handlePageTypeChange = (type: JourneyPageType) => {
    dispatch(journeysActions.updatePageStep({ type }));
  };

  const handlePageCardTitleChange = (cardTitle: string) => {
    dispatch(journeysActions.updatePageStep({ cardTitle }));
  };

  const handleEditLabel = () => {
    setIsEditLabel(true);
    editLabelRef.current?.focus();
  };
  const handleLabelChange = (submitLabel: string) => {
    dispatch(journeysActions.updatePageStep({ submitLabel }));
  };
  const handleLabelBlur = () => {
    setIsEditLabel(false);
  };

  const onSort = (e: Sortable.SortableEvent) => {
    if (pageType === JourneyPageType.FORM) {
      const newOrder = [...fields];
      const [movedItem] = newOrder.splice(e.oldIndex || 0, 1);
      newOrder.splice(e.newIndex || 0, 0, movedItem);
      dispatch(journeysActions.updatePageStep({ formFields: newOrder }));
    } else if (pageType === JourneyPageType.CARD) {
      const newOrder = [...cards];
      const [movedItem] = newOrder.splice(e.oldIndex || 0, 1);
      newOrder.splice(e.newIndex || 0, 0, movedItem);
      dispatch(journeysActions.updatePageStep({ cards: newOrder }));
    }
  };

  useEffect(() => {
    const sortable =
      list.current &&
      new Sortable(list.current, {
        handle: '.drag-and-drop',
        onSort: onSort,
      });

    return () => {
      sortable && sortable.destroy();
    };
  }, [fields, cards, pageType]);

  const handlePlusClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (pageType === JourneyPageType.FORM) {
      formRef?.current?.toggle(e);
    } else {
      setCard({ __typename: 'JourneyCard', id: uuid() });
      setIsEdit(false);
      setIsCardModalOpen(true);
    }
  };

  const handleFieldAdd = (e: React.MouseEvent<HTMLDivElement, MouseEvent>, type: JourneyFormFieldType) => {
    setFormField({ __typename: 'JourneyFormField', id: uuid(), type, ...getDefaultFormFieldData(type) });
    setIsEdit(false);
    setIsFieldModalOpen(true);
    formRef?.current?.toggle(e);
  };

  const handleEditField = (field: JourneyFormField) => {
    setFormField(field);
    setIsEdit(true);
    setIsFieldModalOpen(true);
  };

  const handleDeleteField = (field: JourneyFormField) => {
    dispatch(journeysActions.updatePageStep({ formFields: fields.filter((el) => el?.id !== field?.id) }));
  };

  const handleEditCard = (card: JourneyCard) => {
    setCard(card);
    setIsEdit(true);
    setIsCardModalOpen(true);
  };

  const handleDeleteCard = (card: JourneyCard) => {
    dispatch(journeysActions.updatePageStep({ cards: cards.filter((el) => el?.id !== card?.id) }));
  };

  const typeItemTemplate = (option: { value: string; label: string }) => {
    return (
      <div className="flex-center gap-12px">
        {option.label}
        {Object.keys(COMING_SOON).includes(option.value) && <ComingSoonLabel />}
      </div>
    );
  };

  return (
    <>
      <div className="flex mt-16px justify-content-between gap-20px">
        <div className="flex flex-column w-full gap-8px">
          <div className="text-title-xs-med text-heavy-100">{labels.name}</div>
          <InputText
            value={pageName}
            onChange={(e) => handleNameChange(e.target.value)}
            placeholder={labels.pageName}
            maxLength={MAX_LENGTH_NAME}
            className={`${isValidate && !pageName ? 'p-invalid' : ''}`}
            disabled={isReadOnly}
          />
        </div>
        <div className="flex flex-column w-200px gap-8px flex-shrink-0">
          <div className="text-title-xs-med text-heavy-100">{labels.pageType}</div>
          <Dropdown
            value={pageType}
            options={PAGE_TYPE_OPTIONS}
            optionDisabled={(option) => Object.keys(COMING_SOON).includes(option.value)}
            itemTemplate={typeItemTemplate}
            onChange={(e) => handlePageTypeChange(e.value)}
            placeholder={labels.select}
            className={`${isValidate && !pageType ? 'p-invalid' : ''}`}
            disabled={isReadOnly}
          />
        </div>
      </div>
      <div className="bg-heavy-20 h-1px mt-24px mb-40px -mx-32px px-32px" />

      {pageType === JourneyPageType.CARD && (
        <div className="flex flex-column gap-6px w-full mb-20px w-412px">
          <div className="text-heavy-100 text-title-xs-med">{labels.title}</div>
          <InputText
            value={page?.cardTitle || ''}
            onChange={(e) => handlePageCardTitleChange(e.target.value)}
            placeholder={labels.titlePlaceholder}
            maxLength={MAX_LENGTH_NAME}
            className={`w-full ${isValidate && !page?.cardTitle ? 'p-invalid' : ''}`}
            disabled={isReadOnly}
          />
        </div>
      )}

      {hasElements ? (
        <div className="h-max overflow-y-auto py-20px pl-32px -mx-32px">
          {pageType && (
            <div className="flex flex-column gap-16px w-412px" ref={list}>
              {pageType === JourneyPageType.FORM &&
                fields.map((field) =>
                  field ? (
                    <AddPageFormFieldCard
                      key={field?.label}
                      field={field}
                      handleDelete={() => handleDeleteField(field)}
                      handleEdit={() => handleEditField(field)}
                      isDeletable={!nonDeletable.includes(field.id || '')}
                      isReadOnly={isReadOnly}
                    />
                  ) : undefined
                )}

              {pageType === JourneyPageType.CARD &&
                cards.map((card) =>
                  card ? (
                    <AddPageCardCard
                      key={card?.title}
                      card={card}
                      handleDelete={() => handleDeleteCard(card)}
                      handleEdit={() => handleEditCard(card)}
                      isDeletable={!nonDeletable.includes(card.id || '')}
                      isReadOnly={isReadOnly}
                    />
                  ) : undefined
                )}
            </div>
          )}
        </div>
      ) : (
        <div className="w-412px text-label-md-reg text-heavy-100">{labels.noElements}</div>
      )}
      {!isReadOnly && (
        <Button
          className="button-add w-412px flex-center flex-shrink-0 mt-16px mb-20px text-label-md-reg"
          onClick={handlePlusClick}
          disabled={!pageType}
        >
          {!pageType ? labels.selectType : <PlusIcon width={14} height={14} />}
        </Button>
      )}

      {pageType === JourneyPageType.FORM && (
        <div className="flex-left-center gap-10px mb-20px">
          <Button className="button-xl h-48px w-fit flex-center pointer-events-none" style={{ minWidth: '120px' }}>
            <div className="absolute w-full">
              <InputText
                type="text"
                ref={editLabelRef}
                className={`w-full border-none shadow-none bg-transparent text-primary-white text-button-md-med text-center${
                  isEditLabel ? '' : ' opacity-0'
                }`}
                style={{ padding: '17px 14px' }}
                value={page?.submitLabel || ''}
                onChange={(e) => handleLabelChange(e.target.value.trimStart())}
                onBlur={handleLabelBlur}
                maxLength={MAX_LENGTH_NAME}
              />
            </div>
            <div className={`flex-left-center ${isEditLabel ? 'opacity-0' : ''}`}>{page?.submitLabel || ''}</div>
          </Button>
          {!isReadOnly && (
            <PencilIcon
              width={24}
              height={24}
              className="text-heavy-60 hover-text-heavy-100 cursor-pointer"
              onClick={handleEditLabel}
            />
          )}
        </div>
      )}

      <OverlayPanel ref={formRef} className="form-fields w-412px">
        <div className="text-body-s-reg text-primary-black mb-6px">{labels.title}</div>
        <div className="flex flex-row flex-wrap justify-content-between">
          {PAGE_TITLE_FIELDS.map((field) => (
            <div key={field.type} className="field-card" onClick={(e) => handleFieldAdd(e, field.type)}>
              <field.Icon width={20} height={20} className="text-blue-main" />
              <div className="text-label-md-reg text-heavy-blue-80">{field.label}</div>
            </div>
          ))}
        </div>
        <div className="text-body-s-reg text-primary-black mt-20px mb-6px">{labels.inputs}</div>
        <div className="flex flex-row flex-wrap justify-content-between gap-8px">
          {PAGE_INPUT_FIELDS.map((field) => (
            <div key={field.type} className="field-card" onClick={(e) => handleFieldAdd(e, field.type)}>
              <field.Icon width={20} height={20} className="text-blue-main" />
              <div className="text-label-md-reg text-heavy-blue-80">{field.label}</div>
            </div>
          ))}
        </div>
        <div className="text-body-s-reg text-primary-black mt-20px mb-6px">{labels.selection}</div>
        <div className="flex flex-row flex-wrap justify-content-between">
          {PAGE_SELECTION_FIELDS.map((field) => (
            <div key={field.type} className="field-card" onClick={(e) => handleFieldAdd(e, field.type)}>
              <field.Icon width={20} height={20} className="text-blue-main" />
              <div className="text-label-md-reg text-heavy-blue-80">{field.label}</div>
            </div>
          ))}
        </div>
      </OverlayPanel>

      <AddPageFormFieldModal
        visible={isFieldModalOpen}
        onHide={() => setIsFieldModalOpen(false)}
        field={formField}
        isEdit={isEdit}
      />
      <AddPageCardModal
        visible={isCardModalOpen}
        onHide={() => setIsCardModalOpen(false)}
        card={card}
        isEdit={isEdit}
      />
    </>
  );
};
