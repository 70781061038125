import { useEffect, useState } from 'react';
import { DURATION_CHANGE_THE_LOADING_TEXT } from '../types/constants';

export const useRotatingText = (texts: string[], rotate = false, interval = DURATION_CHANGE_THE_LOADING_TEXT) => {
  const [rotatingText, setRotatingText] = useState(texts[0]);
  const [, setIndex] = useState(0);

  useEffect(() => {
    if (!rotate) {
      setIndex(0);
      setRotatingText(texts[0]);
      return;
    }

    const intervalId = setInterval(() => {
      setIndex((prevIndex) => {
        if (prevIndex < texts.length - 1) {
          const newIndex = prevIndex + 1;
          setRotatingText(texts[newIndex]);
          return newIndex;
        } else {
          clearInterval(intervalId);
          return prevIndex;
        }
      });
    }, interval);

    return () => clearInterval(intervalId);
  }, [rotate, texts, interval]);

  return rotatingText;
};
