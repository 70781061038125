import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'primereact/button';
import { WorkspaceIntegrationType } from '../../../API';
import { ConnectIntegration } from '../connectIntegration/ConnectIntegration';
import labels from './labels';
import {
  ConnectFormIntegration,
  ConnectFormIntegrationSaveResponse,
} from '../connectFormIntegration/ConnectFormIntegration';
import { integrationActions, integrationSelectors } from '../../../store/integration';
import { authenticationSelectors } from '../../../store/authentication';

interface IGoogleAnalyticsIntegration {
  integrationType: WorkspaceIntegrationType;
  handleDisconnect: () => void;
}

export const GoogleAnalyticsIntegration = ({ integrationType, handleDisconnect }: IGoogleAnalyticsIntegration) => {
  const dispatch = useDispatch();

  const workspaceId = useSelector(authenticationSelectors.selectWorkspaceId);
  const isFetching = useSelector(integrationSelectors.selectIsFetching);
  const isError = useSelector(integrationSelectors.selectIsError);

  const [showForm, setShowForm] = useState(false);

  useEffect(() => {
    if (!isFetching && !isError) {
      toggleShowForm();
    }
  }, [isFetching]);

  const toggleShowForm = () => {
    setShowForm((prevState) => !prevState);
  };

  const handleSave = (dataForm: ConnectFormIntegrationSaveResponse) => {
    dispatch(
      integrationActions.connectWorkspaceIntegrationRequest({
        type: integrationType,
        workspaceId: workspaceId,
        customParameters: {
          trackingId: dataForm['trackingId']?.value,
          measurementId: dataForm['measurementId']?.value,
        },
      })
    );
  };

  const connectButton = () => {
    return <Button className="button-xl ml-20px" onClick={toggleShowForm} label={labels.connect} />;
  };

  if (showForm) {
    return (
      <ConnectFormIntegration
        integrationType={integrationType}
        handleSave={handleSave}
        handleCancel={toggleShowForm}
        contentForm={[
          {
            type: 'input',
            uniqueName: 'trackingId',
            placeholder: labels.trackingIdPlaceholder,
            visibleName: labels.trackingIdVisibleName,
          },
          {
            type: 'input',
            uniqueName: 'measurementId',
            placeholder: labels.measurementIdPlaceholder,
            visibleName: labels.measurementIdVisibleName,
          },
        ]}
      />
    );
  } else {
    return (
      <ConnectIntegration
        integrationType={integrationType}
        handleDisconnect={handleDisconnect}
        ConnectButton={connectButton}
      />
    );
  }
};
