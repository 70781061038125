import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import { TeamsActionTypes, teamsActions } from './actions';
import { authenticationSelectors } from '../authentication';
import { deleteTeam, getTeams, saveTeam } from './service';
import { teamsSelectors } from './selectors';
import { notificationsActions } from '../notifications';
import {
  GET_TEAMS_FAIL_TOAST,
  SAVE_TEAM_SUCCESS_TOAST,
  SAVE_TEAM_ERROR_TOAST,
  DELETE_TEAM_SUCCESS_TOAST,
  DELETE_TEAM_ERROR_TOAST,
  ACTIVATE_TEAM_SUCCESS_TOAST,
  DEACTIVATE_TEAM_SUCCESS_TOAST,
  DEACTIVATE_ACTIVATE_TEAM_ERROR_TOAST,
} from './constants';
import { CreateTeamInput } from '../../API';
import { handleServiceError } from '../utils/reduxUtils';
import { editTeamModalActions } from './modal';
import { createSelector } from 'reselect';
import { GetTeamsResponse, TeamRecord } from './types';
import { usersSaga } from '../users/sagas';
import { workspacesSaga } from '../workspaces/sagas';
import { userSettingsSelectors } from '../userSettings';
import { usersSelectors } from '../users';

const selectTeamUpdateRequest = createSelector(teamsSelectors.selectSelectedTeam, (team) => ({
  id: team.id,
  workspaceId: team.workspaceId,
  name: team.name,
  active: team.active,
  workspacesForRead: team.workspacesForRead,
  members: team.members,
  assignmentMode: team.assignmentMode,
  rescheduleWith: team.rescheduleWith,
  createdBy: team.createdBy,
  createdAt: team.createdAt,
}));

function* getTeamsSaga() {
  try {
    const workspaceId: string = yield select(authenticationSelectors.selectWorkspaceId);
    const teamsResponse: GetTeamsResponse = yield call(getTeams, workspaceId);

    if (!teamsResponse) {
      throw new Error('Teams not found');
    }

    const teams: TeamRecord[] = [
      ...teamsResponse.createdTeams,
      ...teamsResponse.teamsForRead.map((team) => ({ ...team, readOnly: true })),
    ];
    yield put(teamsActions.getTeamsSuccess(teams));
  } catch (error: unknown) {
    yield put(teamsActions.getTeamsFail(error?.toString()));
    yield call(handleServiceError, error, GET_TEAMS_FAIL_TOAST, false);
  }
}

function* getTeamsPageSaga() {
  try {
    const workspaceId: string = yield select(authenticationSelectors.selectWorkspaceId);
    const isSuperAdmin: boolean = yield select(userSettingsSelectors.selectIsSuperAdmin);
    const isPermissionToWorkspaceREAD: boolean = yield select(userSettingsSelectors.selectWorkspacesRead);

    const requestsList = [call(getTeams, workspaceId), call(usersSaga.getUsers)];

    if (isSuperAdmin && isPermissionToWorkspaceREAD) {
      requestsList.push(call(workspacesSaga.getWorkspaces));
    }

    const [teamsResponse]: [GetTeamsResponse] = yield all(requestsList);
    const teams: TeamRecord[] = [
      ...teamsResponse.createdTeams,
      ...teamsResponse.teamsForRead.map((team) => ({ ...team, readOnly: true })),
    ];
    yield put(teamsActions.getTeamsSuccess(teams));
  } catch (error: unknown) {
    yield put(teamsActions.getTeamsFail(error?.toString()));
    yield call(handleServiceError, error, GET_TEAMS_FAIL_TOAST, true);
  }
}

function* saveTeamSaga() {
  try {
    const team: CreateTeamInput = yield select(selectTeamUpdateRequest);
    yield call(saveTeam, team);

    const isDefaultCreatedByFilter: boolean = yield select(teamsSelectors.selectIsDefaultCreatedByFilter);
    if (!team.id && isDefaultCreatedByFilter) {
      // add current user to createdBy filter
      const createdByFilter: string[] = yield select(teamsSelectors.selectFilterCreatedBy);
      const userName: string = yield select(usersSelectors.selectCurrentUserNameOrEmail);
      // if he is not in the list
      if (!createdByFilter.includes(userName)) {
        yield put(teamsActions.setFilter({ createdBy: [...createdByFilter, userName] }));
      }
    }

    yield put(editTeamModalActions.closeModal());
    yield put(teamsActions.saveTeamSuccess());
    yield put(teamsActions.getTeamsRequest());
    yield put(notificationsActions.showToast(SAVE_TEAM_SUCCESS_TOAST));
  } catch (error: unknown) {
    yield put(teamsActions.saveTeamFail(error?.toString()));
    yield call(handleServiceError, error, SAVE_TEAM_ERROR_TOAST);
  }
}

function* deleteTeamSaga() {
  try {
    const teamId: string | undefined = yield select(teamsSelectors.selectTeamId);
    const teams: TeamRecord[] = yield select(teamsSelectors.selectTeams);
    const teamCreatedBy: string = yield select(teamsSelectors.selectTeamCreatedBy);
    if (teamId) {
      yield call(deleteTeam, teamId);
      // if there is no other records with this createdBy
      if (!teams.find((team) => team.createdBy === teamCreatedBy && team.id !== teamId)) {
        // delete createdBy value from filter
        const createdByFilter: string[] = yield select(teamsSelectors.selectFilterCreatedBy);
        yield put(teamsActions.setFilter({ createdBy: createdByFilter.filter((name) => name !== teamCreatedBy) }));
      }

      yield put(teamsActions.deleteTeamSuccess());
      yield put(teamsActions.getTeamsRequest());
      yield put(notificationsActions.showToast(DELETE_TEAM_SUCCESS_TOAST));
    }
  } catch (error: unknown) {
    yield put(teamsActions.deleteTeamFail(error?.toString()));
    yield call(handleServiceError, error, DELETE_TEAM_ERROR_TOAST);
  }
}

function* deactivateTeamSaga() {
  try {
    const team: CreateTeamInput = yield select(selectTeamUpdateRequest);
    const active = !team.active;
    yield call(saveTeam, { ...team, active });

    yield put(teamsActions.deactivateTeamSuccess());
    yield put(teamsActions.getTeamsRequest());
    yield put(notificationsActions.showToast(active ? ACTIVATE_TEAM_SUCCESS_TOAST : DEACTIVATE_TEAM_SUCCESS_TOAST));
  } catch (error: unknown) {
    yield put(teamsActions.deactivateTeamFail(error?.toString()));
    yield call(handleServiceError, error, DEACTIVATE_ACTIVATE_TEAM_ERROR_TOAST);
  }
}

export function* watchTeamsSaga() {
  yield takeLatest(TeamsActionTypes.GET_TEAMS_REQUEST, getTeamsSaga);
  yield takeLatest(TeamsActionTypes.GET_TEAMS_PAGE_REQUEST, getTeamsPageSaga);
  yield takeLatest(TeamsActionTypes.SAVE_TEAM_REQUEST, saveTeamSaga);
  yield takeLatest(TeamsActionTypes.DELETE_TEAM_REQUEST, deleteTeamSaga);
  yield takeLatest(TeamsActionTypes.DEACTIVATE_TEAM_REQUEST, deactivateTeamSaga);
}

export const teamsSaga = {
  getTeams: getTeamsSaga,
};
