export const AuthenticationToastsNotifications = Object.freeze({
  CHOOSE_AN_ACCOUNT_ERROR_TOAST: `Fehler bei der Anforderung zur Auswahl des Kontos`,
  DELETE_USER_ERROR_MESSAGE: `Fehler bei der Anforderung zur Löschung des Benutzers`,
  DELETE_USER_SUCCESS_MESSAGE: `Erfolgreiche Anfrage zur Löschung des Benutzers`,
  GET_USER_AUTH_ERROR_MESSAGE: `Fehler bei der Anforderung zur Benutzeranmeldung`,
  GET_USER_LOGIN_ERROR_MESSAGE: `Kein Benutzer mit dieser E-Mail gefunden`,
  GET_USER_LOGOUT_ERROR_MESSAGE: `Fehler bei der Anforderung zur Benutzerabmeldung`,
  GET_MAIN_DATA_ERROR_MESSAGE: `Authentifizierungsanforderung fehlgeschlagen`,
  ROLE_DEACTIVATED_ERROR_MESSAGE: `Die Rolle wurde deaktiviert. Bitte kontaktieren Sie Ihren SUMO1-Administrator.`,
  USER_DEACTIVATED_ERROR_MESSAGE: `Der Benutzer wurde deaktiviert. Bitte kontaktieren Sie Ihren SUMO1-Administrator.`,
  TRIAL_LIMIT_ERROR_MESSAGE: `Sie haben das Limit von 30 Elementen für die Testversion überschritten`,
  INVITE_EXPIRED_ERROR_TITLE: `Einladung abgelaufen`,
  INVITE_EXPIRED_ERROR_MESSAGE_PART1: `Ihre Einladung ist abgelaufen. Bitte kontaktieren Sie Ihren Administrator, um einen neuen Link zu erhalten, oder versuchen Sie, `,
  INVITE_EXPIRED_ERROR_MESSAGE_PART2: `sich ohne Einladung anzumelden`,
  SWITCH_ACCOUNT_ERROR_MESSAGE: `Ein anderes Konto ist bereits mit dieser E-Mail verknüpft`,
});

export const Authorise = Object.freeze({
  SING_IN_TITLE_LINE_1: `Willkommen`,
  SING_IN_TITLE_LINE_2: `zurück bei SUMO`,
  SING_IN_SUBTITLE: `Login zu Ihrem Planungszentrum.`,
  SING_IN_GOOGLE: `Mit Google anmelden`,
  SING_IN_MICROSOFT: `Mit Microsoft anmelden`,
  SING_IN_DONT_HAVE_AN_ACCOUNT: `Sie haben kein Konto?`,
  SIGN_UP_COMMENT: `*SUMO1 erfordert keine Salesforce-Lizenz.`,
  SING_UP_TITLE_LINE_1: `Online-Terminplanung`,
  SING_UP_TITLE_LINE_2: `und mehr`,
  SING_UP_SUBTITLE: `Beginnen Sie mit der kostenlosen Nutzung des Dienstes mit nur einem Klick.`,
  SING_UP_GOOGLE: `Mit Google registrieren`,
  SING_UP_MICROSOFT: `Mit Microsoft registrieren`,
  SIGN_UP_FREE_TRIAL: `15 Tage kostenlos testen. Keine Kreditkarte erforderlich`,
  SING_UP_HAVE_AN_ACCOUNT: `Haben Sie bereits ein Konto?`,
  LOOKING_FOR_SUMO_CEP: `Suchen Sie nach SUMO für Salesforce?`,
  LOADING_LABEL_1: `Benutzer autorisieren`,
  LOADING_LABEL_2: `Kalender überprüfen`,
  LOADING_LABEL_3: `Token erstellen`,
});

export const DialogLeaveAccount = Object.freeze({
  ACCEPT_TEXT: 'Möchten Sie diese Einladung annehmen und das andere SUMO1-Konto verlassen?',
  INVITE_TEXT_PART_1: `Sie wurden von`,
  INVITE_TEXT_PART_2: `eingeladen, SUMO1 beizutreten, aber Ihre E-Mail ist bereits mit einem anderen SUMO1-Konto verknüpft.`,
  LAST_SUPER_ADMIN_NOTE: `Sie sind der einzige Super-Admin in der Organisation. Sie müssen sich zunächst bei Ihrer bestehenden Organisation anmelden und die Rolle "Super-Admin" auf einen anderen Benutzer übertragen, bevor Sie diese Einladung annehmen. Kontaktieren Sie den SUMO-Support für Unterstützung.`,
  TITLE: `Konto verlassen`,
});

export const DialogUserAccounts = Object.freeze({
  ACCEPT_TEXT: `Möchten Sie diese Einladung annehmen und das andere SUMO1-Konto verlassen?`,
  INVITE_FROM: `Einladung von:`,
  INVITE_TEXT: `Sie wurden eingeladen, SUMO1 beizutreten, versuchen jedoch, sich für ein neues SUMO1-Konto anzumelden.`,
  SELECT_TEXT: `Wählen Sie unten aus, wenn Sie diese Einladung annehmen möchten, anstatt ein neues Unternehmenskonto für SUMO1 zu erstellen.`,
  STAY_IN_MY_ACCOUNT: `In meinem Konto bleiben`,
  TITLE: `Einladung annehmen`,
});
