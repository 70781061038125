import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { CANCEL_EVENT_MODAL_NAME, cancelEventModalActions } from '../../../store/bookedMeetings/modal';
import { Modal } from '../../common';
import { Button } from 'primereact/button';
import { useSelector } from 'react-redux';
import { scheduledMeetingsSelectors } from '../../../store/bookedMeetings/selectors';
import labels from './labels';
import { scheduledMeetingsActions, CANCEL_TYPE_OPTIONS } from '../../../store/bookedMeetings';
import { CanceledType } from '../../../API';
import { Dropdown } from 'primereact/dropdown';
import { InputTextarea } from 'primereact/inputtextarea';
import { MAX_LENGTH_DESCRIPTION } from '../../../types/constants';

export const CancelBookedMeetingModal = () => {
  const dispatch = useDispatch();
  const cancelType = useSelector(scheduledMeetingsSelectors.selectEventCancelType);
  const cancelNote = useSelector(scheduledMeetingsSelectors.selectEventCancelNote);
  const [localNote, setLocalNote] = useState(cancelNote || '');

  const handleClose = () => {
    setLocalNote('');
    dispatch(cancelEventModalActions.closeModal());
  };

  const handleCancel = () => {
    dispatch(scheduledMeetingsActions.cancelBookedMeetingRequest());
    handleClose();
  };

  const handleChangeType = (type: CanceledType) => {
    dispatch(scheduledMeetingsActions.updateCancelReason(type));
  };

  const handleChangeNote = (note: string) => {
    setLocalNote(note);
  };

  const handleBlurNote = () => {
    dispatch(scheduledMeetingsActions.updateCancelNote(localNote));
  };

  return (
    <Modal.Container name={CANCEL_EVENT_MODAL_NAME} className="w-460px">
      <Modal.Header>
        <div>{labels.cancelTitle}</div>
      </Modal.Header>

      <div className="flex flex-column gap-28px p-fluid">
        <div>
          <div className="text-title-xs-med pb-10px">{labels.cancelDesc}</div>
          <Dropdown
            value={cancelType}
            onChange={(e) => handleChangeType(e.value as CanceledType)}
            options={CANCEL_TYPE_OPTIONS}
          />
        </div>
        <div>
          <div className="text-title-xs-med pb-10px">{labels.cancelReason}</div>
          <InputTextarea
            autoResize
            rows={4}
            value={localNote || ''}
            onChange={(e) => handleChangeNote(e.target.value)}
            onBlur={handleBlurNote}
            maxLength={MAX_LENGTH_DESCRIPTION}
          />
        </div>
      </div>

      <Modal.Buttons>
        {/* <Button label={labels.cancelTitle} onClick={handleCancel} severity="danger" /> */}
        <Button label={labels.cancelTitle} onClick={handleCancel} className="button-tomato" />
        <Button label={labels.close} onClick={handleClose} text />
      </Modal.Buttons>
    </Modal.Container>
  );
};
