import { Dropdown, DropdownChangeEvent } from 'primereact/dropdown';
import labels from './labels';
import { MultiSelect } from 'primereact/multiselect';
import { CreateAdminDataInput, UpdateBookingTemplateInput } from '../../../API';
import { useSelector } from 'react-redux';
import { workspacesSelectors } from '../../../store/workspaces';
import { userSettingsSelectors } from '../../../store/userSettings';
import { rolesSelectors } from '../../../store/roles';
import { Dispatch, SetStateAction, useEffect } from 'react';
import { usersSelectors } from '../../../store/users';
import { authenticationSelectors } from '../../../store/authentication';
import { TeamRecord } from '../../../store/teams';

type InviteDialogOptionsProps = {
  formData: CreateAdminDataInput;
  setFormData: Dispatch<SetStateAction<CreateAdminDataInput>>;
  handleChange: (event: React.ChangeEvent<HTMLTextAreaElement> | DropdownChangeEvent) => void;
};
export const InviteDialogOptions = ({ formData, setFormData, handleChange }: InviteDialogOptionsProps) => {
  const workspaceOptions = useSelector(workspacesSelectors.selectWorkspaceOptions);
  const isSuperAdmin = useSelector(userSettingsSelectors.selectIsSuperAdmin);
  const supportedRoles = useSelector(userSettingsSelectors.selectSupportedRoles);
  const superSuperAdminIds = useSelector(rolesSelectors.selectSuperAdminIds);
  const userWorkspace = useSelector(authenticationSelectors.selectWorkspaceId);
  const userWorkspaces = useSelector(userSettingsSelectors.selectUserWorkspaces) || [];
  const bookingTemplates = useSelector(
    usersSelectors.selectBookingTemplateOptionsByWorkspaces(formData.workspaceIds || [])
  );
  const teamList = useSelector(usersSelectors.selectTeamsOptionsByWorkspaces(formData.workspaceIds || []));

  useEffect(() => {
    // when we only have one workspace, we don't show the workspace input, just pre-populate the first one.
    if (userWorkspaces.length === 1) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        workspaceIds: [workspaceOptions[0].id],
      }));
    }
    // if we have more
    if (userWorkspaces.length > 1) {
      // checking all Workspaces if roleId is superSuperAdminId
      if (superSuperAdminIds.includes(formData.roleId)) {
        setFormData((prevFormData) => ({
          ...prevFormData,
          workspaceIds: workspaceOptions.map((option) => option.id),
        }));
      }
      // if we don't select if before set the current workspace
      if (!formData.workspaceIds?.length && !superSuperAdminIds.includes(formData.roleId)) {
        setFormData((prevFormData) => ({
          ...prevFormData,
          workspaceIds: [userWorkspace],
        }));
      }
    }
  }, [formData.roleId, superSuperAdminIds, workspaceOptions, userWorkspaces]);

  const handleTemplatesChange = (selectedWorkspaceIds: string[]) => {
    // filter all selected templates according to new list of workspaces
    const listTemplates: UpdateBookingTemplateInput[] = [];
    if (bookingTemplates) {
      for (const option of bookingTemplates) {
        if (selectedWorkspaceIds.includes(option.workspaceId)) {
          listTemplates.push(...option.bookingTemplates);
        }
      }
      const actualTemplates = formData.bookingTemplateIds?.filter((id) =>
        listTemplates.some((template) => template.id === id)
      );
      setFormData((prev) => ({ ...prev, bookingTemplateIds: actualTemplates }));
    }
  };

  const handleTeamsChange = (selectedWorkspaceIds: string[]) => {
    // filter all selected teams according to new list of workspaces
    const teams: TeamRecord[] = [];
    if (teamList) {
      for (const option of teamList) {
        if (selectedWorkspaceIds.includes(option.workspaceId)) {
          teams.push(...option.teams);
        }
      }
      const actualTeams = formData.team?.filter((id) => teams.some((team) => team.id === id));
      setFormData((prev) => ({ ...prev, team: actualTeams }));
    }
  };

  const handleWorkspaceChange = (e: DropdownChangeEvent) => {
    if (!superSuperAdminIds.includes(formData.roleId)) {
      handleTemplatesChange(e.target.value);
      handleTeamsChange(e.target.value);
      handleChange(e);
    } else {
      // Prevent unchecking if roleId is superSuperAdminId
      const currentValues = formData.workspaceIds ?? [];
      const newValue = e.value;
      if (newValue.length < currentValues.length) {
        e.preventDefault();
      } else {
        handleTemplatesChange(e.target.value);
        handleTeamsChange(e.target.value);
        handleChange(e);
      }
    }
  };

  return (
    <div className="flex flex-column gap-24px">
      {(userWorkspaces?.length || 0) > 1 && (
        <div className="flex flex-column gap-10px">
          <div className="text-title-xs-med">{labels.assignWorkspace}</div>
          <MultiSelect
            name="workspaceIds"
            options={
              isSuperAdmin ? workspaceOptions : workspaceOptions.filter((option) => userWorkspaces.includes(option.id))
            }
            optionLabel="name"
            optionValue="id"
            value={formData.workspaceIds}
            onChange={handleWorkspaceChange}
            display="chip"
            filter
            className={`${!formData.workspaceIds?.length && 'p-invalid'}`}
          />
          {superSuperAdminIds.includes(formData.roleId) && (
            <div className="m-1">{labels.superAdminAccessDescription}</div>
          )}
        </div>
      )}
      <div className="flex flex-column gap-10px">
        <div className="text-title-xs-med">{labels.assignRole}</div>
        <Dropdown
          name="roleId"
          value={formData.roleId}
          options={supportedRoles}
          optionLabel="name"
          optionValue="id"
          onChange={handleChange}
          className={`${!formData.roleId && 'p-invalid'}`}
        />
      </div>
      <div className="flex flex-column gap-10px">
        <div className="flex-between-center">
          <div className="text-title-xs-med">{labels.addToTeam}</div>
          <div className="text-label-xs-reg text-heavy-50">{labels.optional}</div>
        </div>
        {teamList ? (
          <MultiSelect
            name="team"
            value={formData.team}
            options={teamList}
            optionLabel="name"
            optionValue="id"
            optionGroupLabel="label"
            optionGroupChildren="teams"
            onChange={handleChange}
            placeholder={labels.addToTeamPlaceholder}
            display="chip"
            filter
            disabled={!formData.workspaceIds?.length || !teamList.length}
          />
        ) : (
          /* show spinner during loading the data */
          <div className=" flex-left-center h-40px pl-12px border-1 border-heavy-40 border-radius-8px">
            <span className="pi pi-spinner pi-spin text-heavy-50" />
          </div>
        )}
      </div>
      <div className="flex flex-column gap-10px">
        <div className="flex-between-center">
          <div className="text-title-xs-med">{labels.assignBookingTemplates}</div>
          <div className="text-label-xs-reg text-heavy-50">{labels.optional}</div>
        </div>
        {bookingTemplates ? (
          <MultiSelect
            name="bookingTemplateIds"
            value={formData.bookingTemplateIds}
            options={bookingTemplates}
            optionLabel="what.customName"
            optionValue="id"
            optionGroupLabel="label"
            optionGroupChildren="bookingTemplates"
            onChange={handleChange}
            placeholder={labels.assignBookingTemplatesPlaceholder}
            display="chip"
            filter
            disabled={!formData.workspaceIds?.length || !bookingTemplates.length}
          />
        ) : (
          /* show spinner during loading the data */
          <div className=" flex-left-center h-40px pl-12px border-1 border-heavy-40 border-radius-8px">
            <span className="pi pi-spinner pi-spin text-heavy-50" />
          </div>
        )}
      </div>
    </div>
  );
};
