import { API_PUBLIC } from '../../types/constants';
import { handleAPIRequest } from '../utils/reduxUtils';
import { GetListUserDataByUserIdResponse, UserDataInputCreatedAt } from './types';

// ***
// When you change the requests do not forget to specify new data
// in docs/Endpoints.md
// ***

export const getUserDataById = async (): Promise<UserDataInputCreatedAt[]> => {
  const response = await handleAPIRequest<GetListUserDataByUserIdResponse>(`${API_PUBLIC}/getListUserDataByUserId`, {});
  return response.userData;
};
