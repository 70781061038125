import { useDispatch } from 'react-redux';
import labels from './labels';
import { useSelector } from 'react-redux';
import { bookingPageSelectors, bookingPagesActions } from '../../../store/bookingPages';
import { MemberInput, MemberType } from '../../../API';
import { userSettingsSelectors } from '../../../store/userSettings';
import { authenticationSelectors } from '../../../store/authentication';
import { HostOption, MemberSubType } from '../../../types/types';
import { HostCard } from '../../common/hostCard/HostCard';
import { MultiSelectChangeEvent } from 'primereact/multiselect';
import { SumoMultiSelect } from '../../common';

export const BookingPageWhoStep = () => {
  const dispatch = useDispatch();
  const hostAndGuests = useSelector(bookingPageSelectors.selectHostAndGuestsData);
  const potentialHostsAndTeams = useSelector(bookingPageSelectors.selectPotentialHostsAndTeams);
  const isUserRequiredHost = useSelector(bookingPageSelectors.selectIsUserRequiredHost);
  const userId = useSelector(authenticationSelectors.selectUserId);
  const isBookingPageLocked = useSelector(bookingPageSelectors.selectIsBookingPageLocked());
  const isTeamRead = useSelector(userSettingsSelectors.selectTeamsRead);

  const handleHostsAndGuestsChange = (e: MultiSelectChangeEvent) => {
    const selectedValues: string[] = e.value;
    updateMembers(potentialHostsAndTeams.filter((member) => selectedValues.includes(member.value)));
  };

  const handleSelectAllChange = (checked: boolean) => {
    updateMembers(checked ? potentialHostsAndTeams : []);
  };

  const handleRemoveMember = (memberId: string) => {
    updateMembers(hostAndGuests.filter((member) => member.value !== memberId));
  };

  const handleMakeMainHost = (memberId: string) => {
    const newMainHost = hostAndGuests.find((member) => member.value === memberId);
    const newCoHosts = hostAndGuests.filter((member) => member.value !== memberId);

    if (newMainHost) {
      updateMembers([newMainHost, ...newCoHosts]);
    }
  };

  const updateMembers = (members: HostOption[]) => {
    const newMembers = members.map(
      (member) =>
        ({
          id: member.value,
          type: MemberType.SUMO1,
          subType: member.isTeam ? MemberSubType.TEAM : MemberSubType.USER,
        } as MemberInput)
    );
    dispatch(
      bookingPagesActions.updateWhoStep({
        hostMembers: [...newMembers.slice(0, 1)],
        guests: [...newMembers.slice(1)],
      })
    );

    const previousHostId = hostAndGuests[0] && hostAndGuests[0].value;
    const newHostId = members[0] && members[0].value;
    if (newHostId && newHostId !== previousHostId) {
      dispatch(
        bookingPagesActions.updateWhereStep({
          videoConferenceType: members[0].defaultVideoIntegration,
        })
      );
    }
  };

  return (
    <div className="flex flex-column gap-16px">
      <div className="flex flex-column gap-4px">
        <div className="text-title-xs-med text-heavy-100">{labels.hostsAndTeams}</div>
        <div className="text-body-s-reg text-heavy-60">{labels.hostsDescription}</div>
      </div>

      <div className="flex flex-wrap -mx-20px -my-6px">
        <div className="w-6 px-20px py-6px">
          <SumoMultiSelect
            className="pr-24px"
            onSelectAllChange={handleSelectAllChange}
            options={potentialHostsAndTeams}
            value={hostAndGuests.map((member) => member.value)}
            onChange={handleHostsAndGuestsChange}
            filterPlaceholder={labels.searchPlaceholder}
            disabled={isBookingPageLocked}
            templateType="host"
          />
        </div>
        <div className="w-6 px-20px py-6px"></div>
        {hostAndGuests.map((host, index) => (
          <div key={host.value} className="w-6 px-20px py-6px">
            <HostCard
              host={host}
              onRemove={
                !isBookingPageLocked && !(isUserRequiredHost && host.value === userId) && (!host.isTeam || isTeamRead)
                  ? handleRemoveMember
                  : undefined
              }
              isMainHost={index === 0}
              onMakeMainHost={handleMakeMainHost}
            />
          </div>
        ))}
      </div>
    </div>
  );
};
