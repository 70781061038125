import { ChargeeBeeResponse, ItemPrices, Subscription } from './types';

export enum BillingActionTypes {
  GET_BILLING_REQUEST = 'billing/GET_BILLING_REQUEST',
  GET_BILLING_SUCCESS = 'billing/GET_BILLING_SUCCESS',
  GET_BILLING_FAIL = 'billing/GET_BILLING_FAIL',
  CHANGE_SEATS_REQUEST = 'billing/CHANGE_SEATS_REQUEST',
  CHANGE_SEATS_SUCCESS = 'billing/CHANGE_SEATS_SUCCESS',
  CHANGE_SEATS_FAIL = 'billing/CHANGE_SEATS_FAIL',
  CHANGE_TERM_REQUEST = 'billing/CHANGE_TERM_REQUEST',
  CHANGE_TERM_SUCCESS = 'billing/CHANGE_TERM_SUCCESS',
  CHANGE_TERM_FAIL = 'billing/CHANGE_TERM_FAIL',
  DELETE_SCHEDULED_CHANGE_REQUEST = 'billing/DELETE_SCHEDULED_CHANGE_REQUEST',
  DELETE_SCHEDULED_CHANGE_SUCCESS = 'billing/DELETE_SCHEDULED_CHANGE_SUCCESS',
  DELETE_SCHEDULED_CHANGE_FAIL = 'billing/DELETE_SCHEDULED_CHANGE_FAIL',
  SET_ADD_OR_REMOVE_SEATS = 'billing/SET_ADD_OR_REMOVE_SEATS',
  SET_CHANGE_SEATS = 'billing/SET_CHANGE_SEATS',
  SET_TERM = 'billing/SET_TERM',
  GET_ITEM_PRICES_REQUEST = 'billing/GET_ITEM_PRICES_REQUEST',
  GET_ITEM_PRICES_SUCCESS = 'billing/GET_ITEM_PRICES_SUCCESS',
  GET_ITEM_PRICES_FAIL = 'billing/GET_ITEM_PRICES_FAIL',
  REFRESH_TRIAL_LICENSE_REQUEST = 'authentication/REFRESH_TRIAL_LICENSE_REQUEST',
  REFRESH_TRIAL_LICENSE_SUCCESS = 'authentication/REFRESH_TRIAL_LICENSE_SUCCESS',
}

export type BillingAction =
  | { type: BillingActionTypes.GET_BILLING_REQUEST }
  | { type: BillingActionTypes.GET_BILLING_SUCCESS; payload: Subscription }
  | { type: BillingActionTypes.GET_BILLING_FAIL; error: string }
  | { type: BillingActionTypes.CHANGE_SEATS_REQUEST }
  | { type: BillingActionTypes.CHANGE_SEATS_SUCCESS; payload: ChargeeBeeResponse }
  | { type: BillingActionTypes.CHANGE_SEATS_FAIL; error: string }
  | { type: BillingActionTypes.CHANGE_TERM_REQUEST }
  | { type: BillingActionTypes.CHANGE_TERM_SUCCESS; payload: ChargeeBeeResponse }
  | { type: BillingActionTypes.CHANGE_TERM_FAIL; error: string }
  | { type: BillingActionTypes.DELETE_SCHEDULED_CHANGE_REQUEST; payload: string }
  | { type: BillingActionTypes.DELETE_SCHEDULED_CHANGE_SUCCESS }
  | { type: BillingActionTypes.DELETE_SCHEDULED_CHANGE_FAIL; error: string }
  | { type: BillingActionTypes.SET_ADD_OR_REMOVE_SEATS; payload: boolean }
  | { type: BillingActionTypes.SET_CHANGE_SEATS; payload: number }
  | { type: BillingActionTypes.SET_TERM; payload: string }
  | { type: BillingActionTypes.GET_ITEM_PRICES_REQUEST }
  | { type: BillingActionTypes.GET_ITEM_PRICES_SUCCESS; payload: ItemPrices[] }
  | { type: BillingActionTypes.GET_ITEM_PRICES_FAIL; error: string }
  | { type: BillingActionTypes.REFRESH_TRIAL_LICENSE_REQUEST }
  | { type: BillingActionTypes.REFRESH_TRIAL_LICENSE_SUCCESS };

const getBillingRequest = (): BillingAction => ({ type: BillingActionTypes.GET_BILLING_REQUEST });
const getBillingSuccess = (payload: Subscription): BillingAction => ({
  type: BillingActionTypes.GET_BILLING_SUCCESS,
  payload,
});
const getBillingFail = (error: string): BillingAction => ({ type: BillingActionTypes.GET_BILLING_FAIL, error });

const changeSeatsRequest = (): BillingAction => ({ type: BillingActionTypes.CHANGE_SEATS_REQUEST });
const changeSeatsSuccess = (payload: ChargeeBeeResponse): BillingAction => ({
  type: BillingActionTypes.CHANGE_SEATS_SUCCESS,
  payload,
});
const changeSeatsFail = (error: string): BillingAction => ({ type: BillingActionTypes.CHANGE_SEATS_FAIL, error });

const changeTermRequest = (): BillingAction => ({ type: BillingActionTypes.CHANGE_TERM_REQUEST });
const changeTermSuccess = (payload: ChargeeBeeResponse): BillingAction => ({
  type: BillingActionTypes.CHANGE_TERM_SUCCESS,
  payload,
});
const changeTermFail = (error: string): BillingAction => ({ type: BillingActionTypes.CHANGE_TERM_FAIL, error });

const deleteScheduledChangeRequest = (payload: string): BillingAction => ({
  type: BillingActionTypes.DELETE_SCHEDULED_CHANGE_REQUEST,
  payload,
});
const deleteScheduledChangeSuccess = (): BillingAction => ({
  type: BillingActionTypes.DELETE_SCHEDULED_CHANGE_SUCCESS,
});
const deleteScheduledChangeFail = (error: string): BillingAction => ({
  type: BillingActionTypes.DELETE_SCHEDULED_CHANGE_FAIL,
  error,
});

const setAddOrRemoveSeats = (payload: boolean): BillingAction => ({
  type: BillingActionTypes.SET_ADD_OR_REMOVE_SEATS,
  payload,
});

const setChangeSeats = (payload: number): BillingAction => ({
  type: BillingActionTypes.SET_CHANGE_SEATS,
  payload,
});

const setTerm = (payload: string): BillingAction => ({
  type: BillingActionTypes.SET_TERM,
  payload,
});

const getItemPricesRequest = (): BillingAction => ({ type: BillingActionTypes.GET_ITEM_PRICES_REQUEST });
const getItemPricesSuccess = (payload: ItemPrices[]): BillingAction => ({
  type: BillingActionTypes.GET_ITEM_PRICES_SUCCESS,
  payload,
});
const getItemPricesFail = (error: string): BillingAction => ({ type: BillingActionTypes.GET_ITEM_PRICES_FAIL, error });

const refreshTrialLicenseRequest = (): BillingAction => ({
  type: BillingActionTypes.REFRESH_TRIAL_LICENSE_REQUEST,
});
const refreshTrialLicenseSuccess = (): BillingAction => ({
  type: BillingActionTypes.REFRESH_TRIAL_LICENSE_SUCCESS,
});

export const billingActions = {
  getBillingRequest,
  getBillingSuccess,
  getBillingFail,

  changeSeatsRequest,
  changeSeatsSuccess,
  changeSeatsFail,

  changeTermRequest,
  changeTermSuccess,

  changeTermFail,
  deleteScheduledChangeRequest,
  deleteScheduledChangeSuccess,
  deleteScheduledChangeFail,

  setAddOrRemoveSeats,
  setChangeSeats,
  setTerm,

  getItemPricesRequest,
  getItemPricesSuccess,
  getItemPricesFail,

  refreshTrialLicenseRequest,
  refreshTrialLicenseSuccess,
};
