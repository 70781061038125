import { t } from '../../../i18n/i18n';

export default {
  clone: t('Common:CLONE'),
  delete: t('Common:DELETE'),
  edit: t('Common:EDIT'),
  view: t('Common:VIEW'),
  useTemplate: t('EditSmartAlert:USE_TEMPLATE'),
  useAlert: t('EditSmartAlert:USE_ALERT'),
};
