import { useDispatch, useSelector } from 'react-redux';
import { InputTextarea } from 'primereact/inputtextarea';
import { MAX_LENGTH_DESCRIPTION, MAX_LENGTH_EMAIL } from '../../../types/constants';
import { CustomField } from '../../common/meeting/customField/CustomField';
import { eventActions, eventSelectors } from '../../../store/publicBookingPage';
import { CustomFieldInput, CustomFieldType } from '../../../API';
import labels from './labels';
import { Checkbox } from 'primereact/checkbox';
import {
  ChatBubbleOvalLeftIcon,
  InformationCircleIcon,
  PlusIcon,
  UserCircleIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { ScrollPanel } from 'primereact/scrollpanel';

export const PublicBookingPageYourInformation = () => {
  const dispatch = useDispatch();
  const isPreviewMode = useSelector(eventSelectors.selectIsPreviewMode);
  const personalFields = useSelector(eventSelectors.selectPersonalInputFields);
  const inputFields = useSelector(eventSelectors.selectFilteredInputFields);
  const guestEmails = useSelector(eventSelectors.selectAdditionalGuestEmails);
  const note = useSelector(eventSelectors.selectNote);
  const additionalGuestEmailsValidation = useSelector(eventSelectors.selectAdditionalGuestEmailsValidation);
  const inviteOthers = useSelector(eventSelectors.selectInviteOthers);
  const enterNote = useSelector(eventSelectors.selectEnterNote);
  const isSMSEnabled = useSelector(eventSelectors.selectIsSMSEnabled);
  const isSMSAllowed = useSelector(eventSelectors.selectIsSMSAllowed);

  const handleCustomFieldChange = (customField: CustomFieldInput) => {
    dispatch(eventActions.updateCustomField(customField));
  };

  const handleAddAttendees = () => {
    dispatch(eventActions.updateEvent({ guestEmails: [...guestEmails, null] }));
  };

  const handleRemoveAttendees = (index: number) => {
    dispatch(eventActions.updateEvent({ guestEmails: guestEmails.filter((_, i) => i !== index) }));
  };

  const handleUpdateAttendee = (value: string, index: number) => {
    dispatch(
      eventActions.updateEvent({
        guestEmails: guestEmails.map((email, i) => (i === index ? value : email)),
      })
    );
  };

  const handleChangeNote = (value: string) => {
    dispatch(eventActions.updateEvent({ note: value }));
  };

  const handleSMSAllowedChange = (isSMSAllowed: boolean) => {
    dispatch(eventActions.updateEvent({ isSMSAllowed }));
  };

  return (
    <div className="flex-1">
      <ScrollPanel>
        <div className="booking-info-container">
          <div className="booking-info">
            <div className="flex-left-center gap-6px text-label-s-med text-heavy-60 mb-16px">
              <UserCircleIcon width={20} height={20} />
              <div>{labels.personalInfo}</div>
            </div>
            <div className="flex flex-column gap-12px mb-20px">
              {personalFields?.map(
                (field) =>
                  field && (
                    <div key={field.id} className="">
                      <CustomField
                        customField={field}
                        onChange={handleCustomFieldChange}
                        disabled={isPreviewMode}
                        hideLabel
                      ></CustomField>
                    </div>
                  )
              )}
            </div>

            {inviteOthers && (
              <div className="py-20px border-top-1 border-heavy-20">
                {guestEmails.length ? (
                  <div className="flex flex-column gap-8px mb-20px">
                    {guestEmails.map((email, index) => (
                      <div key={index} className="flex-left-center gap-4px">
                        <InputText
                          className={`flex-1 ${additionalGuestEmailsValidation[index] ? '' : 'p-invalid'}`}
                          placeholder={labels.AttendeeEmail}
                          value={email || ''}
                          onChange={(e) => handleUpdateAttendee(e.target.value, index)}
                          maxLength={MAX_LENGTH_EMAIL}
                        />
                        <div className="action-button" onClick={() => handleRemoveAttendees(index)}>
                          <XMarkIcon width={20} />
                        </div>
                      </div>
                    ))}
                  </div>
                ) : null}
                <Button className="gap-8px" text disabled={isPreviewMode} onClick={handleAddAttendees}>
                  <PlusIcon width={18} height={18} />
                  <div>{labels.addAttendees}</div>
                </Button>
              </div>
            )}

            {enterNote && (
              <div className="flex flex-column gap-16px border-top-1 border-heavy-20 pt-16px pb-32px">
                <div className="flex-left-center gap-6px text-label-s-med text-heavy-60">
                  <ChatBubbleOvalLeftIcon width={20} height={20} />
                  <div>{labels.noteForAttendees}</div>
                </div>
                <InputTextarea
                  autoResize
                  rows={1}
                  placeholder={labels.notePlaceholder}
                  value={note || ''}
                  onChange={(e) => handleChangeNote(e.target.value.trimStart())}
                  maxLength={MAX_LENGTH_DESCRIPTION}
                  disabled={isPreviewMode}
                />
              </div>
            )}

            {inputFields.length ? (
              <div className="flex flex-column gap-16px border-top-1 border-heavy-20 pt-16px">
                <div className="flex-left-center gap-6px text-label-s-med text-heavy-60">
                  <InformationCircleIcon width={20} height={20} />
                  <div>{labels.yourAnswers}</div>
                </div>
                {inputFields?.map(
                  (customField) =>
                    customField?.enabled && (
                      <div key={customField.id} className="pb-8px">
                        <CustomField
                          customField={customField}
                          onChange={handleCustomFieldChange}
                          disabled={isPreviewMode}
                        ></CustomField>
                        {customField.fieldType === CustomFieldType.PHONE && isSMSEnabled && (
                          <div className="flex-left-center mt-10px">
                            <Checkbox
                              inputId="allowSms"
                              checked={isSMSAllowed}
                              onChange={(e) => handleSMSAllowedChange(Boolean(e.checked))}
                            />
                            <label htmlFor="allowSms" className="cursor-pointer ml-8px text-body-lg-reg">
                              {labels.allowSms}
                            </label>
                          </div>
                        )}
                      </div>
                    )
                )}
              </div>
            ) : null}
          </div>
        </div>
      </ScrollPanel>
    </div>
  );
};
