import { createSelector } from 'reselect';
import { State } from '../rootStore';
import { globalSelectors } from '../global';
import { CARD, CONDTION_TYPE_OPTIONS, DEFAULT_FILTER } from './constants';
import { authenticationSelectors } from '../authentication';
import { StatusFilter } from '../../types/types';
import {
  AttachmentType,
  JourneyCard,
  JourneyConditionType,
  JourneyDestinationType,
  JourneyFormField,
  JourneyFormFieldType,
  JourneyPageType,
  JourneyStepType,
  TimeUnit,
  UpdateBookingPageInput,
} from '../../API';
import { getAllConditionFieldCardIds, getAllParents } from './utils';
import { t } from '../../i18n/i18n';
import { DateFormat, userSettingsSelectors } from '../userSettings';
import { formatDateByThreeOptions } from '../../services/DateService';
import dayjs from 'dayjs';
import { DEFAULT_BOOKING_PAGE_HOW } from '../bookingPages';
import { validateURL } from '../../services/URLService';
import { isOldData } from '../../services/utils';

const journeysState = (state: State) => state.journeys;

const selectIsFetching = createSelector(journeysState, (state) => state.isFetching);
const selectError = createSelector(journeysState, (state) => state.error);
const selectJourneys = createSelector(journeysState, (state) => state.journeys);
const selectJourney = createSelector(journeysState, (state) => state.journey);
const selectJourneyStep = createSelector(journeysState, (state) => state.journeyStep);
const selectFilter = createSelector(journeysState, (state) => state.filter);
const selectSelectedJourneys = createSelector(journeysState, (state) => state.selectedJourneys);
const selectCloneName = createSelector(journeysState, (state) => state.cloneName);
const selectEditSidebar = createSelector(journeysState, (state) => state.editStepSidebar);
const selectFieldsAndCards = createSelector(journeysState, (state) => state.fieldsAndCards);
const selectIsEdited = createSelector(journeysState, (state) => state.isEdited);

const selectJourneyEvents = createSelector(journeysState, (state) => state.journeyEvents);
const selectEventFilter = createSelector(journeysState, (state) => state.journeyEventFilter);

const selectLastLoadTime = createSelector(journeysState, (state) => state.lastLoadTime);
const selectIsSpinnerFetching = createSelector(
  selectIsFetching,
  selectLastLoadTime,
  (isFetching, loadTime) => isFetching && !isOldData(loadTime)
);
const selectIsSkeletonFetching = createSelector(
  selectIsFetching,
  selectLastLoadTime,
  (isFetching, loadTime) => isFetching && isOldData(loadTime)
);

const selectIsDefaultFilter = createSelector(selectFilter, (filter) => filter === DEFAULT_FILTER);
const selectFilterCreatedBy = createSelector(selectFilter, (filter) => filter.createdBy);
const selectFilterStatus = createSelector(selectFilter, (filter) => filter.status);

const selectShowActive = createSelector(selectFilterStatus, (status) => status.includes(StatusFilter.ACTIVE));
const selectShowInactive = createSelector(selectFilterStatus, (status) => status.includes(StatusFilter.INACTIVE));

const selectIsFilterInUse = createSelector(
  selectFilter,
  authenticationSelectors.selectUserId,
  (filter, userId) => filter.createdBy.length !== 1 || filter.createdBy[0] !== userId || filter.status.length !== 2
);

const selectIsSelectedJourney = (id: string) =>
  createSelector(selectSelectedJourneys, (selectedJourneys) => selectedJourneys.some((item) => item === id));

const selectFilteredJourneys = createSelector(
  selectJourneys,
  selectShowActive,
  selectShowInactive,
  selectFilterCreatedBy,
  (journeys, showActive, showInactive, createdBy) =>
    journeys
      .filter(
        (journey) =>
          ((showActive && journey.active) || (showInactive && !journey.active)) &&
          createdBy.includes(journey.createdBy || '')
      )
      .sort((a, b) => (a.createdAt <= b.createdAt ? 1 : -1))
);

const selectSearchedJourneys = createSelector(
  selectFilteredJourneys,
  globalSelectors.selectSearchString,
  (journeys, searchString) =>
    journeys.filter((journey) => journey.name?.toLowerCase().includes(searchString.toLowerCase()))
);

const selectIsNoJourneysToShow = createSelector(selectSearchedJourneys, (journeys) => !journeys.length);

const selectIsCloneNameValid = createSelector(selectCloneName, (name) => Boolean(name));

const selectJourneyId = createSelector(selectJourney, (journey) => journey.id);
const selectJourneyName = createSelector(selectJourney, (journey) => journey.name);
const selectJourneyStyle = createSelector(selectJourney, (journey) => journey?.style);

const selectIsNewJourney = createSelector(selectJourneyId, (id) => !id);
const selectIsJourneyNameValid = createSelector(selectJourneyName, (name) => Boolean(name));
const selectJourneyStepType = createSelector(selectJourneyStep, (step) => step?.type);
const selectJourneyPage = createSelector(selectJourneyStep, (step) => step?.page);
const selectPageName = createSelector(selectJourneyPage, (page) => page?.name || '');
const selectPageType = createSelector(selectJourneyPage, (page) => page?.type);
const selectPageSubmitLabel = createSelector(selectJourneyPage, (page) => page?.submitLabel || '');
const selectPageFields = createSelector(selectJourneyPage, (page) => page?.formFields || []);
const selectPageCards = createSelector(selectJourneyPage, (page) => page?.cards || []);
const selectPageHasElements = createSelector(
  selectJourneyPage,
  (page) =>
    (page?.type === JourneyPageType.CARD && !!page.cards?.length) ||
    (page?.type === JourneyPageType.FORM && !!page.formFields?.length)
);

const selectIsPageStepValid = createSelector(
  selectJourneyPage,
  (page) =>
    !!page?.name &&
    !!page.type &&
    ((page.type === JourneyPageType.CARD && !!page.cardTitle && !!page.cards?.length) ||
      (page.type === JourneyPageType.FORM && !!page.formFields?.length))
);

const selectJourneyCondition = createSelector(selectJourneyStep, (step) => step?.condition);
const selectConditionType = createSelector(selectJourneyCondition, (condition) => condition?.type);
const selectConditionAttachmentType = createSelector(selectJourneyCondition, (condition) => condition?.attachmentType);
const selectIsRange = createSelector(selectConditionType, (type) => type === JourneyConditionType.RANGE);
const selectIsFileSizeType = createSelector(
  selectConditionAttachmentType,
  (type) => type && [AttachmentType.SIZE_IS_BIGGER_THAN, AttachmentType.SIZE_IS_SMALLER_THAN].includes(type)
);
const selectAllStepParents = createSelector(selectJourney, selectJourneyStep, (journey, step) =>
  getAllParents(journey.root, step?.id || '')
);

const selectAllFields = createSelector(selectAllStepParents, (parents) => {
  const fields: JourneyFormField[] = [];
  parents.forEach((parent) => {
    if (parent.type === JourneyStepType.PAGE && parent.page?.type === JourneyPageType.FORM) {
      parent.page.formFields?.forEach(
        (field) =>
          field?.type &&
          ![JourneyFormFieldType.TITLE, JourneyFormFieldType.SUBTITLE].includes(field.type) &&
          fields.push(field)
      );
    }
  });
  console.log(parents, fields);
  return fields;
});

const selectAllCards = createSelector(selectAllStepParents, (parents) => {
  const cards: JourneyCard[] = [];
  parents.forEach((parent) => {
    if (parent.type === JourneyStepType.PAGE && parent.page?.type === JourneyPageType.CARD) {
      parent.page.cards?.forEach((card) => card && cards.push(card));
    }
  });
  return cards;
});

const selectConditionOptions = createSelector(selectAllCards, selectAllFields, (cards, fields) => {
  const options = cards.length ? [{ value: CARD, label: t('EditJourney:CARD') }] : [];
  fields.forEach((field) => {
    options.push({ value: field.id || '', label: field.label || '' });
  });
  return options;
});

const selectJourneyConditionField = createSelector(selectAllFields, selectJourneyCondition, (fields, condition) =>
  fields.find((field) => field.id === condition?.conditionField)
);
const selectJourneyConditionFieldType = createSelector(selectJourneyConditionField, (field) => field?.type || CARD);
const selectJourneyConditionFieldAnswers = createSelector(selectJourneyConditionField, (field) => field?.answers || []);

const selectIsAttachmentConditionField = createSelector(
  selectJourneyConditionFieldType,
  (fieldType) => fieldType === JourneyFormFieldType.ATTACHMENT
);

const selectConditionTypeOptions = createSelector(selectJourneyConditionFieldType, (fieldType) => {
  if (fieldType === CARD || [JourneyFormFieldType.DROPDOWN, JourneyFormFieldType.RADIO_BUTTONS].includes(fieldType)) {
    return CONDTION_TYPE_OPTIONS.filter((option) =>
      [JourneyConditionType.IS, JourneyConditionType.IS_NOT].includes(option.value)
    );
  } else return CONDTION_TYPE_OPTIONS;
});

const selectIsConditionValueValid = createSelector(
  selectJourneyCondition,
  (condition) => !condition?.value?.some((el) => !el)
);

const selectIsConditionStepValid = createSelector(
  selectJourneyCondition,
  selectIsFileSizeType,
  selectIsConditionValueValid,
  (condition, isFileSizeType, isValueValid) =>
    !!condition?.conditionField &&
    (((!!condition.type || !!condition.attachmentType) && isValueValid) ||
      (isFileSizeType && !!condition.attachmentSize?.type && !!condition.attachmentSize?.value))
);

const selectJourneyDestination = createSelector(selectJourneyStep, (step) => step?.destination);

const selectIsDestinationStepValid = createSelector(
  selectJourneyDestination,
  (destination) =>
    (destination?.type === JourneyDestinationType.BOOKING_PAGE && !!destination.bookingPageId) ||
    (destination?.type === JourneyDestinationType.EXTERNAL_URL &&
      !!destination.externalURL &&
      validateURL(destination.externalURL)) ||
    (destination?.type === JourneyDestinationType.MESSAGE &&
      !!destination.message?.header &&
      !!destination.message?.body)
);

const selectIsStepValid = createSelector(
  selectJourneyStepType,
  selectIsPageStepValid,
  selectIsConditionStepValid,
  selectIsDestinationStepValid,
  (type, isPage, isCondition, isDestination) =>
    (type === JourneyStepType.PAGE && isPage) ||
    (type === JourneyStepType.CONDITION && isCondition) ||
    (type === JourneyStepType.DESTINATION && isDestination)
);

const selectFields = createSelector(selectFieldsAndCards, (state) => state.fields);
const selectCards = createSelector(selectFieldsAndCards, (state) => state.cards);

const selectAllNonDeletableFieldsCards = createSelector(selectJourneyStep, (step) => getAllConditionFieldCardIds(step));

const selectEventFilterDateRange = createSelector(selectEventFilter, (filter) =>
  filter.dateRange.map((date) => (date ? new Date(date) : null))
);
const selectIsFilterActive = createSelector(
  selectEventFilter,
  (filter) => !!filter.dateRange.length || filter.response.length !== 3
);
const selectetFilterDateRangeLabel = createSelector(
  selectEventFilterDateRange,
  userSettingsSelectors.selectDateFormat,
  (dateRange, dateFormat) =>
    dateRange && dateRange[0]
      ? ' ' +
        formatDateByThreeOptions(dateRange[0], dateFormat || DateFormat.default) +
        (dateRange[1] ? ` - ${formatDateByThreeOptions(dateRange[1], dateFormat || DateFormat.default)}` : '')
      : ''
);

const selectFilteredEvents = createSelector(selectEventFilter, selectJourneyEvents, (filter, events) =>
  events.filter((event) => {
    const lastStep = event.steps?.length ? event.steps[event.steps?.length - 1] : null;
    const createdAt = dayjs(event.createdAt).format('YYYY-MM-DD');
    return (
      (!filter.dateRange.length ||
        (createdAt >= (filter.dateRange[0] || '') && createdAt <= (filter.dateRange[1] || ''))) &&
      lastStep?.destination?.type &&
      filter.response.includes(lastStep?.destination?.type)
    );
  })
);

const selectSearchedEvents = createSelector(
  selectFilteredEvents,
  globalSelectors.selectSearchString,
  (events, searchString) =>
    events.filter((event) => {
      if (searchString) {
        const searchLC = searchString.toLowerCase();
        return event.steps?.some(
          (step) =>
            (step?.page?.cards &&
              (step.page.cards[0]?.title?.toLowerCase().includes(searchLC) ||
                step.page.cards[0]?.details?.toLowerCase().includes(searchLC))) ||
            (step?.page?.formFields &&
              step.page.formFields.some((field) => field?.value && field.value[0]?.toLowerCase().includes(searchLC)))
        );
      } else {
        return events;
      }
    })
);

const selectJourneyReadOnly = createSelector(
  selectIsNewJourney,
  userSettingsSelectors.selectJourneyBuilderRead,
  userSettingsSelectors.selectJourneyBuilderCreate,
  userSettingsSelectors.selectJourneyBuilderEdit,
  (isNew, isRead, isCreate, isEdit) => (isNew ? isRead && !isCreate : isRead && !isEdit)
);

const selectBookingPageForPreview = createSelector(
  selectJourneyStyle,
  (style) =>
    ({
      id: '',
      workspaceId: '',
      labels: DEFAULT_BOOKING_PAGE_HOW,
      style,
      when: { duration: { timeUnit: TimeUnit.MINUTE, count: 30 } },
    } as UpdateBookingPageInput)
);

export const journeysSelectors = {
  selectIsFetching,
  selectError,
  selectJourneys,
  selectJourney,
  selectJourneyStep,
  selectSelectedJourneys,
  selectCloneName,
  selectEditSidebar,
  selectIsEdited,
  selectIsSpinnerFetching,
  selectIsSkeletonFetching,

  selectJourneyEvents,
  selectEventFilter,

  selectIsDefaultFilter,
  selectFilterStatus,
  selectFilterCreatedBy,
  selectIsFilterInUse,
  selectFilteredJourneys,
  selectSearchedJourneys,
  selectIsNoJourneysToShow,
  selectIsCloneNameValid,
  selectIsSelectedJourney,

  selectJourneyName,
  selectJourneyId,
  selectIsNewJourney,
  selectIsJourneyNameValid,

  selectJourneyPage,
  selectPageName,
  selectPageType,
  selectPageSubmitLabel,
  selectPageFields,
  selectPageCards,
  selectPageHasElements,
  selectIsPageStepValid,

  selectAllStepParents,
  selectAllFields,
  selectAllCards,

  selectJourneyCondition,
  selectConditionType,
  selectConditionAttachmentType,
  selectIsRange,
  selectIsFileSizeType,
  selectConditionOptions,
  selectJourneyConditionFieldType,
  selectJourneyConditionFieldAnswers,
  selectIsAttachmentConditionField,
  selectConditionTypeOptions,
  selectIsConditionStepValid,

  selectJourneyDestination,

  selectIsStepValid,
  selectFields,
  selectCards,
  selectAllNonDeletableFieldsCards,
  selectEventFilterDateRange,
  selectIsFilterActive,
  selectetFilterDateRangeLabel,

  selectFilteredEvents,
  selectSearchedEvents,

  selectJourneyReadOnly,

  selectBookingPageForPreview,
};
