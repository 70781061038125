import React, { useState, useRef, useEffect } from 'react';
import { OverlayPanel } from 'primereact/overlaypanel';
import { HeroIconComponent, HeroIconKey } from '../../../types/types';
import { HeroIcons } from '../../../types/constants';
import labels from './labels';

const iconList = Object.keys(HeroIcons).map((iconName) => ({
  name: iconName,
  Icon: HeroIcons[iconName as HeroIconKey] as HeroIconComponent,
}));

type IconPickerProps = {
  value?: string | null;
  onChange: (value: string | null) => void;
};

export const IconPicker = ({ value, onChange }: IconPickerProps) => {
  const overlayPanelRef = useRef<OverlayPanel>(null);
  const [Icon, setIcon] = useState<HeroIconComponent | null>(null);

  useEffect(() => {
    setIcon(value ? (HeroIcons[value as HeroIconKey] as HeroIconComponent) : null);
  }, [value]);

  const handleIconSelect = (e: React.MouseEvent<HTMLDivElement, MouseEvent>, iconName: string) => {
    onChange(iconName);
    overlayPanelRef.current?.toggle(e);
  };

  const renderIconGrid = () => (
    <div className="flex flex-wrap gap-2px">
      {iconList.map((icon) => (
        <div
          key={icon.name}
          className="cursor-pointer flex justify-center items-center hover:bg-gray-200 p-4px border-radius-4px"
          onClick={(e) => handleIconSelect(e, icon.name)}
        >
          <icon.Icon width={24} height={24} />
        </div>
      ))}
    </div>
  );

  return (
    <div className="flex">
      <div
        className="border-radius-10px w-200px bg-heavy-1 hover-shadow-level-2 py-24px px-18px cursor-pointer flex-center"
        onClick={(e) => overlayPanelRef.current?.toggle(e)}
      >
        <div className="flex-center gap-8px text-heavy-60">
          {Icon ? (
            <Icon width={48} height={48} />
          ) : (
            <>
              <HeroIcons.CloudArrowUpIcon width={48} height={48} />
              <div className="flex flex-column gap-4px text-label-xs-reg">
                <div>{labels.selectIcon}</div>
                <div>({labels.optional})</div>
              </div>
            </>
          )}
        </div>
      </div>

      <OverlayPanel ref={overlayPanelRef} dismissable className="icon-picker">
        {renderIconGrid()}
      </OverlayPanel>
    </div>
  );
};
