export const CannotDeleteWorkspace = Object.freeze({
  DESCRIPTION: `Los siguientes usuarios están asignados para usar solo este espacio de trabajo`,
  NOTES: `Debe asignar primero estos usuarios a otro espacio de trabajo antes de eliminar este.`,
  TITLE: `Reasignar Usuarios`,
});

export const WorkspaceToastsNotifications = Object.freeze({
  ACTIVATE_INACTIVATE_WORKSPACE_ERROR_MESSAGE: `Error al activar/desactivar el espacio de trabajo`,
  ACTIVATE_WORKSPACE_SUCCESS_MESSAGE: `Espacio de trabajo activado`,
  DELETE_WORKSPACE_ERROR_MESSAGE: `Error al eliminar el espacio de trabajo`,
  DELETE_WORKSPACE_SUCCESS_MESSAGE: `Solicitud de eliminación del espacio de trabajo exitosa`,
  GET_WORKSPACES_ERROR_MESSAGE: `Error al obtener la solicitud de espacios de trabajo`,
  INACTIVATE_WORKSPACE_SUCCESS_MESSAGE: `Espacio de trabajo desactivado`,
  REMOVE_INTEGRATION_SUCCESS_TOAST: `Integración del espacio de trabajo desconectada con éxito`,
  SAVE_WORKSPACE_ERROR_MESSAGE: `Error al guardar la solicitud del espacio de trabajo`,
  SAVE_WORKSPACE_SUCCESS_MESSAGE: `Espacio de trabajo guardado exitosamente`,
  CLONE_WORKSPACE_ERROR_MESSAGE: `Error en la solicitud de clonación del espacio de trabajo`,
  CLONE_WORKSPACE_SUCCESS_MESSAGE: `Espacio de trabajo clonado con éxito`,
});

export const WorkspaceWhoStep = Object.freeze({
  ADMINS_TITLE: `Administradores`,
  ADMIN_DESCRIPTION: `Las siguientes personas pueden administrar este espacio de trabajo.`,
  INVITE_SETTINGS_TITLE: `Configuración de Invitación`,
  NOTES_PART_1: `Alternativamente, puede usar la sección de `,
  NOTES_PART_2: `Gestión de Usuarios`,
  NOTES_PART_3: ` para encontrar un usuario y agregarlo a un espacio de trabajo.`,
  PHONE_NUMBER_DESCRIPTION: `Si planea utilizar SUMO para reservar llamadas telefónicas, se recomienda que lo mantenga habilitado.`,
  PHONE_NUMBER_TITLE: `Hacer obligatorio el campo "Número de teléfono" para los usuarios.`,
  SEARCH_ADD_ADMINS: `Buscar y agregar administrador`,
  SEARCH_ADD_USERS: `Buscar y agregar usuarios`,
  SUPER_ADMINS_DESCRIPTION: `Los superadministradores siempre estarán precargados`,
  USERS_DESCRIPTION: `Las siguientes personas pueden usar este espacio de trabajo.`,
  USERS_TITLE: `Usuarios`,
});

export const Workspaces = Object.freeze({
  ACTIVE: `Activo`,
  ADMIN_TITLE: `Administrador`,
  CLONE_MODAL_TITLE: `Clonar espacio de trabajo`,
  DEFAULT_WORKSPACE: `Espacio de trabajo predeterminado`,
  DELETE_DESCRIPTION: `¿Está seguro de que desea eliminar`,
  INACTIVE: `Inactivo`,
  NAME_TITLE: `Nombre del espacio de trabajo`,
  NAME_PLACEHOLDER: `Ingrese un nombre de espacio de trabajo`,
  NEW_WORKSPACE: `Nuevo espacio de trabajo`,
  SELECTED_WORKSPACES: `espacios de trabajo seleccionados`,
  TITLE: `Espacios de trabajo`,
  BRANDING_TITLE: `Personalización de la página de marca`,
  BRANDING_DESCRIPTION: `Personalice la apariencia, los estilos y las etiquetas para las nuevas páginas de reservas.`,
});

export const EditWorkspace = Object.freeze({
  WHAT_TITLE: `Qué`,
  WHAT_DESCRIPTION: `Nombre y estado`,
  WHO_TITLE: `Quién`,
  WHO_DESCRIPTION: `Administradores y usuarios`,
  HOW_TITLE: `Marca`,
  HOW_DESCRIPTION: `Estilos y presentaciones de marca`,
  ADDITIONAL_CONFIGURATIONS: `Configuraciones adicionales`,
});
