import { PhysicalLocation } from '../../API';
import { t } from '../../i18n/i18n';
import {
  TOAST_ERROR_COLOR,
  TOAST_ERROR_DURATION,
  TOAST_SUCCESS_COLOR,
  TOAST_SUCCESS_DURATION,
} from '../../types/constants';
import { StatusFilter } from '../../types/types';
import { ToastNotificationOptions } from '../notifications';
import { FilterStatusOption, LocationFilter } from './types';

export const GOOGLE_MAP_ACCESS_LOCATION_LINK = 'https://support.google.com/maps/answer/2839911';

export const DEFAULT_PHYSICAL_LOCATION: PhysicalLocation = {
  __typename: 'PhysicalLocation',
  id: '',
  name: '',
  active: true,
  createdAt: '',
  updatedAt: '',
};

export const ImportLocationsLabels = ['name', 'address', 'city', 'state', 'zip', 'country'];

export const DEFAULT_FILTER: LocationFilter = {
  status: [StatusFilter.ACTIVE, StatusFilter.INACTIVE],
  state: [],
  country: [],
  city: [],
};

export const FILTER_STATUS_OPTIONS: FilterStatusOption[] = [
  { value: StatusFilter.ACTIVE, label: t('Locations:ACTIVE') },
  { value: StatusFilter.INACTIVE, label: t('Locations:INACTIVE') },
];

export const GET_LOCATIONS_ERROR_TOAST: ToastNotificationOptions = Object.freeze({
  color: TOAST_ERROR_COLOR,
  autoHideDuration: TOAST_ERROR_DURATION,
  message: t('LocationsToastsNotifications:GET_LOCATIONS_ERROR_MESSAGE'),
});

export const DEACTIVATE_LOCATION_SUCCESS_TOAST: ToastNotificationOptions = Object.freeze({
  color: TOAST_SUCCESS_COLOR,
  autoHideDuration: TOAST_SUCCESS_DURATION,
  message: t('LocationsToastsNotifications:DEACTIVATE_LOCATION_SUCCESS_MESSAGE'),
});
export const ACTIVATE_LOCATION_SUCCESS_TOAST: ToastNotificationOptions = Object.freeze({
  color: TOAST_SUCCESS_COLOR,
  autoHideDuration: TOAST_SUCCESS_DURATION,
  message: t('LocationsToastsNotifications:ACTIVATE_LOCATION_SUCCESS_MESSAGE'),
});
export const DEACTIVATE_LOCATION_ERROR_TOAST: ToastNotificationOptions = Object.freeze({
  color: TOAST_ERROR_COLOR,
  autoHideDuration: TOAST_ERROR_DURATION,
  message: t('LocationsToastsNotifications:DEACTIVATE_LOCATION_ERROR_MESSAGE'),
});

export const CLONE_LOCATION_SUCCESS_TOAST: ToastNotificationOptions = Object.freeze({
  color: TOAST_SUCCESS_COLOR,
  autoHideDuration: TOAST_SUCCESS_DURATION,
  message: t('LocationsToastsNotifications:CLONE_LOCATION_SUCCESS_MESSAGE'),
});
export const CLONE_LOCATION_ERROR_TOAST: ToastNotificationOptions = Object.freeze({
  color: TOAST_ERROR_COLOR,
  autoHideDuration: TOAST_ERROR_DURATION,
  message: t('LocationsToastsNotifications:CLONE_LOCATION_ERROR_MESSAGE'),
});

export const DELETE_LOCATION_SUCCESS_TOAST: ToastNotificationOptions = Object.freeze({
  color: TOAST_SUCCESS_COLOR,
  autoHideDuration: TOAST_SUCCESS_DURATION,
  message: t('LocationsToastsNotifications:DELETE_LOCATION_SUCCESS_MESSAGE'),
});
export const DELETE_LOCATION_ERROR_TOAST: ToastNotificationOptions = Object.freeze({
  color: TOAST_ERROR_COLOR,
  autoHideDuration: TOAST_ERROR_DURATION,
  message: t('LocationsToastsNotifications:DELETE_LOCATION_ERROR_MESSAGE'),
});

export const VALIDATE_LOCATIONS_ERROR_TOAST: ToastNotificationOptions = Object.freeze({
  color: TOAST_ERROR_COLOR,
  autoHideDuration: TOAST_ERROR_DURATION,
  message: t('LocationsToastsNotifications:VALIDATE_LOCATIONS_ERROR_MESSAGE'),
});

export const IMPORT_LOCATIONS_SUCCESS_TOAST: ToastNotificationOptions = Object.freeze({
  color: TOAST_SUCCESS_COLOR,
  autoHideDuration: TOAST_SUCCESS_DURATION,
  message: t('LocationsToastsNotifications:IMPORT_LOCATIONS_SUCCESS_MESSAGE'),
});
export const IMPORT_LOCATIONS_ERROR_TOAST: ToastNotificationOptions = Object.freeze({
  color: TOAST_ERROR_COLOR,
  autoHideDuration: TOAST_ERROR_DURATION,
  message: t('LocationsToastsNotifications:IMPORT_LOCATIONS_ERROR_MESSAGE'),
});

export const GET_LOCATION_ERROR_TOAST: ToastNotificationOptions = Object.freeze({
  color: TOAST_ERROR_COLOR,
  autoHideDuration: TOAST_ERROR_DURATION,
  message: t('LocationsToastsNotifications:GET_LOCATION_ERROR_MESSAGE'),
});

export const SAVE_LOCATION_ERROR_TOAST: ToastNotificationOptions = Object.freeze({
  color: TOAST_ERROR_COLOR,
  autoHideDuration: TOAST_ERROR_DURATION,
  message: t('LocationsToastsNotifications:SAVE_LOCATION_ERROR_MESSAGE'),
});
export const CREATE_LOCATION_SUCCESS_TOAST: ToastNotificationOptions = Object.freeze({
  color: TOAST_SUCCESS_COLOR,
  autoHideDuration: TOAST_SUCCESS_DURATION,
  message: t('LocationsToastsNotifications:CREATE_LOCATION_SUCCESS_MESSAGE'),
});
export const UPDATE_LOCATION_SUCCESS_TOAST: ToastNotificationOptions = Object.freeze({
  color: TOAST_SUCCESS_COLOR,
  autoHideDuration: TOAST_SUCCESS_DURATION,
  message: t('LocationsToastsNotifications:UPDATE_LOCATION_SUCCESS_MESSAGE'),
});
