import { BackgroundType, CreateTenantInput } from '../../API';
import { t } from 'i18next';
import { ToastNotificationOptions } from '../notifications';
import { LinkStatus } from '../userSettings';
import urlConstants from '../../shared/JSON/urlConstants.json';
import {
  TOAST_ERROR_COLOR,
  TOAST_ERROR_DURATION,
  TOAST_SUCCESS_COLOR,
  TOAST_SUCCESS_DURATION,
} from '../../types/constants';

export const DEFAULT_LINK_STATUS = LinkStatus.FREE;

export const SAVE_QUICK_SETUP_SUCCESS_TOAST: ToastNotificationOptions = Object.freeze({
  color: TOAST_SUCCESS_COLOR,
  autoHideDuration: TOAST_SUCCESS_DURATION,
  title: t('QuickSetupToastsNotifications:SAVE_QUICK_SETUP_SUCCESS_TOAST_TITLE'),
  message: t('QuickSetupToastsNotifications:SAVE_QUICK_SETUP_SUCCESS_TOAST_DESC'),
});

export const SAVE_QUICK_SETUP_ERROR_TOAST: ToastNotificationOptions = Object.freeze({
  color: TOAST_ERROR_COLOR,
  autoHideDuration: TOAST_ERROR_DURATION,
  message: t('QuickSetupToastsNotifications:SAVE_QUICK_SETUP_ERROR_TOAST'),
});

export const DEFAULT_TENANT_INFO: Partial<CreateTenantInput> = {
  tenantStyle: {
    logoImage: null,
    backgroundImage: null,
    backgroundColor: '#fff',
    backgroundType: BackgroundType.BLANK,
    primaryColor: '#006CE0',
    css: null,
    footerHtml: `<a href="${urlConstants.NAME_URL_SITE}" target="_blank">Powered by SUMO</a>`,
  },
};
