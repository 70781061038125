import { t } from '../../i18n/i18n';

export default {
  back: t('Common:BACK'),
  importCSV: t('Locations:IMPORT_CSV'),
  importLabel: t('Locations:IMPORT_LABEL'),
  importDescriptionPart1: t('Locations:IMPORT_DESCRIPTION_PART1'),
  importDescriptionPart2: t('Locations:IMPORT_DESCRIPTION_PART2'),
  importDescriptionPart3: t('Locations:IMPORT_DESCRIPTION_PART3'),
  cancel: t('Common:CANCEL'),
};
